import React from 'react';
import Panel from '../panel';
import { dateString } from '../../helpers';

const DateDescriptionList = ({ dates }) => {
    // let date_description_list = dates?.filter(date => dateString(date) !== '')
    return (
        <>
            {
                dates?.length > 0 && dates?.map((date, index) => {
                    let title = '';
                    title = (date.event_type_name) ? title.concat(date.event_type_name, ', ') : title;
                    title = dateString(date) ? title.concat(dateString(date)) : title;
                    let dateDescription = date.description ? date.description : '';

                    return (
                        <div key={index} className='mt-2'>
                            <Panel title={title} lineNumber={2} description={dateDescription} bold={true} italic={false} expandable={dateDescription.length > 0} />
                        </div>
                    )
                })
            }
        </>
    )
}

export default DateDescriptionList;