import React, { useState } from 'react';
import EditImage from './editInfo/editImage';
import Button from '../buttons/default';
import ImageViewer from '../viewers/image';
import Spinner from '../spinner';
const ImagesCardStatic = (props) => {
    const [show, setShow] = useState(false)
    const [type, setType] = useState('')
    const caption = props?.data?.caption || '';
 
    return (
        <div className='mt-3'>
            <div>
                <div className={`bg-light py-1 `}>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-between'>
                            <div className={`fw-bold`}>Website Image</div>
                            <div>
                                <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={() => { setShow(true); setType('thumb') }} />
                            </div>
                        </div>
                        <div className='col d-flex justify-content-between'>
                            <div className={`fw-bold`}>Phone App Image</div>
                            <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={() => { setShow(true); setType('headshot') }} />
                        </div>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-4 ' onClick={() => { setShow(true); setType('thumb') }}>
                        <Spinner display={show}>
                            <div >
                                <ImageViewer url={props?.data?.url} type="thumb" cssClass='featured-thumb cursor-pointer' />
                                {caption && <div className='bg-light fw-bold small' style = {{width : '300px', height: 'auto'}}>{caption}</div>}
                            </div>
                        </Spinner>
                    </div>
                    <div className='col-auto' onClick={() => { setShow(true); setType('banner') }}>
                        <Spinner display={show}>
                            <ImageViewer url={props?.data?.url} type="banner@3x" cssClass={`height-200px cursor-pointer`} />
                        </Spinner>
                    </div>
                </div>
            </div>
            <EditImage show={show} key={show} onHide={() => setShow(false)} type={type} url={props?.data?.url} id={props?.data?.id} title={props?.imageEditorTitle} />
        </div>
    )
}

export default ImagesCardStatic