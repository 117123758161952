import React, { useState } from 'react';
import Panel from '../panel';
import { dateString, getAwardsName } from '../../helpers'
const MIN_LENGTH = 3

const AwardList = ({ awards }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {
                (awards?.length > 0) &&
                <>
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <Panel title='Awards' bold={true} italic={true} />
                        {
                            awards.length > MIN_LENGTH &&
                            <div>
                                    {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${awards.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                    {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(awards.length); showMore(true) }}>{`${awards.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                            </div>
                        }
                    </div>
                </>
            }
            {
                (awards?.length > 0) && <>
                    {
                        awards.slice(0, length).map((item, index) => {
                            return <div key={index}>
                                {getAwardsName(item)} {dateString(item).length > 0 ? ' in ' : ''}  {dateString(item)}
                            </div>
                        })
                    }
                </>
            }
        </>
    )
}

export default AwardList;