import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';

import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import uniqid from 'uniqid'

import SelectSite from '../../components/selects/site';
import Button from '../../components/buttons/default'
import TextEditor from '../../components/editors/textEditor';
import ImageViewer from '../../components/viewers/image';
import { toast } from 'react-toastify';
import TourAlerts from './tourAlerts';

const _api = require('../../api');

const StopEditor = (props) => {
    const [tourAlert, showtourAlert] = useState(false);

    const history = useHistory();

    const dispatch = useDispatch();
    const tour = useSelector(state => state[props.tourId]);
    const [isEdited, setEdited] = useState(false)

    const getStop = () => {
        let stop = {}
        if (props.stopId === 'NEW') {
            if (!tour.stops) tour.stops = [];
            stop = {
                'id': `temp.${uniqid()}`,
                'tour_id': tour.id,
                'stop_name': '',
                'description': '',
                'image_id': null,
                'site_id': null,
                'site_name': '',
                'location': {},
                'additional_info': '',
                'sort': tour.stops.length + 1,
                'sites': []
            }
        } else {
            stop = tour?.stops?.filter(stop => stop.id === props.stopId)[0]
        }
        return stop
    }

    const [stop, setStop] = useState(getStop());


    const saveStop = () => {
        if (stop.site_id === 'site_id') {
            toast.error('Please select a site')
        } else {
            if (props.stopId === 'NEW') {
                if (!tour.stops) tour.stops = [];
                tour.stops.push(stop);
                dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops, duration_override: false, route: null } });

            } else {
                const index = tour.stops.findIndex(s => s.id === stop.id)
                tour.stops[index] = stop
                dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
            }
            setEdited(false)
            props.onHide()
            props.setDragula && props.setDragula(uniqid())
        }
    }

    const handleSiteLocChange = async (value) => {
        let site_id = value.id
        let site_name = value.name
        let location = {} //get from site 
        if (!stop.sites) stop.sites = [];
        let siteLocations = await _api.site.getLocation(site_id)
        if (siteLocations?.length > 0) {
            let siteItem = {
                'id': `temp.${uniqid()}`,
                'tour_id': tour.id,
                'stop_id': stop.id,
                'site_id': site_id,
                'site_name': site_name,
                'highlight': false,
                'description': '',
                'location': siteLocations[0],
                'date_id': null,
                'sort': stop.sites?.length + 1
            }
            location = siteLocations[0];
            if (tour.format === 2) stop.sites.push(siteItem)
        }
        setStop({ ...stop, site_id: site_id, site_name: site_name, location: location, sites: stop.sites })
        setEdited(true)
    }

    const handleAddressSelect = async (address, placeId, suggestion) => {
        let location = {};
        let results = await geocodeByPlaceId(placeId);
        if (results.length > 0) {
            location.google_location = results[0];
            location.address = results[0].formatted_address;
            location.city = results[0].address_components.filter((x) => x.types.includes('locality'))[0]?.long_name;
            location.state = results[0].address_components.filter((x) => x.types.includes('administrative_area_level_1'))[0]?.long_name;
            location.country = results[0].address_components.filter((x) => x.types.includes('country'))[0]?.long_name;
            location.postal_code = results[0].address_components.filter((x) => x.types.includes('postal_code'))[0]?.long_name;
            location.location = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
            };
            setStop({ ...stop, location: location })
            setEdited(true)
        }
    };

    const handleAddressChange = (address) => {
        stop.location.address = address
        setStop({ ...stop, location: stop.location })
        setEdited(true)
    }

    const handleChange = (e) => {
        setStop({ ...stop, [e.target.name]: e.target.value })
        setEdited(true)
    }

    const handleLocTypeChange = (value) => {
        setStop({ ...stop, site_id: value })
        setEdited(true)
    }

    const handleEditorChange = (data) => {
        setStop({ ...stop, description: data })
        setEdited(true)
    }

    const selectFromMap = () => {
        saveStop();
        const index = tour.stops.findIndex(s => s.id === stop.id)
        history.push({ pathname: `/tour/${tour.id}/editor/tour_map_editor/location_pick/${index}` })
    }


    return (<>
        <Modal show={props.show} onHide={() => props.onHide()} size='xl' centered backdrop="static" style={{ opacity: tourAlert ? "0.8" : '1' }}>
            <Modal.Body>
                <div>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-auto'><div className='tour-stop-number prevent-select d-flex'>{stop.sort}</div></div>
                                <div className='col p-1'><label className='cnow-form-title mb-0'>{`Tour Stop ${stop.sort}`}</label></div>
                            </div>
                        </div>
                        <div className='col-auto btn-list'>
                            {(isEdited) ? <Button size='sm' color='danger' label='Save' onClick={() => saveStop()} /> : <Button size='sm' label='Save' onClick={() => saveStop()} />}
                            {(isEdited) ? <Button size='sm' icon='close' onClick={() => showtourAlert(true)} /> : <Button size='sm' icon='close' onClick={() => props.onHide()} />}
                        </div>
                    </div>
                    <hr className='cnow-hr' />
                    <div name='addStop'>
                        <div className='row g-2 py-1 mb-2'>
                            <div className='col-auto'>
                                <div className=''>&nbsp;</div>
                                <ImageViewer entityType='image' entityId={stop.image_id} type='thumb' cssClass='thumb-small' />
                            </div>
                            <div className='col'>
                                <label className='cnow-form-label fw-bold'>Stop Name*</label>
                                <input value={stop.stop_name} className='form-control' name='stop_name' placeholder='Name the stop' onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row py-1'>
                            <div className='col'>
                                <div className='row'>
                                    <label className='cnow-form-label fw-bold'>Stop Location</label>
                                </div>
                                <div className='row'>
                                    <div className='col-auto'>
                                        <div className='form-check form-check-inline'>
                                            <input type='radio' className='form-check-input' value={null} name='site_id' checked={!stop.site_id} onChange={() => handleLocTypeChange(null)} />
                                            <label className='form-check-label small'>By Address</label>
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className='form-check form-check-inline'>
                                            <input type='radio' className='form-check-input' value={'site_id'} name='site_id' checked={stop.site_id} onChange={() => handleLocTypeChange('site_id')} />
                                            <label className='form-check-label small'>By Site Location</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row g-1'>
                                    {
                                        stop.site_id ? <SelectSite value={{ id: stop.site_id, name: stop.site_name }} onChange={(value) => handleSiteLocChange(value)} /> :
                                            <>
                                                <div className='col-auto'>
                                                    <Button icon='map-location-bgblack' onClick={() => selectFromMap()} />
                                                </div>
                                                <div className='col'>
                                                    <PlacesAutocomplete value={stop.location.address} onChange={handleAddressChange} onSelect={handleAddressSelect}>
                                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                            <div>
                                                                <input {...getInputProps({ className: 'form-control' })} placeholder='Type in the address or select it on the map' />
                                                                <div className='autocomplete-container'>
                                                                    {suggestions.map((suggestion, index) => {
                                                                        return (
                                                                            <div className='suggestion-item' key={index} {...getSuggestionItemProps(suggestion)}>
                                                                                {suggestion.description}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row  py-1'>
                            <div className='col'>
                                <label className='cnow-form-label fw-bold'>Additional Location Information for this stop (ex. "in the lobby of the building" OR "Acccessible to the public from 2.00pm - 4:00pm" OR "Meet at the flagpole")</label>
                                <input value={stop.additional_info} className='form-control' placeholder='Type in here' name='additional_info' onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row  py-1'>
                            <div className='col'>
                                <TextEditor placeholder="Type in here" limit={250} toolbar={true} data={stop.description} onChange={(data) => handleEditorChange(data)}>
                                    <label className='cnow-form-label fw-bold'>About the stop (Highlights not to be missed)</label>
                                </TextEditor>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {tourAlert && <TourAlerts alertStop={saveStop} show={tourAlert} onHide={() => showtourAlert(false)} tourStopHide={props.onHide} type={'AddaStop'} heading={'SAVE THE TOUR STOP INFORMATION'} stopSort={stop.sort} />}
    </>)

}
export default StopEditor