import React from "react";
import { useSelector } from 'react-redux';
import PageTitle from "../../components/pageTitle";
import SpecialFeature from "../../components/specialFeature";
const SiteSpecialFeature = (props) => {
    const site = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={site.name} />
            <SpecialFeature id={props.id} type={'site'} />
        </>
    )
}

export default SiteSpecialFeature