import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import PeopleEditor from '../../components/editors/peopleEditor';
import RelatedPeople from '../../components/editors/relatedPeopleEditor';

const SitePeople = (props) => {
  const site = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={site?.name} />
      <PeopleEditor id={props.id} type='site' key={`person-editor-${key}`} refresh={() => { setKey(uniqid()) }} labelGlobalList='Role On Site *' title={'People/Organization/Publication'} />
      <RelatedPeople id={props.id} type='site' key={`related-person-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default SitePeople;