/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
import ResultCount from '../../../components/resultCount';
import Icon from '../../../components/viewers/icon';

const globalLists = require('../../../data/global_list.json');

const GlobalList = (props) => {
    const [items, setItems] = useState(globalLists);
    const [keyword, setKeyword] = useState('');

    const getItems = () => {
        setItems(globalLists.filter(x => x.label.includes(keyword)));
    }

    return (
        <>
            <div className='container-fluid mt-3'>
                <div className='row g-2'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light' type='text' placeholder='Search by name' value={keyword} onChange={(event) => { setKeyword(event.target.value) }} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => getItems()} />
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-3 p-0'>
                <div className='card mt-3'>
                    <div className='card-header bg-light p-2'>
                        <h4 className='mb-0'>Lists</h4>
                        <div className='small'>
                            <ResultCount page={1} take={items.length} count={items.length} />
                        </div>
                    </div>

                    <div className='table-responsive cnow-table'>
                        <table className='table table-striped table-hover table-bordered'>
                            <thead>
                                <tr className='bg-light'>
                                    <td className='cnow-col cnow-col-150'></td>
                                    <td className='cnow-col cnow-col-300'>List Item</td>
                                    <td className='cnow-col cnow-col-200'>Number of Items</td>
                                    <td className='cnow-col'>Related Item</td>
                                    <td className='cnow-col cnow-col-100'>Added By</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className='btn-list'>
                                                        <LinkButton size='sm' icon='edit' to={`/global-list/${item.id}`} />
                                                        <Button size='sm' icon='download' />
                                                    </div>
                                                </td>
                                                <td>{item.name}</td>
                                                <td>0</td>
                                                <td>
                                                    {
                                                        item.related_items.map((rel, index) => {
                                                            return (
                                                                <span className='me-2' key={index}>{rel.name}{index < item.related_items.length - 1 && ','}</span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GlobalList;