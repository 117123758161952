import React, { useState } from 'react';

const ToolTip = ({ tooltip, text }) => {
	const [isTooltipVisible, setTooltipVisible] = useState(false);

	const spanStyle = {
		display: 'inline-block',
		position: 'relative',
		cursor: 'pointer',
	};

	const tooltipStyle = {
		visibility: isTooltipVisible ? 'visible' : 'hidden',
		width: '100px',
		backgroundColor: '#333',
		color: '#fff',
		textAlign: 'center',
		borderRadius: '5px',
		padding: '5px',
		position: 'absolute',
		top: 'calc(100% + 5px)',
		left: '50%',
		transform: 'translateX(-50%)',
		opacity: isTooltipVisible ? '1' : '0',
		transition: 'opacity 0.3s, visibility 0.3s',
		fontSize: '11px'
	};

	const handleMouseEnter = () => setTooltipVisible(true);
	const handleMouseLeave = () => setTooltipVisible(false);

	return (
		<span style={spanStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			{text}
			<span style={tooltipStyle}>{tooltip}</span>
		</span>
	);
};

export default ToolTip;
