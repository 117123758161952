import React, { useState, useEffect } from 'react'
import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/spinner';
import FeaturedTitle from './title';
import SiteOfTheDay from './siteOfTheDay';
// import FeaturedForCollection from './featuredForCollection';
import AboutTheMuseum from './aboutTheMuseum';
import OnThisDayMonthYear from './onThisDayMonthYear';
import InThisPhoto from './inThisPhoto';
import WhatHappenedHere from './whatHappenedHere';
import People from './people';
import Podcast from './podcast';
import CNowMaps from './cNowMaps';
import Tours from './tours';
import UpComingEvents from './upComingEvents';
import StaticImages from './staticImages';
import CulturenowCollectionSite from './culturenowCollectionSite';
import CollapsableSection from '../../components/collapsableSection';
import FeaturedForCollection from './featuredForCollection';

const _api = require('../../api');
const Index = () => {
    const featured = useSelector((state) => state.featured);
    const start_date = featured && featured.start_date && JSON.parse(featured.start_date)
    const end_date = featured && featured.end_date && JSON.parse(featured.end_date)
    const start = featured && featured.start_date;
    const end = featured && featured.end_date;
    const dispatch = useDispatch();
    const [spinner, showSpinner] = useState(false);
    const [menu] = useState(require('../../data/menu_featured.json'))
    const [count, setCount] = useState({})
    useEffect(() => {
        if (featured?.start_date && featured?.end_date && start_date && end_date && (start_date === end_date)) getData()
        if (featured?.start_date && featured?.end_date && start_date && end_date && (start_date !== end_date)) dispatch({ type: 'featured', payload: { start_date: start, end_date: end, source: '' } }); setCount({ 'featured': 0, 'culturenow collection': 0, 'static images': 0 })
        // eslint-disable-next-line 
    }, [featured?.start_date, featured?.end_date, start_date, end_date])
    const calculateSectionCount = (data) => {
        let countValues = {}
        for (let i in menu) {
            let count = 0
            for (let j in menu[i].data) {
                if (data && data[menu[i].data[j].path]?.length > 0) count = count + 1
            }
            countValues[menu[i].title] = count
        }
        setCount(countValues)
    }
    const getData = async () => {
        showSpinner(true);
        let params = {
            "section": '',
            "start_date": start_date.slice(0, 10),
            "end_date": end_date.slice(0, 10)
        }
        let response = await _api.featured.getFeatured(params)
        let data = groupBy(response, response => response.section)
        data.start_date = start
        data.end_date = end
        data.source = ''
        dispatch({ type: 'featured', payload: { ...data } })
        calculateSectionCount(data)
        showSpinner(false);
    }
    return (
        <Spinner display={spinner}>
            <FeaturedTitle title={'Featured Sites on the Landing Page Preview'} />
            <hr className={`cnow-hr `} />
            <CollapsableSection collapsable={true} title="FEATURED" count={count?.featured > 1 ? `${count?.featured} items` : `${count?.featured} item`} disable={count?.featured === 0 ? true : false} key={"FEATURED"} >
                {featured?.site_of_the_day?.length > 0 && <SiteOfTheDay page={'home'} />}
                {featured?.featured_for_collection?.length > 0 && <FeaturedForCollection page={'home'} />}
                {featured?.about_the_museum_without_walls?.length > 0 && <AboutTheMuseum page={'home'} />}
                {featured?.on_this_day?.length > 0 && <OnThisDayMonthYear page={'home'} />}
                {featured?.in_this_photo?.length > 0 && <InThisPhoto page={'home'} />}
                {featured?.what_happened_here?.length > 0 && <WhatHappenedHere page={'home'} />}
            </CollapsableSection>

            <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" key={"WHATS IN THE COLLECTION"} count={count?.[`culturenow collection`] > 1 ? `${count[`culturenow collection`]} items` : `${count[`culturenow collection`]} item`} disable={count?.[`culturenow collection`] === 0 ? true : false} >
                {featured?.public_art?.length > 0 && <CulturenowCollectionSite section={'public_art'} page={'home'} />}
                {featured?.architecture?.length > 0 && <CulturenowCollectionSite section={'architecture'} page={'home'} />}
                {featured?.history?.length > 0 && <CulturenowCollectionSite section={'history'} page={'home'} />}
                {featured?.people?.length > 0 && <People page={'home'} />}
                {featured?.podcast?.length > 0 && <Podcast page={'home'} />}
                {featured?.places_over_time?.length > 0 && <CulturenowCollectionSite section={'places_over_time'} page={'home'} />}
                {featured?.cnow_maps?.length > 0 && <CNowMaps page={'home'} />}
                {featured?.tours?.length > 0 && <Tours page={'home'} />}
                {featured?.upcoming_events?.length > 0 && <UpComingEvents page={'home'} />}
                {featured?.what_is_around?.length > 0 && <CulturenowCollectionSite section={'what_is_around'} page={'home'} />}
            </CollapsableSection>

            <CollapsableSection collapsable={true} title="STATIC IMAGES" key={"STATIC IMAGES"} count={count?.[`static images`] > 1 ? `${count[`static images`]} items` : `${count[`static images`]} item`} disable={count?.[`static images`] === 0 ? true : false}>
                {featured?.image_search_for_app?.length > 0 && <StaticImages section={'image_search_for_app'} page={'home'} />}
                {featured?.curate_your_own_collection?.length > 0 && <StaticImages section={'curate_your_own_collection'} page={'home'} />}
                {featured?.contribute_content?.length > 0 && <StaticImages section={'contribute_content'} page={'home'} />}
                {featured?.join_the_community?.length > 0 && <StaticImages section={'join_the_community'} page={'home'} />}
                {featured?.donate?.length > 0 && <StaticImages section={'donate'} page={'home'} />}
            </CollapsableSection>
        </Spinner>
    )
}

export default Index