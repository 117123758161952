/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import Icon from '../../components/viewers/icon';
import TableHeader from '../../components/tableHeader';
import LinkButton from '../../components/buttons/link';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import PreviewButton from '../../components/buttons/preview';
import swal from 'sweetalert';
import RelatedPeopleList from '../../components/table/relatedPeopleList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import LocationList from '../../components/table/locationList';
import EventType from '../../components/table/eventType';
import EventPeopleList from '../../components/table/eventPeopleList';
import { TABLE_ROWS, changeUTC, checkUserTypeAndReturnIds } from '../../helpers';
import EventTicketPrice from '../../components/table/eventTicketPrice';
import EventReservation from '../../components/table/eventReservation';
import EventEducation from '../../components/table/eventEducation';
import TagList from '../../components/table/tagList';
import LongTextView from '../../components/viewers/longText';
import PodcastList from '../../components/table/podcastList';
import PlayList from '../../components/table/playlist';
import RelatedEventSeriesList from '../../components/table/relatedEventSeries';
import RelatedRecipeList from '../../components/table/relatedRecipeList';
import RelatedEventList from '../../components/table/relatedEvent';
import RelatedTourList from '../../components/table/relatedTourList';
import RelatedMapList from '../../components/table/relatedMapList';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { groupBy, uniqBy } from 'lodash';
const _api = require('../../api');
const columns = require('../../data/columns_event.json');

const Events = (props) => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const [searchResult, setSearchResult] = useState({
        dates: [],
        results: {}
    });
    const [pagination, setPagination] = useState(null)
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);
    const [label, setLabel] = useState([])
    const [resultFilters, setResultFilters] = useState({
        past_event: false,
        future_event: true,
    })
    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        search()
    }, [page, keyword, user.id]);

    const search = async () => {
        showSpinner(true)
        setSearchResult({
            dates: [],
            results: {},
            total: {}
        })
        let sortBoolean = true
        let scoreBoolean = false
        const params = {
            "bool": {
                "must": [
                    {
                        "match": {
                            "deleted": "false"
                        }
                    },
                    {
                        "match": {
                            "is_parent": "true"
                        }
                    },
                    { "match": { "created_via": "ADMIN" } },

                ]
            }
        };
        handleSetKeywords(keyword)
        if (keyword) {
            sortBoolean = false
            scoreBoolean = true

            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^5",
                        "participants.person_name",
                        "alias",
                        "participants.person_first_name",
                        "participants.person_last_name",
                        "hosts.person_name",
                        "locations.name",
                        "locations.city",
                        "locations.state",
                        "locations.country",
                        "categories.category_name",
                        "categories.subcategory_name",
                        "types.type_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_people.related_person_name",
                        "related_people.person_name",
                        "podcasts.podcast_name",
                        "participants.role_name",
                        "people.profession.profession_name",
                        "related_people.org_type.organization_name",
                        "locations.address"
                    ],
                    "fuzziness": "AUTO",
                    "prefix_length": 2
                }
            });
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }
        if (scoreBoolean) {
            finalParams.sort = [
                { "_score": { "order": "desc" } }
            ]
        }

        if (resultFilters?.future_event || resultFilters?.past_event) {
            if (resultFilters?.future_event) {


                params.bool.must.push({
                    "range": {
                        "occurring_dates.occurrence": {
                            "gte": moment().unix()
                        }
                    }
                })
                await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                    results.hits.hits.forEach((elem) => elem.start_date = elem?._source?.dates?.length > 0 ? moment(elem?._source.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                    setPagination(results.nbHits)
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            dates: uniqBy(results.hits.hits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`)

                        };
                    });

                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            results: groupBy(results.hits.hits, obj => `${obj.start_date}`)

                        };
                    });

                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            total: results.hits.total

                        };
                    });
                    showSpinner(false)
                })
            }
            if (resultFilters?.past_event) {

                params.bool.must.push({
                    "range": {
                        "occurring_dates.occurrence": {
                            "lte": moment().unix()
                        }
                    }
                })
                await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                    setPagination(results.nbHits)

                    results.hits.hits.forEach((elem) => elem.start_date = elem?._source?.dates?.length > 0 ? moment(elem?._source?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            dates: uniqBy(results.hits.hits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`)

                        };
                    });
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            results: groupBy(results.hits.hits, obj => `${obj.start_date}`)

                        };
                    });
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            total: results.hits.total

                        };
                    });

                    showSpinner(false)
                })
            }

        }
        else {
            await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                results.hits.hits.forEach((elem) => elem.start_date = elem?._source?.dates?.length > 0 ? moment(elem?._source?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                // setPagination(results.nbHits)
                setSearchResult((prev) => {
                    return {
                        ...prev,
                        dates: uniqBy(results.hits.hits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`)

                    };
                });
                setSearchResult((prev) => {
                    return {
                        ...prev,
                        results: groupBy(results.hits.hits, obj => `${obj?.start_date}`)

                    };
                });
                setSearchResult((prev) => {
                    return {
                        ...prev,
                        total: results.hits.total

                    };
                });
                showSpinner(false)
            })
        }
    }

    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.past_event) data.push(`Past - Events`)
        if (resultFilters?.future_event) data.push(`Future - Events`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Past - Events')) {
            resultFilters.past_event = false
        }
        if (data.includes('Future - Events')) {
            resultFilters.future_event = false
        }
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const handleSortInformation = (column) => {
        if (column?.name === 'Future Events') {
            resultFilters.future_event = true
            resultFilters.past_event = false
        }
        if (column?.name === 'Past Events') {
            resultFilters.past_event = true
            resultFilters.future_event = false
        }
        setResultFilters(resultFilters)
        search()
    }

    const handleDelete = (event) => {
        swal({
            title: `Delete Event`,
            text: `Are you sure that you want to delete ${event?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.event.deleteById(event.id).then(() => { search() })
        });
    }
    const updateLive = (event, live) => {
        showSpinner(true)
        // let selectedSearchResult = Object.values(searchResult?.results || {}).flat().filter((item) => item?.id === id);
        // let selectedResult = selectedSearchResult[0]
        if (live === true) {
            _api.event.updateLive(event?.id, live).then((_) => search())
            swal({
                title: 'Event is Live!',
                icon: 'success',
                text: `${event?.name} is now Live!`,
                buttons: 'Ok',
            })
        } else {
            _api.event.updateLive(event?.id, live).then((_) => search())
            swal({
                title: 'Not Live!',
                icon: 'info',
                text: `${event?.name} is Not Live.`,
                buttons: 'Ok',
            })
        }
        showSpinner(false)
    }

    function compareDates(a, b) {
        if (resultFilters.future_event) {
            const dateA = new Date(a);
            const dateB = new Date(b);

            if (dateA.getMonth() !== dateB.getMonth()) {
                return dateA.getMonth() - dateB.getMonth();
            }

            return dateA.getDate() - dateB.getDate();
        }
        if (resultFilters.past_event) {
            const dateA = new Date(a);
            const dateB = new Date(b);

            if (dateA.getFullYear() !== dateB.getFullYear()) {
                return dateB.getFullYear() - dateA.getFullYear(); // Reverse order for years
            }

            if (dateA.getMonth() !== dateB.getMonth()) {
                return dateB.getMonth() - dateA.getMonth(); // Reverse order for months
            }

            return dateB.getDate() - dateA.getDate(); // Reverse order for days
        }
    }
    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add an Event' color='danger' icon='plus-white' onClick={() => history.push('/event/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0'>Events</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search' key={`icon-${index}`}>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => { setSort(column); handleSortInformation(column) }}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchResult && searchResult.dates.length > 0 && searchResult.dates.sort(compareDates).map((date_month, index) => {
                                            return (

                                                <>
                                                    {(resultFilters.future_event || resultFilters.past_event) && <tr className={resultFilters.future_event === true ? `cnow-bg-red` : 'bg-dark'}>
                                                        <td colSpan={25}>
                                                            <div className='d-flex'>
                                                                <div className={`fw-bold ps-2 text-white text-uppercase`} style={{ width: '92vw' }}>{date_month}</div>
                                                            </div>
                                                        </td>

                                                    </tr>}
                                                    {searchResult.results && searchResult.results[date_month]?.length > 0 && searchResult.results[date_month].map((item, index) => {
                                                        return (
                                                            <>

                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='row g-1'>
                                                                            <div className='col-auto'>
                                                                                <div className='form-check mt-1'>
                                                                                    <input className='form-check-input align-middle' type='checkbox' name='select_event' value={item?._source.id} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-auto'>
                                                                                <div className='btn-list'>
                                                                                    <LinkButton size='sm' icon='edit' to={`/event/${item?._source.id}/editor`} />
                                                                                    <Button size='sm' icon='delete' onClick={() => { handleDelete(item?._source) }} />
                                                                                    <PreviewButton size='sm' link={`event/${item?._source?.vanity_url || item?._source?.id}`} />
                                                                                    <Button size='live'
                                                                                        icon={item?._source.live === true ? 'live' : 'not-live'}
                                                                                        color={item?._source.live === true ? '' : 'danger'}
                                                                                        onClick={() => { updateLive(item?._source, item?._source.live === true ? false : true) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ImageViewer url={item?._source.image?.url} type='thumb' cssClass='thumb' />
                                                                    </td>
                                                                    <td className='fw-bold'>{item?._source?.name}</td>
                                                                    <td>{changeUTC(item?._source?.dates?.[0]?.start_date)}</td>
                                                                    <EventType data={item?._source?.types} />
                                                                    <EventPeopleList host={item?._source?.hosts} sponsor={item?._source?.sponsors} other={item?._source?.participants} />
                                                                    <LocationList data={item?._source?.locations} />
                                                                    <td></td>
                                                                    <EventTicketPrice data={item?._source?.tickets} />
                                                                    <EventReservation data={item?._source?.reservations} />
                                                                    <td></td>
                                                                    <EventEducation data={item?._source?.continuing_education} />
                                                                    <td></td>
                                                                    <td>{item?._source?.id}</td>
                                                                    <TagList data={item?._source.tags} />
                                                                    <td>
                                                                        <LongTextView lines={TABLE_ROWS + 1}>{item?._source.description || (item?._source?.dates && item?._source?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                                    </td>
                                                                    <PodcastList data={item?._source?.podcasts} />
                                                                    <PlayList data={item?._source?.related_playlists} />
                                                                    <RelatedEventSeriesList data={item?._source?.related_event_series} />
                                                                    <RelatedRecipeList data={item?._source?.recipes} />
                                                                    <RelatedSiteList data={item?._source?.related_sites} />
                                                                    <RelatedPeopleList data={item?._source?.related_people} />
                                                                    <RelatedEventList data={item?._source?.related_events} />
                                                                    <RelatedTourList data={item?._source?.related_tours} />
                                                                    <RelatedMapList data={item?._source?.related_maps} />
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })
                                    }
                                </tbody >
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default Events;