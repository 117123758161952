import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/buttons/default';
import PreviewButton from '../../../components/buttons/preview';
// import Name from '../../components/preview/name';
// import Description from '../../components/preview/description';
// import EventList from '../../components/preview/eventList';
// import TourList from '../../components/preview/tourList';
// import EventHost from '../../components/preview/eventHost';
// import EventSponsor from '../../components/preview/eventSponsor';
// import EventEducation from '../../components/preview/eventEducation';
// import MapsList from '../../components/preview/mapsList';
// import MetaDescription from '../../components/preview/metaDescription';
// import EventReservationTicket from '../../components/preview/eventReservationTicket';
// import EventTicketPrice from '../../components/preview/eventTicketPrice';
// import TagList from '../../components/preview/tagList';
// import { locationName } from '../../helpers';
// import LocationList from '../../components/preview/locationList';
// import EventMap from '../../components/preview/eventMap';
// import EventImages from '../../components/preview/eventImages';
// import StoryList from '../../components/preview/storyList';
import swal from 'sweetalert'
// import { chain } from "lodash";
// import EventDate from '../../components/preview/eventDates';
// import EventPerson from '../../components/preview/eventPerson';
// import EventPodcast from '../../components/preview/eventPodcast';
// import EventRecipe from '../../components/preview/eventRecipe';
// import SiteCard from '../../components/preview/siteCard';
// import MapCard from '../../components/preview/mapCard';
// import About from '../../components/preview/about';
const _api = require('../../../api');

const UserPreview = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);
    const previewSize = useSelector((state) => state.preview);
    // let participants = event?.participants ? event?.participants?.filter(element => element.is_primary === true) : []
    // let participant = chain([...participants]).groupBy("role_name").map((value, key) => ({ role_name: key, people: value })).value()
    // let otherParticipants = event?.participants?.filter(element => element.is_primary === false)
    // let featured_projects = event?.featured_projects ? event?.featured_projects.filter(element => element.featured === true) : []
    // let featureds = chain([...featured_projects]).groupBy("person_name").map((value, key) => ({ person_name: key, sites: value })).value()

    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

    const handleLive = (eventId) => {
        if (event.id === 'NEW') {
            swal({
                title: 'Event cannot go Live.',
                icon: 'error',
                text: `${event?.id === 'NEW' && 'Fill the mandatory fields * to make the Event go live.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
        }
        else if (
            event?.name &&
            event?.types &&
            event?.image_id
        ) {
            swal({
                title: 'Event is Live!',
                icon: 'success',
                text: `${event.name} is now Live.`,
                buttons: 'Ok',
            })
            _api.event.updateLive(eventId, true).then(() =>
                dispatch({ type: 'entity', payload: { ...event, live: true } })
            )
        } else {
            swal({
                title: 'Event cannot go Live.',
                icon: 'error',
                text: `${'Please fill the mandatory fields:'}
					${event?.name ? '' : 'Title is missing.'}
                    ${event?.types ? '' : 'Type is missing.'}
					${event?.image_id ? '' : 'Image is missing.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
            _api.event.updateLive(eventId, false).then(() =>
                dispatch({ type: 'entity', payload: { ...event, live: false } })
            )
        }
    }

    const handleNotLive = (eventId) => {
        _api.event.updateLive(eventId, false).then(() =>
            dispatch({ type: 'entity', payload: { ...event, live: false } })
        )
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${event.name} is now Not Live.`,
            buttons: 'Ok',
        })
    }

    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`event/${event?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {event?.live ? (<Button size='sm' color='light' label='Live' onClick={() => { handleNotLive(event.id) }} />)
                            : (<Button size='sm' color='danger' label='Not Live' onClick={() => { handleLive(event.id) }} />)}
                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`event/${event?.id}`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>

            {/* {previewSize === 'lg' && <div className='preview'>
                {
                    props.section !== 'location' && props.section !== 'attach_map' && props.section !== 'meta' && props.section !== 'images' && props.section !== 'people' && props.section !== 'special_features' && props.section !== 'featured_projects' && <>
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        {participant.map((item, index) => {
                            return (
                                <>
                                    <div className='cnow-form-title '>{item?.role_name}</div>
                                    <EventPerson data={item?.people} />
                                </>
                            )
                        })}

                        {otherParticipants?.length > 0 &&
                            <> <div className='cnow-form-title '>Other Participants</div>
                                <EventPerson data={otherParticipants} />
                            </>}

                        {event?.description?.length > 0 && <>
                            <About data={event?.description} story={event?.stories} />

                        </>}
                        <EventPodcast data={event?.podcasts} />
                        {event?.recipes?.map((item, index) => { return (<EventRecipe data={item} />) })}

                        {featureds?.length > 0 && <>
                            <div className='cnow-preview-title fw-bold'>Featured Projects</div>
                            {featureds?.map((item, index) => {
                                return (
                                    <>
                                        <div className='cnow-form-title'>{`Projects by ${item?.person_name}`}</div>
                                        <SiteCard data={item?.sites} />
                                    </>
                                )
                            })}
                        </>
                        }
                        {event?.related_maps?.length > 0 && <>
                            <div className='cnow-preview-title fw-bold'>Historic Maps</div>
                            {event?.related_maps?.map((item, index) => { return (<MapCard data={item} />) })}
                        </>
                        }

                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        <Name data={event?.name} />
                        <div> {locationName(event?.locations?.[0])}</div>
                        <div className='d-flex'>
                            {event?.type?.length > 0 && event?.types?.map((type, index) => {
                                return (<div className='small text-muted d-flex'>{type?.type_name}{!(index === event?.types?.length - 1) ? "," : ""}</div>)
                            })}
                        </div>
                        <EventDate data={event?.dates} title={event?.name} location={locationName(event?.locations?.[0])} description={event?.description} />
                        <Description data={event?.description} />
                        <EventEducation data={event?.continuing_education} />
                        <EventTicketPrice data={event?.tickets} />
                        <EventReservationTicket data={event?.reservations} />
                        <EventHost data={event?.hosts} title={"Hosted by"} />
                        <EventSponsor data={event?.sponsors} />
                        <StoryList data={event?.stories} />
                        <EventList data={event?.related_events} />
                        <TourList data={event?.related_tours} />
                        <EventMap id={props?.id} type={'event'} />
                        <TagList tags={event?.tags} themes={event?.themes} categories={event?.categories} entity={'event'} />

                    </>
                }
                {event && props.section === 'people' &&
                    <>
                        {participant.map((item, index) => {
                            return (
                                <>
                                    <div className='cnow-form-title '>{item?.role_name}</div>
                                    <EventPerson data={item?.people} />
                                </>)
                        })}
                        <div className='cnow-form-title '>Other Participants</div>
                        <EventPerson data={otherParticipants} />
                    </>
                }


                {event && props.section === 'location' && !props.locationId && <EventMap id={props?.id} type={'event'} height='70vh' column='flex-column-reverse' />}
                {event && props.section === 'location' && props.locationId && <LocationList id={event.id} height='70vh' locationId={props.locationId} type={'event'} column='flex-column-reverse' />}
                {event && props.section === 'attach_map' &&
                    <>
                        <MapsList id={event.id} type='event' />
                        <EventMap id={props?.id} height='70vh' type={'event'} />

                    </>
                }

                {event && props.section === 'special_features' && <>
                    {event?.recipes?.map((item, index) => { return (<EventRecipe data={item} />) })}
                </>
                }

                {event && props.section === 'featured_projects' && <>
                    {featureds?.length > 0 && <>
                        <div className='cnow-preview-title fw-bold'>Featured Projects</div>
                        {featureds?.map((item, index) => {
                            return (
                                <>
                                    <div className='cnow-form-title'>{`Projects by ${item?.person_name}`}</div>
                                    <SiteCard data={item?.sites} />
                                </>
                            )
                        })}
                    </>
                    }
                </>
                }
                {event && props.section === 'images' &&
                    <EventImages id={event?.id} type="event" />}
                {event && props.section === 'meta' && <MetaDescription data={event?.meta} id={event?.id} type='event' />}
            </div>} */}
        </>
    );
};

export default UserPreview;