/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import { useHistory } from 'react-router-dom';
import { customSelectStyle } from '../../helpers';
import RelatedEventImage from './RelatedEventImage';
import bulkOptions from '../../data/bulk_options.json'
import BulkImageEditor from '../site/bulkImageEditor';
import ArrangeImagesList from '../../components/arrangeImagesList';
import Select from 'react-select';
const _api = require('../../api')
const EventSeriesImage = (props) => {

    const dispatch = useDispatch()
    const event = useSelector(state => state[props.id])
    const [bulkEditorType, setBulkEditorType] = useState()
    const [showBulkEditor, setShowBulkEditor] = useState(false)
    const [selectedImages, handleSelectItem] = useState([])

    const location = useLocation()
    const history = useHistory()

    let currentImages = event ? event.images?.filter(x => x.type === 'image' && x.date_id === null) : []
    let currentDocuments = event ? event.images?.filter(x => x.type === 'document' && x.date_id === null) : []
    let imageRecognition = event ? event?.images?.filter(x => x.type === 'image-recognition') : []
    const filteredOptions = bulkOptions.filter(option => option.value !== "image-recognition");

    const handleOnHide = async () => {
        setBulkEditorType();
        //to revert back user changes
        event.images = await _api.event_series.getImage(props.id)
        dispatch({ type: 'entity', payload: { ...event, image: event.images } });
        setShowBulkEditor(false);
    }

    const editItems = (id) => {
        let imagesList = event.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: event.id, entityType: 'event_series' } })
        history.push(`/image/${id}/banner`)
    }

    return (
        <>
            {
                event && <>
                    <PageTitle title={event?.name} />

                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(event.image_id)} className='cursor-pointer'>
                                        <ImageViewer
                                            entityId={event?.image_id}
                                            entityType={'image'}
                                            type='banner'
                                            cssClass='cnow-image-editor-banner'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(event.image_id)} disabled={event.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='bulk-edit' className='row g-1 align-items-start mb-2 justify-content-between'>
                        <div className='col-3'>
                            <div className='cnow-form-title'></div>
                        </div>
                        <div className='col'>
                            <div className='row g-1 align-items-end'>
                                <div className='col-auto'>
                                </div>
                                <div className='col'>
                                </div>

                                <div className='col-4'>
                                    <label className='small fw-bold fst-normal'>Bulk Editing</label>
                                    <Select styles={customSelectStyle} options={filteredOptions} onChange={(item) => { setBulkEditorType(item); setShowBulkEditor(true) }} />

                                    {
                                        (bulkEditorType?.value === 'image-type' || bulkEditorType?.value === 'image-credit' || bulkEditorType?.value === 'image-date' || bulkEditorType?.value === 'another-section' || bulkEditorType?.value === 'delete' || bulkEditorType?.value === 'image-recognition') &&
                                        <BulkImageEditor
                                            id={event?.id}
                                            show={showBulkEditor}
                                            type={bulkEditorType}
                                            items={selectedImages}
                                            onHide={() => handleOnHide()}
                                            entityType={'event_series'}
                                        />
                                    }

                                    {
                                        bulkEditorType?.value === 'rearrange' &&
                                        <ArrangeImagesList id={event?.id}
                                            show={showBulkEditor}
                                            type={bulkEditorType}
                                            items={selectedImages}
                                            section='event_series'
                                            entityType='event_series'
                                            onHide={() => handleOnHide()}
                                        />
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div id='entity-image-sort'>

                        {
                            <EntityImages title={`Images *`} items={currentImages}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='image'
                                entityType='0'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={0}
                            />
                        }

                        {
                            <EntityImages title={`Drawing and Documents`} items={currentDocuments}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='document'
                                entityType='event_series'
                                entityId={event.id}
                                buttonLabel={'Add a Document'}
                                offsetIndex={0}
                            />
                        }

                        {
                            <EntityImages title={`Image Recognition *`} items={imageRecognition}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='image-recognition'
                                entityType='event_series'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={currentImages?.length + currentDocuments?.length}
                            />
                        }

                        <label className='cnow-form-title pt-3'>{`Related Events`}</label>
                        <RelatedEventImage id={props?.id} type='site' items={event?.related_event} title={'Site Title'} />


                    </div>

                </>
            }
        </>
    )
}

export default EventSeriesImage 