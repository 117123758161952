import React from "react";

const MapAddressList = (props) => {
    let locations = props.locations
    const handleMapRedirect = () => {
        if (props.type) window.open(`${process.env.REACT_APP_WEB_URL}/historic-map/${props.type}/${props.id}`, "_blank");
    }
    return (

        <>
            <div className='cursor-pointer' onClick={() => { handleMapRedirect() }}>
                <hr />
                <div className={'fw-bold fst-italic'}>{'Address'}</div>
                {
                    locations.map((location, index) => {
                        return (
                            <div key={index} className='mt-2' >
                                <span className='text-muted'>{location.city}, </span>
                                <span className='text-muted'>{location.state}</span>
                                <div className='text-muted'>{location.description}</div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default MapAddressList