import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Button from '../../buttons/default';
import { handleApiError } from '../../../helpers';
import Spinner from '../../../components/spinner';
import { toast } from 'react-toastify';
const _api = require('../../../api')
const EditImage = (props) => {
    const [crop, setCrop] = useState({});
    const [aspectRatio, setAspect] = useState();
    const [spinner, showSpinner] = useState(false);
    const [scale, setScale] = useState({ scaleX: '', scaleY: '' });
    const [region, setRegion] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const featured = useSelector((state) => state.featured);
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    useEffect(() => {
        if (props.type === 'thumb') setAspect(1.25)
        if (props.type === 'banner') setAspect(3.2)
        if (props.type === 'headshot') setAspect(1 / 2)
        if (props.type === 'featured') setAspect(1.85)
    }, [props.type]);

    const handleLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = img;
        // if (props.type === 'web') setAspect(naturalWidth / naturalHeight)
        setScale({ scaleX: naturalHeight / offsetHeight, scaleY: naturalWidth / offsetWidth })
    };

    const cropImage = async (crop) => {
        setRegion({
            left: parseInt(crop.x * scale.scaleX),
            top: parseInt(crop.y * scale.scaleY),
            width: parseInt(crop.width * scale.scaleY),
            height: parseInt(crop.height * scale.scaleX)
        });
    };

    const handleSave = () => {
        if (region?.width) {
            showSpinner(true)
            _api.image.process({
                'id': props.id,
                'type': props.type,
                'region': region
            }).then(response => {
                toast.success('Your image has been cropped')
                showSpinner(false)
            }).catch(handleApiError);
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide()} className='modal-image-crop' backdrop="static">
            <Modal.Body className='h-100'>
                <div className='banner-head mx-5'>
                    <div className='d-flex justify-content-between align-items-end mb-2' >
                        <div className='cnow-form-title cnow-text-primary text-capitalize'>{`${props?.title}`}</div>
                        <div className='d-flex gap-2'>
                            <Spinner display={spinner}>
                                <div className='my-auto text-dark fw-bold text-capitalize'>{`${start_date === end_date ? start : `${start} - ${end}`} ${props.type}`}  </div>
                                <Button size='sm' label='Save' color='danger' onClick={() => { handleSave() }} />
                                <Button size='sm' icon='close' target='_self' onClick={() => props.onHide()} />
                            </Spinner>
                        </div>
                    </div>
                    <hr className='cnow-hr' />
                </div>

                <div id='image_tagger' className='cnow-image-resize-container text-center mt-2'>
                    <ReactCrop
                        crop={crop}
                        onChange={crop => setCrop(crop)}
                        onComplete={crop => cropImage(crop)}
                        aspect={aspectRatio}
                        ruleOfThirds
                    >
                        <img alt="crop" src={props.url} onLoad={handleLoad} />
                    </ReactCrop>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditImage