import React from "react";
import { useSelector } from 'react-redux';
import PageTitle from "../../components/pageTitle";
import SpecialFeature from "../../components/specialFeature";
const EventSpecialFeature = (props) => {
    const event = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={event.name} />
            <SpecialFeature id={props.id} type={'event'} />
        </>
    )
}

export default EventSpecialFeature