import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/default';
import InlineImageList from '../../components/inlineImageList';
import { customSelectStyle } from '../../helpers';
import SelectGlobalList from '../../components/selects/globalList';
import SelectDate from '../../components/selects/date';
import Select from 'react-select';
import SelectPerson from '../../components/selects/person';
import ImageCategoryList from '../../components/selects/entityCategoryList';
import LinkButton from '../../components/buttons/link'
import swal from 'sweetalert';

const _api = require('../../api')

function BulkImageEditor(props) {


    const entity = useSelector(state => state[props.id]);
    const dispatch = useDispatch()

    let [images, setImages] = useState([])

    const [bulkEditorType, setBulkEditorType] = useState('')
    const [imageType, setImageType] = useState()
    const [imageDate, setImageDate] = useState(null)
    const [credits, setCredits] = useState([])



    const imageTypeOptions = () => {
        let options = []
        if (props.entityType === 'site') {
            options.push({
                'value': 'image',
                'label': 'Images',
                'type': 'image'
            })

            entity.dates.forEach((date) => {
                options.push({
                    'value': `${date.id}-image`,
                    'label': `Images by date - ${date.event_type_name} ${date?.year}`,
                    'type': 'image'
                })
            })

            options.push({
                'value': `document`,
                'label': `Drawings and Documents`,
                'type': 'document'
            })
            entity.dates.forEach((date) => {
                options.push({
                    'value': `${date.id}-document`,
                    'label': `Documents by date - ${date.event_type_name} ${date?.year}`,
                    'type': 'document'
                })
            })

            options.push({
                'value': 'image-recognition',
                'label': 'Image Recognition',
                'type': 'image-recognition'
            })
            return options
        }
        else if (props.entityType === 'event' || props.entityType === 'event_series') {
            options.push({
                'value': `document`,
                'label': `Other Drawings`,
                'type': 'document'
            })
            options.push({
                'value': 'image',
                'label': 'Other Photos',
                'type': 'image'
            })
            return options
        }
        else {
            options.push({
                'value': `document`,
                'label': `Other Drawings`,
                'type': 'document'
            })
            options.push({
                'value': 'image',
                'label': 'Other Photos',
                'type': 'image'
            })
            options.push({
                'value': 'image-recognition',
                'label': 'Image Recognition',
                'type': 'image-recognition'
            })
            return options
        }

    }


    useEffect(() => {
        setBulkEditorType(props.type.value ? props.type.value : '')
    }, [props.type.value])

    const handleUpdateImageType = async () => {
        if (imageType) {

            let selectedImagesId = []
            props.items.forEach(item => {
                selectedImagesId.push(item)
            })
            let udpatedImageType = {}
            udpatedImageType.id = selectedImagesId
            udpatedImageType.category_id = imageType.id
            await _api.image.updateImagesBulk(udpatedImageType)
            toast.success('Success!', { hideProgressBar: true })
        }
        else toast.warning('Select the Image-Type from the dropdown.', { hideProgressBar: true })
    }


    const addImageCredit = () => {
        let copyOfCredits = [...credits]
        copyOfCredits.push({
            'id': null,
            'person_id': null,
            'person_name': '',
            'role_name': '',
            'role_id': ''
        });
        setCredits(copyOfCredits)
    }

    const handleChange = (type, index, value) => {
        let copyOfCredits = [...credits]
        if (type === 'person') {
            copyOfCredits[index].person_name = value.name
            copyOfCredits[index].person_id = value.id

        }
        if (type === 'role') {
            copyOfCredits[index].role_id = value.id
            copyOfCredits[index].role_name = value.name
        }
        setCredits(copyOfCredits)
    }

    const handleSaveCredit = async () => {

        if (!credits.length) toast.warning('Please select the Credits for the selected images')
        else {

            let tempCredits = []
            for (const img_id of props.items) {
                for (const item of credits) {
                    let newCredit = {}
                    newCredit.id = img_id
                    newCredit.person_id = item.person_id
                    newCredit.role_id = item.role_id

                    tempCredits.push(newCredit)
                }
            }
            await _api.image.udpateImagesCreditsBulk(tempCredits)
            setCredits([])
            toast.success('Success!', { hideProgressBar: true })
        }
    }


    const handleDeletePerson = (index) => {
        let copyOfCredits = [...credits]
        copyOfCredits.splice(index, 1)
        setCredits(copyOfCredits)

    }

    const handleTakenDateChange = async () => {
        if (imageDate) {

            let selectedImagesId = []
            props.items.forEach(item => {
                selectedImagesId.push(item)
            })
            let udpatedImageTakenDate = {}
            udpatedImageTakenDate.id = selectedImagesId
            udpatedImageTakenDate.taken_date = imageDate
            await _api.image.updateImagesBulk(udpatedImageTakenDate)
            toast.success('Success!', { hideProgressBar: true })
        }
        else toast.warning('Please select the Taken Date for the selected images.')
    }

    const handleMoveToImageRecognition = async () => {
        props.items.forEach((item) => {
            let index = entity.images.findIndex((image) => image?.image_id === item);
            entity.images[index].type = 'image-recognition';
        })
        entity.images.forEach((image, index) => image.sort = index + 1);
        toast.success('Success!', { hideProgressBar: true })
        entity.images = await _api[props.entityType].updateImage(props.id, entity.images)
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
        props.onHide && props.onHide()

    }

    const handleMoveImages = (option) => {
        if (option.type === 'image') {
            props.items.forEach((item) => {
                let index = entity.images.findIndex((image) => image?.image_id === item);


                entity.images[index].date_id = (option.value === 'image' ? null : option.value.replace('-image', ''))
                entity.images[index].type = 'image';
            })
        }
        else if (option.type === 'document') {
            props.items.forEach((item) => {
                let index = entity.images.findIndex((image) => image?.image_id === item);
                entity.images[index].date_id = (option.value === 'document' ? null : option.value.replace('-document', ''))
                entity.images[index].type = 'document';
            })
        }
        else if (option.type === 'image-recognition') {
            props.items.forEach((item) => {
                let index = entity.images.findIndex((image) => image?.image_id === item);
                entity.images[index].date_id = (option.value === 'image-recognition' ? null : option.value.replace('-image-recognition', ''))
                entity.images[index].type = 'image-recognition';
            })
        }
        //update Sort
        entity.images.forEach((image, index) => image.sort = index + 1);

        setImages(entity.images)
    }

    const handleSaveMovedImages = async () => {
        toast.success('Success!', { hideProgressBar: true })

        entity.images = await _api[props.entityType].updateImage(props.id, images)

        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
        props.update && props.update()
        props.onHide && props.onHide()
    }

    const handleDelete = () => {
        swal({
            title: `Delete Images`,
            text: `Are you sure that you want to delete the selected images?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                for (let i in props.items) {
                    let item = props.items[i]
                    let index = entity.images.findIndex(x => x.image_id === item)
                    await _api[props.entityType].deleteImage(entity.images[index].id)

                    entity.images.splice(index, 1)
                    dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
                }
            }
            toast.success('Success!!!')
            props.onHide && props.onHide()
        })
    }

    const updateBannerImage = async () => {
        const imageRecognitionIds = entity?.images?.length > 0 ? entity?.images?.filter((elem) => elem?.type === 'image-recognition')?.map((elem) => elem.image_id) : []
        if (imageRecognitionIds?.length > 0) {
            if (imageRecognitionIds?.includes(entity?.image_id)) {
                const imageDoc = entity.images?.filter((elem) => elem.type !== 'then_now' && elem.type !== 'image-recognition')
                entity.image_id = imageDoc?.length > 0 ? imageDoc?.[0]?.image_id : null
                entity.image = imageDoc?.length > 0 ? { id: imageDoc?.[0]?.image_id, caption: imageDoc?.[0]?.image_caption, url: imageDoc?.[0]?.image_url } : {}
                await _api[props.entityType].update(entity);
                dispatch({ type: 'entity', payload: { ...entity, image_id: entity.image_id, image: entity.image } })
            }
        }
    }

    return (
        <Modal show={props.show} size='xl' onHide={() => props.onHide(false)}>
            <Modal.Body className='modal-bulk'>

                <div className='d-flex justify-content-between'>
                    {
                        bulkEditorType === 'image-credit' &&
                        <div className='cnow-form-title'>Add Image Credits for the Selected Images</div>
                    }
                    {
                        bulkEditorType === 'image-type' &&
                        <div className='cnow-form-title'>Select Image Type for the Selected Images</div>
                    }
                    {
                        bulkEditorType === 'image-date' &&
                        <div className='cnow-form-title'>Add Date Taken for the Selected Images</div>
                    }
                    {
                        bulkEditorType === 'another-section' &&
                        <div className='cnow-form-title'>Move to Selected Images to Another Section</div>
                    }
                    {
                        bulkEditorType === 'delete' &&
                        <div className='cnow-form-title'>Delete the Selected Images</div>
                    }
                    {
                        bulkEditorType === 'image-recognition' &&
                        <div className='cnow-form-title'>Move the Selected Image to Image Recognition</div>
                    }

                    <Button size='sm' icon='close' target='_self' onClick={() => props.onHide(false)} />
                </div>

                <hr className='cnow-hr' />
                <InlineImageList items={props?.items} type='bulk-editor' />
                <div className='mt-3'>
                    {
                        bulkEditorType === 'image-type' &&
                        <div>
                            <div className='bg-light cnow-form-title'>Type of Images *</div>
                            <div className='mt-2 w-25'>
                                <ImageCategoryList type='image_category' value={imageType} onChange={(type) => setImageType(type)} />
                            </div>
                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Save' color='danger' onClick={() => { handleUpdateImageType() }} />
                                <Button size='sm' icon='chevrons-right' label='Image Credit' onClick={() => { setBulkEditorType('image-credit'); handleUpdateImageType() }} />
                                <Button size='sm' icon='chevrons-right' label='Taken Date' onClick={() => { setBulkEditorType('image-date'); handleUpdateImageType() }} />
                            </div>
                        </div>
                    }

                    {
                        bulkEditorType === 'image-credit' &&
                        <div id='date_taken'>
                            <hr className='cnow-hr' />
                            <div className='d-flex justify-content-between'>
                                <div className='cnow-form-title'>Image Credits *</div>
                                <Button size='xl' icon='plus' label='Add one more' onClick={() => addImageCredit()} />
                            </div>
                            <div className='col'>
                                <div className='w-75'>

                                    {
                                        credits && credits.length > 0 && credits.map((item, index) => {
                                            return (
                                                <div className='row g-1 align-items-end'>
                                                    <div className='col-5'>
                                                        <label className='cnow-form-label fw-bold'>Role</label>
                                                        <SelectGlobalList type='image_credit' value={{ id: item.role_id, name: item.role_name }} onChange={(value) => handleChange('role', index, value)} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <label className='cnow-form-label fw-bold'>{item.role_name}</label>
                                                        <SelectPerson value={{ id: item.person_id, name: item.person_name }} onChange={(value) => handleChange('person', index, value)} />
                                                    </div>

                                                    <div className='col-1'>
                                                        <div className='d-flex gap-1'>
                                                            <LinkButton icon='edit' to={`/people/${item.person_id}/editor`} />
                                                            <Button icon='delete' onClick={() => handleDeletePerson(index)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Save' color='danger' onClick={() => { handleSaveCredit() }} />
                                <Button size='sm' icon='chevrons-left' label='Image Type' onClick={() => { setBulkEditorType('image-type'); handleSaveCredit() }} />
                                <Button size='sm' icon='chevrons-right' label='Taken Date' onClick={() => { setBulkEditorType('image-date'); handleSaveCredit() }} />
                            </div>
                        </div>
                    }

                    {
                        bulkEditorType === 'image-date' &&
                        <div id='date_taken'>
                            <hr className='cnow-hr' />
                            <div className='bg-light cnow-form-title'>Date Taken <small>(When was the image originally drawn/published/photographed)</small></div>
                            <div className='col'>
                                <SelectDate type={false} value={imageDate} label={true} onChange={(date) => { setImageDate(date) }} />
                            </div>
                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Save' color='danger' onClick={() => { handleTakenDateChange() }} />
                                <Button size='sm' icon='chevrons-left' label='Image Type' onClick={() => { setBulkEditorType('image-type'); handleTakenDateChange() }} />
                                <Button size='sm' icon='chevrons-left' label='Image Credit' onClick={() => { setBulkEditorType('image-credit'); handleTakenDateChange() }} />
                            </div>
                        </div>
                    }

                    {
                        bulkEditorType === 'another-section' &&
                        <div id='another-section'>
                            <div className='bg-light cnow-form-title'>Move to another section</div>
                            <div className='w-50'>
                                <Select styles={customSelectStyle} options={imageTypeOptions()} onChange={(item) => handleMoveImages(item)} />
                            </div>
                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Save' color='danger' onClick={() => { updateBannerImage(); handleSaveMovedImages(); }} />
                            </div>
                        </div>
                    }
                    {
                        bulkEditorType === 'delete' &&
                        <div id='date_taken'>
                            <hr className='cnow-hr' />
                            <div className='bg-light cnow-form-title'>Are you sure you want to delete the selected images?</div>
                            <div className='small fw-bold'>Instead of deleting, move to image recognition? This will not be visible on the frontend.</div>
                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Move to image recognition' color='danger' onClick={(item) => handleMoveToImageRecognition(item)} />
                                <Button size='sm' label='Delete' onClick={() => handleDelete()} />
                            </div>
                        </div>
                    }

                    {
                        bulkEditorType === 'image-recognition' &&
                        <div id='date_taken'>
                            <hr className='cnow-hr' />
                            <div className='bg-light cnow-form-title'>Are you sure you want to move to Image Recognition</div>

                            <div className='btn-list mt-2'>
                                <Button size='sm' label='Move to image recognition' color='danger' onClick={() => { handleMoveToImageRecognition(); updateBannerImage(); }} />

                            </div>
                        </div>
                    }
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default BulkImageEditor