import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Name from '../../components/preview/name';
import Alias from '../../components/preview/alias';
import Location from '../../components/preview/location';
import PersonDesignationList from '../../components/preview/personDesignationList';
import ProfessionList from '../../components/preview/professionList';
import AffiliationList from '../../components/preview/affiliationList';
import Description from '../../components/preview/description';
import RecipeList from '../../components/preview/recipieList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import OrganizationTypeList from '../../components/preview/organizationTypeList';
import PublicationTypeList from '../../components/preview/publicationTypeList';
import Publication from '../../components/preview/publication';
import Playlist from '../../components/preview/playlist';
import { personName } from '../../helpers';
import ImageViewer from '../../components/viewers/image';


const _api = require('../../api')

const ParticipantPreview = (props) => {
	const dispatch = useDispatch();
	const person = useSelector(state => state[props.id]);
	const previewSize = useSelector((state) => state.preview);
	const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

	const handleLive = (personId) => {
		if (person.type === 'person') {
			if (
				person?.first_name &&
				person?.professions &&
				person?.professions?.[0]?.profession_name &&
				person?.description &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person.first_name} is now Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live!`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person.first_name ? '' : 'Person Firstname is missing.'} 
          ${person?.description ? '' : 'About Person is missing.'} 
          ${person?.professions?.[0] && person?.professions?.[0]?.profession_name ? '' : 'Person Profession is missing.'} 
          ${person?.image?.url ? '' : 'Person Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		} else if (person.type === 'organization') {
			if (
				person?.name &&
				person?.organization_types && person?.organization_types?.[0]?.organization_type_name &&
				person?.description &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person?.name} Organization is Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live!`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person?.name ? '' : '\n Organization name is missing.'} 
          ${person?.description ? '' : '\n About Organization is missing.'} 
          ${person?.organization_types && person?.organization_types?.[0]?.organization_type_name ? '' : 'Organization type is missing.'}  
          ${person?.image?.url ? '' : '\n Organization Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		} else {
			if (
				person?.name &&
				person?.publication_types && person?.publication_types?.[0]?.publication_type_name &&
				person?.description &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person?.name} is Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live.`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person?.name ? '' : 'Publication name is missing.'} 
          ${person?.publication_types && person?.publication_types?.[0]?.publication_type_name ? '' : 'Publication type is missing.'} 
          ${person?.description ? '' : 'About Publication is missing.'}  
          ${person?.image?.url ? '' : 'Publication Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		}
	}

	const handleNotLive = (personId) => {
		swal({
			title: 'Not Live!',
			icon: 'info',
			text: `${person?.type === 'person'
				? `${person?.first_name} is Not Live.`
				: `${person?.name} is Not Live.`
				}`,
			buttons: 'Ok',
		})
		_api.person.updateLive(personId, false).then(() =>
			dispatch({ type: 'entity', payload: { ...person, live: false } })
		)
	}

	return (
		<>
			<>
				{
					previewSize === 'sm' && <div className='btn-grid'>
						<Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' link={`participant/${person?.id}`} />
						<LinkButton size='sm' icon='pan' to={`/people/${person?.id}/editor`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

						<Button size='sm' color='light' icon='help' />
					</div>
				}

				{
					previewSize === 'lg' && <div className='btn-list action-bar btn-preview-pan'>
						<Button size='sm' color='light' label='Instructions' icon='help' />
						{person?.live ? (<Button size='sm' color='light' label='Live' onClick={() => handleNotLive(person?.id)} />) :
							(<Button size='sm' color='danger' label='Not Live' onClick={() => handleLive(person?.id)} />)}
						<Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<LinkButton size='sm' icon='pan' to={`/people/${person?.id}/editor`} />
						<PreviewButton size='sm' link={`participant/${person?.id}`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
					</div>
				}
			</>

			{
				person &&
				<div className='preview'>
					{
						previewSize === 'lg' &&
						<>
							<div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
							<ImageViewer url={person.url} type="thumb@2x" cssClass="participant-thumb" />
							<Name data={personName(person)} />
							<PersonDesignationList data={person?.designations} />
							<Alias data={person.alias} />
							{person.hide_location === false && <Location data={person.locations} />}
							<OrganizationTypeList data={person.organization_types} />
							<PublicationTypeList data={person.publication_types} />
							<ProfessionList data={person.professions} />
							<AffiliationList data={person.affiliations?.length > 0 ? person.affiliations.filter((item) => item.related_person_type !== 'publication') : []} />
							<Description data={person?.description} title='About' />
							{/* <PodcastList data={person.podcasts} /> */}
							<Publication data={person.affiliations?.length > 0 ? person.affiliations.filter((item) => item.related_person_type === 'publication') : []} />
							<RelatedSiteList data={person.related_sites} />
							<RelatedPeopleList data={person.related_people?.length > 0 ? person.related_people.filter((item) => item.related_person_type !== 'publication') : []} />
							<RelatedPeopleList data={person.related_people?.length > 0 ? person.related_people.filter((item) => item.related_person_type === 'publication') : []} title={'Related Publication'} />
							<Playlist data={person?.playlists} />
							<RecipeList data={person.recipes} />
						</>
					}
				</div>
			}
		</>
	);
};

export default ParticipantPreview;