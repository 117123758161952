import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import FeaturedImage from "../../preview/featuredImage";
import { dateString } from "../../../helpers";

const InThisPhoto = ({ featured }) => {
    const in_this_photo = featured.in_this_photo
    const [inThisPhoto, setInThisPhoto] = useState([])
    // eslint-disable-next-line
    useEffect(() => { formatInThisPhoto() }, [in_this_photo?.length, in_this_photo])

    const formatInThisPhoto = () => {
        let data = []
        if (in_this_photo)
            for (let i in in_this_photo) {
                if (in_this_photo[i]?.data?.in_this_photo) {
                    for (let j in in_this_photo[i].data.in_this_photo) {
                        in_this_photo[i].data.in_this_photo[j].section_index = i
                        in_this_photo[i].data.in_this_photo[j].entity_index = j
                        data.push(in_this_photo[i].data.in_this_photo[j])
                    }
                }
            }
        setInThisPhoto(data)
    }

    return (
        <>
            {in_this_photo && <Carousel>
                {inThisPhoto?.length > 0 && inThisPhoto.map((elem, index) => {
                    return (
                        <Carousel.Item key={`in-this-photo-preview-${index}`} interval={2000}>
                            <FeaturedImage
                                url={elem?.url}
                                title="IN THIS PHOTO"
                                subTitle={`${dateString(elem?.taken_date) || ''}${elem?.caption ? ` - ${elem?.caption}` : ''}`}
                                description={elem?.description}
                                type={'featured@3x'}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>}
        </>
    )
}

export default InThisPhoto