/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import ImageViewer from '../../components/viewers/image';
import TableHeader from '../../components/tableHeader';
import Icon from '../../components/viewers/icon';
import LongTextView from '../../components/viewers/longText';
import PeopleList from '../../components/table/peopleList';
import LocationList from '../../components/table/locationList'
import DateList from '../../components/table/dateList'
import CategoryList from '../../components/table/categoryList'
import TagList from '../../components/table/tagList'
import ThemeList from '../../components/table/themeList';
import PodcastList from '../../components/table/podcastList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import Playlist from '../../components/table/playlist';
import RelatedPeopleList from '../../components/table/relatedPeopleList';
import RelatedMapList from '../../components/table/relatedMapList';
import { TABLE_ROWS, checkUserTypeAndReturnIds, getCategoryName } from '../../helpers';
import RelatedEventSeriesList from '../../components/table/relatedEventSeries';
import RelatedEventList from '../../components/table/relatedEvent';
import RelatedTourList from '../../components/table/relatedTourList';
import { useSelector } from 'react-redux';

const columns = require('../../data/columns_maps.json')
const _api = require('../../api')

const Maps = (props) => {
	const history = useHistory();
	const user = useSelector(state => state.user)
	const [searchResult, setSearchResult] = useState([]);
	const [keywordValue, setKeywordValue] = useState('');
	const [keyword, setKeyword] = useState('');

	const take = 50;
	const [page, setPage] = useState(1);;
	const [sort, setSort] = useState(columns[0]);

	const [spinner, showSpinner] = useState(false);

	const search = useCallback(() => {
		let sortBoolean = true
		const params = {
			"bool": {
				"must": [
					{ "match": { "deleted": "false" } },
					{ "match": { "created_via": "ADMIN" } },
				]
			}
		};
		if (keyword) {
			sortBoolean = false
			params.bool.must.push({
				"multi_match": {
					"query": keyword,
					"fields": [
						"name^3",
						"people.person_name^2",
						"dates.event_type_name",
						"locations.name",
						"locations.city",
						"locations.state",
						"locations.country",
						"categories.category_name",
						"categories.subcategory_name",
						"tags.tag_name",
						"related_sites.related_site_name",
						"related_people.related_person_name",
						"podcasts.podcast_name",
						"people.role_name",
						"people.profession.profession_name",
						"related_people.org_type.organization_name",
						"locations.address",
						"theme.theme_name",
						"maps.map_name",
						"events.event_name",
						"tours.tour_name",
						"event_series.event_series_name",
					],
					"fuzziness": 2,
					"prefix_length": 2
				}
			});
		}
		const finalParams = {
			query: params
		}
		if (sortBoolean) {
			finalParams.sort = [{ "modified_date": { "order": "desc" } }]
		}

		showSpinner(true);

		_api.map.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
			setSearchResult(response?.hits)
			showSpinner(false)
		})
	}, [page, keyword, user.id])

	useEffect(() => {
		search();
	}, [search]);

	const deleteMap = (map) => {
		swal({
			title: `Delete Map`,
			text: `Are you sure that you want to delete ${map?.name}?`,
			buttons: ['Cancel', 'Yes'],
			icon: 'warning',
			dangerMode: true,
		}).then(async (status) => {
			_api.map.deleteById(map?.id).then(() => { search() })
		});
	};

	const handleLive = (mapId) => {
		showSpinner(true)
		let selectedSearchResult = searchResult.hits.filter(
			(item) => item.id === mapId
		)
		let selectedResult = selectedSearchResult[0]
		let dateDescription = selectedResult.dates?.filter((e) => e.description !== '')
		let category = getCategoryName(selectedResult?.categories)
		if (
			selectedResult?.name &&
			category &&
			selectedResult?.themes?.length > 0 && selectedResult?.themes?.[0]?.theme_name &&
			selectedResult?.locations && selectedResult?.locations?.[0]?.address &&
			(selectedResult?.description || dateDescription?.length > 0) &&
			(selectedResult?.image)
		) {
			_api.map.updateLive(mapId, true).then((_) => {
				search()
				swal({
					title: 'Map is Live!',
					icon: 'success',
					text: `${selectedResult?.name} is now Live!`,
					buttons: 'Ok',
				})
			})
		} else {
			swal({
				title: `${selectedResult.name} cannot go Live!`,
				icon: 'error',
				text: `Please fill the mandatory fields:
              ${selectedResult?.name ? '' : 'Title is missing.'}
              ${category ? '' : 'Category is missing.'} 
              ${selectedResult?.themes?.length > 0 && selectedResult?.themes?.[0]?.theme_name ? '' : 'Theme is missing.'} 
              ${selectedResult?.locations && selectedResult?.locations?.[0]?.address ? '' : 'Location is missing.'} 
              ${(selectedResult?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
              ${(selectedResult?.image) ? '' : 'Image is missing.'} `,
				buttons: 'Go back',
				dangerMode: true,
			})
		}
		showSpinner(false)
	}

	const handleNotLive = (mapId) => {
		showSpinner(true)
		let selectedSearchResult = searchResult.hits.filter(
			(item) => item.id === mapId
		)
		let selectedResult = selectedSearchResult[0]
		_api.map.updateLive(mapId, false).then((_) => {
			search()
			swal({
				title: 'Not Live!',
				icon: 'info',
				text: `${selectedResult?.name} is Not Live.`,
				buttons: 'Ok',
			})
		})
		showSpinner(false)
	}

	return (
		<>
			<div className='container-fluid mt-3'>
				<div className='row g-1'>
					<div className='col'>
						<div className='input-group'>
							<span className='input-group-text border-end-0'>
								<Icon name='search' />
							</span>
							<input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
								onChange={(event) => { setKeywordValue(event.target.value) }}
								onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className='col-auto'>
						<div className='btn-list'>
							<Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
						</div>
					</div>
					<div className='col-auto'>
						<div className='btn-list'>
							<Button label='Add a Map' color='danger' icon='plus-white' onClick={() => history.push('/map/create')} />
						</div>
					</div>
				</div>
			</div>

			<div className='container-fluid mt-3 p-0'>
				<div className='card'>
					<div className='card-header bg-light p-2'>
						<h4 className='mb-0'>Maps</h4>
						<div className='d-flex justify-content-between'>
							<div className='small'>
								<ResultCount page={page} take={take} count={searchResult?.total?.value} />
								{keyword && <span className='badge badge-search'>
									{keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
								</span>}
							</div>
							<div className='cols'>
								<Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
							</div>
							<div className=''>
								<div className='dropdown'>
									<button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
										Sort by: {sort.name}
									</button>
									<ul className='dropdown-menu'>
										{columns.filter(x => x.sort === true).map((column, index) =>
											<button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>)}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Spinner display={spinner}>
						<div className='table-responsive cnow-table'>
							<table className='table table-striped table-hover table-bordered'>
								<thead>
									<tr className='bg-light'>
										{columns.filter(x => x.display === true).map((column, index) =>
											<TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
										)}
									</tr>
								</thead>
								<tbody>
									{searchResult && searchResult?.hits && searchResult?.hits?.map((item, index) => {

										const mapList = item?._source?.related_maps?.map((cat) => cat) || []
										return (
											<tr key={index}>
												<td>
													<div className='row g-1'>
														<div className='col-auto'>
															<div className='form-check mt-1'>
																<input className='form-check-input align-middle' type='checkbox' name='select_map' value={item?._source.id} />
															</div>
														</div>
														<div className='col-auto'>
															<div className='btn-list'>
																<LinkButton size='sm' icon='edit' to={`/map/${item?._source.id}/editor`} />
																<Button size='sm' icon='delete' onClick={() => deleteMap(item?._source)} />
																<PreviewButton size='sm' link={`map/${item?._source?.vanity_url || item?._source?.id}`} />
																{item?._source.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item?._source.id)} />
																	: <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item?._source.id)} />}
															</div>
														</div>
													</div>
												</td>
												<td><ImageViewer url={item?._source.image?.url} type='thumb' cssClass='thumb' /></td>
												<td className='fw-bold'>{item?._source.name}</td>
												<PeopleList data={item?._source?.people} />
												<LocationList data={item?._source.locations} />
												<DateList data={item?._source.dates} />
												<CategoryList data={item?._source.categories} />
												<ThemeList data={item?._source?.themes} />
												<td>{item?._source.id}</td>
												<TagList data={item?._source.tags} />
												<td><LongTextView lines={TABLE_ROWS + 1}>{item?._source.description}</LongTextView></td>
												<PodcastList data={item?._source.podcasts} />
												<td>{(item?._source.map_overlays && item?._source.map_overlays?.length > 0 && item?._source.map_overlays[0]?.map_overlay_name) ? 'yes' : ''}</td>
												<td></td>
												<Playlist data={item?._source.playlists} />
												<RelatedEventSeriesList data={item?._source?.event_series} />
												<td></td>
												<RelatedSiteList data={item?._source.related_sites} />
												<RelatedPeopleList data={item?._source.related_people} />
												<RelatedEventList data={item?._source?.events} />
												<RelatedTourList data={item?._source?.tours} />
												<RelatedMapList data={mapList} />
												<td>{item?._source.edited_by}</td>
											</tr>
										);
									})
									}
								</tbody>
							</table>
						</div>
						<div className='d-flex justify-content-between m-3'>
							<div className='cols'>
								<Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
							</div>
						</div>
					</Spinner>
				</div>
			</div>
			<ScrollToTop />
		</>
	);
}

export default Maps;