import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import DateEditor from '../../components/editors/dateEditor';
import { personName } from '../../helpers';

const PeopleDate = (props) => {
  const person = useSelector(state => state[props.id]);

  return (
    <>
      <PageTitle title={personName(person)} />
      <DateEditor id={props?.id} type={`${person?.type}`} onUpdate={props?.onUpdate} />
    </>
  );
};

export default PeopleDate;