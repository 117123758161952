/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import { dateString, getOffsetImageIndex } from '../../helpers';
import { useHistory } from 'react-router-dom';

const MapImages = (props) => {

    const dispatch = useDispatch()
    const map = useSelector(state => state[props.id])

    const location = useLocation()
    const history = useHistory()

    let currentMaps = map ? map.images?.filter(x => x.type === 'map' && x.date_id === null) : []
    let currentImages = map ? map.images?.filter(x => x.type === 'image' && x.date_id === null) : []
    let currentDocuments = map ? map.images?.filter(x => x.type === 'document' && x.date_id === null) : []
    let mapImages = map ? map.images?.filter(x => x.type === 'map') : []
    let images = map ? map.images?.filter(x => x.type === 'image') : []

    const editItems = (id) => {
        let imagesList = map.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: map.id, entityType: 'map' } })
        history.push(`/image/${id}/banner`)
    }

    return (
        <>
            {
                map && <>
                    <PageTitle title={map?.name} />

                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(map.image_id)} className='cursor-pointer'>
                                        <ImageViewer
                                            url={map?.image?.url}
                                            type='banner' cssClass='cnow-image-editor-banner' />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(map.image_id)} disabled={map.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='entity-image-sort'>
                        {
                            <EntityImages title={`Maps`} items={currentMaps}
                                imageType='map'
                                entityType='map'
                                entityId={map.id}
                                dateId={null}
                                buttonLabel={'Add a Map'}
                                offsetIndex={0}
                            />
                        }

                        {
                            map.dates?.map((date, index) => {
                                const dateImages = map.images?.filter(x => x.type === 'map' && x.date_id === date.id)
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            <EntityImages title={`${dateString(date)} - ${date.event_type_name} Maps`} items={dateImages}
                                                imageType='map'
                                                entityType='map'
                                                entityId={map.id}
                                                dateId={date.id}
                                                buttonLabel={'Add a Map'}
                                                offsetIndex={getOffsetImageIndex(map.images, map.dates, date.id, 'map')}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }

                        {
                            <EntityImages title={`Additional Images`} items={currentImages}
                                imageType='image'
                                entityType='map'
                                entityId={map.id}
                                dateId={null}
                                buttonLabel={'Add an Image'}
                                offsetIndex={mapImages?.length}
                            />
                        }

                        {
                            <EntityImages title={`Additional Document and Drawings`} items={currentDocuments}
                                imageType='document'
                                entityType='map'
                                entityId={map.id}
                                dateId={null}
                                buttonLabel={'Add a Document'}
                                offsetIndex={mapImages?.length + images?.length}
                            />
                        }
                    </div>

                </>
            }
        </>
    )
}

export default MapImages 
