import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import PreviewButton from "../../components/buttons/preview";
import LinkButton from "../../components/buttons/link";
import Button from "../../components/buttons/default";
const _api = require('../../api')
const DirectEventsInfo = ({ title, type, id }) => {
    const person = useSelector(state => state[id]);
    const dispatch = useDispatch();
    const deleteEventPeople = async (source, index) => {
        const getDeleteLabel = (type) => {
            if (type === 'participant') return 'deleteParticipant'
            if (type === 'host') return 'deleteHost'
            if (type === 'sponsor') return 'deleteSponsor'
        }
        const giveEntity = () => {
            let entity = type === 'direct_events' ? 'event' : 'event_series'
            return entity
        }
        await _api[giveEntity()][getDeleteLabel(source.source)](source.id)
        person[type].splice(index, 1)
        person[type].forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...person, [type]: person[type] } });
    }
    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{title}</label>
                </div>
            </div>
            {
                person[type]?.length > 0 && person[type].map((item, index) => {
                    return (
                        <div key={item.id} className='mb-3'>
                            <div className='row g-1'>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                </div>
                                <div className='col'>
                                    <div className='row g-1'>
                                        <div className='col'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Title</label>}
                                            <input type='text' className='form-control' value={type === 'direct_events' ? item.event_name : item.event_series_name} autoComplete="off" disabled />
                                        </div>
                                        <div className='col-4'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Role</label>}
                                            <input type='text' className='form-control' value={item.source} autoComplete="off" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <PreviewButton link={`${type === 'direct_events' ? 'event' : 'event_series'}/${type === 'direct_events' ? item.event_id : item.event_series_id}`} />
                                        <LinkButton icon='edit' to={`/${type === 'direct_events' ? 'event' : 'event_series'}/${type === 'direct_events' ? item.event_id : item.event_series_id}/editor`} />
                                        <Button icon='delete' onClick={() => deleteEventPeople(item, index)} />
                                    </div>
                                </div>
                            </div>
                        </div>)
                })
            }
        </>
    )
}

export default DirectEventsInfo