import { ApiCall } from './utils'

//event
const getById = (id) => ApiCall('get', `/event_series/${id}`)

const search = (params) => ApiCall('post', `/event_series/search`, params)

const searchByElastic = (from, size, params) => ApiCall('post', `/search/event_series/${from}/${size}`, params)

const update = (params) => ApiCall('post', `/event_series`, params)

const deleteById = (id) => ApiCall('delete', `/event_series/${id}`)

const updateLive = (id, live) => ApiCall('put', `/event_series/${id}/live/${live}`)

//tag
const getTag = (id) => ApiCall('get', `/event_series/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/event_series/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/event_series/tag/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/event_series/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/event_series/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/event_series/category/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/event_series/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/event_series/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/event_series/theme/${id}`)

//location
const getLocation = (id) => ApiCall('get', `/event_series/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/event_series/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/event_series/location/${id}`)

//event_series_type

const getType = (id) => ApiCall('get', `/event_series/${id}/type`)

const updateType = (id, params) => ApiCall('post', `/event_series/${id}/type`, params)

const deleteType = (id) => ApiCall('delete', `/event_series/type/${id}`)

//event_series_host

const getHost = (id) => ApiCall('get', `/event_series/${id}/host`)

const updateHost = (id, params) => ApiCall('post', `/event_series/${id}/host`, params)

const deleteHost = (id) => ApiCall('delete', `/event_series/host/${id}`)

//Related Sites Get and Related Site People
const getRelatedPerson = (id) => ApiCall('get', `/event_series/${id}/related_person`)

const getRelatedSite = (id) => ApiCall('get', `/event_series/${id}/related_site`)


//stories
const getStory = (id) => ApiCall('get', `/event_series/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/event_series/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/event_series/story/${id}`)



//get Related Tour

const gettour = (id) => ApiCall('get', `/event_series/${id}/tour`)

const update_tour = (id, params) => ApiCall('post', `/event_series/${id}/tour`, params)

const delete_tour = (id) => ApiCall('delete', `/event_series/tour/${id}`)

//get Related map

const getRelatedMap = (id) => ApiCall('get', `/event_series/${id}/related_map`)



//featured _Event

const getevent = (id) => ApiCall('get', `/event_series/${id}/featured`)

const update_event = (id, params) => ApiCall('post', `/event_series/${id}/featured`, params)

const delete_event = (id) => ApiCall('delete', `/event_series/featured/${id}`)

//image 
const getImage = (id) => ApiCall('get', `/event_series/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/event_series/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/event_series/image/${id}`)

const EventImage = (id) => ApiCall('get', `/event_series/${id}/related_event`)

//podcast
const getPodcast = (id) => ApiCall('get', `/event_series/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/event_series/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/event_series/podcast/${id}`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/event_series/${id}/related_playlist`)


const deletePlaylist = (id) => ApiCall('delete', `/playlist/event_series/${id}`)

//Automated Playlist 

const automatedPlaylist = (id) => ApiCall('post', `/event_series/${id}/playlist/sync`, '')



const getEventSeriesRecipe = (id) => ApiCall('get', `/event_series/${id}/recipe`)

const updateEventSeriesRecipe = (id, params) => ApiCall('post', `/event_series/${id}/recipe`, params)

const deleteRecipe = (id) => ApiCall('delete', `/event_series/recipe/${id}`)

//curator

const getCurator = (id) => ApiCall('get', `/event_series/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/event_series/${id}/curator`, params)



export default {
    getById,
    search,
    searchByElastic,
    update,
    deleteById,
    updateLive,
    getTag,
    updateTag,
    deleteTag,
    getCategory,
    updateCategory,
    deleteCategory,
    getTheme,
    updateTheme,
    deleteTheme,
    getLocation,
    updateLocation,
    deleteLocation,
    getType,
    updateType,
    deleteType,
    getHost,
    gettour,
    update_tour,
    delete_tour,


    updateHost,
    deleteHost,
    getRelatedPerson,
    getRelatedSite,
    getevent,
    update_event,
    delete_event,
    getStory,
    updateStory,
    deleteStory,
    getRelatedMap,
    getImage,
    updateImage,
    deleteImage,
    EventImage,
    getPodcast,
    updatePodcast,
    deletePodcast,


    getPlaylist,
    deletePlaylist,
    automatedPlaylist,

    getEventSeriesRecipe,
    updateEventSeriesRecipe,
    deleteRecipe,

    getCurator,
    updateCurator

}
