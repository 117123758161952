import { ApiCall } from './utils'

//event
const getById = (id) => ApiCall('get', `/event/${id}`)

const search = (params) => ApiCall('post', `/event/search`, params)

const searchByElastic = (from, size, params) => ApiCall('post', `/search/event/${from}/${size}`, params)


const searchByDate = (params, start_date, end_date) => ApiCall('post', `/event/search?start_date=${start_date}&end_date=${end_date}`, params)

const update = (params) => ApiCall('post', `/event`, params)

const deleteById = (id) => ApiCall('delete', `/event/${id}`)

const updateLive = (id, live) => ApiCall('put', `/event/${id}/live/${live}`)

//tag
const getTag = (id) => ApiCall('get', `/event/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/event/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/event/tag/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/event/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/event/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/event/category/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/event/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/event/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/event/theme/${id}`)

//location
const getLocation = (id) => ApiCall('get', `/event/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/event/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/event/location/${id}`)

//event_type

const getType = (id) => ApiCall('get', `/event/${id}/type`)

const updateType = (id, params) => ApiCall('post', `/event/${id}/type`, params)

const deleteType = (id) => ApiCall('delete', `/event/type/${id}`)

//event_host

const getHost = (id) => ApiCall('get', `/event/${id}/host`)

const updateHost = (id, params) => ApiCall('post', `/event/${id}/host`, params)

const deleteHost = (id) => ApiCall('delete', `/event/host/${id}`)

//event_participants

const getParticipant = (id) => ApiCall('get', `/event/${id}/participant`)

const updateParticipant = (id, params) => ApiCall('post', `/event/${id}/participant`, params)

const deleteParticipant = (id) => ApiCall('delete', `/event/participant/${id}`)

//Related Sites Get and Related Site People
const getRelatedPerson = (id) => ApiCall('get', `/event/${id}/related_person`)

const getRelatedSite = (id) => ApiCall('get', `/event/${id}/related_site`)

//event_Sponsors

const getSponsor = (id) => ApiCall('get', `/event/${id}/sponsor`)

const updateSponsor = (id, params) => ApiCall('post', `/event/${id}/sponsor`, params)

const deleteSponsor = (id) => ApiCall('delete', `/event/sponsor/${id}`)

//stories
const getStory = (id) => ApiCall('get', `/event/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/event/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/event/story/${id}`)

//get Related Events

const getEvent = (id) => ApiCall('get', `/event/${id}/related_event`)

const updateEvent = (id, params) => ApiCall('post', `/event/${id}/related_event`, params)

const deleteEvent = (id) => ApiCall('delete', `/event/related_event/${id}`)

//get Related Tour

const getRelatedTour = (id) => ApiCall('get', `/event/${id}/related_tour`)

const deleteTour = (id) => ApiCall('delete', `/tour/event/${id}`)

//get Related map

const getRelatedMap = (id) => ApiCall('get', `/event/${id}/related_map`)

//featured People

const getFeaturedPeople = (id) => ApiCall('get', `/event/${id}/featured`)

const updateFeaturedPeople = (id, status) => ApiCall('put', `/event/${id}/featured/${status}`)

//featured Projects

const getFeaturedProjects = (id) => ApiCall('get', `/event/${id}/featured_project`)

const updateFeaturedProjects = (id, status) => ApiCall('put', `/event/${id}/featured_project/${status}`)

//onthisDay 

const getOnThisDay = (id) => ApiCall('get', `/event/${id}/on_this_day`)

const updateOnThisDay = (id, params) => ApiCall('post', `/event/${id}/on_this_day`, params)

const deleteOnThisDay = (id) => ApiCall('delete', `/event/on_this_day/${id}`)

const updateListOnThisDay = (event_id, id, value) => ApiCall('put', `/event/${event_id}/on_this_day/${id}/live/${value}`)

//reservations

const getReservation = (id) => ApiCall('get', `/event/${id}/reservation`)

const updateReservation = (id, params) => ApiCall('post', `/event/${id}/reservation`, params)

const deleteReservation = (id) => ApiCall('delete', `/event/reservation/${id}`)

//Tickets

const getTicket = (id) => ApiCall('get', `/event/${id}/ticket`)

const updateTicket = (id, params) => ApiCall('post', `/event/${id}/ticket`, params)

const deleteTicket = (id) => ApiCall('delete', `/event/ticket/${id}`)

//Continuing Education

const getContinuingEducation = (id) => ApiCall('get', `/event/${id}/continuing_education`)

const updateContinuingEducation = (id, params) => ApiCall('post', `/event/${id}/continuing_education`, params)

const deleteContinuingEducation = (id) => ApiCall('delete', `/event/continuing_education/${id}`)

//image
const getImage = (id) => ApiCall('get', `/event/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/event/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/event/image/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/event/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/event/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/event/podcast/${id}`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/event/${id}/related_playlist`)

const deletePlaylist = (id) => ApiCall('delete', `/playlist/event/${id}`)

// dates

const getEventDate = (id) => ApiCall('get', `/event/${id}/date`)

const updateEventDate = (id, params) => ApiCall('post', `/event/${id}/date`, params)

const deleteEventDate = (id) => ApiCall('delete', `/event/date/${id}`)

//recipe

const getEventRecipe = (id) => ApiCall('get', `/event/${id}/recipe`)

const updateEventRecipe = (id, params) => ApiCall('post', `/event/${id}/recipe`, params)

const deleteRecipe = (id) => ApiCall('delete', `/event/recipe/${id}`)


// event series
const getEventSeries = (id) => ApiCall('get', `/event/${id}/related_event_series`)

const deleteEventSeries = (id) => ApiCall('delete', `/event_series/featured/${id}`)


//curator

const getCurator = (id) => ApiCall('get', `/event/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/event/${id}/curator`, params)

//Exhibitions 

const getExhibition = () => ApiCall('get', `/event/list/exhibition`)

const updateExhibition = (params) => ApiCall('post', `/event/exhibition`, params)

const deleteExhibition = (id) => ApiCall('delete', `/event/exhibition/${id}`)

export default {
    getById,
    search,
    update,
    deleteById,
    updateLive,
    getTag,
    updateTag,
    deleteTag,
    getCategory,
    updateCategory,
    deleteCategory,
    getTheme,
    updateTheme,
    deleteTheme,
    getLocation,
    updateLocation,
    deleteLocation,
    getType,
    updateType,
    deleteType,
    getHost,
    updateHost,
    deleteHost,
    getParticipant,
    updateParticipant,
    deleteParticipant,
    getRelatedPerson,
    getRelatedSite,
    getSponsor,
    updateSponsor,
    deleteSponsor,
    getEvent,
    updateEvent,
    deleteEvent,
    getRelatedTour,
    deleteTour,
    getStory,
    updateStory,
    deleteStory,
    getRelatedMap,
    getFeaturedPeople,
    updateFeaturedPeople,
    getFeaturedProjects,
    updateFeaturedProjects,
    getOnThisDay,
    updateOnThisDay,
    deleteOnThisDay,
    getReservation,
    updateReservation,
    deleteReservation,
    getTicket,
    updateTicket,
    deleteTicket,
    getContinuingEducation,
    updateContinuingEducation,
    deleteContinuingEducation,
    getImage,
    updateImage,
    deleteImage,
    getPodcast,
    updatePodcast,
    deletePodcast,
    getPlaylist,
    deletePlaylist,
    getEventDate,
    updateEventDate,
    deleteEventDate,
    searchByDate,
    searchByElastic,

    getEventRecipe,
    updateEventRecipe,
    deleteRecipe,


    getEventSeries,
    deleteEventSeries,

    updateListOnThisDay,

    getCurator,
    updateCurator,

    getExhibition,
    updateExhibition,
    deleteExhibition
}
