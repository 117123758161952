import React from 'react';
import { TABLE_ROWS } from '../../helpers';

const PublicationTypeList = ({ data }) => {
    return (
        <>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'>{item.publication_type_name}</div>
                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </>
    )
}

export default PublicationTypeList;