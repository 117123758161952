import React from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';

import Tour from './tour';
import Event from './event';
import EventSeries from './eventSeries';

const EventsTours = (props) => {
    const site = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={site.name} />
            <Tour id={site?.id} />
            <Event id={site.id} />
            <EventSeries id={site.id} />
            {/* <RelatedTourEditor id={site.id} type={'site'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} /> */}
            {/* <RelatedEventEditor id={site.id} type={'site'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={true} /> */}
            {/* <RelatedEventSeriesEditor id={site.id} type={'site'} key={`related-event_series -editor-${key}`} refresh={() => { setKey(uniqid()) }} /> */}

        </>
    );
};

export default EventsTours;