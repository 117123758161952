import React, { useState } from 'react'
import ImageViewer from './viewers/image'

const RearrangeImages = (props) => {

    const [expand, setExpand] = useState(true)


    const handleSelectAll = () => { }
    return (
        <>
            <div className='mb-1'>
                {
                    <>
                        <div className='cnow-form-title cnow-preview-title d-flex justify-content-between '>
                            <div className='d-flex gap-2'>
                                <div>
                                    <input type='checkbox' className='form-check-input' defaultChecked={''} onChange={() => handleSelectAll()} />
                                </div>
                                <div >{props.title}</div>
                            </div>
                            <div>
                                {expand === true && <i className='fe fe-chevron-down ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(false)} />}
                                {expand === false && <i className='fe fe-chevron-up ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(true)} />}
                            </div>
                        </div>
                    </>
                }
                <div id={props.dragId} className='draggable-images dragula-container' key={props.dragInstance} >
                    {expand &&
                        props.images  && props.images.length > 0 && props.images.filter(img => img.type !== 'then_now').map((item, index) =>
                            item?.image_url && <span onClick={() => props.handleSelectedImages(item)} key={index} className='dragula-item' id={item.id}>
                                <ImageViewer url={item?.image_url} type='thumb' cssClass={`thumb p-1 ${props.selectedImages.findIndex(x => x.id === item.id) !== -1 ? 'highlight-box' : ''}`} />
                            </span>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default RearrangeImages