import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../components/pageTitle'
import ImageRelease from '../../components/legal/imageRelease';
import { filter, includes, groupBy, uniqBy } from 'lodash';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';
import LegalScrollableBox from '../../components/legal/scrollableBox';
import PdfDownloadUpload from '../../components/legal/pdfDownloadUpload';
import PodcastRelease from '../../components/legal/podcastRelease';
import SignatureForm from '../../components/legal/signature';
import Button from '../../components/buttons/default';
import Spinner from '../../components/spinner';
import { personName } from '../../helpers';

const _api = require('../../api');
const Legal = (props) => {
    const initialValues = {
        image: [],
        podcast: [],
        signature: '',
        date: '',
        mode: ''
    }
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const [legal, setLegal] = useState(initialValues);
    const [pdfData, setPdfData] = useState([])
    const [spinner, setSpinner] = useState(false)
    // eslint-disable-next-line
    useEffect(() => { handleEntitySelected() }, [legal?.image, legal?.image?.length, legal?.podcast?.length, legal?.podcast])

    const handleEntitySelect = (data, type) => {
        if (type === 'image') legal.image = data
        if (type === 'podcast') legal.podcast = data
        setLegal((prev) => { return { ...prev, image: legal.image, podcast: legal.podcast } })
    }

    const handleSignatureChange = (signature, date) => setLegal((prev) => { return { ...prev, signature, date } })

    const submitLegalForms = async () => {
        setSpinner(true)
        if (legal?.image?.length > 0) legal.image.filter((elem) => elem.mode === legal.mode).forEach((elem) => { elem.modified_date = legal?.date || new Date().toISOString().split('T')[0] })
        if (legal?.podcast?.length > 0) legal.podcast.filter((elem) => elem.mode === legal.mode).forEach((elem) => { elem.modified_date = legal?.date || new Date().toISOString().split('T')[0] })
        if (legal?.image?.length > 0 && legal.mode === 'digital_signature') {
            legal.image.filter((elem) => elem.mode === legal.mode).forEach((elem) => { elem.signature = legal.signature; })
        }
        if (legal?.podcast?.length > 0 && legal.mode === 'digital_signature') {
            legal.podcast.filter((elem) => elem.mode === legal.mode).forEach((elem) => { elem.signature = legal.signature; })
        }
        const postData = [...legal.image.filter((elem) => elem.pdf_url || elem.signature), ...legal.podcast.filter((elem) => elem.pdf_url || elem.signature)]
        const legalizedId = postData.map((elem) => elem.entity_id)
        await _api.legal.bulkLegalize(postData)
        if (entity.images?.length > 0) entity.images.forEach((elem) => { if (legalizedId.includes(elem.image_id)) { elem.is_legal = true } })
        if (entity.podcasts?.length > 0) entity.podcasts.forEach((elem) => { if (legalizedId.includes(elem.podcast_id)) { elem.is_legal = true } })
        dispatch({ type: 'entity', images: entity.images, podcasts: entity.podcasts })
        setSpinner(false)
        setLegal(initialValues)
        setPdfData([])
        toast.success(`Data Saved`);
    }

    const handleModeofUpload = (mode) => { if (!legal.mode) setLegal((prev) => { return { ...prev, mode } }) }
    const clearModeofUpload = (entityCount) => { if (entityCount === 0) setLegal((prev) => { return { ...prev, mode: '' } }) }

    const generatePdfData = () => {
        let pdf = []
        if (legal?.image?.length > 0 && legal.mode === 'upload_document') {
            const idList = legal.image.filter((elem) => elem.mode === legal.mode).map((elem) => elem.entity_id)
            let images = filter(entity.images, item => includes(idList, item.image_id))
            images = uniqBy(images, 'image_id')
            const imagesObject = groupBy(images, (elem) =>
                elem?.credits?.length > 0
                    ? elem.credits
                        .map((credit) => `${credit.person_id}-${credit.role_id}`)
                        .sort()
                        .join('|')
                    : uniqid()
            );
            const imagePdf = Object.keys(imagesObject).map((key) => {
                const commonUniqid = uniqid();
                return {
                    [commonUniqid]: imagesObject[key],
                    entity_type: 'image',
                    id: commonUniqid
                }
            }
            );
            pdf.push(...imagePdf)
        }
        if (legal?.podcast?.length > 0 && legal.mode === 'upload_document') {
            const idList = legal.podcast.filter((elem) => elem.mode === legal.mode).map((elem) => elem.entity_id)
            let podcasts = filter(entity.podcasts, item => includes(idList, item.podcast_id))
            podcasts = uniqBy(podcasts, 'podcast_id')
            const podcastsObject = groupBy(podcasts, (elem) =>
                elem?.credits?.length > 0
                    ? elem.credits
                        .map((credit) => `${credit.person_id}-${credit.role_id}`)
                        .sort()
                        .join('|')
                    : uniqid()
            );
            const podcastPdf = Object.keys(podcastsObject).map((key) => {
                const commonUniqid = uniqid();
                return {
                    [commonUniqid]: podcastsObject[key],
                    entity_type: 'podcast',
                    id: commonUniqid
                }
            }
            );
            pdf.push(...podcastPdf)
        }
        if (pdf?.length > 0) setPdfData(pdf); handleDocumentName(pdf)
    }

    const handleDocumentName = async (pdf) => {
        pdf.forEach((elem) => {
            if (elem.entity_type === "image") {
                elem[elem.id].forEach((data) => {
                    const index = legal.image.findIndex((elem) => elem.entity_id === data.image_id)
                    if (index !== -1) legal.image[index].document_name = elem.id
                })
            }
            if (elem.entity_type === "podcast") {
                elem[elem.id].forEach((data) => {
                    const index = legal.podcast.findIndex((elem) => elem.entity_id === data.podcast_id)
                    if (index !== -1) legal.podcast[index].document_name = elem.id
                })
            }
        })
        setLegal((prev) => { return { ...prev, image: legal.image, podcast: legal.podcast } })
        const postData = [...legal.image, ...legal.podcast]
        await _api.legal.bulkLegalize(postData)
        postData.forEach((data) => {
            if (data.entity_type === "image") {
                const index = entity.images.findIndex((elem) => data.entity_id === elem.image_id)
                if (index !== -1) entity.images[index].document_name = data.document_name
            }
            if (data.entity_type === "podcast") {
                const index = entity.podcasts.findIndex((elem) => data.entity_id === elem.podcast_id)
                if (index !== -1) entity.podcasts[index].document_name = data.document_name
            }
        })
        dispatch({ type: 'entity', images: entity.images, podcasts: entity.podcasts })
    }

    const handleEntitySelected = () => {
        const entityCount = legal?.image?.length + legal?.podcast?.length
        clearModeofUpload(entityCount)
    }

    const handlePdfUpload = (data) => {
        legal.image = []
        legal.podcast = []
        if (data?.length > 0) {
            const uniqueImages = uniqBy(entity.images, 'image_id').filter((elem) => !elem.is_legal && elem.type !== 'then_now');
            const uniquePodcasts = uniqBy(entity.podcasts, 'podcast_id').filter((elem) => !elem.is_legal)
            const imagePodcastdata = [...uniqueImages, ...uniquePodcasts]
            const dataSet = groupBy(imagePodcastdata, (elem) => elem.document_name ? elem.document_name : '')
            for (let i in data) {
                if (dataSet?.[data[i].id]?.length > 0) {
                    if (entity?.images?.length > 0) {
                        uniqueImages.forEach((elem) => {
                            if (elem.document_name === data[i].id) {
                                legal.image.push({
                                    entity_id: elem.image_id,
                                    entity_type: 'image',
                                    pdf_url: data[i].url,
                                    mode: 'upload_document',
                                    document_name: data[i].id
                                })
                            }
                        })
                    }
                    if (entity?.podcasts?.length > 0) {
                        uniquePodcasts.forEach((elem) => {
                            if (elem.document_name === data[i].id) {
                                legal.podcast.push({
                                    entity_id: elem.podcast_id,
                                    entity_type: 'podcast',
                                    pdf_url: data[i].url,
                                    mode: 'upload_document',
                                    document_name: data[i].id
                                })
                            }
                        })
                    }

                }
                else {
                    toast.error(`Uploaded PDF Name : ${data[i].id} has been Changed or Updating wrong PDF. Please Update Correct PDF`);
                }
            }
            setLegal((prev) => { return { ...prev, image: legal.image, podcast: legal.podcast, mode: 'upload_document' } })
        }
    }

    return (
        <Spinner display={spinner}>
            <PageTitle title={entity?.name || personName(entity)} />
            <hr className='cnow-hr' />
            <div className=''>
                <div className='col'>
                    <label className='cnow-form-title'>LEGAL *</label>
                </div>
                <div className='col-auto fw-bold'>
                    You can either sign electronically or download  a PDF approval form for individual approvals.
                </div>
            </div>
            {entity?.images?.length > 0 &&
                <ImageRelease id={entity?.id} handleImageClick={(image) => handleEntitySelect(image, 'image')} handleUploadMode={(mode) => { handleModeofUpload(mode) }} mode={legal.mode} />
            }
            {entity?.podcasts?.length > 0 &&
                <PodcastRelease id={entity?.id} handlePodcastClick={(podcast) => handleEntitySelect(podcast, 'podcast')} handleUploadMode={(mode) => { handleModeofUpload(mode) }} mode={legal.mode} />
            }
            <LegalScrollableBox isImagesToBeLegal={entity?.images?.filter((elem) => !elem.is_legal)?.length > 0} isPodcastToBeLegal={entity?.podcasts?.filter((elem) => !elem.is_legal)?.length > 0} />
            <PdfDownloadUpload pdf={pdfData} entity_title={`${entity?.name || personName(entity)}`} key={pdfData?.length} handlePdfUpload={(docs) => { handlePdfUpload(docs) }} mode={legal.mode} onDownloadPdf={() => { generatePdfData() }} />
            <hr className='cnow-hr' />
            <div className="container">
                <SignatureForm onChange={(signature, date) => { handleSignatureChange(signature, date) }} mode={legal.mode} />
                <div className="mt-5 ms-1">
                    <Button className='custom-button' size="sm" label="Submit" onClick={() => { submitLegalForms() }} />
                </div>
            </div>
        </Spinner>
    )
}

export default Legal