/* eslint-disable react-hooks/exhaustive-deps */
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import moment from 'moment';
import Button from '../../../components/buttons/default';
import { useHistory, useLocation } from 'react-router-dom';
import PreviewButton from '../../../components/buttons/preview';
import Pagination from "../../../components/pagination";
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import CreditList from '../../../components/table/creditList';
import LocationList from '../../../components/table/locationList';
import ImageRelatedItemList from '../../../components/table/imageRelatedItems';
import RelatedEntityList from '../../../components/table/relatedEntityList';
import TagList from '../../../components/table/tagList';
import TableHeader from '../../../components/tableHeader';
import Icon from '../../../components/viewers/icon';
import ImageView from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import ImageUpload from '../../image/upload'
import { differenceBy } from 'lodash';
import InThisPhotoList from '../../../components/table/inThisPhotoList'
import { useDispatch, useSelector } from 'react-redux';
import { dateString, fileFormat, TABLE_ROWS, cleanHtmlMeta, newTempId } from '../../../helpers';
import LinkButton from '../../../components/buttons/link';

const columns = require('../../../data/columns_images.json')
const _api = require('../../../api')

const FeaturedImages = (props) => {
    const dispatch = useDispatch();

    const history = useHistory();
    const location = useLocation();
    //   const [searchResult, setSearchResult] = useState([]);

    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');

    const take = 48;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);
    const [uploader, showUploader] = useState(false);
    const [view, setView] = useState('list');
    const [paginationHits, setPaginationHits] = useState(0)
    const [licensed, setLicensed] = useState(false)

    let section = props.section
    const searchImage = useSelector((state) => state.search);
    let searchResult = searchImage.searchResult || []
    let selectedResult = searchImage.selectedResult?.filter((elem) => elem.entity_type === 'image') || []
    let sectionResult = searchImage[section]?.filter((elem) => elem.entity_type === 'image') || []
    let selectedEntity = [...sectionResult, ...selectedResult]
    const featured = useSelector((state) => state.featured);

    const [resultFilters, setResultFilters] = useState({
        inThisPhoto: searchImage.inThisPhoto,
        limit: searchImage.selectedLimit
    })

    const [label, setLabel] = useState([])
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    useEffect(() => {
        search()
    }, [page, sort, keyword]);

    const search = async () => {
        showSpinner(true);

        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    {
                        "match": {
                            "deleted": "false"
                        }
                    },
                    { "match": { "created_via": "ADMIN" } },

                ]
            }
        };
        // const params = {
        //     keyword: keyword,
        //     hitsPerPage: take,
        //     page: page - 1,
        //     facetFilters: [
        //         [
        //             "deleted:false"
        //         ],
        //         [`type: ${section === 'then_now' ? section : ''}`]
        //     ]
        // }
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "caption^3",
                        "credits.person_name^2",
                        "related_item.categories.category_name",
                        "related_item.categories.subcategory_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_people.related_person_name",
                        "podcasts.podcast_name",
                        "credits.role_name",
                        "people.profession.profession_name",
                        "image_category",
                        "locations.address",
                        "theme.theme_name",
                        "related_item.name",
                        "related_item.themes.theme_name",
                        "type",

                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }
        if (section === 'then_now') {
            params.bool.must[params.bool.must.length] = {
                "bool": {
                    "should": [
                        {
                            "match": {
                                "type": 'then_now'
                            }
                        }
                    ]
                }
            }
        }
        if (resultFilters?.inThisPhoto) {

            params.bool.must[params.bool.must.length] = {
                "bool": {
                    "should": [
                        {
                            "match": {
                                "has_entity": true
                            }
                        }
                    ]
                }
            }
            // params.facetFilters.push(["has_entity: true"])
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }


        handleSetKeywords(keyword)
        _api.image.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
            setPaginationHits(results?.hits?.total?.value);
            const mappedHits = results?.hits?.hits.map(hit => hit._source);
            const filteredArray = differenceBy(mappedHits, selectedEntity, 'id')
            dispatch({ type: 'search', payload: { ...searchImage, searchResult: filteredArray } })
            showSpinner(false)
        })

        dispatch({ type: 'images', payload: {} })
    }

    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.inThisPhoto) data.push(`In this Photo tags`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes(`In this Photo tags`) && resultFilters?.inThisPhoto) resultFilters.inThisPhoto = false
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const deleteImage = (image) => {
        swal({
            title: `Delete Image`,
            text: `Are you sure that you want to delete ${image?.caption}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => { _api.image.deleteById(image?.id).then(() => { search() }) });
    };

    // const editImage = async (id, type, url) => {
    //     history.push(`/image/${id}/editor`)
    //     let imgs = []
    //     if (type === 'then_now') {
    //         let imgObj = {
    //             'id': id,
    //             'url': url
    //         }

    //         imgs = await _api.image.getRelatedImage(id)
    //         imgs.unshift(imgObj)
    //     }
    //     dispatch({ type: 'images', payload: { entityImages: imgs, source: location.pathname } })
    // }

    const downloadImage = (image) => {
        saveAs(image.url, image.caption)
    }

    const checkLicensed = (image) => {
        setLicensed(!licensed)
    }

    const onSave = (images) => {
        dispatch({ type: 'images', payload: { entityImages: images, source: location.pathname } })
        history.push({ pathname: `/image/${images[0].id}/editor` })
    }

    const selectedImages = (index) => {
        searchResult[index].entity_type = 'image'
        selectedResult.push(searchResult[index])
        searchResult.splice(index, 1)
        dispatch({
            type: 'search', payload: {
                ...searchImage,
                selectedResult: selectedResult,
                searchResult: searchResult
            }
        })
    }

    const removeSelectedImage = (index) => {
        if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
            let section_index = selectedEntity[index].section_index
            let entity_index = selectedEntity[index].entity_index
            featured[section][section_index].edited = true
            featured[section][section_index].data[section].splice(entity_index, 1)
            featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
            let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
            sectionResult.splice(sectionIndex, 1)
            sectionResult.forEach((elem, index) => elem.entity_index = index)
            dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
            dispatch({ type: 'search', payload: { ...searchImage, [`${section}`]: sectionResult } })
        }
        else {
            let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
            searchResult.push(selectedResult[searchResultIndex])
            selectedResult.splice(searchResultIndex, 1)
            dispatch({
                type: 'search', payload: { ...searchImage, selectedResult: selectedResult, searchResult: searchResult }
            })
        }

    }

    const selectedImagesDispatch = () => {
        if (selectedResult.length > 0) {
            let index = featured[`${section}`]?.length === 0 ? -1 : 0
            let data = {
                "start_date": start_date.slice(0, 10),
                "end_date": end_date.slice(0, 10),
                "section": `${section}`,
            }
            if (index === -1) {
                data.id = newTempId()
                data.data = {
                    [`${section}`]: selectedResult
                }
                featured[`${section}`].push(data)
            }
            else {
                featured[`${section}`][index].edited = true
                featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
            }
        }
        dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
        dispatch({ type: 'search', payload: {} })
        history.goBack()
    }




    return (
        <>
            <div className='container-fluid mt-3'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a image name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button label='Add an Image' color='danger' icon='plus-white' onClick={() => showUploader(true)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-3 p-0'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => selectedImagesDispatch()}>{`${searchImage.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                                <h6 className='mb-0'>IMAGES</h6>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={paginationHits} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search' key={`icon-${index}`}>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                            <div>
                                <div className='d-flex gap-2 align-self-end'>
                                    <div>
                                        {view === 'list' && <Button icon='grid' onClick={() => setView('grid')} />}
                                        {view === 'grid' && <Button icon='list' onClick={() => setView('list')} />}
                                    </div>
                                    <div>
                                        <div className='dropdown'>
                                            <button className='btn btn-light dropdown-toggle' type='button' id="sort" data-bs-toggle='dropdown' aria-expanded='false'>
                                                Sort by: {sort.name}
                                            </button>
                                            <ul className='dropdown-menu'>
                                                {columns.filter(x => x.sort === true).map((column, index) =>
                                                    <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        {view === 'list' &&
                            <div className='table-responsive cnow-table'>
                                <table className='table table-striped table-hover table-bordered'>
                                    <thead>
                                        <tr className='bg-light'>
                                            {columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-dark'>
                                            <th className='text-white' scope="col " colSpan="100%">
                                                <div className='d-flex ' style={{ width: "94vw" }}>
                                                    <span className=''>
                                                        {`${searchImage.title} IMAGES SELECTED`}
                                                    </span>
                                                    <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                                                </div>
                                            </th>
                                        </tr>
                                        {selectedEntity && selectedEntity.map((item, index) => {
                                            let inThisPhoto = []
                                            if (item.image_tags) item.image_tags.forEach(cat => inThisPhoto.push(cat))
                                            return (
                                                <tr key={`${item.id}-${index}`}>
                                                    <td>
                                                        <div className='row g-1'>
                                                            <div className='col-auto'>
                                                                <div className='form-check mt-1'>
                                                                    <input className='form-check-input align-middle' type='checkbox' name='select_image' value={item.id} onClick={() => removeSelectedImage(index)} defaultChecked={true} />
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='btn-list'>
                                                                    <LinkButton size='sm' icon='edit' to={`/image/${item.id}/editor`} target="_blank" />
                                                                    <Button size='sm' icon='delete' onClick={() => deleteImage(item)} />
                                                                    <PreviewButton size='sm' link={(item?.type === 'then_now' || item?.type === 'image' || item?.type === 'document') ? `image/${item.id}/preview/${item.id}` : `image/${item.id}`} />
                                                                    <Button size='sm' icon='download' onClick={() => downloadImage(item)} />
                                                                    {licensed ? <Button size='sm' icon='yes' onClick={() => checkLicensed(item)} /> :
                                                                        <Button size='sm' icon='no' color='danger' onClick={() => checkLicensed(item)} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><ImageView url={item.url} type='thumb' cssClass='thumb' /></td>
                                                    <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item?.caption}</LongTextView></td>
                                                    <td>{item?.image_category}</td>
                                                    <CreditList data={item.credits} />
                                                    <LocationList data={item.related_item} />
                                                    <ImageRelatedItemList data={item.related_item} />
                                                    <InThisPhotoList data={item.entity_tag} />
                                                    <td><ImageView url={item.url} type='banner' cssClass='banner' /></td>
                                                    <td>{item.id}</td>
                                                    <TagList data={item.tags} />
                                                    <td>{fileFormat(item?.url)}</td>
                                                    <td>{item.show_taken_date && dateString(item.taken_date)}</td>
                                                    <td><LongTextView lines={TABLE_ROWS + 1}>{item.description && cleanHtmlMeta(item.description)}</LongTextView></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                                                    <td>{item?.edited_by}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tbody>
                                        <tr className='bg-dark'>
                                            <th className='text-white' scope="col " colSpan="100%">
                                                <div className='d-flex ' style={{ width: "94vw" }}>
                                                    <span className=''>
                                                        {`${searchImage.title} IMAGES AVAILABLE`}
                                                    </span>
                                                    <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                                                </div>
                                            </th>
                                        </tr>
                                        {searchResult && searchResult.map((item, index) => {
                                            let inThisPhoto = []
                                            if (item.image_tags) item.image_tags.forEach(cat => inThisPhoto.push(cat))
                                            return (
                                                <tr key={`${item.id}`}>
                                                    <td>
                                                        <div className='row g-1'>
                                                            <div className='col-auto'>
                                                                <div className='form-check mt-1'>
                                                                    <input className='form-check-input align-middle' type='checkbox' name='select_image' value={item.id} onChange={(() => { selectedImages(index) })} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='btn-list'>
                                                                    <LinkButton size='sm' icon='edit' to={`/image/${item.id}/editor`} target="_blank" />
                                                                    <Button size='sm' icon='delete' onClick={() => deleteImage(item)} />
                                                                    <PreviewButton size='sm' link={(item?.type === 'then_now' || item?.type === 'image' || item?.type === 'document') ? `image/${item.id}/preview/${item.id}` : `image/${item.id}`} />
                                                                    <Button size='sm' icon='download' onClick={() => downloadImage(item)} />
                                                                    {licensed ? <Button size='sm' icon='yes' onClick={() => checkLicensed(item)} /> :
                                                                        <Button size='sm' icon='no' color='danger' onClick={() => checkLicensed(item)} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><ImageView url={item.url} type='thumb' cssClass='thumb' /></td>
                                                    <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item?.caption}</LongTextView></td>
                                                    <td>{item?.image_category}</td>
                                                    <CreditList data={item.credits} />
                                                    <LocationList data={item.related_item} />
                                                    <ImageRelatedItemList data={item.related_item} />
                                                    <InThisPhotoList data={item.entity_tag} />
                                                    <td><ImageView url={item.url} type='banner' cssClass='banner' /></td>
                                                    <td>{item.id}</td>
                                                    <TagList data={item.tags} />
                                                    <td>{fileFormat(item?.url)}</td>
                                                    <td>{item.show_taken_date && dateString(item.taken_date)}</td>
                                                    <td><LongTextView lines={TABLE_ROWS + 1}>{item.description && cleanHtmlMeta(item.description)}</LongTextView></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                                                    <td></td>
                                                    <td></td>
                                                    <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                                                    <td>{item?.edited_by}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                        </div>

                    </Spinner>
                </div>
            </div>

            {
                uploader && <ImageUpload
                    show={uploader}
                    onHide={() => showUploader(false)}
                    onSave={items => onSave(items)}
                    thenAndNow={true}
                />
            }

            <ScrollToTop />
        </>
    );
};

export default FeaturedImages;