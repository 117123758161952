import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../components/buttons/default';
import Icon from '../../components/viewers/icon';

const menu = require('../../data/menu_recipe.json');

const RecipeMenu = (props) => {
    const dispatch = useDispatch();

    const recipe = useSelector(state => state[props.id]);
    const menuSize = useSelector((state) => state.menu);
    const toggleMenuSize = () => dispatch({ type: 'menu', payload: menuSize === 'sm' ? 'lg' : 'sm' })

    const hasData = (path) => {
        let hasData = false;

        if (path === 'title') hasData = recipe?.name?.length > 0 || recipe?.categories?.length || recipe?.themes?.length;
        if (path === 'people') hasData = recipe?.people?.length > 0;
        if (path === 'about') hasData = recipe?.description?.length > 0 || recipe?.tags?.length > 0 || recipe?.stories?.length > 0 || recipe?.related_sites?.length > 0;
        if (path === 'related') hasData = recipe?.related_sites?.length > 0 || recipe?.related_events?.length > 0 || recipe?.related_event_series?.length > 0 || recipe?.related_playlists?.length > 0;;
        if (path === 'attach_map') hasData = recipe?.related_maps?.length > 0;
        if (path === 'featured_projects') hasData = recipe?.featured?.length > 0;
        if (path === 'images') hasData = recipe?.images?.length > 0;
        if (path === 'podcast') hasData = recipe?.podcasts?.length > 0 || recipe?.playlists?.length > 0
        if (path === 'legal') hasData = recipe?.images?.filter((elem) => !elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || recipe?.podcasts?.filter((elem) => !elem?.is_legal)?.length > 0
        else if (path === 'curator') hasData = recipe?.curator !== null || recipe?.images?.filter((elem) => elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || recipe?.podcasts?.filter((elem) => elem?.is_legal)?.length > 0

        return hasData;
    }

    return (
        <>
            {
                menuSize === 'sm' && <>
                    <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    <div className='btn-list mt-4 text-center'>
                        {
                            menu.map((item, index) => {
                                let icon = item?.icon;
                                if (hasData(item.path)) icon = `${item.icon}-done`;
                                if (props.section === item.path) icon = `${item.icon}-active`

                                return (
                                    <div key={index} className='mt-2'>
                                        <OverlayTrigger placement='right' overlay={<Tooltip>{item?.name}</Tooltip>}>
                                            <button className='btn' onClick={() => { if (!props.display) props.onClick(item.path) }} data-bs-toggle='tooltip' data-bs-placement='right' title='Tooltip on right'>
                                                <Icon name={icon} size={24} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            {
                menuSize === 'lg' && <>
                    <div className='d-flex justify-content-between'>
                        <h5>Add a Recipe</h5>
                        <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                    </div>

                    <ul className='list-group'>
                        {
                            menu.map((item, index) => {
                                let itemClass = 'list-group-item cnow-list-group-item text-start';
                                if (props.section === item.path) itemClass = `${itemClass} active`;
                                if (!item.enabled) itemClass = `${itemClass} disabled`
                                if (!recipe?.id) itemClass = `${itemClass} disabled`;
                                return (
                                    <li key={index} className={itemClass}>
                                        <div className='row g-1'>
                                            <div className='col-auto pt-1'>
                                                <i className={`fe fe-check ${hasData(item?.path) ? '' : 'cnow-text-light'}`} />
                                            </div>
                                            <div className='col'>
                                                <button className={itemClass} onClick={() => { if (!props.display) props.onClick(item.path) }}>

                                                    {item.name}
                                                    {item.required && <span className='ms-1'>*</span>}
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            item.path === 'location' && recipe?.locations && recipe?.locations.map((location, index) => {
                                                let subItemClass = 'list-group-item cnow-list-group-item text-start';
                                                if (props.sectionId === location.id) subItemClass = `${subItemClass} active`;

                                                return (
                                                    <div className='small ms-5' key={index}>
                                                        <button className={subItemClass} onClick={() => { if (!props.display) props.onClick(`${item.path}/${location.id}`) }} >
                                                            {location.name || `Location ${index + 1}`}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>

                </>
            }
        </>
    )
}

export default RecipeMenu;