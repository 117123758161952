import React, { useState } from 'react';
import uniqid from 'uniqid'
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import AwardEditor from '../../components/editors/awardEditor';
import HighlightEditor from '../../components/editors/highlightEditor';
import { personName } from '../../helpers';

const PeopleAwardDesignation = (props) => {
	const person = useSelector(state => state[props.id]);
	const [key, setKey] = useState(uniqid())
	const getAwardListType = () => {
		let award_type = '';
		if (person) {
			if (person.type === 'person') {
				award_type = 'award_person';
			} else if (person.type === 'organization') {
				award_type = 'award_organization';
			} else if (person.type === 'publication') {
				award_type = 'award_publication';
			}
		}
		return award_type;
	}

	return (
		<>
			{
				person && <>
					<PageTitle title={personName(person)} />
					<AwardEditor id={props.id} type={getAwardListType()} key={`award-editor-${key}`} showDate={false} refresh={() => { setKey(uniqid()) }} entity={'person'} />
					<HighlightEditor id={props.id} type='people' key={`highlight-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
				</>
			}
		</>
	);
};

export default PeopleAwardDesignation;