import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../helpers';
import Button from '../buttons/default';
import NoOptionsMessage from './NoOptionsMessage';
import { debounce } from 'lodash';

const globalList = require('../../data/global_list.json');
const _api = require('../../api')

const SelectGlobalList = (props) => {
    const [modal, showModal] = useState(false);
    const [item, setItem] = useState('');

    const list = globalList.filter(x => x.id === props.type)[0];
    let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.name } : {};

    const items = async (keyword,callback) => {
            let items = [];
            let response = await _api.global_list.getAll(props.type, keyword);
            response.forEach(data => items.push({ 'value': data.id, 'label': data.name }));
            if (items.length === 0) items.push({ 'value': 'NEW', 'label': `Don't see what you're looking for?`, 'data': keyword })
            callback(items)
            return items;
    }

    const handleChange = (e) => {
        if (e.value === 'NEW') {
            setItem(e.data);
            showModal(true);
        } else {
            props.onChange({ 'id': e.value, 'name': e.label });
        }
    }

    const addItem = () => {
        _api.global_list.create(props.type, {
            name: item
        }).then(response => {
            props.onChange({ 'id': response.id, 'name': response.name });
            showModal(false);
        })
    }

    const debouncedSuggestions = debounce((keyword, callBack) => {
		items(keyword, callBack)
	}, 500)

    return (
        <>
            <AsyncSelect cacheOptions defaultOptions
                styles={customSelectStyle}
                loadOptions={debouncedSuggestions}
                value={selected}
                onChange={handleChange}
                components={{ NoOptionsMessage }}
            />

            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <div className='cnow-form-title'>Add a {list?.name}</div>
                    <hr />
                    <p>Don't see what you're looking for? <br />Add one here.</p>
                    <input type='text' className='form-control' value={item} onChange={(e) => setItem(e.target.value)} autoComplete="off" />
                    <p>If our curators have any questions we will get back to you.</p>
                    <Button label='Save' onClick={() => addItem()} />
                </Modal.Body>
            </Modal>

        </>
    );
};

export default SelectGlobalList;