import React from 'react';
import { dateString, TABLE_ROWS } from '../../helpers';

const DateList = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return (
                        <div key={index} className='text-truncate'>
                            <i>{item.event_type_name + ': '}</i> {dateString(item)}
                        </div>
                    )
                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default DateList;