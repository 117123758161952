import React, { useState, useEffect } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import { useDispatch, useSelector } from 'react-redux';
import { newTempId } from '../../helpers';
import FeaturedTitle from './title';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
const AboutTheMuseum = (props) => {
    const dispatch = useDispatch();
    const [editedIndex, setEditedIndex] = useState(-1);
    const [descriptionData, setDescriptionData] = useState([])
    const [headingData, setHeadingData] = useState([]);
    const featured = useSelector((state) => state.featured);
    const start_date = featured && featured.start_date && JSON.parse(featured.start_date)

    const history = useHistory();
    const about_the_museum_without_walls = featured.about_the_museum_without_walls
    let data = `<p>Consider the world outside a museum. Imagine that the world that we live in is really another kind of museum where the works of art exist in the landscape itself. What if you could have a gallery guide which would tell you about the buildings and artworks you find around you. It would show you what the place used to look like and introduce you to some of the people who shaped it.</p>
    <p>Our growing virtual collection of photographs and drawings, maps and documents, podcasts and videos tell the stories of how some of the more iconic places in our cities got to be the way they are and what they might become.</p>
    <p>Explore buildings of the past, present and future. Look at the vast selection of artwork that graces the public realm. And discover how places have evolved over time. Deconstruct the layers of history that form the fabric of our urban landscape. Meet people who have made their mark on our cities and country who have lived in the past or are living now. Listen to their voices. Take (or make) a tour. And join us at an event either virtual or real.</p>
    <p>Our curators are the artists, architects, photographers and historians who created the images, podcasts and videos to share their knowledge and insights.
    Our collaborators are museums, universities, cities, and civic organizations who are the stewards of our shared cultural history.</p>
   <p>Use the guide online or take it with you on your phone…..</p>
   <p>Like the cities we live in, this is a work in progress….. Enjoy!</p>`
    let dataHeading = `<h2><span style="color:#a82829;"><strong>${moment.unix(moment.utc(start_date).unix()).format("MMMM Do,YYYY")} </strong></span></h2>`;
    // "<h2><span style="color:#a82829;"><strong>April 18th 2024 On this day</strong></span></h2>"

    useEffect(() => { formatAboutTheMuseum() }, /* eslint-disable react-hooks/exhaustive-deps */[about_the_museum_without_walls?.[0]?.data?.description])

    const formatAboutTheMuseum = () => {
        let data = []
        let headingArray = [];
        if (about_the_museum_without_walls)
            for (let i in about_the_museum_without_walls) {
                if (about_the_museum_without_walls[i]?.data?.description) {
                    data.push(about_the_museum_without_walls[i]?.data?.description)
                }
                if (about_the_museum_without_walls[i]?.data?.heading) {
                    headingArray.push(about_the_museum_without_walls[i]?.data?.heading);
                }

            }
        setDescriptionData(data)
        setHeadingData(headingArray);


    }

    const handleDescriptionChange = (value) => {
        let index = about_the_museum_without_walls?.length === 0 ? -1 : 0
        if (index === -1) {
            let start_date = featured.start_date && JSON.parse(featured.start_date)
            let end_date = featured.end_date && JSON.parse(featured.end_date)
            let data = {
                "start_date": start_date.slice(0, 10),
                id: newTempId(),
                "end_date": end_date.slice(0, 10),
                "section": "about_the_museum_without_walls",
            }
            featured.about_the_museum_without_walls = []
            data.data = { description: value }
            featured.about_the_museum_without_walls.push(data)
        }
        else {
            featured.about_the_museum_without_walls[index].edited = true
            if (!featured.about_the_museum_without_walls[index].data.description_original) {
                featured.about_the_museum_without_walls[index].data.description_original = featured.about_the_museum_without_walls[index].data.description
            }
            featured.about_the_museum_without_walls[index].data.description = value
            // featured.about_the_museum_without_walls[index].data.heading = headingData[0] ? headingData[0] : dataHeading;
        }
        dispatch({ type: 'featured', payload: { ...featured } })
    }
    const handleHeadingChange = (value) => {
        let index = about_the_museum_without_walls?.length === 0 ? -1 : 0;
        if (index === -1) {
            // Create new data object if it doesn't exist
            let start_date = featured.start_date && JSON.parse(featured.start_date);
            let end_date = featured.end_date && JSON.parse(featured.end_date);
            let data = {
                "start_date": start_date?.slice(0, 10),
                id: newTempId(),
                "end_date": end_date?.slice(0, 10),
                "section": "about_the_museum_without_walls",
            };
            featured.about_the_museum_without_walls = [];
            data.data = { heading: value };
            featured.about_the_museum_without_walls.push(data);
        } else {
            // Update existing data object
            featured.about_the_museum_without_walls[index].edited = true;
            featured.about_the_museum_without_walls[index].data.heading = value;
            // featured.about_the_museum_without_walls[index].data.description = descriptionData[0] ? descriptionData[0] : data;
        }
        dispatch({ type: 'featured', payload: { ...featured } });
    };
    // const handleDescriptionReset = () => {
    //     let index = about_the_museum_without_walls?.length === 0 ? -1 : 0
    //     if (featured.about_the_museum_without_walls[index].data.description_original) {
    //         featured.about_the_museum_without_walls[index].edited = true
    //         featured.about_the_museum_without_walls[index].data.description = featured.about_the_museum_without_walls[index].data.description_original
    //         dispatch({ type: 'featured', payload: { ...featured } })
    //     }
    // }

    return (

        <div>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="ABOUT THE MUSEUM WITHOUT WALLS"
                        count={`1 item`}
                        button={{ edit: { show: true, onHandle: () => { setEditedIndex(0) } } }}
                        page={props?.page}
                    >
                        {descriptionData.length > 0 ? descriptionData.map((data, index) => {
                            return (
                                <div className='d-flex flex-row gap-4' key={`${index}-${editedIndex}`}>
                                    <div className={`mt-2`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') setEditedIndex(index) }}>
                                        {headingData?.length > 0 ? <div dangerouslySetInnerHTML={{ __html: headingData[0] }} className=' bg-light' /> : <div dangerouslySetInnerHTML={{ __html: dataHeading }} className=' bg-light' />}
                                        <div dangerouslySetInnerHTML={{ __html: data }} className=' bg-light' />
                                    </div>
                                </div>
                            )
                        })
                            :
                            <>
                                <div className='d-flex flex-row gap-4 bg-light mt-3'>
                                    <div className={`mt-2`} style={{ border: `${editedIndex === 0 ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') setEditedIndex(0) }}>
                                        <div dangerouslySetInnerHTML={{ __html: dataHeading }} />
                                        <div dangerouslySetInnerHTML={{ __html: data }} />
                                    </div>
                                </div>
                            </>
                        }
                    </CollapsableSection>
                    {editedIndex !== -1 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{ save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } } }}>
                        <CollapsableSection textColor="text-black" collapsable={false} title="WEBSITE TEXT" />
                        <div className='mt-2 mx-5'>
                            <TextEditor heading={true} data={headingData?.length > 0 ? featured.about_the_museum_without_walls[editedIndex].data.heading : dataHeading} onChange={handleHeadingChange} limit="50" >
                                <label className='cnow-form-label mt-2 fw-bold'>Edit Heading</label>
                            </TextEditor>
                        </div>
                        <div className='mt-2 mx-5'>
                            <TextEditor homePage={true} date_id={null} data={descriptionData?.length > 0 ? featured.about_the_museum_without_walls[editedIndex].data.description : data} onChange={(data) => { handleDescriptionChange(data) }} limit="50" key={`TextEditor-${editedIndex}`} showRemarksAttribution={true} entity_id={featured?.about_the_museum_without_walls?.[0]?.id} >
                                <label className='cnow-form-label mt-2 fw-bold'>Edit Text</label>
                            </TextEditor>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default AboutTheMuseum