import React from 'react'

import Button from '../buttons/default';
import ImageViewer from '../viewers/image';

const PlaylistImageEditor = (props) => {
    return (
        <div className='d-flex justify-content-between mt-3'>
            <div className='flex-grow-1'>
                <div className='cnow-playlist-image-thum-box mx-auto'>
                    <div className='d-flex'>
                        <div className='border' style={{ width: '350px', height: '270px' }}>
                            {props.selected?.[0]?.url && <ImageViewer url={props.selected?.[0]?.url} type="thumb@2x" cssClass="playlist-thumb" />}
                        </div>
                        <div className='border' style={{ width: '350px', height: '270px' }}>
                            {props.selected?.[1]?.url && <ImageViewer url={props.selected?.[1]?.url} type="thumb@2x" cssClass="playlist-thumb" />}
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='border' style={{ width: '350px', height: '270px' }}>
                            {props.selected?.[2]?.url && <ImageViewer url={props.selected?.[2]?.url} type="thumb@2x" cssClass="playlist-thumb" />}
                        </div>
                        <div className='border' style={{ width: '350px', height: '270px' }}>
                            {props.selected?.[3]?.url && <ImageViewer url={props.selected?.[3]?.url} type="thumb@2x" cssClass="playlist-thumb" />}
                        </div>
                    </div>
                </div>
            </div>
            <div className='btn-list'>
                <Button size='sm' label='Save' onClick={props.handleSave} />
                <Button size='sm' icon='close' onClick={props.handleCancel} />
            </div>
        </div>
    )
}

export default PlaylistImageEditor