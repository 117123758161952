/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import { dateString, handleReorder, getNewItemIndex, getRole, getOffsetIndex } from '../../helpers';
import Button from '../buttons/default';
import LinkButton from '../buttons/link';
import PreviewButton from '../buttons/preview';
import SelectPerson from '../selects/person';
import ImageViewer from "../viewers/image";
import SelectGlobalList from '../selects/globalList';
import IndiviualCreditsPerson from '../selects/indiviualCreditsPerson';

const _api = require('../../api')

const PeopleEditor = (props) => {
    const labelGlobalList = props?.labelGlobalList || 'Role *';
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity?.dates && entity?.dates?.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.people, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, people: items } })
            props.refresh && props.refresh()
        })
    }, [dragula,])
    // Dragula End

    const addPerson = (date_id) => {
        if (!entity.people) entity.people = [];
        let index = getNewItemIndex(entity.people, entity.dates, date_id)

        let people_item = {
            'date_id': date_id,
            'id': `temp.${uniqid()}`,
            'person_first_name': '',
            'person_last_name': '',
            'person_id': null,
            'person_image': '',
            'person_name': '',
            'role_id': null,
            'role_name': '',
            'is_participant': false,
            'sort': index
        }
        people_item[`${props.type}_id`] = entity.id
        entity.people.splice(index, 0, people_item);
        setDragula(uniqid())
        entity.people.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });
    }

    const addIndiviualCredits = (date_id, index) => {
        if (entity.people.individual_credits) entity.people[index].individual_credits = []

        let individual_credits_item = {
            'id': `temp.${uniqid()}`,
            'person_first_name': '',
            'person_last_name': '',
            'person_id': null,
            'person_image': '',
            'person_name': '',
            'role_id': null,
            'role_name': '',
            'is_participant': false,
            'site_id': entity.id
        }
        entity.people[index].individual_credits.push(individual_credits_item);

        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });

    }

    const removePerson = async (index) => {
        if (entity.people[index]) {
            if (!entity.people[index].id.startsWith('temp.')) await _api[props.type].deletePerson(entity.people[index].id)

            entity.people.splice(index, 1)
            entity.people.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });
        }
    }


    const removeIndiviualCredits = async (index, individual_index) => {
        if (entity.people[index].individual_credits[individual_index]) {
            if (!entity.people[index].individual_credits[individual_index].id.startsWith('temp.')) await _api.site.deleteIndiviualCredits(entity.people[index].individual_credits[individual_index].id)

            entity.people[index].individual_credits.splice(individual_index, 1)
            dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });
        }
    }

    const handlePersonChange = (index, value) => {
        if (props.type === 'site' || props.type === 'map') {
            entity.people[index].person_id = value.id;
            entity.people[index].person_name = value.name;
            entity.people[index].is_participant = value.is_participant;
            entity.people[index].person_type = value.type;
            entity.people[index].affiliations = value.affiliations;
        }
        else if (props.type === 'playlist') {
            entity.people[index].person_id = value.id;
            entity.people[index].person_name = value.name;
            entity.people[index].role_name = value.role_name;
            entity.people[index].role_id = value.role_id;
            entity.people[index].is_participant = value.is_participant;
        }
        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } })
    };

    const handleIndividualCredit = (index, indiviual_index, value) => {
        entity.people[index].individual_credits[indiviual_index].credited_person_id = value?.id
        entity.people[index].individual_credits[indiviual_index].person_id = value?.parentId
        entity.people[index].individual_credits[indiviual_index].type = 'person'
        entity.people[index].individual_credits[indiviual_index].person_name = value?.name

        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } })
    }

    const handleRoleChange = (index, value) => {
        entity.people[index].role_id = value.id;
        entity.people[index].role_name = value.name;
        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });
    }

    const handleIndiviualRoleChange = (index, indiviual_index, value) => {
        entity.people[index].individual_credits[indiviual_index].credited_person_role_id = value.id
        entity.people[index].individual_credits[indiviual_index].role_name = value.name
        dispatch({ type: 'entity', payload: { ...entity, people: entity.people } });
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.title}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Person' onClick={() => addPerson(null)} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    entity?.people?.length > 0 && entity.people.filter(x => x.date_id === null).map((item, index) => {
                        return (
                            <div>

                                <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                    {
                                        entity?.people?.length > 1 &&
                                        <div className='col-auto' >
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                            <input type='text' id={`${item.person_name}, ${item.role_name}, ${item.person_id}, ${item.role_id}`} className='nwprime form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col-auto'>
                                        {index === 0 && <div className=''>&nbsp;</div>}
                                        <ImageViewer key={item.id} entityType='person' entityId={item.person_id} type='thumb' cssClass='thumb-small' />
                                    </div>

                                    <div className='col-auto d-flex flex-grow-1'>
                                        <div className='w-100'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Name *</label>}
                                            <SelectPerson key={item.id} value={item} className="prime" onChange={(value) => handlePersonChange(index, value)} />
                                        </div>
                                        <div className='w-75 ms-2'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>{labelGlobalList}</label>}
                                            {
                                                props.type === 'site' || props.type === 'map' ?
                                                    <SelectGlobalList key={item.id} type={`${props.type}_person_role`} className="prime" value={{ id: item.role_id, name: item.role_name }} onChange={(value) => handleRoleChange(index, value)} /> :
                                                    <input value={getRole(item)} disabled className='w-100 cnow-input-disabled-height' />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        {index === 0 && <label className='cnow-form-label'></label>}
                                        <div className='btn-list'>
                                            {((item?.person_type === "publication" || item?.person_type === "organization") && (props?.type === 'site')) && <Button className={item?.affiliations?.length ? '' : 'disabled'} icon='plus' onClick={() => addIndiviualCredits(null, index)} />}
                                            <PreviewButton link={`${!item.is_participant ? 'people' : 'participant'}/${item.person_id}`} />
                                            <LinkButton icon='edit' to={`/${!item.is_participant ? 'people' : 'participant'}/${item.person_id}/editor`} />
                                            <Button icon='delete' onClick={() => removePerson(index)} />

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {

                                        item?.individual_credits?.length > 0 && item.individual_credits?.map((indiviual_item, individual_index) => {
                                            return (
                                                <div className='row g-1 mt-1 ms-5'>

                                                    <div className='col-auto'>
                                                        <ImageViewer key={indiviual_item.id} entityType='person' entityId={indiviual_item.credited_person_id} type='thumb' cssClass='thumb-small' />
                                                    </div>

                                                    <div className='col-auto d-flex flex-grow-1'>
                                                        <div className='w-100'>
                                                            <IndiviualCreditsPerson key={indiviual_item.id} individual_credits={indiviual_item} options={item?.affiliations} onChange={(value) => handleIndividualCredit(index, individual_index, value)} />
                                                        </div>
                                                        <div className='w-75 ms-2'>
                                                            {
                                                                props.type === 'site' || props.type === 'map' ?
                                                                    <SelectGlobalList key={indiviual_item.id} type={`${props.type}_person_role`} className="prime" value={{ id: indiviual_item.credited_person_role_id, name: indiviual_item.role_name }} onChange={(value) => handleIndiviualRoleChange(index, individual_index, value)} /> :
                                                                    <input value={getRole(indiviual_item)} disabled className='w-100 cnow-input-disabled-height' />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}`} />
                                                            <LinkButton icon='edit' to={`/${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeIndiviualCredits(index, individual_index)} />

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                entity?.dates?.length > 0 && <>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>People/Organization/Publication, By Dates</div>
                    {
                        entity?.dates?.map((date, index) => {
                            let offset = getOffsetIndex(entity?.people, entity?.dates, date.id)
                            return (
                                <div key={index}>
                                    <div className='mb-3' >
                                        <div className='row'>
                                            <div className='col-3'>
                                                <label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <Button size='sm' icon='plus' label='Add a Person' onClick={() => addPerson(date.id)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`${dragulaContainer}-${date.id}`} key={`${dragula}${date.id}`} className='dragula-container'>
                                        {
                                            entity.people?.length > 0 && entity.people?.filter(x => x.date_id === date.id)?.map((person, index) => {
                                                const personIndex = entity.people.findIndex(x => x.id === person.id)
                                                return (
                                                    <div className='mb-2 dragula-item' id={person.id} key={index}>
                                                        <div className="row g-1 mt-1" >
                                                            {entity.people?.length > 1 &&
                                                                <div className={entity.people?.length > 1 ? 'col-auto' : 'd-none'}>
                                                                    {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                                                    <input type='text' className='dateprime form-control form-control-sort' disabled name='sort' placeholder={offset + index + 1} autoComplete="off" />
                                                                </div>
                                                            }
                                                            <div className='col-auto'>
                                                                {index === 0 && <div className=''>&nbsp;</div>}
                                                                <ImageViewer entityType='person' entityId={person.person_id} type='thumb' cssClass='thumb-small' />
                                                            </div>
                                                            <div className='col-auto d-flex flex-grow-1'>
                                                                <div className='w-100'>
                                                                    {index === 0 && <label className='cnow-form-label fw-bold'>Name *</label>}

                                                                    <SelectPerson value={person} className="prime" onChange={(value) => handlePersonChange(personIndex, value)} />
                                                                    <input className='dateid' id={person.id} hidden></input>
                                                                </div>
                                                                <div className='w-75 ms-2'>
                                                                    {index === 0 && <label className='cnow-form-label fw-bold'>{labelGlobalList}</label>}
                                                                    {
                                                                        props.type === 'site' || props.type === 'map' ?
                                                                            <SelectGlobalList type={`${props.type}_person_role`} className="prime2" value={{ id: person?.role_id, name: person.role_name }} onChange={(value) => handleRoleChange(personIndex, value)} /> :
                                                                            <input value={person.role} disabled className='w-100 cnow-input-disabled-height' />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                {index === 0 && <label className='cnow-form-label'></label>}
                                                                <div className='btn-list'>
                                                                    {((person?.person_type === "publication" || person?.person_type === "organization") && (props?.type === 'site')) && <Button className={person?.individual_credits < person?.affiliations ? '' : 'disabled'} icon='plus' onClick={() => addIndiviualCredits(date.id, offset + index)} />}
                                                                    <PreviewButton link={`${!person?.is_participant ? 'people' : 'participant'}/${person?.person_id}`} />
                                                                    <LinkButton icon='edit' to={`/${!person?.is_participant ? 'people' : 'participant'}/${person?.person_id}/editor`} />
                                                                    <Button icon='delete' onClick={() => removePerson(personIndex)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            person?.individual_credits?.length > 0 && person.individual_credits?.map((indiviual_item, individual_index) => {
                                                                return (
                                                                    <div className='row g-1 mt-1'>

                                                                        <div className='col-auto'>
                                                                            {individual_index === 0 && <div className=''>&nbsp;</div>}
                                                                            <ImageViewer key={indiviual_item.id} entityType='person' entityId={indiviual_item.person_id} type='thumb' cssClass='thumb-small' />
                                                                        </div>

                                                                        <div className='col-auto d-flex flex-grow-1'>
                                                                            <div className='w-100'>
                                                                                {individual_index === 0 && <label className='cnow-form-label fw-bold'>Name *</label>}
                                                                                <IndiviualCreditsPerson key={indiviual_item.id} individual_credits={indiviual_item} options={person?.affiliations} onChange={(value) => handleIndividualCredit(offset + index, individual_index, value)} />
                                                                            </div>
                                                                            <div className='w-75 ms-2'>
                                                                                {individual_index === 0 && <label className='cnow-form-label fw-bold'>{labelGlobalList}</label>}
                                                                                {
                                                                                    props.type === 'site' || props.type === 'map' ?
                                                                                        <SelectGlobalList key={indiviual_item.id} type={`${props.type}_person_role`} className="prime" value={{ id: indiviual_item.credited_person_role_id, name: indiviual_item.role_name }} onChange={(value) => handleIndiviualRoleChange(offset + index, individual_index, value)} /> :
                                                                                        <input value={getRole(indiviual_item)} disabled className='w-100 cnow-input-disabled-height' />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-auto'>
                                                                            {individual_index === 0 && <label className='cnow-form-label'></label>}
                                                                            <div className='btn-list'>
                                                                                <PreviewButton link={`${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}`} />
                                                                                <LinkButton icon='edit' to={`/${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}/editor`} />
                                                                                <Button icon='delete' onClick={() => removeIndiviualCredits(offset + index, individual_index)} />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </>
    )
}

export default PeopleEditor;