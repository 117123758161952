import React from "react";
import Button from "../../../components/buttons/default";


const FormLayout = ({ heading, children, button, icon, label, onClick, restOfButtons }) => {
    return (
        <>
            <div className={`sm-mt-2 mt-5`}>
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div className='card w-75'>
                        <div className='card-header d-flex justify-content-between align-items-center'>
                            <div className="fw-bold">
                                {heading}
                            </div>
                            <div>
                                {restOfButtons}
                                {button && <Button size='sm' icon={icon} label={label} onClick={onClick} />}
                            </div>
                        </div>
                        <div className='card-body'>
                            {children}
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default FormLayout