import React from "react";
import PageTitle from "../../components/pageTitle";
import moment from "moment";
import { useSelector } from 'react-redux';
const FeaturedTitle = (props) => {
    const featured = useSelector((state) => state.featured);
    const start_date = (featured && featured.start_date) ? JSON.parse(featured.start_date) : ''
    const end_date = (featured && featured.end_date) ? (JSON.parse(featured.end_date) || start_date) : ''
    let start = moment(start_date).format("MMMM Do YYYY");
    let end = moment(end_date).format("MMMM Do YYYY");
    return (
        <>
            {props?.page !== 'home' && <div className='d-flex justify-content-between align-items-center'>
                <PageTitle title={props.title} />
                <div className='fw-bold'>{start_date === end_date ? start : `${start} - ${end}`}</div>
            </div>}
        </>
    )
}

export default FeaturedTitle