import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import Button from '../../components/buttons/default';
import { handleReorder, newTempId } from '../../helpers';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
const _api = require('../../api');

const Location = (props) => {
    const dispatch = useDispatch();
    let person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-location'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.locations, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, locations: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End
    const handleChange = ({ target: { name, checked } }) => {
        dispatch({ type: 'entity', payload: { ...person, [name]: checked } });
    }

    //#region Location Section

    const addLocation = () => {
        let locations = person.locations || []
        locations.push({
            'id': newTempId(),
            'name': '',
            'description': '',
            'address': '',
            'city': '',
            'state': '',
            'country': '',
            'postal_code': '',
            'google_location': {},
            'location': {},
            'alias': '',
            'sort': locations.length + 1
        })

        dispatch({ type: 'entity', payload: { ...person, locations: locations } });
    }

    const removeLocation = async (id) => {
        person.locations = person.locations.filter(function (item) { return item.id !== id; });
        person.locations.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...person, locations: person.locations } });
        if (!id.startsWith('temp.')) await _api.person.deleteLocation(id)
    }

    const handleLocationChange = ({ target: { name, value, dataset } }) => {
        const index = dataset.id;
        if (name === 'lat' || name === 'lng') person.locations[index].location[name] = parseFloat(value);
        else person.locations[index][name] = value;
        dispatch({ type: 'entity', payload: { ...person, locations: person.locations } });
    }

    const handleAddressChange = (index, address) => {
        person.locations[index].address = address;
        dispatch({ type: 'entity', payload: { ...person, locations: person.locations } });
    }

    const handleAddressSelect = async (index, address, placeId, suggestion) => {
        let results = await geocodeByPlaceId(placeId);
        if (results.length > 0) {
            person.locations[index].google_location = results[0];
            person.locations[index].address = results[0].formatted_address
            person.locations[index].city = results[0].address_components.filter(x => x.types.includes('locality'))[0]?.long_name;
            person.locations[index].state = results[0].address_components.filter(x => x.types.includes('administrative_area_level_1'))[0]?.long_name;
            person.locations[index].country = results[0].address_components.filter(x => x.types.includes('country'))[0]?.long_name;
            person.locations[index].postal_code = results[0].address_components.filter(x => x.types.includes('postal_code'))[0]?.long_name;
            person.locations[index].location = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
            }
            dispatch({ type: 'entity', payload: { ...person, locations: person.locations } });
        }
    }

    //#endregion
    return (
        <div id='locations'>
            <hr className='cnow-hr' />

            <div className='row g-1'>
                <div className='col'>
                    <div className='form-group'>
                        <div className='cnow-form-title'>Location</div>
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='form-check'>
                        <input className='form-check-input' name='hide_location' type='checkbox' checked={person.hide_location || false} onChange={handleChange} />
                        <label className='small'>Do not show on the frontend</label>
                    </div>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add an Address' onClick={() => addLocation()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula}>
                {
                    person?.locations?.map((location, index) => {
                        return (
                            <div className='row g-1 mt-2 dragula-item' key={index} id={location.id} >
                                {
                                    person.locations.length > 1 &&
                                    <div className='col-auto'>
                                        <label className='cnow-form-label fw-bold'>{index === 0 ? 'Order' : ''}</label>
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={location.sort} onChange={handleLocationChange} data-id={index} autoComplete="off" />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='form-group'>
                                        <label className='cnow-form-label fw-bold'>Location Name</label>
                                        <input name='name' type='text' className='form-control' value={location.name || ''} onChange={handleLocationChange} data-id={index} autoComplete="off" />
                                    </div>
                                    <div className='form-group'>
                                        <label className='cnow-form-label fw-bold'>Address</label>
                                        <PlacesAutocomplete value={location.address || ''} onChange={(address) => handleAddressChange(index, address)} onSelect={(address, placeId, suggestion) => handleAddressSelect(index, address, placeId, suggestion)}>
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                <div>
                                                    <input {...getInputProps({ className: 'form-control' })} />
                                                    <div className='autocomplete-container'>
                                                        {
                                                            suggestions.map((suggestion, index) => {
                                                                return (
                                                                    <div key={index} className='suggestion-item' {...getSuggestionItemProps(suggestion)}>
                                                                        {suggestion.description}
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <label className='cnow-form-label mt-3 fw-bold'>Google Coordinates</label>
                                    <div className='row g-1'>
                                        <div className='col'>
                                            <label className='cnow-form-label fw-bold'>Latitude</label>
                                            <input name='lat' type='text' className='form-control' value={location?.location.lat || ''} onChange={handleLocationChange} data-id={index} autoComplete="off" />
                                        </div>
                                        <div className='col'>
                                            <label className='cnow-form-label fw-bold'>Longitude</label>
                                            <input name='lng' type='text' className='form-control' value={location?.location.lng || ''} onChange={handleLocationChange} data-id={index} autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label className='cnow-form-label'>Notes: If the location is hard to find, put more info here. (ex. “In the lobby of the building.”)</label>
                                        <input name='description' type='text' className='form-control' value={location.description || ''} onChange={handleLocationChange} data-id={index} autoComplete="off" />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <label className='cnow-form-label'></label>
                                    <div className='text-end'>
                                        <Button icon='delete' onClick={() => removeLocation(location.id)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization'],
})(Location);