import React from 'react'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';


const EventImages = (props) => {
    const entity = useSelector(state => state[props.id]);
    let currentImages = entity.images?.filter(x => (x.type === 'image'))
    let currentDocuments = entity ? entity?.images?.filter(x => x.type === 'document') : []
    let imageRecognition = entity ? entity?.images?.filter(x => x.type === 'image-recognition') : []
    let participantsImages = entity?.participants;
    let featuredProjects = entity?.featured_projects;
    let FeaturedImages = entity?.related_event;
    return (
        <div>
            {currentImages && currentImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>{props?.type === 'event_series' ? 'Event Series Images' : "Event Images"}</div>
                    {currentImages.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {currentDocuments && currentDocuments.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Event Documents</div>
                    {currentDocuments.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {imageRecognition && imageRecognition.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Image Recognition</div>
                    {imageRecognition.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {participantsImages && participantsImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Featured Project Images</div>
                    {featuredProjects?.map((item, index) => {
                        return <>   <ImageViewer key={index} entityType={'site'} entityId={item.site_id} type='thumb' cssClass='thumb p-1' /> </>
                    }

                    )}
                </>
            }
            {featuredProjects && featuredProjects.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Participant Images</div>
                    {participantsImages?.map((item, index) => {
                        return <>   <ImageViewer key={index} entityType={'person'} entityId={item.person_id} type='thumb' cssClass='thumb p-1' /> </>
                    }

                    )}
                </>
            }
            {FeaturedImages && FeaturedImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Related Event Images</div>
                    {FeaturedImages?.map((item, index) => {
                        return <>   <ImageViewer key={index} entityType={'event'} entityId={item.event_id} type='thumb' cssClass='thumb p-1' /> </>
                    }

                    )}
                </>
            }
        </div>
    )
}

export default EventImages