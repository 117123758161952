import React, { useState } from "react";
import SelectPeople from "../selects/person";
import Button from "../buttons/default";

const RemarksAttributionLink = (props) => {
    const [linkType, setLinkType] = useState('')
    const [dataInfo, setDataInfo] = useState({})

    const onTypeChange = ({ target: { value, name } }) => {
        setLinkType(value)
        setDataInfo({})
    };

    const handlePersonChange = (data) => {
        setDataInfo(data)
    }

    const handleLinkSave = () => {
        props.handleLinkUpdate(dataInfo)
        props.setSelectedText('')
    }

    const deleteSelectedText = () => {
        props.setSelectedText('')
    }

    return (
        <>
            <div className="row  py-2">
                <div className='col-auto'>
                    <label className='form-check-label fw-bold small'>Selected Text</label>
                </div>
                <div className='col'>
                    <input name='selected text' type='text' className='form-control' value={props?.selectedText || ''} disabled={true} autoComplete="off" />
                </div>
                <div className='col-auto'>
                    <Button size='sm' color='light' icon='delete' className={``} onClick={() => { deleteSelectedText() }} />
                </div>
            </div>
            <div className="row py-2">
                <div className='col-auto'>
                    <label className='form-check-label fw-bold small'>Remarks Attribution</label>
                </div>
                <div className='col-auto'>
                    <div className='form-check form-check-inline'>
                        <input type='radio' className='form-check-input' name='type' value='person/org' onChange={onTypeChange} />
                        <label className='form-check-label small'>Person/Org</label>
                    </div>
                    {linkType && linkType === 'person/org' && <div className='row'>
                        <div className='col-8'>
                            <SelectPeople key={linkType} value={{ 'id': dataInfo?.id, 'name': dataInfo?.name }} onChange={(data) => handlePersonChange(data)} facetFiltersMoreThanOne entityType='person' subEntityType='organization' />
                        </div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}

                </div>
                <div className='col-auto'>
                    <div className='form-check form-check-inline'>
                        <input type='radio' className='form-check-input' name='type' value='publication' onChange={onTypeChange} />
                        <label className='form-check-label small'>Publication</label>
                    </div>
                    {linkType && linkType === 'publication' && <div className='row'>
                        <div className='col-8'>
                            <SelectPeople key={linkType} value={{ 'id': dataInfo?.id, 'name': dataInfo?.name }} onChange={(data) => handlePersonChange(data)} entityType='publication' />
                        </div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}
                </div>
                <div className='col-auto'>
                    <div className='form-check form-check-inline'>
                        <input type='radio' className='form-check-input' name='type' value='other' onChange={onTypeChange} />
                        <label className='form-check-label small'>Other(text field)</label>
                    </div>
                    {linkType && linkType === 'other' && <div className='row'>
                        <div className='col-8'><input name='link' type='text' className='form-control' value={dataInfo.link || ''} onChange={(e) => { setDataInfo({ link: e.target.value, type: 'other' }) }} autoComplete="off" /></div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}
                </div>

            </div>
        </>
    )
}

export default RemarksAttributionLink