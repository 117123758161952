/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import SiteMenu from './menu';
import SitePreview from './preview';
import SiteTitle from './title';
import SiteDate from './date';
import SiteCategory from './category';
import SiteLocation from './location';
import SitePeople from './people';
import SiteDescription from './description';
import SiteAssociatedSites from './associatedSites';
import SiteImages from './image';
import SiteAwardDesignation from './awardDesignation';
import SiteMetaDescription from './meta';
import SitePodcast from './podcast';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import RelationshipMapping from '../../components/relationshipMapping';
import SiteCuratorManagement from './curator';
import Spinner from '../../components/spinner';
import OnThisDayInThisPhoto from './onThisDayInThisPhoto';
import { dateString } from '../../helpers';
import EventsTours from './eventsTours';
import SiteSpecialFeature from './specialFeature';
import Legal from '../../components/legal/editor';
import { toast } from 'react-toastify';

const _api = require('../../api');

const SiteEditor = (props) => {
  const id = props.match.params.id || 'NEW';
  const section = props.match.params.section || 'title';
  const sectionId = props.match.params.sectionId || '';
  const history = useHistory();
  const dispatch = useDispatch();
  const site = useSelector(state => state[id]);
  const menuSize = useSelector((state) => state.menu);
  const previewSize = useSelector((state) => state.preview);
  const user = useSelector((state) => state.user);
  const { id: userId, parent_id: parentId } = useSelector((state) => state.user);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    if (id === 'NEW') {
      dispatch({
        type: 'entity', payload: {
          'id': 'NEW',
          'id_ext': '',
          'name': '',
          'alias': '',
          'description': '',
          'material': '',
          'dimension': {},
          'meta': {},
          'url': '',
          'image_id': null,
          'deleted': false,
          'live': false,
          'user_id': parentId || userId
        }
      })
    } else {
      getSiteData()
    }
  }, [id, sectionId, section]);

  useEffect(() => {
    return () => {
      const data = JSON.parse(localStorage.getItem('cnow-cms'))
      delete data[id]
      localStorage.setItem('cnow-cms', JSON.stringify(data))
    }
  }, [])

  const getSiteData = async () => {
    showSpinner(true);
    let mySite = await _api.site.getById(id)

    if (section === 'title' || !section) {
      mySite.themes = await _api.site.getTheme(id)
    }

    if (section === 'date') {
      mySite.dates = await _api.site.getDate(id)
    }

    if (section === 'description') {
      mySite.dates = await _api.site.getDate(id)
      mySite.stories = await _api.site.getStory(id)
      mySite.tags = await _api.site.getTag(id) || []
      mySite.related_sites = await _api.site.getRelatedSite(id) || []
    }

    if (section === 'awards_designations') {
      mySite.dates = await _api.site.getDate(id)
      mySite.awards = await _api.site.getAward(id) || []
      mySite.designations = await _api.site.getDesignation(id)
    }

    if (section === 'category') {
      mySite.dates = await _api.site.getDate(id)
      mySite.categories = await _api.site.getCategory(id)
    }

    if (section === 'location') {
      mySite.locations = await _api.site.getLocation(id) || []
    }

    if (section === 'attach_map') {
      mySite.maps = await _api.site.getMap(id)
    }

    if (section === 'people') {
      mySite.dates = await _api.site.getDate(id)
      mySite.people = await _api.site.getPerson(id)
      mySite.related_people = await _api.site.getRelatedPerson(id) || []
    }

    if (section === 'events_tours') {
      mySite.related_tours = await _api.site.getTour(id) || []
      mySite.related_events = await _api.site.getEvent(id) || []
      mySite.related_event_series = await _api.site.getEventSeries(id) || []
    }

    if (section === 'images') {
      mySite.dates = await _api.site.getDate(id)
      mySite.images = await _api.site.getImage(id)
    }

    if (section === 'podcasts') {
      mySite.dates = await _api.site.getDate(id)
      mySite.podcasts = await _api.site.getPodcast(id)
      mySite.playlists = await _api.site.getPlaylist(id)
    }

    if (section === 'on_this_day') {
      mySite.images = await _api.site.getImage(id)
      mySite.on_this_day = await _api.site.getOnThisDay(id) || []
    }

    if (section === 'legal') {
      mySite.images = await _api.site.getImage(id)
      mySite.podcasts = await _api.site.getPodcast(id)
    }

    if (section === 'associated_sites') {
      mySite.associated_sites = await _api.site.getAssociatedSites(id)
    }
    if (section === 'special_features') {
      mySite.special_features = await _api.site.getSiteRecipe(id)
    }

    if (section === 'curator') {
      mySite.images = await _api.site.getImage(id)
      mySite.podcasts = await _api.site.getPodcast(id)
      mySite.curator = await _api.site.getCurator(id)
    }

    dispatch({ type: 'entity', payload: mySite })
    showSpinner(false);
  }

  const updateAndRedirect = async (path) => {
    if (user.id) {
      site.created_via = "ADMIN"
    }

    if (!path) {
      path = section;
      if (sectionId) path = path.concat('/', sectionId);
    }

    showSpinner(true);
    if (site.id === 'NEW') {
      site.id = null
      site.modified_by = null
    } else {
      site.modified_by = user.id
    }

    let isValidVanityUrl = true;

    if (section === 'meta') {
      const { isValid, message } = await _api.vanity_url.validateVanityUrl({ vanity_url: site.meta.vanity_url, id: site.id, entity: "site" })
      isValidVanityUrl = isValid;
      if (!isValid) toast.error(message);
      showSpinner(false);
    }

    let mySite;
    if (isValidVanityUrl) {
      mySite = await _api.site.update(site);
      // dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
    }

    if (section === 'title' || !section) {
      mySite.themes = await _api.site.updateTheme(mySite.id, site.themes)
    }

    if (section === 'date') {
      mySite.dates = await _api.site.updateDate(mySite.id, site.dates)
    }

    if (section === 'description') {
      mySite.dates = await _api.site.updateDate(mySite.id, site.dates)
      mySite.stories = await _api.site.updateStory(mySite.id, site.stories)
      mySite.tags = await _api.site.updateTag(mySite.id, site.tags)
      mySite.related_sites = await _api.site.updateRelatedSite(mySite.id, site.related_sites)
    }

    if (section === 'awards_designations') {
      site.awards = site.awards?.filter((award) => dateString(award) || award.award_id || award.description || award.attribution)
      site.awards.forEach((elem, index) => { elem.sort = index + 1 })
      mySite.awards = await _api.site.updateAward(mySite.id, site.awards)
      mySite.designations = await _api.site.updateDesignation(mySite.id, site.designations)
    }

    if (section === 'category') {
      mySite.categories = await _api.site.updateCategory(mySite.id, site.categories)
    }

    if (section === 'podcasts') {
      mySite.podcasts = await _api.site.updatePodcast(mySite.id, site.podcasts)
      mySite.playlists = await _api.site.updatePlaylist(mySite.id, site.playlists)
    }

    if (section === 'events_tours') {
      mySite.related_tours = await _api.site.updateTour(mySite.id, site.related_tours)
      mySite.related_events = await _api.site.updateEvent(mySite.id, site.related_events)
      mySite.related_event_series = await _api.site.updateEventSeries(mySite.id, site.related_event_series)
    }

    if (section === 'location') {
      mySite.locations = await _api.site.updateLocation(mySite.id, site.locations)
    }

    if (section === 'people') {
      site.people = await _api.site.updatePerson(mySite.id, site.people)


      site.related_people = await _api.site.updateRelatedPerson(mySite.id, site.related_people)
      // mySite.people.individual_credits = await _api.site.updateIndiviualCredits(mySite.id, site.people)
      if (site.people?.length > 0) {
        for (const [index, item] of site.people.entries()) {
          let response = await _api.site.updateIndiviualCredits(mySite.id, item?.individual_credits);
          site.people[index].individual_credits = response;
        }
        mySite.people = site.people
      }
      if (site.related_people.length > 0) {
        for (const [index, item] of site.related_people.entries()) {
          let response = await _api.site.updateIndiviualCredits(mySite.id, item?.individual_credits);
          site.related_people[index].individual_credits = response;
        }
        mySite.related_people = site.related_people

      }
    }

    if (section === 'images') {
      mySite.images = await _api.site.updateImage(mySite.id, site.images)
    }

    if (section === 'attach_map') {
      mySite.maps = await _api.site.updateMap(mySite.id, site.maps)

    }

    if (section === 'associated_sites') {
      mySite.associated_sites = await _api.site.updateAssociatedSite(mySite.id, site.associated_sites)
    }

    if (section === 'on_this_day') {
      mySite.on_this_day = await _api.site.updateOnThisDay(site.on_this_day)
      mySite.images = await _api.site.updateImage(mySite.id, site.images)
    }
    if (section === 'special_features') {
      mySite.special_features = await _api.site.updateSiteRecipe(mySite.id, site.special_features)
    }

    if (section === 'curator') {
      mySite.curator = await _api.site.updateCurator(mySite.id, site.curator)
    }


    if (mySite) {
      dispatch({ type: 'entity', payload: mySite })
      history.push(`/site/${mySite.id}/editor/${path}`)
      showSpinner(false);
    }

  }

  return (
    <div className='cnow-editor-container'>
      <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
        <SiteMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
      </div>
      <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
        <Spinner display={spinner}>
          {section === 'title' && <SiteTitle id={id} />}
          {section === 'date' && <SiteDate id={id} onUpdate={() => updateAndRedirect} />}
          {section === 'category' && <SiteCategory id={id} />}
          {section === 'location' && <SiteLocation id={id} locationId={sectionId} onUpdate={(path) => updateAndRedirect(path)} type={'site'} />}
          {section === 'people' && <SitePeople id={id} />}
          {section === 'description' && <SiteDescription id={id} />}
          {section === 'associated_sites' && <SiteAssociatedSites id={id} onUpdate={() => updateAndRedirect()} />}
          {section === 'images' && <SiteImages id={id} onUpdate={() => updateAndRedirect()} />}
          {section === 'awards_designations' && <SiteAwardDesignation id={id} />}
          {section === 'meta' && <SiteMetaDescription id={id} />}
          {section === 'podcasts' && <SitePodcast id={id} />}
          {section === 'legal' && <Legal id={id} />}
          {section === 'events_tours' && <EventsTours id={id} />}
          {section === 'special_features' && <SiteSpecialFeature id={id} />}
          {section === 'attach_map' && <RelatedMapEditor id={id} type='site' showDate={true} />}
          {section === 'relationship-mapping' && <RelationshipMapping id={id} type={'site'} />}
          {section === 'on_this_day' && <OnThisDayInThisPhoto id={id} onUpdate={(path) => updateAndRedirect(path)} />}
          {section === 'curator' && <SiteCuratorManagement id={id} />}
        </Spinner>
      </div>
      <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
        <SitePreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
  libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(SiteEditor);