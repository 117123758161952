import React from 'react';
import { TABLE_ROWS } from '../../helpers';

const RelatedMapList = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'>{item?.map?.map_name || item?.map_name || item?.related_map_name}</div>

                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default RelatedMapList;