/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import PeopleMenu from './menu';
import PeoplePreview from './preview';
import PeopleName from './name';
import PeopleContact from './contact';
import PeopleAffiliation from './affiliation';
import PeopleDate from './date';
import PeopleAbout from './about';
import PeopleAssociatedSites from './associatedSites';
import PeopleAwardHighlight from './awardHighlight';
import PeopleMeta from './meta';
import Spinner from '../../components/spinner';
import PeopleImages from './image';
import PeoplePodcast from './podcast'
import RelationshipMapping from '../../components/relationshipMapping';
import OnThisDayInThisPhoto from './onThisDayInThisPhoto';
import { getImageAlbum, dateString } from '../../helpers';
import EventsTours from './eventsTours';
import PeopleAttachMap from './peopleAttachMap';
import PeopleSpecialFeature from './specialFeature';
import PeopleCuratorManagement from './curator';
import Legal from '../../components/legal/editor';
import { toast } from 'react-toastify';
const _api = require('../../api');
const PeopleEditor = (props) => {
	const id = props.match.params.id || 'NEW';
	const section = props.match.params.section || 'name';
	const history = useHistory();
	const dispatch = useDispatch();
	const person = useSelector(state => state[id])
	const user = useSelector((state) => state.user);
	const { id: userId, parent_id: parentId } = useSelector((state) => state.user);

	const menuSize = useSelector((state) => state.menu);
	const previewSize = useSelector((state) => state.preview);

	const [spinner, showSpinner] = useState(false);

	useEffect(() => {
		if (id === 'NEW') {
			dispatch({
				type: 'entity', payload: {
					'id': 'NEW',
					'id_ext': '',
					'type': '',
					'name': '',
					'first_name': '',
					'last_name': '',
					'alias': '',
					'description': '',
					'phone': '',
					'email': '',
					'url': '',
					'hide_location': false,
					'deleted': false,
					'live': false,
					'primary_contact': null,
					'alternate_contact': null,
					'image_id': null,
					'meta': {},
					'is_participant': false,
					'user_id': parentId || userId
				}
			})
		} else {
			getPersonData();
		}
	}, [id, section]);

	useEffect(() => {
		return () => {
			const data = JSON.parse(localStorage.getItem('cnow-cms'))
			delete data[id]
			localStorage.setItem('cnow-cms', JSON.stringify(data))
		}
	}, [])


	const getPersonData = async () => {
		showSpinner(true);
		let myPerson = await _api.person.getById(id)

		if (section === 'name') {
			myPerson.professions = await _api.person.getProfession(id)
			myPerson.designations = await _api.person.getDesignation(id)
			myPerson.organization_types = await _api.person.getOrganizationType(id)
			myPerson.publication_types = await _api.person.getPublicationType(id)
		}

		if (section === 'contact') {
			myPerson.locations = await _api.person.getLocation(id) || []
		}

		if (section === 'about') {
			myPerson.dates = await _api.person.getDate(id)
			myPerson.stories = await _api.person.getStory(id)
			myPerson.tags = await _api.person.getTag(id)
			myPerson.quotes = await _api.person.getQuote(id)
		}

		if (section === 'date') {
			myPerson.dates = await _api.person.getDate(id)
		}

		if (section === 'awards_highlights') {
			myPerson.awards = await _api.person.getAward(id)
			myPerson.highlights = await _api.person.getHighlight(id)
		}

		if (section === 'affiliation') {
			myPerson.affiliations = await _api.person.getAffiliation(id)
			myPerson.related_people = await _api.person.getRelatedPerson(id) || []
		}

		if (section === 'associated_sites') {
			myPerson.sites = await _api.person.getSite(id) || []
			myPerson.related_sites = await _api.person.getRelatedSite(id) || []
			myPerson.publications = await _api.person.getPublication(id) || []
			myPerson.image_albums = getImageAlbum(await _api.person.getSiteImageAlbums(id), 'site_id')?.concat(getImageAlbum(await _api.person.getPersonImageAlbums(id), 'person_id'));
			myPerson.credited_podcasts = myPerson.podcasts?.filter((elem) => elem?.source === "credited_podcast") || []
		}

		if (section === 'images') {
			myPerson.images = await _api.person.getImage(id)
			myPerson.sites = await _api.person.getSite(id)
			myPerson.related_sites = await _api.person.getRelatedSite(id)
			myPerson.publications = await _api.person.getPublication(id) || []
			myPerson.image_albums = getImageAlbum(await _api.person.getSiteImageAlbums(id), 'site_id')?.concat(getImageAlbum(await _api.person.getPersonImageAlbums(id), 'person_id'));
			myPerson.additionalPhotos = await _api.person.getAdditionalPhotos(id)
			myPerson.credited_podcasts = myPerson.podcasts?.filter((elem) => elem?.source === "credited_podcast") || []
		}

		if (section === 'attach_map') {
			myPerson.maps = await _api.person.getMap(id)
			myPerson.map_credits = await _api.person.getMapCredits(id)
		}

		if (section === 'events_tours') {
			myPerson.related_tours = await _api.person.getTour(id) || []
			myPerson.tour_roles = await _api.person.getTourRoles(id)
			myPerson.direct_events = await _api.person.getDirectEvents(id)
			myPerson.related_events = await _api.person.getEvent(id) || []
			myPerson.related_event_series = await _api.person.getEventSeries(id)
			myPerson.direct_event_series = await _api.person.getDirectEventSeries(id)
		}

		if (section === 'podcasts') {
			myPerson.dates = await _api.person.getDate(id) || []
			myPerson.podcasts = await _api.person.getPodcast(id) || []
			myPerson.sites = await _api.person.getSite(id) || []
			myPerson.related_sites = await _api.person.getRelatedSite(id) || []
			myPerson.affiliations = await _api.person.getAffiliation(id) || []
			myPerson.playlists = await _api.person.getPlaylist(id) || []
			myPerson.credited_podcasts = myPerson.podcasts?.filter((elem) => elem?.source === "credited_podcast") || []
		}

		if (section === 'on_this_day') {
			myPerson.images = await _api.person.getImage(id)
			myPerson.on_this_day = await _api.person.getOnThisDay(id)
		}

		if (section === 'special_features') {
			myPerson.special_features = await _api.person.getRecipePeople(id)
		}

		if (section === 'legal') {
			myPerson.images = await _api.person.getImage(id)
			myPerson.podcasts = await _api.person.getPodcast(id) || []
			if (myPerson?.image_id) {
				myPerson.images.push(myPerson.image)
			}
			myPerson.images.forEach((elem) => {
				if (!elem.image_id && !elem.image_type && !elem.image_url && !elem.image_caption) {
					elem.image_id = elem.id;
					elem.image_type = elem.type;
					elem.image_url = elem.url;
					elem.image_caption = elem.caption;
				}
			})
		}

		if (section === 'curator') {
			myPerson.images = await _api.person.getImage(id);
			myPerson.podcasts = await _api.person.getPodcast(id) || [];
			myPerson.curator = await _api.person.getCurator(id) || [];
		}

		dispatch({ type: 'entity', payload: myPerson })
		showSpinner(false);
	}

	const updateAndRedirect = async (path) => {
		if (user.id) {
			person.created_via = "ADMIN"
		}

		if (!path) {
			path = section;
		}

		showSpinner(true);
		if (person.id === 'NEW') {
			person.id = null
			person.modified_by = null
		} else {
			person.modified_by = user.id
		}

		person.is_participant = false;

		let isValidVanityUrl = true;

		if (section === 'meta') {
			const { isValid, message } = await _api.vanity_url.validateVanityUrl({ vanity_url: person.meta.vanity_url, id: person.id, entity: "person" })
			isValidVanityUrl = isValid;
			if (!isValid) toast.error(message);
			showSpinner(false);
		}
		let myPerson
		if (isValidVanityUrl) {
			myPerson = await _api.person.update(person)
		}
		if (section === 'name') {
			myPerson.professions = await _api.person.updateProfession(myPerson.id, person.professions)
			myPerson.designations = await _api.person.updateDesignation(myPerson.id, person.designations)
			myPerson.organization_types = await _api.person.updateOrganizationType(myPerson.id, person.organization_types)
			myPerson.publication_types = await _api.person.updatePublicationType(myPerson.id, person.publication_types)
		}

		if (section === 'contact') {
			myPerson.locations = await _api.person.updateLocation(id, person.locations)
		}

		if (section === 'date') {
			myPerson.dates = await _api.person.updateDate(id, person.dates)
		}

		if (section === 'about') {
			myPerson.dates = await _api.person.updateDate(id, person.dates)
			myPerson.stories = await _api.person.updateStory(id, person.stories)
			myPerson.tags = await _api.person.updateTag(id, person.tags)
			myPerson.quotes = await _api.person.updateQuote(id, person.quotes)
		}

		if (section === 'events_tours') {
			myPerson.related_tours = await _api.person.updateTour(myPerson.id, person.related_tours)
			myPerson.related_events = await _api.person.updateEvent(myPerson.id, person.related_events)
			myPerson.related_event_series = await _api.person.updateEventSeries(myPerson.id, person.related_event_series)
		}

		if (section === 'awards_highlights') {
			person.awards = person.awards?.filter((award) => dateString(award) || award.award_id || award.description || award.attribution)
			person.awards.forEach((elem, index) => { elem.sort = index + 1 })
			myPerson.awards = await _api.person.updateAward(id, person.awards)
			myPerson.highlights = await _api.person.updateHighlight(id, person.highlights)
		}

		if (section === 'affiliation') {
			myPerson.affiliations = await _api.person.updateAffiliation(id, person.affiliations)
			myPerson.related_people = await _api.person.updateRelatedPerson(id, person.related_people)
		}

		if (section === 'associated_sites') {
			// updatePersonRelatedSite(person)
			if (person.related_sites?.length > 0) {
				for (const item of person.related_sites) {
					let obj = {
						description: item.description,
						id: item.id,
						related_person_first_name: person.first_name,
						related_person_id: person.id,
						related_person_last_name: person.last_name,
						related_person_name: person.name,
						related_person_type: person.type,
						site_id: item.site_id,
						sort: null
					}
					await _api.site.updateRelatedPerson(item.site_id, [obj])
				}
			}
		}

		if (section === 'images') {
			myPerson.images = await _api.person.updateImage(id, person.images)
		}

		if (section === 'attach_map') {
			myPerson.maps = await _api.person.updateMap(id, person.maps)
		}

		if (section === 'podcasts') {
			myPerson.podcasts = await _api.person.updatePodcast(id, person.podcasts)
			myPerson.playlists = await _api.person.updatePlaylist(id, person.playlists)
		}

		if (section === 'on_this_day') {
			myPerson.on_this_day = await _api.person.updateOnThisDay(person.on_this_day)
			myPerson.images = await _api.person.updateImage(id, person.images)
		}

		if (section === 'special_features') {
			myPerson.special_features = await _api.person.updateRecipePeople(id, person.special_features)
		}

		if (section === 'curator') {
			myPerson.curator = await _api.person.updateCurator(id, person.curator)
		}

		dispatch({ type: 'entity', payload: myPerson })
		history.push(`/people/${myPerson.id}/editor/${path}`)
		showSpinner(false);

	}

	return (
		<div className='cnow-editor-container'>
			<div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
				<PeopleMenu id={id} section={section} onClick={(path) => updateAndRedirect(path)} display={spinner} />
			</div>
			<div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
				<Spinner display={spinner}>
					{section === 'name' && <PeopleName id={id} onUpdate={() => updateAndRedirect()} />}
					{section === 'contact' && <PeopleContact id={id} />}
					{section === 'affiliation' && <PeopleAffiliation id={id} />}
					{section === 'date' && <PeopleDate id={id} />}
					{section === 'about' && <PeopleAbout id={id} />}
					{section === 'associated_sites' && <PeopleAssociatedSites id={id} />}
					{section === 'images' && <PeopleImages id={id} onUpdate={(path) => updateAndRedirect(path)} />}
					{section === 'awards_highlights' && <PeopleAwardHighlight id={id} />}
					{section === 'events_tours' && <EventsTours id={id} />}
					{section === 'meta' && <PeopleMeta id={id} />}
					{section === 'podcasts' && <PeoplePodcast id={id} />}
					{section === 'attach_map' && <PeopleAttachMap id={id} />}
					{section === 'on_this_day' && <OnThisDayInThisPhoto id={id} onUpdate={(path) => updateAndRedirect(path)} />}
					{section === 'relationship-mapping' && <RelationshipMapping id={id} type={'people'} />}
					{section === 'legal' && <Legal id={id} />}
					{section === 'special_features' && <PeopleSpecialFeature id={id} />}
					{section === 'curator' && <PeopleCuratorManagement id={id} />}
				</Spinner>
			</div>
			<div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
				<PeoplePreview id={id} onUpdate={() => updateAndRedirect()} section={section} display={spinner} />
			</div>
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_MAP_KEY,
	libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(PeopleEditor);