import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageTitle from '../../components/pageTitle';
import ImageViewer from '../../components/viewers/image';
import Button from '../../components/buttons/default';
import EntityPlaylistImages from '../../components/editors/entityPlaylistImages'

const PlaylistImages = (props) => {
    const history = useHistory();
    const playlist = useSelector(state => state[props.id]);

    const onEditClick = () => {
        history.push(`/playlist/${props.id}/headshot`)
    }
    return (
        <>
            <PageTitle title={playlist.name} />

            <div id='header'>
                <hr className='cnow-hr' />
                <div className='row'>
                    <div className='col'>
                        <div className='form-group'>
                            <div className='cnow-form-title'>Set Banner Image</div>
                        </div>
                    </div>
                </div>
                <div className='px-1'>
                    <div className='d-flex small w-100 fw-bold mb-2'>
                        <div style={{ width: '120px' }}>Media</div>
                        <div className='flex-grow-1'>Photo Title, Credits</div>
                        <div style={{ width: '50px' }}>Edit</div>
                    </div>
                    <div className='d-flex w-100'>
                        <div style={{ width: '120px' }}>
                            <ImageViewer url={playlist?.url} type='thumb' cssClass='thumb' />
                        </div>
                        <div className='flex-grow-1'>
                            <div className='small fw-bold'>
                                {
                                    playlist.thumbnail?.slice(0, 4)?.map(item => {
                                        return (
                                            <div className='bg-light w-fit-content mb-1'>{item.name}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ width: '50px' }}>
                            <Button size='sm' icon='edit' onClick={onEditClick} />
                        </div>
                    </div>
                </div>
            </div>

            <div id='images'>
                <hr className='cnow-hr' />
                <div className='row'>
                    <div className='col'>
                        <div className='cnow-form-title'>Images, for Podcasts *</div>
                    </div>
                </div>

                <EntityPlaylistImages
                    imageType='image'
                    entityType='playlist'
                    entityId={playlist.id}
                    dateId={null}
                />
            </div>

        </>
    );
};

export default PlaylistImages;