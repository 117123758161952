import React from "react";
import { useSelector } from 'react-redux';
import PageTitle from "../../components/pageTitle";
import { personName } from "../../helpers";
import SpecialFeature from "../../components/specialFeature";
const PeopleSpecialFeature = (props) => {
    const person = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={personName(person)} />
            <SpecialFeature id={props.id} type={'person'} recipe_role={true}/>
        </>
    )
}

export default PeopleSpecialFeature