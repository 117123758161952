import React from 'react'

const Permission = ({ label,  handleMainInputChange,  mainInput, hrTrue, placeholder }) => {
  return (
    <>
        {hrTrue && <hr className="cnow-hr" />}
        {label && <div className='row'>
            <div className='col'>
                <label className='cnow-form-title'>{label}</label>
            </div>
        </div>}
        <div className='row g-1'>
            <div className='col'>
                { <div className='row'>
                    <div className='col'>
                        <select name='link' className='form-control' placeholder={placeholder || 'Esto'} value={mainInput || ''} onChange={({ target: { value } }) => handleMainInputChange(value)} autoComplete='off' >
                            <option value="test1">test1</option>
                            <option value="test2">test2</option>
                            <option value="test3">test3</option>
                        </select>
                    </div>
                </div>}
            </div>
        </div>
    </>
  )
}

export default Permission