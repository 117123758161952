import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import PeopleEditor from '../../components/editors/peopleEditor';
import RelatedPeople from '../../components/editors/relatedPeopleEditor';

const PlaylistPeople = (props) => {
  const playlist = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={playlist.name} />
      <PeopleEditor id={props.id} type='playlist' key = {`people-editor-${key}`} refresh={() => setKey(uniqid())} title={'Curator'}/>
      <RelatedPeople id={props.id} type='playlist' key = {`related-people-${key}`} refresh={() => setKey(uniqid())} />
    </>
  );
};

export default PlaylistPeople;