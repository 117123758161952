import React from 'react'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';


const RecipeImages = (props) => {
    const entity = useSelector(state => state[props?.id]);
    let currentImages = entity?.images?.filter(x => (x.type === 'image'))
    return (
        <div>
            {currentImages && currentImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>{"Recipe Images"}</div>
                    {currentImages.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
        </div>
    )
}

export default RecipeImages