import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Button from '../../components/buttons/default';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import ImageViewer from '../../components/viewers/image';
import ToursImage from './toursImages';
import swal from 'sweetalert';
import Spinner from '../../components/spinner';
const _api = require('../../api')

const TourImages = (props) => {
    const dispatch = useDispatch();
    const tour = useSelector(state => state[props.id]);
    const location = useLocation()
    const history = useHistory()
    const [spinner, showSpinner] = useState(false)

    const editItems = (id) => {
        dispatch({ type: 'images', payload: { source: location.pathname, id: tour.id, entityType: 'tour', editType: 'headshot' } })
        history.push(`/tour/image/${id || 'tour_headshot'}/headshot`)
    }

    useEffect(() => {
        setDefaultTourImage();
        // eslint-disable-next-line
    }, [])

    const setDefaultTourImage = async () => {
        if (tour && !tour.image_id && tour?.stops?.length > 0 && tour.stops[0].sites?.length > 0 && tour.stops[0].sites[0]?.image_id) {
            showSpinner(true)
            tour.image_id = tour.stops[0].sites[0].image_id
            let tourResponse = await _api.tour.update(tour)
            dispatch({ type: 'entity', payload: { ...tour, ...tourResponse } });
            showSpinner(false)
        }
    }

    const removeBanner = async () => {
        swal({
            title: `Delete Image`,
            text: `Are you sure that you want to delete this image?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async () => {
            showSpinner(true)
            tour.image = {}
            tour.image_id = null
            tour.url = null
            let tourResponse = await _api.tour.update(tour)
            dispatch({ type: 'entity', payload: { ...tour, ...tourResponse } });
            showSpinner(false)
        })
    }

    return (
        tour && <>
            <PageTitle title={tour?.name} />
            <div id='banner'>
                <hr className='cnow-hr' />
                <div className='row'>
                    <div className='col-auto'>
                        <div className='form-group'>
                            <div className='cnow-form-title'>Set Banner Image *</div>
                            <div className='d-flex'>
                                <div onClick={() => editItems(tour.image_id)} className='cursor-pointer'>
                                    <Spinner display={spinner}>
                                        <ImageViewer key={tour.image_id} entityId={tour.image_id} entityType='image' type={'headshot'} cssClass={'cnow-image-editor-headshot'} />
                                    </Spinner>
                                </div>
                                <div className='w-25 px-3'>
                                    <div className='bg-light fw-bold py-0'>{tour?.image_name}</div>
                                    <div className='ps-1 fw small'> {tour?.image_credits}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='d-flex gap-1 justify-content-end'>
                            <Button icon='edit' onClick={() => editItems(tour.image_id)} />
                            <Button icon='delete' onClick={() => removeBanner(tour.image_id)} />
                        </div>
                    </div>
                </div>
            </div>
            <hr className='cnow-hr' />
            <div name='tour_stops'>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>Images for Tour Stop</label>
                    </div>
                </div>
                <div className='row px-2'>
                    {
                        tour.stops?.length > 0 && tour.stops.map((stop, index) => {
                            return (<ToursImage tourId={tour.id} stop={stop} />)
                        })
                    }
                </div>
            </div>
            <hr className='cnow-hr' />
            <EntityImages title={`TOUR IMAGES`} items={tour.images}
                entityType='tour'
                entityId={tour.id}
                imageType={'tour Images'}
                buttonLabel={'Add an Image'}
                sort='index'
            />
        </>
    )
}

export default TourImages