import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ImageViewer from '../viewers/image';

const _api = require('../../api');

const MapsList = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    const pushMapOverlays = async (item) => {
        let map_id;
        if (props.type === 'site') {
            map_id = item.map_id
        } else if (props.type === 'map') {
            map_id = item.related_map_id
        } else return;

        if (map_id !== entity.selectedRelatedMap?.mapid) {
            await _api.map.getById(map_id).then(map => {
                entity.selectedRelatedMap = { mapid: map.id, overlays: map.map_overlays }
            })
        } else entity.selectedRelatedMap = { mapid: null, overlays: [] }
        dispatch({ type: 'entity', payload: { ...entity, selectedRelatedMap: entity.selectedRelatedMap } })
    }

    const isHighlight = (item) => {
        if (props.type === 'site') {
            return item.map_id === entity.selectedRelatedMap?.mapid
        } else if (props.type === 'map') {
            return item.related_map_id === entity.selectedRelatedMap?.mapid
        } else return false
    }

    return (
        <>
            {(entity?.maps?.length > 0) &&
                <>
                    <hr className={props.type ? 'd-none' : ''} />
                    <div className='fw-bold fst-italic'>Historic Maps</div>
                    
                        {
                            entity.maps.map((item, index) =>
                                <span key={item.id} onClick={() => pushMapOverlays(item)} >
                                    <ImageViewer key={`imageFor${item.id}`} entityType={'map'} entityId={props?.type === 'map' ? item?.related_map_id : item?.map_id} type='thumb' cssClass={`p-1 thumb cursor-pointer prevent-select ${isHighlight(item) ? 'highlight-box' : ''}`} />
                                </span>)
                        }
                    
                </>
            }
        </>
    )
}

export default MapsList