import React from 'react';
import { personName, TABLE_ROWS } from '../../helpers';

const EventPeopleList = ({ other, sponsor, host }) => {
    return (
        <td>
            {
                host?.slice(0, 1).map((item, index) => {
                    return (
                        <div key={index} className='text-truncate'>
                            <i>Host: </i> {personName(item)}
                        </div>
                    )
                })
            }
            {
                sponsor?.slice(0, 1).map((item, index) => {
                    return (
                        <div key={index} className='text-truncate'>
                            <i>Sponsor: </i> {personName(item)}
                        </div>
                    )
                })
            }{
                other?.slice(0, 1).map((item, index) => {
                    return (
                        <div key={index} className='text-truncate'>
                            <i>other: </i> {personName(item)}
                        </div>
                    )
                })
            }
            {(host?.length + sponsor?.length + other?.length) > TABLE_ROWS && <div className='small text-muted'>+ {(host?.length + sponsor?.length + other?.length) - TABLE_ROWS} more</div>}
        </td>
    )
}

export default EventPeopleList;