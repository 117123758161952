import React from 'react';
import AsyncSelect from 'react-select/async';
import { checkUserTypeAndReturnIds, customSelectStyle } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const _api = require('../../api')


const SelectEventSeries = (props) => {
    const user = useSelector(state => state.user)
    let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.name, 'image': props.value.image, } : {};

    const items = async (keyword, callback) => {
        let items = [];
        if (keyword) {
            const params = {
                "bool": {
                    "must": [
                        { "match": { "deleted": "false" } },
                        { "match": { "created_via": "ADMIN" } },
                        {
                            "multi_match": {
                                "query": keyword,
                                "fields": [
                                    "name",
                                ],
                                "fuzziness": 2,
                                "prefix_length": 2
                            }
                        }
                    ]
                }
            };
            const finalParams = {
                query: params
            }


            let response = await _api.event_series.searchByElastic(0, 50, finalParams);
            response.hits.hits.forEach(data => items.push({
                'value': data._source.id,
                'label': data._source.name,
                'image': data._source.image_id,
                'images': data._source?.images,
                'type': 'event_series'
            }));
        }
        callback(items);
        return items;
    }
    const handleChange = (e) => {
        let value = { 'id': e.value, 'name': e.label, 'image': e.image, 'type': 'event_series', 'images': e.images };
        props.onChange(value);
    }

    const debouncedSuggestions = debounce((keyword, callBack) => {
        items(keyword, callBack)
    }, 500)

    return (
        <AsyncSelect cacheOptions defaultOptions
            styles={customSelectStyle}
            loadOptions={debouncedSuggestions}
            value={selected}
            onChange={handleChange}
            components={{ NoOptionsMessage }}
        />
    );
};

export default SelectEventSeries;