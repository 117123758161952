import React, { useEffect } from "react";
import jsPDF from 'jspdf';
import ImagePdfLayout from "./imagePdfTemplate";
import PodcastPdfLayout from "./podcastPdfTemplate";
const PDFGenerator = (props) => {
  const data = props?.data
  let pdf = new jsPDF('p', 'pt', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth()
  const pdfHeight = pdf.internal.pageSize.getHeight()
  const giveFooterTitle = (entity) => {
    let text = ''
    if (entity === 'image') text = 'Photography'
    if (entity === 'podcast') text = 'Speaker'
    return text
  }
  // eslint-disable-next-line
  useEffect(() => { setTimeout(() => { generatePdf() }, props.milliSec - 4000) }, [])

  const generatePdf = () => {
    let html = document.getElementById(`html-${props?.title}`);
    if (html) {
      pdf.html(html, {
        callback: function (pdf) {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.text(pdfWidth - 230, pdfHeight - 23, `cultureNOW ${giveFooterTitle(props?.type)} Release Form ` + i + ' of ' + totalPages);
          }
          pdf.save(`${props?.title}`)
        },
        margin: [30, 0, 30, 0],
        autoPaging: 'text'
      });
    }
  }

  return (
    <div key={`${props?.title}-layout`}>
      <div className="d-none">
        <div id={`html-${props?.title}`} style={{
          width: pdfWidth,
          padding: '20px',
          fontSize: 'smaller',
          lineHeight: 1.3,
        }}>
          {props?.type === 'image' && <ImagePdfLayout data={data} enity_title={props.enity_title} key={props?.title} id={props?.title} />}
          {props?.type === 'podcast' && <PodcastPdfLayout data={data} enity_title={props.enity_title} key={props?.title} id={props?.title} />}
        </div>
      </div>
    </div>
  )
}

export default PDFGenerator