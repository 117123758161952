import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  newTempId, handleReorder } from '../../helpers';
import uniqid from 'uniqid';
import Dragula from "react-dragula";
import Button from '../buttons/default';
import TextEditor from './textEditor';

const _api = require('../../api');

const QuoteEditors = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-quote'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.quotes, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, quotes: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End

    const addQuote = () => {
        let quotes = entity.quotes ? entity.quotes : []

        if (!entity.quotes) entity.quotes = [];

        entity.quotes.push({
            'id': newTempId(),
            'quote': '',
            'sort': entity.quotes.length + 1
        });

        // Update sort order
        quotes.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...entity, quotes: entity.quotes } });
    }

    const removeQuote = async (index) => {
        if (entity.quotes[index]) {
            if (!entity.quotes[index].id.startsWith('temp.'))
                _api.person.deleteQuote(entity.quotes[index].id)

            entity.quotes.splice(index, 1)
            entity.quotes.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, quotes: entity.quotes } })
        }
    }

    const handleEditorChange = (index, data) => {
        entity.quotes[index].quote = data;
        dispatch({ type: 'entity', payload: { ...entity, quotes: entity.quotes } });
    }

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.name}s</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label={`Add ${props.name === 'Quote' ? 'a' : 'an'} ${props.name}`} onClick={() => addQuote()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula}>
                {
                    entity.quotes?.length > 0 && entity?.quotes?.map((quote, index) => {
                        return (
                            <div key={index} className={index > 0 ? 'mt-4 dragula-item' : 'dragula-item'} id={quote.id}>
                                <div className='row g-1'>
                                    {
                                        entity?.quotes?.length > 1 &&
                                        <div className='col-auto'>
                                            <label className='cnow-form-label fw-bold'>Order</label>
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col'>
                                        <TextEditor limit={250} data={quote.quote} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label fw-bold'>{entity.type === 'person' ? 'Quote' : 'Excerpt'}</label>
                                        </TextEditor>
                                    </div>
                                    <div className='col-auto'>
                                        <label className='cnow-form-label'></label>
                                        <div className='text-end'>
                                            <Button icon='delete' onClick={() => removeQuote(index)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default QuoteEditors;