import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import CuratorTextField from "./textField";
import { newTempId } from "../../helpers";
const ToursMapInput = ({ id }) => {
    const tour = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const tour_curator = tour.curator
    const handleOnChange = (data, type) => {
        if (!tour?.curator) tour.curator = {}
        if (!tour?.curator.id) tour.curator.id = newTempId()
        if (!tour.curator?.[type]) tour.curator[type] = ''
        tour.curator[type] = data
        dispatch({ type: 'entity', payload: { ...tour, curator: { ...tour.curator, [type]: tour.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'TOUR MAP NAME'} key={`${id}-tour_map_name`} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'tour_map_name') }} mainInput={tour_curator?.tour_map_name || ''} hrTrue />

            <div className="py-2"><CuratorTextField label={'TOUR LOCATION'} key={`${id}-tour_location`} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'tour_location') }} mainInput={tour_curator?.tour_location || ''} /></div>
            {/* need to confirm from admin list from clients */}
            <div className="py-2"><CuratorTextField label={'TOUR SECTION'} key={`${id}-tour_section`} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'tour_section') }} mainInput={tour_curator?.tour_section || ''} placeholder={'Boston Tour'} /></div>

            <div className="py-2"><CuratorTextField label={'TOUR ORDER'} key={`${id}-tour_order`} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'tour_order') }} mainInput={tour_curator?.tour_order || ''} placeholder={'000'} /></div>
        </>
    )
}


export default ToursMapInput
