import React from 'react';
import Icon from './viewers/icon';

const TableHeader = (props) => {
    const width = props.column.width || '100px'
    const textAlign = props.column.textAlign || 'text-start'
    const cursor = props.column.sort === true ? 'pointer' : 'auto'
    const sortIcon = props.column.order === 'desc' ? 'sort-up' : 'sort-down'
    
    const handleSort = () => {
        let column = props.column
        column.order = column.order === 'asc' ? 'desc' : 'asc'
        props.onSort(column)
    }

    return (
        <th style={{ cursor: cursor, width: width, textAlign: textAlign }}>
            {props.column.name}
            {props.column.sort === true && <Icon name={sortIcon} size={12} left={4} onClick={() => handleSort()} />}
        </th>
    )
}

export default TableHeader