/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Button from '../../components/buttons/default';
import SelectGlobalList from '../../components/selects/globalList';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import Dragula from 'react-dragula';
import { toast } from 'react-toastify';
import { handleReorder, personName } from '../../helpers';
const _api = require('../../api')

const PersonProfession = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-profession'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.professions, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, professions: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addProfession = () => {
        if (!personName(person)) toast.warning('please enter First Name')
        else {
            let professions = person.professions ? person.professions : [];
            professions.push({
                'id': `temp.${uniqid()}`,
                'person_id': person.id,
                'profession_id': null,
                'profession_name': '',
                'sort': person.professions ? person.professions.length + 1 : 1
            });
            dispatch({ type: 'entity', payload: { ...person, professions: professions } });
            props.refresh && props.refresh();
        }
    };

    const removeProfession = async (index) => {
        if (!person.professions[index].id.startsWith('temp.')) await _api.person.deleteProfession(person.professions[index].id)
        person.professions.splice(index, 1)
        dispatch({ type: 'entity', payload: { ...person, professions: person.professions } });
    };

    const handleProfessionChange = (index, data) => {
        let professions = person.professions
        professions[index].profession_id = data.id
        professions[index].profession_name = data.name
        dispatch({ type: 'entity', payload: { ...person, professions: professions } });
    }

    return (
        <>
            <div className='form-group'>
                <hr className='cnow-hr' />
                <div className='row'>
                    <div className='col'>
                        <span className='cnow-form-title'>Profession*</span>
                    </div>
                    <div className='col-auto text-end'>
                        <Button size='sm' icon='plus' label='Add a Profession' onClick={() => addProfession()} />
                    </div>
                </div>
                <div id={dragulaContainer} key={dragula}>
                    {
                        person?.professions?.length > 0 && person?.professions?.map((item, index) => {
                            return (
                                <div key={index} id={item.id} className='mt-2 dragula-item'>
                                    <div className='row g-1'>
                                        <div className={person?.professions.length > 1 ? `col-auto` : `d-none`}>
                                            <div className='cnow-form-label fw-bold'>{index === 0 ? 'Order' : ''}&nbsp;</div>
                                            <input key={item.id} type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                        </div>
                                        <div className='col-auto d-flex flex-grow-1'>
                                            <div className='w-100'>
                                                <div className='cnow-form-label fw-bold'>{index === 0 ? 'Main Profession' : 'Additional Professions'}  *</div>
                                                <SelectGlobalList key={item.id} type='person_profession' value={{ id: item?.profession_id, name: item?.profession_name }} onChange={(data) => handleProfessionChange(index, data)} />
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='cnow-form-label' key={item.id}>&nbsp;</div>
                                            <Button icon='delete' onClick={() => removeProfession(index)} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

        </>
    )
}

export default PersonProfession