import React from 'react';
import NumberFormat from 'react-number-format';

const ResultCount = ({ page, take, count }) => {
    const start = ((page - 1) * take) + 1;
    const end = count < page * take ? count : page * take;

    return (
        <span className='small'>
            <span>
                <NumberFormat value={start} displayType='text' thousandSeparator={true} />
            </span>
            <span className='ms-1'>
                - <NumberFormat value={end} displayType='text' thousandSeparator={true} />
            </span>
            <span className='mx-1'>
                of <NumberFormat value={count} displayType='text' thousandSeparator={true} /> Results
            </span>
        </span>
    )
}

export default ResultCount;