import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import TourPeopleEditor from '../../components/editors/tourPeopleEditor'
import RelatedPeople from '../../components/editors/relatedPeopleEditor'; 

const People = (props) => {
  const tour = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
       <PageTitle title={tour.name} />
       <TourPeopleEditor id={props.id} type='tour' section='created_by' key={`created-by-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Created By*' />
       <TourPeopleEditor id={props.id} type='tour' section='partner_org' key={`partner-org-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Partner Organization' />
       <TourPeopleEditor id={props.id} type='tour' section='tour_leader' key={`partner-org-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Tour Leader' />
       <RelatedPeople id={props.id} type='tour' key={`related-person-editor-${key}`} refresh={() => { setKey(uniqid()) }} /> 
    </>
  );
};

export default People;