import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Theme from '../../components/theme';
import Category from '../../components/category';
import uniqid from 'uniqid'
import EventSeries from './series';
import EventType from '../../components/eventType';

const EventTitle = (props) => {
    const dispatch = useDispatch();
    const event = useSelector((state) => state[props.id]);
    const [key, setKey] = useState(uniqid())
    const handleInputChange = ({ target: { name, value } }) => {
        event[name] = value;
        dispatch({ type: 'entity', payload: { ...event, [name]: value } });
        setKey(uniqid())
    }

    return (
        <>
            <div className='form-group'>
                <div className='cnow-form-title'>Title <span className='text-cn-warning'>*</span> </div>
                <input name='name' type='text' className='form-control' value={event?.name} onChange={handleInputChange} autoComplete="off" />
            </div>

            {
                event?.id && <>
                    <EventSeries id={event.id} key={`event-series-${key}`} />
                    <EventType id={event.id} key={`event-type-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'event_type'} type={'event'} />
                    <Category id={event.id} key={`event-category-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'collection_category'} type={'event'} disableButton={true} />
                    <Theme id={event.id} key={`event-theme-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'collection_theme'} type={'event'} disableButton={true} />
                </>
            }
        </>
    );
}

export default EventTitle