import React from "react";
import { missingInfo } from '../../helpers';

const MissingRequiredInfoList = ({ data }) => {
    let requiredInfo = missingInfo(data)?.length > 2 ? missingInfo(data).slice(0, 2) : missingInfo(data) || []
    return (
        <td className="fst-italic">
            {requiredInfo?.length > 0 &&
                requiredInfo.map((item, index) => {
                    return <div key={`missing-info-list-${index}`}>{item}</div>
                })
            }
            {missingInfo(data)?.length > 2 && <div>etc</div>}

        </td>
    )
}

export default MissingRequiredInfoList