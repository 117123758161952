import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import SelectPeople from '../../components/selects/person';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import ImageViewer from '../viewers/image';
import Spinner from '../../components/spinner'
const _api = require('../../api');

const Publication = (props) => {

	const entity = useSelector(state => state[props.id]);
	const [publication, setPublication] = useState([])
	const [spinner, showSpinner] = useState(false)

	useEffect(() => {
		showSpinner(true)
		_api.person.getPublication(props.id).then((response) => {
			setPublication(response)
			if (props.setOffsetIndex) props.setOffsetIndex({ ...props?.offsetIndex, "podcast": props?.offsetIndex.publications + response.length })
			showSpinner(false)
		})
		// eslint-disable-next-line
	}, [props.id])

	return (
		<>
			{publication?.map((item, index) => {
				return (
					<div id={item?.id} key={index} className='mb-3 dragula-item'>
						{entity?.credits?.publications &&
							<Spinner display={spinner} >
								<div className='row g-1'>
									<div className='col-auto'>
										{index === 0 && <label className='cnow-form-label'>Order</label>}
										<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort'
											placeholder={props?.offsetIndex?.publications ? props?.offsetIndex?.publications + index + 1 : index + 1} autoComplete="off" />
									</div>
									<div className='col'>
										<div className='row g-1'>
											<div className='col-auto'>
												{index === 0 && <label className='cnow-form-label'></label>}
												<div>
													<ImageViewer type={'thumb'} url={item?.image_url} cssClass='thumb-small' />
												</div>
											</div>
											<div className='col'>
												{index === 0 && <label className='cnow-form-label'>Position</label>}
												<input disabled type='text' className='form-control' value={item?.related_person_position ? item?.related_person_position : ''} autoComplete="off" />
											</div>
											<div className='col'>
												{index === 0 && <label className='cnow-form-label'>Person/Organization</label>}
												<SelectPeople disabled key={item?.related_person_id} value={{ id: item?.related_person_id, name: item?.related_person_name }} onChange={''} />
											</div>
										</div>
									</div>
									<div className='col-auto'>
										{index === 0 && <label className='cnow-form-label'></label>}
										<div className='btn-list'>
											<PreviewButton link={`people/${item?.related_person_id}`} />
											<LinkButton icon='edit' to={`/people/${item?.related_person_id}/editor`} />
										</div>
									</div>
								</div>
							</Spinner>}
					</div>
				)
			})}
		</>
	)
}

export default Publication