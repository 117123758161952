import React, { useState } from "react";
import SelectPeople from "../selects/person";
import Button from "../buttons/default";
import Select from 'react-select';
import { customSelectStyle } from "../../helpers";
import SelectSite from "../selects/site";
import SelectPlaylist from "../selects/playlist";
import SelectMap from "../selects/map";
import SelectTour from "../selects/tour";
import SelectEvent from "../selects/event";
import SelectEventSeries from "../selects/eventSeries";
import SelectRecipe from "../selects/recipe";

const RemarksAttributionTypeLink = (props) => {
    const [linkType, setLinkType] = useState('')
    const [dataInfo, setDataInfo] = useState({})
    const [type, settype] = useState('')
    const relatedItemTypes = require('../../data/related_item_types.json');
    const onTypeChange = (value) => {
        setLinkType(value)
        setDataInfo({})
    };

    const handlePersonChange = (data) => {
        setDataInfo(data)
    }

    const handleLinkSave = () => {
        props.handleLinkUpdate(dataInfo)
        props.setSelectedText('')
    }

    const deleteSelectedText = () => {
        props.setSelectedText('')
    }




    const handleRelatedItemTypeChange = () => {

    }

    return (
        <>
            <div className="row  py-2">
                <div className='col-auto'>
                    <label className='form-check-label fw-bold small'>Selected Text</label>
                </div>
                <div className='col'>
                    <input name='selected text' type='text' className='form-control' value={props?.selectedText || ''} disabled={true} autoComplete="off" />
                </div>
                <div className='col-auto'>
                    <Button size='sm' color='light' icon='delete' className={``} onClick={() => { deleteSelectedText() }} />
                </div>
            </div>
            <div className="row py-2">
                <div className='col-auto'>

                    <div className='form-check form-check-inline'>
                        <Select name='type'
                            options={relatedItemTypes}
                            styles={customSelectStyle}
                            value={{ 'value': linkType?.value, 'label': linkType?.label }}
                            onChange={(value) => { onTypeChange(value) }} placeholder={<div className='text-muted'>MM</div>} />
                    </div>
                </div>
                <div className='col-8'>
                    {/* {linkType && linkType === 'person/org' && <div className='row'>
                        <div className='col-8'>
                            <SelectPeople key={linkType} value={{ 'id': dataInfo?.id, 'name': dataInfo?.name }} onChange={(data) => handlePersonChange(data)} facetFiltersMoreThanOne entityType='person' subEntityType='organization' />
                        </div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}

                </div>
                <div className='col-auto'>
                    <div className='form-check form-check-inline'>
                        <input type='radio' className='form-check-input' name='type' value='publication' onChange={onTypeChange} />
                        <label className='form-check-label small'>Publication</label>
                    </div>
                    {linkType && linkType === 'publication' && <div className='row'>
                        <div className='col-8'>
                            <SelectPeople key={linkType} value={{ 'id': dataInfo?.id, 'name': dataInfo?.name }} onChange={(data) => handlePersonChange(data)} entityType='publication' />
                        </div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}
                </div>
                <div className='col-auto'>
                    <div className='form-check form-check-inline'>
                        <input type='radio' className='form-check-input' name='type' value='other' onChange={onTypeChange} />
                        <label className='form-check-label small'>Other(text field)</label>
                    </div> */}   <div className="col-8" >
                        {linkType?.value === '' &&
                            <Select styles={customSelectStyle} />
                        }
                        {linkType?.value === 'site' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectSite value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'person' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectPeople value={{ 'id': dataInfo.id, 'name': dataInfo.name, is_participant: dataInfo.is_participant }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'playlist' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectPlaylist value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'map' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectMap value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'tour' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectTour value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'event' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectEvent value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'event_series' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectEventSeries value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                        {linkType?.value === 'recipe' &&
                            <div className='row'>
                                <div className='col-8'>
                                    <SelectRecipe value={{ 'id': dataInfo.id, 'name': dataInfo.name }} onChange={(data) => { handlePersonChange(data) }} type_id='' />
                                </div>
                                <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                            </div>
                        }
                    </div>
                    {linkType && linkType === 'other' && <div className='row'>
                        <div className='col-8'><input name='link' type='text' className='form-control' value={dataInfo.link || ''} onChange={(e) => { setDataInfo({ link: e.target.value, type: 'other' }) }} autoComplete="off" /></div>
                        <div className='col-auto'><Button size='sm' color='light' icon='save' className={`ms-1`} onClick={() => { handleLinkSave() }} /></div>
                    </div>}
                </div>

            </div>
        </>
    )
}

export default RemarksAttributionTypeLink