/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination";
import ResultCount from "../../components/resultCount";
import ScrollToTop from "../../components/scrollToTop";
import Spinner from "../../components/spinner";
import Icon from "../../components/viewers/icon";
import TableHeader from "../../components/tableHeader";
import LinkButton from "../../components/buttons/link";
import Button from "../../components/buttons/default";
import ImageViewer from "../../components/viewers/image";
import PreviewButton from "../../components/buttons/preview";
import LongTextView from "../../components/viewers/longText";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import { TABLE_ROWS } from "../../helpers";
import moment from "moment";
import swal from 'sweetalert';
import TableCollapsable from "../../components/featured/onThisDay/TableCollapsable";
import { groupBy, uniqBy, differenceBy } from "lodash";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import LocationList from "../../components/table/locationList";
const _api = require("../../api");
const columns = require("../../data/colums_on_this_day.json");

const OnThisDay = (props) => {
  const months = [
    { value: 0, label: "Jan" },
    { value: 1, label: "Feb" },
    { value: 2, label: "Mar" },
    { value: 3, label: "Apr" },
    { value: 4, label: "May" },
    { value: 5, label: "Jun" },
    { value: 6, label: "Jul" },
    { value: 7, label: "Aug" },
    { value: 8, label: "Sept" },
    { value: 9, label: "Oct" },
    { value: 10, label: "Nov" },
    { value: 11, label: "Dec" },
  ];
  const today = new Date();
  const initialDate = today.getDate();
  const initialMonth = today.getMonth();
  const initialYear = today.getFullYear();
  const initialMonthObject = months.find(month => month.value === initialMonth);
  { console.log(initialDate, initialMonth, "dsfjdojfsoidhjoijh"); }
  const [keywordValue, setKeywordValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const take = 50;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(columns[0]);
  const [spinner, showSpinner] = useState(false);
  const [paginationHits, setPaginationHits] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(initialMonthObject);
  const [selectedYear, setSelectedYear] = useState(initialYear);
  const [selectedDate, setSelectedDate] = useState({ label: initialDate, value: initialDate });
  const [label, setLabel] = useState([])


  const [onThisDay, setOnThisDay] = useState({
    dateMonth: [],
    searched: {},
  });

  let section = props.section;
  const searchOnThisDay = useSelector((state) => state.search);
  let selectedResult = searchOnThisDay.selectedResult || [];
  let sectionResult = searchOnThisDay[section] || [];
  let selectedEntity = [...sectionResult, ...selectedResult];
  const featured = useSelector((state) => state.featured);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [resultFilters, setResultFilters] = useState({
    live: searchOnThisDay.filterLive,
    limit: searchOnThisDay.selectedLimit,
    dateFilter: true,
  });
  let start_date = featured.start_date && JSON.parse(featured.start_date);
  let end_date = featured.end_date && JSON.parse(featured.end_date);
  let start = start_date ? moment(start_date).format("MMMM Do YYYY") : "";
  let end = end_date ? moment(end_date).format("MMMM Do YYYY") : "";

  useEffect(() => {
    search(selectedMonth, selectedYear, selectedDate);
  }, [page, sort, keyword]);
  const search = async (selectedMonth, selectedYear, selectedDate) => {
    showSpinner(true);
    // const params = {
    //   keyword: keyword,
    //   hitsPerPage: take,
    //   page: page - 1,
    //   facetFilters: [],
    // };
    // if (selectedYear) {
    //   let data = [];
    //   data.push([`year:${selectedYear}`]);
    //   params.facetFilters.push(...data);
    // }
    // if (selectedDate) {
    //   let data = [];
    //   data.push([`day:${selectedDate?.value}`]);
    //   params.facetFilters.push(...data);
    // }
    // if (selectedMonth) {
    //   let data = [];
    //   data.push([`month:${selectedMonth?.value}`]);
    //   params.facetFilters.push(...data);
    // }
    let sortBoolean = true
    const params = {
      "bool": {
        "must": [


        ]
      }
    };
    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "entity",
            "entity_name",
            "image_caption",
            "name"

          ],
          "fuzziness": 2,
          "prefix_length": 2
        }
      });
    }
    if (selectedMonth) {
      params.bool.must[params.bool.must.length] = {
        "bool": {
          "should": [
            {
              "match": {
                "month": selectedMonth.value
              }
            }
          ]
        }
      }
    }
    if (selectedYear) {
      const paddedYear = selectedYear.toString().padStart(4, '0');

      params.bool.must.push({
        "bool": {
          "should": [
            {
              "match": {
                "year": paddedYear
              }
            }
          ]
        }
      })
    }
    if (selectedDate) {
      params.bool.must[params.bool.must.length] = {
        "bool": {
          "should": [
            {
              "match": {
                "day": selectedDate.value
              }
            }
          ]
        }
      }
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "month": { "order": "asc" } }, { "day": { "order": "asc" } }]
    }

    handleSetKeywords(keyword);
    await _api.featured.searchByElastic((page - 1) * 50, take, finalParams).then((response) => {

      setPaginationHits(response?.hits?.total?.value);
      const mappedHits = response?.hits?.hits.map(hit => hit._source);
      const filteredArray = differenceBy(
        mappedHits,
        selectedEntity,
        "objectID"
      );
      // dispatch({
      //   type: "search",
      //   payload: { ...searchOnThisDay, searchResult: filteredArray },
      // });
      //date null filter, handle in Params
      setOnThisDay((prev) => {
        return {
          ...prev,
          dateMonth: uniqBy(mappedHits, (obj) => {
            if (obj.month !== null && obj.day !== null) {
              return `${obj.month}-${obj.day}`;
            }
            return `${obj.year}`;
          })
            .filter((elem) => elem.day !== null && elem.month !== null || elem.year !== null)
            .map((obj) => {
              if (obj.month !== null && obj.day !== null) {
                return `${obj.month}-${obj.day}`;
              }
              return `${obj.year}`;
            }),
        };
      });
      setOnThisDay((prev) => {
        return {
          ...prev,
          searched: groupBy(filteredArray, (obj) =>
            obj.month !== null && obj.day !== null ? `${obj.month}-${obj.day}` : obj.year
          ),
        };
      });
      setOnThisDay((prev) => {
        return {
          ...prev,
          selected: groupBy(selectedEntity, (obj) => `${obj.month}-${obj.day}`),
        };
      });
      showSpinner(false);
    });
  };
  const handleSetKeywords = (keyword) => {
    let data = [];
    if (resultFilters?.live) data.push(`Live - On This Day`);
    if (resultFilters?.dateFilter)
      start_date === end_date
        ? data.push(`${start}`)
        : data.push(`${start} - ${end}`);
    if (keyword) data.push(keyword);
  };



  const handleYearSelect = (value) => {
    // If the value is empty, set selectedYear to null
    setSelectedYear(value === "" ? null : parseInt(value));
    search(selectedMonth, value === "" ? null : parseInt(value), selectedDate);
  };

  const handleDateChange = (selectedOption) => {
    setSelectedDate(selectedOption);
    search(selectedMonth, selectedYear, selectedOption);
  };
  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    search(selectedOption, selectedYear, selectedDate);
  };
  const getDateOptions = () => {
    const options = [];
    for (let date = 1; date <= 31; date++) {
      options.push({ value: date, label: date.toString() });
    }
    return options;
  };
  const dateOptions = getDateOptions();

  const handleClearYear = () => {
    setSelectedYear(null);
    search(selectedMonth, null, selectedDate);
  };

  const updateLive = (id, live, date_month) => {
    showSpinner(true)
    let selectedSearchResult = onThisDay.searched[date_month].filter((item) => item.objectID === id)
    let selectedResult = [selectedSearchResult[0]]
    if (live === false) {

      _api[selectedResult[0].entity].updateListOnThisDay(selectedResult[0]?.entity_id, selectedResult[0]?.objectID, false).then(async (_) => {
        await search()
        swal({
          title: 'Not Live!',
          icon: 'info',
          text: `${selectedResult[0]?.entity_name} is Not Live.`,
          buttons: 'Ok',
        })
      })

    } else {

      _api[selectedResult?.[0]?.entity].updateListOnThisDay(selectedResult[0]?.entity_id, selectedResult[0]?.objectID, true).then(async (_) => {
        await search()
        swal({
          title: `Live!`,
          icon: 'success',
          text: `${selectedResult[0]?.entity_name} is now Live!`,
          buttons: 'Ok',
        })
      })
    }
    showSpinner(false)
  }
  {
    console.log(onThisDay, "onThisDay");
  }
  return (
    <>
      <div className="mt-3 container-fluid">
        <div className="row g-1">
          <div className="col">
            <div className="input-group">
              <span className="input-group-text border-end-0">
                <Icon name="search" />
              </span>
              <input className="form-control bg-light border-start-0" type="text" placeholder="Search by a person, a category, a site name, date"
                value={keywordValue} onChange={(recipe) => { setKeywordValue(recipe.target.value); }}
                onKeyDown={(e) => { if (e.key === "Enter") { setPage(1); setKeyword(keywordValue); } }} autoComplete="off" />
            </div>
          </div>
          <div className="col-auto">
            <Button label="Search" onClick={() => { setPage(1); setKeyword(keywordValue); }} />
          </div>
        </div>
      </div>

      <div className="mt-3 p-0 container-fluid">
        <div className="card">
          <div className="card-header bg-light p-2">
            <div className="d-flex justify-content-between gap-2 align-items-end">
              <div>
                <h4 className="mb-0 cursor-pointer text-capitalize">
                  On This Day
                </h4>
                <div className="small">

                  <ResultCount page={page} take={take} count={paginationHits} />
                  {
                    keyword && <span className='badge badge-search'>
                      {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                    </span>
                  }
                </div>
              </div>
              <Pagination page={page} take={take} count={paginationHits} setPage={setPage}
              />
              <div id="main">
                <div className="rows d-flex justify-content-center" id="main">
                  <div className=" m-0 p-1">
                    <Select options={dateOptions} value={selectedDate} onChange={handleDateChange} placeholder="Date" isClearable />
                  </div>
                  <div className=" m-0 p-1">
                    <Select options={months} value={selectedMonth} onChange={handleMonthChange} placeholder="Month" isClearable />
                  </div>
                  <div className=" m-0 p-1">
                    <div className="input-wrapper">
                      <NumberFormat className="form-control onThis-year" placeholder="YYYY" format="####" value={selectedYear || ""}
                        onValueChange={({ value }) => handleYearSelect(value)}
                        isAllowed={(values) => {
                          const { formattedValue, value } = values;
                          // Return true to allow typing or pasting the value.
                          // Only allow values that are empty or valid 4-digit numbers.
                          return !formattedValue || (value >= 0 && value <= 9999);
                        }}
                      />
                      {selectedYear && (
                        <button className="clear-icon" onClick={() => handleClearYear()}>&times;</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <button className="btn btn-light dropdown-toggle" type="button" id="sort" data-bs-toggle="dropdown" aria-expanded="false">
                  Sort by: {sort.name}
                </button>
                <ul className="dropdown-menu">
                  {columns.filter((x) => x.sort === true).map((column, index) => (
                    <button key={index} className="dropdown-item" onClick={() => setSort(column)}>
                      {column.name}
                    </button>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className="table-responsive cnow-table">
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr className="bg-light">
                    {columns.filter((x) => x.display === true).map((column, index) => (
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                    ))}
                  </tr>
                </thead>
                {onThisDay &&
                  onThisDay.dateMonth?.length > 0 && onThisDay.dateMonth.sort((a, b) => {
                    const [aFirst, aSecond] = a.split("-").map(Number);
                    const [bFirst, bSecond] = b.split("-").map(Number);
                    if (aFirst === bFirst) { return aSecond - bSecond; } return aFirst - bFirst;
                  }).map((date_month, index) => {
                    return (
                      <TableCollapsable collapsable count={"2 Items"} title={date_month} key={date_month} onThisDay={true}>
                        {onThisDay.searched && onThisDay.searched[date_month]?.length > 0 && onThisDay.searched[date_month].map((item, index) => {
                          return (
                            <tr key={item?.objectID}>
                              <td>
                                <div className="row g-1">
                                  <div className="col-auto">
                                    <div className="form-check mt-1">
                                      <input className="form-check-input align-middle" type="checkbox" name="select_on_this_day" />
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="btn-list">
                                      <LinkButton size="sm" icon="edit" to={`/${item.entity !== "person" ? item.entity : "people"}/${item.entity_id}/editor`} />
                                      <PreviewButton size="sm" link={`${item.entity !== "person" ? item.entity : "people"}/${item.entity_id}`} />
                                      <Button size='live' icon={item.live === true ? 'live' : 'not-live'} color={item.live === true ? '' : 'danger'}
                                        onClick={() => updateLive(item.objectID, item.live === true ? false : true, date_month)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <ImageViewer url={item.image_url} type="thumb" cssClass="thumb" />
                              </td>
                              <td>
                                <p className="fw-bold">{`${item?.month == null ? 0 : Number(item?.month) + 1}.${item?.day === null ? 0 : item?.day}.${item?.year === null ? 0 : item?.year}`}</p>
                              </td>
                              <td>
                                <p className="fw-bold cnow-ellipsis-3">{`${item?.entity_name}`}</p>
                              </td>
                              <td>
                                <p>{`${item?.sub_entity} : ${item?.name}`}</p>
                              </td>
                              <td>
                                <LongTextView lines={TABLE_ROWS + 1}>
                                  {item?.description}
                                </LongTextView>
                              </td>
                              <td>
                                <div className="cnow-ellipsis-3">
                                  <div className="fw-bold">{`${item?.month == null ? 0 : Number(item?.month) + 1}.${item?.day === null ? 0 : item?.day}.${item?.year === null ? 0 : item?.year} - ${item?.entity_name
                                    }`}</div>
                                  <LongTextView lines={3}>
                                    {item?.description}
                                  </LongTextView>
                                </div>
                              </td>
                              <td>
                                <p className="text-capitalize">{`${item?.entity} : ${item?.entity_name}`}</p>
                              </td>
                              <td></td>
                              <LocationList data={[item.location]} />
                              <td></td>
                              <td>{item?.objectID}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          );
                        }
                        )}
                      </TableCollapsable>
                    );
                  })}
              </table>
            </div>

            <div className="d-flex justify-content-between m-3">
              <div className="cols">
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
};

export default OnThisDay;
