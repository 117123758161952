import React from 'react';
import PageTitle from '../../../components/pageTitle';

const UserMembers = () => {

  return (
    <>
      <PageTitle title={` User Members`} />
      <hr className="cnow-hr" />
    </>
  )
}

export default UserMembers;