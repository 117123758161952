import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import Spinner from '../../components/spinner';
const _api = require('../../api');
const BannerMenu = (props) => {
    const tour = useSelector(state => state[props.tourId])
    const images = useSelector((state) => state.images);
    let stop = images.stop[0]
    let site = images?.site ? images?.site[0] : null
    const indexStop = tour.stops.findIndex((elem) => elem.id === stop.id)
    let indexSite = 0
    const [stopSite, setStopSite] = useState([{}])
    const [spinner, setSpinner] = useState(false)
    const [checkBox, setCheckBox] = useState('')
    const [radioCheck, setRadioCheck] = useState([])
    const [siteImages, setSiteImages] = useState([])

    useEffect(() => {
        getStopSites()
        // eslint-disable-next-line
    }, [])

    const getStopSites = async () => {
        setSpinner(true)
        let siteImages = []
        if (stop.sites?.length) {
            for (let i in stop.sites) {
                let image = await _api.site.getImage(stop.sites[i].site_id)
                siteImages.push({
                    image,
                    site: stop.sites[i],
                    label: `${Number(indexStop) + 1}.${Number(i) + 1}`,
                    id: stop.sites[i].id,
                    sort: (Number(i) + 1),
                    site_name: stop?.sites[i]?.site_name,
                    type: 'site_images'
                })

            }
        }
        setStopSite(siteImages)
        setSpinner(false)

        if (site) {
            let imageIndex = siteImages.filter((elem) => elem.id === site.id)
            props.onChange(imageIndex[0].image)
        }
    }

    const handleInputChange = (e) => {
        let data = e.target
        let checkedItems = [...checkBox]
        let siteImagesInfo = [...siteImages]
        let siteInfo = stopSite.filter((elem) => elem.id === data.id)
        if (data.name === 'site_images') {
            if (checkedItems.includes(data.id) && siteImagesInfo.map((elem) => elem.id).includes(data.id)) {
                checkedItems = checkedItems.filter((elem) => elem !== data.id)
                siteImagesInfo = siteImagesInfo.filter((elem) => elem.id !== data.id)
            }
            else {
                checkedItems.push(data.id)
                siteImagesInfo.push(siteInfo[0])
            }
            setSiteImages(siteImagesInfo)
            setCheckBox(checkedItems)
            setRadioCheck(['stop_image'])
            props.onChange(siteImagesInfo.sort((a, b) => a.sort - b.sort))
        }
        else if (data.id === 'tours_image') {
            setCheckBox([])
            setRadioCheck(['tours_image'])
            setSiteImages([])
            props.onChange(tour.images)
        }
        else if (data.id === 'stop_image') {
            setCheckBox([])
            setRadioCheck(['stop_image'])
            setSiteImages([])
            props.onChange([])
        }

    }

    if (site) {
        indexSite = stop.sites.findIndex((elem) => elem.id === site.id)
        if (tour.format === 2) indexSite = (Number(indexStop) + 1) + (Number(indexSite) + 1) / 10
        if (tour.format === 1) indexSite = (Number(indexStop) + 1)
    }

    return (
        <>
            <div className='py-2'> <PageTitle title={tour.name} /></div>
            <hr />
            {images.editType === "stop" ? <>
                <div className='cnow-text-primary fw-bold text-uppercase pt-2'>{images.editType}</div>
                <div className='d-flex align-items-center'><div className='tour-stop-number-red d-flex me-2'>{indexStop + 1}</div><div className='cnow-text-primary '>{stop?.stop_name}</div></div>
                <div className='cnow-text-primary text-decoration py-3 d-flex fw-bold'><u>Select Images</u></div>
                {stopSite.length > 0 && <div className='d-flex fw-bold'> <div className='pe-1'><input type='radio' name='image' id='stop_image' className='form-check-input' onChange={handleInputChange} checked={radioCheck.includes('stop_image')} /></div>Available Site Images</div>}
                {radioCheck.includes('stop_image') &&
                    <Spinner display={spinner}>
                        {stopSite.length > 0 && stopSite.map((elem) => {
                            return (
                                <>
                                    <div className='form-check m-0 ms-4 py-2 align d-flex'>
                                        <div className='cnow-test'>
                                            <div>
                                                <input id={elem.id} name='site_images' type='checkbox' className='form-check-input' onChange={handleInputChange} checked={checkBox.includes(elem.id)} />
                                            </div>
                                            <div className='d-flex'>
                                                <div className={`${checkBox.includes(elem.id) ? 'tour-stop-site-number-red' : 'tour-stop-site-number'} d-flex mx-2 `}>{elem.label}</div>
                                                <div><div className='cnow-ellipsis-2 fw-bold'>{elem?.site_name}</div>
                                                    <small className='cnow-form-label'>+ {elem.image?.length} Images</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </Spinner>
                }
                {tour?.images?.length > 0 && <div className='d-flex py-3 fw-bold'> <div className='pe-1'><input type='radio' name='image' id='tours_image' className='form-check-input ' onChange={handleInputChange} checked={radioCheck.includes('tours_image')} /></div>Tours Images</div>}
            </> :
                <div>
                    <div className='cnow-text-primary fw-bold text-uppercase pt-2'>{images.editType}</div>
                    <div className='d-flex align-items-center'><div className='tour-stop-number d-flex me-2'>{indexSite}</div><div className='cnow-text-primary '>{site?.site_name}</div></div>
                </div>
            }

        </>
    )
}

export default BannerMenu