import React, { useEffect, useState } from 'react';
import Button from './buttons/default';

const ScrollToTop = () => {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop])

    return <>
        {
            scrollTop > 100 && <Button icon='scroll-top' color='light btn-scoll-top' onClick={() => window.scrollTo(0, 0)} />
        }
    </>
};

export default ScrollToTop;