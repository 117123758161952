import React, { useState } from 'react';
import { personName } from '../../helpers'
import Icon from '../viewers/icon';
import { isString } from 'lodash';
const MIN_LENGTH = 3

const PodcastList = ({ data }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {data && data.length > 0 && <>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold fst-italic'>Podcasts</div>
                    {
                        data?.length > MIN_LENGTH &&
                        <div>
                            {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                            {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`} <i className='fe fe-chevron-down ms-1' /></div>}
                        </div>
                    }
                </div>
                {data.slice(0, length).map((item, index) => {
                    return (
                        <div className="d-flex mb-2 d-flex align-items-start" key={index}>
                            <div style={{ paddingTop: '-5px' }}>
                                <Icon name='play' bottom={0} size={14} />
                            </div>
                            <div className="ms-1">
                                <a
                                    href={`${process.env.REACT_APP_WEB_URL}/podcast/${item.podcast_id}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='text-dark text-decoration-none'
                                >
                                    {item?.podcast_name} {(item?.credits?.length > 0 || item?.podcast_credits?.length > 0) && '-'}&nbsp;
                                    <>
                                       {isString(item?.podcast_credits) === true ? <span className='fw-bold'>{item?.podcast_credits || ''}</span> 
                                       : <span className='fw-bold'>{item?.credits?.length > 0 ? personName(item?.credits[0]) : ''}</span>} 
                                    </>
                                </a>
                            </div>
                        </div>
                    );
                })}
            </>}
        </>
    )
}

export default PodcastList;