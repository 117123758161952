import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import RecipePeopleEditor from '../../components/editors/recipePeopleEditor'
import { ReorderPersonRecipe } from '../../helpers';
import Dragula from "react-dragula";
const _api = require('../../api')
const RecipePeople = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const recipe = useSelector((state) => state[props.id]);
  const getRecipeRole = async () => {
    let res = await _api.global_list.getAll('recipe_role')
    setData(res)
    setDragula(uniqid())
  }
  useEffect(() => {
    getRecipeRole()
    // eslint-disable-next-line
  }, [])

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-people'

  useEffect(() => {
    let containers = []
    if (data && data?.length > 0) data.forEach((role) => containers.push(document.getElementById(`${dragulaContainer}-${role.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = ReorderPersonRecipe(recipe.people, data, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...recipe, people: items } })

    })
    // eslint-disable-next-line
  }, [dragula])
  // Dragula End
  return (
    <>
      <PageTitle title={recipe?.name} />
      {
        data.length > 0 && data.map((data, index) => {
          return (
            <RecipePeopleEditor id={props.id} refresh={() => setDragula(uniqid())} title={data.name} role_id={data.id} dragulaContainer={`${dragulaContainer}-${data.id}`} dragula={dragula} />
          )
        })
      }
    </>
  );
};

export default RecipePeople;