/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import ImageViewer from "../viewers/image";
import TextEditor from './textEditor';
import SelectPerson from '../selects/person';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId, personName, relatedPersonName, getRole } from '../../helpers';
import IndiviualCreditsPerson from '../selects/indiviualCreditsPerson';
import SelectGlobalList from '../selects/globalList';
const _api = require('../../api')

const RelatedPeopleEditor = (props) => {
	const dispatch = useDispatch();
	const entity = useSelector(state => state[props.id]);

	// Dragula Start
	const [dragula, setDragula] = useState(uniqid())
	const dragulaRef = useRef()
	const dragulaContainer = 'dragula-container-related-people'

	useEffect(() => {
		let containers = []
		containers.push(document.getElementById(dragulaContainer))
		if (entity?.dates?.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
		dragulaRef.current = Dragula(containers, {})
		dragulaRef.current.on('drop', () => {
			const items = handleReorder(entity.related_people, null, dragulaContainer)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, related_people: items } })
			props.refresh && props.refresh()
		})
	}, [dragula])
	// Dragula End

	const addRelatedPerson = () => {
		if (!entity.related_people) entity.related_people = [];
		if (props.type === 'person' || props.type === 'site') {
			entity.related_people.push({
				'id': newTempId(),
				'description': '',
				'related_person_id': null,
				'related_person_name': '',
				'role_id': null,
				'role_name': '',
				[`${props.type}_id`]: props.id,
				'source': props.type,
				'sort': entity.related_people ? entity.related_people.length + 1 : 1,
				'is_participant': false
			})
		} else {
			entity.related_people.push({
				'id': newTempId(),
				'description': '',
				'person_id': null,
				'person_name': '',
				'role_id': null,
				'role_name': '',
				[`${props.type}_id`]: props.id,
				'source': props.type,
				'sort': entity.related_people ? entity.related_people.length + 1 : 1,
				'is_participant': false
			})
		}
		entity.related_people.forEach((item, index) => item.sort = index + 1)
		setDragula(uniqid())
		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
	}
	const addIndiviualCredits = (index) => {
		if (entity.related_people.individual_credits) entity.related_people[index].individual_credits = []

		let individual_credits_item = {
			'id': `temp.${uniqid()}`,
			'person_first_name': '',
			'person_last_name': '',
			'person_id': null,
			'person_image': '',
			'person_name': '',
			'role_id': null,
			'role_name': '',
			'is_participant': false,
			'site_id': entity.id
		}
		entity.related_people[index].individual_credits.push(individual_credits_item);
		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });

	}

	const removeRelatedPerson = async (index) => {
		if (entity.related_people[index]) {
			if (!entity.related_people[index].id.startsWith('temp.')) {
				if (props.type === 'person' || props.type === 'site') {
					await _api[props.type].deleteRelatedPerson(entity.related_people[index].id)
				} else if (props.type === 'map') {
					await _api.person.deleteMap(entity.related_people[index].id)
				} else if (props.type === 'playlist') {
					await _api.person.deletePlaylist(entity.related_people[index].id)
				} else if (props.type === 'event') {
					await _api.person.deleteEvent(entity.related_people[index].id)
				}

				else if (props.type === 'event_series') {
					await _api.person.deleteEventSeries(entity.related_people[index].id)
				}
				else if (props.type === 'tour') {
					await _api.person.deleteTour(entity.related_people[index].id)
				}

			}

			entity.related_people.splice(index, 1)
			entity.related_people.forEach((item, index) => item.sort = index + 1)
			dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
		}
	}



	const removeIndiviualCredits = async (index, individual_index) => {
		if (entity.related_people[index].individual_credits[individual_index]) {
			if (!entity.related_people[index].individual_credits[individual_index].id.startsWith('temp.')) await _api.site.deleteIndiviualCredits(entity.related_people[index].individual_credits[individual_index].id)

			entity.related_people[index].individual_credits.splice(individual_index, 1)
			dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
		}
	}


	const handleEditorChange = (index, data) => {
		entity.related_people[index].description = data;
		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
	}
	const handleIndividualCredit = (index, indiviual_index, value) => {
		entity.related_people[index].individual_credits[indiviual_index].credited_person_id = value?.id
		entity.related_people[index].individual_credits[indiviual_index].person_id = value?.parentId
		entity.related_people[index].individual_credits[indiviual_index].type = 'related-person'
		entity.related_people[index].individual_credits[indiviual_index].person_name = value?.name

		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } })
	}
	const handleIndiviualRoleChange = (index, indiviual_index, value) => {
		entity.related_people[index].individual_credits[indiviual_index].credited_person_role_id = value?.id
		entity.related_people[index].individual_credits[indiviual_index].role_name = value.name
		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
	}


	const handlePersonChange = (index, value) => {
		if (props.type === 'person' || props.type === 'site') {
			entity.related_people[index].related_person_id = value.id;
			entity.related_people[index].related_person_name = value.name;
			entity.related_people[index].role_name = value.role_name;
			entity.related_people[index].role_id = value.role_id;
			entity.related_people[index].is_participant = value.is_participant;
			entity.related_people[index].related_person_type = value.type;
			entity.related_people[index].affiliations = value.affiliations;

		} else {
			entity.related_people[index].person_id = value.id;
			entity.related_people[index].person_name = value.name;
			entity.related_people[index].role_name = value.role_name;
			entity.related_people[index].role_id = value.role_id;
			entity.related_people[index].is_participant = value.is_participant;
		}
		dispatch({ type: 'entity', payload: { ...entity, related_people: entity.related_people } });
	}

	return (
		<div>
			<hr className='cnow-hr' />

			<div className='row'>
				<div className='col'>
					<label className='cnow-form-title'>Related People/Organization/Publication</label>
				</div>
				<div className='col-auto'>
					<Button size='sm' icon='plus' label='Add a Person' onClick={() => addRelatedPerson()} />
				</div>
			</div>
			<div id={dragulaContainer} key={dragula} >
				{
					entity?.related_people?.map((item, index) => {
						return (
							<div key={index} id={item?.id} className='mb-3 dragula-item'>
								<div className='row g-1'>
									<div className={entity?.related_people?.length > 1 ? 'col-auto' : 'd-none'}>
										<label className='cnow-form-label fw-bold'>Order</label>
										<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
									</div>
									<div className='col-auto'>
										<div className=''>&nbsp;</div>
										<ImageViewer key={item?.id} entityType='person' entityId={(props.type === 'person' || props.type === 'site') ? item?.related_person_id : item?.person_id} type='thumb' cssClass='thumb-small' />
									</div>
									<div className='col'>
										<div className='row g-1'>
											<div className='col-auto d-flex flex-grow-1'>
												<div className='w-100'>
													<label className='cnow-form-label fw-bold'>Name*</label>
													{!item?.id.startsWith('temp.') ?
														<input value={(props.type === 'person' || props.type === 'site') ? relatedPersonName(item) : personName(item)} disabled className='w-100 cnow-input-disabled-height' />
														:
														<SelectPerson key={item.id}
															value={{
																id: (props.type === 'person' || props.type === 'site') ? item?.related_person_id : item?.person_id,
																name: (props.type === 'person' || props.type === 'site') ? relatedPersonName(item) : personName(item),
															}}
															className="prime1" onChange={(value) => handlePersonChange(index, value)} />
													}
												</div>
												{props.type === "playlist" &&
													<div className='w-100 ms-2'>
														<label className='cnow-form-label fw-bold'>Role *</label>
														<input value={getRole(item)} disabled className='w-100 cnow-input-disabled-height' />
														{/* <SelectGlobalList key={item.id} type={`${props.type}_people_role`} className="prime2" value={{ id: item.role_id, name: item.role_name }}
															onChange={(value) => handleRoleChange(index, value)} /> */}
													</div>}
											</div>
											<div className='col-auto'>
												<label className='cnow-form-label'></label>
												<div className='btn-list'>

													{((item?.related_person_type === "publication" || item?.related_person_type === "organization") && (props?.type === 'site')) && <Button className={item?.affiliations?.length ? '' : 'disabled'} icon='plus' onClick={() => addIndiviualCredits(index)} />}
													<PreviewButton link={`${!item.is_participant ? 'people' : 'participant'}/${(props.type === 'person' || props.type === 'site') ? item?.related_person_id : item?.person_id}`} />
													<LinkButton icon='edit' to={`/${!item.is_participant ? 'people' : 'participant'}/${(props.type === 'person' || props.type === 'site') ? item?.related_person_id : item?.person_id}/editor`} />
													<Button icon='delete' onClick={() => removeRelatedPerson(index)} />
												</div>
											</div>
										</div>
										<div>
											{
												item?.individual_credits?.length > 0 && item.individual_credits?.map((indiviual_item, individual_index) => {
													return (
														<div className='row g-1 mt-1'>

															<div className='col-auto'>
																{individual_index === 0 && <div className=''>&nbsp;</div>}
																<ImageViewer key={indiviual_item.id} entityType='person' entityId={indiviual_item.person_id} type='thumb' cssClass='thumb-small' />
															</div>

															<div className='col-auto d-flex flex-grow-1'>
																<div className='w-100'>
																	{individual_index === 0 && <label className='cnow-form-label fw-bold'>Name *</label>}
																	<IndiviualCreditsPerson key={indiviual_item.id} individual_credits={indiviual_item} options={item?.affiliations} onChange={(value) => handleIndividualCredit(index, individual_index, value)} />
																</div>
																<div className='w-75 ms-2'>
																	{individual_index === 0 && <label className='cnow-form-label fw-bold'>{'Role On Site *'}</label>}
																	{
																		props.type === 'site' || props.type === 'map' ?
																			<SelectGlobalList key={indiviual_item.id} type={`${props.type}_person_role`} className="prime" value={{ id: indiviual_item.credited_person_role_id, name: indiviual_item.role_name }} onChange={(value) => handleIndiviualRoleChange(index, individual_index, value)} /> :
																			<input value={getRole(indiviual_item)} disabled className='w-100 cnow-input-disabled-height' />
																	}
																</div>
															</div>
															<div className='col-auto'>
																{individual_index === 0 && <label className='cnow-form-label'></label>}
																<div className='btn-list'>
																	<PreviewButton link={`${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}`} />
																	<LinkButton icon='edit' to={`/${!indiviual_item.is_participant ? 'people' : 'participant'}/${indiviual_item.person_id}/editor`} />
																	<Button icon='delete' onClick={() => removeIndiviualCredits(index, individual_index)} />

																</div>
															</div>
														</div>
													)
												})
											}

										</div>
										<TextEditor key={item?.id} data={item?.description} onChange={(data) => handleEditorChange(index, data)}>
											<label className='cnow-form-label mt-2 fw-bold'>Remarks - Relation to project </label>
										</TextEditor>
									</div>
								</div>









							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default RelatedPeopleEditor;