/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import TableHeader from '../../../components/tableHeader';
import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
import ImageViewer from '../../../components/viewers/image';
import PreviewButton from '../../../components/buttons/preview';
import LongTextView from '../../../components/viewers/longText';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_ROWS, newTempId } from '../../../helpers';
import moment from 'moment';
import OnThisDayCollapsableSection from '../../../components/featured/onThisDay/TableRowCollapsable';
import TableCollapsable from '../../../components/featured/onThisDay/TableCollapsable';
import { groupBy, uniqBy, differenceBy } from 'lodash';
import LocationList from '../../../components/table/locationList';
import NumberFormat from "react-number-format";
import Select from "react-select";
const _api = require('../../../api');
const columns = require('../../../data/colums_on_this_day.json');

const FeaturedOnThisDay = (props) => {
    const history = useHistory();
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);
    const [spinner, showSpinner] = useState(false);
    const [paginationHits, setPaginationHits] = useState(0)
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const months = [
        { value: 0, label: "Jan" },
        { value: 1, label: "Feb" },
        { value: 2, label: "Mar" },
        { value: 3, label: "Apr" },
        { value: 4, label: "May" },
        { value: 5, label: "Jun" },
        { value: 6, label: "Jul" },
        { value: 7, label: "Aug" },
        { value: 8, label: "Sept" },
        { value: 9, label: "Oct" },
        { value: 10, label: "Nov" },
        { value: 11, label: "Dec" },
    ];
    const [onThisDay, setOnThisDay] = useState({
        dateMonth: [],
        selected: {},
        searched: {}
    })

    let section = props.section
    const location = useLocation();
    const searchOnThisDay = useSelector((state) => state.search);
    let searchResult = searchOnThisDay.searchResult || []
    let selectedResult = searchOnThisDay.selectedResult || []
    let sectionResult = searchOnThisDay[section] || []
    let selectedEntity = [...sectionResult, ...selectedResult]
    const featured = useSelector((state) => state.featured);
    const dispatch = useDispatch()
    const [resultFilters, setResultFilters] = useState({
        live: searchOnThisDay.filterLive,
        limit: searchOnThisDay.selectedLimit,
        dateFilter: true
    })
    const [label, setLabel] = useState([])
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    const getDateOptions = () => {
        const options = [];
        for (let date = 1; date <= 31; date++) {
            options.push({ value: date, label: date.toString() });
        }
        return options;
    };
    const dateOptions = getDateOptions();
    useEffect(() => {
        search()
    }, [page, sort, keyword]);


    const search = async (selectedMonth, selectedYear, selectedDate) => {
        showSpinner(true)
        // const params = {
        //     keyword: keyword,
        //     hitsPerPage: take,
        //     page: page - 1,
        //     facetFilters: [[`live:${resultFilters?.live}`]]
        // }

        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                ]
            }
        };
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "entity",
                        "entity_name",
                        "image_caption",
                        "name"

                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }
        if (selectedMonth) {
            params.bool.must[params.bool.must.length] = {
                "bool": {
                    "should": [
                        {
                            "match": {
                                "month": selectedMonth.value
                            }
                        }
                    ]
                }
            }
        }
        if (selectedYear) {
            const paddedYear = selectedYear.toString().padStart(4, '0');

            params.bool.must.push({
                "bool": {
                    "should": [
                        {
                            "match": {
                                "year": paddedYear
                            }
                        }
                    ]
                }
            })
        }
        if (selectedDate) {
            params.bool.must[params.bool.must.length] = {
                "bool": {
                    "should": [
                        {
                            "match": {
                                "day": selectedDate.value
                            }
                        }
                    ]
                }
            }
        }


        if (resultFilters?.live) {
            params.bool.must[params.bool.must.length] = { "match": { "live": resultFilters?.live } }
        }
        if (resultFilters.dateFilter) {
            if (start_date === end_date) {
                const day = moment(start_date).format("D");
                const month = (moment(start_date).format("MM") - 1).toString();
                params.bool.must.push({
                    "term": { "day": day }
                });
                params.bool.must.push({
                    "term": { "month": month }
                });
            }
            if (start_date !== end_date) {
                const numericFilters = [];

                numericFilters.push({
                    "range": {
                        "day": {
                            "gte": moment(start_date).format("D"),
                            "lte": moment(end_date).format("D")
                        }
                    }
                });
                if (moment(start_date).format("MM") === moment(end_date).format("MM")) {
                    numericFilters.push({
                        "term": { "month": (moment(start_date).format("MM") - 1).toString() }
                    });
                }
                else {
                    numericFilters.push({
                        "range": {
                            "month": {
                                "gte": (moment(start_date).format("MM") - 1).toString(),
                                "lte": (moment(end_date).format("MM") - 1).toString()
                            }
                        }
                    });
                }
                params.bool.must.push(...numericFilters);
            }
        }
        handleSetKeywords(keyword)
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "month": { "order": "asc" } }, { "day": { "order": "asc" } }]
        }

        await _api.featured.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
            setPaginationHits(response.hits.total.value);
            const mappedHits = response?.hits?.hits.map(hit => hit._source);
            const filteredArray = differenceBy(mappedHits, selectedEntity, 'objectID')
            dispatch({ type: 'search', payload: { ...searchOnThisDay, searchResult: filteredArray } })
            //date null filter, handle in Params
            setOnThisDay((prev) => { return { ...prev, dateMonth: uniqBy(mappedHits, obj => `${obj.month}-${obj.day}`).filter((elem) => elem.day !== null && elem.month !== null).map(obj => `${obj.month}-${obj.day}`) } })
            setOnThisDay((prev) => { return { ...prev, searched: groupBy(filteredArray, obj => `${obj.month}-${obj.day}`) } })
            setOnThisDay((prev) => { return { ...prev, selected: groupBy(selectedEntity, obj => `${obj.month}-${obj.day}`) } })
            showSpinner(false)
        })
    }
    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.live) data.push(`Live - On This Day`)
        if (resultFilters?.dateFilter) start_date === end_date ? data.push(`${start}`) : data.push(`${start} - ${end}`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Live')) resultFilters.live = ''
        if (data.includes(`${start}`) || data.includes(`${start} - ${end}`)) resultFilters.dateFilter = false
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const selectedOnThisDay = (id) => {
        let index = searchResult.findIndex((elem) => elem.objectID === id)
        showSpinner(true)
        selectedResult.push(searchResult[index])
        searchResult.splice(index, 1)
        dispatch({
            type: 'search', payload: {
                ...searchOnThisDay,
                selectedResult: selectedResult,
                searchResult: searchResult
            }
        })
        setOnThisDay((prev) => { return { ...prev, searched: groupBy(searchResult, obj => `${obj.month}-${obj.day}`) } })
        setOnThisDay((prev) => { return { ...prev, selected: groupBy([...sectionResult, ...selectedResult], obj => `${obj.month}-${obj.day}`) } })
        showSpinner(false)
    }

    const removeSelectedOnThisDay = (id) => {
        let index = selectedEntity.findIndex((elem) => elem.objectID === id)
        if (selectedEntity[index]?.section_index !== undefined && selectedEntity[index]?.entity_index !== undefined) {
            let section_index = selectedEntity[index].section_index
            let entity_index = selectedEntity[index].entity_index
            featured[section][section_index].edited = true
            featured[section][section_index].data[section].splice(entity_index, 1)
            featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
            let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
            sectionResult.splice(sectionIndex, 1)
            sectionResult.forEach((elem, index) => elem.entity_index = index)
            dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
            dispatch({ type: 'search', payload: { ...searchOnThisDay, [`${section}`]: sectionResult } })
            setOnThisDay((prev) => { return { ...prev, selected: groupBy([...sectionResult, ...selectedResult], obj => `${obj.month}-${obj.day}`) } })
        }
        else {
            let searchResultIndex = selectedResult.findIndex(elem => elem.objectID === selectedEntity[index].objectID)
            searchResult.push(selectedResult[searchResultIndex])
            selectedResult.splice(searchResultIndex, 1)
            dispatch({
                type: 'search', payload: { ...searchOnThisDay, selectedResult: selectedResult, searchResult: searchResult }
            })
            setOnThisDay((prev) => { return { ...prev, searched: groupBy(searchResult, obj => `${obj?.month}-${obj?.day}`) } })
            setOnThisDay((prev) => { return { ...prev, selected: groupBy([...sectionResult, ...selectedResult], obj => `${obj?.month}-${obj?.day}`) } })
        }

    }

    const selectedOnThisDayDispatch = () => {
        if (selectedResult.length > 0) {
            let index = featured?.[`${section}`]?.length === 0 ? -1 : 0
            let data = {
                "start_date": start_date.slice(0, 10),
                "end_date": end_date.slice(0, 10),
                "section": `${section}`,
            }
            if (index === -1) {
                data.id = newTempId()
                data.data = {
                    [`${section}`]: selectedResult
                }
                featured[`${section}`].push(data)
            }
            else {
                featured[`${section}`][index].edited = true
                featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
            }
        }
        dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
        dispatch({ type: 'search', payload: {} })
        history.goBack()
    }


    const handleClearYear = () => {
        setSelectedYear(null);
        search(selectedMonth, null, selectedDate);
    };

    const handleYearSelect = (value) => {
        // If the value is empty, set selectedYear to null
        setSelectedYear(value === "" ? null : parseInt(value));
        search(selectedMonth, value === "" ? null : parseInt(value), selectedDate);
    };

    const handleDateChange = (selectedOption) => {
        setSelectedDate(selectedOption);
        search(selectedMonth, selectedYear, selectedOption);
    };
    const handleMonthChange = (selectedOption) => {
        setSelectedMonth(selectedOption);
        search(selectedOption, selectedYear, selectedDate);
    };

    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(recipe) => { setKeywordValue(recipe.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => { selectedOnThisDayDispatch() }}>{`${searchOnThisDay.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={paginationHits} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search' key={`icon-${index}`}>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            <div id="main">
                                <div className="rows d-flex justify-content-center" id="main">
                                    <div className=" m-0 p-1">
                                        <Select options={dateOptions} value={selectedDate} onChange={handleDateChange} placeholder="Date" isClearable />
                                    </div>
                                    <div className=" m-0 p-1">
                                        <Select options={months} value={selectedMonth} onChange={handleMonthChange} placeholder="Month" isClearable />
                                    </div>
                                    <div className=" m-0 p-1">
                                        <div className="input-wrapper">
                                            <NumberFormat className="form-control onThis-year" placeholder="YYYY" format="####" value={selectedYear || ""}
                                                onValueChange={({ value }) => handleYearSelect(value)}
                                                isAllowed={(values) => {
                                                    const { formattedValue, value } = values;
                                                    // Return true to allow typing or pasting the value.
                                                    // Only allow values that are empty or valid 4-digit numbers.
                                                    return !formattedValue || (value >= 0 && value <= 9999);
                                                }}
                                            />
                                            {selectedYear && (
                                                <button className="clear-icon" onClick={() => handleClearYear()}>&times;</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                {
                                    onThisDay && onThisDay.dateMonth?.length > 0 && onThisDay.dateMonth.sort((a, b) => {
                                        const [aFirst, aSecond] = a.split('-').map(Number);
                                        const [bFirst, bSecond] = b.split('-').map(Number);
                                        if (aFirst === bFirst) {
                                            return aSecond - bSecond;
                                        }
                                        return aFirst - bFirst;
                                    }).map((date_month, index) => {
                                        return (
                                            <TableCollapsable collapsable count={'2 Items'} title={date_month} key={date_month}>
                                                <OnThisDayCollapsableSection collapsable title={`Sites Added `} bgColor={'bg-dark'} key={`${date_month}-top-section`} count={onThisDay.selected[date_month]?.length > 1 ? `${onThisDay.selected[date_month]?.length} Items` : `${onThisDay.selected[date_month]?.length || 0} Item`}>
                                                    {
                                                        onThisDay.selected && onThisDay.selected[date_month]?.length > 0 && onThisDay.selected[date_month].map((item, index) => {
                                                            return (<tr key={item?.objectID}>
                                                                <td>
                                                                    <div className='row g-1'>
                                                                        <div className='col-auto'>
                                                                            <div className='form-check mt-1'>
                                                                                <input className='form-check-input align-middle' type='checkbox' name='select_on_this_day' value={item.id} onClick={() => { removeSelectedOnThisDay(item?.objectID) }} defaultChecked />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-auto'>
                                                                            <div className='btn-list'>
                                                                                <LinkButton size='sm' icon='edit' to={`/${item.entity !== 'person' ? item.entity : 'people'}/${item.entity_id}/editor`} target="_blank" />
                                                                                <PreviewButton size='sm' link={`${item.entity !== 'person' ? item.entity : 'people'}/${item.entity_id}`} />
                                                                                <Button size='live'
                                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                                    color={item.live === true ? '' : 'danger'} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ImageViewer url={item.image_url} type='thumb' cssClass='thumb' />
                                                                </td>
                                                                <td><p className='fw-bold'>{`${item?.day}.${Number(item?.month) + 1}.${item?.year}`}</p></td>
                                                                <td><p className='fw-bold cnow-ellipsis-3'>{`${item?.entity_name}`}</p></td>
                                                                <td><p>{`${item?.sub_entity} : ${item?.name}`}</p></td>
                                                                <td><LongTextView lines={TABLE_ROWS + 1}>{item?.description}</LongTextView></td>
                                                                <td><div className='cnow-ellipsis-3'><div className='fw-bold'>{`${Number(item?.month) + 1}.${item?.day}.${item?.year} - ${item?.entity_name}`}</div><LongTextView lines={3}>{item?.description}</LongTextView></div></td>
                                                                <td><p className='text-capitalize'>{`${item?.entity} : ${item?.entity_name}`}</p></td>
                                                                <td></td>
                                                                <LocationList data={[item.location]} />
                                                                <td></td>
                                                                <td>{item?.objectID}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                                </OnThisDayCollapsableSection>
                                                <OnThisDayCollapsableSection collapsable title={`Sites Available`} bgColor={'bg-dark'} key={`${date_month}-bottom-section`} count={onThisDay.searched[date_month]?.length > 1 ? `${onThisDay.searched[date_month]?.length} Items` : `${onThisDay.searched[date_month]?.length || 0} Item`} >
                                                    {

                                                        onThisDay.searched && onThisDay.searched[date_month]?.length > 0 && onThisDay.searched[date_month].map((item, index) => {
                                                            return (<tr key={item?.objectID}>
                                                                <td>
                                                                    <div className='row g-1'>
                                                                        <div className='col-auto'>
                                                                            <div className='form-check mt-1'>
                                                                                <input className='form-check-input align-middle' type='checkbox' name='select_on_this_day' value={item.id} onClick={() => { selectedOnThisDay(item?.objectID) }} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-auto'>
                                                                            <div className='btn-list'>
                                                                                <LinkButton size='sm' icon='edit' to={`/${item.entity !== 'person' ? item.entity : 'people'}/${item.entity_id}/editor`} target="_blank" />
                                                                                <PreviewButton size='sm' link={`${item.entity !== 'person' ? item.entity : 'people'}/${item.entity_id}`} />
                                                                                <Button size='live'
                                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                                    color={item.live === true ? '' : 'danger'} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ImageViewer url={item.image_url} type='thumb' cssClass='thumb' />
                                                                </td>
                                                                <td><p className='fw-bold'>{`${item?.day}.${Number(item?.month) + 1}.${item?.year}`}</p></td>
                                                                <td><p className='fw-bold cnow-ellipsis-3'>{`${item?.entity_name}`}</p></td>
                                                                <td><p>{`${item?.sub_entity} : ${item?.name}`}</p></td>
                                                                <td><LongTextView lines={TABLE_ROWS + 1}>{item?.description}</LongTextView></td>
                                                                <td><div className='cnow-ellipsis-3'><div className='fw-bold'>{`${Number(item?.month) + 1}.${item?.day}.${item?.year} - ${item?.entity_name}`}</div><LongTextView lines={3}>{item?.description}</LongTextView></div></td>
                                                                <td><p className='text-capitalize'>{`${item?.entity} : ${item?.entity_name}`}</p></td>
                                                                <td></td>
                                                                <LocationList data={[item.location]} />
                                                                <td></td>
                                                                <td>{item?.objectID}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>)
                                                        })
                                                    }
                                                </OnThisDayCollapsableSection>
                                            </TableCollapsable>
                                        )
                                    })

                                }
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default FeaturedOnThisDay;