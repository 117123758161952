/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { uniqBy } from 'lodash';

import Dragula from "react-dragula";
import TagEditor from '../../components/editors/tagEditor';
import TextEditor from '../../components/editors/textEditor';
import TextAreaEditor from '../../components/editors/textAreaEditor';

import SelectSite from '../../components/selects/site';
import SelectPerson from '../../components/selects/person';
import SelectGlobalList from '../../components/selects/globalList';
import SelectDate from '../../components/selects/date';

import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';

import { customSelectStyle, newTempId, personName } from '../../helpers';
import SelectPlaylist from '../../components/selects/playlist';
import SelectMap from '../../components/selects/map';
import SelectImage from '../../components/selects/image';
import SelectTour from '../../components/selects/tour';
import SelectEvent from '../../components/selects/event';
import SelectRecipe from '../../components/selects/recipe';
import SelectEventSeries from '../../components/selects/eventSeries';
import ImageCategoryList from '../../components/selects/entityCategoryList';
import uniqid from 'uniqid';

import { handleReorder } from '../../helpers';

const relatedItemTypes = require('../../data/related_item_types.json');
const _api = require('../../api')

const ImageForm = (props) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const history = useHistory();
    const image = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const DragulaContainer_ThenNow = 'dragula-container-thennow'
    const DragulaContainer_Credits = 'dragula-container-credits'
    const DragulaContainer_RelatedItems = 'dragula-container-related-items'
    const DragulaContainer_InThisPhoto = 'dragula-container-inthisphoto'

    useEffect(() => {
        //then and now
        let containers = []
        containers.push(document.getElementById(DragulaContainer_ThenNow))

        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(image.related_images, null, DragulaContainer_ThenNow)
            image.related_images = items || []
            processThenNow()
            dispatch({ type: 'entity', payload: { ...image, related_images: image.related_images } })

            setDragula(uniqid())
            // window.location.reload()
            // props.refresh && props.refresh()
        })

        //credits
        containers = []
        containers.push(document.getElementById(DragulaContainer_Credits))

        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(image.credits, null, DragulaContainer_Credits)
            image.credits = items || []
            image.credits.forEach((item, idx) => item.sort = idx + 1)
            dispatch({ type: 'entity', payload: { ...image, credits: image.credits } })

            setDragula(uniqid())
            // props.refresh && props.refresh()
        })

        //related_items
        containers = []
        containers.push(document.getElementById(DragulaContainer_RelatedItems))

        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(image.related_items, null, DragulaContainer_RelatedItems)
            image.related_items = items || []
            image.related_items.forEach((item, idx) => item.sort = idx + 1)
            dispatch({ type: 'entity', payload: { ...image, related_items: image.related_items } })

            setDragula(uniqid())
            // props.refresh && props.refresh()
        })

        //entity_tags
        containers = []
        containers.push(document.getElementById(DragulaContainer_InThisPhoto))

        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(image.entity_tags, null, DragulaContainer_InThisPhoto)
            image.entity_tags = items || []
            image.entity_tags.forEach((item, idx) => item.sort = idx + 1)
            dispatch({ type: 'entity', payload: { ...image, entity_tags: image.entity_tags } })

            setDragula(uniqid())
            // props.refresh && props.refresh()
        })

    }, [dragula])
    // Dragula End

    useEffect(() => {
        if (image.type === 'then_now' && !image.caption && !image.description) setThenNowCaption()
    }, [image.id])

    const handleInputChange = ({ target: { name, value } }) => {
        image[name] = value;
        dispatch({ type: 'entity', payload: { ...image, [name]: value } });
    };

    const handleEditorChange = (data) => {
        image.description = data;
        dispatch({ type: 'entity', payload: { ...image, description: image.description } });
    }

    const handleCategoryChange = (value) => {
        if (!image.category) image.category = {}
        image.category_id = value.id
        image.category.name = value.image_name;
        dispatch({ type: 'entity', payload: { ...image, category: image.category } });
    };

    const addImageCredit = () => {
        if (!image.credits) image.credits = []

        image.credits.push({
            'id': newTempId(),
            'image_id': image.id,
            'person_id': null,
            'person_name': '',
            'person_first_name': '',
            'person_last_name': '',
            'role_id': null,
            'role_name': '',
            'sort': image.credits ? image.credits.length + 1 : 1
        })
        image.credits.forEach((item, idx) => item.sort = idx + 1)
        dispatch({ type: 'entity', payload: { ...image, credits: image.credits } });
    }

    const removeImageCredit = async (index) => {
        if (image.credits[index]) {
            if (!image.credits[index].id.startsWith('temp.'))
                await _api.image.deleteCredit(image.credits[index].id)

            image.credits.splice(index, 1)
            image.credits.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...image, credits: image.credits } })
        }
    }

    const handleCreditChange = (type, index, value) => {
        let credits = image.credits ? image.credits : []

        if (type === 'person') {
            credits[index].person_name = value.name
            credits[index].person_id = value.id
            credits[index].is_participant = value.is_participant || false
        }
        if (type === 'role') {
            credits[index].role_name = value.name
            credits[index].role_id = value.id
        }

        dispatch({ type: 'entity', payload: { ...image, credits: credits } });
    };

    const handleTakenDateChange = (data) => {
        image.taken_date = data;
        dispatch({ type: 'entity', payload: { ...image, taken_date: image.taken_date } });
    }

    //#region Related Items

    const addRelatedItem = () => {
        if (!image.related_items) image.related_items = [];
        image.related_items.push({
            'row_id': newTempId(),
            'id': null,
            'name': '',
            'source': '',
        });

        dispatch({ type: 'entity', payload: { ...image, related_items: image.related_items } });
    }

    const removeRelatedItem = async (index) => {
        if (image.related_items[index]) {
            if (!image.related_items[index].row_id?.startsWith('temp.'))
                await _api[image.related_items[index].source].deleteImage(image.related_items[index].row_id)

            image.related_items.splice(index, 1)
            dispatch({ type: 'entity', payload: { ...image, related_items: image.related_items } });
        }
    }

    const handleRelatedItemTypeChange = (index, value) => {
        image.related_items[index].source = value.value;
        dispatch({ type: 'entity', payload: { ...image, related_items: image.related_items } });
    }

    const handleRelatedItemChange = async (index, value) => {
        image.related_items[index].id = value.id
        image.related_items[index].name = value.name
        image.related_items[index].is_participant = value.is_participant || false

        let params = {
            id: image.related_items[index].row_id,
            image_id: image.id,
            date_id: null,
            type: image.type
        }
        let response = await _api[image.related_items[index].source].updateImage(value.id, [params])
        image.related_items[index].row_id = response ? response[0].id : image.related_items[index].row_id;
        dispatch({ type: 'entity', payload: { ...image, related_items: image.related_items } });
    };
    //#endregion

    //#region Image Tags 

    const removeImageTag = async (index) => {
        if (image.entity_tags[index]) {
            if (!image.entity_tags[index].id.startsWith('temp.')) {
                await _api.image.deleteEntityTag(image.entity_tags[index].id)
            }
            image.entity_tags.splice(index, 1)
            image.entity_tags.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...image, entity_tags: image.entity_tags } })
        }
    }

    //#endregion

    const toggleShowTakenDate = (isTakenDate) => {
        dispatch({ type: 'entity', payload: { ...image, show_taken_date: isTakenDate } });
    }

    //#region Related images(then and now)
    const addRelatedImage = () => {
        if (!image.related_images) image.related_images = [];

        image.related_images.push({
            'id': newTempId(),
            'image_id': image.id,
            'related_image_id': null,
            'related_image_caption': '',
            'related_image_credits': '',
            'description': '',
            'taken_date': null,
        })

        dispatch({ type: 'entity', payload: { ...image, related_images: image.related_images } });
    }

    const handleImageChange = async (index, value) => {
        image.related_images[index].related_image_id = value.id
        image.related_images[index].related_image_caption = value.caption
        processThenNow()
        dispatch({ type: 'entity', payload: { ...image, related_images: image.related_images } });
        saveImages()
    }

    const removeThenNowImage = async (index) => {
        if (!image.related_images[index]?.id?.startsWith('temp.'))
            await _api.image.deleteRelatedImage(image.related_images[index].id)

        image.related_images.splice(index, 1)
        processThenNow()
        dispatch({ type: 'entity', payload: { ...image, related_images: image.related_images } });
        saveImages()
    }

    const saveImages = () => {
        let imgs = image.related_images ? [...image.related_images] : []
        let imgObj = {
            'id': image.id,
            'caption': image.caption,
            'url': image.url
        }
        imgs.unshift(imgObj)
        imgs = imgs?.length > 0 ? uniqBy(imgs, 'id') : []
        dispatch({ type: 'images', payload: { entityImages: imgs, source: location.pathname } })
    }

    const setThenNowCaption = async () => {
        let imagesCaption = ''
        let imagesDescription = ''
        for (const item of image.related_images) {
            imagesCaption += item.related_image_caption ? `${item.related_image_caption} - ` : ''
            imagesDescription += item.description ? `${item.description} ` : ''
        }
        image.caption = imagesCaption.slice(0, -2)
        image.description = imagesDescription
        let myImage = await _api.image.update(image)
        dispatch({ type: 'entity', payload: myImage })
    }

    const processThenNow = async () => {
        props.showSpinner(true)
        // 1. update related images
        if (image.related_images?.length > 0) {
            image.related_images = await _api.image.updateRelatedImage(image.id, image.related_images)
            dispatch({ type: 'entity', payload: { ...image, related_images: image.related_images } });
        }
        // 2. Process image
        await _api.image.process(image)

        window.location.reload();
    }
    //#end related images

    const getRouteName = (item) => {
        if (item.source === 'person') {
            return !item.is_participant ? 'people' : 'participant'
        }
        else return item.source
    }

    return (
        <div className='cnow-left-menu'>
            <div className='cnow-form-title'>Edit Image Information *</div>
            {
                image && <>
                    <div id='caption_description'>
                        <span className='cnow-form-label'>Caption *</span>
                        <input name='caption' type='text' className='form-control' value={image?.caption || ''} onChange={handleInputChange} key={`caption-${image.id}`} />
                        <TextEditor key={`description-${image.id}`} data={image?.description} toolbar={true} onChange={(data) => handleEditorChange(data)} autoComplete="off" showRemarksAttribution ={true} type='image'  entity_id={image.id} date_id={null}>
                            <label className='cnow-form-label mt-2'>Description</label>
                        </TextEditor>
                    </div>
                    {
                        image.type === 'then_now' && <>
                            <hr className='cnow-hr' />
                            <div className='d-flex justify-content-between'>
                                <div></div>
                                <div><Button size='sm' icon='plus' label='Add one more' onClick={() => addRelatedImage()} /></div>
                            </div>
                            <div id={DragulaContainer_ThenNow} key={dragula}>
                                {image.related_images?.map((item, index) => {
                                    return (
                                        <div id={item.id} key={index} className='mb-2 dragula-item'>
                                            <div className='card-bodys'>
                                                <div className='row g-1'>
                                                    <div className='col-auto d-flex flex-grow-1'>
                                                        <div className='w-100'>
                                                            <label className='cnow-form-title mb-0'>Image {index + 1} *</label>
                                                            {
                                                                image.type === 'then_now' &&
                                                                <SelectImage value={{ 'id': item.related_image_id, 'caption': item.related_image_caption || 'No Caption', 'url': item.url }} onChange={(value) => handleImageChange(index, value)} />

                                                            }
                                                            {image.type !== 'then_now' &&
                                                                <SelectImage value={{ 'id': item.id, 'caption': item.caption || 'No Caption', 'url': item.url }} onChange={(value) => handleImageChange(index, value)} />
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <LinkButton icon='edit' to={`/image/${image.type === 'then_now' ? item.related_image_id : item.id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeThenNowImage(index)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }

                    {
                        image.type !== 'then_now' && <>
                            <div id='image_type'>
                                <hr className='cnow-hr' />
                                <div className='cnow-form-title'>Type of Image *</div>
                                <ImageCategoryList type='image_category' value={{ id: image?.category_id, name: image?.category?.name }} onChange={(value) => handleCategoryChange(value)} />
                            </div>

                            <div id='image_credits'>
                                <hr className='cnow-hr' />
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <label className='cnow-form-title'>Photo Credits *</label>
                                    </div>
                                    <div>
                                        <Button size='sm' icon='plus' label='Add one more' onClick={() => addImageCredit()} />
                                    </div>
                                </div>
                                <div id={DragulaContainer_Credits} key={dragula}>
                                    {
                                        image.credits?.length > 0 && image.credits?.map((item, index) => {
                                            return (
                                                <div id={item.id} className='row g-1 mt-1 dragula-item' key={index}>
                                                    <div className={image.credits.length > 1 ? 'col-auto' : 'd-none'}>
                                                        {index === 0 && <label className='cnow-form-label'>Order</label>}
                                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} autoComplete="off" />
                                                    </div>
                                                    <div className='col-auto d-flex flex-grow-1'>
                                                        <div className='w-75'>
                                                            {index === 0 && <label className='cnow-form-label'>Role</label>}
                                                            <SelectGlobalList type='image_credit' value={{ id: item.role_id, name: item.role_name }} onChange={(value) => handleCreditChange('role', index, value)} />
                                                        </div>

                                                        <div className='w-100 ms-2'>
                                                            {index === 0 && <label className='cnow-form-label'>Name</label>}
                                                            <SelectPerson value={{ id: item.person_id, name: personName(item), is_participant: item.is_participant }} onChange={(value) => handleCreditChange('person', index, value)} />
                                                        </div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        {index === 0 && <label className='cnow-form-label'></label>}
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`${!item.is_participant ? 'people' : 'participant'}/${item.person_id}`} />
                                                            <LinkButton icon='edit' to={`/${!item.is_participant ? 'people' : 'participant'}/${item.person_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeImageCredit(index)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div id='move_image'>
                                <hr className='cnow-hr' />
                                <div className='cnow-form-title'>Move image to a different section</div>
                            </div>

                            <div id='date_taken'>
                                <hr className='cnow-hr' />
                                <div className='cnow-form-title'>Date Taken <small>(When was the image originally drawn/published/photographed)</small></div>
                                <div className='d-flex justify-content-between w-100'>
                                    <div>
                                        <SelectDate type={false} value={image?.taken_date} onChange={handleTakenDateChange} />
                                    </div>
                                    <div className='form-check py-2'>
                                        <input className='form-check-input' name='show_taken_date' type='checkbox' checked={image?.show_taken_date} onChange={(e) => toggleShowTakenDate(e.target.checked)} />
                                        <label className='small'>show</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <div id='related_items'>
                        <hr className='cnow-hr' />
                        <div className='d-flex justify-content-between'>
                            <div>
                                <label className='cnow-form-title'>Related Item</label>
                            </div>
                            <div>
                                <Button size='sm' icon='plus' label='Add one more' onClick={() => addRelatedItem()} />
                            </div>
                        </div>
                        {
                            image?.related_items?.length > 0 && <div className='d-flex mt-1'>
                                <div className={image?.related_items?.length > 1 ? '' : 'd-none'} style={{ width: '50px' }}>
                                    <label className='cnow-form-label'>Order</label>
                                </div>
                                <div className='flex-grow-1 d-flex'>
                                    <div className='w-75 ms-1'>
                                        <label className='cnow-form-label'>Type</label>
                                    </div>
                                    <div className='w-100 ms-1'>
                                        <label className='cnow-form-label'>Name</label>
                                    </div>
                                </div>
                                <div className='text-end' style={{ width: '130px' }}>
                                    <label className='cnow-form-label'></label>
                                </div>
                            </div>
                        }
                        <div id={DragulaContainer_RelatedItems} key={dragula}>
                            {
                                image?.related_items?.map((item, index) => {
                                    return (
                                        <div id={item.id} className='d-flex mt-1 dragula-item' key={index}>
                                            <div className={image?.related_items?.length > 1 ? '' : 'd-none'}>
                                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} autoComplete="off" />
                                            </div>
                                            <div className='flex-grow-1 d-flex'>
                                                <div className='w-75 ms-1'>
                                                    {!item?.row_id?.startsWith('temp.') ?
                                                        <input value={item.source} disabled className='cnow-input-height w-100' /> :
                                                        <Select name='type'
                                                            options={relatedItemTypes}
                                                            styles={customSelectStyle}
                                                            value={{ 'value': item.source, 'label': item.source }}
                                                            onChange={(value) => { handleRelatedItemTypeChange(index, value) }} placeholder={<div className='text-muted'>MM</div>} />}
                                                </div>

                                                <div className='w-100 ms-1'>
                                                    {!item?.row_id?.startsWith('temp.') ?
                                                        <input value={item.name} disabled className='cnow-input-height w-100' />
                                                        :
                                                        <>
                                                            {item.source === '' &&
                                                                <Select styles={customSelectStyle} />}
                                                            {item.source === 'site' &&
                                                                <SelectSite value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'person' &&
                                                                <SelectPerson value={{ 'id': item.id, 'name': item.name, is_participant: item.is_participant }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'playlist' &&
                                                                <SelectPlaylist value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'map' &&
                                                                <SelectMap value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'tour' &&
                                                                <SelectTour value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'event' &&
                                                                <SelectEvent value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'event_series' &&
                                                                <SelectEventSeries value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                                                            {item.source === 'recipe' &&
                                                                <SelectRecipe value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} type_id='' />}
                                                        </>}

                                                </div>
                                            </div>
                                            <div className='text-end ms-1'>
                                                <div className='d-flex gap-1 justify-content-end'>
                                                    <PreviewButton link={`${getRouteName(item)}/${item.id}`} />
                                                    <LinkButton icon='edit' to={`/${getRouteName(item)}/${item.id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeRelatedItem(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* {image.type !== 'then_now' && */}
                        <div id='entity_tags'>
                            <hr className='cnow-hr' />
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label className='cnow-form-title'>In this Photo List <small>(Tag on the image)</small></label>
                                </div>
                                <div>
                                    <Button size='sm' icon='plus' label='Add one more' onClick={() => { props.onSave(); _api.image.update(image).then(_ => history.push(`/image/${image.id}/tag`)) }} />
                                </div>
                            </div>
                            <div id={DragulaContainer_InThisPhoto} key={dragula}>
                                {image.entity_tags?.map((item, index) => {
                                    return (
                                        <div id={item.id} className='row g-1 mt-1 dragula-item' key={index}>
                                            <div className={image.entity_tags.length > 1 ? 'col-auto' : 'd-none'}>
                                                {index === 0 && <label className='cnow-form-label'>Order</label>}
                                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} data-id={item.id} required autoComplete="off" />
                                            </div>
                                            <div className='col'>
                                                {index === 0 && <label className='cnow-form-label'>Name</label>}
                                                <input disabled={true} value={item.site_name || personName(item)} className='cnow-input-disabled-height w-100' />
                                            </div>
                                            <div className='col-auto'>
                                                {index === 0 && <label className='cnow-form-label'></label>}
                                                <div className='btn-list'>
                                                    <PreviewButton link={`${item.site_id ? 'site' : 'people'}/${item.site_id ? item.site_id : item.person_id}`} />
                                                    <LinkButton icon='edit' to={`/${item.site_id ? 'site' : 'people'}/${item.site_id ? item.site_id : item.person_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeImageTag(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    {/* } */}

                    {image.type !== 'then_now' && <>
                        <TagEditor id={props.id} type='image' />
                        <div id='alt_text'>
                            <hr className='cnow-hr' />
                            <TextAreaEditor id={image?.id} title='Alt Text' subTitle='(Additional Description for Image recognition)' data={image?.alt_text} />
                        </div>
                    </>}
                </>
            }
        </div>
    )
}

export default ImageForm;