/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import TextEditor from './textEditor';
import SelectPerson from '../selects/person';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId, personName } from '../../helpers';
const _api = require('../../api')

const TourPeopleEditor = (props) => {
	const dispatch = useDispatch();
	const entity = useSelector(state => state[props.id]);
	// Dragula Start
	const [dragula, setDragula] = useState(uniqid())
	const dragulaRef = useRef()
	const dragulaContainer = `dragula-container-${props.section}-people`

	useEffect(() => {
		let containers = []
		containers.push(document.getElementById(dragulaContainer))
		dragulaRef.current = Dragula(containers, {})
		dragulaRef.current.on('drop', () => {
			const items = handleReorder((props.section === 'created_by' ? entity.created_by : (entity.partner_org ? entity.partner_org : entity.tour_leader)), null, dragulaContainer)
			if (props.section === 'created_by') {
				dispatch({ type: 'entity', payload: { ...entity, created_by: items } })
			} else if (props.section === 'partner_org') {
				dispatch({ type: 'entity', payload: { ...entity, partner_org: items } })
			} else if (props.section === 'tour_leader') {
				dispatch({ type: 'entity', payload: { ...entity, tour_leader: items } })
			}
			setDragula(uniqid())
			props.refresh && props.refresh()
		})
	}, [dragula])
	// Dragula End

	const addPerson = () => {
		if (props.section === 'created_by') {
			if (!entity.created_by) entity.created_by = [];
			entity.created_by.push({
				'id': newTempId(),
				'tour_id': props.id,
				'person_id': null,
				'description': '',
				'source': props.type,
				'is_participant': false,
				'sort': entity.created_by ? entity.created_by.length + 1 : 1,
			})
			entity.created_by.forEach((item, index) => item.sort = index + 1)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, created_by: entity.created_by } });
		}
		if (props.section === 'partner_org') {
			if (!entity.partner_org) entity.partner_org = [];
			entity.partner_org.push({
				'id': newTempId(),
				'tour_id': props.id,
				'person_id': null,
				'description': '',
				'source': props.type,
				'is_participant': false,
				'sort': entity.partner_org ? entity.partner_org.length + 1 : 1,
			})
			entity.partner_org.forEach((item, index) => item.sort = index + 1)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, partner_org: entity.partner_org } });
		}
		if (props.section === 'tour_leader') {
			if (!entity.tour_leader) entity.tour_leader = [];
			entity.tour_leader.push({
				'id': newTempId(),
				'tour_id': props.id,
				'person_id': null,
				'description': '',
				'source': props.type,
				'is_participant': false,
				'sort': entity.tour_leader ? entity.tour_leader.length + 1 : 1,
			})
			entity.tour_leader.forEach((item, index) => item.sort = index + 1)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, tour_leader: entity.tour_leader } });
		}

	}

	const removePerson = async (index) => {
		if (props.section === 'created_by') {
			if (entity.created_by[index]) {
				if (!entity.created_by[index].id.startsWith('temp.')) {
					await _api.tour.deleteCreatedBy(entity.created_by[index].id)
				}
				entity.created_by.splice(index, 1)
				entity.created_by.forEach((item, index) => item.sort = index + 1)
				dispatch({ type: 'entity', payload: { ...entity, created_by: entity.created_by } });
			}
		}
		if (props.section === 'partner_org') {
			if (entity.partner_org[index]) {
				if (!entity.partner_org[index].id.startsWith('temp.')) {
					await _api.tour.deletePartnerOrg(entity.partner_org[index].id)
				}
				entity.partner_org.splice(index, 1)
				entity.partner_org.forEach((item, index) => item.sort = index + 1)
				dispatch({ type: 'entity', payload: { ...entity, partner_org: entity.partner_org } });
			}
		}
		if (props.section === 'tour_leader') {
			if (entity.tour_leader[index]) {
				if (!entity.tour_leader[index].id.startsWith('temp.')) {
					await _api.tour.deletePartnerOrg(entity.tour_leader[index].id)
				}
				entity.tour_leader.splice(index, 1)
				entity.tour_leader.forEach((item, index) => item.sort = index + 1)
				dispatch({ type: 'entity', payload: { ...entity, tour_leader: entity.tour_leader } });
			}
		}
	}

	const handleEditorChange = (index, data) => {
		if (props.section === 'created_by') {
			entity.created_by[index].description = data;
			dispatch({ type: 'entity', payload: { ...entity, created_by: entity.created_by } });
		}
		if (props.section === 'partner_org') {
			entity.partner_org[index].description = data;
			dispatch({ type: 'entity', payload: { ...entity, partner_org: entity.partner_org } });
		}
		if (props.section === 'tour_leader') {
			entity.tour_leader[index].description = data;
			dispatch({ type: 'entity', payload: { ...entity, tour_leader: entity.tour_leader } });
		}

	}

	const handlePersonChange = (index, value) => {
		if (props.section === 'created_by') {
			entity.created_by[index].person_id = value.id;
			entity.created_by[index].person_name = value.name;
			entity.created_by[index].is_participant = value.is_participant;
			dispatch({ type: 'entity', payload: { ...entity, created_by: entity.created_by } });
		}
		if (props.section === 'partner_org') {
			entity.partner_org[index].person_id = value.id;
			entity.partner_org[index].person_name = value.name;
			entity.partner_org[index].is_participant = value.is_participant;
			dispatch({ type: 'entity', payload: { ...entity, partner_org: entity.partner_org } });
		}
		if (props.section === 'tour_leader') {
			entity.tour_leader[index].person_id = value.id;
			entity.tour_leader[index].person_name = value.name;
			entity.tour_leader[index].is_participant = value.is_participant;
			dispatch({ type: 'entity', payload: { ...entity, tour_leader: entity.tour_leader } });
		}
	}

	return (
		<div>
			<hr className='cnow-hr' />
			<div className='row'>
				<div className='col'>
					<label className='cnow-form-title'>{props.title}</label>
				</div>
				<div className='col-auto'>
					<Button size='sm' icon='plus' label='Add a Person' onClick={() => addPerson()} />
				</div>
			</div>
			<div id={dragulaContainer} key={dragula} >
				{
					entity?.[props.section]?.map((item, index) => {
						return (
							<div key={index} id={item.id} className='mb-3 dragula-item'>
								<div className='row g-1'>
									<div className={entity?.[props.section]?.length > 1 ? 'col-auto' : 'd-none'}>
										<label className='cnow-form-label fw-bold'>Order</label>
										<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
									</div>
									<div className='col'>
										<div className='row g-1'>
											<div className='col-auto d-flex flex-grow-1'>
												<div className='w-100'>
													<label className='cnow-form-label fw-bold'>Name*</label>
													{
														!item.id.startsWith('temp.') ?
															<input value={personName(item)} disabled className='w-100 cnow-input-disabled-height' /> :
															<SelectPerson key={item.id} value={{ id: item?.person_id, name: personName(item) }} className="prime1" onChange={(value) => handlePersonChange(index, value)} />
													}
												</div>
											</div>
											<div className='col-auto'>
												<label className='cnow-form-label'></label>
												<div className='btn-list'>
													<PreviewButton link={`${!item.is_participant ? 'people' : 'participant'}/${item?.person_id}`} />
													<LinkButton icon='edit' to={`/${!item.is_participant ? 'people' : 'participant'}/${item?.person_id}/editor`} />
													<Button icon='delete' onClick={() => removePerson(index)} />
												</div>
											</div>
										</div>

										<TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(index, data)}>
											<label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
										</TextEditor>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default TourPeopleEditor;