/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import TableHeader from '../../../components/tableHeader';
import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
import ImageViewer from '../../../components/viewers/image';
import PreviewButton from '../../../components/buttons/preview';
import swal from 'sweetalert';
import { differenceBy } from 'lodash';
import RelatedPeopleList from '../../../components/table/relatedPeopleList';
import RelatedSiteList from '../../../components/table/relatedSiteList';
import LocationList from '../../../components/table/locationList';
import EventType from '../../../components/table/eventType';
import { useSelector, useDispatch } from 'react-redux';
import { changeUTC, newTempId, TABLE_ROWS } from '../../../helpers';
import EventPeopleList from '../../../components/table/eventPeopleList';
import EventTicketPrice from '../../../components/table/eventTicketPrice';
import EventReservation from '../../../components/table/eventReservation';
import EventEducation from '../../../components/table/eventEducation';
import TagList from '../../../components/table/tagList';
import LongTextView from '../../../components/viewers/longText';
import PodcastList from '../../../components/table/podcastList';
import PlayList from '../../../components/table/playlist';
import RelatedEventSeriesList from '../../../components/table/relatedEventSeries';
import RelatedRecipeList from '../../../components/table/relatedRecipeList';
import RelatedEventList from '../../../components/table/relatedEvent';
import RelatedTourList from '../../../components/table/relatedTourList';
import RelatedMapList from '../../../components/table/relatedMapList';

const _api = require('../../../api');
const columns = require('../../../data/columns_event.json');

const FeaturedEvent = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    // const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    const [paginationHits, setPaginationHits] = useState('')
    let section = props.section
    const searchEvent = useSelector((state) => state.search);
    let searchResult = searchEvent.searchResult || []
    let selectedResult = searchEvent.selectedResult?.filter((elem) => elem.entity_type === 'event') || []
    let sectionResult = searchEvent[section]?.filter((elem) => elem.entity_type === 'event') || []
    let selectedEntity = [...sectionResult, ...selectedResult]
    const featured = useSelector((state) => state.featured);
    const [resultFilters, setResultFilters] = useState({
        live: searchEvent.filterLive,
        limit: searchEvent.selectedLimit,
        future_event: true
    })
    const [label, setLabel] = useState([])
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    useEffect(() => {
        search()
    }, [page, sort, keyword]);

    const search = async () => {
        showSpinner(true)
        // setSearchResult([])
        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    {
                        "match": {
                            "deleted": "false"
                        }
                    },
                    {
                        "match": {
                            "is_parent": "true"
                        }
                    },
                    { "match": { "created_via": "ADMIN" } },

                ]
            }
        };
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^5",
                        "participants.person_name",
                        "alias",
                        "participants.person_first_name",
                        "participants.person_last_name",
                        "hosts.person_name",
                        "locations.name",
                        "locations.city",
                        "locations.state",
                        "locations.country",
                        "categories.category_name",
                        "categories.subcategory_name",
                        "types.type_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_people.related_person_name",
                        "podcasts.podcast_name",
                        "participants.role_name",
                        "people.profession.profession_name",
                        "related_people.org_type.organization_name",
                        "locations.address"
                    ],
                    "fuzziness": "AUTO",
                    "prefix_length": 2
                }
            });
        }
        if (resultFilters?.live) {
            params.bool.must[params.bool.must.length] = { "match": { "live": resultFilters?.live } }
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }

        handleSetKeywords(keyword)
        if (resultFilters.future_event) {


            params.bool.must.push({
                "range": {
                    "occurring_dates.occurrence": {
                        "gte": moment().unix()
                    }
                }
            })

            await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                // setSearchResult(results)
                setPaginationHits(results?.hits?.total?.value);
                const mappedHits = results?.hits?.hits.map(hit => hit._source);
                const filteredArray = differenceBy(mappedHits, selectedEntity, 'id')
                dispatch({ type: 'search', payload: { ...searchEvent, searchResult: filteredArray } })
                showSpinner(false)
            })
        }
        else {
            await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                // setSearchResult(results)
                setPaginationHits(results?.hits?.total?.value);
                const mappedHits = results?.hits?.hits.map(hit => hit._source);
                const filteredArray = differenceBy(mappedHits, selectedEntity, 'id')
                dispatch({ type: 'search', payload: { ...searchEvent, searchResult: filteredArray } })
                showSpinner(false)
            })
        }
    }

    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.live) data.push(`Live - Events`)
        if (resultFilters?.future_event) data.push(`Future - Events`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Live')) resultFilters.live = ''
        if (data.includes('Future - Events')) resultFilters.future_event = false
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const handleDelete = (event) => {
        swal({
            title: `Delete Event`,
            text: `Are you sure that you want to delete ${event?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.event.deleteById(event.id).then(() => { search() })
        });
    }

    const updateLive = (id, live) => {

        showSpinner(true)
        let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
        let selectedResult = selectedSearchResult[0]
        if (live === true) {
            _api.event.updateLive(id, live).then((_) => search())
            swal({
                title: 'Event is Live!',
                icon: 'success',
                text: `${selectedResult?.name} is now Live!`,
                buttons: 'Ok',
            })
        } else {
            _api.event.updateLive(id, live).then((_) => search())
            swal({
                title: 'Not Live!',
                icon: 'info',
                text: `${selectedResult?.name} is Not Live.`,
                buttons: 'Ok',
            })
        }
        showSpinner(false)
    }

    const selectedEvent = (index) => {
        showSpinner(true)
        searchResult[index].entity_type = 'event'
        selectedResult.push(searchResult[index])
        searchResult.splice(index, 1)
        dispatch({
            type: 'search', payload: {
                ...searchEvent,
                selectedResult: selectedResult,
                searchResult: searchResult
            }
        })
        showSpinner(false)
    }

    const removeSelectedEvent = (index) => {
        if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
            let section_index = selectedEntity[index].section_index
            let entity_index = selectedEntity[index].entity_index
            featured[section][section_index].edited = true
            featured[section][section_index].data[section].splice(entity_index, 1)
            featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
            let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
            sectionResult.splice(sectionIndex, 1)
            sectionResult.forEach((elem, index) => elem.entity_index = index)
            dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
            dispatch({ type: 'search', payload: { ...searchEvent, [`${section}`]: sectionResult } })
        }
        else {
            let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
            searchResult.push(selectedResult[searchResultIndex])
            selectedResult.splice(searchResultIndex, 1)
            dispatch({
                type: 'search', payload: { ...searchEvent, selectedResult: selectedResult, searchResult: searchResult }
            })
        }

    }

    const selectedEventDispatch = () => {
        if (selectedResult.length > 0) {
            let index = featured[`${section}`].length === 0 ? -1 : 0
            let data = {
                "start_date": start_date.slice(0, 10),
                "end_date": end_date.slice(0, 10),
                "section": `${section}`,
            }
            if (index === -1) {
                data.id = newTempId()
                data.data = {
                    [`${section}`]: selectedResult
                }
                featured[`${section}`].push(data)
            }
            else {
                featured[`${section}`][index].edited = true
                featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
            }
        }
        dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
        dispatch({ type: 'search', payload: {} })
        history.goBack()
    }

    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add an Event' color='danger' icon='plus-white' onClick={() => history.push('/event/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => { selectedEventDispatch() }}>{`${searchEvent.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                                <h6 className='mb-0'>EVENTS</h6>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={paginationHits} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search' key={`icon-${index}`}>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />

                            {/* <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div> */}
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colSpan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchEvent.title} EVENTS SELECTED`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        selectedEntity?.map((item, index) => (
                                            <tr key={`${item.id}-${index}`}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_event' defaultChecked={true} value={item.id} onClick={() => { removeSelectedEvent(index) }} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/event/${item.id}/editor`} target="_blank" />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                <PreviewButton size='sm' link={`event/${item.id}`} />
                                                                <Button size='live'
                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                    color={item.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item?.name}</td>
                                                <td>{changeUTC(item?.dates?.[0]?.start_date)}</td>
                                                <EventType data={item?.types} />
                                                <EventPeopleList host={item?.hosts} sponsor={item?.sponsors} other={item?.participants} />
                                                <LocationList data={item?.locations} />
                                                <td></td>
                                                <EventTicketPrice data={item?.tickets} />
                                                <EventReservation data={item?.reservations} />
                                                <td></td>
                                                <EventEducation data={item?.continuing_education} />
                                                <td></td>
                                                <td>{item?.id}</td>
                                                <TagList data={item.tags} />
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                </td>
                                                <PodcastList data={item?.podcasts} />
                                                <PlayList data={item?.related_playlists} />
                                                <RelatedEventSeriesList data={item?.related_event_series} />
                                                <RelatedRecipeList data={item?.recipes} />
                                                <RelatedSiteList data={item?.related_sites} />
                                                <RelatedPeopleList data={item?.related_people} />
                                                <RelatedEventList data={item?.related_events} />
                                                <RelatedTourList data={item?.related_tours} />
                                                <RelatedMapList data={item?.related_maps} />
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colSpan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchEvent.title} EVENTS AVAILABLE`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        searchResult?.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_event' value={item.id} onClick={() => { selectedEvent(index) }} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/event/${item.id}/editor`} target="_blank" />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                <PreviewButton size='sm' link={`event/${item.id}`} />
                                                                <Button size='live'
                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                    color={item.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item?.name}</td>
                                                <td>{changeUTC(item?.dates?.[0]?.start_date)}</td>
                                                <EventType data={item?.types} />
                                                <EventPeopleList host={item?.hosts} sponsor={item?.sponsors} other={item?.participants} />
                                                <LocationList data={item?.locations} />
                                                <td></td>
                                                <EventTicketPrice data={item?.tickets} />
                                                <EventReservation data={item?.reservations} />
                                                <td></td>
                                                <EventEducation data={item?.continuing_education} />
                                                <td></td>
                                                <td>{item?.id}</td>
                                                <TagList data={item.tags} />
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                </td>
                                                <PodcastList data={item?.podcasts} />
                                                <PlayList data={item?.related_playlists} />
                                                <RelatedEventSeriesList data={item?.related_event_series} />
                                                <RelatedRecipeList data={item?.recipes} />
                                                <RelatedSiteList data={item?.related_sites} />
                                                <RelatedPeopleList data={item?.related_people} />
                                                <RelatedEventList data={item?.related_events} />
                                                <RelatedTourList data={item?.related_tours} />
                                                <RelatedMapList data={item?.related_maps} />
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default FeaturedEvent;