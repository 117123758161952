import React, { useState, useEffect } from "react";
import moment from 'moment'


const Description = ({ featured }) => {
    const about_the_museum_without_walls = featured.about_the_museum_without_walls
    const start_date = featured && featured.start_date && JSON.parse(featured.start_date)

    useEffect(() => { formatAboutTheMuseum() }, /* eslint-disable react-hooks/exhaustive-deps */[about_the_museum_without_walls?.[0]?.data?.description, about_the_museum_without_walls?.[0]?.data?.heading])
    const [descriptionData, setDescriptionData] = useState([]);
    const [headingData, setHeadingData] = useState([]);
    const formatAboutTheMuseum = () => {
        let data = []
        let headingArray = [];
        if (about_the_museum_without_walls)
            for (let i in about_the_museum_without_walls) {
                if (about_the_museum_without_walls[i]?.data?.description) {
                    data.push(about_the_museum_without_walls[i]?.data?.description)
                }
                if (about_the_museum_without_walls[i]?.data?.heading) {
                    headingArray.push(about_the_museum_without_walls[i]?.data?.heading);
                }

            }
        setDescriptionData(data)
        setHeadingData(headingArray);
    }

    return (
        <div className={`mt-2 fs-small`}>
            {headingData.length > 0 ? headingData.map((data, index) => {
                return (
                    <div key={`description-preview-${index}-heading`}>
                        <div dangerouslySetInnerHTML={{ __html: data }} className=' bg-light' />
                    </div>
                )
            })
                :
                <div className='' key={`description-preview-heading`}>
                    <h2><span style={{ color: "#a82829" }}><strong>{moment.unix(moment.utc(start_date).unix()).format("MMMM Do,YYYY")} </strong></span></h2>
                </div>

            }
            {descriptionData.length > 0 ? descriptionData.map((data, index) => {
                return (
                    <div key={`description-preview-${index}`}>
                        <div dangerouslySetInnerHTML={{ __html: data }} className=' bg-light' />
                    </div>
                )
            })
                :
                <div className='' key={`description-preview`}>
                    Consider the world outside a museum. Imagine that the world that we live in is really another kind of museum where the works of art exist in the landscape itself. What if you could have a gallery guide which would tell you about the buildings and artworks you find around you. It would show you what the place used to look like and introduce you to some of the people who shaped it. Our growing virtual collection of photographs and drawings, maps and documents, podcasts and videos tell the stories of how some of the more iconic places in our cities got to be the way they are and what they might become. Explore buildings of the past, present and future. Look at the vast selection of artwork that graces the public realm. And discover how places have evolved over time. Deconstruct the layers of history that form the fabric of our urban landscape. Meet people who have made their mark on our cities and country who have lived in the past or are living now. Listen to their voices. Take (or make) a tour. And join us at an event either virtual or real. Our curators are the artists, architects, photographers and historians who created the images, podcasts and videos to share their knowledge and insights. Our collaborators are museums, universities, cities, and civic organizations who are the stewards of our shared cultural history. Use the guide online or take it with you on your phone….. Like the cities we live in, this is a work in progress……..Enjoy!
                </div>
            }
        </div>

    )
}

export default Description