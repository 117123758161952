import React, { useState, useEffect, useRef } from "react";
import Button from "../buttons/default";
import PdfUploader from "./pdfUpload";
import PdfPreview from "./pdfPreview";
import PdfCurrentData from "./pdfCurrentData";
import Spinner from "../spinner";
const PdfDownloadUpload = (props) => {
    const [upload, setUpload] = useState(false)
    const [signedPdf, setSignedPdf] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [spinner, setSpinner] = useState(false)
    const pdf = props.pdf
    const pdfIndex = useRef()

    useEffect(() => {
        if (pdf?.length > 0) {
            if (currentIndex < pdf?.length) setSpinner(true)
            if (currentIndex === pdf?.length) setSpinner(false)
            if (!currentIndex) { pdfIndex.current = 0 }
            const timer = setInterval(() => {
                if (pdfIndex.current < pdf.length) {
                    pdfIndex.current = pdfIndex.current + 1
                    setCurrentIndex(pdfIndex.current)
                    return clearTimeout(timer);
                }
            }, findTiming(pdf[pdfIndex.current]))
        }
        // eslint-disable-next-line
    }, [pdf?.length, currentIndex])

    const findTiming = (data) => {
        // const count = data?.[data?.id]?.length
        // let milliSec
        // if (count > 0 && count <= 5) milliSec = 5000
        // if (count >= 6 && count <= 10) milliSec = 10000
        // if (count >= 11 && count <= 15) milliSec = 15000
        // if (count >= 16) milliSec = 20000
        // return milliSec
        return 10000
    }

    const handleDeleteDocuments = (id) => {
        const index = signedPdf.findIndex((elem) => elem.id === id)
        signedPdf.splice(index, 1)
        setSignedPdf([...signedPdf])
        props.handlePdfUpload([...signedPdf]);
    }
    return (
        <>
            <hr />
            <div className="container py-3">
                <div className="row">
                    <div className="col">
                        <span className='cnow-form-title'>PDF APPROVAL FORM</span>
                    </div>
                    <div className="col-auto">
                        <Spinner display={spinner}>
                            <Button size='sm' color='light' label={'Download Forms'} onClick={() => { props.onDownloadPdf() }} disabled={props.mode === 'upload_document' ? false : true} />
                            <Button size='sm' color='light' label={'Upload Forms'} onClick={() => { setUpload(true) }} />
                        </Spinner>
                    </div>
                </div>
                {signedPdf?.length > 0 && signedPdf.map((elem, index) => { return <PdfPreview key={elem.id} id={elem.id} url={elem.url} handleDelete={(id) => { handleDeleteDocuments(id) }} index={index} /> })

                }
            </div>

            <PdfCurrentData currentIndex={currentIndex} pdf={pdf} entity_title={props.entity_title} findTiming={findTiming} key={currentIndex} />

            {
                upload && <PdfUploader onSave={(data) => { setSignedPdf(data); props.handlePdfUpload(data); }} onHide={(value) => { setUpload(value) }} show={upload} onCancel={() => setUpload(false)} />
            }
        </>
    )
}
export default PdfDownloadUpload
