import { useSelector } from 'react-redux';
import React, { useState, useEffect } from "react";
import Spinner from '../spinner';
import { chain } from "lodash";
import { filterLiveAndDeleted } from "../../helpers";
import moment from "moment";
import TimeLine from "./timeLine";
import axios from "axios";

export default function EntityTimeLine(props) {
    const [timeline, setTimeline] = useState([]);
    const [spinner, setSpinner] = useState([]);
    // eslint-disable-next-line
    const [opacityStyle, setOpacityStyle] = useState({ id: '', sectionId: '', opacValue: '' })
    const featured = useSelector((state) => state.featured);
    useEffect(() => {
        PlacesOverTime()
        // eslint-disable-next-line
    }, []);

    const getTimeLineData = (list, site) => {
        setSpinner(true)
        let timelineList = [];
        //images, documents, date images
        let copyList = [...list]
        copyList = copyList.filter((x) => x.title.toLowerCase() !== "current");
        let groupedImages = chain(copyList).groupBy("year").map((value, key) => {
            let groupByYear = [];
            let img = []
            if (value[0]?.list?.length > 0) {
                for (let item of value[0]?.list) {
                    if (item.images?.length > 0 && img.length === 0) {
                        let id = item.id?.split('-')
                        id = `${id[0]}-Image${id[0]}-Document`

                        if (item.images?.[0]?.image_type === 'document') {
                            img = [{
                                url: item.images?.[0]?.image_url,
                                sectionId: item.id,
                                id: id,
                                image_type: 'document'
                            }]
                        } else {
                            img = [{
                                url: item.images?.[0]?.image_url,
                                sectionId: item.id,
                                id: id,
                            }]
                        }
                        groupByYear = img.length > 0 ? groupByYear?.concat(img) : groupByYear;
                    }
                }
            }

            return { year: key, section: groupByYear };
        }).value();

        if (groupedImages?.length) {
            timelineList = [].concat(groupedImages).concat(timelineList)
        }
        // associated sites
        // eslint-disable-next-line
        let aSites = site?.associated_sites?.map((item) => {
            if (item.live === true) {
                return {
                    year: item?.dates?.length > 0 ? item.dates[0].year : 0,
                    image: `${process.env.REACT_APP_API_URL}public/image/site/${item?.id}/thumb`,
                    id: item.id
                };
            }
        });

        if (aSites?.length) {
            let groupedASites = chain([...aSites]).groupBy("year")?.map((value, key) => {
                let groupByYear = [];
                let imgs = value?.map((d) => ({
                    url: d?.image,
                    sectionId: "site_associated_sites",
                    id: `tl-${d?.id}`
                }));
                groupByYear = groupByYear?.concat(imgs);
                return { year: key, section: groupByYear };
            }).value();
            if (groupedASites?.length) {
                timelineList = [].concat(groupedASites).concat(timelineList)
            }
        }

        //group timelineList by year
       
        let finalList = chain([...timelineList]).groupBy("year")?.map((value, key) => {
            let sections = []
            if (value.length > 0) {
                // eslint-disable-next-line
                value.map(data => {
                    sections = data?.section?.length > 0 ? [].concat(sections).concat(data.section) : sections
                })
            }
            return ({ year: key ? Number(key) : 0, section: sections })
        }).value();

        finalList = finalList.filter(x => x?.section?.length > 0);

        //sort
        finalList = finalList.sort((a, b) => Number(a.year) - Number(b.year));
        setTimeline(finalList);
        props.handleCount && props.handleCount(finalList?.length)
        setSpinner(false)
    };
    const getListData = (list, year, event) => {
        return {
            title: `${year === moment().year() ? "current" : year}${event ? " - " + event : ""}`,
            year: year || 0,
            list: list,
        };
    };

    const getSubListData = (images, year, event, type) => {
        return {
            title: `${year}${event ? " - " + event + " -" : ""}${type?.toLowerCase() === "image" ? " Images" : " Drawings and documents"}`,
            images: images,
            type: type,
            id: `${year}-${type}`,
        };
    };

    const sortSiteImages = async (site) => {
          let list = [], subList = [];
        let response =await axios.get(`/site/${site?.id}/public/associated_site`);
        if (response?.data?.length > 0) site.associated_sites = filterLiveAndDeleted(response.data) || [];

        //1. current images
        const siteImages = site?.images?.filter((item) => (item?.type === "image" || item?.type === "dzi") && !item.date_id);
        subList.push(getSubListData(siteImages, `current`, null, "Image"));

        //2. current documents
        const siteDocuments = site?.images?.filter((item) => item.type === "document" && !item.date_id);
        subList.push(getSubListData(siteDocuments, `current`, null, "Document"));

        list.push(getListData(subList, moment().year()));

        //3. date images and documents
        if (site?.dates) {
            for (let date of site.dates) {
                subList = [];
                const date_images = site?.images?.filter((item) => (item?.type === "image" || item?.type === "dzi") && item?.date_id === date?.id);
                const date_documents = site?.images?.filter((item) => item?.type === "document" && item?.date_id === date?.id);

                subList.push(getSubListData(date_images, date?.year, date?.event_type_name, "Image"));
                subList.push(getSubListData(date_documents, date?.year, date?.event_type_name, "Document"));

                list.push(getListData(subList, date?.year, date.event_type_name));
            }
        }
        list = list.sort((a, b) => Number(b.year) - Number(a.year));
        getTimeLineData(list, site);
    };

    const PlacesOverTime = async () => {
        const places_over_time = featured.places_over_time
        if (places_over_time?.length) {
            sortSiteImages(places_over_time?.[0]?.data?.places_over_time?.[0])
        } 
    }

    return (
        <>
            <Spinner display={spinner}>
                <div className="row">
                    <div className="site-sticky pt-1">
                        <div className="site-sticky pt-1"><TimeLine data={timeline} setOpacityStyle={setOpacityStyle} section={'homePage'} /></div>
                    </div>
                </div>
            </Spinner >
        </>);
}