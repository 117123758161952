import React, { useState } from 'react';

function SignatureForm(props) {
  const [name, setName] = useState('');
  const today = new Date().toLocaleDateString();

  const handleNameChange = (event) => {
    setName(event.target.value)
    props.onChange(event.target.value, new Date().toISOString().split('T')[0])
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="signature" className="col-sm-3 col-form-label text-end">
              Signature:
            </label>
          </div>
        </div>
        <div className="col-lg-8">
          <input
            type="text"
            id="signature"
            value={name}
            onChange={handleNameChange}
            className="form-control"
            disabled={props.mode === 'upload_document' ? true : false}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-4">
          <div className="form-group row">
            <label htmlFor="date" className="col-sm-9 col-form-label">
              Today's Date:
            </label>
          </div>
        </div>

        <div className="col-lg-8">
          <input type="text" id="date" value={today} disabled className="form-control" />
        </div>
      </div>
    </>

  );
}

export default SignatureForm;

