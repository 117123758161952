/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MetaKeywordsEditor = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    useEffect(() => {
        getKeywords();
    }, [])

    const getKeywords = () => {
        let items = [];
        if (entity.type === 'person') {
            if (entity.professions?.length > 0) {
                entity.professions.forEach(item => {
                    if (!items.includes(item?.profession_name)) {
                        items.push(item?.profession_name)
                    }
                })
            }
            if (entity.designations?.length > 0) {
                entity.designations.forEach(item => {
                    if (!items.includes(item?.designation_name)) {
                        items.push(item?.designation_name)
                    }
                })
            }
        }
        else if (entity.type === 'organization') {
            if (entity.organization_types?.length > 0) {
                entity.organization_types.forEach(item => {
                    if (!items.includes(item?.organization_type_name)) {
                        items.push(item?.organization_type_name)
                    }
                })
            }
        }
        else if (entity.type === 'publication') {
            if (entity.publication_types?.length > 0) {
                entity.publication_types.forEach(item => {
                    if (!items.includes(item?.publication_type_name)) {
                        items.push(item?.publication_type_name)
                    }
                })
            }
        }
        if (entity.tags)
            entity.tags.forEach(item => {
                if (!items.includes(item?.tag_name))
                    items.push(item.tag_name)
            })

        if (entity?.themes)
            entity.themes.forEach(item => {
                if (!items.includes(item.theme_name))
                    items.push(item?.theme_name)
            })

        if (entity.categories?.length > 0)
            entity.categories.forEach(item => {
                if (item.category_name)
                    if (!items.includes(item.category_name))
                        items.push(item.category_name);

                if (item.subcategory_name)
                    if (!items.includes(item.subcategory_name))
                        items.push(item.subcategory_name);

                if (item.program_name)
                    if (!items.includes(item.program_name))
                        items.push(item.program_name);
            })

        if (!entity.meta.keyWordsUpdated) {
            entity.meta.keywords = items?.join(', ');
            dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
        }
        return items?.join(', ')

    }

    const handleInputChange = async ({ target: { name, value } }) => {
        entity.meta.keyWordsUpdated = true;
        entity.meta[name] = value;
        dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });

    }
    return (
        <>
            {
                <>
                    <div className='row mt-2'>
                        <div className='col'>
                            <label className='cnow-form-title'>
                                Meta Keywords
                            </label>
                        </div>
                        <div className='col-auto'>
                            <label className='cnow-form-word-count'>
                                {entity?.meta?.keywords ? `${entity?.meta?.keywords.split(',')?.length} of 160 words` : `Limit 160 words`}
                            </label>
                        </div>
                    </div>
                    <textarea type='textarea' rows='2' className='form-control' name='keywords' value={entity?.meta?.keywords} onChange={handleInputChange} />
                </>
            }

        </>
    )
}

export default MetaKeywordsEditor;