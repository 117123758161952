/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Icon from '../../components/viewers/icon';
import Button from '../../components/buttons/default';

const FeaturedMenu = (props) => {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    let featured = useSelector((state) => state.featured);
    const [menu] = useState(require('../../data/menu_featured.json'))
    const menuSize = useSelector((state) => state.menu);

    const toggleMenuSize = () => dispatch({ type: 'menu', payload: menuSize === 'sm' ? 'lg' : 'sm' })

    useEffect(() => { setInitialDate() }, [])
    const hasData = (path) => {
        let hasData = false;
        // if (path === 'name') hasData = person?.name?.length || person?.first_name?.length > 0 || person?.last_name?.length > 0;
        return hasData;
    }

    const setInitialDate = () => {
        if (!featured) featured = {}
        if (featured.start_date && featured.end_date) {
            setStartDate(new Date(JSON.parse(featured.start_date)))
            setEndDate(new Date(JSON.parse(featured.end_date)))
        }
        else {
            featured.start_date = JSON.stringify(startDate)
            featured.end_date = JSON.stringify(endDate)
            dispatch({ type: 'featured', payload: { ...featured } })
        }

    }

    const dateOnChange = (dates) => {
        if (!props.display) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            featured.start_date = JSON.stringify(start)
            featured.end_date = JSON.stringify(end)
            featured.source = ''
            dispatch({ type: 'featured', payload: { ...featured, } })
        }
    };

    const checkEndDate = (item) => {
        if (featured?.end_date === 'null') {
            featured.end_date = featured.start_date
            setEndDate(startDate)
            dispatch({ type: 'featured', payload: { ...featured, } })
            toast.success('End Date Saved')
        }
        props.onClick(item.path);
        props.onSave(item.path)
    }

    const returnMarkerColor = (data) => {
        return data?.[0]?.data?.added_from_backend ? 'grey' : 'black'
    }

    return (
        <>
            {menuSize === 'sm' && <>
                <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                <div className='btn-list mt-4 text-center'>
                    {menu.map((menuItem, index) => {
                        return (<div key={index}>
                            {menuItem.data?.map((item, idx) => {
                                let icon = item.icon;
                                if (hasData(item.path)) icon = `${item.icon}-done`;
                                if (props.section === item.path) icon = `${item.icon}-active`
                                return (
                                    <div key={idx} className='mt-2'>
                                        <OverlayTrigger placement='right' overlay={<Tooltip>{item.name}</Tooltip>}>
                                            <button className='btn' onClick={() => { if (!props.display) checkEndDate(item) }} data-bs-toggle='tooltip' data-bs-placement='right' title='Tooltip on right'>
                                                <Icon name={icon} size={24} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })}
                        </div>)
                    })}
                </div>
            </>
            }
            {
                menuSize === 'lg' && <>
                    <div className='d-flex align-items-end text-start'>
                        <div className='me-2'>
                            <label className='cnow-form-label fw-bold '>Date</label>
                            <input value={startDate ? moment(startDate).format("MM/DD/YYYY") : ''} className="cnow-featured-date-input" readOnly />
                        </div>
                        <div className='me-1'>
                            <label className='cnow-form-label fw-bold'>Date</label>
                            <input value={endDate ? moment(endDate).format("MM/DD/YYYY") : ''} className="cnow-featured-date-input" readOnly />
                        </div>
                        <div>
                            <Button size='sm' color='light' icon='' className={''} label={`Set`} onClick={() => { }} />
                        </div>
                        <div className='ms-2'>
                            <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                        </div>
                    </div>
                    <div className='cnow-datepicker'>
                        <DatePicker
                            selected={startDate}
                            onChange={(dates) => { if (props.section && featured?.end_date !== 'null') { props.onClick(); toast.success('Data Saved'); } dateOnChange(dates) }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            className="cnow-datepicker-input"
                            calendarClassName='cnow-datepicker-calendar'
                            popperClassName='cnow-datepicker-popper'
                            monthClassName='cnow-datepicker-month'
                        />
                    </div>
                    <div className='featured-menu-section mt-3'>
                        {menu.map((menuItem, idx) => (
                            <div key={menuItem.title + idx} className=''>
                                <div className='text-start'>
                                    <h6 className='text-uppercase text-nowrap fw-bold'>{menuItem.title}</h6>
                                </div>
                                <ul className='list-group'>
                                    {
                                        menuItem.data?.map((item, index) => {
                                            let itemClass = 'list-group-item cnow-list-group-item d-flex align-items-start p-0 pb-1';
                                            if (props.section === item.path) itemClass = `${itemClass} active`;
                                            if (!item.enabled) itemClass = `${itemClass} disabled`
                                            return (
                                                <li key={index} className={itemClass}>
                                                    <div className='row g-1'>
                                                        <div className='col-auto pt-1'>
                                                            {featured[item.path]?.length > 0 ? <i className={`fe fe-check fw-bold mt-1`} style={{ color: returnMarkerColor(featured[item.path]) }} /> : <i className={`fe fe-check cnow-text-light`} />}
                                                        </div>
                                                        <div className='col'>
                                                            <button className={itemClass} onClick={() => { checkEndDate(item) }}>
                                                                <div className='ms-1 text-start cursor-pointer'>
                                                                    <span className='fz-12'>{item.name}</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        ))}
                    </div>
                </>}
        </>)
}

export default FeaturedMenu;