/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import ImageViewer from "../viewers/image";
import TextEditor from './textEditor';
import SelectEvent from '../selects/event';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId } from '../../helpers';
const _api = require('../../api')

const RelatedEventEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-related-events'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.related_events, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, related_events: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addRelatedEvent = () => {
        if (!entity.related_events) entity.related_events = [];
        if (props.type === 'event') {
            entity.related_events.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,
                [`${props.type}_id`]: props?.id,
                'related_event_id': null,
                'related_event_name': "",
                'sort': entity.related_events ? entity.related_events.length + 1 : 1,
            });
        } else {
            entity.related_events.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,
                [`${props.type}_id`]: props?.id,
                'event_id': null,
                'event_name': "",
                'sort': entity.related_events ? entity.related_events.length + 1 : 1,
            });
        }
        entity.related_events.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, related_events: entity.related_events } });
    }

    const removeRelatedEvent = async (index) => {
        if (entity.related_events[index]) {
            if (!entity.related_events[index].id.startsWith('temp.')) {
                await _api[props.type].deleteEvent(entity.related_events[index].id)
            }
            entity.related_events.splice(index, 1)
            entity.related_events.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, related_events: entity.related_events } });
        }
    }

    const handleEditorChange = (index, data) => {
        entity.related_events[index].description = data;
        dispatch({ type: 'entity', payload: { ...entity, related_events: entity.related_events } });
    }

    const handleEventChange = (index, value) => {
        if (props.type === 'event') {
            entity.related_events[index].related_event_id = value.id
            entity.related_events[index].related_event_name = value.name
        }
        else {
            entity.related_events[index].event_name = value.name;
            entity.related_events[index].event_id = value.id;
        }

        dispatch({ type: 'entity', payload: { ...entity, related_events: entity.related_events } });
    }

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Related Events</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add an Event' onClick={() => addRelatedEvent()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.related_events?.map((item, index) => {
                        return (
                            <div key={index} id={item?.id} className='mb-3 dragula-item'>
                                <div className='row g-1'>
                                    <div className={entity?.related_events?.length > 1 ? 'col-auto' : 'd-none'}>
                                        <label className='cnow-form-label fw-bold'>Order</label>
                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <ImageViewer key={item?.id} entityType='event' entityId={item?.related_event_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <div className='row g-1'>
                                            <div className='col-auto d-flex flex-grow-1'>
                                                <div className='w-100'>
                                                    <label className='cnow-form-label fw-bold'>Name*</label>
                                                    {!item?.id.startsWith('temp.') ?
                                                        <input value={props.type === 'event' ? item?.related_event_name : item?.event_name} disabled className='w-100 cnow-input-disabled-height' />
                                                        :
                                                        <SelectEvent key={item.id}
                                                            value={{
                                                                id: props.type === 'event' ? item?.related_event_id : item?.event_id,
                                                                name: props.type === 'event' ? item?.related_event_name : item?.event_name,
                                                            }}
                                                            className="prime1" onChange={(value) => handleEventChange(index, value)} />
                                                    }
                                                </div>
                                                {/*  {props.type === "playlist" &&
                                                    <div className='w-100 ms-2'>
                                                        <label className='cnow-form-label fw-bold'>Role *</label>
                                                        <input value={getRole(item)} disabled className='w-100 cnow-input-disabled-height' />
                                                        <SelectGlobalList key={item.id} type={`${props.type}_people_role`} className="prime2" value={{ id: item.role_id, name: item.role_name }}
															onChange={(value) => handleRoleChange(index, value)} /> 
                                                    </div>}*/}
                                            </div>
                                            <div className='col-auto'>
                                                <label className='cnow-form-label'></label>
                                                <div className='btn-list'>
                                                    <PreviewButton link={`/event/${props.type === 'event' ? item?.related_event_id : item?.event_id}`} />
                                                    <LinkButton icon='edit' to={`/event/${props.type === 'event' ? item?.related_event_id : item?.event_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeRelatedEvent(index)} />
                                                </div>
                                            </div>
                                        </div>

                                        {props?.remarksEditor && <TextEditor key={item?.id} data={item?.description} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks - Relation to project </label>
                                        </TextEditor>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RelatedEventEditor;