/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import TableHeader from '../../../components/tableHeader';
import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
import ImageViewer from '../../../components/viewers/image';
import PreviewButton from '../../../components/buttons/preview';
import swal from 'sweetalert';
import RelatedPeopleList from '../../../components/table/relatedPeopleList';
import RelatedSiteList from '../../../components/table/relatedSiteList';
import LocationList from '../../../components/table/locationList';
import EventType from '../../../components/table/eventType';
import EventPeopleList from '../../../components/table/eventPeopleList';
import { TABLE_ROWS, changeUTC, newTempId, checkUserTypeAndReturnIds } from '../../../helpers';
// 
import EventTicketPrice from '../../../components/table/eventTicketPrice';
import EventReservation from '../../../components/table/eventReservation';
import EventEducation from '../../../components/table/eventEducation';
import TagList from '../../../components/table/tagList';
import LongTextView from '../../../components/viewers/longText';
import PodcastList from '../../../components/table/podcastList';
import PlayList from '../../../components/table/playlist';
import RelatedEventSeriesList from '../../../components/table/relatedEventSeries';
import RelatedRecipeList from '../../../components/table/relatedRecipeList';
import RelatedEventList from '../../../components/table/relatedEvent';
import RelatedTourList from '../../../components/table/relatedTourList';
import RelatedMapList from '../../../components/table/relatedMapList';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { groupBy, uniqBy, intersectionBy, differenceBy } from 'lodash';
import TableCollapsable from '../../../components/featured/onThisDay/TableCollapsable';
import OnThisDayCollapsableSection from '../../../components/featured/onThisDay/TableRowCollapsable';
import { toast } from 'react-toastify';
const _api = require('../../../api');
const columns = require('../../../data/columns_event.json');

const EventExhibitionList = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const exhibitionEvent = useSelector(state => state['exhibition'])
    const exhibition = exhibitionEvent?.event || []
    const [searchResult, setSearchResult] = useState({
        dates: [],
        results: {},
        selected_results: {}
    });
    const [pagination, setPagination] = useState(null)
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);
    const [label, setLabel] = useState([])
    const [resultFilters, setResultFilters] = useState({
        past_event: false,
        future_event: true,
    })
    const [spinner, showSpinner] = useState(false);


    useEffect(() => {
        search()
    }, [page, keyword, user.id]);

    const search = async () => {
        showSpinner(true)
        setSearchResult({
            dates: [],
            results: {},
            selected_results: {}
        })
        // const params = {
        //     keyword: keyword,
        //     hitsPerPage: take,
        //     page: page - 1,
        //     //need to apply filter here
        //     facetFilters: [
        //         "deleted:false",
        //         "types.type_name:Exhibition",
        //         "is_parent:true",
        //         "live:true",
        //         ["hosts.person_name:cultureNOW",
        //             "participants.person_name:cultureNOW",
        //             "related_people.person_name:cultureNOW"],
        //             ...checkUserTypeAndReturnIds(user)
        //     ]
        // }
        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    {
                        "match": {
                            "deleted": "false"
                        }
                    },
                    {
                        "match": {
                            "is_parent": "true"
                        }
                    },
                    {
                        "match": {
                            "created_via": "ADMIN"
                        }
                    },
                    {
                        "match": {
                            "types.type_name": "Exhibition"
                        }
                    },
                    {
                        "bool": {
                            "should": [
                                { "match": { "hosts.person_name": "cultureNOW" } },
                                { "match": { "participants.person_name": "cultureNOW" } },
                                { "match": { "related_people.person_name": "cultureNOW" } },

                            ]
                        }
                    }
                ]
            }
        };
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^5",
                        "participants.person_name",
                        "alias",
                        "participants.person_first_name",
                        "participants.person_last_name",
                        "hosts.person_name",
                        "locations.name",
                        "locations.city",
                        "locations.state",
                        "locations.country",
                        "categories.category_name",
                        "categories.subcategory_name",
                        "types.type_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_people.related_person_name",
                        "podcasts.podcast_name",
                        "participants.role_name",
                        "people.profession.profession_name",
                        "related_people.org_type.organization_name",
                        "locations.address"
                    ],
                    "fuzziness": "AUTO",
                    "prefix_length": 2
                }
            });
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }
        handleSetKeywords(keyword)

        if (resultFilters?.future_event || resultFilters?.past_event) {
            if (resultFilters?.future_event) {
                params.bool.must.push({
                    "range": {
                        "occurring_dates.occurrence": {
                            "gte": moment().unix()
                        }
                    }
                })
                await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {

                    const mappedHits = results?.hits?.hits.map(hit => hit._source);

                    mappedHits.forEach((elem) => { elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null; elem.event_id = elem.id })
                    const updatedExhibition = intersectionBy(mappedHits, exhibition, 'event_id');
                    updatedExhibition.forEach((elem) => elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                    const filteredResult = differenceBy(mappedHits, updatedExhibition, 'event_id')
                    setPagination(results?.hits?.total?.value)
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            dates: uniqBy(mappedHits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`),
                            results: groupBy(filteredResult, obj => `${obj.start_date}`),
                            selected_results: groupBy(updatedExhibition, obj => `${obj.start_date}`)
                        };
                    });
                    showSpinner(false)
                })
            }
            if (resultFilters?.past_event) {


                params.bool.must.push({
                    "range": {
                        "occurring_dates.occurrence": {
                            "lte": moment().unix()
                        }
                    }
                })
                await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                    setPagination(results.nbHits)
                    const mappedHits = results?.hits?.hits.map(hit => hit._source);
                    mappedHits.forEach((elem) => { elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null; elem.event_id = elem.id })
                    const updatedExhibition = intersectionBy(mappedHits, exhibition, 'event_id');
                    updatedExhibition.forEach((elem) => elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                    const filteredResult = differenceBy(mappedHits, updatedExhibition, 'event_id')
                    setSearchResult((prev) => {
                        return {
                            ...prev,
                            dates: uniqBy(mappedHits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`),
                            results: groupBy(filteredResult, obj => `${obj.start_date}`),
                            selected_results: groupBy(updatedExhibition, obj => `${obj.start_date}`)
                        };
                    });

                    showSpinner(false)
                })
            }

        }
        else {
            await _api.event.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
                const mappedHits = results?.hits?.hits.map(hit => hit._source);
                mappedHits.forEach((elem) => { elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null; elem.event_id = elem.id })
                const updatedExhibition = intersectionBy(mappedHits, exhibition, 'event_id');
                updatedExhibition.forEach((elem) => elem.start_date = elem?.dates?.length > 0 ? moment(elem?.dates?.[0]?.start_date * 1000).format("MMMM D, YYYY") : null)
                const filteredResult = differenceBy(mappedHits, updatedExhibition, 'event_id')
                setPagination(results?.hits?.total?.value)
                setSearchResult((prev) => {
                    return {
                        ...prev,
                        dates: uniqBy(mappedHits, obj => `${obj.start_date}`).filter((elem) => elem.start_date !== null).map(obj => `${obj.start_date}`),
                        results: groupBy(filteredResult, obj => `${obj.start_date}`),
                        selected_results: groupBy(updatedExhibition, obj => `${obj.start_date}`)
                    };
                });

                showSpinner(false)
            })
        }
    }

    const handleSetKeywords = (keyword) => {
        let data = [`Live-Event`, `Event Type=Exhibition`, `people=cultureNow`];
        if (resultFilters?.past_event) data.push(`Past - Events`)
        if (resultFilters?.future_event) data.push(`Future - Events`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Past - Events')) {
            resultFilters.past_event = false
        }
        if (data.includes('Future - Events')) {
            resultFilters.future_event = false
        }
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const handleSortInformation = (column) => {
        if (column?.name === 'Future Events') {
            resultFilters.future_event = true
            resultFilters.past_event = false
        }
        if (column?.name === 'Past Events') {
            resultFilters.past_event = true
            resultFilters.future_event = false
        }
        setResultFilters(resultFilters)
        search()
    }

    const handleDelete = (event) => {
        swal({
            title: `Delete Event`,
            text: `Are you sure that you want to delete ${event?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.event.deleteById(event.id).then(() => { search() })
        });
    }
    const updateLive = (id, live) => {
        showSpinner(true)
        let selectedSearchResult = searchResult.results.filter((item) => item.id === id)
        let selectedResult = selectedSearchResult[0]
        if (live === true) {
            _api.event.updateLive(id, live).then((_) => search())
            swal({
                title: 'Event is Live!',
                icon: 'success',
                text: `${selectedResult?.name} is now Live!`,
                buttons: 'Ok',
            })
        } else {
            _api.event.updateLive(id, live).then((_) => search())
            swal({
                title: 'Not Live!',
                icon: 'info',
                text: `${selectedResult?.name} is Not Live.`,
                buttons: 'Ok',
            })
        }
        showSpinner(false)
    }

    function compareDates(a, b) {
        if (resultFilters.future_event) {
            const dateA = new Date(a);
            const dateB = new Date(b);

            if (dateA.getMonth() !== dateB.getMonth()) {
                return dateA.getMonth() - dateB.getMonth();
            }

            return dateA.getDate() - dateB.getDate();
        }
        if (resultFilters.past_event) {
            const dateA = new Date(a);
            const dateB = new Date(b);

            if (dateA.getFullYear() !== dateB.getFullYear()) {
                return dateB.getFullYear() - dateA.getFullYear(); // Reverse order for years
            }

            if (dateA.getMonth() !== dateB.getMonth()) {
                return dateB.getMonth() - dateA.getMonth(); // Reverse order for months
            }

            return dateB.getDate() - dateA.getDate(); // Reverse order for days
        }
    }

    const handleSelectedEvent = (event_id, date_month) => {
        exhibitionEvent.event.push({
            id: `${newTempId()}`,
            event_id: event_id,
            sort: 0
        })
        exhibitionEvent.event.forEach((item, index) => item.sort = index + 1)
        const dispatchData = {
            id: 'exhibition',
            event: uniqBy(exhibitionEvent.event, 'event_id')
        }
        dispatch({ type: 'entity', payload: dispatchData });
        const selectedFindIndex = searchResult.results[date_month].findIndex((elem) => elem.event_id === event_id)
        const removedEvents = searchResult.results[date_month].splice(selectedFindIndex, 1)
        if (!searchResult.selected_results[date_month]) searchResult.selected_results[date_month] = []
        searchResult.selected_results[date_month].push(removedEvents[0])
        setSearchResult((prev) => {
            return {
                ...prev,
                results: { ...searchResult.results, [date_month]: searchResult.results[date_month] },
                selected_results: { ...searchResult.selected_results, [date_month]: searchResult.selected_results[date_month] }
            };
        });
    }

    const removeSelectedEvent = async (event_id, date_month) => {
        const indexInfo = exhibitionEvent.event.findIndex((elem) => elem.event_id === event_id)
        if (!exhibitionEvent.event[indexInfo].id.startsWith('temp.')) {
            await _api.event.deleteExhibition(exhibitionEvent.event[indexInfo].id).then(() => {
                exhibitionEvent.event.splice(indexInfo, 1)
                exhibitionEvent.event.forEach((item, index) => item.sort = index + 1)
                const dispatchData = {
                    id: 'exhibition',
                    event: exhibitionEvent.event
                }
                dispatch({ type: 'entity', payload: dispatchData });
                const selectedFindIndex = searchResult.selected_results[date_month].findIndex((elem) => elem.event_id === event_id)
                const removedEvents = searchResult.selected_results[date_month].splice(selectedFindIndex, 1)
                if (!searchResult.results[date_month]) searchResult.results[date_month] = []
                searchResult.results[date_month].push(removedEvents[0])
                setSearchResult((prev) => {
                    return {
                        ...prev,
                        results: { ...searchResult.results, [date_month]: searchResult.results[date_month] },
                        selected_results: { ...searchResult.selected_results, [date_month]: searchResult.selected_results[date_month] }
                    };
                });
            })
        }
        else {
            const selectedFindIndex = searchResult.selected_results[date_month].findIndex((elem) => elem.event_id === event_id)
            const removedEvents = searchResult.selected_results[date_month].splice(selectedFindIndex, 1)
            if (!searchResult.results[date_month]) searchResult.results[date_month] = []
            searchResult.results[date_month].push(removedEvents[0])
            setSearchResult((prev) => {
                return {
                    ...prev,
                    results: { ...searchResult.results, [date_month]: searchResult.results[date_month] },
                    selected_results: { ...searchResult.selected_results, [date_month]: searchResult.selected_results[date_month] }
                };
            });
            exhibitionEvent.event.splice(indexInfo, 1)
            exhibitionEvent.event.forEach((item, index) => item.sort = index + 1)
            const dispatchData = {
                id: 'exhibition',
                event: exhibitionEvent.event
            }
            dispatch({ type: 'entity', payload: dispatchData });
        }
    }

    const handleSaveExhibition = async () => {
        showSpinner(true)
        await _api.event.updateExhibition(exhibitionEvent.event).then(() => {
            showSpinner(false)
            toast.success('Data Saved')
            history.push(`/event/exhibition`)
        })
    }


    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add an Event' color='danger' icon='plus-white' onClick={() => history.push('/event/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer' onClick={() => { handleSaveExhibition() }}>ExhibitionList</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={pagination} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search' key={`icon-${index}`}>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Pagination page={page} take={take} count={pagination} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => { setSort(column); handleSortInformation(column) }}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>

                                {
                                    searchResult && searchResult.dates.length > 0 && searchResult.dates.sort(compareDates).map((date_month, index) => {
                                        return (
                                            <TableCollapsable bgColor={resultFilters.future_event === true ? `cnow-bg-red` : 'bg-dark'} collapsable count={'2 Items'} title={date_month} key={date_month} showTitle>
                                                <OnThisDayCollapsableSection collapsable title={`Events Selected`} bgColor={resultFilters.future_event === true ? 'bg-dark' : 'cnow-light-gray'} key={`${date_month}-top-section`} count={searchResult.selected_results[date_month]?.length > 1 ? `${searchResult.selected_results[date_month]?.length} Items` : `${searchResult.selected_results[date_month]?.length || 0} Item`}>
                                                    {searchResult.selected_results && searchResult.selected_results[date_month]?.length > 0 && searchResult.selected_results[date_month].map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='row g-1'>
                                                                            <div className='col-auto'>
                                                                                <div className='form-check mt-1'>
                                                                                    <input className='form-check-input align-middle cursor-pointer' type='checkbox' name='select_event' value={item.id} defaultChecked onClick={() => { removeSelectedEvent(item.event_id, date_month) }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-auto'>
                                                                                <div className='btn-list'>
                                                                                    <LinkButton size='sm' icon='edit' to={`/event/${item.id}/editor`} />
                                                                                    <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                                    <PreviewButton size='sm' link={`event/${item?.vanity_url || item?.id}`} />
                                                                                    <Button size='live'
                                                                                        icon={item.live === true ? 'live' : 'not-live'}
                                                                                        color={item.live === true ? '' : 'danger'}
                                                                                        onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ImageViewer entityId={item?.event_id} entityType={'event'} type='thumb' cssClass='thumb' />
                                                                    </td>
                                                                    <td className='fw-bold'>{item?.name}</td>
                                                                    <td>{changeUTC(item?.dates?.[0]?.start_date)}</td>
                                                                    <EventType data={item?.types} />
                                                                    <EventPeopleList host={item?.hosts} sponsor={item?.sponsors} other={item?.participants} />
                                                                    <LocationList data={item?.locations} />
                                                                    <td></td>
                                                                    <EventTicketPrice data={item?.tickets} />
                                                                    <EventReservation data={item?.reservations} />
                                                                    <td></td>
                                                                    <EventEducation data={item?.continuing_education} />
                                                                    <td></td>
                                                                    <td>{item?.id}</td>
                                                                    <TagList data={item.tags} />
                                                                    <td>
                                                                        <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                                    </td>
                                                                    <PodcastList data={item?.podcasts} />
                                                                    <PlayList data={item?.related_playlists} />
                                                                    <RelatedEventSeriesList data={item?.related_event_series} />
                                                                    <RelatedRecipeList data={item?.recipes} />
                                                                    <RelatedSiteList data={item?.related_sites} />
                                                                    <RelatedPeopleList data={item?.related_people} />
                                                                    <RelatedEventList data={item?.related_events} />
                                                                    <RelatedTourList data={item?.related_tours} />
                                                                    <RelatedMapList data={item?.related_maps} />
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </OnThisDayCollapsableSection>
                                                <OnThisDayCollapsableSection bgColor={resultFilters.future_event === true ? 'bg-dark' : 'cnow-light-gray'} collapsable title={`Events Available`} key={`${date_month}-bottom-section`} count={searchResult.results[date_month]?.length > 1 ? `${searchResult.results[date_month]?.length} Items` : `${searchResult.results[date_month]?.length || 0} Item`}>
                                                    {searchResult.results && searchResult.results[date_month]?.length > 0 && searchResult.results[date_month].map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className='row g-1'>
                                                                            <div className='col-auto'>
                                                                                <div className='form-check mt-1'>
                                                                                    <input className='form-check-input align-middle cursor-pointer' type='checkbox' name='select_event' value={item.id} onClick={() => { handleSelectedEvent(item.event_id, date_month) }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-auto'>
                                                                                <div className='btn-list'>
                                                                                    <LinkButton size='sm' icon='edit' to={`/event/${item.id}/editor`} />
                                                                                    <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                                    <PreviewButton size='sm' link={`event/${item?.vanity_url || item?.id}`} />
                                                                                    <Button size='live'
                                                                                        icon={item.live === true ? 'live' : 'not-live'}
                                                                                        color={item.live === true ? '' : 'danger'}
                                                                                        onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <ImageViewer entityId={item?.event_id} entityType={'event'} type='thumb' cssClass='thumb' />
                                                                    </td>
                                                                    <td className='fw-bold'>{item?.name}</td>
                                                                    <td>{changeUTC(item?.dates?.[0]?.start_date)}</td>
                                                                    <EventType data={item?.types} />
                                                                    <EventPeopleList host={item?.hosts} sponsor={item?.sponsors} other={item?.participants} />
                                                                    <LocationList data={item?.locations} />
                                                                    <td></td>
                                                                    <EventTicketPrice data={item?.tickets} />
                                                                    <EventReservation data={item?.reservations} />
                                                                    <td></td>
                                                                    <EventEducation data={item?.continuing_education} />
                                                                    <td></td>
                                                                    <td>{item?.id}</td>
                                                                    <TagList data={item.tags} />
                                                                    <td>
                                                                        <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                                    </td>
                                                                    <PodcastList data={item?.podcasts} />
                                                                    <PlayList data={item?.related_playlists} />
                                                                    <RelatedEventSeriesList data={item?.related_event_series} />
                                                                    <RelatedRecipeList data={item?.recipes} />
                                                                    <RelatedSiteList data={item?.related_sites} />
                                                                    <RelatedPeopleList data={item?.related_people} />
                                                                    <RelatedEventList data={item?.related_events} />
                                                                    <RelatedTourList data={item?.related_tours} />
                                                                    <RelatedMapList data={item?.related_maps} />
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </OnThisDayCollapsableSection>
                                            </TableCollapsable>
                                        )
                                    })
                                }

                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={pagination} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default EventExhibitionList;