import React from 'react';
import { TABLE_ROWS } from '../../helpers';

const CategoryList = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    let list = []
                    if (item.category_name) list.push(item.category_name)
                    if (item.subcategory_name) list.push(item.subcategory_name)
                    if (item.program_name) list.push(item.program_name)
                    return <div key={index} className='text-truncate'>{list.join(' - ')}</div>
                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default CategoryList;