import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import LocationEditor from '../../components/editors/locationEditor';

const OverlayLocation = (props) => {
  const overlay = useSelector(state => state[props.id]);

  return (
    <>
      <PageTitle title={overlay.name} />
      <LocationEditor id={overlay.id} type='map_overlay' locationId={props.locationId} onUpdate={(path) => props.onUpdate(path)} showLocationType={false} disableCheckList={true}/>
    </>
  );
};

export default OverlayLocation