import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/pageTitle'
import FormLayout from './formLayout'
import LabelInput from './labelInput'
import { useSelector, useDispatch } from 'react-redux';
const _api = require('../../../api');
const UserMembership = (props) => {
  const id = props.id

  const user = useSelector(state => state.user) || {}
  const [userData, setuserData] = useState()
  const dispatch = useDispatch();
  const handleOnClick = (url) => {
    // if (url) router.push(url)
  }
  useEffect(() => {
    if (user) handleUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUserInfo = async () => {
    const userInfo = await _api.user.getUserById(props?.id)
    setuserData(userInfo)
    // dispatch({ type: 'user', payload: userInfo })
  }
  return (
    <>
      <PageTitle title={`User Membership`} />
      <hr className="cnow-hr" />
      <FormLayout heading={'Membership'}>
        {/* IF HANDLE CLICK NOT WORKING WE HAVE TO ENCLOSE IT IN DIV .Input field has to be removed and replaced with div and onClick function*/}
        <LabelInput label={'MEMBERSHIP LEVEL'} type='text' name={''} handleChange={() => { }} value={`${userData?.type} - ${userData?.subtype} `} readonlY className='cursor-pointer px-2 text-transform fw-bold' handleOnClick={() => { handleOnClick(`/join-us/explore-membership?type=${userData?.type.toLowerCase()}`) }} />
        <LabelInput label={'MEMBERSHIP ID'} type='text' name={''} handleChange={() => { }} value={`${userData?.id}`} readonlY className='cursor-pointer px-2' />
        {userData?.account_limit !== 0 && <LabelInput label={'LINKED ACCOUNTS'} type='text' name={''} handleChange={() => { }} value={`${userData?.linked_accounts?.length} out of ${userData?.account_limit} Connected( Send More Request )`} readonly className='cursor-pointer px-2' handleOnClick={() => { handleOnClick('/account-settings?page=members') }} />}
        <LabelInput label={'DELETE ACCOUNT'} type='text' name={''} handleOnClick={() => { }} value={'Deleting your account is a permanent option, and can not be undone.'} className='cursor-pointer px-2 fw-bold' readonlY>
          <div className="fw-bold small" style={{ fontSize: '10px', lineHeight: '1.14' }}>Deleting your account does not delete the content added to the platform by you. Please double check that you really want to delete this account</div>
        </LabelInput>
        <LabelInput label={'SUBSCRIPTION'} type='text' name={''} handleChange={() => { }} value={'Mailing List Preferences'} readonly className='cursor-pointer px-2' handleOnClick={() => { handleOnClick(`/auth/mail_preference`) }} />
      </FormLayout>
    </>
  )
}

export default UserMembership
