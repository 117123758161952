import React from 'react';
import { personName } from '../../helpers';

const TourPeopleList = ({ data, title }) => {
    return (
        <>
            {
                data?.slice(0, 1).map((item, index) => {
                    return (
                        <div key={index} >
                            <i>{title}: </i> {personName(item)}
                        </div>
                    )
                })
            }
        </>
    )
}

export default TourPeopleList;