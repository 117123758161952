import React from 'react';
import ImageViewer from './viewers/image';

const ImageCard = (props) => {
    const image = props.image;
    const type = props.type || 'thumb'

    const bgColor = props.bgColor || 'bg-light'
    const textColor = props.bgColor || 'text-red'
    const padding = props.padding || 'p-2'

    return (
        <>
            {
                <div className='card border-0 h-100'>
                    <ImageViewer url={image} type={type} cssClass={'img-fluid'} />
                    <div className={`card-body ${bgColor} ${padding} px-2`} style={{ minHeight: '45px' }}>
                        <div className='container px-0 small'>
                            <div className={`${textColor} fw-bold text-capitalize`}>{props.typeInfo}</div>
                            <div className={`${textColor} fw-bold text-capitalize`}>{props.title}</div>
                            <div className='small fw-bold'>{props.caption}</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ImageCard;