import React from "react";
import { useSelector } from 'react-redux';
import InThisPhoto from "../../components/inThisPhoto";
import OnThisDay from "../../components/onThisDay";
import PageTitle from "../../components/pageTitle";

const EventOnThisDayInThisPhoto = (props) => {

    const event = useSelector(state => state[props.id]);

    return (
        <>
            <PageTitle title={event.name} />
            <OnThisDay id={event.id} type={'event'} onUpdate={(path) => props.onUpdate(path)} />
            <InThisPhoto id={event.id} type={'event'} onUpdate={(path) => props.onUpdate(path)} />
        </>
    )
}
export default EventOnThisDayInThisPhoto