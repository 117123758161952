import React, { useState, useEffect } from 'react';
import { relatedPersonName } from '../../helpers';
const MIN_LENGTH = 3
const AffiliationList = ({ data }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    let items = [];
    useEffect(() => {
        for (let i in data) {
            const item = data[i];
            let position = item.former ? 'Former ' + item?.person_position : item?.person_position;
            let index = items.findIndex(x => x.position === position);
            if (index >= 0) {
                items[index].people.push({ name: relatedPersonName(item), id: item.related_person_id, designations_name: item.designations_name, is_participant: item.is_participant })
            } else {
                items.push({
                    'position': position,
                    'people': [{ name: relatedPersonName(item), id: item.related_person_id, designations_name: item.designations_name, is_participant: item.is_participant }]
                })
            }
        }
        let count = 0
        // eslint-disable-next-line
        items = items.map((element) => {
            return ({
                role: element.position, people: element?.people?.map((people) => {
                    count = ++count
                    if (count <= length) return people
                    else return null
                }).filter(item => item)
            })
        }).filter(item => item.people.length)

        setPeopleList(items)
    }, [length, data])

    return (
        <>
            {
                peopleList.length > 0 && <>
                    <hr />
                    <div>
                        <div>
                            {
                                peopleList.slice(0, length).map((item, index) => {
                                    return (
                                        <div key={index} className='mb-1'>
                                            <div className="row d-flex">
                                                <div className={`fw-bold fst-italic ${data?.length > MIN_LENGTH ? 'col-6' : 'col'}`}>{item?.role}</div>
                                                {
                                                    data?.length > MIN_LENGTH && index === 0 &&
                                                    <div className='d-flex justify-content-end col-6'>
                                                        {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                                        {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                                                    </div>
                                                }
                                            </div>
                                            {item.people.map((person, index) => <div key={index}><a href={`${process.env.REACT_APP_WEB_URL}${!person.is_participant ? 'people' : 'participant'}/${person.id}`} target='_blank' rel='noopener noreferrer' className='text-decoration-none text-dark'>{person.name}{person.designations_name && <span className='text-muted fs-small'><small> {person.designations_name}</small></span>} </a></div>)}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default AffiliationList;