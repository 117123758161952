
import React from 'react'
import ImageViewer from '../viewers/image'

const EventPerson = (data) => {
  return (
    <div>
        {
            data?.data?.map((item,index)=>{
                return(
                    <>
                     <ImageViewer key={index} entityType={"person"} entityId={item?.person_id}  type='thumb' cssClass='thumb p-1' />
                    </>
                )
            })
        }
    </div>
  )
}

export default EventPerson