/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import Icon from '../../components/viewers/icon';
import TableHeader from '../../components/tableHeader';
import LinkButton from '../../components/buttons/link';
import Button from '../../components/buttons/default';
// import CategoryList from '../../components/table/categoryList';
// import ThemeList from '../../components/table/themeList';
import TagList from '../../components/table/tagList';
import ImageViewer from '../../components/viewers/image';
import LongTextView from '../../components/viewers/longText';
import PreviewButton from '../../components/buttons/preview';
import { TABLE_ROWS, getCategoryName, locationName } from '../../helpers';
import swal from 'sweetalert';
import TourPeopleList from '../../components/table/tourPeopleList';
import PodcastList from '../../components/table/podcastList';
import RelatedTourList from '../../components/table/relatedTourList';
import RelatedEventList from '../../components/table/relatedEvent';
import RelatedMapList from '../../components/table/relatedMapList';


import Playlist from '../../components/table/playlist';
import RelatedPeopleList from '../../components/table/relatedPeopleList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import { useSelector } from 'react-redux';

const _api = require('../../api');
const columns = require('../../data/columns_tour.json');

const Tours = (props) => {
    const history = useHistory();
    const user = useSelector(state => state.user)

    const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        search()
    }, [page, sort, keyword, user.id]);

    const search = async () => {
        showSpinner(false)

        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                    { "match": { "created_via": "ADMIN" } },
                ]
            }
        };
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^6",
                        "tour_leader.person_name^2",
                        "created_by.person_name^2",
                        "people.person_name^2",
                        "partner_org.person_name^2",
                        "alias",
                        "type",
                        "stops.location.name",
                        "stops.location.city",
                        "stops.location.state",
                        "stops.location.country",
                        "categories.category_name",
                        "categories.subcategory_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_sites.site_name",
                        "related_people.person_name",
                        "podcasts.podcast_name",
                        "stops.location.address",
                        "theme.theme_name"
                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }

        showSpinner(true)
        _api.tour.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
            setSearchResult(results?.hits)
            showSpinner(false)
        })
    }

    const handleDelete = (tour) => {
        swal({
            title: `Delete Tour`,
            text: `Are you sure that you want to delete ${tour?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => { _api.tour.deleteById(tour.id).then(() => { search() }) });
    }

    const updateLive = (tour, live) => {
        showSpinner(true)
        // let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
        // let selectedResult = selectedSearchResult[0]
        let dateDescription = tour.dates?.filter((e) => e.description !== '')
        let category = getCategoryName(tour?.categories)
        if (live === true) {
            if (
                tour?.name &&
                category &&
                tour?.stops && tour?.stops?.[0]?.location &&
                (tour?.description || dateDescription?.length > 0) &&
                tour?.image_id

            ) {
                _api.tour.updateLive(tour?.id, live).then(async (_) => {
                    await search()
                    swal({
                        title: 'Tour is Live!',
                        icon: 'success',
                        text: `${tour?.name} is now Live!`,
                        buttons: 'Ok',
                    })
                })
            } else {
                swal({
                    title: `${tour.name} cannot go Live!`,
                    icon: 'error',
                    text: `Please fill the mandatory fields:
              ${tour?.name ? '' : 'Title is missing.'}
              ${category && category?.length ? '' : 'Category is missing.'}
              ${tour?.stops && tour?.stops?.[0]?.location ? '' : 'Tour stops is missing.'} 
              ${(tour?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
              ${tour?.image_id ? '' : 'Image is missing.'} `, buttons: 'Go back', dangerMode: true,
                })
            }
        } else {
            _api.tour.updateLive(tour?.id, live).then(async (_) => {
                await search()
                swal({
                    title: 'Not Live!',
                    icon: 'info',
                    text: `${tour?.name} is Not Live.`,
                    buttons: 'Ok',
                })
            })
        }
        showSpinner(false)
    }


    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add a Tour' color='danger' icon='plus-white' onClick={() => history.push('/tour/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0'>Tours</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                                    {
                                        keyword && <span className='badge badge-search'>
                                            {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                                        </span>
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchResult?.hits?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_tour' value={item?._source.id} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/tour/${item?._source.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item?._source) }} />
                                                                <PreviewButton size='sm' link={`tour/${item?._source?.vanity_url || item?._source?.id}`} />
                                                                <Button size='live'
                                                                    icon={item?._source.live === true ? 'live' : 'not-live'}
                                                                    color={item?._source.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item?._source, item?._source.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item?._source.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item?._source.name}</td>

                                                <td>{locationName(item?._source?.stops?.[0]?.location)}</td>
                                                <td> <TourPeopleList data={item?._source.created_by} title="created by" />

                                                </td>
                                                {/* <LocationList data={item?._source.locations} /> */}
                                                {/* <PeopleList data={item?._source.people} /> */}
                                                <td>{item?._source?.stops?.length}</td>

                                                {/* <CategoryList data={item?._source?.categories} /> */}
                                                {/* <ThemeList data={item?._source?.themes} /> */}
                                                <td>{item?._source?.duration}</td>
                                                {/* <DateList data={item?._source.dates} />
                                                <MissingRequiredInfoList data={item?._source} /> */}
                                                <td>{item?._source?.type}</td>
                                                <td>{item?._source?.id}</td>
                                                <TagList data={item?._source?.tags} />
                                                <td>{item?._source?.stops &&
                                                    <>
                                                        <div className='text-muted'>{item?._source.stops?.length} Stops</div>
                                                        {item?._source.stops?.slice(0, 3).map((items, index) => {
                                                            return <div key={index}>{items?.stop_name}</div>
                                                        })}
                                                    </>}</td>
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item?._source?.description}</LongTextView>
                                                </td>
                                                <PodcastList data={item?._source?.podcasts} />



                                                <Playlist data={item?._source?.related_playlists} />
                                                <td></td>
                                                <td></td>
                                                <RelatedSiteList data={item?._source?.related_sites} />
                                                <RelatedPeopleList data={item?._source?.related_people} />
                                                <RelatedEventList data={item?._source?.events} />
                                                <RelatedTourList data={item?._source?.related_tours} />
                                                <RelatedMapList data={item?._source?.related_maps} />

                                                <td></td>

                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default Tours;