import React from 'react';
import ImageViewer from '../viewers/image';

const PlaylistInlineImageList = (props) => {
    return (
        props.items?.length > 0 && <div className='d-flex mb-4'>
            {
                props.items?.length > 0 && props?.items?.map(item => {
                    const selected = props.selected?.findIndex(x => x.id === item.id);
                    return (
                        <div key={item.id} onClick={() => props.onSelect(item)} className="position-relative">
                            {item.podcastOrder ? <div className='position-absolute playlist-image-order-container'>
                                <input type="text" className="form-control playlist-image-order" disabled 
                                name="sort" autoComplete="off" 
                                placeholder={item.podcastOrder} />
                            </div> : null}
                            <ImageViewer cssClass={`thumb thumb-auto mx-2 ${selected >= 0 ? 'highlight-box' : ''}`} type='thumb' url={item.url} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PlaylistInlineImageList