import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import DateEditor from '../../components/editors/dateEditor';

const SiteDate = (props) => {
  const site = useSelector(state => state[props.id]);

  return (
    <>
      <PageTitle title={site.name} />
      <DateEditor id={props.id} type='site' onUpdate={props.onUpdate}/>
    </>
  );
};

export default SiteDate;