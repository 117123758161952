import React from 'react'
import PageTitle from '../../../components/pageTitle'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const _api = require('../../../api');


const SavedContent = () => {

  const { id } = useParams();

  const [getSavedContent, setGetSavedContent] = useState([])

  const getSavedContentData = async () => {
    try {
      const response = await _api?.user?.savedContent(id);
      setGetSavedContent(response)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSavedContentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* <PageTitle title={``} /> */}
      <hr className="cnow-hr" />
      <div className='cnow-form-title text-uppercase'>Saved Content</div>

      <div className="mt-3">
        {getSavedContent.length === 0 ? (
          <div>No saved sites found for this ID</div>
        ) : (
          getSavedContent?.saved_content?.map((item, index) => (
            <div key={index}>
              <div className="d-flex">
                <p className='saved-content-quantity'>{item?.count} &times;</p>
                <p className='text-uppercase border border-dark ms-2 ps-3 saved-content-label'><b>{item?.entity}</b></p>
              </div>
            </div>
          ))
        )}
      </div>

    </>
  )
}

export default SavedContent