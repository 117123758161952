import React from 'react'
import { cleanHtml } from '../../helpers';

const TourHighlights = ({ data, highlightDesc }) => {
    const stops = data;
    const highlights = highlightDesc
    return (
        <>
            {stops && stops.length > 0 && <><hr />

                <div className='fw-bold fst-italic'>Highlights</div>
                <div><span dangerouslySetInnerHTML={{ __html: cleanHtml(highlights) }} /></div>
                {
                    stops?.length > 0 && stops?.map(stop => {
                        return (
                            stop.sites?.length > 0 && stop.sites.filter(s => s.highlight).map(site => {
                                return (
                                    <>
                                        <div className='row g-2 p-2 d-flex align-items-center'>
                                            <div className='col-auto'>
                                                <div className='tour-stop-site-number-red d-flex align-items-center'>{`${stop?.sort}.${site.sort}`}</div>
                                            </div>
                                            <div className='col'>
                                                {site.site_name}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        )
                    })
                }
            </>} </>
    )
}

export default TourHighlights