import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';
import Button from '../../components/buttons/default'
import { toast } from 'react-toastify';

const ReplaceHighlight = ({ show, tourId, selectedSiteStop, selectedSite, highlightedSites, onHide }) => {

    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);
    const [siteToReplace, setSiteToRelace] = useState(null);
    const [stopId, setStopId] = useState({});

    const handleChange = (stop_id, site_id) => {
        setSiteToRelace(site_id);
        setStopId(stop_id);
    }

    const replace = () => {
        if (siteToReplace) {
            updateHighlight(stopId, siteToReplace, false)
            updateHighlight(selectedSiteStop.id, selectedSite.site_id, true)
            onHide()
        } else {
            toast.error('Please Select any site to replace')
        }
    }

    const updateHighlight = (stop_id, site_id, value) => {
        const stopIndex = tour.stops.findIndex(item => item.id === stop_id)
        const siteIndex = tour.stops[stopIndex].sites.findIndex(item => item.site_id === site_id)
        tour.stops[stopIndex].sites[siteIndex].highlight = value
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
    }

    return (
        <div>
            <Modal show={show} onHide={() => onHide()} size='xl' centered>
                <Modal.Body>
                    <div>
                        <div className='row'>
                            <div className='col'>
                                <div className='cnow-form-title'>You can have a maximum of 3 Highlights </div>
                            </div>
                            <div className='col-auto btn-list'>
                                <Button size='sm' icon='close' onClick={() => onHide()} />
                            </div>
                        </div>
                        <div className='row'>
                            <hr className='cnow-hr' />
                            <div>The following sites have already been selected as Tour Highlights</div>
                            <div className='d-flex'>You need to remove and replace one of them before adding <div className='row mx-2'>
                                <div className='tour-stop-highlight-number d-flex col-auto'>{`${selectedSiteStop.sort}.${selectedSite.sort}`}</div>
                                <div className='cnow-form-title col'>{selectedSite.site_name}</div>
                            </div> as a tour highlight</div>
                            <div>Select and replace one of the following or go back to keep already selected highlights</div>
                        </div>
                        <div className='row p-2'>
                            {
                                tour.stops.map(stop => {
                                    return (
                                        stop.sites?.length > 0 && stop.sites.map(site => {
                                            if (highlightedSites.includes(site))
                                                return (
                                                    <>
                                                        <div className='row p-3 ms-2'>
                                                            <div className='col-auto'>
                                                                <input type='radio' value={site.site_id} name='type' className='form-check-input' onChange={() => handleChange(stop.id, site.site_id)} />
                                                            </div>
                                                            <div className='tour-stop-highlight-number d-flex col-auto'>{`${stop?.sort}.${site?.sort}`}</div>
                                                            <div className='cnow-form-title col'>{site.site_name}</div>
                                                        </div>
                                                    </>
                                                )
                                            else return null
                                        })
                                    )
                                })
                            }
                        </div>
                        <div className='row ps-3 ms-1'>
                            <div className='col-auto btn-list'>
                                <Button size='md' label='Go Back' onClick={() => onHide()} />
                                <Button className="ms-4" size='md' label='Replace Selected' onClick={() => replace()} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>)
}
export default ReplaceHighlight
