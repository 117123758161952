/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Button from '../../components/buttons/default'
import { newTempId } from '../../helpers'
const _api = require('../../api')


const PdfUploader = (props) => {
    const uploadRef = useRef(null)
    const pdfType = 'document'
    const operation = 'upload'
    const [uploadErrors, setUploadErrors] = useState([])
    const [pdf, setPdf] = useState([])
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'application/pdf': [] },
        onDrop: async (acceptedFiles) => uploadPdf(acceptedFiles)
    })

    const fileErrors = (file) => {
        const error = { 'filename': file.name, 'error': '' }
        const allowedExt = ['pdf']
        const ext = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
        if (!allowedExt.includes(ext)) error.error = 'has incorrect format / extension'
        return error.error ? error : null
    }

    const uploadPdf = async (items) => {
        let accepted = []
        let rejected = []
        items && items.length > 0 && items.forEach(item => {
            item.id = newTempId()
            if (fileErrors(item)) rejected.push(fileErrors(item))
            else accepted.push({ 'file': item, uploading: true })
        })

        let allPdf = [].concat(pdf).concat(accepted)
        setPdf(allPdf)
        setUploadErrors(rejected)

        accepted = await accepted.reduce(async (previousPromise, file) => {
            let temp = await previousPromise;
            temp.push(file)
            return temp
        }, Promise.resolve([]))

        setUploadErrors(rejected)
        allPdf = [].concat(pdf).concat(accepted)
        setPdf(allPdf)

        accepted.forEach(async (file) => {
            let pdfData;
            try {
                // 1. create pdfData object
                pdfData = {
                    type: 'document',
                    id: file.file.name.split('.')[0]
                }

                // 2. get file upload url
                const ext = file.file.name.split('.')[file.file.name.split('.').length - 1]?.toLowerCase();
                const uploadUrl = await _api.file.getUploadUrl({
                    name: `img/${pdfData.id}/original.${ext}`,
                    contentType: file.file.type
                });

                // 3. upload file to storage
                await _api.file.upload(uploadUrl.url, file.file, { headers: { 'Content-Type': file.file.type } });
                pdfData.url = uploadUrl.url.split('?')[0];


                const index = allPdf.findIndex(x => x.file.id === file.file.id);
                if (index >= 0) {
                    allPdf[index] = pdfData;
                    allPdf[index].uploading = false;
                    allPdf[index].file = file.file;
                }

                setPdf([].concat(allPdf));
            } catch (error) {
                // Handle the error
                setUploadErrors("Error Uploading the file")
                console.log('Error:', error);
            }
        });
    }

    const handleSave = async () => {
        if (props.onSave) {
            props.onSave(pdf)
        }
        props.onHide(false);
    }


    return (
        <Modal className='modal-main' show={props.show} size='xl' onHide={() => { props.onHide(false) }}>
            <Modal.Body>
                {
                    pdfType && <>
                        <div className='d-flex justify-content-end gap-1 py-2'>
                            {pdf.length > 0 && <Button label={'Save'} target='_self' onClick={() => handleSave()} />} 
                            <Button icon='close' target='_self' onClick={() => {props.onCancel()}} />
                        </div>
                        {
                            operation === 'upload' &&
                            <>
                                {
                                    <div {...getRootProps({ className: 'dropzone' })} ref={uploadRef}>
                                        <input {...getInputProps()} />
                                        <div className='cnow-form-title'>Upload Signed Documents</div>
                                        <p>Drop your file(s) anywhere in this window</p>
                                        {/* change pdf to documents */}
                                        {
                                            pdf.length > 0 && pdf.filter(x => x.uploading === true).length > 0 && <>
                                                <div className='progress w-25'>
                                                    <div className='progress-bar progress-bar-striped progress-bar-animated bg-danger' role='progressbar' style={{ width: `${parseInt(((pdf.filter(x => x.uploading === false).length || 0) * 100) / pdf.length)}%` }}></div>
                                                </div>
                                                <p>Uploading {pdf.filter(x => x.uploading === true).length || 1} out of {pdf.length}</p>
                                            </>
                                        }
                                    </div>
                                }

                                {
                                    uploadErrors.length > 0 && <div className='dropzone-error small'>
                                        <div className='cnow-form-title'>Unable to upload {uploadErrors.length} Documents.</div>
                                        {
                                            uploadErrors.map((error, index) => {
                                                return (
                                                    <div key={index}>
                                                        <b>{error.filename}: </b> {error.error}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </>
                        }

                    </>
                }

                {
                    pdf.length > 0 && <>
                        {
                            pdf.length > 0 && pdf.filter(x => x.uploading === false).length > 0 &&
                            <div className='small fw-bold mt-2'>You’ve successfully uploaded {pdf.filter(x => x.uploading === false).length || 0} out of {pdf.length} file(s)</div>
                        }
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default PdfUploader