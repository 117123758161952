import axios from "axios";

const axiosMethods = { get: axios.get, post: axios.post, put: axios.put, delete: axios.delete };

function AuthApiCall(method, url, params) {
	if (!axiosMethods[method]) Promise.reject(new Error(`Invalid HTTP method: ${method}`));
	return axiosMethods[method](url, params)
		.then((response) => {
			if (response.status >= 200 && response.status < 300) return response;
			else throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
		})
		.catch((error) => {
			throw error;
		});
}

export const login = (params) => AuthApiCall('post', `/auth/login`, params);