import React , {useState , useRef , useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import PageTitle from '../pageTitle';
import ImageUploader from '../../pages/image/upload';
import { handleReorder } from '../../helpers';

const PlaylistImageMenu = (props) => {
    const dispatch = useDispatch()
    const [uploader, showUploader] = useState(false)
    const playlist = useSelector(state => state[props.id])

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-playlist-images'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(playlist.selected, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...playlist, selected: items } })
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End

    const addImageToPlaylist = (items) => {
        if (items?.length > 0) {
            const images = items.map(item => ({
                id: item.id,
                name: item.caption,
                type: 'image',
                url: item.url,
                isNew: true
            }))

            playlist.images = [...playlist.images, ...items]
            playlist.images = uniqBy(playlist.images, 'id')
            playlist.allImages = [...playlist.allImages, ...images]

            dispatch({ type: 'entity', payload: { ...playlist, images: playlist.images, allImages: playlist.allImages } })
        }
    }

    return (
        <div className=''>
            <div className='py-2'> <PageTitle title={playlist.name} /></div>
            <hr className='cnow-hr' />
            <div className='mb-5'  id={dragulaContainer} key={dragula}>
                <div className='cnow-form-title'>IMAGE SELECTED</div>
                {
                    props.items?.map(item => {
                        return (
                            <div className='d-flex pb-2 fw-bold align-items-center dragula-item' id={item.id} key={item.id}>
                                <input type="text" className="form-control form-control-image-sort" disabled="" name="sort" autoComplete="off" placeholder={item.podcastOrder || ''} />
                                <div className='ms-2 fz-12'>{item?.name}</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className='d-flex fw-bold align-items-center'>
                <input type='radio' name='image' id='tours_image' className='form-check-input ' onChange={() => showUploader(true)} checked={false} />
                <div className='ms-2 fz-12'>+ Upload New Images</div>
            </div>
            {
                uploader && <ImageUploader
                    globalSearch={true}
                    browse={false}
                    show={uploader}
                    onHide={() => showUploader(false)}
                    onSave={(items) => addImageToPlaylist(items)}
                    imageType={'image'}
                />
            }
        </div>
    )
}

export default PlaylistImageMenu