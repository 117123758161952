/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { customSelectStyle, newTempId } from '../../helpers';
import Button from '../../components/buttons/default';
const currency = require('../../data/event_currency.json')

const MapCuratorTicket = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    const removeTickets = () => {
        entity.curator.is_purchasable = false;
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, is_purchasable: entity.curator.is_purchasable } } })
    }

    const handleInputChange = (data) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        entity.curator.map_name = data
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, map_name: entity.curator.map_name } } })
    }


    const handleChange = (e) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        if (!entity?.curator?.currency) entity.curator.currency = ''
        entity.curator.currency = e.value
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, currency: entity.curator.currency } } })
    }

    const handleThemeChange = () => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        if (!entity.curator?.is_purchasable) entity.curator.is_purchasable = false
        if (!entity.curator?.map_name) entity.curator.map_name = ''
        entity.curator.is_purchasable = !entity.curator.is_purchasable
        entity.curator.map_name = entity.name
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, is_purchasable: entity.curator.is_purchasable, map_name: entity.curator.map_name } } })
    }

    const handlePriceChange = (data) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator.id) entity.curator.id = newTempId()
        if (!entity.curator.price) entity.curator.price = 0
        entity.curator.price = data
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, price: entity.curator.price } } })
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{'BUY A MAP'}</label>
                </div>
                <div className='col-auto'>
                    <input className='form-check-input' type='checkbox' name='theme' key={entity?.id} checked={entity?.curator?.is_purchasable} onChange={() => handleThemeChange()} />
                </div>
            </div>
            {entity?.curator?.is_purchasable && <div>
                <div className='row g-1 mt-1 dragula-item' >
                    <div className='col'>
                        <label className='fw-bold'><small>{'Name of Map'}</small></label>
                        <input name='category' type='text' className='form-control' placeholder='default-name - map Title' value={entity?.curator?.map_name} onChange={({ target: { value } }) => handleInputChange(value)} autoComplete='off' />
                    </div>
                    <div className='col-auto'>
                        <label className='fw-bold'><small>{'Currency'}</small></label>
                        <Select cacheOptions defaultOptions
                            styles={customSelectStyle}
                            options={currency}
                            value={{
                                value: entity?.curator?.currency,
                                label: entity?.curator?.currency
                            }}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className='col-auto'>
                        <label className='fw-bold'><small>{'Price'}</small></label>
                        <input name='price' type='number' className='form-control' placeholder='xxx' value={entity?.curator?.price || ''} onChange={({ target: { value } }) => handlePriceChange(value)} autoComplete='off' />
                    </div>
                    <div className='col-auto'>
                        <label className='cnow-form-label'></label>
                        <div className='btn-list'>
                            <Button icon='delete' onClick={() => removeTickets()} />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default MapCuratorTicket;