import React, { useState, useEffect, useRef } from 'react'
import FeaturedTitle from './title';
import Select from 'react-select'
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CollectionImageCard from '../../components/featured/collectionsImageCard';
import StaticImageCard from '../../components/featured/staticImagesCard';
import StaticContentCard from '../../components/featured/staticContentCard';
import { locationName, customSelectStyle, handleDragDropReorder } from '../../helpers';
import FeaturedCard from '../../components/featured/featuredCard';
import ImagePreview from '../../components/featured/editInfo/imagePreview';
import { uniqBy } from 'lodash';
import EntityTimeLine from '../../components/featured/entityTimeLine';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import ImageViewer from '../../components/viewers/image';
import SelectMapOverlay from '../../components/selects/map_overlay';
const collectionSite = require('../../data/culturenow_collection_site.json')
const CulturenowCollectionSite = ({ section, page = '', onUpdate }) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [siteData, setSiteData] = useState([])
    const [display, setDisplay] = useState(false)
    const [isContentEdit, setIsContentEdit] = useState(false);
    const [latLng, setLatLng] = useState({})
    const [count, setCount] = useState(0)
    const searchSite = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const collection_site = featured[section] || []
    const siteLabel = collectionSite.filter((elem) => elem.section === section)[0]
    let filterData = {
        title: siteLabel?.searchName,
        filterCategory: siteLabel?.searchFilters?.category,
        filterLive: siteLabel?.searchFilters?.live,
        selectedLimit: siteLabel?.limit,
        nearByLocation: siteLabel?.searchFilters?.site_basedon_location,
        lat: latLng?.lat || 40.723,
        lng: latLng?.lng || -74.0006,
        timeLine: siteLabel?.searchFilters?.site_with_timeline
    }
    let selectedDate = siteData[editedIndex]?.dates?.map((elem) => { return { value: elem.year, label: elem.year } })
    selectedDate = uniqBy(selectedDate, 'label')

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (page !== 'home') ? 'dragula-container-what-is-around-me' : ''
    useEffect(() => {
        if (section === 'what_is_around') handleDragDrop(collection_site?.[0]?.data[section] || [])
        // eslint-disable-next-line
    }, [dragula])

    const handleDragDrop = (siteOfDay) => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            setEditedIndex(-1)
            const items = handleDragDropReorder(siteOfDay, dragulaContainer)
            collection_site[0].edited = true
            collection_site[0].data[section] = items
            dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
            setDragula(uniqid())
        })

    }
    // Dragula End

    useEffect(() => {
        formatSite();
        if (section === 'what_is_around') { setLatLng({ lat: 40.723, lng: -74.0006 }) }
        if (!collection_site[0]?.data?.description && !collection_site[0]?.data?.title && section !== 'places_over_time' && section !== 'what_is_around') { setTitleDescription() }
        // eslint-disable-next-line
    }, [collection_site?.length, dragula])

    const formatSite = () => {
        let data = []
        if (collection_site)
            for (let i in collection_site) {
                if (collection_site[i]?.data[section]) {
                    for (let j in collection_site[i].data[section]) {
                        collection_site[i].data[section][j].section_index = i
                        collection_site[i].data[section][j].entity_index = j
                        if (!collection_site[i].data[section][j].description) addDateDescription(i, j)
                        data.push(collection_site[i].data[section][j])
                    }
                }
            }
        setSiteData(data)
    }

    const addDateDescription = (i, j) => {
        collection_site[i].data[section][j].description = collection_site[i].data[section][j]?.dates?.filter((e) => e.description !== '')[0]?.description
        dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
    }

    const setTitleDescription = () => {
        if (collection_site[0]?.data[section]?.length > 0) {
            collection_site[0].data.title = siteLabel.title
            collection_site[0].data.description = siteLabel.description
            dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
        }
    }

    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchSite, [section]: siteData, selectedResult: [], ...filterData } })
        history.push(`/featured/searchList/${section}/site`)
    }

    const handleInputChange = (value, type, id) => {
        if (type === 'name') {
            collection_site[0].data.title = value
        }
        if (type === 'description' && value) {
            collection_site[0].data.description = value
        }
        if (type === 'map_overlay') {
            collection_site[0].data.map_overlay = value
        }
        collection_site[0].edited = true
        dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
    }


    const handleInputChangeSite = (value, type, id) => {
        let id_index = siteData[editedIndex].section_index
        let id_site_index = siteData[editedIndex].entity_index
        collection_site[id_index].edited = true
        if (type === 'site_name') {
            collection_site[id_index].data[section][id_site_index].name = value
        }
        if (type === 'date') {
            let dates = collection_site[id_index].data[section][id_site_index].dates
            let index = dates.findIndex((elem) => elem.year === value.value)
            let selectedDate = dates.splice(index, 1)
            dates.unshift(selectedDate[0])
            collection_site[id_index].data[section][id_site_index].dates = dates
        }
        if (type === 'description' && value) {
            if (!collection_site[id_index].data[section][id_site_index].description_original) {
                collection_site[id_index].data[section][id_site_index].description_original = collection_site[id_index].data[section][id_site_index].description
            }
            collection_site[id_index].data[section][id_site_index].description = value
        }
        dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
    }

    const handleDeleteSite = () => {
        setDisplay(true)
        let id_index = siteData[editedIndex].section_index
        let id_site_index = siteData[editedIndex].entity_index
        collection_site[id_index].edited = true
        collection_site[id_index].data[section].splice(id_site_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
        setDisplay(false)
        //to refresh Call function Again
        formatSite()
        setEditedIndex(editedIndex - 1)
    }

    const handleDescriptionReset = () => {
        let id_index = siteData[editedIndex].section_index
        let id_site_index = siteData[editedIndex].entity_index
        if (collection_site[id_index].data[section][id_site_index].description_original) {
            collection_site[id_index].edited = true
            collection_site[id_index].data[section][id_site_index].description = collection_site[id_index].data[section][id_site_index].description_original
            dispatch({ type: 'featured', payload: { ...featured, [section]: collection_site } })
        }
    }

    return (
        <>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={page} />
            <hr className={`cnow-hr ${page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" count='1 item'
                    disable={page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title={siteLabel.name}
                        count={siteData.length > 1 ? `${siteData?.length} items` : `${siteData?.length} item`}
                        button={{
                            // set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            // setIsEdited(true);
                            edit: { show: true, onHandle: () => { handleClick(0) } },
                            // add: { show: true, onHandle: () => { } },
                        }}
                        page={page}
                    >
                        <Spinner display={display}>
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {(section !== 'what_is_around' && section !== 'places_over_time') ?
                                    siteData.map((item, index) => (
                                        <div className={`mt-2 col-auto`} key={`${index}-${section}`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (page !== 'home') handleClick(index) }}>
                                            <StaticImageCard data={item} />
                                        </div>))

                                    :
                                    siteData.map((item, index) => (
                                        <div id={item.id} key={`${index}-${section}`} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (page !== 'home') handleClick(index) }}>
                                            <FeaturedCard data={item} image="true" title="true" description="true" />
                                        </div>))

                                }
                            </div>
                        </Spinner>
                        {(section !== 'what_is_around' && section !== 'places_over_time') && collection_site[0]?.data && <div className='mt-2' onClick={(e) => { if (page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                            <StaticContentCard data={collection_site[0]?.data} />
                        </div>
                        }

                        {(section === 'what_is_around') && collection_site[0]?.data &&
                            <CollapsableSection textColor="text-dark" bgColor="bg-light" collapsable={true} title="Map Overlays">
                                <div className='row g-1 my-2'>
                                    <div className='col-auto'>
                                        <ImageViewer key={collection_site[0]?.data?.map_overlay?.id} entityType='image' entityId={collection_site[0]?.data?.map_overlay?.image || ''} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <div className='col'>
                                            <SelectMapOverlay value={{ id: collection_site[0]?.data?.map_overlay?.id || '', name: collection_site[0]?.data?.map_overlay?.title || '' }} onChange={(data) => { if (page !== 'home') handleInputChange(data, 'map_overlay') }} />
                                        </div>
                                    </div>
                                </div>
                            </CollapsableSection>
                        }
                    </CollapsableSection>

                    {(section !== 'what_is_around' && section !== 'places_over_time') && (editedIndex !== -1) && siteData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/site/${siteData[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); onUpdate() } },
                            delete: { show: (true), onHandle: () => { handleDeleteSite(); onUpdate() } }
                        }}>

                        <CollectionImageCard key={`image-${editedIndex}`} data={siteData[editedIndex]} name={siteData[editedIndex].name} location={locationName(siteData[editedIndex]?.locations[0])} />

                    </CollapsableSection>}

                    {(section !== 'what_is_around' && section !== 'places_over_time') && isContentEdit && collection_site[0]?.data.description?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: (siteData.length > 0), onHandle: () => { setIsContentEdit(false); onUpdate() } },
                        }} >
                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={collection_site[0]?.data.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={collection_site[0]?.data.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"
                                    key={`TextEditor${editedIndex}`}>
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>
                    }

                    {section === 'what_is_around' && (editedIndex !== -1) && siteData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/site/${siteData[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); onUpdate() } },
                            delete: { show: (siteData.length > 1), onHandle: () => { handleDeleteSite(); onUpdate() } }
                        }}>
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='cnow-form-label fw-bold'>Date</label>
                                    <Select key={`Select-${editedIndex}`} options={selectedDate} styles={customSelectStyle} placeholder={"Select"} onChange={(e) => { handleInputChangeSite(e, 'date') }} defaultValue={selectedDate[0]} />
                                </div>
                                <div className='col-10'>
                                    {<> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteData[editedIndex]?.entity_type} Title`}</label>
                                        <input key={`input-what_is_around-${editedIndex}`} name='name' type='text' className='form-control' value={collection_site[siteData[editedIndex].section_index].data[section][siteData[editedIndex].entity_index]?.name} onChange={(e) => handleInputChangeSite(e.target.value, 'site_name')} autoComplete="off" />
                                    </>}
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-${editedIndex}`} data={collection_site[siteData[editedIndex].section_index].data[section][siteData[editedIndex].entity_index]?.description} onChange={(data) => { handleInputChangeSite(data, 'description', siteData[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => { handleDescriptionReset() } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${siteData[editedIndex]?.entity_type} of the Day Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <ImagePreview data={siteData[editedIndex]} key={`ImagePreview-what_is_around-${editedIndex}`} section={'site'} single={true} singleImageTitle={'Website and Phone App Image'} singleImageButton={true} imageEditorTitle={`What is Around - ${siteData[editedIndex].name}`} />
                    </CollapsableSection>
                    }


                    {section === 'places_over_time' && (editedIndex !== -1) && siteData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/site/${siteData[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); onUpdate() } },
                            delete: { show: (siteData.length > 1), onHandle: () => { handleDeleteSite(); onUpdate() } }
                        }} key={`places_over_time-${editedIndex}`}>
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-12'>
                                    {<> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteData[editedIndex]?.entity_type} Title`}</label>
                                        <input key={`input-places_over_time-${editedIndex}`} name='name' type='text' className='form-control' value={collection_site[siteData[editedIndex].section_index].data[section][siteData[editedIndex].entity_index]?.name} onChange={(e) => handleInputChangeSite(e.target.value, 'site_name')} autoComplete="off" />
                                    </>}
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-places_over_time-${editedIndex}`} data={collection_site[siteData[editedIndex].section_index].data[section][siteData[editedIndex].entity_index]?.description} onChange={(data) => { handleInputChangeSite(data, 'description', siteData[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => { handleDescriptionReset() } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${siteData[editedIndex]?.entity_type} of the Day Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <div>
                            <CollapsableSection textColor="text-dark" bgColor="bg-light" collapsable={false} title="Website and Phone App Image"
                                count={(count && count > 1) ? `${count} items` : `${count || 0} item`}
                                button={{
                                    edit: { show: true, onHandle: () => { window.open(`/site/${siteData[editedIndex].id}/editor`) } },
                                }}>
                                <div className='py-2'>
                                    <EntityTimeLine handleCount={(count) => { setCount(count) }} />
                                </div>
                            </CollapsableSection>
                        </div>
                    </CollapsableSection>
                    }

                </CollapsableSection>
            </div>
        </>
    )
}

export default CulturenowCollectionSite