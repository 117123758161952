import React, { useState } from "react";
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import Reservation from "./reservation";
import Tickets from "./eventTickets";
import Education from "./Education";
import { useSelector } from 'react-redux';
const EventTickets = (props) => {
    const [key, setKey] = useState(uniqid())
    const event = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={event?.name} />
            <Reservation id={props.id} key={`event-reservation-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <Tickets id={props.id} key={`event-tickets-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <Education id={props.id} key={`event-education-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
        </>
    )
}

export default EventTickets