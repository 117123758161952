import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import LocationEditor from '../../components/editors/locationEditor';

const SiteLocation = (props) => {
  const site = useSelector(state => state[props.id]);

  return (
    <>
      <PageTitle title={site.name} />
      <LocationEditor id={props.id} type='site' locationId={props.locationId} onUpdate={(path) => props.onUpdate(path)} showLocationType={true} />
    </>
  );
};

export default SiteLocation