import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import StoryEditor from '../../components/editors/storyEditor';
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';
import uniqid from 'uniqid'

const EventSeriesAbout = (props) => {

  const event = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={event?.name} />
      <DescriptionEditor id={props.id} type='event_series' label={'About'} refresh={() => setKey(uniqid())} />
      <TagEditor id={props.id} type='event_series' />
      <StoryEditor key={`story-editor-${key}`} id={props.id} type='event_series' refresh={() => { setKey(uniqid()) }} />
      <RelatedSiteEditor key={`relatedsite-editor-${key}`} id={props.id} type='event_series' refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default EventSeriesAbout;