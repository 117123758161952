import React from "react";
import Nav from "./nav";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector } from "react-redux";

const accountsAndAccess = {
  EXPLORER : ["site", 'event', 'people', 'organization', 'image', 'podcast'] ,
  PUBLISHER : ["site", 'event', 'people', 'organization', 'publication','image', 'podcast', 'tour', 'playlist'],
  CURATOR: ["site", 'event', 'people', 'organization', 'publication','image', 'podcast', 'tour', 'playlist']
}

const AdminLayout = (props) => {
  const user = useSelector(state => state.user)
  document.title = `${process.env.REACT_APP_ENV} CultureNOW - Museum Without Walls`;
  const doUserHavePermission =  accountsAndAccess[user.type]?.some(keyword => props?.children?.props?.location?.pathname?.includes(`/${keyword}`))

  if ((user.type ===  'ADMIN') || doUserHavePermission) {
    return (
      <>
        <Nav />
        <div className="main-content">{props.children}</div>
      </>
    );
  }else{
    return null
  }
};

export default AdminLayout;