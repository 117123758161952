import React from 'react';

import Icon from '../viewers/icon';

const Button = (props) => {
    const icon = props.icon || '';
    const label = props.label || '';
    const color = props.color || 'light';
    const size = props.size || 'md';
    const className = props.className || '';

    return (
        <>
            {
                icon && label && <button className={`btn btn-${size} btn-${color} ${className}`} onClick={props.onClick} disabled={props.disabled}>
                    {icon && <Icon name={icon} size={12} right={4} />}{label}
                </button>
            }

            {
                icon && !label && <button className={`btn btn-${size} btn-${color} ${className}`} onClick={props.onClick} disabled={props.disabled}>
                    {icon && <Icon name={icon} size={14} />}{label}
                </button>
            }

            {
                !icon && label && <button className={`btn btn-${size} btn-${color} ${className}`} onClick={props.onClick} disabled={props.disabled}>
                    {label}
                </button>
            }
        </>

    );
};

export default Button;