import React, { useEffect, useState } from 'react';
import ImageViewer from '../viewers/image';

const PodcastPlaylist = ({ data, type }) => {
	const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * data.podcasts?.length))

	useEffect(() => {
		setRandomIndex(Math.floor(Math.random() * data.podcasts?.length))
	}, [data.podcasts])

	return (
		<>
			<div className='cnow-bg-red text-white mt-2 fs-5'>PREVIEW</div>
			<div className='w-100 preview-playlist-image-container position-relative mb-2'>
				<div className="position-absolute preview-playlist-headshot-box">
					<ImageViewer url={data?.url?.replace('thumb', 'headshot')} type='headshot' cssClass='headshot' />
				</div>
				<div className='h-100 preview-playlist-web-box' key={randomIndex}>
					<ImageViewer url={data.podcasts?.[randomIndex]?.podcast_image_url || null} type='banner' cssClass='preview-playlist-web' />
				</div>
			</div>
			{data?.podcasts?.length > 0 &&
				<>
					<div className='fw-bold fz-16 cnow-bg-grey'>PLAYLIST</div>
					{data?.podcasts?.map((item, index) => {
						return (
							<div className='d-flex mb-2' key={`${item?.id}_${index}`}>
								<div className='me-1'>
									<ImageViewer entityType="podcast" entityId={item.podcast_id} type='thumb' cssClass='thumb' />
								</div>
								<div className='small flex-grow-1'>
									<div className='d-flex'>
										<div className='me-2 fw-bold'>{index + 1}.</div>
										<div>
											<div className='bg-light fw-bold'>{item?.podcast_name}</div>
											<div>{item.podcast_credits ? 'by ' + item.podcast_credits : ''}</div>
										</div>
									</div>
								</div>
							</div>
						)
					})}
				</>
			}
			{type === 'image' && data?.curator_playlist?.length > 0 &&
				<div>
					<div className='fw-bold fz-16 cnow-bg-grey'>MORE PLAYLISTS BY THE CURATOR</div>
					<div className='row mt-1 px-2'>
						{data?.curator_playlist?.map((item, index) => {
							return (
								<div className='col-6 mb-2 px-1' key={index}>
									<div><ImageViewer url={item.url} type='thumb' cssClass='thumb-auto' /></div>
									<div className='fz-12 fw-bold'>{item?.playlist_name}</div>
								</div>
							)
						})}
					</div>
				</div>
			}
			{data?.related_playlist?.length > 0 && <div>
				<div className='fw-bold fz-16 cnow-bg-grey'>RELATED PLAYLISTS</div>
				<div className='row mt-1 px-2'>
					{data?.related_playlist?.map((item, index) => {
						return (
							<div className='col-6 mb-2 px-1' key={index}>
								<div><ImageViewer url={item.image_url} type='thumb' cssClass='thumb-auto' /></div>
								<div className='fz-12 fw-bold'>{item?.related_playlist_name}</div>
							</div>
						)
					})}
				</div>
			</div>}
		</>
	)
}

export default PodcastPlaylist