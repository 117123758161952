import React from 'react';

const Name = ({ data }) => {
    return (
        <>
            {data && <span className='fs-4 fw-bold'>{data}</span>}
        </>
    )
}

export default Name;