/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import LinkButton from '../buttons/link';
import Button from '../buttons/default';
import ImageViewer from "../viewers/image";
import { personName } from '../../helpers';
import { useSelector } from 'react-redux';
import Select from 'react-select';
const _api = require('../../api')

const PodcastsCard = ({ title, data, type, label, offsetIndex }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    for (const item of data) {
      item.podcast = item.podcasts?.length === 1
        ?
        { value: item.podcasts?.[0]?.podcast_id, label: item.podcasts?.[0]?.podcast_name }
        :
        item.podcasts?.length > 1 ? { value: '', label: `${item.podcasts?.length} podcasts` } : null
    }
    setItems(data)
  }, [data])

  const handleSelect = (index, value) => {
    let copy = [...items]
    copy[index].podcast = value
    setItems(copy)
  }
  return (
    <div className='table table-borderless' key={title}>
      <div className='d-flex mt-3'>
        <div className='cnow-form-title w-100 cnow-bg-light'>{title}</div>
      </div>
      <div className='cnow-bg-light pb-2'>
        {items?.map((item, index) => {
          item.options = item.podcasts?.map(d => ({ 'label': d.podcast_name, 'value': d.podcast_id }))
          return (
            <div className='d-flex' key={item.id}>
              <div className='ms-0 ps-0'>
                <label className='cnow-form-label fw-bold '>Order</label>
                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={offsetIndex + index + 1} autoComplete="off" />
              </div>
              <div className='ms-1 ps-0 pe-0'>
                <div className='cnow-form-label fw-bold'>Media</div>
                <ImageViewer entityId={item.id} entityType={type === 'site' ? 'site' : 'person'} type='thumb' cssClass='thumb' />
              </div>

              <div className='flex-grow-1'>
                <div className='row'>
                  <div className='col-8 pe-0'>
                    <span className='cnow-form-label fw-bold'>{label}</span>
                    <div className='w-100 border border-2 border-dark p-1 fw-bold bg-gray'>{item.name}</div>
                  </div>
                  <div className='col-4 px-1'>
                    <span className='cnow-form-label fw-bold'>Podcast Title</span>
                    <Select
                      options={item.options}
                      value={item.podcast}
                      onChange={(value) => handleSelect(index, value)} />
                  </div>
                </div>
                <div className='ms-0'>
                  <div className='cnow-form-label fw-bold'>{item.caption}</div>
                  <div className='small'>{item.image_credits ? `© ${item.image_credits}` : ''}</div>
                </div>
              </div>

              <div className='ms-1 pe-0'>
                <div>&nbsp;</div>
                {item.podcast?.value ? <LinkButton icon='edit' to={`/podcast/${item.podcast?.value}/editor`} /> :
                  <Button size='md' icon='edit' disabled={true} onClick={() => { }} />}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function ImagePodcast(props) {
  const entity = useSelector((state) => state[props.id]);
  const [projectSites, setProjectSites] = useState([])
  const [projectPeople, setProjectPeople] = useState([])
  let peoplePodcastOffsetIndex = projectSites.length > 0 ? projectSites.length + props.offsetIndex : props.offsetIndex
  useEffect(() => {
    getFeaturingProject()
  }, []);

  const getFeaturingProject = async () => {
    let sites = []
    let people = []
    let podcast_related_items = []

    if (entity.credited_podcasts) {
      for (const pod of entity.credited_podcasts) {
        let res = await _api.podcast.getRelatedItem(pod.podcast_id) || []
        podcast_related_items = podcast_related_items.concat(res)
      }
    }

    podcast_related_items = uniqBy(podcast_related_items, 'id')
    const creditedIds = entity.credited_podcasts?.map(x => x.podcast_id)
    for (const item of podcast_related_items) {
      if (item.source.toLowerCase() === 'site' && item.id !== props.id) {
        let pods = await _api.site.getPodcast(item.id) || []
        let filteredPods = pods.filter(d => creditedIds.includes(d.podcast_id))
        sites.push({ id: item.id, name: item.name, podcasts: filteredPods, caption: item.caption, image_credits: item.image_credits })
      }
      if (item.source.toLowerCase() === 'person' && item.id !== props.id) {
        let pods = await _api.person.getPodcast(item.id) || []
        let filteredPods = pods.filter(d => creditedIds.includes(d.podcast_id))
        people.push({ id: item.id, name: personName(item), podcasts: filteredPods, caption: item.caption, image_credits: item.image_credits })
      }
    }

    setProjectSites(sites)
    setProjectPeople(people)
  }

  return (
    <div>
      {(projectSites.length > 0 || projectPeople.length > 0) && <div className='cnow-form-title'>Podcasts  - Featuring Projects, Events, Tours, and People</div>}

      {projectSites.length > 0 &&
        <PodcastsCard title='Sites' data={projectSites} type="site" label='Site Title, Image Caption, Image Credit' offsetIndex={props.offsetIndex} />}

      {projectPeople.length > 0 &&
        <PodcastsCard title='People' data={projectPeople} type="person" label='Person/Organization/Publication Name, Image Caption, Image Credit' offsetIndex={peoplePodcastOffsetIndex} />}

    </div>
  )
}

export default ImagePodcast