import React from 'react';
import { TABLE_ROWS } from '../../helpers';

const EventEducation = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'>{item.person_name}-{item.credit} {item.name}-{item.su_category}</div>
                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default EventEducation;