/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

import Dragula from 'react-dragula';
import uniqid from 'uniqid';
import swal from 'sweetalert';

import Button from '../buttons/default';
import LinkButton from '../buttons/link';
import { customSelectStyle, dateName, handleDragDropReorder } from '../../helpers';

const _api = require('../../api')

const LocationEditor = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const entity = useSelector((state) => state[props.id]);

  const [locationId] = useState(props.locationId);
  const [index, setIndex] = useState();
  const [dates, setDates] = useState([]);
  const [googleLocation, setGoogleLocation] = useState({});

  useEffect(() => {
    if (locationId) {
      let index = entity.locations.findIndex(function (item) {
        return item.id === locationId;
      });
      if (index >= 0) {
        setGoogleLocation(entity.locations[index].location);
        setIndex(index);
      }
    } else {
      // To create default location automatic
      // if (entity.locations?.length === 0) addLocation(); 
    }

    var dates = [];
    for (let i = 0; i < entity?.dates?.length; i++) {
      if (entity?.dates?.length > 0) dates.push({ value: entity?.dates[i].id, label: dateName(entity.dates[i]), date: entity?.dates[i] });
    }
    setDates(dates);
  }, [props?.locationId, entity?.locations && entity?.locations[index]?.location]);

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-playlist'

  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleDragDropReorder(entity.locations, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...entity, locations: items } })
    })
  }, [dragula])
  // Dragula End

  const addLocation = () => {
    if (!entity.locations) entity.locations = [];
    let location_item = {
      id: null,
      name: '',
      description: '',
      address: '',
      city: '',
      state: '',
      country: '',
      postal_code: '',
      google_location: {},
      location: null,
      path: null,
      alias: '',
      previous: false,
      date_id: null,
      type: 'site',
      sort: entity.locations ? entity.locations.length + 1 : 1
    }

    location_item[`${props.type}_id`] = entity.id
    entity.locations.push(location_item);

    _api[props.type].updateLocation(entity.id, entity.locations).then(locations => {
      const locationId = locations[locations.length - 1].id
      history.push(`/${props.type}/${entity.id}/editor/location/${locationId}`)
      dispatch({ type: 'entity', payload: { ...entity, locations: locations } });
    })
  };

  const removeLocation = async (id, index) => {
    swal({
      title: `Delete Location?`,
      text: `Are you sure that you want to delete ${entity?.locations[index]?.name !== '' ? entity?.locations[index]?.name : 'Location' + [index + 1]}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        entity.locations = entity.locations.filter(function (item) {
          return item.id !== id;
        });
        dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
        await _api[props.type].deleteLocation(id)
        history.push(`/${props.type}/${props.id}/editor/location`)
      }
    });

  };

  const handleLocationTypeChange = ({ target: { value } }) => {
    if (entity?.locations[index]) entity.locations[index].type = value;
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const handleInputChange = ({ target: { name, value, checked } }) => {
    entity.locations[index][name] = value;
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const handleLocationChange = ({ target: { name, value } }) => {
    setGoogleLocation({ ...googleLocation, [name]: parseFloat(value) });
  };

  const updateLocation = () => {
    if (entity.locations[index].location) entity.locations[index].location = googleLocation;
    entity.locations[index].google_location.geometry.location_type = "CHANGED MANUAL"
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const updateGoogleMap = () => {
    if (index >= 0 && entity.locations[index].location)
      entity.locations[index].location = {
        lat: entity.locations[index].google_location?.geometry?.location?.lat,
        lng: entity.locations[index].google_location?.geometry?.location?.lng,
      };

    setGoogleLocation(entity.locations[index].location);
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const handleAddressChange = (address) => {
    entity.locations[index].address = address;
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const handleAddressSelect = async (address, placeId, suggestion) => {
    let results = await geocodeByPlaceId(placeId);
    if (results.length > 0) {
      entity.locations[index].google_location = results[0];
      entity.locations[index].address = results[0].formatted_address;
      entity.locations[index].city = results[0].address_components.filter((x) => x.types.includes('locality'))[0]?.long_name;
      entity.locations[index].state = results[0].address_components.filter((x) => x.types.includes('administrative_area_level_1'))[0]?.long_name;
      entity.locations[index].country = results[0].address_components.filter((x) => x.types.includes('country'))[0]?.long_name;
      entity.locations[index].postal_code = results[0].address_components.filter((x) => x.types.includes('postal_code'))[0]?.long_name;
      entity.locations[index].location = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      };
      entity.locations[index].google_location.geometry.location = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      }
      setGoogleLocation(entity.locations[index].location);
      dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
    }
  };

  const handleCustomLocationChange = ({ target: { name, value, checked } }) => {
    if (name === 'custom') {
      entity.locations.forEach(item => item[name] = checked);
      if (checked === false) entity.locations.forEach(item => item.alias = '');
    } else {
      entity.locations.forEach(item => item[name] = value);
    }

    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const removeCustomLocation = () => {
    entity.locations.forEach(item => {
      item.alias = '';
      item.custom = false
    });
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
    handleInputChange({ target: { name: 'alias', checked: '' } });
  };

  const handleDateChange = (index, item) => {
    if (item) entity.locations[index].date_id = item.date.id;
    else entity.locations[index].date_id = null;
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  const handlePreviousChange = (index, checked) => {
    entity.locations[index].previous = checked;
    dispatch({ type: 'entity', payload: { ...entity, locations: entity.locations } });
  };

  return (
    <>
      <div id='type'>
        <hr className='cnow-hr' />
        <div className='row'>
          <div className='col'>
            <div className='form-group mt-2'>
              {
                (locationId && props.showLocationType) && <>
                  <div className='cnow-form-title'>Type of {props.type}</div>
                  <div className='row'>
                    <div className='col-auto'>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='type'
                          value='site'
                          onChange={handleLocationTypeChange}
                          checked={entity.locations ? entity.locations[index]?.type === 'site' : false}
                        />
                        <label className='form-check-label small'>Site</label>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='type'
                          value='path'
                          onChange={handleLocationTypeChange}
                          checked={entity.locations ? entity.locations[index]?.type === 'path' : false}
                        />
                        <label className='form-check-label small'>Path</label>
                      </div>
                    </div>
                    <div className='col-auto'>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          className='form-check-input'
                          name='type'
                          value='area'
                          onChange={handleLocationTypeChange}
                          checked={entity.locations ? entity.locations[index]?.type === 'area' : false}
                        />
                        <label className='form-check-label small'>Area</label>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className='col-auto'>
            <Button size='sm' icon='plus' label='Add an Address' onClick={() => addLocation()} />
          </div>
        </div>
      </div>
      {!locationId && (
        <>
          <hr className='cnow-hr' />
          <table className='table'>
            <thead>
              <tr>
                <td style={{ width: '56px' }}></td>
                <td></td>
                <td className='col-xl-3 text-center'>{entity?.dates?.length > 0 && <div className='small text-nowrap'>Associate by Date</div>}</td>
                <td className='text-center'>
                  {!props.disableCheckList && <><div className='small text-nowrap'>Previous Locations</div>
                    <div className='small text-nowrap'>(Moved, Demolished)</div></>}
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody id={dragulaContainer} key={dragula} className='dragula-container'>
              {entity?.locations?.length > 0 &&
                entity?.locations.map((location, index) => {
                  const selectedDate = location.date_id ? { value: location.date_id, label: dateName(entity.dates.filter(x => x.id === location.date_id)[0]) } : null;
                  return (
                    <tr key={index} className='dragula-item' id={location.id}>
                      <td style={{ width: '55px' }}>
                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete='off' />
                      </td>
                      <td style={{ width: '400px' }}>
                        <label className='cnow-form-title'>{location.name || `Location ${index + 1}`}</label>
                        <div className='small'>{location.address}</div>
                        <div className='small'>{location.description}</div>
                      </td>
                      
                     {props.disabledDateDropdown ? <td></td> : <td style={{ width: '280px' }}>
                        {entity?.dates?.length > 0 && (
                          <Select key={index} options={dates} styles={customSelectStyle} value={selectedDate} isClearable onChange={(item) => handleDateChange(index, item)} />
                        )}
                      </td>} 
                      <td className='text-center' style={{ width: '260px' }}>
                        {!props.disableCheckList && <input
                          key={location.id}
                          className='form-check-input'
                          name='previous'
                          type='checkbox'
                          defaultChecked={location.previous}
                          onChange={(e) => handlePreviousChange(index, e.target.checked)}
                        />}
                      </td>
                      <td style={{ width: 'auto' }}>
                        <div className='text-nowrap btn-list text-end'>
                          <LinkButton size='sm' icon='edit' to={`/${props.type}/${entity.id}/editor/location/${location.id}`} />
                          <Button size='sm' icon='delete' onClick={() => removeLocation(location.id, index)} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <hr className='cnow-hr' />
          <div className='row g-1'>
            <div className='col'>
              <label className='cnow-form-title fw-bold'>
                Address Override <small className='fw-normal'>(Change city state name for the website)</small>
              </label>
            </div>
            <div className='col-auto'>
              <div className='form-check mt-1 mx-2'>
                <input
                  className='form-check-input'
                  name='custom'
                  type='checkbox'
                  checked={(entity?.locations?.length > 0 ? entity.locations[0]?.custom : false) || false}
                  onChange={handleCustomLocationChange}
                />
                <label className='small'>If required</label>
              </div>
            </div>
          </div>

          {entity.locations && entity.locations[0]?.custom && (
            <div className='row g-1'>
              <div className='col'>
                <input name='alias' type='text' className='form-control' value={entity?.locations?.length ? entity.locations[0]?.alias : ''} onChange={handleCustomLocationChange} autoComplete='off' />
              </div>
              <div className='col-auto'>
                <Button icon='delete' onClick={() => removeCustomLocation()} />
              </div>
            </div>
          )}
        </>
      )}

      {locationId && (
        <div>
          <hr className='cnow-hr' />
          {(entity.locations[index]?.type && entity.locations[index]?.type !== 'site') && (
            <Button icon='plus' label='Enter Coordinates*' onClick={() => props.onUpdate(`location/${entity.locations[index]?.id}/${props.type}`)} />
          )}
          <div className='row g-1'>
            <div className='col'>
              <div className='form-group'>
                <label className='cnow-form-label fw-bold'>Location Names</label>
                <input name='name' value={entity?.locations[index]?.name} type='text' className='form-control' onChange={handleInputChange} autoComplete='off' />
              </div>
            </div>
            <div className='col-auto'>
              <label className='cnow-form-label'></label>
              <div className='text-end'>
                <Button icon='delete' onClick={() => removeLocation(entity.locations[index]?.id, index)} />
              </div>
            </div>
          </div>

          <div className='form-group mt-2'>
            <label className='cnow-form-label fw-bold'>Address</label>
            <PlacesAutocomplete value={entity.locations[index]?.address || ''} onChange={handleAddressChange} onSelect={handleAddressSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                  <input {...getInputProps({ className: 'form-control' })} />
                  <div className='autocomplete-container'>
                    {suggestions.map((suggestion, index) => {
                      return (
                        <div className='suggestion-item' key={index} {...getSuggestionItemProps(suggestion)}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <div className='mt-2'>
              <Button label='Update Map' onClick={() => updateGoogleMap()} /> <span className='small'>(This will reset the pin to the address location)</span>
            </div>
          </div>

          <label className='cnow-form-label mt-3 fw-bold'>
            Google Coordinates or Address - <span className='fw-normal'>If different, move the pin on the map, the address will not change</span>
          </label>
          <div className='row g-1'>
            <div className='col'>
              <label className='cnow-form-label fw-bold'>Latitude</label>
              <input name='lat' type='text' className='form-control' value={googleLocation?.lat || ''} onChange={handleLocationChange} autoComplete='off' />
            </div>
            <div className='col'>
              <label className='cnow-form-label fw-bold'>Longitude</label>
              <input name='lng' type='text' className='form-control' value={googleLocation?.lng || ''} onChange={handleLocationChange} autoComplete='off' />
            </div>
            <div className='col-auto'>
              <label className='cnow-form-label'>&nbsp;</label>
              <div>
                <Button label='Update Location' onClick={() => updateLocation()} />
              </div>
            </div>
          </div>
          <div className='form-group mt-2'>
            <label className='cnow-form-label fw-bold'>Notes: If the work is hard to find, put more info here. (ex. “In the lobby of the building.”)</label>
            <input name='description' type='text' className='form-control' value={entity.locations[index]?.description || ''} onChange={handleInputChange} autoComplete='off' />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationEditor;
