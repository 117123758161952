import React from 'react';
import ImageViewer from './viewers/image';

const GridImageList = (props) => {
    const uniqueItems = new Set();
    return (
        <div className='row'>
            {
                props.items?.length > 0 && props?.items?.filter((item) => {
                    if(item?.image_id!=null && !uniqueItems.has(item?.image_id)){
                        uniqueItems.add(item?.image_id);
                        return true;
                    }
                    else{
                        return false;
                    }
                })
                .map((item, index) => {
                    const selected = props.image ? props.image.id === item?.image_id : props.bannerImageId === item?.image_id;
                    return (
                        <div key={index} onClick={() => props.onSelect(item?.image_id)}  className='col-auto p-0 m-0'>
                            {
                                item?.image_type === 'document' && item?.image_url?.endsWith('.pdf') ?
                                <ImageViewer key={index} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`} url={item?.image_url} entityType='image'/>
                                :<ImageViewer key={index} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`} entityId={item?.image_id} entityType='image' />
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default GridImageList