import React from 'react';
import { TABLE_ROWS, cleanHtml } from '../../helpers';

const EventReservation = ({ data }) => {
    const openUrl = (url) => {
        window.open(url, '_blank');
    };
    return (
        <td>
        {data?.slice(0, TABLE_ROWS).map((item, index) => {
            const name = cleanHtml(item?.name); // Assuming cleanHtml works as expected
            return (
                <div key={index} className='text-truncate text-danger text-underline-hover' onClick={() => openUrl(item?.url)}>
                    <span dangerouslySetInnerHTML={{ __html: name }} />
                </div>
            );
        })}

        {data?.length > TABLE_ROWS && (
            <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>
        )}
    </td>
    )
}

export default EventReservation;