import React, { useState } from 'react';
import { dateString } from '../../helpers';
import Panel from '../panel';
const MIN_LENGTH = 3

const SiteDesignationList = ({ designations }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {
                (designations?.length > 0 ) &&
                <>
                    <hr />
                    <div className='d-flex justify-content-between'>

                        <Panel title='Designations' bold={true} italic={true} />
                        {
                            designations.length > MIN_LENGTH &&
                            <div>
                                    {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${designations.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                    {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(designations.length); showMore(true) }}>{`${designations.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                            </div>
                        }
                    </div>
                </>
            }
            {
                (designations?.length > 0 ) &&
                designations.slice(0, length).map((item, index) => {
                    return <div key={index}>
                        {item?.designation_name} {dateString(item).length > 0 ? ' in ' : ''} {dateString(item)}
                    </div>
                })


            }
        </>
    )
}

export default SiteDesignationList;