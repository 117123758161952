/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../components/buttons/default';
import Icon from '../../components/viewers/icon';

const menu = require('../../data/menu_participant.json');

const ParticipantMenu = (props) => {
    const dispatch = useDispatch();

    const person = useSelector(state => state[props.id]);
    const menuSize = useSelector((state) => state.menu);

    const toggleMenuSize = () => dispatch({ type: 'menu', payload: menuSize === 'sm' ? 'lg' : 'sm' })

    const hasData = (path) => {
        let hasData = false;

        if (path === 'name') hasData = person?.name?.length || person?.first_name?.length > 0 || person?.last_name?.length > 0;
        
        return hasData;
    }

    return (
        <>
            {
                menuSize === 'sm' && <>
                    <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    <div className='btn-list mt-4 text-center'>
                        {
                            menu.map((item, index) => {
                                let icon = item.icon;
                                if (hasData(item.path)) icon = `${item.icon}-done`;
                                if (props.section === item.path) icon = `${item.icon}-active`

                                return (
                                    <div key={index} className='mt-2'>
                                        <OverlayTrigger placement='right' overlay={<Tooltip>{item.name}</Tooltip>}>
                                            <button className='btn' onClick={() => { if (!props.display) props.onClick(item.path) }} data-bs-toggle='tooltip' data-bs-placement='right' title='Tooltip on right'>
                                                <Icon name={icon} size={24} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            {
                menuSize === 'lg' && <>
                    <div className='d-flex justify-content-between'>
                        <h5 className='text-capitalize text-nowrap'>{`Add An Person`}</h5>
                        <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                    </div>

                    <ul className='list-group'>
                        {
                            menu.map((item, index) => {
                                let itemClass = 'list-group-item cnow-list-group-item d-flex align-items-start';
                                if (props.section === item.path) itemClass = `${itemClass} active`;
                                if (!item.enabled) itemClass = `${itemClass} disabled`
                                if (!person?.id) itemClass = `${itemClass} disabled`;

                                return (
                                    <li key={index} className={itemClass}>
                                        <button className={itemClass} onClick={() => { if (!props.display) props.onClick(item.path) }}>
                                            <i className={`fe fe-check mt-1 ${hasData(item.path) ? '' : 'cnow-text-light'}`} />
                                            <div className='ms-2 text-start'>
                                                {item.name}
                                                {item.required && <span className='ms-1'>*</span>}
                                            </div>
                                        </button>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </>
            }
        </>
    )
}

export default ParticipantMenu;