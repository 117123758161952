import React, { useState } from 'react';
import { cleanHtml } from '../../helpers';

const MIN_LENGTH = 3

const RelatedMapList = ({ data }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    <div className='d-flex justify-content-between'>
                    <div className='fw-bold fst-italic'>Related Maps</div>

                        {
                            data.length > MIN_LENGTH &&
                            <div>
                                {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                            </div>
                        }
                    </div>
                    {
                        data.slice(0, length).map((item, index) => {
                            return <div key={index}>
                                <a href={`${process.env.REACT_APP_WEB_URL}/maps/${item?.related_map_id || item?.map_id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none text-dark'>{item?.related_map_name || item?.map_name}</a>
                                {
                                    item.description && <>
                                        <span className='me-1'>-</span><span dangerouslySetInnerHTML={{ __html: cleanHtml(item.description) }} />
                                    </>
                                }
                            </div>;
                        })
                    }
                </>
            }
        </>
    )
}

export default RelatedMapList;