import React, { useState, useEffect, useRef } from 'react'
import FeaturedTitle from './title';
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import { useHistory } from 'react-router-dom';
import FeaturedCard from '../../components/featured/featuredCard';
import Spinner from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import StaticContentCard from '../../components/featured/staticContentCard';
import CollectionImageCard from '../../components/featured/collectionsImageCard';
import { handleDragDropReorder } from '../../helpers';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
const UpComingEvents = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [eventsData, setEventsData] = useState([])
    const [display, setDisplay] = useState(false)
    const [isContentEdit, setIsContentEdit] = useState(false);

    const searchEvents = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const upcoming_events = featured.upcoming_events || []

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (props?.page !== 'home') ? 'dragula-container-up-comming-events' : ''
    useEffect(() => {
        handleDragDrop(upcoming_events?.[0]?.data?.upcoming_events || [])
        // eslint-disable-next-line
    }, [dragula])
    const handleDragDrop = (siteOfDay) => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            setEditedIndex(-1)
            const items = handleDragDropReorder(siteOfDay, dragulaContainer)
            upcoming_events[0].edited = true
            upcoming_events[0].data.upcoming_events = items
            dispatch({ type: 'featured', payload: { ...featured, upcoming_events: upcoming_events } })
            setDragula(uniqid())
        })

    }
    // Dragula End

    // eslint-disable-next-line
    useEffect(() => { formatEvents(); if (!upcoming_events[0]?.data?.description && !upcoming_events[0]?.data?.title) setTitleDescription() }, [upcoming_events?.length, dragula])

    const formatEvents = () => {
        let data = []
        if (upcoming_events)
            for (let i in upcoming_events) {
                if (upcoming_events[i]?.data?.upcoming_events) {
                    for (let j in upcoming_events[i].data.upcoming_events) {
                        upcoming_events[i].data.upcoming_events[j].section_index = i
                        upcoming_events[i].data.upcoming_events[j].entity_index = j
                        data.push(upcoming_events[i].data.upcoming_events[j])
                    }
                }
            }
        setEventsData(data)
    }

    const setTitleDescription = () => {
        if (upcoming_events[0]?.data?.upcoming_events.length > 0) {
            upcoming_events[0].data.title = 'Attend an Event'
            upcoming_events[0].data.description = 'There are lectures, symposia, roundtables exhibitions, tours and programs.'
            dispatch({ type: 'featured', payload: { ...featured, upcoming_events: upcoming_events } })
        }
    }

    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchEvents, upcoming_events: eventsData, selectedResult: [], title: `HOMEPAGE - Events`, filterLive: true } })
        history.push(`/featured/searchList/upcoming_events/event`)
    }

    const handleInputChange = (value, type, id) => {
        if (type === 'name') {
            upcoming_events[0].data.title = value
        }
        if (type === 'description' && value) {
            upcoming_events[0].data.description = value
        }
        upcoming_events[0].edited = true
        dispatch({ type: 'featured', payload: { ...featured, upcoming_events: upcoming_events } })
    }

    const handleDeletePeople = () => {
        setDisplay(true)
        let id_index = eventsData[editedIndex].section_index
        let id_event_index = eventsData[editedIndex].entity_index
        upcoming_events[id_index].edited = true
        upcoming_events[id_index].data.upcoming_events.splice(id_event_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, upcoming_events: upcoming_events } })
        setDisplay(false)
        //to refresh Call function Again
        formatEvents()
        setEditedIndex(editedIndex - 1)
    }

    return (
        <>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="UPCOMING EVENTS"
                        count={eventsData.length > 1 ? `${eventsData?.length} items` : `${eventsData?.length} item`}
                        page={props?.page}
                        button={{
                            // set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            // setIsEdited(true);
                            edit: { show: true, onHandle: () => { handleClick(0) } },
                            // add: { show: true, onHandle: () => { } },
                        }}
                    >
                        <Spinner display={display}>
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {eventsData.map((item, index) => (
                                    <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') handleClick(index) }}>
                                        <FeaturedCard data={item} image="true" title="true" description="true" section={'event'} />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                        {eventsData?.length > 0 && <div className='mt-2' onClick={(e) => { if (props?.page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                            <StaticContentCard data={upcoming_events[0]?.data} />
                        </div>}
                    </CollapsableSection>
                    {(editedIndex !== -1) && eventsData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/event/${eventsData[editedIndex]?.parent_id ? eventsData[editedIndex]?.parent_id : eventsData[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (true), onHandle: () => { handleDeletePeople(); props.onUpdate(); } }
                        }}>
                        <CollectionImageCard key={`image-${editedIndex}`} data={eventsData[editedIndex]} name={eventsData[editedIndex]?.name} />
                    </CollapsableSection>}

                    {isContentEdit && upcoming_events[0]?.data?.description && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: (eventsData.length > 0), onHandle: () => { setIsContentEdit(false); props.onUpdate(); } },
                        }}>

                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={upcoming_events[0]?.data?.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={upcoming_events[0]?.data?.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"                              
                                    key={`TextEditor${editedIndex}`}
                                >
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </>
    )
}

export default UpComingEvents