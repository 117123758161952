import React from "react";
import { useSelector } from 'react-redux';
import InThisPhoto from "../../components/inThisPhoto";
import OnThisDay from "../../components/onThisDay";
import PageTitle from "../../components/pageTitle";
import { personName } from '../../helpers';

const OnThisDayInThisPhoto = (props) => {
    const maps = useSelector(state => state[props.id]);

    return (
        <>
            <PageTitle title={personName(maps)} />
            <OnThisDay id={maps.id} type={'map'} />
            <InThisPhoto id={maps.id} type={'map'} onUpdate={() => props.onUpdate()} />
        </>
    )
}
export default OnThisDayInThisPhoto