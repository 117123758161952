import React from "react";
import Button from "../buttons/default";
import PreviewButton from "../buttons/preview";
const _api = require('../../api');
const RemarksAttributedList = (props) => {

    const handleDelete = async (id, index) => {
        await _api.remarks_attribution.deleteRemarksAttributionById(id)
        props.getUpdatedData(props?.data[index], index)
    }
    return (
        <>
            <div className="fw-bold">Remarks Attributed List</div>
            {
                props?.data && props?.data?.length > 0 && props?.data.map((elem, index) => {
                    return (
                        <>

                            <div className="row  py-1">

                                {props?.homePage &&
                                    <>
                                        <div className='col-auto'>
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} autoComplete="off" />
                                        </div>

                                        <div className='w-25 ms-1'>
                                            <input value={elem?.tag_type} disabled className='cnow-input-height w-100' />
                                        </div>
                                    </>
                                }
                                {!props?.homePage && <div className='col'>
                                    <input name='selected text' type='text' className='form-control' value={elem?.highlighted_text || ''} disabled={true} autoComplete="off" />
                                </div>}

                                <div className='col'>
                                    <input name='selected text' type='text' className='form-control' value={elem?.tag_name || elem?.tag_text} disabled={true} autoComplete="off" />
                                </div>
                                <div className='col-auto'>
                                    <PreviewButton size='sm' color='light' link={`people/${elem?.tag_id}`} />
                                    <Button size='sm' color='light' icon='delete' className={`ms-1`} onClick={() => { handleDelete(elem.id, index) }} />
                                </div>
                            </div>
                        </>
                    )
                })

            }
        </>
    )
}

export default RemarksAttributedList