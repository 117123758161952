import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import RelatedEventEditor from '../../components/editors/eventEditor';
import RelatedTourEditor from '../../components/editors/relatedTourEditor';
import PageTitle from '../../components/pageTitle';
import RelatedEventSeriesEditor from '../../components/editors/relatedEventSeriesEditor';

const EventsTours = (props) => {
    const playlist = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={playlist.name} />
            <RelatedEventEditor id={playlist.id} type={'playlist'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={false} />
            <RelatedTourEditor id={playlist.id} type={'playlist'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <RelatedEventSeriesEditor   id={playlist.id} type={'playlist'} key={`related-playlist-editor-${key}`} refresh={() => { setKey(uniqid()) }}/>
        </>
    );
};

export default EventsTours;