/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import { handleReorder } from '../../helpers';
import Button from '../../components/buttons/default';
import PeopleFeatureProject from './peopleFeatureProject';
const _api = require('../../api');
const EventFeaturedProject = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-featured-projects'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(event.featured_people, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, featured_people: items } })
        })
    }, [dragula])
    // Dragula End
    const featuredEvent = event?.featured_people?.filter((elem) => elem.featured)
    const handleHighlightChange = async (index) => {
        await _api.event.updateFeaturedPeople(event.featured_people[index].id, !event.featured_people[index].featured)
        event.featured_people[index].featured = !event.featured_people[index].featured
        dispatch({ type: 'entity', payload: { ...event, featured_people: event.featured_people } });
    }

    return (
        <>

            <PageTitle title={event?.name} />
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>PEOPLE / ORGANIZATION *</label>
                </div>

            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    event?.featured_people?.length > 0 && event.featured_people.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    event?.featured_people?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                        <input type='text' id={`${item.person_name}, ${item.person_id}`} className='nwprime form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                }
                                <div className='col-auto d-flex flex-grow-1'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Person or Organization*</label>}
                                        <input value={item?.person_name} disabled className='w-100 cnow-input-disabled-height' />
                                    </div>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Profession *</label>}
                                        <input value={item?.profession_name} disabled className='w-100 cnow-input-disabled-height' />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'>Featured</label>}
                                    <div className='btn-list me-3'>
                                        <Button icon={`${item.featured ? 'star-red' : 'star-black'}`} onClick={() => handleHighlightChange(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PeopleFeatureProject id={props?.id} key={`featuredEvent${featuredEvent?.length}`} />
        </>
    )
}

export default EventFeaturedProject;