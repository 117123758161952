import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';


import SelectSite from '../selects/site';
import SelectPerson from '../selects/person';
import SelectPlaylist from '../selects/playlist';
import SelectMap from '../selects/map';
import SelectTour from '../selects/tour';

import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { cleanHtml, customSelectStyle, newTempId } from '../../helpers';
import SelectPodcast from '../selects/podcast';

const relatedItemTypes = require('../../data/participant_related_item_type.json');
const _api = require('../../api')

const RelatedItem = (props) => {
  const dispatch = useDispatch();
  const person = useSelector(state => state[props.id])

  //#region Related Items
  const addRelatedItem = () => {
    if (!person.related_items) person.related_items = [];
    person.related_items.push({
      'row_id': newTempId(),
      'id': null,
      'name': '',
      'source': '',
      'remarks': '',
      'entity_type': ''
    });

    dispatch({ type: 'entity', payload: { ...person, related_items: person.related_items } });
  }

  const removeRelatedItem = async (index) => {
    if (person.related_items[index]) {
      if (!person.related_items[index].row_id?.startsWith('temp.')) {
        // TO DO - 2 way apis based on type
        //site
        if (person.related_items[index].source === 'related-site') {
          await _api.site.deleteRelatedPerson(person.related_items[index].row_id)
        }
        //person
        if (person.related_items[index].source === 'related-person') {
          await _api.person.deleteRelatedPerson(person.related_items[index].row_id)
        }
        //map
        if (person.related_items[index].source === 'related-map') {
          await _api.person.deleteMap(person.related_items[index].row_id)
        }
        //podcast
        if (person.related_items[index].source === 'related-podcast') {
          await _api.person.deletePodcast(person.related_items[index].row_id)
        }
        //tour
        if (person.related_items[index].source === 'related-tour') {
          await _api.person.deleteTour(person.related_items[index].row_id)
        }
        //playlist
        if (person.related_items[index].source === 'related-playlist') {
          await _api.person.deletePlaylist(person.related_items[index].row_id)
        }
      }
      person.related_items.splice(index, 1)
      dispatch({ type: 'entity', payload: { ...person, related_items: person.related_items } });
    }
  }

  const handleRelatedItemTypeChange = (index, value) => {
    person.related_items[index].source = value.value;
    let entity_type = '';
    if(value.value.includes('site')) entity_type = 'site'
    if(value.value.includes('person')) entity_type = 'person'
    if(value.value.includes('podcast')) entity_type = 'podcast'
    if(value.value.includes('playlist')) entity_type = 'playlist'
    if(value.value.includes('map')) entity_type = 'map'
    if(value.value.includes('tour')) entity_type = 'tour'
    
    person.related_items[index].entity_type = entity_type


    dispatch({ type: 'entity', payload: { ...person, related_items: person.related_items } });
  }

  const handleRelatedItemChange = async (index, value) => {
    person.related_items[index].id = value.id
    person.related_items[index].name = value.name
    person.related_items[index].is_participant = value.is_participant || false

    // TO DO - 2 way apis based on type
    //site
    if (person.related_items[index].source === 'related-site') {
      const params = {
        id: person.related_items[index].row_id,
        site_id: value.id,
        related_person_id: person.id,
        description: person.related_items[index].remarks,
        sort: null
      }
      const response = await _api.site.updateRelatedPerson(value.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }

    //person
    if (person.related_items[index].source === 'related-person') {
      const params = {
        id: person.related_items[index].row_id,
        person_id: person.id,
        related_person_id: value.id,
        description: person.related_items[index].remarks,
        source: 'person',
        role_id: null,
        sort: null
      }
      const response = await _api.person.updateRelatedPerson(person.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }

    //map
    if (person.related_items[index].source === 'related-map') {
      const params = {
        id: person.related_items[index].row_id,
        map_id: value.id,
        person_id: person.id,
        description: person.related_items[index].remarks,
        sort: null,
        source: 'person'
      }
      const response = await _api.person.updateMap(person.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }

    //podcast
    if (person.related_items[index].source === 'related-podcast') {
      const params = {
        id: person.related_items[index].row_id,
        podcast_id: value.id,
        date_id: null,
        sort: null
      }
      const response = await _api.person.updatePodcast(person.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }
    
    //tour
    if (person.related_items[index].source === 'related-tour') {
      const params = {
        id: person.related_items[index].row_id,
        tour_id: value.id,
        person_id: person.id,
        description: person.related_items[index].remarks,
        sort: null,
        source: 'person'
      }
      const response = await _api.person.updateTour(person.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }

    //playlist
    if (person.related_items[index].source === 'related-playlist') {
      const params = {
        id: person.related_items[index].row_id,
        playlist_id: value.id,
        person_id: person.id,
        description: person.related_items[index].remarks,
        sort: null,
        source: 'person'
      }
      const response = await _api.person.updatePlaylist(person.id, [params])
      person.related_items[index].row_id = response ? response[0].id : person.related_items[index].row_id;
    }

    dispatch({ type: 'entity', payload: { ...person, related_items: person.related_items } });
  };

  const handleRemark = (e, index) => {
    person.related_items[index].remarks = e.target.value;
    dispatch({ type: 'entity', payload: { ...person, related_items: person.related_items } });
  }
  //#endregion

  return (
    <div id='related_items'>
      <hr className='cnow-hr' />
      <div className='d-flex justify-content-between'>
        <div className=''>
          <label className='cnow-form-title'>Related Item</label>
        </div>
        <div className=''>
          <Button size='sm' icon='plus' label='Add one more' onClick={() => addRelatedItem()} disabled={props.id === 'NEW'} />
        </div>
      </div>

      <div className='d-flex mt-1'>
        <div className={person?.related_items?.length > 1 ? '' : ''} style={{ width: '50px' }}>
          <label className='cnow-form-label'>Order</label>
        </div>
        <div className='flex-grow-1 d-flex'>
          <div className='w-75 ms-1'>
            <label className='cnow-form-label'>Type</label>
          </div>
          <div className='w-100 ms-1'>
            <label className='cnow-form-label'>Name</label>
          </div>
          <div className='w-75 ms-1'>
            <label className='cnow-form-label'>Role/Remarks</label>
          </div>
        </div>
        <div className='text-end' style={{ width: '130px' }}>
          <label className='cnow-form-label'></label>
        </div>
      </div>
      {person?.related_items?.map((item, index) => {
        return (
          <div className='d-flex mt-1' key={index}>
            <div className={person?.related_items?.length > 1 ? '' : ''}>
              <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} autoComplete="off" />
            </div>
            <div className='flex-grow-1 d-flex'>
              <div className='w-75 ms-1'>
                {!item?.row_id?.startsWith('temp.') ?
                  <input value={item.source} disabled className='cnow-input-height w-100' /> :
                  <Select name='type'
                    options={relatedItemTypes}
                    styles={customSelectStyle}
                    value={{ 'value': item.source, 'label': item.source }}
                    onChange={(value) => { handleRelatedItemTypeChange(index, value) }} placeholder={<div className='text-muted'>MM</div>} />}
              </div>

              <div className='w-100 ms-1'>
                {!item?.row_id?.startsWith('temp.') ?
                  <input value={item.name} disabled className='cnow-input-height w-100' />
                  :
                  <>
                    {item.source === '' &&
                      <Select styles={customSelectStyle} />}
                    {item.source === 'related-site' &&
                      <SelectSite value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                    {(item.source === 'related-person') &&
                      <SelectPerson value={{ 'id': item.id, 'name': item.name, 'is_participant': item.is_participant }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                    {item.source === 'related-podcast' &&
                      <SelectPodcast value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                    {item.source === 'related-playlist' &&
                      <SelectPlaylist value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                    {item.source === 'related-map' &&
                      <SelectMap value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                    {item.source === 'related-tour' &&
                      <SelectTour value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
                  </>}
              </div>
              <div className='w-75 ms-1'>
                <input value={cleanHtml(item.remarks)} className='cnow-input-height w-100' onChange={(e) => handleRemark(e, index)} disabled={(item.source?.includes('related-')) ? false : true} />
              </div>
            </div>
            <div className='text-end ms-1'>
              <div className='d-flex gap-1 justify-content-end'>
                <PreviewButton link={`${item.entity_type === 'person' ? `${!item.is_participant ? 'people' : 'participant'}` : item.entity_type}/${item.id}`} />
                <LinkButton icon='edit' to={`/${item.entity_type === 'person' ? `${!item.is_participant ? 'people' : 'participant'}` : item.entity_type}/${item.id}/editor`} />
                <Button icon='delete' disabled={(item.source?.includes('related-')) ? false : true} onClick={() => removeRelatedItem(index)} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RelatedItem