import moment from 'moment'
import React from 'react'

const Accession = ({ label, data, hrTrue, entity="Site", createdText="Accession Date/Time" }) => {
  return (
    <>
        {hrTrue && <hr className="cnow-hr" />}
        {label && <div className='row'>
            <div className='col'>
                <label className='cnow-form-title'>{label}</label>
            </div>
        </div>}
        <div className=''>
                <div>Added Via: {data?.created_via} </div>
                <div>{createdText}: {moment(new Date(data?.created_date)).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>Added by: {data?.created_by_name}</div>
                <div>Last Worked On by: {data?.modified_by_name}</div>
                <div>{entity} Id: {data?.id}</div>
        </div>
    </>
  )
}

export default Accession