/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import uniqid from 'uniqid';

import Button from '../../components/buttons/default';
import swal from 'sweetalert';
import { isEqual, sortBy } from 'lodash';
import { customSelectStyle } from '../../helpers';

const _API = require('../../api')

const MapEditor = (props) => {

    const id = props.match.params.id
    const [locationId, setLocationId] = useState(props.match.params.sectionId);

    const dispatch = useDispatch();
    const site = useSelector(state => state[id]);
    const location = site?.locations.filter(x => x.id === locationId)[0]

    const [locationlist, setLocationList] = useState([]);

    const [coordinatesModal, showCoordinatesModal] = useState(false);

    const [drawingEnabled, setDrawing] = useState(false);
    const [showDelete, showDeleteButton] = useState(false);
    const [isSaved, setSaved] = useState(true);

    const [mapPolylines, setMapPolyLines] = useState([]);
    const mapPolylinesRef = useRef(mapPolylines);

    const [mapPolygons, setMapPolygons] = useState([]);
    const mapPolygonsRef = useRef(mapPolygons);

    const [center, setCenter] = useState({ lat: 40.730610, lng: -73.935242 }); //New York

    const mapRef = useRef();
    const drawingManagerRef = useRef();
    const uploadShapeFileRef = useRef();

    const [mapkey, setMapKey] = useState(locationId);

    const [selectedlocation, setSelectedLocation] = useState({ value: location?.id, label: location?.name || `Location ${site.locations.findIndex(loc => loc.id === location?.id) + 1}` });

    const [latlng, setLatLng] = useState([]);

    const [coord_text, setText] = useState('');

    const lineStyle = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
    };

    const prevUrl = props.location.pathname.slice(0, props.location.pathname.lastIndexOf('/'));

    useEffect(() => {
        let list = [];
        for (let i = 0; i < site?.locations.length; i++) {
            list.push({ value: site.locations[i].id, label: site.locations[i].name || `Location ${i + 1}` });
        }
        setLocationList(list);
        let index = site.locations.findIndex(x => x.id === locationId);
        if (index >= 0 && (typeof (site.locations[index].geojson) === 'undefined' || !site.locations[index].geojson)) {
            site.locations[index].geojson = {
                type: 'FeatureCollection',
                name: site.locations[index].name,
                features: []
            }
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
        }
    }, [site.locations, locationId, site, dispatch])

    useEffect(() => { setMapKey(locationId) }, [locationId])

    const createGeoJsonFeature = (type, mapObj) => {
        let featureType = '';
        let coordinates = null
        if (type === 'Polygon') {
            featureType = 'Polygon';
            coordinates = [];
            let polygonCordinates = calculatePoints(mapObj);
            polygonCordinates.push(polygonCordinates[0]);
            coordinates.push(polygonCordinates);
        } else if (type === 'Polyline') {
            featureType = 'LineString';
            coordinates = calculatePoints(mapObj);
        }
        let feature = {
            id: mapObj.id,
            type: 'Feature',
            geometry: {
                type: featureType,
                coordinates: coordinates
            }
        }
        return feature;
    }

    const setMapPolygonsRef = (polygon) => {
        mapPolygonsRef.current = polygon;
        setMapPolygons(polygon);
    }

    const setMapPolyLinesRef = (polyline) => {
        mapPolylinesRef.current = polyline;
        setMapPolyLines(polyline);
    }

    const completeDraw = () => {
        drawingManagerRef.current.setDrawingMode(null);
        if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
            mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolygonsRef.current.setEditable(false);
        }
        if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
            mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolylinesRef.current.setEditable(false);
        }
        setDrawing(false);
        setLatLng([]);
    }

    const enableDraw = () => {
        setSaved(false);
        showDeleteButton(false);
        if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
            mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolygonsRef.current.setEditable(false);
        }
        if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
            mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolylinesRef.current.setEditable(false);
        }
        if (location?.type === 'path') drawingManagerRef.current.setDrawingMode('polyline');
        if (location?.type === 'area') drawingManagerRef.current.setDrawingMode('polygon');
        setDrawing(true);
    }

    const handleMapLoad = (google) => {
        mapRef.current = google.map

        const drawingManager = createDrawingManager(google);
        drawingManagerRef.current = drawingManager;
        google.maps.event.addListener(drawingManager, 'polylinecomplete', handlePolyline(google));
        google.maps.event.addListener(drawingManager, 'polygoncomplete', handlePolygon(google));
        mapRef.current.addListener("click", () => {
            if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
                mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolygonsRef.current.setEditable(false);
            }
            if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
                mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolylinesRef.current.setEditable(false);
            }
            showDeleteButton(false);
            setLatLng([]);
        })
        drawingManager.setMap(google.map);
        drawLocations(google)
        mapRef.current.setOptions({
            'zoomControlOptions': { 'position': google.maps.ControlPosition.RIGHT_BOTTOM },
            'controlSize': 24
        })
    }

    const createDrawingManager = (google) => {
        let drawingMode = null;
        let drawingModes = [];

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: drawingMode,
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: drawingModes
            },
            polygonOptions: {
                fillColor: '#A82829',
                fillOpacity: 0.5,
                strokeColor: '#A82829',
                strokeWeight: 5,
                editable: true,
                zIndex: 1
            },
            polylineOptions: {
                strokeColor: '#A82829',
                strokeWeight: 5,
                editable: true,
                zIndex: 1,
                strokeOpacity: 0,
                icons: [
                    {
                        icon: lineStyle,
                        offset: "0",
                        repeat: "20px",
                    },
                ]
            }
        });

        return drawingManager;
    }

    const setEditableFeature = (mapObj, type, google) => {
        mapObj.setEditable(true);
        mapObj.setOptions({ strokeColor: '#A82829', fillColor: '#A82829' });
        showDeleteButton(true);
        if (type === 'polyline') {
            setMapPolyLinesRef(mapObj)
            google.maps.event.addListener(mapObj.getPath(), 'set_at', updateFeature(mapObj.id));
            google.maps.event.addListener(mapObj.getPath(), 'insert_at', updateFeature(mapObj.id));
        }
        else if (type === 'polygon') {
            setMapPolygonsRef(mapObj)
            google.maps.event.addListener(mapObj.getPath(), 'set_at', updateFeature(mapObj.id));
            google.maps.event.addListener(mapObj.getPath(), 'insert_at', updateFeature(mapObj.id));
        }
    }

    const drawLocations = (google) => {
        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < site.locations.length; i++) {
            let pathColor = '';
            let areaColor = '';
            let isDraggable = false;
            let opacityLevel = 1;
            const location = site.locations[i];
            const features = location.geojson?.features;
            if (location.id === locationId) {
                areaColor = '#86E8FF';
                pathColor = '#86E8FF'; // #A82829
                isDraggable = true;
                opacityLevel = 1;
            } else {
                areaColor = '#444444';
                pathColor = '#444444';
                isDraggable = false;
                opacityLevel = 0.5;
            }
            if (location.location) {
                const position = { lat: parseFloat(location.location.lat), lng: parseFloat(location.location.lng) }
                const marker = new google.maps.Marker({
                    title: location.name,
                    position: position,
                    draggable: isDraggable,
                    opacity: opacityLevel,
                    icon: '/img/icons/red-marker.svg'
                });
                marker.addListener('dragend', (marker) => handleMarkerMove(location.id, marker));
                marker.setMap(mapRef.current);
            }

            for (let i in features) {
                const feature = features[i]
                let isNewFeature = false;
                let featurecoords = feature.geometry.type === 'Polygon' ? feature.geometry.coordinates[0] : feature.geometry.coordinates;
                if (isEqual(sortBy(featurecoords), sortBy(latlng))) {
                    isNewFeature = true;
                }
                if (feature.geometry.type === 'LineString') {
                    let polylineCoordinates = convertLatLng(feature.geometry);
                    const polyline = new google.maps.Polyline({
                        id: feature.id,
                        path: polylineCoordinates, strokeColor: pathColor, strokeWeight: 5, strokeOpacity: 0, editable: drawingEnabled, icons: [
                            {
                                icon: lineStyle,
                                offset: "0",
                                repeat: "20px",
                            },
                        ]
                    });
                    google.maps.event.addListener(polyline, 'click', handlePolylineEdit(google, polyline));
                    google.maps.event.addListener(polyline, 'rightclick', function (event) {
                        if (event.vertex === undefined) {
                            return;
                        } else {
                            let path = polyline.getPath();
                            path.removeAt(event.vertex);
                        }
                    });
                    if (isNewFeature) setEditableFeature(polyline, 'polyline', google)
                    polyline.setMap(mapRef.current);
                } else if (feature.geometry.type === 'Polygon') {
                    let polygonCordinates = convertLatLng(feature.geometry);
                    const polygon = new google.maps.Polygon({ id: feature.id, paths: polygonCordinates, strokeColor: pathColor, strokeWeight: 5, fillColor: areaColor, fillOpacity: 0.5, editable: drawingEnabled });
                    google.maps.event.addListener(polygon, 'click', handlePolygonEdit(google, polygon));
                    google.maps.event.addListener(polygon, 'rightclick', function (event) {
                        if (event.vertex === undefined) {
                            return;
                        } else {
                            let path = polygon.getPath();
                            path.removeAt(event.vertex);
                        }
                    });
                    if (isNewFeature) setEditableFeature(polygon, 'polygon', google)
                    polygon.setMap(mapRef.current);
                }
            };

            if (location.geojson?.features?.length > 0) {
                const additionalFeatures = { ...location.geojson }
                additionalFeatures.features = additionalFeatures.features.filter(x => x.geometry.type !== 'LineString' && x.geometry.type !== 'Polygon' && x.geometry.type !== 'Point')
                mapRef.current.data.addGeoJson(additionalFeatures);
                mapRef.current.data.setStyle(function (feature) {
                    let type = feature.getGeometry().getType();
                    if (type === 'MultiPolygon') {
                        return { strokeColor: pathColor, fillColor: areaColor, fillOpacity: 0.5 };
                    }
                });
            }

            if (locationId) {
                if (location.id === locationId) {
                    let focus = {
                        lat: parseFloat(location.location?.lat),
                        lng: parseFloat(location.location?.lng)
                    }
                    if (location.location && location.location.lat && location.location.lng) {
                        bounds.extend(focus)
                        setCenter(focus)
                    }
                    features && features.forEach((feature) => {
                        let coordinates = convertLatLng(feature.geometry);
                        coordinates.forEach(line => bounds.extend(line))
                    });
                    if (location.location || features?.length)
                        mapRef.current.fitBounds(bounds)
                    if (!features?.length && location.location) {
                        mapRef.current.setZoom(15); // zoom level - 13 for proper city context
                    }
                }
            }
        }
        mapRef.current.data.addListener('click', (event) => {
            let type = event.feature.getGeometry().getType()
            if (type !== 'Polgon' || type !== 'LineString')
                toast.error(`Feature of type ${type} cannot be edited`);
        });
        if (site.map_overlays?.length > 0) {
            renderOverlays(google, bounds);
        }
    }

    const renderOverlays = (google, bounds) => {
        let overlays = site.map_overlays;
        overlays?.length > 0 && overlays.forEach(layer => {
            layer.items?.length > 0 && layer.items.forEach(link => {
                let OverLay = new google.maps.ImageMapType({
                    getTileUrl: function (coord, zoom) { return link.url + zoom + '/' + coord.x + '/' + coord.y + '.png' },
                    tileSize: new google.maps.Size(256, 256),
                    name: link.id
                });
                if (bounds && link.meta?.bounds?.length > 0) {
                    let tilerBounds = [
                        { lat: link.meta?.bounds[1], lng: link.meta?.bounds[0] },
                        { lat: link.meta?.bounds[1], lng: link.meta?.bounds[2] },
                        { lat: link.meta?.bounds[3], lng: link.meta?.bounds[2] },
                        { lat: link.meta?.bounds[3], lng: link.meta?.bounds[0] }
                    ]
                    tilerBounds.forEach(point => bounds.extend(point))
                    mapRef.current.fitBounds(bounds);
                }
                mapRef.current.overlayMapTypes.push(OverLay);
            })
        })
        let OverlayView = new google.maps.OverlayView();
        OverlayView.setMap(mapRef.current);
        OverlayView.getPanes().mapPane.style.zIndex = 105
        OverlayView.getPanes().markerLayer.style.zIndex = 106
    }

    const convertLatLng = (geometry) => {
        let coords = [];
        let points = geometry.coordinates;
        if (geometry.type === 'Polygon') {
            points[0].forEach(p => { coords.push({ lat: p[1], lng: p[0] }); })
        } else if (geometry.type === 'LineString') {
            points.forEach(p => { coords.push({ lat: p[1], lng: p[0] }); })
        }
        return coords;
    }

    const calculatePoints = (mapObj) => {
        let points = [];
        for (var i = 0; i < mapObj.getPath().getLength(); i++) {
            var pt = mapObj.getPath().getAt(i);
            points.push([
                pt.lng(), pt.lat()
            ]);
        }
        return points;
    }

    const handleMarkerMove = (locationId, marker) => {
        const index = site.locations.findIndex(x => x.id === locationId)
        site.locations[index].location = {
            lat: marker.latLng.lat(),
            lng: marker.latLng.lng()
        }
        setSaved(false);
        dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
    }

    const handlePolyline = (google) => {
        return function (polyline) {
            polyline.id = uniqid();
            const index = site.locations.findIndex(x => x.id === locationId);
            if (!site.locations[index].geojson) site.locations[index].geojson = {}
            if (!site.locations[index].geojson.features) site.locations[index].geojson.features = [];
            site.locations[index].geojson.features.push(createGeoJsonFeature('Polyline', polyline));
            setMapPolyLinesRef(polyline);
            setLatLng(site.locations[index].geojson.features.filter(x => x.id === polyline.id)[0].geometry.coordinates)
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
            // polyline.setEditable(false);
            google.maps.event.addListener(polyline, 'click', handlePolylineEdit(google, polyline));
            google.maps.event.addListener(polyline, 'rightclick', function (event) {
                if (event.vertex === undefined) {
                    return;
                } else {
                    let path = polyline.getPath();
                    path.removeAt(event.vertex);
                }
            });
            if (mapPolylinesRef.current && mapPolylinesRef.current.id && mapPolylinesRef.current.id !== polyline.id) {
                mapPolylinesRef.current.setEditable(false);
                mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            }
            if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
                mapPolygonsRef.current.setEditable(false);
                mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolygonsRef.current = null;
            }
            // polyline.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            completeDraw();
            showDeleteButton(false)
            setSaved(false);
        }
    }

    const handlePolygon = (google) => {
        return function (polygon) {
            polygon.id = uniqid();
            const index = site.locations.findIndex(x => x.id === locationId);
            if (!site.locations[index].geojson) site.locations[index].geojson = {}
            if (!site.locations[index].geojson.features) site.locations[index].geojson.features = [];
            site.locations[index].geojson.features.push(createGeoJsonFeature('Polygon', polygon));
            setLatLng(site.locations[index].geojson.features.filter(x => x.id === polygon.id)[0].geometry.coordinates[0])
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
            // polygon.setEditable(false);
            google.maps.event.addListener(polygon, 'click', handlePolygonEdit(google, polygon));
            google.maps.event.addListener(polygon, 'rightclick', function (event) {
                if (event.vertex === undefined) {
                    return;
                } else {
                    let path = polygon.getPath();
                    path.removeAt(event.vertex);
                }
            });
            if (mapPolygonsRef.current && mapPolygonsRef.current.id && mapPolygonsRef.current.id !== polygon.id) {
                mapPolygonsRef.current.setEditable(false);
                mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            }
            if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
                mapPolylinesRef.current.setEditable(false);
                mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolylinesRef.current = null;
            }
            setMapPolygonsRef(polygon);
            // polygon.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            completeDraw()
            showDeleteButton(false)
            setSaved(false);
        }
    }

    const handlePolygonEdit = (google, polygon) => {
        return function () {
            if (mapPolygonsRef.current && mapPolygonsRef.current.id && mapPolygonsRef.current.id !== polygon.id) {
                mapPolygonsRef.current.setEditable(false);
                mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            }
            if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
                mapPolylinesRef.current.setEditable(false);
                mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolylinesRef.current = null;
            }
            if (location.geojson && location.geojson.features.filter(feature => feature.id === polygon.id).length > 0) {
                setLatLng(findCoordinatesbyId(polygon.id)[0])
                showDeleteButton(true);
                polygon.setEditable(true);
                polygon.setOptions({ strokeColor: '#A82829', fillColor: '#A82829' });
                setMapPolygonsRef(polygon);
                google.maps.event.addListener(polygon.getPath(), 'set_at', updateFeature(polygon.id));
                google.maps.event.addListener(polygon.getPath(), 'insert_at', updateFeature(polygon.id));
            } else {
                showDeleteButton(false);
            }
        }
    }

    const handlePolylineEdit = (google, polyline) => {
        return function () {
            if (mapPolylinesRef.current && mapPolylinesRef.current.id && mapPolylinesRef.current.id !== polyline.id) {
                mapPolylinesRef.current.setEditable(false);
                mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            }
            if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
                mapPolygonsRef.current.setEditable(false);
                mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
                mapPolygonsRef.current = null;
            }
            if (location.geojson && location.geojson.features.filter(feature => feature.id === polyline.id).length > 0) {
                setLatLng(findCoordinatesbyId(polyline.id))
                setMapPolyLinesRef(polyline);
                showDeleteButton(true);
                polyline.setOptions({ strokeColor: '#A82829', fillColor: '#A82829' });
                polyline.setEditable(true);
                google.maps.event.addListener(polyline.getPath(), 'set_at', updateFeature(polyline.id));
                google.maps.event.addListener(polyline.getPath(), 'insert_at', updateFeature(polyline.id));
            } else {
                showDeleteButton(false);
            }
        }
    }

    const updateFeature = (updatedFeatureid) => {
        return function () {
            const index = site.locations.findIndex(x => x.id === locationId);
            let EditedFeature = site.locations[index].geojson.features.filter(feature => feature.id === updatedFeatureid)[0];
            let coordinates;
            if (EditedFeature.geometry.type === 'Polygon') {
                coordinates = [];
                let polygonCordinates = calculatePoints(mapPolygonsRef.current);
                polygonCordinates.push(polygonCordinates[0]);
                coordinates.push(polygonCordinates)
                setLatLng(polygonCordinates);
            } else if (EditedFeature.geometry.type === 'LineString') {
                coordinates = calculatePoints(mapPolylinesRef.current);
                setLatLng(coordinates);
            }
            EditedFeature.geometry.coordinates = coordinates;
            setSaved(false);
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
        }
    }

    const handleDelete = () => {
        const index = site.locations.findIndex(x => x.id === locationId);
        if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
            let featurelist = site.locations[index].geojson.features.filter(feature => feature.id !== mapPolygonsRef.current.id)
            site.locations[index].geojson.features = featurelist;
            mapPolygonsRef.current.setMap(null);
            mapPolygonsRef.current = null;
            showDeleteButton(false);
        }
        if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
            let featurelist = site.locations[index].geojson.features.filter(feature => feature.id !== mapPolylinesRef.current.id);
            site.locations[index].geojson.features = featurelist;
            mapPolylinesRef.current.setMap(null);
            mapPolylinesRef.current = null;
            showDeleteButton(false);
        }
        setSaved(false);
        setLatLng([]);
        dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
    }

    const handleSave = async () => {

        //     await axios.put(`/${props.match.params.type === 'sitemap' ? 'site' : 'map'}/${id}`, site).then((response) => {
        //     dispatch({ type: 'entity', payload: response.data });
        //     toast.success('Map saved sucessfully', { hideProgressBar: true });
        //     setSaved(true);
        // });

        const Index = site?.locations.findIndex(x => x.id === locationId)

        await _API[props.match.params.type].updateLocation(site.id, site.locations).then((locations) => {
            setLocationId(locations[Index].id)
            dispatch({ type: 'entity', payload: { ...site, locations: locations } });
            toast.success('Map saved sucessfully', { hideProgressBar: true });
            setSaved(true);
        })

        if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
            mapPolygonsRef.current.setEditable(false);
        }
        if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
            mapPolylinesRef.current.setEditable(false);
        }
    }

    // const handleLatLngChange = (e) => {
    //     let coords = e.target.value.split(';');
    //     let latLngArr = [];
    //     coords.forEach(point => {
    //         let TransPoint = point.split(',');
    //         if (typeof (TransPoint[0]) !== 'undefined' && typeof (TransPoint[1]) !== 'undefined') {
    //             let translatlng = [parseFloat(TransPoint[0]), parseFloat(TransPoint[1])]
    //             latLngArr.push(translatlng);
    //         }
    //     });
    //     setLatLng(latLngArr);
    //     e.preventDefault();
    // }

    const getTransformedCoords = () => {
        let coords = coord_text.split(';');
        let latLngArr = [];
        let isValid = true;
        coords.forEach(point => {
            if (point) {
                let TransPoint = point.split(',');
                if (typeof (TransPoint[0]) !== 'undefined' && typeof (TransPoint[1]) !== 'undefined' && !isNaN(TransPoint[0]) && !isNaN(TransPoint[1])) {
                    let translatlng = [parseFloat(TransPoint[0]), parseFloat(TransPoint[1])]
                    if (isNaN(translatlng[0]) || isNaN(translatlng[1])) isValid = false;
                    latLngArr.push(translatlng);
                } else {
                    isValid = false;
                }
            }
        });
        if (isValid) return latLngArr
        else return isValid
    }

    const enterCoordinates = () => {
        let featureType;
        let coordinates;
        let coords = getTransformedCoords();
        if (coords) {
            const index = site.locations.findIndex(x => x.id === locationId);

            if (location.type === 'area') {
                featureType = 'Polygon';
                coordinates = [];
                let polygonCordinates = coords;
                polygonCordinates.push(polygonCordinates[0]);
                coordinates.push(polygonCordinates);
            } else if (location.type === 'path') {
                featureType = 'LineString';
                coordinates = coords;
            }
            let feature = {
                id: uniqid(),
                type: 'Feature',
                properties: { style: { color: '#A82829' } },
                geometry: {
                    type: featureType,
                    coordinates: coordinates
                }
            }
            site.locations[index].geojson.features.push(feature);
            setSaved(false);
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
            showCoordinatesModal(false);
            setMapKey(uniqid());
        } else {
            toast.error('Invalid Coordinates')
        }
    }

    const findCoordinatesbyId = (id) => {
        const index = site.locations.findIndex(x => x.id === locationId);
        if (index >= 0)
            return site.locations[index].geojson.features.filter(x => x.id === id)[0].geometry.coordinates
    }

    const updateCoordinates = () => {
        let coords = getTransformedCoords();
        if (coords) {
            const index = site.locations.findIndex(x => x.id === locationId);
            if (mapPolygonsRef.current && mapPolygonsRef.current.id && index >= 0) {
                site.locations[index].geojson.features.filter(x => x.id === mapPolygonsRef.current.id)[0].geometry.coordinates = [coords];
            }

            if (mapPolylinesRef.current && mapPolylinesRef.current.id && index >= 0) {
                site.locations[index].geojson.features.filter(x => x.id === mapPolylinesRef.current.id)[0].geometry.coordinates = coords
            }
            setLatLng(coords);
            dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
            showCoordinatesModal(false);
            setMapKey(uniqid());
        } else {
            toast.error('Invalid Coordinates')
        }
    }

    const handleShapeFile = (event) => {
        const index = site.locations.findIndex(x => x.id === locationId);
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            var reader = new FileReader();
            var onload = function (event) {
                let geoJsonData = JSON.parse(reader.result);
                let isPresent = false;
                geoJsonData.features.forEach(feature => {
                    feature.id = uniqid();
                    site.locations[index].geojson.features.push(feature)
                    let type = feature.geometry.type;
                    if (type === 'MultiPolygon') {
                        isPresent = type
                    }
                })
                if (isPresent) {
                    toast.warning(`Feauture of ${isPresent} has been added`)
                }
            };
            reader.onload = onload;
            reader.readAsText(file);
            reader.addEventListener('progress', (event) => {
                if (event.loaded === event.total) {
                    updateFileData();
                }
            });
        }
    };

    const updateFileData = () => {
        dispatch({ type: 'entity', payload: { ...site, locations: site.locations } });
        setMapKey(uniqid());
        setSaved(false);
    }

    const handleClose = () => {
        if (!isSaved) {
            swal({
                title: `Close Map`,
                text: `Do you want to save your work ?`,
                buttons: ['No', 'Yes'],
                icon: 'warning',
                dangerMode: true,
                closeOnClickOutside: false,
            }).then(async (status) => {
                if (status) {
                    await handleSave()
                    window.location = `${prevUrl}`;

                }
                else {
                    let data = await _API[props.match.params.type].getById(site.id)
                    dispatch({ type: 'entity', payload: data });
                    window.location = `${prevUrl}`;
                }
            });
        } else {
            window.location = `${prevUrl}`
        }
    }

    const formatCoordinates = (coords) => {
        let convertedCoords = ''
        coords?.length > 0 && coords.forEach(point => {
            convertedCoords = convertedCoords + `${point};`
        })
        return convertedCoords;
    }

    const changeLocation = (item) => {
        setLocationId(item.value);
        setSelectedLocation(item);
        showDeleteButton(false);
        if (mapPolygonsRef.current && mapPolygonsRef.current.id) {
            mapPolygonsRef.current.setEditable(false);
            mapPolygonsRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolygonsRef.current = null;
        }
        if (mapPolylinesRef.current && mapPolylinesRef.current.id) {
            mapPolylinesRef.current.setEditable(false);
            mapPolylinesRef.current.setOptions({ strokeColor: '#86E8FF', fillColor: '#86E8FF' });
            mapPolylinesRef.current = null;
        }
    }

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row map-editor-header'>
                    <div className='col'>
                        <strong>
                            {selectedlocation?.label} - Map Editor
                        </strong>
                    </div>
                    <div className='col-2'>
                        <Select styles={customSelectStyle} options={locationlist} value={selectedlocation} onChange={(item) => changeLocation(item)} />
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            {showDelete && <Button size='md' label='Delete' onClick={handleDelete} />}
                            {drawingEnabled && location?.type === 'path' ? (<Button size='md' label='Complete' color='danger' onClick={completeDraw} />) : (<></>)}
                            {location?.type !== 'site' && <Button size='md' label='Draw' disabled={drawingEnabled} onClick={enableDraw} />}
                            {location?.type !== 'site' && <Button size='md' label='Enter Coordinates' onClick={() => { setText(formatCoordinates(latlng)); showCoordinatesModal(true) }} />}
                            <input type='file' style={{ display: 'none' }} accept='.json,.geojson' ref={uploadShapeFileRef} onChange={handleShapeFile} />
                            <Button size='md' label='Upload GeoJson File' onClick={() => uploadShapeFileRef.current.click()} />
                            <Button size='md' label='Save' color='danger' onClick={() => { completeDraw(); handleSave(); }} />
                            <Button size='md' icon='close' onClick={() => { completeDraw(); handleClose(); }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Map Manual Coordinates */}
            <Modal show={coordinatesModal} size='l' centered>
                <Modal.Header>
                    <Modal.Title>Enter Coordinates</Modal.Title>
                    <Button size='sm' icon='close' onClick={() => { showCoordinatesModal(false) }} />
                </Modal.Header>
                <Modal.Body>
                    <textarea className='form-control' rows={4}
                        placeholder='Enter coordinates in long, lat; format Example: 12.3456, 17.086;'
                        // onChange={(e) => handleLatLngChange(e)} value={formatCoordinates(latlng) || ''} />
                        onChange={(e) => setText(e.target.value)} value={coord_text} />
                    <div className='mt-2'>
                        <Button label='Update Location' color='danger' onClick={() => { if (showDelete || (mapPolylinesRef.current && mapPolylinesRef.current.id) || (mapPolygonsRef.current && mapPolygonsRef.current.id)) updateCoordinates(); else enterCoordinates() }} />
                    </div>
                </Modal.Body>
            </Modal>

            <div className='cnow-mapView-container text-center'>
                <GoogleMapReact
                    key={mapkey}
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_MAP_KEY,
                        libraries: 'drawing,geometry'
                    }}
                    center={center}
                    zoom={13}
                    defaultZoom={13}
                    options={{ mapId: "1f81af8398de79dd" }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={handleMapLoad}
                />
            </div>
        </div >
    )
}

export default MapEditor; 