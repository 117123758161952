import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import PageTitle from '../../components/pageTitle';
import PeopleEditor from '../../components/editors/peopleEditor';
import RelatedPeople from '../../components/editors/relatedPeopleEditor';

const MapPeople = (props) => {
  const map = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={map?.name} />
      <PeopleEditor id={props.id} type='map' key = {`people-editor-${key}`} refresh={() => { setKey(uniqid()) }} title={'People/Organization/Publication'} />
      <RelatedPeople id={props.id} type='map' key={`related-people-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default MapPeople;