import React from 'react';
import { getCategoryName } from '../../helpers';
const TagList = ({ tags, themes, categories, entity }) => {
    let items = [];

    if (categories?.length > 0) {
        categories.forEach(item => {
            if (item?.category_name)
                if (!items.includes(item.category_name))
                    items.push(item.category_name);

            if (item?.subcategory_name)
                if (!items.includes(item.subcategory_name))
                    items.push(item.subcategory_name);

            if (item?.program_name)
                if (!items.includes(item.program_name))
                    items.push(item.program_name);

        })
        if (entity !== 'playlist' && entity !== 'event') {
            let index = items.findIndex((elem) => elem === getCategoryName(categories))
            items.splice(index, 1)
        }
    }

    if (themes?.length > 0)
        themes.forEach(item => {
            if (!items.includes(item?.name) && item?.name) items.push(item?.name)
            if (!items.includes(item?.theme_name) && item?.theme_name) items.push(item?.theme_name)
        })

    if (tags?.length > 0)
        tags.forEach(item => {
            if (!items.includes(item?.tag_name))
                items.push(item?.tag_name)
        })
    return (
        <>
            {
                items && items.length > 0 && <>
                    <hr />
                    <ul className='list-inline text-muted'>
                        {items.map((item, index) => <li key={index} className='list-inline-item'>#{item}</li>)}
                    </ul>
                </>
            }

        </>
    )
}

export default TagList;