/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Theme from '../../components/theme';
import Category from '../../components/category';

const PlaylistTitle = (props) => {
  const dispatch = useDispatch();
  const playlist = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  const handleInputChange = ({ target: { name, value } }) => {
    playlist[name] = value;
    dispatch({ type: 'entity', payload: { ...playlist, [name]: value } });
    setKey(uniqid())
  };

  return (
    <>
      <div className='form-group'>
        <div className='cnow-form-title'>Title <span className='text-cn-warning'>*</span> </div>
        <input name='name' type='text' className='form-control' placeholder='e.g. TWA Flight Center' value={playlist?.name || ''} onChange={handleInputChange} autoComplete="off" />
      </div>
      <Category id={playlist.id} key={`playlist-category-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'collection_category'} type={'playlist'} disableButton={true} />
      <Theme id={playlist.id} key={`playlist-theme-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'collection_theme'} type={'playlist'} />
    </>
  );
};

export default PlaylistTitle;