import React from "react";
import FeaturedSites from "./sites";
import FeaturedPeople from "./people";
import FeaturedMap from "./map";
import FeaturedTour from "./tour";
import FeaturedImages from "./image";
import FeaturedPodcast from "./podcast";
import FeaturedEvent from "./event";
import FeaturedPlaylist from "./playlist";
import FeaturedEventSeries from "./eventSeries";
import FeaturedRecipe from "./recipe";
import FeaturedOnThisDay from "./onThisDay";
const FeatureSearchList = (props) => {
    const section = props.match.params.section
    const entity = props.match.params.entity
    return (
        <>
            {entity === 'site' && <FeaturedSites entity={entity} section={section} />}
            {entity === 'person' && <FeaturedPeople entity={entity} section={section} />}
            {entity === 'map' && <FeaturedMap entity={entity} section={section} />}
            {entity === 'podcast' && <FeaturedPodcast entity={entity} section={section} />}
            {entity === 'tour' && <FeaturedTour entity={entity} section={section} />}
            {entity === 'event' && <FeaturedEvent entity={entity} section={section} />}
            {entity === 'playlist' && <FeaturedPlaylist entity={entity} section={section} />}
            {entity === 'image' && <FeaturedImages entity={entity} section={section} />}
            {entity === 'event_series' && <FeaturedEventSeries entity={entity} section={section} />}
            {entity === 'recipe' && <FeaturedRecipe entity={entity} section={section} />}
            {entity === 'onThisDay' && <FeaturedOnThisDay entity={entity} section={section} />}
        </>
    )
}
export default FeatureSearchList      