import React , {useState} from "react";
import { useSelector } from "react-redux";
import uniqid from 'uniqid';
import PageTitle from "../../components/pageTitle";
import RelatedPlaylist from "../../components/editors/relatedPlaylist";
import PodcastEditor from "../../components/editors/podcastEditor";

const SitePodcast = (props) => {
  const site = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (<>
    <PageTitle title={site?.name} />
    <hr className='cnow-hr' />
    <PodcastEditor id={props.id} type="site" key={`podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    <RelatedPlaylist id={props.id} type="site" key={`related-podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
  </>);
};

export default SitePodcast;
