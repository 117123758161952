/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/buttons/default';
import Spinner from '../../../components/spinner';
import StaticPages from "./index";
// import JSON from './supporters.json'
const _api = require('../../../api');

const StaticPageEditor = (props) => {
	const [menu, setMenu] = useState({ page: (props.match.params.page || 'board_staff').replace(/_/g, ' & '), mainMenu: '', subMenu: '' })
	const [spinner, setSpinner] = useState(false);
	const [menuSize, setMenuSize] = useState('lg');
	const [previewSize, setPreviewSize] = useState('lg');

	const [JSONData, setJSONData] = useState({})
	const [JSONID, setJSONID] = useState()

	const toggleMenuSize = () => setMenuSize(menuSize === 'sm' ? 'lg' : 'sm')
	const togglePreviewSize = () => setPreviewSize(previewSize === 'sm' ? 'lg' : 'sm')

	const selectItem = (mainMenu, subMenu) => setMenu((prev => ({ ...prev, mainMenu: mainMenu || '', subMenu: subMenu || '' })))
	const sideMenuHeader = 'ADD ' + (menu?.page || '').replace(/_/g, ' & ').toUpperCase();

	useEffect(() => {
		setMenu((prev => ({ ...prev, page: (props.match.params.page || 'board_staff').replace(/_/g, ' & '), mainMenu: '', subMenu: '' })))
		fetchData();
	}, [props.match.params])

	const fetchData = async () => {
		try {
			setSpinner(true)
			const result = await _api.static_pages.getByList(props.match.params.page|| 'board_staff');
			setJSONData(result.data)
			setJSONID(result.id)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		finally {
			setSpinner(false)
		}
	};

	const updateItem = async () => {
		const params = { "id": JSONID, "list": props.match.params.page, "data": JSONData }
		try {
			setSpinner(true)
			const result = await _api.static_pages.saveItems(params);
			setJSONData(result.data)
			toast.success('Data saved successfully')
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		finally {
			setSpinner(false)
		}
	}



	return (
		<Spinner display={spinner}>
			{!isEmpty(JSONData)
				? <div className='cnow-editor-container static-page-container'>
					<div className={`cnow-editor-menu cnow-editor-menu-${menuSize} px-0`}>
						{menuSize === 'sm' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />}
						{
							menuSize === 'lg' && <>
								<div className='top-header'>
									<div>{sideMenuHeader}</div>
									<Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
								</div>
								<ul className='list-group mt-3'>
									{
										Object.entries(JSONData).filter(([key, value]) => typeof value !== 'string').map(([key, value], index) => {
											const subItems = (typeof value === 'object' && !Array.isArray(value))
												? Object.entries(value)
													.map(([subKey]) => <p className={menu?.subMenu === subKey ? 'active' : ''} key={subKey} onClick={() => selectItem(key, subKey)} > {subKey} </p>)
													.filter(Boolean)
												: [];
											return (
												<div key={index} className='static-side-menu'>
													<li className={menu?.mainMenu === key ? 'active' : ''} onClick={() => selectItem(key, '')}> {key} </li>
													{subItems}
												</div>
											);
										})
									}
								</ul>
							</>
						}
					</div>
					<div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize} p-0`}>
						<StaticPages menu={menu} setMenu={setMenu} setJSONData={setJSONData} JSONData={JSONData} />
					</div>
					<div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
						{
							previewSize === 'sm' && <div className='btn-grid'>
								<Button size='sm' color='danger' icon='save-white' onClick={() => updateItem()} />
								{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
								{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
								<Button size='sm' color='light' icon='help' />
							</div>
						}
						{
							previewSize === 'lg' && <div className='btn-list action-bar'>
								<Button size='sm' label='INSTRUCTIONS' />
								<Button size='sm' color='danger' label='NOT LIVE' />
								<Button size='sm' color='danger' label='SAVE' onClick={() => updateItem()} />
								{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
								{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
							</div>
						}
					</div>
				</div>
				: <div className='data-health-card-group'>
					<header className='text-red'>Something went wrong!</header>
				</div>
			}
		</Spinner >

	);
};

export default StaticPageEditor;
