import React from 'react';
import { dateString } from '../../helpers';
const Category = ({ categories }) => {
	const getCategoryName = () => {
		let category = '';
		if (categories && categories?.length > 0) {
			if (categories[0]?.category_name) category = categories[0]?.category_name;
			if (categories[0]?.subcategory_name) category = categories[0]?.subcategory_name;
			if (categories[0]?.program_name) category = categories[0]?.program_name;
			return category
		}
		let index = categories?.length > 0 && categories.findIndex((category) => { return dateString(category) !== '' })
		if (index !== -1 && categories?.length > 0) {
			if (categories[index]?.category_name) category = categories[index]?.category_name;
			if (categories[index]?.subcategory_name) category = categories[index]?.subcategory_name;
			if (categories[index]?.program_name) category = categories[index]?.program_name;
			return category
		}
		return category
	}
	return (
		<div className='small text-muted'>{getCategoryName()}</div>
	)
}

export default Category;