import React from 'react';

const PersonDesignationList = ({ data }) => {
    return (
        <>
            {
                data && data.length > 0 && <span className='list-inline fw-normal text-muted ms-2 mb-0 align-self-bottom'>
                    {
                        data.map((item, index) => {
                            return <span key={index} className='list-inline-item' style={{ color: '#707070' }}>{item?.designation_name}</span>
                        })
                    }
                </span>
            }
        </>
    )
}

export default PersonDesignationList;