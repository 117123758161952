/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Button from '../../components/buttons/default';
import GridImageList from '../../components/gridImageList';

const _api = require('../../api')

const Banner = (props) => {
    const [entity, setEntity] = useState([])
    const [relatedImages, setRelatedImages] = useState([])
    const podcast = useSelector((state) => state[props.id]);
    const [selectedImage, setSelectedImage] = useState(podcast?.image  || entity?.url || (entity?.images && entity.images?.length > 0 ? entity.images[0]?.url : []))
    useEffect(() => {
        let type = props.entityType
        if (props.type === 'podcast') {
            let relatedItemsImage = []
            for (let i in podcast.related_items) {
                let item = podcast.related_items[i].images?.filter(x => x.type !== 'then_now')
                relatedItemsImage = [].concat(item).concat(relatedItemsImage)
            }
            setRelatedImages(relatedItemsImage)
        } else {
            _api[type].getById(props.entityId).then((res) => setEntity(res))
        }
    }, [podcast?.related_items[podcast?.related_items?.length - 1]?.id, podcast?.related_items?.length])

    const handleSave = async () => {
        props.handleSave(selectedImage)
        if (props.onHide) props.onHide()
    }

    const handleSelectBannerImage = async (id) => {
        let newBannerImage = []
        newBannerImage = await _api.image.getById(id)
        setSelectedImage(newBannerImage)
    }

    return (
        <>
            <Modal show={props.show} fullscreen={props.fullscreen} size='xl' onHide={() => { props.onHide(false) }} backdrop='static' >
                <Modal.Body>
                    <div className='banner-head'>
                        <div className='d-flex justify-content-between align-items-end mb-2' >
                            <div className='cnow-form-title'>SET IMAGE</div>
                            <div className='d-flex gap-2'>
                                <Button size='sm' label='Save' color='danger' onClick={() => { handleSave() }} />
                                <Button size='sm' icon='close' target='_self' onClick={() => props.onHide(false)} />
                            </div>
                        </div>
                        <hr className='cnow-hr mb-2' />
                    </div>
                    <GridImageList
                        onSelect={(id) => handleSelectBannerImage((id))}
                        items={relatedImages?.length > 0 ? relatedImages : []}
                        image={selectedImage}
                        type='banner'
                        bannerImageId={entity?.image_id}
                    />
                </Modal.Body>
            </Modal>
        </>

    )
}

export default Banner