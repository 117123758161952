import React from "react";
import FirstPage from "./pdfFirstPagePodcast";
import PDFImage from "./pdfImage";

const PodcastPdfTemplate = ({ data, enity_title, id }) => {
    return (
        <div className="row" key={id}>
            <div className="col">
                <div>
                    <div className="row pb-2 d-flex align-items-start">
                        <div className="col-4"><PDFImage src='/img/logo/cnow_light_logo.png' className='img-fluid' /></div>
                        <div className="col-5 fw-bold" style={{ color: '#63509f' }}>
                            <div>37-24 24th Street, Suite 102</div>
                            <div>Long Island City, NY 11101</div>
                            <div className="pt-2"> 718.706.0900</div>
                            <div>info@culturenow.org </div>
                        </div>
                    </div>
                    <FirstPage />
                </div>
                {/* <div className="position-relative">
                                {elem?.podcast_image_url && <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                    {elem.podcast_type === 'audio' ? <img src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%' }} alt="playlist" />
                                        : <img src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%' }} alt="playlist" />}
                                    <PDFImage src={elem?.podcast_image_url} className='thumb' imageType={'thumb'} key={elem?.id} />
                                </div>}
                 </div> */}
                <div className="py-3 fw-bold">This release form is applicable for the use of following media:</div>
                {
                    data && data?.length > 0 && data.map((elem, index) => {
                        return <div className="d-flex justify-content-start align-items-top py-3">
                            <PDFImage src={elem?.podcast_image_url} className='thumb' imageType={'thumb'} key={elem?.id} />
                            <div>
                                <div className="ms-4">{elem?.podcast_name}</div>
                                <div className="text-capitalize ms-4 py-1"> Type : {elem?.podcast_type} </div>
                            </div>
                        </div>
                    })
                }
                <div className="pb-3">
                    <div className="fw-bold">Non Exclusive and Exclusive Permission License and Release.</div>
                    <p>Nothing in this grant of permission, license to use, or release of copyright of the Released Podcasts / Videos shall be deemed to grant an exclusive right to use the Released media unless negotiated by CULTURE NOW with the Owner of the Podcast / Video /Copyright for a set period of time, or if no set period of time is stated, then for an unlimited period of time, and signed and dated by the parties to this Agreement attached hereto as an Exclusivity Clause.</p>
                </div>

                <div className="pb-3">
                    <div className="fw-bold">Credit</div>
                    <p>cultureNOW will include credit and any copyright on media that it publishes and will include this information with all material distributed to other media or retained in its library archives. cultureNOW will not be responsible for failure of any other person or organization to include this information in their publicity.</p>
                </div>

                <div className="pb-3">
                    <div className="fw-bold">Copyright</div>
                    <p>If materials submitted are copyrighted, the copyright notice must be made available to cultureNOW and can be uploaded here.</p>
                </div>

                <div className="pb-3">
                    <div className="fw-bold">Compensation</div>
                    <p>cultureNOW will not be required to pay royalties, make any other payment, or provide other consideration of any type. No Financial Remuneration will be received for the license, use, reproduction and release of the media.</p>
                </div>
                <div>
                    <p className="py-2 fw-bold">Speaker Name : </p>
                    <p className="py-2 fw-bold">Signature : </p>
                    <p className="py-2 fw-bold">Date : </p>
                </div>
            </div>
        </div>
    )
}

export default PodcastPdfTemplate