
import { ApiCall } from './utils'

//map_overlay
const getById = (id) => ApiCall('get', `/map_overlay/${id}`)

const search = (params) => ApiCall('post', `/map_overlay/search`, params)
const searchByElastic = (from, size, params) => ApiCall('post', `/search/map_overlay/${from}/${size}`, params)

const update = (params) => ApiCall('post', `/map_overlay`, params)

const deleteById = (id) => ApiCall('delete', `/map_overlay/${id}`)

//overlay items
const getOverlayLinks = (id) => ApiCall('get', `/map_overlay/${id}/item`)

const deleteMapOverlays = (id) => ApiCall('delete', `/map_overlay/item/${id}`)

const updateOverlayLinks = (id, params) => ApiCall('post', `/map_overlay/${id}/item`, params)

//maps
const getRelatedMaps = (id) => ApiCall('get', `/map_overlay/${id}/map`)

//location
const getLocation = (id) => ApiCall('get', `/map_overlay/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/map_overlay/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/map_overlay/location/${id}`)

export default {
    getById,
    search,
    searchByElastic,
    update,
    deleteById,

    getOverlayLinks,
    deleteMapOverlays,
    updateOverlayLinks,

    getLocation,
    updateLocation,
    deleteLocation,

    getRelatedMaps
}