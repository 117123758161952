import { ApiCall } from './utils'

const getUserById = (id) => ApiCall('get', `/user/${id}`)
const getPaymentDetailsById = (id) => ApiCall('get', `/payment/bills/${id}`);
const search = (params) => ApiCall('post', `/user/search`, params)
const searchByElastic = (from, size, params) => ApiCall('post', `/search/user/${from}/${size}`, params)
const deleteById = (id) => ApiCall('delete', `/user/${id}`);
const updateUserById = (id, params) => ApiCall('post', `/user/${id}`, params)
const savedContent = (id) => ApiCall('get', `/user/saved_content/${id}`)
const resetPassword = (params) => ApiCall('post', `/email/forgot-password`, params)
const emailInvite = (params) => ApiCall('post', `/email/invite`, params)
const emailInviteDelete = (id) => ApiCall('delete', `/user/invite/${id}`)


export default {
    getUserById,
    getPaymentDetailsById,
    search,
    searchByElastic,
    deleteById,
    updateUserById,
    savedContent,
    resetPassword,
    emailInvite,
    emailInviteDelete
}