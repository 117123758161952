import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StoryEditor from '../../components/editors/storyEditor';
import PageTitle from '../../components/pageTitle';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import uniqid from 'uniqid'
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';

const TourDescription = (props) => {
    const tour = useSelector(state => state[props.id]);
    // eslint-disable-next-line
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={tour?.name} />
            <DescriptionEditor id={props.id} type='tour' refresh={() => setKey(uniqid())} label={'About'} />
            <TagEditor id={props.id} type='tour' />
            <StoryEditor key={`story-editor-${key}`} id={props.id} type='tour' refresh={() => { setKey(uniqid()) }} />
            <RelatedSiteEditor key={`relatedsite-editor-${key}`} id={props.id} type='tour' refresh={() => { setKey(uniqid()) }} />
        </>
    );
};

export default TourDescription;