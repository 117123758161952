import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import EventPeopleEditor from '../../components/eventPeopleEditor';
import RelatedPeople from '../../components/editors/relatedPeopleEditor';

const EventSeriesPeople = (props) => {
    const event = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={event?.name} />
            <EventPeopleEditor id={event?.id} type='event_series' section='hosts' key={`host-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Host *' deleteLabel={'deleteHost'} />
            <RelatedPeople id={props.id} type='event_series' key={`related-person-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
        </>
    );
};

export default EventSeriesPeople;