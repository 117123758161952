import React from 'react'
import ImageViewer from '../viewers/image'
import Icon from '../viewers/icon'

export const EventAudio = ({ data ,type }) => {
    return (
        <div>
            <div className='container ps-0'>
                <div className='row mt-4'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 '>
                        <div className="row m-0 p-0" style={{ backgroundColor: "#ededed" }}>
                            <div className="col-6  m-0 p-0">
                                <div className='position-relative event-image-opacity'>
                                    <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute' >
                                        <div className="overlay-test"></div>
                                        <button type="button" className="play-button z-3" style={{ zIndex: "99" }} ><Icon name={"play-round-black"} size={25} /></button>
                                    </div> <ImageViewer url={data?.podcast_image_url} type='thumb@2x' cssClass={"thumb"} /> </div>
                            </div>
                            <div className='col-6  m-0 p-0 cnow-preview-title'> <span className='fw-bold d-flex justify-content-center'>{data?.name}</span> </div>
                        </div>
                    </div>
                 { type==="event" && <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                        <div className="d-flex flex-column h-100 mapViews-overlays-scrollBar" style={{ overflow: 'auto', maxHeight: '70px' }}>
                            <div className=" d-block">
                                <div className='text-muted' style={{ display: "-webkit-inline-box !important" }}>
                                    {data?.credits?.map((item) => {
                                        return (
                                            <div>
                                                <span>{item?.person_name && `${item?.person_name}, `}</span>
                                                <span>{item?.role_name && `${item?.role_name}, `}</span>
                                                {item?.affiliation?.filter(item => item.related_person_type !== "person")?.slice(0, 1)?.map(item => { return (<span>{item?.related_person_name}</span>) })}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div ><span className='cnow-ellipsis-5' dangerouslySetInnerHTML={{ __html: data?.description }} /></div>
                            </div>
                            <div className="d-flex align-items-end h-100"> <div className='text-muted'>{data?.duration}</div></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

const EventVideo = ({ data }) => {
    return (
        <div>
            <div className='container ps-0'>
                <div className='row mt-4'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                        <div className='position-relative event-image-opacity'>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                <button type="button" className="play-button" style={{ zIndex: "99" }} >   <Icon name={"play-round-black"} size={35} />  </button>
                            </div>
                            <ImageViewer url={data?.podcast_image_url} type='thumb' cssClass='podcast-thumb' />
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                        <div className="d-flex flex-column h-100" >
                            <div className="">
                                <span className='fw-bold d-block'>{data?.name}</span>
                                <div className='text-muted'>
                                    {data?.credits?.map((item) => {
                                        return (
                                            <div>
                                                <span>{item?.person_name && `${item?.person_name}, `}</span>
                                                <span>{item?.role_name && `${item?.role_name}, `}</span>
                                                {item?.affiliation?.filter(item => item.related_person_type !== "person")?.slice(0, 1)?.map(item => { return (<span>{item?.related_person_name}</span>) })}
                                            </div>)
                                    })}
                                </div>
                                <div><span className='cnow-ellipsis-12' dangerouslySetInnerHTML={{ __html: data?.description }} /></div>
                            </div>
                            <div className="d-flex align-items-end h-100"><div className='text-muted'>{data?.duration} </div> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EventPodcast = (props) => {
    return (
        <div>
            <div className='cnow-form-title '>The Event</div>
            {props.data?.filter(x => x.type === "event_trailer_recording")?.map(item => {
                return (
                    <>
                        {item.podcast_type === "video" ? <EventVideo data={item} /> : <EventAudio data={item} type={"event"} />}
                    </>
                )
            })}
        </div>
    )
}
export default EventPodcast