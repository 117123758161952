import React from 'react';
import ImageViewer from '../viewers/image';

const StaticImageCard = (props) => {
  const data = props.data
  return (
    <div className={`${props.cssClass}`}>
      <div className="card h-100 small bg-light border-0"  style={{ width: '200px'}}>
        <div onClick={() => {}} className="cursor-pointer">
          <ImageViewer url={data?.image?.url || data?.url} type="thumb@2x" cssClass="thumb-2x" />
        </div>
        </div>     
    </div>
  )
}

export default StaticImageCard