/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import uniqid from 'uniqid'

import Icon from '../viewers/icon';
import GoogleMapReact from 'google-map-react';
import { time_convert } from '../../helpers';

const TourMap = (props) => {

    const dispatch = useDispatch()
    const tour = useSelector(state => state[props.id]);

    const [center] = useState({ lat: 40.730610, lng: -98.6273925 }); //United States
    const [google, setGoogle] = useState();
    const [mapKey, refreshMap] = useState(uniqid())

    const mapRef = useRef();
    const column = props.column || 'flex-column';
    const height = props.height || ''

    // const lineStyle = {
    //     path: 'M 0,-1 0,1',
    //     strokeOpacity: 1,
    //     strokeWeight: 2,
    //     scale: 4.5,
    // };

    // const RouteLineStyle = {
    //     strokeColor: '#A82829', fillColor: '#A82829', icons: [
    //         {
    //             icon: lineStyle,
    //             offset: '0',
    //             repeat: '20px',
    //         },], strokeWeight: 2, strokeOpacity: 0 // can mark zero - for dotted lines
    // };

    const setBounds = (google) => {
        var bounds = new google.maps.LatLngBounds();
        let markerCount = 0;
        tour?.stops?.length > 0 && tour.stops.forEach((stop) => {
            if (stop.location?.location?.lat && stop.location?.location?.lng)
                bounds.extend(stop.location.location)
            markerCount = markerCount + 1;
            stop.sites?.length > 0 && stop.sites.forEach((site) => {
                markerCount = markerCount + 1;
                if (site.location?.location?.lat && site.location?.location?.lng)
                    bounds.extend(site.location.location)
            })
        })
        if (tour?.stops?.length > 0) mapRef.current.fitBounds(bounds);
        if (markerCount === 1) mapRef.current.setZoom(16);
    }

    const handleMapLoad = (google) => {

        mapRef.current = google.map;
        mapRef.current.setOptions({
            'zoomControlOptions': { 'position': google.maps.ControlPosition.RIGHT_BOTTOM },
            'controlSize': 24,
            'minZoom': 0
        })
        setGoogle(google);
        setBounds(google);
        if (props.showPath && tour.route) {
            let route = JSON.parse(tour.route)
            route.forEach(line => {
                let linepath = new google.maps.geometry.encoding.decodePath(line)
                const polyline = new google.maps.Polyline({
                    path:  linepath, 
                    strokeColor: '#86E8FF', 
                    strokeWeight: 5, 
                    strokeOpacity: 1 
                });
                    polyline.setMap(mapRef.current);
            })
        } else if (props.showPath && tour.type) {
            showRoutes(new google.maps.DirectionsService(), new google.maps.DirectionsRenderer({
                map: mapRef.current,
                polylineOptions: { strokeColor: '#A82829' },
                suppressInfoWindows: false,
                suppressMarkers: true,
                suppressBicyclingLayer: true
            }), google)
        }
    }

    const handleMapRedirect = () => {

    }

    const MarkerIcon = ({ number, colour, fill }) => ((number.toString().length) > 1 ? (
        <svg id="Component_131_14" data-name="Component 131 – 14" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
            <g id="Path_583" data-name="Path 583" fill="#fff">
                <path d="M 14 26 C 10.79467964172363 26 7.781219959259033 24.75177955627441 5.51471996307373 22.48527908325195 C 3.248219966888428 20.21878051757812 2 17.20532035827637 2 14 C 2 10.79467964172363 3.248219966888428 7.781219959259033 5.51471996307373 5.51471996307373 C 7.781219959259033 3.248219966888428 10.79467964172363 2 14 2 C 17.20532035827637 2 20.21878051757812 3.248219966888428 22.48527908325195 5.51471996307373 C 24.75177955627441 7.781219959259033 26 10.79467964172363 26 14 C 26 17.20532035827637 24.75177955627441 20.21878051757812 22.48527908325195 22.48527908325195 C 20.21878051757812 24.75177955627441 17.20532035827637 26 14 26 Z" stroke="none" fill={fill ? colour : null} />
                <path d="M 14 4 C 8.485979080200195 4 4 8.485979080200195 4 14 C 4 19.51401901245117 8.485979080200195 24 14 24 C 19.51401901245117 24 24 19.51401901245117 24 14 C 24 8.485979080200195 19.51401901245117 4 14 4 M 14 0 C 21.73197937011719 0 28 6.268009185791016 28 14 C 28 21.73197937011719 21.73197937011719 28 14 28 C 6.268009185791016 28 0 21.73197937011719 0 14 C 0 6.268009185791016 6.268009185791016 0 14 0 Z" stroke="none" fill={colour} />
            </g>
            <text y="18" x="7" fill="#000">{number}</text><path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
        </svg>) : (
        <svg id="Component_131_14" data-name="Component 131 – 14" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
            <g id="Path_583" data-name="Path 583" fill="#fff">
                <path d="M 14 26 C 10.79467964172363 26 7.781219959259033 24.75177955627441 5.51471996307373 22.48527908325195 C 3.248219966888428 20.21878051757812 2 17.20532035827637 2 14 C 2 10.79467964172363 3.248219966888428 7.781219959259033 5.51471996307373 5.51471996307373 C 7.781219959259033 3.248219966888428 10.79467964172363 2 14 2 C 17.20532035827637 2 20.21878051757812 3.248219966888428 22.48527908325195 5.51471996307373 C 24.75177955627441 7.781219959259033 26 10.79467964172363 26 14 C 26 17.20532035827637 24.75177955627441 20.21878051757812 22.48527908325195 22.48527908325195 C 20.21878051757812 24.75177955627441 17.20532035827637 26 14 26 Z" stroke="none" fill={fill ? colour : null} />
                <path d="M 14 4 C 8.485979080200195 4 4 8.485979080200195 4 14 C 4 19.51401901245117 8.485979080200195 24 14 24 C 19.51401901245117 24 24 19.51401901245117 24 14 C 24 8.485979080200195 19.51401901245117 4 14 4 M 14 0 C 21.73197937011719 0 28 6.268009185791016 28 14 C 28 21.73197937011719 21.73197937011719 28 14 28 C 6.268009185791016 28 0 21.73197937011719 0 14 C 0 6.268009185791016 6.268009185791016 0 14 0 Z" stroke="none" fill={colour} />
            </g>
            <text y="18" x="10" fill="#000">{number}</text>
            <path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
        </svg>))

    const Marker = ({ stop, site, type, number }) => {
        let colour = true ? '#86E8FF' : '#a82829';
        return (
            <div className='cnow-marker'>
                <MarkerIcon colour={colour} number={number} fill={type === 'stop' || tour.format === 1} />
            </div>
        )
    }

    const showRoutes = (directionsService, directionsRenderer, google) => {
        let stops = [];
        
            tour.stops?.length > 0 && tour.stops.forEach((stop, index) => {
                if (index > 0 && index < tour.stops.length - 1 && stop.location.location?.lat && stop.location.location?.lng)
                    stops.push({ location: stop.location.location, stopover: true })
            })

        let request = {
            origin: tour.stops[0]?.location.location,
            destination: tour.stops[tour.stops.length - 1]?.location.location,
            waypoints: stops,
            optimizeWaypoints: true,
            travelMode: tour.type
        };

        directionsService.route(request, function (response, status) {
            if (status === 'OK') {
                let totalTime = 0;
                let polylineList = [];
                directionsRenderer.setDirections(response);
                response.routes[0].legs.forEach(leg => {
                    totalTime = totalTime + leg.duration.value;
                    leg.steps?.length > 0 && leg.steps.forEach(step => {
                        polylineList.push(step.polyline.points);
                    })
                });
                if (!tour.duration_override) 
                dispatch({ type: 'entity', payload: { ...tour, duration: time_convert(totalTime),  route: JSON.stringify(polylineList), route_overview: response.routes[0].overview_polyline} });
            }
        })
    }

    useEffect(() => {
        if (google) setBounds(google);
    }, [tour])

    useMemo(() => {
        refreshMap(uniqid());
    }, [tour?.type, tour?.route, tour?.stops])

    return (
        <>
            {
                <>
                    <div className={`d-flex  ${column}`}>
                        <div>
                            <hr />
                            <div className='d-flex cursor-pointer' onClick={() => { handleMapRedirect() }}>
                                <Icon name='map-view' size={31} />
                                <div className='fw-bold fst-italic ms-1 mt-1 text-dark'>Map View</div>
                            </div>
                            <div className='cnow-map-preview mt-2' style={{ height: height }}>
                                <GoogleMapReact
                                    key={mapKey}
                                    center={center}
                                    defaultZoom={3}
                                    options={{ mapId: "1f81af8398de79dd" }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={handleMapLoad}
                                >
                                    {
                                        tour?.format === 2 && tour?.stops?.length > 0 && tour.stops.map((stop) => {
                                            if (stop.location?.location?.lat && stop.location?.location?.lng)
                                                return (
                                                    <Marker
                                                        key={stop.id}
                                                        lat={stop.location.location.lat}
                                                        lng={stop.location.location.lng}
                                                        type={'stop'}
                                                        number={stop.sort}
                                                    />)
                                            else return null;
                                        }
                                        )
                                    }
                                    {
                                        tour?.stops?.length > 0 && tour.stops.map(stop => {
                                            return (
                                                stop.sites?.length > 0 && stop.sites.map((site) => {
                                                    if (site.location?.location?.lat && site.location?.location?.lng)
                                                        return (
                                                            <Marker
                                                                key={site.id}
                                                                lat={site.location.location.lat}
                                                                lng={site.location.location.lng}
                                                                type={'site'}
                                                                number={tour.format === 2 ? `${stop.sort}.${site.sort}` : stop.sort}
                                                            />)
                                                    else return null;
                                                }
                                                )
                                            )
                                        })
                                    }
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default TourMap;