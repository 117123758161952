import React , {useState} from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import PodcastEditor from '../../components/editors/podcastEditor';
import RelatedPlaylist from '../../components/editors/relatedPlaylist';
import PodcastStop from './podcastStop';
const TourPodcast = (props) => {
  const tour = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (<>
    <PageTitle title={tour?.name} />
    <hr className='cnow-hr' />
    <PodcastEditor id={props.id} type='tour' key={`podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    <RelatedPlaylist id={props.id} type='tour' key={`related-podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    <PodcastStop id={props.id} key={`podcast-stop-${key}`}/>
  </>);
};

export default TourPodcast;
