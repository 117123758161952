import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import StoryEditor from '../../components/editors/storyEditor';
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';
import uniqid from 'uniqid'
import DimensionsEditor from '../../components/editors/dimensionsEditor';

const SiteDescription = (props) => {

  const site = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())

  const isPublicArt = () => {
    return site?.categories?.filter(cat => 
      cat?.category_name?.toLowerCase() === 'public art' || cat?.program_name?.toLowerCase() === 'public art' || cat?.subcategory_name?.toLowerCase() === 'public art')?.length > 0;
  }

  return (
    <>
      <PageTitle title={site?.name} />
      {isPublicArt() && <DimensionsEditor id={props.id} />}
      <DescriptionEditor id={props.id} type='site' refresh={() => setKey(uniqid())} label={'Description'} showRemarksAttribution/>
      <TagEditor id={props.id} type='site' />
      <StoryEditor key={`story-editor-${key}`} id={props.id} type='site' refresh={() => { setKey(uniqid()) }} />
      <RelatedSiteEditor key={`relatedsite-editor-${key}`} id={props.id} type='site' refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default SiteDescription;