import React from 'react';

import AsyncSelect from 'react-select/async';
import { customSelectStyle, personName } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';
// import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const _api = require('../../api')

const SelectPeople = (props) => {
	// const user = useSelector(state => state.user)
	let name = personName(props.value)
	let selected = props.value?.id ? {
		'value': props.value.id, 'label': name, 'email': props.value.email, 'role_name': props.value.role_name,
		'role_id': props.value.role_id, 'is_participant': props.value.is_participant
	} : {};

	const items = async (keyword, callback) => {
		if (keyword) {
			let items = [];
			const params = {
				"bool": {
					"must": [
						{ "match": { "deleted": "false" } },
						{ "match": { "created_via": "ADMIN" } },
						{
							"multi_match": {
								"query": keyword,
								"fields": [
									"name^2",
									"full_name^4",
								],
								"fuzziness": "AUTO",
								"prefix_length": 2
							}
						}
					]
				}
			};
			if (props?.entityType) {
				params.bool.must[3] = {
					"bool": {
						"should": [
							{
								"match": {
									"type": props?.entityType
								}
							}
						]
					}
				}
			}
			// params.facetFilters[1] = `type  : ${props?.entityType}` }
			if (props?.facetFiltersMoreThanOne) {
				params.bool.must[3] = {
					"bool": {
						"should": [
							{
								"match": {
									"type": props.entityType
								}
							},
							{
								"match": {
									"type": props.subEntityType
								}
							}
						]
					}
				}

			}
			const finalParams = {
				query: params
			}


			let response = await _api.person.searchByElastic(0, 50, finalParams);
			if (response.hits.hits?.length) {
				response.hits.hits.forEach(data => {
					let item = {
						'value': data._source.id,
						'label': personName(data._source),
						'email': data._source.primary_contact?.email,
						'images': data._source?.images,
						'type': data._source.type,
						'is_participant': data._source.is_participant,
						'vanity_url': data._source?.vanity_url
					}

					if (data._source.type === 'person') {
						item.role_name = data._source.professions?.length > 0 ? data._source.professions[0]?.profession_name : ''
						item.role_id = data._source.professions?.length > 0 ? data._source.professions[0]?.profession_id : null
						item.designations_name = data._source.designations?.length > 0 ? data._source.designations[0]?.designation_name : null

					} else if (data._source.type === 'organization') {
						item.role_name = data._source.organization_types?.length > 0 ? data._source.organization_types[0]?.organization_type_name : ''
						item.role_id = data._source.organization_types?.length > 0 ? data._source.organization_types[0]?.organization_type_id : null
						item.affiliations = data._source?.affiliations?.length > 0 ? data._source?.affiliations : null
					}
					else if (data._source.type === 'publication') {
						item.role_name = data._source.publication_types?.length > 0 ? data._source.publication_types[0]?.publication_type_name : ''
						item.role_id = data._source.publication_types?.length > 0 ? data._source.publication_types[0]?.publication_type_id : null
						item.affiliations = data._source?.affiliations?.length > 0 ? data._source?.affiliations : null
					}
					items.push(item)
				});
			}
			else {
				items.push({ 'value': 'participant', 'label': '+ Add a Participants' })
			}
			callback(items)
			return items;
		}
		else {
			callback([])
			return []
		}
	}

	const handleChange = (e) => {
		if (e.value !== 'participant') {
			let value = { 'id': e.value, 'name': e.label, 'email': e.email, 'role_name': e.role_name, 'role_id': e.role_id, 'type': e.type, 'designations_name': e.designations_name, 'images': e.images, 'is_participant': e.is_participant, 'vanity_url': e.vanity_url, 'affiliations': e.affiliations };
			props.onChange(value);
		}
		else {
			if (e.value === 'participant') window.open('/participant/create')
		}
	}

	const debouncedSuggestions = debounce((keyword, callBack) => {
		items(keyword, callBack)
	}, 500)

	return (
		<AsyncSelect cacheOptions defaultOptions
			styles={customSelectStyle}
			components={{ NoOptionsMessage }}
			loadOptions={debouncedSuggestions}
			value={selected}
			onChange={handleChange}
		/>
	);
};

export default SelectPeople;