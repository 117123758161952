import React from 'react';
import { personName, TABLE_ROWS } from '../../helpers';

const EventSeriesPeopleList = ({ host }) => {
    return (
        <td>
            {
                host?.map((item, index) => {
                    return (
                        <div key={index} className='text-truncate'>
                            <i>Host: </i> {personName(item)}
                        </div>
                    )
                })
            }
            {(host?.length) > TABLE_ROWS && <div className='small text-muted'>+ {(host?.length) - TABLE_ROWS} more</div>}
        </td>
    )
}

export default EventSeriesPeopleList;