import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import LocationEditor from '../../components/editors/locationEditor';

const EventLocation = (props) => {
  const event = useSelector(state => state[props.id]);

  return (
    <>
      <PageTitle title={event?.name} />
      <LocationEditor disabledDateDropdown id={event?.id} type='event' locationId={props?.locationId} onUpdate={(path) => props.onUpdate(path)} showLocationType={false} disableCheckList={true} />
    </>
  );
};

export default EventLocation