import React from "react";
import Button from "../buttons/default";

const PdfPreview = (props) => {
    const handlePreview = () => {
        window.open(props.url)
    }
    return (
        <div className="row py-2">
            <div className="col-auto"><Button size='sm' icon='preview' onClick={() => { handlePreview() }} /></div>
            <div className="col-auto"><Button size='sm' icon='delete' onClick={() => { props.handleDelete(props.id) }} /></div>
            <div className="col fw-bold">Form {props.index + 1} - {props.id} - {new Date().toISOString().split('T')[0]}</div>
        </div>
    )
}

export default PdfPreview