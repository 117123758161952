/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { newTempId } from "../../../helpers";

import Spinner from '../../../components/spinner';
import Button from '../../../components/buttons/default';
import LinkButton from '../../../components/buttons/link';

import Icon from '../../../components/viewers/icon';
import ResultCount from '../../../components/resultCount';
import Pagination from '../../../components/pagination';
import ScrollToTop from '../../../components/scrollToTop';

import { handleApiError } from '../../../helpers';

const _api = require('../../../api');
const globalLists = require('../../../data/global_list.json');
const sortOptions = require('../../../data/sort_global_list.json');

const GlobalListSubItems = (props) => {
    const [list] = useState(globalLists.filter(x => x.id === props.match.params.type)[0]);
    const parentId = props.match.params.id
    const [items, setItems] = useState([]);
    const [count, setItemCount] = useState(0);
    const [keyword, setKeyword] = useState('');

    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(sortOptions[0]);
    const [order] = useState('asc');

    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        getItems();
    }, [page, sort, order]);

    const getItems = () => {
        showSpinner(true);
        _api.global_list.getSubItems(list.id).then(response => {
            const fiteredArray = response.filter((elem) => elem.id === parentId)
            setItems(fiteredArray[0]?.child_awards || fiteredArray[0]?.sub_catgories)
            showSpinner(false);
        }).catch(handleApiError);
        _api.global_list.getCount(list.id, keyword).then(response => {
            setItemCount(response.count)
        }).catch(handleApiError);
    }

    const deleteItem = (item) => {
        swal({
            title: `Delete ${item.name}`,
            text: `Are you sure that you want to delete ${item.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                _api.global_list.deleteById(item.id).then(response => {
                    getItems();
                })
            }
        });
    };
    const addItem = () => {
        if (list.id === 'site_category') {
            _api.global_list.create(list.id, {
                name: 'New Item',
                parent: parentId,
                parent_name: items[0]?.parent_name,
                alias: `${items[0]?.parent_name} New Item`,
                id: newTempId(),
                has_children: false,
                type: list.id
            }).then(response => {
                window.location = `/global-list/${list.id}/${parentId}/${response?.id}/editor`;
            })

        }
        else {

            _api.global_list.create(list.id, {
                name: items[0]?.parent_name,
                parent: null,
                parent_name: null,
                id: items[0]?.parent,
                has_children: false,
                type: list.id
            }
            ).then(response => {
                props.history.push(`/global-list/${list.id}/${response.id}/editor`);
            })
        }
    }



    const handleItemClick = (listId, itemId, parent) => {
        if (listId === 'site_category') {
            const url = `/global-list/${listId}/${parent}/${itemId}/view`;
            props.history.push(url);
        }
    };

    return (
        <>
            <div className='container-fluid mt-3'>
                <div className='row g-2'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light' type='text' placeholder='Search by name' value={keyword} onChange={(event) => { setKeyword(event.target.value) }} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button label='Search' onClick={() => { setPage(1); getItems(); }} />
                            <Button icon='plus-white' color='danger' label='Add Item' onClick={() => addItem()} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-3 p-0'>
                <div className='card mt-3'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between'>
                            <div className='small'>
                                {list?.id === 'site_category' ? <h4 className='mb-0'>List &gt; Site-Category{items?.[0]?.parent_name}</h4> : <h4 className='mb-0'>List &gt; {list?.name}</h4>}

                                <ResultCount page={page} take={take} count={count} />
                            </div>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={count} setPage={setPage} />
                            </div>
                            <div className=''>
                                <div className='dropdown'>
                                    <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                        Sort by: {sort.name}
                                    </button>
                                    <ul className='dropdown-menu'>
                                        {
                                            sortOptions.map((item, index) => {
                                                return (
                                                    <button key={index} className='dropdown-item' onClick={() => { setSort(item); }}>{item.label}</button>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        <td className='cnow-col cnow-col-150'></td>

                                        {list?.id === 'site_category' ? (
                                            <>
                                                <td className='cnow-col cnow-col-300'>Category</td>
                                                <td className='cnow-col cnow-col-300'>Sub Category</td>
                                            </>
                                        ) : (
                                            <>
                                                <td className='cnow-col cnow-col-300'>{list.name}</td>
                                                <td className='cnow-col cnow-col-300'>Award List</td>
                                            </>
                                        )}


                                        <td className='cnow-col'>Number of Items</td>
                                        <td className='cnow-col cnow-col-100'>Id</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className='btn-list'>
                                                            {list?.id === 'awarding_organization' && <LinkButton size='sm' icon='edit' to={`/global-list/${item.type}/${item.id}/editor`} />}
                                                            {list?.id === 'site_category' && <LinkButton size='sm' icon='edit' to={`/global-list/${item.type}/${item.parent}/${item.id}/editor`} />}
                                                            <Button size='sm' icon='delete' onClick={() => deleteItem(item)} />
                                                        </div>
                                                    </td>
                                                    <td onClick={() => handleItemClick(list.id, item.id, item.parent)}>{item.parent_name}</td>
                                                    <td onClick={() => handleItemClick(list.id, item.id, item.parent)}>{item.name}</td>
                                                    <td onClick={() => handleItemClick(list.id, item.id, item.parent)}>0</td>
                                                    <td onClick={() => handleItemClick(list.id, item.id, item.parent)}>{item.id}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={count} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default GlobalListSubItems;
