import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Annotation from 'react-image-annotation';
import ImageTagEditor from './tagEditor';
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import ImageViewer from '../../components/viewers/image';
import { toast } from 'react-toastify';
import { personName } from '../../helpers';

const _api = require('../../api')

const ImageTag = (props) => {
    const id = props.match.params.id

    const dispatch = useDispatch()
    const image = useSelector(state => state[id])
    const [tag, setTag] = useState({})

    const handleSave = () => {
        image.entity_tags.forEach((entity_tag) => {
            if (entity_tag.id?.startsWith('temp.')) entity_tag.id = null
        })

        _api.image.updateEntityTag(image.id, image.entity_tags).then((tags) => {
            dispatch({ type: 'entity', payload: { ...image, entity_tags: tags } })
            toast.success('Image saved successfully')
        })
    }

    const removeImageTag = async (id) => {
        if (!id.startsWith('temp.')) await _api.image.deleteEntityTag(id)
        image.entity_tags = image.entity_tags.filter(function (item) { return item.id !== id; });
        dispatch({ type: 'entity', payload: { ...image, entity_tags: image.entity_tags } });
    }

    const preview = (tag) => {
        const { geometry } = tag.annotation;
        return (
            <div key={tag.annotation.id}
                style={{
                    backgroundColor: 'white',
                    padding: 8,
                    position: 'absolute',
                    fontSize: 12,
                    left: `${geometry.x}%`,
                    top: `${geometry.y + geometry.height}%`
                }}
            >
                {tag.annotation.site_id ? 'Site' : 'Person'}: {tag.annotation.site_id ? tag.annotation.site_name : personName(tag.annotation)}
            </div>
        )
    };

    const editor = (props) => {
        const { geometry } = props.annotation;
        if (!geometry) return null;
        return (
            <div style={{
                width: '400px',
                background: 'white',
                padding: '8px',
                position: 'absolute',
                left: `${geometry.x}%`, top: `${geometry.y + geometry.height}%`,
                zIndex: 3
            }}>
                <ImageTagEditor id={id} {...props} />
            </div>
        )
    }

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>In This Photo - Image Tagging </strong>(Click and Drag on the photo to start tagging)
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button size='sm' label='Save' color='danger' onClick={handleSave} />
                            <LinkButton size='sm' icon='close' target='_self' to={`/image/${image.id}/editor`} />
                        </div>
                    </div>
                </div>
            </div>
            <div id='image_tagger' className='cnow-image-tag-container d-flex justify-content-center align-items-center prevent-select' >
                <Annotation src={image.url?.toLowerCase().endsWith('.pdf') ? image.url.replace('.pdf', '.png') : image?.type === 'then_now' ? image.url.replace('original', 'web') : image.url} annotations={image.entity_tags || []}
                    alt={image.alt_text || image.caption}
                    value={tag}
                    onChange={(tag) => setTag(tag)}
                    onSubmit={() => setTag({})}
                    renderEditor={editor}
                    renderContent={preview}
                />
            </div>

            <div id='bottom' className='cnow-image-tag-list'>
                <ul className='list-inline'>
                    {
                        image?.entity_tags?.map((item, index) => {
                            return (
                                <li className='list-inline-item mb-2' key={index}>
                                    <div className='card'>
                                        <div className='card-body p-2'>
                                            <div className='row g-2'>
                                                <div className='col-auto'>
                                                    <ImageViewer entityType={item.site_id ? 'site' : 'person'} entityId={item.site_id ? item.site_id : item.person_id}
                                                        type='thumb' cssClass='image-tag-thumbnail' />
                                                </div>
                                                <div className='col'>
                                                    <strong className='small'>{item.person_id ? 'Person' : 'Site'}</strong>
                                                    <div>{item.site_name ? item.site_name : personName(item)}</div>
                                                </div>
                                                <div className='col-auto'>
                                                    <Button size='sm' icon='close' onClick={() => removeImageTag(item.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

export default ImageTag;