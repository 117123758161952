import React from 'react';
import { locationName } from '../../helpers';

const Location = ({ data, prevLocations }) => {
    return (
        <>
           {
             data && data.length > 0 ? <div>{locationName(data[0])}</div> : <div>{prevLocations && prevLocations.length > 0 && locationName(prevLocations[0])}</div>
           }
           
        </>
    )
}

export default Location;