/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import FeaturedProjectImage from './featuredProjectImage';
import { useHistory } from 'react-router-dom';
import { customSelectStyle } from '../../helpers';
import bulkOptions from '../../data/bulk_options.json'
import ArrangeImagesList from '../../components/arrangeImagesList';
import BulkImageEditor from '../site/bulkImageEditor';
import Select from 'react-select';
const _api = require('../../api')
const EventImages = (props) => {

    useEffect(() => {
        getAllRecipeImages()
    }, []);

    const dispatch = useDispatch()
    const event = useSelector(state => state[props.id])
    const [recipeImages, setrecipeImages] = useState([]);
    const [bulkEditorType, setBulkEditorType] = useState()
    const [showBulkEditor, setShowBulkEditor] = useState(false)
    const [selectedImages, handleSelectItem] = useState([])
    const location = useLocation()
    const history = useHistory()
    const [isSelectAll, setSelectAll] = useState(false);

    let currentImages = event ? event?.images?.filter(x => x.type === 'image') : []
    let currentDocuments = event ? event?.images?.filter(x => x.type === 'document') : []
    const filteredOptions = bulkOptions.filter(option => option.value !== "image-recognition");
    // let imageRecognition = event ? event?.images?.filter(x => x.type === 'image-recognition') : []

    const editItems = (id) => {
        let imagesList = event.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: event.id, entityType: 'event' } })
        history.push(`/image/${id}/banner`)
    }

    const getAllRecipeImages = () => {
        let allRecipeImages = []
        if (event?.recipes) {
            for (let result of event?.recipes) {
                if (result?.images) {
                    for (let images of result?.images) {
                        allRecipeImages.push(images)
                    }
                }
            }
            setrecipeImages(allRecipeImages)
        }
    }

    const handleOnHide = async () => {
        setBulkEditorType();
        //to revert back user changes



        event.images = await _api.event.getImage(props.id)
        dispatch({ type: 'entity', payload: { ...event, images: event.images } });


        setShowBulkEditor(false);
    }

    { console.log(currentImages, "currentImages"); }
    return (
        <>
            {
                event && <>
                    <PageTitle title={event?.name} />


                    <div id='entity-image-sort'>
                        <div id='banner'>
                            <hr className='cnow-hr' />

                            <div className='row'>
                                <div className='col-auto'>
                                    <div className='form-group'>
                                        <div className='cnow-form-title'>Set Banner Image *</div>
                                        <div onClick={() => editItems(event.image_id)} className='cursor-pointer'>
                                            {event?.images?.find(item => item.image_id === event?.image_id)?.image_url?.endsWith('.pdf') ?
                                                <ImageViewer
                                                    url={event?.images?.find(item => item.image_id === event?.image_id)?.image_url}
                                                    entityType={'image'}
                                                    type='banner'
                                                    cssClass='cnow-image-editor-banner'
                                                />
                                                :
                                                <ImageViewer
                                                    entityId={event?.image_id}
                                                    entityType={'image'}
                                                    type='banner'
                                                    cssClass='cnow-image-editor-banner'
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='d-flex gap-1 justify-content-end'>
                                        <Button icon='edit' label='Set Banner Image' onClick={() => editItems(event.image_id)} disabled={event.images?.length > 0 ? false : true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='cnow-hr' />
                        <div id='bulk-edit' className='row g-1 align-items-start mb-2 justify-content-between'>
                            <div className='col-3'>
                                <div className='cnow-form-title'></div>
                            </div>
                            <div className='col'>
                                <div className='row g-1 align-items-end'>
                                    <div className='col-auto'>
                                    </div>
                                    <div className='col'>
                                    </div>

                                    <div className='col-4'>
                                        <label className='small fw-bold fst-normal'>Bulk Editing</label>
                                        <Select styles={customSelectStyle} options={filteredOptions} onChange={(item) => { setBulkEditorType(item); setShowBulkEditor(true) }} />

                                        {
                                            (bulkEditorType?.value === 'image-type' || bulkEditorType?.value === 'image-credit' || bulkEditorType?.value === 'image-date' || bulkEditorType?.value === 'another-section' || bulkEditorType?.value === 'delete' || bulkEditorType?.value === 'image-recognition') &&
                                            <BulkImageEditor
                                                id={event?.id}
                                                show={showBulkEditor}
                                                type={bulkEditorType}
                                                items={selectedImages}
                                                onHide={() => handleOnHide()}
                                                entityType={'event'}
                                            />
                                        }

                                        {
                                            bulkEditorType?.value === 'rearrange' &&
                                            <ArrangeImagesList id={event?.id}
                                                show={showBulkEditor}
                                                type={bulkEditorType}
                                                items={selectedImages}
                                                section='event'
                                                entityType='event'
                                                onHide={() => handleOnHide()}
                                            />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr className='cnow-hr' />
                        {

                            <EntityImages title={`Images *`} items={currentImages}
                                onSelect={(items) => handleSelectItem(items)}
                                onSelectAll={(value) => setSelectAll(value)}
                                isSelectAll={isSelectAll}
                                showCheckbox={true}
                                imageType='image'
                                entityType='event'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={0}
                            />
                        }

                        {
                            <EntityImages title={`Drawing and Documents`} items={currentDocuments}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='document'
                                entityType='event'
                                entityId={event.id}
                                buttonLabel={'Add a Document'}
                                offsetIndex={currentImages?.length}
                                link={true}
                            />
                        }

                        {/* {
                            <EntityImages title={`Image Recognition *`} items={imageRecognition}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='image-recognition'
                                entityType='event'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={currentImages?.length + currentDocuments?.length}
                            />
                        } */}
                        {
                            event?.recipes?.length > 0 && (
                                <>
                                    <label className='cnow-form-title'>{`Recipe Photographs`}</label>
                                    <FeaturedProjectImage id={props?.id} type='person' items={event.recipes ? recipeImages : []} title={'Person/Org Name'} showByEntityInfo={true} />
                                </>
                            )
                        }

                        <label className='cnow-form-title pt-3'>{`Featured Project Images`}</label>
                        <FeaturedProjectImage id={props?.id} type='site' items={event.featured_projects ? event.featured_projects.filter((elem) => elem.featured) : []} title={'Site Title'} showByEntityInfo={true} />

                        <hr className='cnow-hr' />
                        <label className='cnow-form-title'>{`Participant Images`}</label>
                        <FeaturedProjectImage id={props?.id} type='person' items={event.participants ? event.participants : []} title={'Person/Org Name'} showByEntityInfo={false} />
                    </div>

                </>
            }
        </>
    )
}


export default EventImages 
