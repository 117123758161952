import React from 'react';
import { relatedPersonName, personName, TABLE_ROWS } from '../../helpers';

const RelatedEntityList = ({ data, type }) => {
  let items = [];
  if (type.toLowerCase() === 'site') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  } else if (type.toLowerCase() === 'people') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  } else if (type.toLowerCase() === 'map') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  } else if (type.toLowerCase() === 'playlist') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  }
  else if (type.toLowerCase() === 'tour') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  }
  else if (type.toLowerCase() === 'events') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  }
  else if (type.toLowerCase() === 'event_series') {
    items = data
    return (
      <td>
        {
          items?.slice(0, TABLE_ROWS).map((item, index) => {
            return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

          })
        }

        {items?.length > TABLE_ROWS && <div className='small text-muted'>+ {items?.length - TABLE_ROWS} more</div>}
      </td>
    )
  }
  return (<td></td>);
}

export default RelatedEntityList;