/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/default';
import 'react-image-crop/dist/ReactCrop.css';
import { handleApiError } from '../../helpers';
import InlineImageList from '../../components/inlineImageList';
import Spinner from '../../components/spinner';
const _api = require('../../api')

const BannerEditor = (props) => {
    const id = props.match.params.id
    const type = 'banner';
    const image = useSelector(state => state[id]);
    const images = useSelector((state) => state.images);
    const entity = useSelector(state => state[images.id])
    const [crop, setCrop] = useState({});
    const [aspectRatio, setAspect] = useState();
    const [spinner, setSpinner] = useState(false)

    const [scale, setScale] = useState({ scaleX: '', scaleY: '' });
    const [region, setRegion] = useState({ left: 0, top: 0, width: 0, height: 0 });

    const dispatch = useDispatch();
    const history = useHistory()

    const [selectedImage, setSelectedImage] = useState(entity?.url || (entity?.images && entity.images?.length > 0 ? entity.images[0]?.url : []))

    useEffect(() => {
        if (type === 'banner') {
            setAspect(3.2)
            if (image?.url) {
                image.url = image.url.replace('.pdf', '.png')
            }
            setSelectedImage(image)
        }
    }, [type, image]);
    useEffect(() => {
        if (!image) {
            _api.image.getById(id).then(response => {
                dispatch({ type: 'entity', payload: response })
            })
        }
    }, [id])

    const handleLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = img;
        setScale({ scaleX: naturalHeight / offsetHeight, scaleY: naturalWidth / offsetWidth })
    };

    const handleSave = async () => {
        setSpinner(true)
        if (region?.width) {
            setSpinner(true)
            await _api.image.process({
                'id': selectedImage?.id,
                'type': 'banner',
                'region': region
            }).then(response => {
                setSpinner(false)
                toast.success('Your image has been cropped')
                setSpinner(false)
            }).catch(handleApiError);

            entity.image = selectedImage || entity.image
            entity.image_id = selectedImage ? selectedImage?.id : entity.image_id
            dispatch({ type: 'entity', payload: { ...entity, image: entity.image, image_id: entity.image_id } });
            await _api[images.entityType].update(entity)
        }
        else {
            entity.image = selectedImage
            entity.image_id = selectedImage ? selectedImage?.id : null
            dispatch({ type: 'entity', payload: { ...entity, image: entity.image, image_id: entity.image_id } });
            await _api[images.entityType].update(entity).then(response => {
                toast.success('Your image has been cropped')
                setSpinner(false)
            }).catch(handleApiError);
        }
    }

    const cropImage = async (crop) => {
        setRegion({
            left: parseInt(crop.x * scale.scaleX),
            top: parseInt(crop.y * scale.scaleY),
            width: parseInt(crop.width * scale.scaleY),
            height: parseInt(crop.height * scale.scaleX)
        });
    };

    const handleSelectBannerImage = async (id) => {
        let newBannerImage = []
        newBannerImage = await _api.image.getById(id)
        newBannerImage.url = newBannerImage.url.replace('.pdf', '.png')
        setSelectedImage(newBannerImage)
    }

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>
                            {type === 'web' && 'Image Editor'}
                            {type === 'thumb' && 'Edit Thumbnail'}
                            {type === 'banner' && 'Edit Banner'}
                        </strong>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Spinner display={spinner}>
                                <Button size='sm' label='Save' color='danger' onClick={handleSave} />
                                <Button size='sm' icon='close' onClick={() => { images?.source ? history.push(images?.source) : history.goBack() }} />
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>

            <div id='image_tagger' className='cnow-image-resize-container text-center'>
                <ReactCrop
                    crop={crop}
                    onChange={crop => setCrop(crop)}
                    onComplete={crop => cropImage(crop)}
                    aspect={aspectRatio}
                    ruleOfThirds
                >
                    <img alt={selectedImage?.alt_text || selectedImage?.caption} src={selectedImage?.url ? selectedImage?.url : entity.image?.url} onLoad={handleLoad} />
                </ReactCrop>
            </div>

            <InlineImageList
                onSelect={(id) => handleSelectBannerImage((id))}
                items={(entity?.images && entity.images?.length > 0) && entity.images?.filter(x => (x.type !== 'then_now' && x.type !== 'image-recognition'))?.map(x => x.image_id)}
                image={selectedImage}
                type='banner'
                bannerImageId={entity.image_id}
                entity={(entity?.images && entity.images?.length > 0) && entity.images?.filter(x => (x.type !== 'then_now' && x.type !== 'image-recognition'))}
            />
        </div>
    )
}
export default BannerEditor;   
