/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import PageTitle from '../../../components/pageTitle';
import Button from '../../../components/buttons/default';
import { handleApiError } from '../../../helpers';
import { useHistory } from "react-router-dom";
import AwardOrganizations from '../../../components/admin/award-organizations';
import SelectGlobalList from '../../../components/selects/globalList';
import SelectPeople from '../../../components/selects/person';
import SubCategory from '../../../components/admin/subCategory';
import Program from '../../../components/admin/program';
import Spinner from '../../../components/spinner';
const _api = require('../../../api');
const globalLists = require('../../../data/global_list.json');

const GlobalListEditor = (props) => {
    // const take = 10000;
    const [id, setId] = useState(props.match.params.id);
    const [subcategoryId, setsubcategoryId] = useState(props.match.params?.sub_category_id);
    const [programId, setProgramId] = useState(props.match.params?.program_id);
    //For tier 3 of siteCategory to set category name we are using below state
    const [categoryName, setCategoryName] = useState()
    const [list] = useState(globalLists.filter(x => x.id === props.match.params.type)[0]);
    const [items, setItems] = useState([]);
    const [item, setItem] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [menuSize, setMenuSize] = useState('lg');
    const [previewSize, setPreviewSize] = useState('lg');
    const history = useHistory();
    useEffect(() => {
        setSpinner(true)
        if (id && !subcategoryId && !programId) getItems();
        if (id && subcategoryId && !programId) getSubItems();
        if (id && subcategoryId && programId) getProgramItems();
        setSpinner(false)
    }, [id, subcategoryId, programId]);

    const toggleMenuSize = () => setMenuSize(menuSize === 'sm' ? 'lg' : 'sm')
    const togglePreviewSize = () => setPreviewSize(previewSize === 'sm' ? 'lg' : 'sm')

    const getItems = () => {
        setSpinner(true)
        _api.global_list.getAll(list.id).then(response => {

            setItems(response)
            setItem(response.filter(x => x.id === id)[0]);
            setCategoryName(response.filter(x => x.id === id)[0]?.name)
            setSpinner(false)
        }).catch(handleApiError);
    }

    const getSubItems = () => {
        setSpinner(true)
        _api.global_list.getAll(list.id).then(response => {
            let category = response.filter(x => x.id === id)
            setCategoryName(category[0].name)
            setItems(category[0]?.sub_catgories)
            setItem(category[0]?.sub_catgories.filter(x => x.id === subcategoryId)[0]);
            setSpinner(false)
        }).catch(handleApiError);
    }

    const getProgramItems = () => {
        setSpinner(true)
        _api.global_list.getAll(list.id).then(response => {
            let category = response.filter(x => x.id === id)
            setCategoryName(category[0].name)
            let sub_catgories = category[0]?.sub_catgories?.filter(x => x.id === subcategoryId)
            setItems(sub_catgories[0]?.programs)
            setItem(sub_catgories[0]?.programs.filter(x => x.id === programId)[0]);
            setSpinner(false)
        }).catch(handleApiError);
    }

    const selectItem = (itemId) => {

        _api.global_list.create(list.id, item).then(response => {
            if (id && !subcategoryId && !programId) {
                setId(itemId);
                setItem(items.filter(x => x.id === itemId)[0])
            }
            if (id && subcategoryId && !programId) setsubcategoryId(itemId)
            if (id && subcategoryId && programId) setProgramId(itemId)

        });
    }

    const updateItem = () => {
        _api.global_list.create(list.id, item)
    }

    const removeItem = () => {
        swal({
            title: `Delete ${item.name}`,
            text: `Are you sure that you want to delete ${item.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            _api.global_list.deleteById(item.id).then(response => {
                window.location = `/global-list/${props.match.params.type}/${items[0].id}/editor`;
            })
        });
    }

    const handleInputChange = ({ target: { name, value } }) => {
        item[name] = value;
        if (item.alias) item.alias = `${item.parent_name} ${value}`
        setItem({ ...item, [name]: value });
        let index = -1
        if (list?.id && id && !subcategoryId && !programId) index = items.findIndex(x => x.id === id);
        if (id && subcategoryId && !programId) index = items.findIndex(x => x.id === subcategoryId);
        if (id && subcategoryId && programId) index = items.findIndex(x => x.id === programId);
        if (index !== -1) {
            items[index][name] = value;
            setItems(items);
        }
    };

    const handleThemeChange = (value) => {
        item.parent_name = value.name;
        item.parent = value.id
        item.alias = `${value.name} ${item.name}`
        setItem({ ...item });
    }

    const handleSelectPeople = (value) => {
        item.name = value.name;
        item.parent_name = null
        item.parent = null
        setItem({ ...item });
    }

    return (
        <div className='cnow-editor-container'>

            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                {
                    menuSize === 'sm' && <>
                        <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    </>
                }

                {
                    menuSize === 'lg' && <>
                        <div className='d-flex justify-content-between'>
                            {list?.id !== "site_category" && <h5 className='cursor-pointer' onClick={() => { history.goBack() }}>Add {list?.name}</h5>}
                            {list?.id === "site_category" && !subcategoryId && !programId && <h5 className='cursor-pointer' onClick={() => { history.goBack() }}>Add Site Category</h5>}
                            {list?.id === "site_category" && id && subcategoryId && !programId && <h5 className='cursor-pointer' onClick={() => { history.goBack() }}>{`Add Sub Category for Site ${categoryName}`}</h5>}
                            {list?.id === "site_category" && id && subcategoryId && programId && <h5 className='cursor-pointer' onClick={() => { history.goBack() }}>{`Add Program for ${item?.parent_name} for ${categoryName}`}</h5>}
                            <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                        </div>
                        <ul className='list-group mt-3'>
                            {
                                items?.map((item, index) => {
                                    let itemClass = 'text-start list-group-item cnow-list-group-item';
                                    if (item.id === id || item.id === subcategoryId || item.id === programId) itemClass = `${itemClass} active`;

                                    return (
                                        <li key={index} className={itemClass}>
                                            <button className={itemClass} onClick={() => { selectItem(item.id) }}>
                                                {item.name}
                                                {item.required && <span className='ms-1'>*</span>}
                                            </button>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </>
                }
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    <div className='h-100'>
                        {list?.id !== "site_category" && <PageTitle title={list?.name} subtitle={item?.name} />}
                        {list?.id === "site_category" && <PageTitle title={"Site - Category"} subtitle={categoryName} />}
                        {list?.id === "site_category" && id && subcategoryId && <PageTitle title={" Sub Category"} subtitle={programId ? item?.parent_name : item?.name} />}
                        {list?.id === "site_category" && id && subcategoryId && programId && <PageTitle title={"Program"} subtitle={item?.name} />}
                        <div className='cnow-form-title mt-4'>List</div>
                        <input name='name' type='text' className='form-control disabled' disabled value={list?.name} autoComplete="off" />

                        {(list?.id === 'award_site' || list?.id === 'award_person' || list?.id === 'award_publication' || list?.id === 'award_organization') &&
                            <>
                                <div className='cnow-form-title mt-4'>Awarding Organizations</div>
                                <SelectGlobalList type={'awarding_organization'} value={{ id: item?.parent || '', name: item?.parent_name || '' }} onChange={(data) => handleThemeChange(data)} />
                            </>
                        }

                        {(id && subcategoryId && list?.id === 'site_category') && <>
                            <div className='cnow-form-title mt-3'>Site- Category</div>
                            <input name='name' type='text' className='form-control' value={categoryName || ''} autoComplete="off" />
                        </>
                        }
                        {
                            (id && subcategoryId && programId && list?.id === 'site_category') && <>
                                <div className='cnow-form-title mt-3'>Site- Sub Category</div>
                                <input name='name' type='text' className='form-control' value={item?.parent_name || ''} autoComplete="off" />
                            </>
                        }

                        {
                            (list?.id === 'awarding_organization') ?
                                <>
                                    <div className='cnow-form-title mt-4'>Awarding Organizations</div>
                                    <SelectPeople type={'awarding_organization'} entityType="organization" value={{ id: item?.id || '', name: item?.name || '' }} onChange={(data) => handleSelectPeople(data)} />
                                </>
                                :
                                <>
                                    <div className='cnow-form-title mt-3'>{list?.name}</div>
                                    <input name='name' type='text' className='form-control' value={item?.name || ''} onChange={handleInputChange} autoComplete="off" />
                                </>
                        }
                        {list?.id === 'awarding_organization' && <AwardOrganizations awards='SITES' awards_id='award_site' item={item} id={item?.id} name={item?.name} child_awards={item?.child_awards} />}
                        {list?.id === 'awarding_organization' && <AwardOrganizations awards='PERSON' awards_id='award_person' item={item} id={item?.id} name={item?.name} child_awards={item?.child_awards} />}
                        {list?.id === 'awarding_organization' && <AwardOrganizations awards='ORGANIZATION' awards_id='award_organization' item={item} id={item?.id} name={item?.name} child_awards={item?.child_awards} />}
                        {list?.id === 'awarding_organization' && <AwardOrganizations awards='PUBLICATION' awards_id='award_publication' item={item} id={item.id} name={item.name} child_awards={item.child_awards} />}
                        {(list?.id === 'site_category' && id && !subcategoryId && !programId) && <SubCategory item={item} id={item?.id} list_id='site_category' title='Sub Category' name={item?.name} sub_catgories={item?.sub_catgories} key={item.id} />}
                        {(list?.id === 'site_category' && id && subcategoryId && !programId) && <Program item={item} category_id={id} id={item?.id} list_id='site_category' title='Program' name={item?.name} programs={item?.programs} key={item.id} />}
                    </div>
                </Spinner >
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => updateItem()} />
                        <Button size='sm' icon='delete' onClick={() => removeItem()} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list'>
                        <Button size='sm' label='Instructions' />
                        <Button size='sm' color='danger' label='Not Live' />
                        <Button size='sm' color='danger' label='Save' onClick={() => updateItem()} />
                        <Button size='sm' icon='delete' onClick={() => removeItem()} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                    </div>
                }
            </div>
        </div>
    );
};

export default GlobalListEditor;