import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default'
import ImageViewer from '../../components/viewers/image';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/spinner';
import swal from 'sweetalert';
const _api = require('../../api')
const ToursImage = ({ tourId, stop }) => {

    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);
    const [spinner, showSpinner] = useState(false)
    const location = useLocation()
    const history = useHistory()

    const editItems = (id, type, tourId, siteId) => {
        let item = tour.stops.filter((elem) => elem.id === tourId)
        if (type === 'stop') {
            dispatch({ type: 'images', payload: { source: location.pathname, id: tour.id, entityType: 'tour', editType: type, stop: item } })
        }
        else if (type === 'site') {
            let siteItems = item[0]?.sites?.filter(elem => elem.id === siteId)
            dispatch({ type: 'images', payload: { source: location.pathname, id: tour.id, entityType: 'tour', editType: type, stop: item, site: siteItems } })
        }
        history.push(`/tour/image/${id || 'tour_images'}/thumb`)
    }

    const removeBanner = async (id, type, stop_id) => {

        swal({
            title: `Delete Image`,
            text: `Are you sure that you want to delete this image?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                showSpinner(true)
                if (type === "stop") {
                    let index = tour.stops.findIndex((elem) => elem.id === id)
                    tour.stops[index].image_id = null;
                    tour.stops[index].image_caption = '';
                    tour.stops[index].image_credits = '';
                    await _api.tour.updateTourStops(tour.id, tour.stops)
                    dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } })
                    showSpinner(false)

                }
                if (type === "site") {
                    let stopIndex = tour.stops.findIndex((elem) => elem.id === stop_id)
                    let siteIndex = tour.stops[stopIndex].sites.findIndex((elem) => elem.id === id)
                    tour.stops[stopIndex].sites[siteIndex].image_id = null;
                    tour.stops[stopIndex].sites[siteIndex].image_caption = '';
                    tour.stops[stopIndex].sites[siteIndex].image_credits = '';
                    await _api.tour.updateTourStops(tour.id, tour.stops)
                    dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } })
                    showSpinner(false)
                }
            }
        })
    }

    return (
        <Spinner display={spinner}>
            <div className='tour-stop-bg p-2 mb-4 prevent-select'>
                {tour?.format === 2 && <div className='row g-2 mb-2'>
                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <ImageViewer entityType='site' entityId={stop?.site_id} type='thumb' cssClass='thumb-small' />
                    </div>
                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='tour-stop-number d-flex'>{stop?.sort}</div>
                    </div>
                    <div className='col'>
                        <label className='cnow-form-label fw-bold'>Stop Name*</label>
                        <input value={stop?.stop_name ? stop?.stop_name : `Tour Stop ${stop?.sort}`} className={`${stop?.stop_name ? '' : 'cnow-form-title'} form-control cnow-preview-title fw-bold`} disabled />
                        <div className='bg-light fw-bold py-0'>{stop?.image_caption}</div>
                        <div className='ps-1 fw small'>© {stop?.image_credits}</div>
                    </div>

                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='d-flex gap-1 justify-content-end'>
                            <Button icon='edit' onClick={() => editItems(stop?.image_id, 'stop', stop?.id)} />
                            <Button icon='delete' onClick={() => removeBanner(stop?.id, 'stop')} />
                        </div>
                    </div>
                </div>}
                <div className={`row`}>
                    <div className='col'>
                        {
                            stop?.sites?.length > 0 && stop.sites.map((site, index) =>
                                <div className='row mb-2 g-2'>
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <ImageViewer entityType='site' entityId={site.site_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <div className='tour-stop-site-number d-flex'>{tour?.format === 2 ? `${stop?.sort}.${site.sort}` : `${stop?.sort}`}</div>
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-label fw-bold'>Associated Site name*</label>
                                        <input value={site.site_name} disabled className='bg-white fw-bold w-100 cnow-input-disabled-height border-0 text-dark' />
                                        <div className='bg-light fw-bold py-0'>{site?.image_caption}</div>
                                        <div className='ps-1 fw small'>© {site?.image_credits}</div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <div className='d-flex gap-1 justify-content-end'>
                                            <Button icon='edit' onClick={() => editItems(site.image_id, 'site', stop?.id, site?.id)} />
                                            <Button icon='delete' onClick={() => removeBanner(site.id, 'site', stop?.id)} />
                                        </div>
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
            </div>
        </Spinner>
    )
}
export default ToursImage
