import React, { useState } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { customSelectStyle, newTempId } from '../../helpers';
const _api = require('../../api')
const HashTags = (props) => {
    const [data, setData] = useState(props.data)

    const searchTags = async (keyword) => {
        let response = await _api.global_list.getAll('tags', keyword);
        let items = [];
        response.forEach(data => items.push({ 'value': data.id, 'label': data.name, id: newTempId() }));
        if (items.length === 0) items.push({ 'value': 'NEW', 'label': keyword, 'data': keyword, id: newTempId() })
        return items;
    }

    const handleChange = async (items, e) => {
        if (e.action === 'select-option') {
            let tempArray = []
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (item.value === 'NEW') {
                    const response = await _api.global_list.create('tags', { name: item.label })
                    item = { 'value': response.id, 'label': response.name, 'id': newTempId() }
                }
                tempArray.push(item)
            }
            props.updateData(tempArray)
            setData(tempArray)
        }
        if (e.action === 'remove-value') {
            props.updateData(items)
            setData(items)
        }
    }

    return (
        <div>
            <label className='cnow-form-title'>Hash Tags</label>
            <AsyncCreatableSelect isMulti cacheOptions defaultOptions styles={customSelectStyle}
                loadOptions={searchTags} value={data}
                onChange={(items, e) => handleChange(items, e)} isClearable={false} formatCreateLabel={() => undefined}
            />
        </div>
    )
}

export default HashTags