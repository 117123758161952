/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import TextEditor from '../../../components/editors/textEditor';
import SelectPerson from '../../../components/selects/person';
import ImageViewer from '../../../components/viewers/image';
import ImageUploader from './../../image/upload';


const Index = ({ menu, setMenu, JSONData, setJSONData }) => {

	const mainMenuData = JSONData[menu?.mainMenu];
	const isPlainObject = (obj) => obj !== null && typeof obj === 'object' && !Array.isArray(obj) && obj.constructor === Object;
	const listTableHeading = menu?.mainMenu === 'board' ? 'PEOPLE *' : menu?.mainMenu === 'interns' ? '' : menu?.mainMenu === 'advisors' ? (menu?.mainMenu + ' - ' + menu.subMenu).toUpperCase() : 'ITEM *';

	const renderSubMenu = () => (
		<div key={menu?.mainMenu}>
			{menu?.page !== 'awards' && <ImageControl name='LEFT SIDE IMAGE' mainHeading='SUB - ITEM *' menu={menu} JSONData={JSONData} setJSONData={setJSONData} />}
			<SubListTable menu={menu} setMenu={setMenu} JSONData={mainMenuData} setJSONData={setJSONData} />
		</div>
	);



	const renderData = () => {
		if (isEmpty(menu?.mainMenu)) {
			return <MainListTable key={'main-list'} heading='ITEM *' setMenu={setMenu} JSONData={JSONData} />
		} else {
			return Object.entries(JSONData).filter(([_, value]) => typeof value !== 'string').map(([mainKey, mainValue]) => {
				if (isPlainObject(mainValue)) {
					return (mainKey === menu.mainMenu) &&
						<div key={mainKey}>
							{(mainKey !== 'advisors' && menu.page !== 'partnership' && menu.subMenu) && <ImageControl name={menu.mainMenu.toUpperCase() + ' IMAGE'} mainHeading={menu.subMenu.toUpperCase()} menu={menu} JSONData={JSONData} setJSONData={setJSONData} />}
							{renderSubData(mainKey, mainValue)}
							{menu?.page === 'awards' && menu?.subMenu && <AwardRow JSONData={JSONData} setJSONData={setJSONData} menu={menu} />}
						</div>
				} else {
					return (menu.mainMenu === mainKey && Array.isArray(JSONData[menu.mainMenu])) &&
						<div key={mainKey}>
							{(mainKey !== 'advisors') && <ImageControl name='LEFT SIDE IMAGE' mainHeading='SUB - ITEM *' menu={menu} JSONData={JSONData} setJSONData={setJSONData} />}
							<List key={mainKey} heading={listTableHeading} mainKey={menu.mainMenu} JSONData={JSONData} setJSONData={setJSONData} menu={menu} />
						</div>
				}
			});
		}
	};



	const renderSubData = (mainKey, mainValue) => {
		if (!isEmpty(menu?.mainMenu) && isEmpty(menu?.subMenu)) {
			return renderSubMenu()
		}
		else if (isPlainObject(mainValue)) {
			return Object.entries(mainValue).flatMap(([subKey, value]) => {
				if (isPlainObject(value)) {
					return Object.entries(value).filter(([_, value]) => typeof value !== 'string').map(([key, value]) => (
						menu.mainMenu && menu.subMenu === subKey && key && <List key={`${mainKey}-${subKey}-${key}`} mainKey={menu.mainMenu} subKey={menu.subMenu} nestedKey={key} JSONData={JSONData} setJSONData={setJSONData} heading={`LIST OF ${key.toUpperCase()}`} />
					));
				} else {
					return (menu.mainMenu && menu.subMenu === subKey) && Array.isArray(JSONData[menu.mainMenu][menu.subMenu]) && <List key={`${mainKey}-${subKey}`} heading={listTableHeading} mainKey={menu.mainMenu} subKey={menu.subMenu} JSONData={JSONData} setJSONData={setJSONData} />;
				}
			});
		} else {
			return (menu.mainMenu && Array.isArray(JSONData[menu.mainMenu])) && <List key={mainKey} mainKey={menu.mainMenu} JSONData={JSONData} />;
		}
	};

	return (
		!isEmpty(JSONData) &&
		<Content menu={menu} setMenu={setMenu} JSONData={JSONData} setJSONData={setJSONData}>
			<>
				<hr className='content-hr' />
				{menu && JSONData && renderData()}
			</>
		</Content>
	);
};


const MissionRow = ({ menu, JSONData, setJSONData }) => {
	const { mainMenu } = menu || {};
	const [description, setDescription] = useState(JSONData[menu.mainMenu]?.[0]?.description);

	const handleDescription = () => {
		if (!mainMenu || !description) return;
		setJSONData((prev) => ({ ...prev, [mainMenu]: [{ ...prev[mainMenu][0], description: description },], }));
		toast.success('Description Saved...');
	};

	return (
		<div className="d-flex flex-row gap-2">
			<div className='w-100'>
				<TextEditor name='description' toolbar={true} data={JSONData[menu.mainMenu]?.[0]?.description} onChange={(data) => setDescription(data)} >
					<label className='text-red'>DESCRIPTION</label>
				</TextEditor>
			</div>
			<button onClick={handleDescription} className='icon-button-fixed mt-4'> <i className='fe fe-save icon' /> </button>
		</div>
	);
};

const AwardRow = ({ menu, JSONData, setJSONData }) => {
	const { mainMenu, subMenu } = menu || {};
	const [name, setName] = useState(JSONData[mainMenu]?.[subMenu]?.name);

	const handleAwardDescription = () => {
		if (!mainMenu || !subMenu || !name) return;
		setJSONData((prev) => ({ ...prev, [mainMenu]: { ...prev[mainMenu], [subMenu]: { ...prev[mainMenu]?.[subMenu], name, }, }, }));
		toast.success('Description Saved...');
	};

	return (
		<div className="d-flex flex-row gap-2">
			<div className='w-100'>
				<TextEditor name='description' toolbar={true} data={name} onChange={(data) => setName(data)} >
					<label>Description</label>
				</TextEditor>
			</div>
			<button onClick={handleAwardDescription} className='icon-button-fixed mt-4'> <i className='fe fe-save icon' /> </button>
		</div>
	);
};

const List = ({ mainKey, subKey = '', nestedKey = '', JSONData, setJSONData, heading = '', menu = {} }) => {
	const [rows, setRows] = useState([]);
	useEffect(() => {
		if (!isEmpty(mainKey) && !isEmpty(subKey) && !isEmpty(nestedKey) && !isEmpty(JSONData)) setRows(JSONData[mainKey]?.[subKey]?.[nestedKey]);
		else if (!isEmpty(mainKey) && !isEmpty(subKey) && !isEmpty(JSONData)) setRows(JSONData[mainKey]?.[subKey]);
		else if (!isEmpty(mainKey) && !isEmpty(JSONData)) setRows(JSONData[mainKey]);
		else JSONData && setRows([]);
	}, [mainKey, subKey, nestedKey]);


	useEffect(() => {
		if (!isEmpty(mainKey) && !isEmpty(subKey) && !isEmpty(nestedKey) && !isEmpty(JSONData)) {
			setJSONData((prevJsonData) => {
				let updatedJsonData = { ...prevJsonData };
				updatedJsonData[mainKey][subKey][nestedKey] = rows;
				return updatedJsonData;
			});
		}
		else if (!isEmpty(mainKey) && !isEmpty(subKey) && !isEmpty(JSONData)) {
			setJSONData((prevJsonData) => {
				let updatedJsonData = { ...prevJsonData };
				updatedJsonData[mainKey][subKey] = rows;
				return updatedJsonData;
			});
		}
		else if (!isEmpty(mainKey) && !isEmpty(JSONData)) {
			setJSONData((prevJsonData) => {
				let updatedJsonData = { ...prevJsonData };
				updatedJsonData[mainKey] = rows;
				return updatedJsonData;
			});
		}
	}, [rows])


	const THead = generateHeaders(rows)
	const [editIndex, setEditIndex] = useState(-1);

	const addRow = (rows) => {
		const isBlankRowExists = rows.some(row => Object.values(row).every(value => value === ''));
		if (!isBlankRowExists) {
			const newRow = Object.keys(rows[0] || {}).reduce((acc, key) => { acc[key] = ''; return acc; }, {});
			setRows([newRow, ...rows]);
		}
	};

	const handleEdit = (index) => { setEditIndex(index); alert('Data Alert'); };

	return (
		!isEmpty(rows) && <div className='mb-4'>
			{menu?.page !== 'mission' &&
				<div className='d-flex justify-content-between align-items-center mb-2'>
					<label className='label text-red'>{heading}</label>
					<button onClick={() => addRow(rows)}>+ Add One More</button>
				</div>
			}
			<div>
				<table className='static-page-table'>
					<thead>
						<tr>
							{subKey !== 'in memoriam' && menu?.page !== 'press & publication' && menu?.page !== 'mission' && THead?.map(({ title, width }, index) => <th key={index} style={{ width }}>{title}</th>)}
						</tr>
					</thead>
					<tbody>
						{rows && rows.map((row, index) => (
							subKey === 'in memoriam'
								? <MemoriamRow key={index} row={row} rows={rows} setRows={setRows} index={index} onEdit={handleEdit} editIndex={editIndex} />
								: menu?.page === 'press & publication'
									? <PressRow key={index} row={row} rows={rows} setRows={setRows} index={index} onEdit={handleEdit} editIndex={editIndex} />
									: menu?.page === 'mission'
										? <MissionRow key={index} menu={menu} JSONData={JSONData} setJSONData={setJSONData} />
										: <Row key={index} row={row} rows={rows} setRows={setRows} index={index} onEdit={handleEdit} editIndex={editIndex} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const generateHeaders = (data) => {
	if (!data || !Array.isArray(data) || data.length === 0) return [];
	const hasNameKey = data[0] && 'id' in data[0];

	const fieldCount = Object.entries(data[0]).filter(([key]) => key !== 'image').length;
	const fixedWidth = 5;
	const dynamicWidth = (100 - fixedWidth) / fieldCount;

	const headers = Object.entries(data[0] || {}).map(([key]) => ({
		title: key === 'id' ? 'ORDER' : key === 'name' ? 'PERSON NAME' : key === 'role' ? 'ROLE *' : key.toUpperCase(),
		width: key === 'id' ? `${fixedWidth}%` : `${Math.floor(dynamicWidth)}%`,
	}));

	if (!hasNameKey) headers.unshift({ title: 'ORDER', width: `${fixedWidth}%` });
	return headers;
};

const blankValues = (arr) => {
	if (arr.length === 0) return arr;

	const processElement = (element) => {
		if (Array.isArray(element)) {
			return element.map(processElement);
		} else if (typeof element === 'object' && element !== null) {
			const newObj = {};
			for (const [key, value] of Object.entries(element)) {
				newObj[key] = processElement(value);
			}
			return newObj;
		}
	};

	return arr.map(processElement)[0];
};

const Content = ({ menu, setMenu, children, JSONData, setJSONData }) => {
	const handleItemDropdown = (event) => { event.persist(); setMenu(prev => ({ ...prev, mainMenu: event.target.value || '', subMenu: '' })) }
	const handleSubItemDropdown = (event) => { event.persist(); setMenu(prev => ({ ...prev, subMenu: event.target.value ? event.target.value : '' })) }

	return (
		<div className='static-page'>
			<div className="content-heading">
				<h6 className='text-red'>
					{menu?.page.toUpperCase()}
					{menu?.mainMenu && <span className='text-red'>{` - ${menu?.mainMenu}`}</span>}
					{menu?.subMenu && menu?.mainMenu !== menu?.subMenu && <span className='text-red'>{` - ${menu?.subMenu}`}</span>}
				</h6>
			</div>
			<div className="content-body">
				<div className='form-group'>
					<label className='label text-red'>LIST *</label>
					<select type='select' value={'Manish'} disabled className='form-select bg-cnow-gray fw-bold'>
						<option>{menu?.page}</option>
					</select>
				</div>
				{
					!isEmpty(menu?.mainMenu) ?
						<>
							<div className='form-group mt-4'>
								<label className='label text-red'>ITEM *</label>
								<select type='select' value={menu?.mainMenu} onChange={handleItemDropdown} className='form-select' >
									{
										Object.entries(JSONData).filter(([_, value]) => typeof value !== 'string').map(([key, __], index) => {
											return <option key={index} className='static-page-option' value={key}>{key}</option>
										})
									}
								</select>
							</div>
							{typeof JSONData[menu?.mainMenu] === 'object' && !Array.isArray(JSONData[menu?.mainMenu]) && JSONData[menu?.mainMenu] && (
								<div className='form-group mt-4'>
									<label className='label text-red'>SUB ITEM *</label>
									<select type='select' value={menu?.subMenu} onChange={handleSubItemDropdown} className='form-select'>
										<option className='static-page-option'> Select Sub Item </option>
										{Object.entries(JSONData[menu?.mainMenu]).map(([subKey, subValue], index) => (
											<option key={index} className='static-page-option' value={subKey}> {subKey} </option>
										))}
									</select>
								</div>
							)}
						</>
						:
						<ImageControl main_banner_image={'main_banner_image'} JSONData={JSONData} setJSONData={setJSONData} />
				}
				{children}
			</div>
		</div>
	);
}

const MainListTable = ({ heading = 'ITEM *', setMenu, JSONData }) => {
	const thead = [{ title: 'Order', width: '4%' }, { title: 'Type', width: '86%' }, { title: '', width: '10%' }];

	const selectItem = (mainMenu, subMenu) => setMenu((prev => ({ ...prev, mainMenu: mainMenu || '', subMenu: subMenu || '' })))
	return (
		<div>
			{heading && <div className='d-flex justify-content-between align-items-center'>
				<label className='label text-red'>{heading}</label>
				<button>+ Add One More</button>
			</div>}
			<div>
				<table className='static-page-table'>
					<thead>
						<tr>
							{thead.map((header, index) => <th key={index} style={{ width: header.width }}> {header.title} </th>)}
						</tr>
					</thead>
					<tbody>
						{Object.entries(JSONData).filter(([_, value]) => typeof value !== 'string').map(([key, value], index) => {
							return (
								<tr key={index} >
									<td>
										<div className='d-flex gap-1 justify-content-start align-items-center'>
											<span className='order-box'>{index + 1}</span>
										</div>
									</td>
									<td>
										<input type='text' className='form-control input text-capitalize' value={key} readOnly />
									</td>
									<td>
										<div className='icon-button-group'>
											<button onClick={() => selectItem(key, '')} className='icon-button'> <i className='fe fe-edit icon' />  </button>
											<button className='icon-button'> <i className='fe fe-trash icon' />  </button>
										</div>
									</td>
								</tr>
							)
						})
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const SubListTable = ({ heading = '', menu, setMenu, JSONData, setJSONData }) => {
	const thead = [{ title: 'Order', width: '4%' }, { title: menu?.mainMenu, width: '86%' }, { title: '', width: '10%' }];

	const selectItem = (mainMenu, subMenu) => setMenu((prev => ({ ...prev, mainMenu: mainMenu || '', subMenu: subMenu || '' })))

	const replaceKeyInNestedJSON = (obj, oldKey, newKey) => {
		if (obj && typeof obj === 'object') {
			if (obj.hasOwnProperty(oldKey)) {
				obj[newKey] = obj[oldKey];
				delete obj[oldKey];
			}
			for (const prop in obj) {
				if (obj.hasOwnProperty(prop)) obj[prop] = replaceKeyInNestedJSON(obj[prop], oldKey, newKey);
			}
		}
		return obj;
	};

	const handleChange = (oldKey, newKey) => {
		setJSONData((prev) => {
			const updatedData = { ...prev };

			if (oldKey === newKey) return updatedData;
			if (updatedData.hasOwnProperty(newKey)) {
				toast.error(`${newKey} already exists.`);
				return updatedData;
			}

			for (const key in updatedData) {
				if (!updatedData.hasOwnProperty(key)) continue;
				const subkeys = Object.keys(updatedData[key]);
				if (subkeys.includes(newKey)) {
					toast.error(`${newKey.toUpperCase()} already exists in the ${key}.`);
					return updatedData;
				}
			}

			for (const key in updatedData) {
				if (!updatedData.hasOwnProperty(key)) continue;
				updatedData[key] = replaceKeyInNestedJSON(updatedData[key], oldKey, newKey);
			}
			return updatedData;
		});

		setIndexNumber(null);
		setNewKey('');
	};




	const addRow = () => {
		setJSONData((prev) => ({ ...prev, [menu.mainMenu]: { ...prev[menu.mainMenu], '': blankValues(Object.values(JSONData)) } }))
		setIndexNumber(0)
	};

	const [indexNumber, setIndexNumber] = useState()
	const [newKey, setNewKey] = useState('')
	const handleKeyInput = (index, key) => { setIndexNumber(index); setNewKey(key) }
	// const handleEditInputChange = (value) => setNewKey(value)

	const deleteSubkey = (subKey) => {
		if (!JSONData || typeof JSONData !== 'object') { return JSONData; }

		if (subKey !== '' && JSONData.hasOwnProperty(subKey)) {
			const keysExceptInMemory = Object.keys(JSONData).filter(key => key !== 'in memoriam');
			const isLastElementExceptInMemory = keysExceptInMemory.length === 1;

			if (isLastElementExceptInMemory) {
				console.warn('Cannot delete the last element (except \'in memoriam\').');
			} else {
				delete JSONData[subKey];
				setJSONData((prev) => ({ ...prev, [menu.mainMenu]: JSONData }));
			}
		}
	};

	return (
		<div>
			<div className='d-flex justify-content-between align-items-center'>
				<label className='label text-red'>{heading}</label>
				<button onClick={() => addRow()}>+ Add One More</button>
			</div>
			<div>
				<table className='static-page-table'>
					<thead>
						<tr>
							{thead.map((header, index) => <th key={index} style={{ width: header.width }}> {header.title} </th>)}
						</tr>
					</thead>
					<tbody>
						{Object.keys(JSONData)
							.filter(key => typeof JSONData[key] !== 'string')
							.sort((a, b) => (!isNaN(+a) && !isNaN(+b) ? +a - +b : String(a).localeCompare(String(b))))
							.map((key, index) => {
								return (
									<tr key={index} >
										<td>
											<div className='d-flex gap-1 justify-content-start align-items-center'>
												<span className='order-box'>{index + 1}</span>
											</div>
										</td>
										<td>
											{/* {indexNumber === index ?
												<input type='text' className='form-control input text-capitalize' value={newKey} onChange={(e) => handleEditInputChange(e.target.value)} />
												:
												<input type='text' className='form-control input text-capitalize' value={key} disabled />
											} */}
											<input
												type='text'
												className='form-control input text-capitalize'
												value={indexNumber === index ? newKey : key}
												onFocus={() => {
													setIndexNumber(index);
													setNewKey(key); // Set the initial value when the input is focused
												}}
												onBlur={() => {
													handleChange(key, newKey);
													setIndexNumber(null);
												}}
												onChange={(e) => setNewKey(e.target.value)}
											/>

										</td>
										<td>
											<div className='icon-button-group'>
												{
													key !== "in memoriam" &&
													(indexNumber === index
														? <button onClick={() => handleChange(key, newKey)} className='icon-button'> <i className='fe fe-check icon' />  </button>
														: <button onClick={() => handleKeyInput(index, key)} className='icon-button'> <i className='fe fe-edit icon' />  </button>)
												}
												<button onClick={() => selectItem(menu.mainMenu, key)} className='icon-button'> <i className='fe fe-eye icon' />  </button>
												{
													key !== "in memoriam" &&
													<button className='icon-button' onClick={() => deleteSubkey(key)}> <i className='fe fe-trash icon' />  </button>
												}

											</div>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const Row = ({ row, index, onEdit, rows, setRows }) => {

	const keyMapping = { "role_name": "role" };

	const handleChange = (fieldName, value) => {
		const updatedValue = Object.entries(value).reduce((acc, [key, val]) => {
			const updatedKey = keyMapping[key] || key;
			return { ...acc, [updatedKey]: val };
		}, {});

		const updatedRows = rows.map((r, i) => {
			if (i === index) {
				const updatedRow = { ...r };
				for (const key in updatedValue) {
					if (updatedValue.hasOwnProperty(key) && updatedRow.hasOwnProperty(key)) {
						updatedRow[key] = updatedValue[key];
					}
				}
				return updatedRow;
			}
			return r;
		});

		setRows(updatedRows);
	};

	const handleCustomChange = (fieldName, value) => {
		const updatedRows = rows.map((r, i) => (i === index ? { ...r, [fieldName]: value } : r));
		setRows(updatedRows);
	}

	const handleDelete = () => {
		if (index < 0 || index >= rows.length || rows.length === 1) {
			console.error('ERROR - Cannot delete the last element');
			return;
		}

		const updatedRows = rows.slice(0, index).concat(rows.slice(index + 1));
		setRows(updatedRows);
	};

	const renderInputField = (fieldName) => {
		return (
			(fieldName in row) && (
				<td>
					<input type='text' className='form-control input' value={row[fieldName]} onChange={(e) => handleCustomChange(fieldName, e.target.value)} />
				</td>
			)
		);
	};


	return (
		<DragAndDropRow index={index} rows={rows} setRows={setRows}>
			<td style={{ width: "5%" }}>
				<div className='d-flex gap-1 justify-content-start align-items-center'>
					<span className='order-box'>{index + 1}</span>
					{
						row?.name && <ImageViewer key={row.id} entityType='person' entityId={row.id} type='thumb' cssClass='thumb-small' />
					}
				</div>
			</td>
			{
				row && 'name' in row &&
				<td className='person-dd-custom flex-grow'>
					<SelectPerson onChange={(value) => handleChange('name', value)} value={{ id: row?.id, name: row?.name }} />
				</td>
			}
			{renderInputField('role')}
			{renderInputField('project')}
			{renderInputField('affiliation')}
			{renderInputField('location')}
			{renderInputField('link')}
			{renderInputField('institute')}
			{renderInputField('collection')}
			{renderInputField('partner')}
			{renderInputField('publications')}
			{renderInputField('description')}
			<td style={{ width: "5%" }}>
				<div className='icon-button-group'>
					<button onClick={() => onEdit(index)} className='icon-button ms-1'> <i className='fe fe-edit icon' /> </button>
					<button onClick={handleDelete} className='icon-button'> <i className='fe fe-trash icon' /> </button>
				</div>
			</td>
		</DragAndDropRow>
	);
};

const MemoriamRow = ({ row, index, onEdit, rows, setRows }) => {
	const handleChange = (fieldName, value) => {
		const updatedRows = rows.map((r, i) => (i === index ? { ...r, id: value.id, name: value.name, role: value.role_name } : r));
		setRows(updatedRows);
	};

	const handleDelete = () => {
		if (index < 0 || index >= rows.length || rows.length === 1) {
			console.error('ERROR - Cannot delete the last element');
			return;
		}

		const updatedRows = rows.slice(0, index).concat(rows.slice(index + 1));
		setRows(updatedRows);
	};

	const handleImageDelete = () => setRows((prevRows) => prevRows.map((row, i) => (i === index ? { ...row, image: "" } : row)));


	const handleCustomChange = (fieldName, value) => {
		const updatedRows = rows.map((r, i) => (i === index ? { ...r, [fieldName]: value } : r));
		setRows(updatedRows);
	}

	const renderInputField = (fieldName) => {
		return (
			<input type='text' className='form-control input' value={row[fieldName]} onChange={(e) => handleCustomChange(fieldName, e.target.value)} />
		);
	};

	const [uploader, showUploader] = useState(false);

	const addItems = (items) => {
		if (!isEmpty(items) && items[0]?.url && items[0].type === 'image') {
			const originalUrl = items[0].url;
			const thumbUrl = originalUrl.replace('/original.', '/thumb.');

			const updatedRows = rows.map((r, i) => (i === index ? { ...r, image: thumbUrl } : r));
			setRows(updatedRows);
		};
	}

	return (
		<DragAndDropRow index={index} rows={rows} setRows={setRows} className="mb-5">
			<td>
				<div className="d-flex gap-2 p-0">
					<div className='d-flex flex-column align-content-center align-items-center'>
						<label>Order</label>
						<span className='order-box'>{index + 1}</span>
					</div>
					<div className='d-flex flex-column mb-2' style={{ alignItems: 'flex-start' }}>
						<label>Person Headshot *</label>
						<ImageViewer key={row.id} entityType='person' entityId={row.id} type='thumb' cssClass='memoriam-image' />
					</div>
					<div className='d-flex flex-column mb-2 flex-grow-1' style={{ alignItems: 'flex-start' }}>
						<div className='d-flex flex-column mb-1 w-100 person-dd-custom'>
							<label>Person Name *</label>
							<SelectPerson onChange={(value) => handleChange('name', value)} value={{ id: row?.id, name: row?.name }} />
						</div>
						<div className='d-flex w-100 gap-1'>
							<div className='d-flex flex-column w-50' style={{ alignSelf: 'flex-start' }}>
								<label>Year (Born) *</label>
								{renderInputField('born')}
							</div>
							<div className='d-flex flex-column w-50' style={{ alignSelf: 'flex-start' }}>
								<label>Year (Died) *</label>
								{renderInputField('died')}
							</div>
						</div>
						<div className='d-flex flex-column w-100' style={{ alignSelf: 'flex-start' }}>
							<label>Role*</label>
							{renderInputField('role')}
						</div>
					</div>
					<div>
						<label>&nbsp;</label>
						<div className='icon-button-group'>
							<button onClick={() => onEdit(index)} className='icon-button'> <i className='fe fe-eye icon' /> </button>
							<button onClick={() => onEdit(index)} className='icon-button'> <i className='fe fe-edit icon' /> </button>
							<button onClick={handleDelete} className='icon-button'> <i className='fe fe-trash icon' /> </button>
						</div>
					</div>
				</div>

				<div className="d-flex gap-2 p-0">
					<div className="ms-4"></div>
					<div style={{ marginLeft: "10px" }}>
						<div className='d-flex flex-column mb-2' style={{ alignItems: 'flex-start' }}>
							<label>Person Second Image *</label>
							<ImageViewer key={row.id} type='thumb' cssClass='memoriam-image' url={row?.image || 'NULL'} />
						</div>
					</div>
					<div>
						<label>&nbsp;</label>
						<div className='icon-button-group'>
							{
								!isEmpty(row.image)
									? <>
										<button onClick={() => showUploader(true)} className='icon-button'> <i className='fe fe-repeat icon' /> </button>
										<button onClick={handleImageDelete} className='icon-button'> <i className='fe fe-trash icon' /> </button>
									</>
									: <button className='icon-button' onClick={() => showUploader(true)}> <i className='fe fe-edit icon' />  </button>
							}
						</div>
						{uploader && (
							<ImageUploader globalSearch={true} show={uploader} onHide={() => showUploader(false)} onSave={async (items) => { addItems(items); showUploader(false) }} />
						)}
					</div>
				</div>
			</td>
		</DragAndDropRow >

	);
};

const PressRow = ({ row, index, onEdit, rows, setRows }) => {

	// const handleChange = (__, value) => {
	// 	const updatedRows = rows.map((r, i) => (i === index ? { ...r, id: value.id, name: value.name, role: value.role_name } : r));
	// 	setRows(updatedRows);
	// };

	const handleDelete = () => {
		if (index < 0 || index >= rows.length || rows.length === 1) {
			console.error('ERROR - Cannot delete the last element');
			return;
		}

		const updatedRows = rows?.slice(0, index).concat(rows.slice(index + 1));
		setRows(updatedRows);
	};

	const handleCustomChange = (fieldName, value) => {
		const updatedRows = rows?.map((r, i) => (i === index ? { ...r, [fieldName]: value } : r));
		setRows(updatedRows);
	}

	const renderInputField = (fieldName) => {
		return (
			<input type='text' className='form-control input' value={row[fieldName]} onChange={(e) => handleCustomChange(fieldName, e.target.value)} />
		);
	};

	return (
		<DragAndDropRow index={index} rows={rows} setRows={setRows} className="mb-5">
			<td>
				<div className="d-flex gap-2 p-0">
					<div className='d-flex flex-column align-content-center align-items-center'>
						<label>Order</label>
						<span className='order-box'>{index + 1}</span>
					</div>
					<div className='d-flex flex-column mb-2 flex-grow-1' style={{ alignItems: 'flex-start' }}>
						<label>Publications*</label>
						{renderInputField('publications')}
						<div className='mt-2 w-100'>
							<TextEditor name='description' toolbar={true} data={row?.description} onChange={(data) => handleCustomChange('description', data)} >
								<label>Description</label>
							</TextEditor>
						</div>
					</div>
					<div>
						<label>&nbsp;</label>
						<div className='icon-button-group'>
							<button onClick={() => onEdit(index)} className='icon-button'> <i className='fe fe-eye icon' /> </button>
							<button onClick={() => onEdit(index)} className='icon-button'> <i className='fe fe-edit icon' /> </button>
							<button onClick={handleDelete} className='icon-button'> <i className='fe fe-trash icon' /> </button>
						</div>
					</div>
				</div>
			</td>
		</DragAndDropRow >
	);
};

const DragAndDropRow = ({ index, children, rows, setRows, className = '' }) => {
	const handleDragStart = (e, index) => e.dataTransfer.setData('text/plain', index);
	const handleDragOver = (e) => e.preventDefault();
	const handleDrop = (e, dropIndex) => {
		e.preventDefault();
		const draggedIndex = e.dataTransfer.getData('text/plain');
		if (draggedIndex === '' || draggedIndex === dropIndex.toString()) return;

		const updatedRows = [...rows];
		const [draggedRow] = updatedRows.splice(draggedIndex, 1);
		updatedRows.splice(dropIndex, 0, draggedRow);

		setRows(updatedRows);
	};

	return (
		<tr draggable onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)} className={className} >{children}</tr>
	);
};

const ImageControl = ({ mainHeading = null, name = 'BANNER IMAGE *', main_banner_image = '', menu, JSONData, setJSONData }) => {
	const [image, setImage] = useState('');
	const margin = mainHeading ? 'mt-1 mb-3' : 'mt-4';
	const [uploader, showUploader] = useState(false);
	const { mainMenu, subMenu, page } = menu || {};

	useEffect(() => {

		if (page === 'mission') {
			setImage(JSONData?.[menu?.mainMenu]?.[0]?.image);
		} else if (main_banner_image) {
			setImage(JSONData?.main_banner_image);
		} else if (mainMenu && !subMenu) {
			setImage(JSONData?.[`${mainMenu}_banner`]);
		} else if (mainMenu && subMenu) {
			setImage(JSONData?.[mainMenu]?.[subMenu]?.image ? JSONData?.[mainMenu]?.[subMenu]?.image : '');
		} else {
			console.error("Error", menu);
		}
	}, [menu?.mainMenu, menu?.subMenu, JSONData]);

	const addItems = (items) => {
		if (!isEmpty(items) && items[0]?.url && items[0].type === 'image') {
			const originalUrl = items[0].url;
			const thumbUrl = originalUrl.replace('/original.', '/thumb.');

			if (page === 'mission') {
				setJSONData((prev) => ({ ...prev, [mainMenu]: [{ ...prev[mainMenu][0], image: thumbUrl },], }));
			} else if (main_banner_image) {
				setJSONData((prev) => ({ ...prev, main_banner_image: thumbUrl }));
			} else if (menu?.mainMenu && !menu.subMenu) {
				setJSONData((prev) => ({ ...prev, [menu?.mainMenu + '_banner']: thumbUrl }));
			} else if (menu?.mainMenu && menu.subMenu) {
				setJSONData((prev) => ({ ...prev, [menu?.mainMenu]: { ...prev[menu?.mainMenu], [menu?.subMenu]: { ...prev[menu?.mainMenu]?.[menu?.subMenu], image: thumbUrl }, }, }));
			} else {
				toast.error('Something went wrong!');
			}
		}
	};

	const deleteBanner = () => {
		if (page === 'mission') setJSONData((prev) => ({ ...prev, [mainMenu]: [{ ...prev[mainMenu][0], image: "" },], }));
		else if (main_banner_image) setJSONData((prev) => ({ ...prev, main_banner_image: "" }));
		else if (menu?.mainMenu && !menu.subMenu) setJSONData((prev) => ({ ...prev, [menu?.mainMenu + '_banner']: "" }));
		else if (menu?.mainMenu && menu.subMenu) setJSONData((prev) => ({ ...prev, [menu?.mainMenu]: { ...prev[menu?.mainMenu], [menu?.subMenu]: { ...prev[menu?.mainMenu]?.[menu?.subMenu], image: "" }, }, }));
		else toast.error('Something went wrong!');
	};

	return (
		<>
			{mainHeading && <label className='label text-red'>{mainHeading}</label>}
			<div className={`form-group d-flex flex-column ${margin}`}>
				<label className='label'>{name}</label>
				<div className='banner-btn d-flex'>
					<ImageViewer type='thumb' cssClass='static-banner-image' url={image ? image : 'null'} />
					{!isEmpty(image)
						? <>
							<button className='icon-button' onClick={() => showUploader(true)}> <i className='fe fe-repeat icon' />  </button>
							<button className='icon-button' onClick={() => deleteBanner()}> <i className='fe fe-trash icon' />  </button>
						</>
						: <button className='icon-button' onClick={() => showUploader(true)}> <i className='fe fe-edit icon' />  </button>
					}
				</div>
				{uploader && <ImageUploader globalSearch={true} show={uploader} onHide={() => showUploader(false)} onSave={async (items) => { addItems(items); showUploader(false) }} />}
			</div>
		</>
	);
};

export default Index;
