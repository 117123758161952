import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapOverlayEditor from '../../components/editors/mapOverlayEditor';
import Theme from '../../components/theme';
import Category from '../../components/category';
import uniqid from 'uniqid'

const MenuTitle = (props) => {
    const dispatch = useDispatch();
    const map = useSelector((state) => state[props.id]);
    const [key, setKey] = useState(uniqid())
    const handleInputChange = ({ target: { name, value } }) => {
        map[name] = value;
        dispatch({ type: 'entity', payload: { ...map, [name]: value } });
        setKey(uniqid())
    }

    return (
        <>
            <div className='form-group'>
                <div className='cnow-form-title'>Title <span className='text-cn-warning'>*</span> </div>
                <input name='name' type='text' className='form-control' value={map?.name} onChange={handleInputChange} autoComplete="off" />
            </div>

            {
                map?.id && <>
                    <div className='form-group'>
                        <MapOverlayEditor id={map.id} key={`map-overlay-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
                    </div>
                    <Category id={map.id} key={`map-category-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'map_category'} type={'map'} />
                    <Theme id={map.id} key={`map-theme-${key}`} refresh={() => { setKey(uniqid()) }} globalListType={'map_theme'} type={'map'} />
                </>
            }
        </>
    );
}
export default MenuTitle;