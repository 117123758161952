import axios from 'axios';

const auth = async (credentials) => {
  try {
    const data = await axios.post('auth', credentials);
    return data;
  } catch (err) {
    return err;
  }
};

const forgotPassword = async (email) => {
  try {
    const data = await axios.post('auth/send-password-reset-email', email);
    return data;
  } catch (err) {
    return err;
  }
};

const resetPassword = async (password, token) => {
  axios.defaults.headers.common['Authorization'] = token;
  try {
    const data = await axios.post('me/update-password', password);
    return data;
  } catch (err) {
    return err;
  }
};

export { auth, forgotPassword, resetPassword };
