import React from 'react';
import { locationName, TABLE_ROWS } from '../../helpers';

const LocationList = ({ data }) => {
    //to show the custom location address override only once
    let custom = (data?.length && data[0]?.custom) ? data[0].alias : false;
    return (
        <>
            {
                custom ? (
                    <td>
                        <div className='text-truncate'>
                            {custom}
                        </div>
                    </td>
                ) :
                    (
                        <td>
                            {
                                data?.length > 0 && data?.slice(0, TABLE_ROWS).map((location, index) => {
                                    return <div key={index} className='text-truncate'>
                                        {locationName(location) ? locationName(location) : `Location ${index + 1}`}
                                    </div>
                                })
                            }

                            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
                        </td>
                    )
            }
        </>
    )
}

export default LocationList;