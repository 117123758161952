import React from 'react'
import { Modal } from "react-bootstrap";
import Button from '../../components/buttons/default'
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LongTextView from "../../components/viewers/longText";
import Spinner from "../../components/spinner";
import { default as ImageViewer } from "../../components/viewers/image";
import TagList from '../../components/table/tagList'
import LocationList from '../../components/table/locationList';
import EventPeopleList from "../../components/table/eventPeopleList"
import { changeUTC } from '../../helpers';

const _api = require('../../api');
const EventSearch = (props) => {
  const [spinner, showSpinner] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [searchResult, setSearchResult] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [type, setType] = useState(props.type);
  const entity = useSelector(state => state[props.entityId])


  const search = useCallback(() => {
    showSpinner(true);

    let sortBoolean = true
    const params = {
      "bool": {
        "must": [
          {
            "match": {
              "deleted": "false"
            }
          },
          {
            "match": {
              "is_parent": "true"
            }
          },
          { "match": { "created_via": "ADMIN" } },

        ]
      }
    };
    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "name^5",
            "participants.person_name",
            "alias",
            "participants.person_first_name",
            "participants.person_last_name",
            "hosts.person_name",
            "locations.name",
            "locations.city",
            "locations.state",
            "locations.country",
            "categories.category_name",
            "categories.subcategory_name",
            "types.type_name",
            "tags.tag_name",
            "related_sites.related_site_name",
            "related_people.related_person_name",
            "podcasts.podcast_name",
            "participants.role_name",
            "people.profession.profession_name",
            "related_people.org_type.organization_name",
            "locations.address"
          ],
          "fuzziness": "AUTO",
          "prefix_length": 2
        }
      });
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }

    _api.event.searchByElastic(0, 100, finalParams).then(response => {

      setSearchResult(response.hits)
      showSpinner(false)
    })
  }, [keyword])

  useEffect(() => {
    search()
  }, [search]);
  const handleSave = (values) => {
    if (props.onSave) {
      props.onSave(values);
      props.onHide();
    }
  }

  const handleSelect = (item) => {
    let items = selectedItems

    if (items?.findIndex(x => x.id === item.id) >= 0) items = items.filter(x => x.id !== item.id)
    else items.push(item)

    setSelectedItems(items)
  };

  return (
    <Modal show={props.show} size='xl' onHide={() => props.onHide(false)}>
      <Modal.Body>
        {!type && (
          <div>
            <div className='row'>
              <div className='col'>
                <div className='row'>

                  <div className='col p-1'><label className='cnow-form-title mb-0'>{`ADD A EVENT - TITLE ENTERED ON PAGE ONE`}</label></div>
                </div>
              </div>
              <div className='col-auto btn-list'>

                {<Button size='sm' icon='close' onClick={() => props.onHide()} />}
              </div>
            </div>

            <div className='d-flex  mt-5'>
              <Button size='md' className="ms-5 me-5" label='Add Event from cultureNow database' onClick={() => setType("dataBase")} />
              <Button size='md' className="ms-5" label='Add new Event' onClick={() => window.open('/event/create')} />
            </div>
          </div>)}

        {type === "dataBase" && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>SEARCH FOR EVENTS IN THE CULTURENOW EVENT DATABASE</div>
              <div>
                <Button
                  size='sm'
                  label='Save'
                  color='light'
                  onClick={() => handleSave(selectedItems)}
                  className='me-2'
                />
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <hr />
            <div className='row g-1 mt-5'>
              <div className='col'>
                <input className='form-control bg-light border-start-0' type='text' placeholder='Search for events from database...' value={keyword}
                  onChange={(event) => { setKeyword(event.target.value) }}
                  onKeyDown={(e) => { if (e.key === 'Enter') { search(); } }}
                  autoComplete="off"
                />
              </div>
              <div className='col-auto'>
                <div className='btn-list'>
                  <Button label='Submit' onClick={() => { search(); }} />
                </div>
              </div>
            </div>

            <Spinner display={spinner}>
              <div className='mt-2'>
                <table className='table small'>
                  <thead>
                    <tr className='bg-light small'>
                      <th style={{ width: "100px" }}>Thumbnail</th>
                      <th style={{ width: "150px" }}>Event ID</th>
                      <th style={{ width: "150px" }}>Event Name</th>

                      <th style={{ width: "100px" }}> Type</th>
                      <th style={{ width: "100px" }}> Location</th>
                      <th style={{ width: "100px" }}> Date and time </th>
                      <th style={{ width: "150px" }}> People</th>
                      <th style={{ width: "50px" }}> Public Tag</th>
                      <th className='text-center'>Select Event</th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                      const isChecked = selectedItems?.findIndex(x => x.podcast_id === item._source.id) >= 0;
                      return (
                        <tr key={index}>
                          <td>
                            <ImageViewer entityType={'podcast'} entityId={item._source?.id} type='thumb@2x' cssClass='thumb' />
                          </td>
                          <td>{item._source?.id}</td>
                          <td>
                            <LongTextView>{item._source?.name}</LongTextView>
                          </td>
                          <td>
                            {item._source?.types?.map((item, index) => {
                              return (
                                <span>

                                  {item?.type_name}     {index < item?.types - 1 && ','}</span>
                              )
                            })}
                          </td>
                          <LocationList data={item._source?.locations} />
                          <td>{changeUTC(item._source?.dates?.[0]?.start_date)}</td>
                          <EventPeopleList host={item._source?.hosts} sponsor={item._source?.sponsors} other={item._source?.participants} />
                          <TagList data={item._source?.tags} />
                          {/* <EventDateTime/> */}


                          <td>
                            <div className='form-check align d-flex justify-content-center'>
                              {
                                <input
                                  disabled={entity?.featured && entity?.featured.findIndex(x => x?.event_id === item._source?.id) >= 0 ? true : false}
                                  id={item._source?.id}
                                  className='form-check-input'
                                  type='checkbox' name='select_podcast' defaultChecked={isChecked}
                                  onChange={(e) => { handleSelect(item._source) }} />
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Spinner>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default EventSearch