/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import uniqid from 'uniqid';
import Dragula from 'react-dragula';
import Button from '../../components/buttons/default';
import { useDispatch, useSelector } from 'react-redux';
import SelectGlobalList from '../../components/selects/globalList';
import { toast } from 'react-toastify';
import { handleReorder, personName } from '../../helpers';
const _api = require('../../api')

const PersonDesignation = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.designations, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, designations: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addDesignation = () => {
        if (!personName(person)) toast.warning('please enter First Name')
        else {
            let designations = person.designations ? person.designations : []
            designations.push({
                'id': `temp.${uniqid()}`,
                'person_id': person.id,
                'designation_id': null,
                'designation_name': '',
                'sort': designations ? designations.length + 1 : 1
            });
            dispatch({ type: 'entity', payload: { ...person, designations: designations } });
            props.refresh && props.refresh();
        }
    };

    const removeDesignation = async (index) => {
        if (!person.designations[index].id.startsWith('temp.')) await _api.person.deleteDesignation(person.designations[index].id)
        person.designations.splice(index, 1)
        dispatch({ type: 'entity', payload: { ...person, designations: person.designations } });
    };

    const handleDesignationChange = (index, data) => {
        let designations = person.designations
        designations[index].designation_id = data.id
        designations[index].designation_name = data.name
        dispatch({ type: 'entity', payload: { ...person, designations: designations } });
    }

    return (
        <>
            <div className='form-group' >
                <hr className='cnow-hr' />

                <div className='row'>
                    <div className='col'>
                        <span className='cnow-form-title'>{props.title}</span>
                    </div>
                    <div className='col-auto'>
                        <Button size='sm' icon='plus' label={props.type === 'person_designation' ? 'Add a Credential' : 'Add a Type'} onClick={() => addDesignation()} />
                    </div>
                </div>


                <div id={dragulaContainer} key={dragula}>
                    {
                        person?.designations?.map((item, index) => {
                            const selectedDesignation = { id: item?.designation_id, name: item?.designation_name }
                            return (
                                <div key={index} id={item.id} className='mt-2 dragula-item'>
                                    <div className='row g-1'>
                                        <div className={person?.designations.length > 1 ? `col-auto` : `d-none`}>
                                            {index === 0 && <div className='cnow-form-label fw-bold'>Order</div>}
                                            <input key={item.id} type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                        </div>
                                        <div className='col-auto d-flex flex-grow-1'>
                                            <div className='w-100'>
                                                {index === 0 && <div className='cnow-form-label'>&nbsp;</div>}
                                                <SelectGlobalList key={item.id} type={props.type} value={selectedDesignation} onChange={(data) => handleDesignationChange(index, data)} />
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            {index === 0 && <div key={item.id} className='cnow-form-label'>&nbsp;</div>}
                                            <Button icon='delete' onClick={() => removeDesignation(index)} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default PersonDesignation