import React from "react";
import TextEditor from "../editors/textEditor";

const CuratorTextField = ({ label, title, subTitle, disableSubTextBox, disableMainTextBox, handleMainInputChange, handleSubInputChange, mainInput, subInput, hrTrue, placeholder }) => {
    return (
        <>
            {hrTrue && <hr className="cnow-hr" />}
            {label && <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{label}</label>
                </div>
            </div>}
            <div className='row g-1'>
                <div className='col'>
                    {!disableMainTextBox && <div className='row'>
                        <div className='col'>
                            {title && <label className='fw-bold'><small>{title}</small></label>}
                            <input name='link' type='text' className='form-control' placeholder={placeholder || 'Esto'} value={mainInput || ''} onChange={({ target: { value } }) => handleMainInputChange(value)} autoComplete='off' />
                        </div>
                    </div>}
                    {!disableSubTextBox && <div className='row'>
                        <div className='col'>
                            <TextEditor limit={250} toolbar={true} data={subInput} onChange={(data) => handleSubInputChange(data)} placeholder='Esto'>
                                <label className='fw-bold'><small>{subTitle}</small></label>
                            </TextEditor>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default CuratorTextField