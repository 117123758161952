/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import PageTitle from '../../components/pageTitle';
import { personName } from '../../helpers';
import PersonAffiliation from '../../components/peopleEditor/affiliation';
import RelatedPeopleEditor from '../../components/editors/relatedPeopleEditor';

const PeopleAffiliation = (props) => {
	const person = useSelector(state => state[props.id]);
	const [key, setKey] = useState(uniqid())
	return (
		<>
			<PageTitle title={personName(person)} />
			<PersonAffiliation id={props.id} key={`person-affiliation-${key}`} refresh={() => setKey(uniqid())} />
			<RelatedPeopleEditor id={props.id} type='person' key={`related-people-editor-${key}`} refresh={() => setKey(uniqid())} />
		</>
	);
};

export default PeopleAffiliation;