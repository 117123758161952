import React, { useState } from "react";
import { useSelector } from "react-redux";
import uniqid from 'uniqid'
import PageTitle from "../../components/pageTitle";
import PodcastEditor from "../../components/editors/podcastEditor";
import RelatedPlaylist from "../../components/editors/relatedPlaylist";

const MapPodcast = (props) => {
  const map = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={map?.name} />
      <hr className='cnow-hr' />
      <PodcastEditor id={props.id} type="map" key={`podcast-editor-${key}`} refresh={() => setKey(uniqid())} />
      <RelatedPlaylist id={props.id} type="map" key={`related-playlist-${key}`} refresh={() => setKey(uniqid())} />
    </>
  );
};

export default MapPodcast;
