import React, { useState } from 'react';
import moment from 'moment';
const TableCollapsable = (props) => {
    const [more, showMore] = useState(true);
    const collapsable = props.collapsable || false;
    const bgColor = props.bgColor || 'cnow-bg-red';
    const textColor = props.textColor || 'text-white';
    const [month, day] = props.title.split('-');

    let formattedDate;
    if (month && day) {
        formattedDate = moment(`${Number(month) + 1}-${day}`, 'M-DD').format('MMMM DD');
    } else {
        formattedDate = moment(`${props.title}-01-01`, 'YYYY-MM-DD').format('YYYY');
    }

    return (
        <tbody>
            <tr className={`${bgColor}`}>
                <td colSpan={25}>
                    <div className='d-flex'>
                        <div className={`fw-bold ps-2 ${textColor} text-uppercase`} style={{ width: '92vw' }}>{props?.showTitle ? props.title : formattedDate}</div>
                        <div className={`me-2 ${textColor}`}>
                            {props.count && <span className='small fst-italic me-1'>{props.count}</span>}
                            {
                                collapsable && <>
                                    {more &&
                                        <span onClick={() => showMore(false)} className={`small fst-italic cursor-pointer`}>Show less <i className='fe fe-chevron-up' /></span>}
                                    {!more &&
                                        <span onClick={() => showMore(true)} className={`small fst-italic cursor-pointer`}>Show more <i className='fe fe-chevron-down' /></span>}
                                </>
                            }
                        </div>
                    </div>
                </td>
            </tr>
            {more && <>{props.children}</>}
        </tbody>


    )
}

export default TableCollapsable;