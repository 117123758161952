import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';

import PageTitle from '../../components/pageTitle';
import PersonDesignation from '../../components/peopleEditor/designation';
import PersonOrganizationType from '../../components/peopleEditor/organizationType';
import PersonPublicationType from '../../components/peopleEditor/publication';
import PersonProfession from '../../components/peopleEditor/profession';
import PersonAffiliation from '../../components/peopleEditor/affiliation';
import AlternateContact from '../../components/peopleEditor/alternateContact';
import TextEditor from '../../components/editors/textEditor';
import PersonHeadshot from '../../components/peopleEditor/headshot';
import { personName } from '../../helpers';
import RelatedItem from '../../components/peopleEditor/relatedItem';

const ParticipantInfo = (props) => {
	const dispatch = useDispatch();
	const person = useSelector(state => state[props.id]);
	const [key, setKey] = useState(uniqid())

	const onTypeChange = ({ target: { value, checked } }) => {
		person.type = value;
		person.professions = []
		person.designations = []
		person.organization_types = []
		person.publication_types = []
		person.quotes = []

		if (value === 'person') {
			person.first_name = person.name || '';
			person.name = '';
		} else {
			person.name = person.name || (person.first_name || '').concat('', person.last_name || '');
			person.first_name = '';
			person.last_name = '';
		}

		dispatch({
			type: 'entity', payload: {
				...person,
				name: person.name,
				first_name: person.first_name,
				last_name: person.last_name,
				type: value,
				professions: person.professions,
				designations: person.designations,
				organization_types: person.organization_types,
				publication_types: person.publication_types
			}
		});
	};

	const handleInputChange = ({ target: { name, value } }) => {
		person[name] = value;
		dispatch({ type: 'entity', payload: { ...person, [name]: value } });
		//on change refresh dragula
		setKey(uniqid())
	};

	const handleEditorChange = (data) => {
		dispatch({ type: 'entity', payload: { ...person, description: data } });
	}

	const handleAddToMailChange = ({ target: { checked } }) => {
		dispatch({ type: 'entity', payload: { ...person, add_to_mail: checked } });
	}
	return (
		<div>
			{
				person && <>
					{/* Title  */}
					<PageTitle title={personName(person)} />
					<hr className='cnow-hr' />

					{/* Mailing list  */}
					<div className='d-flex justify-content-between'>
						<div className='cnow-form-title'>Add a Participant - Person</div>
						<div>
							<input type="checkbox" className='form-check-input' onChange={handleAddToMailChange} />
							<span className='ms-2'>Add to mailing list</span>
						</div>
					</div>
					<hr className='cnow-hr' />

					{/* Type  */}
					<div className='form-group mt-2'>
						<div className='cnow-form-title'>Type *</div>
						<div className='row'>
							<div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='person' onChange={onTypeChange} checked={person.type === 'person'} />
									<label className='form-check-label small'>Person</label>
								</div>
							</div>
							<div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='organization' onChange={onTypeChange} checked={person.type === 'organization'} />
									<label className='form-check-label small'>Organization</label>
								</div>
							</div>
							<div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='publication' onChange={onTypeChange} checked={person.type === 'publication'} />
									<label className='form-check-label small'>Publication</label>
								</div>
							</div>
						</div>
					</div>

					{/* Name  */}
					{
						person.type &&
						<div>
							<hr className='cnow-hr' />
							<label className='cnow-form-title'>Name *</label>
							{
								person.type === 'person' && <>
									<div className='row g-2'>
										<div className='col-6'>
											<span className='cnow-form-title'>First Name *</span>
											<input name='first_name' type='text' className='form-control' value={person.first_name || ''} onChange={handleInputChange} autoComplete="off" />
										</div>
										<div className='col-6'>
											<span className='cnow-form-title'>Last Name *</span>
											<input name='last_name' type='text' className='form-control' value={person.last_name || ''} onChange={handleInputChange} autoComplete="off" />
										</div>
									</div>
								</>
							}

							{
								person.type !== 'person' && <input name='name' type='text' className='form-control' value={person.name || ''} onChange={handleInputChange} autoComplete="off" />
							}
						</div>
					}

					{/* Credentials/Type  */}
					{
						person.type === 'person' && <>
							<PersonDesignation type='person_designation' title='Credentials (Post-nominal letters, ex. - “MD”, “FAIA”)' onUpdate={props.onUpdate} id={props?.id} key={`person-designation-${key}`} refresh={() => setKey(uniqid())} />
							<PersonProfession onUpdate={props.onUpdate} id={props?.id} key={`person-profession-${key}`} refresh={() => setKey(uniqid())} />
						</>
					}

					{
						person.type === 'organization' && <>
							<PersonOrganizationType type='organization_type' title='Organization Type *' onUpdate={props.onUpdate} id={props?.id} key={`person-organization-${key}`} />
						</>
					}

					{
						person.type === 'publication' && <>
							<PersonPublicationType type='publication_type' title='Publication Type *' onUpdate={props.onUpdate} id={props?.id} key={`person-profession-${key}`} />
						</>
					}

					{/* Affiliation  */}
					<PersonAffiliation id={props.id} key={`person-affiliation`} refresh={() => setKey(uniqid())} />
					<hr className='cnow-hr' />

					{/* About  */}
					<TextEditor key={props.id} limit={500} toolbar={true} data={person?.description} onChange={handleEditorChange}>
						<label className='cnow-form-title'>About <span className='text-cn-warning'>*</span> </label>
					</TextEditor>

					{/* Headshot */}
					<PersonHeadshot id={person.id} imageType={'thumb'} />

					{/* Contact */}
					<AlternateContact id={props.id} key={`alternate-contact-${key}`} refresh={() => setKey(uniqid())} isParticipant={true} />

					{/* Related Items  */}
					<RelatedItem id={person.id} />
				</>
			}
		</div>
	);
};

export default ParticipantInfo;