import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { newTempId } from "../../helpers";

const EntityOfDayCheckBox = ({ entity_day, id, show_entity_of_day }) => {
    const dispatch = useDispatch()
    const entity = useSelector(state => state[id]);
    const handleThemeChange = () => {
        if (!entity?.curator.id) entity.curator.id = newTempId()
        if (!entity?.curator?.[show_entity_of_day]) entity.curator[show_entity_of_day] = false
        entity.curator[show_entity_of_day] = !entity.curator[show_entity_of_day]
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, [show_entity_of_day]: entity.curator[show_entity_of_day] } } })
    }
    return (
        <>
            <hr className="cnow-hr" />
            <div className="d-flex">
                <div className='cnow-form-title'>{`${entity_day} of the day`}</div>
                <div className="d-flex ps-5">
                    <input className='form-check-input' type='checkbox' name='theme' key={id} checked={entity?.curator?.[show_entity_of_day]} onChange={(e) => handleThemeChange(e.target)} />
                    <div className="ps-2">{`DO NOT show as the ${entity_day} of the day`}</div>
                </div>
            </div>

        </>
    )
}

export default EntityOfDayCheckBox