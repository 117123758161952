import React from 'react';
import { TABLE_ROWS } from '../../helpers';
import LongTextViewer from '../viewers/longText';

const TagList = ({ data }) => {
    let tags = [];
    for (let i in data) tags.push(data[i].tag_name)

    return (
        <td>
            <LongTextViewer lines={TABLE_ROWS + 1}>
                {tags.join(', ')}
            </LongTextViewer>
        </td>
    )
}

export default TagList;