import React, { useState } from 'react';
import uniqid from 'uniqid'
import { personName } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import EditImage from './editImage';
import Button from '../../buttons/default';
import ImageViewer from '../../viewers/image';
import ImageUploader from '../../../pages/image/upload';
import Spinner from '../../spinner';
const ImagePreview = (props) => {

    const [show, setShow] = useState(false)
    const [type, setType] = useState('')
    const [uploader, showUploader] = useState(false)
    const [key, setKey] = useState(uniqid())
    const caption = props?.data?.caption || '';
    const singleImg = props.single || false;
    const dispatch = useDispatch()
    let featured = useSelector((state) => state.featured);
    const onSaveImage = (image) => {
        let section_index = props.section_index
        let entity_index = props.entity_index
        let section = props.section
        featured[section][section_index].edited = true
        featured[section][section_index].data[section][entity_index].image = image[0]
        featured[section][section_index].data[section][entity_index].image_id = image[0].id
        dispatch({ type: 'featured', payload: { ...featured } })
        if (props?.onUpdate) props.onUpdate()
        setKey(uniqid())
    }
    return (
        <div className='mt-3' key={key}>
            {singleImg === false && <div>
                <div className={`bg-light py-1 `}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-inline-flex justify-content-between align-items-center w-75 mx-5'>
                            <div className={`fw-bold`}>{props?.data?.name || personName(props?.data)}</div>
                            <div>
                                <Button size='sm' color='light' icon='plus' className={`ms-1`} label={'Upload Image'} onClick={() => { showUploader(true) }} />
                                <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={() => { setShow(true); setType('featured') }} />
                            </div>
                        </div>
                        <div className='d-inline-flex justify-content-between align-items-center w-25 me-5'>
                            <div className={`fw-bold`}>{props?.data?.name || personName(props?.data)}</div>
                            <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={() => { setShow(true); setType('headshot') }} />
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='mx-5 w-75' onClick={() => { setShow(true); setType('featured') }} >
                        <Spinner display={show}>
                            <ImageViewer url={props?.data?.image?.url} type={props?.typeWeb || "banner"} cssClass='web-image cursor-pointer' />
                            {caption && <div className='bg-light'>{caption}</div>}
                        </Spinner>
                    </div>
                    <div className='me-5 w-25' onClick={() => { setShow(true); setType('headshot') }}>
                        <Spinner display={show}>
                            <ImageViewer url={props?.data?.image?.url} type="headshot" cssClass='phone-image cursor-pointer' />
                        </Spinner>
                    </div>
                </div>
            </div>}
            {singleImg && <div>
                <div className={`bg-light py-1 `}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-inline-flex justify-content-between align-items-center w-100 mx-3'>
                            <div className={`fw-bold`}>{props?.singleImageTitle}</div>
                            {(props?.singleImageButton && props?.section !== 'then_now') && <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={() => { setShow(true); setType(props?.type || 'banner') }} />}
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center w-60 mt-3'>
                    <div className='mx-5' onClick={() => { if (props?.singleImageButton) setShow(props?.section === "then_now" ? false : true); setType(props?.type || 'banner') }}>
                        <div className='row'>
                            <Spinner display={show}>
                                <div className='col-8'> <ImageViewer url={props?.data?.image?.url ? props?.data?.image?.url : props?.section === "then_now" || props.section === "in_this_photo" ? props?.data?.url : props?.data?.image_url} type={props?.section === "then_now" ? 'thumb' : props?.type ? 'featured@3x' : "banner@3x"} cssClass={`${props?.type === 'featured' ? 'featured-image' : 'web-image'} cursor-pointer`} /></div>
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>}

            <EditImage show={show} key={show} onHide={() => setShow(false)} type={type} url={props?.data?.image?.url?.toLowerCase().endsWith('.pdf') ? props?.data?.image?.url?.replace('.pdf', '.png') : props?.section === "then_now" ? props?.data?.url : props?.data?.image?.url ? props?.data?.image?.url : props?.data?.image_url ? props?.data?.image_url : props?.data?.url} id={props?.data?.image?.id || props?.data?.image_id || props?.data?.id} title={props?.imageEditorTitle} />

            {
                uploader && <ImageUploader globalSearch={true} show={uploader} browse={false}
                    onHide={() => showUploader(false)}
                    onSave={async (items) => { onSaveImage(items); showUploader(false) }}
                />
            }
        </div>
    )
}

export default ImagePreview