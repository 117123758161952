import React from 'react';
// import { dateString } from '../../helpers';
import Panel from '../panel';

const Description = ({ data, title, dates, date_descriptions }) => {
    return (
        <>
            {
                (data || (data && dates.length > 0)) && <>
                    <hr />
                    <Panel title={title || 'Description'} lineNumber={4} description={data} bold={true} italic={true} expandable={true} />
                    {/* {
                        dates?.map((date, index) => {
                            let title = '';
                            title = date.event_type?.name ? title.concat(date.event_type?.name, ', ') : title;
                            title = date.date ? title.concat(dateString(date.date)) : title;

                            let findDescription = date_descriptions?.filter(x => x.id === date.id);
                            let description = findDescription?.length > 0 ? findDescription[0]?.description : '';

                            return (
                                 <div key={index} className='mt-2'>
                                    <Panel title={title} description={description} bold={true} italic={true} expandable={description.length > 0} />
                                </div>
                            )
                        })
                    } */}
                </>
            }
        </>
    )
}

export default Description;