import React from "react";

const FirstPage = () => {
    return (
        <>
            <div className='fs-large py-2 fw-bold'>PERMISSION AND RELEASE AGREEMENT</div>
            <div>
                CultureNOW is a Not-For-Profit Corporation formed under the laws of the State of New York and exempt from federal income taxation under section 501(c)(3) of the Internal Revenue Code.
            </div>
            <div className='fs-large py-2 fw-bold'>PHOTOGRAPH, DRAWING AND DOCUMENT RELEASE AND DIGITAL IMAGE RELEASE</div>
            <div>
                The owners and copyright holders of drawings, photographs, and other images submitted to cultureNOW, Inc in connection with the Museum Without Walls website and App hereby grant permission to cultureNOW for the following purposes:
            </div>
            <ol className="pt-2">
                <li>For use in the Museum Without Walls Website and App</li>
                <li>To use and reproduce this material as required</li>
                <li>For educational purposes, including audiovisual presentations, exhibitions, tours articles, pdf’s and books related to its Museum Without Walls</li>
                <li>For publicity purposes in other media to announce the Museum Without walls and cultureNOW programs in addition to media (inclusive of the culturenow website or partner organization websites in coordination with cultureNOW programming) or to educate the public about architecture, history and public art.</li>
                <li>For image recognition on Amazon or Google platforms</li>
                <li>It is understood that the images may be cropped or otherwise digitally edited to fit the design constraints of the platforms.</li>
            </ol>
            <div className='fs-large py-2 fw-bold'>Credit</div>
            <div>
                cultureNOW will include credit and any copyright on media that it publishes and will include this information with all material distributed to other media or retained in its library archives. cultureNOW will not be responsible for the failure of any other person or organization to include this information in their publicity.
            </div>
            <div className='fs-large py-2 fw-bold'>Copyright</div>
            <div>
                If materials submitted are copyrighted, the copyright notice must be made available to cultureNOW and can be uploaded here.
            </div>
            <div className='fs-large py-2 fw-bold'>Compensation</div>
            <div className='pb-4'>
                cultureNOW will not be required to pay royalties, make any other payment, or provide other consideration of any type. No Financial Remuneration will be received for the license, use, reproduction and release of the media.
            </div>
        </>
    )
}

export default FirstPage