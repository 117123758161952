/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import ImageViewer from '../../components/viewers/image';
import { personName } from '../../helpers';
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';
import Publication from '../../components/editors/publicationList';
import PodcastRelatedItemListView from '../../components/podcastRelatedItemListView';

const PeopleAssociatedSites = (props) => {
    const person = useSelector(state => state[props.id]);
    let sites = person?.sites?.length && person.credits.sites ? person.sites.length : 0
    let related_sites = person?.related_sites?.length ? person?.related_sites.length : 0
    let image_albums = person?.image_albums?.length && person.credits.image_albums ? person.image_albums.length : 0
    const [offsetIndex, setOffsetIndex] = useState({
        "sites": 0,
        "related_sites": sites,
        "image_albums": sites + related_sites,
        "publications": sites + related_sites + image_albums,
        "podcast": 0
    })

    return (
        <>
            {person && <>
                <PageTitle title={personName(person)} />
                <hr className='cnow-hr' />
                <div className='cnow-form-title'>Sites <span className='small fw-normal'>(Direct relationship to a site)</span></div>
                {person.credits?.sites && <div id='sites'>
                    {person.sites?.length > 0 && person.sites.map((item, index) => {
                        return (
                            <>
                                <div className='row g-1 mt-1' key={index}>
                                    <div className='col-auto'>
                                        {index >= 0 && <label className='cnow-form-label'>Order</label>}
                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={index + 1} autoComplete="off" />
                                    </div>
                                    <div className='col-auto'>
                                        <div>&nbsp;</div>
                                        <ImageViewer key={item.image_id} entityType='site' entityId={item.site_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        {index >= 0 && <label className='cnow-form-label'>Site Name</label>}
                                        <input type='text' className='form-control' disabled value={item?.site_name} autoComplete="off"></input>
                                    </div>
                                    <div className='col'>
                                        {index >= 0 && <label className='cnow-form-label'>Role</label>}
                                        <input type='text' className='form-control' disabled value={item?.role_name} autoComplete="off"></input>
                                    </div>
                                    <div className='col-auto'>
                                        {index >= 0 && <label className='cnow-form-label'></label>}
                                        <div className='btn-list'>
                                            <PreviewButton link={`site/${item?.site_id}`} />
                                            <LinkButton icon='edit' to={`/site/${item?.site_id}/editor`} />
                                        </div>
                                    </div>
                                </div>
                                {item?.individual_credits?.map((item, index) => {
                                    return (
                                        <div className='row g-1 mt-1'>

                                            <div className='col ms-5 ps-5'>
                                                <input type='text' className='form-control' disabled value={item?.person_name} autoComplete="off"></input>
                                            </div>
                                            <div className='col'>
                                                <input type='text' className='form-control' disabled value={item?.role_name} autoComplete="off"></input>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='btn-list'>
                                                    <PreviewButton link={`people/${item?.credited_person_id}`} />
                                                    <LinkButton icon='edit' to={`/people/${item?.credited_person_id}/editor`} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    })}
                </div>}

                <RelatedSiteEditor id={person.id} type='person' siteLength={(person.credits?.sites && person.sites?.length) ? person.sites?.length : 0} />
                <hr className='cnow-hr' />
                <div className='cnow-form-title'>Image Albums <span className='small fw-normal'>(Photographer/Collection)</span></div>
                {person.credits?.image_albums && <div id='image_albums'>
                    {person.image_albums?.length > 0 &&
                        person.image_albums.map((data, index) => {
                            return (
                                <div className='row g-1 mt-1' key={data.site_id || data.person_id}>
                                    <div className='col-auto'>
                                        <label className='cnow-form-label'>Order</label>
                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' value={offsetIndex.image_albums + index + 1} autoComplete="off" />
                                    </div>
                                    <div className='col-auto'>
                                        <div>&nbsp;</div>
                                        <ImageViewer key={data.image_id} entityType='image' entityId={data.image_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-label'>Site Title</label>
                                        <input type='text' className='form-control' disabled value={data.site_name || data.person_name} autoComplete="off"></input>
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-label'>Number of Images</label>
                                        <input type='text' className='form-control' value={`${data.site_images?.length || data.person_images?.length} Images of ${data.site_image_count || data.person_image_count}`} autoComplete="off" disabled></input>
                                    </div>
                                    <div className='col-auto'>
                                        <label className='cnow-form-label'></label>
                                        <div className='btn-list'>
                                            <PreviewButton icon='preview' link={`${data.entity_type}/${data.site_id || data.person_id}`} />
                                            <LinkButton icon='edit' to={`/${data.entity_type}/${data.site_id || data.person_id}/editor`} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>}


                {person.type !== 'publication' && <div id='publication'>
                    < hr className='cnow-hr' />
                    <div className='cnow-form-title'>Publication <span className='small fw-normal'>(Author/Editor)</span></div>
                    <Publication id={person.id} offsetIndex={offsetIndex} setOffsetIndex={setOffsetIndex} />

                </div>}

                {person.credits?.writing_articles && <div id='writing_articles'>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>Writing and Articles <span className='small fw-normal'>(Remarks attribution as an Author/Publication)</span></div>
                </div>}

                <div id='podcasts'>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>Podcasts  - Featuring Projects, Events, Tours, and People</div>
                    {person.credited_podcasts?.length > 0 &&
                        <PodcastRelatedItemListView
                            podcasts={person.credited_podcasts}
                            state={offsetIndex}
                        />
                    }
                </div>

            </>
            }
        </>
    );
};

export default PeopleAssociatedSites;