import React from 'react';

import Icon from '../viewers/icon';

const PreviewButton = (props) => {
    const color = props.color || 'light';
    const size = props.size || 'md';

    return (
        <a className={`btn btn-${size} btn-${color}`} href={`${process.env.REACT_APP_WEB_URL}${props.link}`} target='_blank' rel='noopener noreferrer'>
            <Icon name='preview' size={14} />
        </a>
    );
};

export default PreviewButton;