import React from 'react';

const Name = ({ data }) => {
    return (
        <>
            {data && <div className='fs-6 fst-italic'>{data}</div>}
        </>
    )
}

export default Name;