/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'

import Button from '../../components/buttons/default'
import Spinner from '../../components/spinner'
import ImageView from '../../components/viewers/image'
import LongTextView from '../../components/viewers/longText'

import CreditList from '../../components/table/creditList'
import RelatedItemList from '../../components/table/relatedItemList'
import TagList from '../../components/table/tagList'

import { getImageUrl, newTempId, TABLE_ROWS } from '../../helpers'
import ImageViewer from '../../components/viewers/image'

const _api = require('../../api')

const imageTypes = {
    'image': {
        'title': 'Upload Image',
        'description': 'Images must be JPG or PNG or GIF or TIFF or WebP format with size greater than 50KB and smaller than 100mb and resolution greater than 72 and smaller than 3840.',
        'accept': { 'image/*': [] }
    },
    'document': {
        'title': 'Upload Document & Drawings',
        'description': 'Images must be PDF or JPG or PNG or GIF or TIFF or WebP format with size greater than 50KB and smaller than 100mb and resolution greater than 72 and smaller than 3840.',
        'accept': { 'application/pdf': [], 'image/*': [] }
    },
    'dzi': {
        'title': 'Upload High Resolution Image',
        'description': 'Images must be JPG or PNG or GIF or TIFF or WebP format with size greater than 50KB and smaller than 100mb and resolution greater than 72.',
        'accept': { 'image/*': [] }
    },
    'then_now': {
        'title': 'Upload Then & Now',
        'description': 'Images must be JPG or PNG or GIF or TIFF or WebP format with size greater than 50KB and smaller than 100mb and resolution greater than 72 and smaller than 3840.',
        'accept': { 'image/*': [] }
    },
}

const ImageUploader = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const uploadRef = useRef(null)
    const history = useHistory()
    const location = useLocation()
    const [imageType, setImageType] = useState(props.replace ? 'image' : props.imageType)
    const [operation, setOperation] = useState('upload')
    const take = 48

    const [keyword, setKeyword] = useState('')
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [spinner, showSpinner] = useState(false)
    const [saveSpinner, setSaveSpinner] = useState(false)
    const [uploadErrors, setUploadErrors] = useState([])
    const [images, setImages] = useState([])

    const { getRootProps, getInputProps } = useDropzone({
        accept: imageType ? imageTypes[imageType].accept : 'image/*',
        onDrop: async (acceptedFiles) => uploadImage(acceptedFiles)
    })

    const entity = useSelector(state => state[props.entityId]);

    useEffect(() => {
        if (operation === 'globalSearch') getImages()
    }, [operation, page, take])

    const getImages = () => {
        showSpinner(true)
        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                ]
            }
        };

        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "caption^3",
                        "credits.person_name^2",
                        "related_item.categories.category_name",
                        "related_item.categories.subcategory_name",
                        "tags.tag_name",
                        "related_sites.related_site_name",
                        "related_people.related_person_name",
                        "podcasts.podcast_name",
                        "credits.role_name",
                        "people.profession.profession_name",
                        "image_category",
                        "locations.address",
                        "theme.theme_name",
                        "related_item.name",
                        "related_item.themes.theme_name",
                        "type",

                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }

        if (imageType) {
            params.bool.must[2] = {
                "bool": {
                    "should": [
                        {
                            "match": {
                                "type": imageType
                            }
                        }
                    ]
                }
            }
        }
        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }

        _api.image.searchByElastic((page - 1) * 50, take, finalParams).then(images => {
            setItems(images?.hits)
            showSpinner(false)
        })
    }

    const checkDimension = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                let img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                    resolve((this.height * this.width) > 200000000);
                };
            }
        })
    }

    const fileErrors = (file) => {
        const error = { 'filename': file.name, 'error': '' }
        const allowedExt = ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'pdf']
        const ext = file.name.split('.')[file.name.split('.').length - 1].toLowerCase()

        if (!allowedExt.includes(ext)) error.error = 'has incorrect format / extension'
        else if (file.size <= 50000) error.error = 'is too small, please upload images larger than 50kb'
        else if (file.size >= 100000000) error.error = 'is too big, please upload images smaller than 100mb'

        return error.error ? error : null
    }

    // Done
    const uploadImage = async (items) => {
        setSaveSpinner(true)
        let accepted = []
        let rejected = []

        items && items.length > 0 && items.forEach(item => {
            item.id = newTempId()
            if (fileErrors(item)) rejected.push(fileErrors(item))
            else accepted.push({ 'file': item, uploading: true })
        })

        let allImages = [].concat(images).concat(accepted)
        setImages(allImages)
        setUploadErrors(rejected)

        accepted = await accepted.reduce(async (previousPromise, file) => {
            let temp = await previousPromise;
            let InValidDimension;
            if (file.file.type !== 'application/pdf') {
                InValidDimension = await checkDimension(file.file)
                InValidDimension ? rejected.push({ 'filename': file.file.name, 'error': 'file exceeds 200 million pixel limit' }) : temp.push(file);
            }
            else {
                temp.push(file)
            }
            return temp
        }, Promise.resolve([]))

        setUploadErrors(rejected)
        allImages = [].concat(images).concat(accepted)
        setImages(allImages)

        accepted.forEach(async (file) => {
            let image;

            try {
                // 1. create image object
                image = await _api.image.update({
                    type: imageType === 'then_now' ? 'image' : imageType,
                    created_via: "ADMIN"

                });

                // 2. get file upload url
                const ext = file.file.name.split('.')[file.file.name.split('.').length - 1]?.toLowerCase();
                const uploadUrl = await _api.file.getUploadUrl({
                    name: `img/${image.id}/original.${ext}`,
                    contentType: file.file.type
                });

                // 3. upload file to storage
                await _api.file.upload(uploadUrl.url, file.file, { headers: { 'Content-Type': file.file.type } });
                image.url = uploadUrl.url.split('?')[0];

                //   adding user id to image upload 
                if (user?.id) {
                    image.user_id = user.parent_id || user.id;
                }
                // 4. update image url
                await _api.image.update(image);

                // 5. process image
                await _api.image.process(image);

                const index = allImages.findIndex(x => x.file.id === file.file.id);
                if (index >= 0) {
                    allImages[index] = image;
                    allImages[index].uploading = false;
                    allImages[index].file = file.file;
                }

                setImages([].concat(allImages));
                if (allImages.filter(x => x.uploading === true).length === 0) setSaveSpinner(false)
            } catch (error) {
                // Handle the error
                setUploadErrors("Error Uploading the file")
                console.log('Error:', error);
            }
        });
    }

    const processThenNow = async () => {
        showSpinner(true)
        setSaveSpinner(true)
        let image = {
            url: '',
            type: 'then_now'
        }

        if (user?.id) {
            image.user_id = user.parent_id || user.id;
        }
        // 1. Create new Then & Now image
        image = await _api.image.update(image)

        //in entitysearch the images are already uploaded
        if (operation === 'entitySearch') {
            const uploadUrl = await _api.file.getUploadUrl({
                name: `img/${image.id}/original.jpg`,
                contentType: 'image'
            })
            image.url = uploadUrl.url.split('?')[0]
            await _api.image.update(image)
        } else {
            const file = images[0]?.file

            // 2. get file upload url
            const ext = file?.name?.split('.')[1]
            const uploadUrl = await _api.file.getUploadUrl({
                name: `img/${image.id}/original.${ext}`,
                contentType: file?.type
            })

            // 3. upload file to storage
            await _api.file.upload(uploadUrl.url, file, { headers: { 'Content-Type': file.type } })
            image.url = uploadUrl.url.split('?')[0]
        }

        // 4. update image url
        await _api.image.update(image)

        // 5. related images
        let related_images = []
        for (let i in images) {
            let related_img = {
                'image_id': image.id,
                'sort': i + 1
            }
            if (operation === 'entitySearch') {
                related_img.related_image_id = images[i].image_id
            } else {
                related_img.related_image_id = images[i].id
            }
            related_images.push(related_img);
        }
        await _api.image.updateRelatedImage(image.id, related_images)

        // 6. process image
        await _api.image.process(image)

        showSpinner(false)
        setSaveSpinner(false)
        return image
    }

    const handleSave = async () => {
        setSaveSpinner(true)
        let image = images[0]
        //operation search no need to process then_now
        if (imageType === 'then_now' && operation !== 'globalSearch') {
            image = await processThenNow()
            images.splice(0, 0, image)
        }
        if (props.onSave) {
            props.onSave(images)
        }
        props.onHide();
        setSaveSpinner(false)
    }

    const handleSaveAndRedirect = async () => {
        await handleSave()
        dispatch({ type: 'images', payload: { entityImages: images, source: location.pathname, type: props?.entityType } })
        history.push({ pathname: `/image/${images[0].id}/editor` })
    }

    const handleSelect = (e, item) => {
        let myImages = [].concat(images)

        if (e.target.checked) myImages.push(item)
        else myImages = myImages.filter(x => x.id !== item.id)

        setImages(myImages)
    }

    const handleUpload = () => {
        setOperation('upload')
        if (uploadRef && uploadRef.current) {
            uploadRef.current.click()
        }
    }

    const handleEntityImageClick = (image) => {
        if (images.findIndex(x => x.id === image.id) < 0) {
            image.uploading = false;
            image.url = image.image_url;
            setImages([...images, image]);
        } else {
            setImages(images.filter(x => x.id !== image.id))
        }
    }

    // const handleClickedImages = (image) => {
    //     let selectedImage = [...images];
    //     if ((selectedImage.findIndex(x => x.id === image.id) === -1)) {
    //         selectedImage.push(image)
    //     }
    //     else {
    //         selectedImage = selectedImage.filter(x => x.id !== image.id)
    //     }
    //     setImages(selectedImage)
    // }

    // const handleSaveThenAndNow = async () => {

    //     showSpinner(true)
    //     let image = {
    //         type: 'then_now',
    //         url: images[0].url
    //     }

    //     const response = await axios.post('image', image)
    //     image = response.data
    //     image.url = image.url ? image.url.replace(images[0].id, image.id) : ''
    //     images.forEach(item => image.images.push(getMiniImage(item)))

    //     await axios.put(`image/${image.id}`, image)

    //     let related_images = []
    //     for (let i in images) {
    //         related_images.push({
    //             'image_id': image.id,
    //             'related_image_id': images[i].id,
    //             'sort': i + 1
    //         })
    //     }

    //     await axios.post(`image/${image.id}/related_image`, related_images)

    //     await axios.post(`image/${image.id}/process`, {
    //         type: 'then_now'
    //     })

    //     setTimeout(() => {
    //         props.onSave(image, 'fromEntity')
    //         showSpinner(false)
    //     }, 10000)
    // }

    return (
        <Modal className='modal-main' show={props.show} size='xl' onHide={() => { props.onHide(false) }}>
            <Modal.Body>
                {
                    !imageType && <>
                        <div className='cnow-form-title'>UPLOAD IMAGE</div>
                        <div className='btn-list'>
                            <Button label='Image' onClick={() => setImageType('image')} />
                            <Button label='Drawings & Documents' onClick={() => setImageType('document')} />
                            <Button label='High Resolution Image' onClick={() => setImageType('dzi')} />
                            {props.thenAndNow && <Button label='Then & Now Image' onClick={() => setImageType('then_now')} />}
                        </div>
                    </>
                }

                {
                    imageType && <>
                        <div className='row'>
                            <div className='col'>
                                <div className='cnow-form-title'>
                                    {imageTypes[imageType].title}
                                </div>
                                <div className='small'>{imageTypes[imageType].description}</div>
                            </div>
                            <div className='col-auto'>
                                <div className='d-flex justify-content-end gap-1'>
                                    <Spinner display={saveSpinner}>
                                        {
                                            images && images.length > 0 && <Button label='Save' onClick={() => handleSave()} />
                                        }
                                        <Button icon='close' target='_self' onClick={() => props.onHide(false)} />
                                    </Spinner>
                                </div>
                            </div>
                        </div>


                        <div className='btn-list my-2'>
                            {   //the default upload btn not shown if images are from the entity to create then n now
                                props.browse !== false && <Button label='Browse Computer / iPad / Phone' onClick={handleUpload} />
                            }
                            {
                                props.globalSearch === true &&
                                <Button label={`${props?.thenAndNow ? 'Add Then & Now from cultureNOW database' : 'Add Image from cultureNOW database'}`} onClick={() => setOperation('globalSearch')} />
                            }
                            {
                                props.entitySearch && <Button label={`Add Image from ${props.type}`} onClick={() => setOperation('entitySearch')} />
                            }
                        </div>

                        {
                            operation === 'upload' && props.browse !== false &&
                            <>
                                {
                                    <div {...getRootProps({ className: 'dropzone' })} ref={uploadRef}>
                                        <input {...getInputProps()} />
                                        <div className='cnow-form-title'>{imageTypes[imageType].title}</div>
                                        <p>Drop your file(s) anywhere in this window</p>
                                        {
                                            images.length > 0 && images.filter(x => x.uploading === true).length > 0 && <>
                                                <div className='progress w-25'>
                                                    <div className='progress-bar progress-bar-striped progress-bar-animated bg-danger' role='progressbar' style={{ width: `${parseInt(((images.filter(x => x.uploading === false).length || 0) * 100) / images.length)}%` }}></div>
                                                </div>
                                                <p>Uploading {images.filter(x => x.uploading === true).length || 1} out of {images.length}</p>
                                            </>
                                        }
                                    </div>
                                }

                                {
                                    uploadErrors.length > 0 && <div className='dropzone-error small'>
                                        <div className='cnow-form-title'>Unable to upload {uploadErrors.length} images, see error below</div>
                                        {
                                            uploadErrors.map((error, index) => {
                                                return (
                                                    <div key={index}>
                                                        <b>{error.filename}: </b> {error.error}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </>
                        }

                        {
                            operation === 'globalSearch' && <>
                                <div className='row g-1 mt-2'>
                                    <div className='col'>
                                        <input className='form-control bg-light border-start-0' type='text' placeholder='Search for images from database...' value={keyword}
                                            onChange={(event) => { setKeyword(event.target.value) }}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); getImages() } }}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='col-auto'>
                                        <div className='btn-list'>
                                            <Button label='Submit' onClick={() => { setPage(1); getImages() }} />
                                        </div>
                                    </div>
                                </div>

                                <Spinner display={spinner}>
                                    <div className='mt-2'>
                                        <table className='table small'>
                                            <thead>
                                                <tr className='bg-light small'>
                                                    <th style={{ width: '100px' }}>Thumbnail</th>
                                                    <th style={{ width: '150px' }}>Image ID</th>
                                                    <th style={{ width: '270px' }}>Original Caption</th>
                                                    <th>Related Sites</th>
                                                    <th style={{ width: '200px' }}>Photo Credit</th>
                                                    <th>Public Tags</th>
                                                    <th className='text-center'>Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    items?.hits?.map((item, index) => {
                                                        const isChecked = images && images.findIndex(x => x.id === item?._source?.id) >= 0
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <ImageView url={item?._source?.url} type='thumb' cssClass='thumb' />
                                                                </td>
                                                                <td>{item?._source?.id}</td>
                                                                <td>
                                                                    <LongTextView lines={TABLE_ROWS + 1}>{item?._source?.caption}</LongTextView>
                                                                </td>
                                                                <RelatedItemList data={item?._source?.related_items} />
                                                                <CreditList data={item?._source?.credits} />
                                                                <TagList data={item?._source?.tags} />
                                                                <td>
                                                                    <div className='form-check align d-flex justify-content-center'>
                                                                        <input id={item?._source?.id} className='form-check-input' type='checkbox' name='select_site' defaultChecked={isChecked} onChange={(e) => handleSelect(e, item?._source)}
                                                                            disabled={props?.items?.length > 0 && props?.items?.findIndex((x) => x.id === item?._source?.id) >= 0 ? true : false} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Spinner>
                            </>
                        }


                        {
                            operation === 'entitySearch' && <>
                                <Spinner display={spinner}>
                                    <div className='mt-2'>
                                        <div className='cnow-form-title cnow-preview-title d-flex justify-content-between '>
                                            <div>Then And Now Images</div>
                                        </div>
                                        {entity.images?.length > 0 && entity.images.filter(image => (image.type !== 'then_now')).map((image, index) => {
                                            return <span onClick={() => handleEntityImageClick(image)} key={index} id={image.id}>
                                                <ImageViewer entityId={image.image_id} entityType='image' type='thumb' cssClass={`thumb p-1 ${images.findIndex(x => x.id === image.id) !== -1 ? 'highlight-box' : ''}`} />
                                            </span>
                                        })
                                        }
                                    </div>
                                </Spinner>
                            </>
                        }
                    </>
                }

                {
                    images.length > 0 && <>
                        {
                            images.length > 0 && images.filter(x => x.uploading === false).length > 0 &&
                            <div className='small fw-bold mt-2'>You’ve successfully uploaded {images.filter(x => x.uploading === false).length || 0} out of {images.length} file(s)</div>
                        }

                        <aside className='row my-1 g-1'>
                            {
                                images.map((image, index) => {
                                    const spinner = image.url && image.url.length > 0 ? false : true
                                    return (
                                        <div key={index} className='col-auto'>
                                            <Spinner display={spinner}>
                                                <img className='thumb' src={getImageUrl(image.url, 'thumb')} alt={image.id} />
                                            </Spinner>
                                        </div>
                                    )
                                })
                            }
                        </aside>

                        {
                            !props.replace && images.length > 0 && images.filter(x => x.uploading === true).length === 0 &&
                            <div className='mt-2'>
                                <Spinner display={spinner}>
                                    <Button label='Add description for all images' color='danger' onClick={handleSaveAndRedirect} />
                                </Spinner>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default ImageUploader