/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import MapMenu from './menu';
import MapPreview from './preview';
import MapDate from './date';
import MapPeople from './people';
import MapMetaDescription from './meta';
import MapAbout from './about';
import MenuTitle from './title';
import MapLocation from './location';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import MapPodcast from './podcast'
import Spinner from '../../components/spinner';
import MapImages from './image';
import OnThisDayInThisPhoto from './onThisDayInThisPhoto';
import EventsTours from './eventsTours';
import Legal from '../../components/legal/editor';
import CuratorManagement from './curator';
import { toast } from 'react-toastify';
const _api = require('../../api');

const MapEditor = (props) => {

  const mapId = props.match.params.id || 'NEW';
  const section = props.match.params.section || 'title';
  const sectionId = props.match.params.sectionId || '';
  const { id: userId, parent_id: parentId } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const map = useSelector((state) => state[mapId]);
  const menuSize = useSelector((state) => state.menu);
  const previewSize = useSelector((state) => state.preview);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    if (mapId === 'NEW') {
      dispatch({
        type: 'entity', payload: {
          'id': 'NEW',
          'description': '',
          'meta': {},
          'deleted': false,
          'live': false,
          'overlay_description': '',
          'overlay_title': '',
          'title_url': '',
          'name': '',
          'categories': [],
          'themes': [],
          'user_id': parentId || userId
        }
      })
    } else {
      getMapData()
    }
  }, [sectionId, section, mapId]);

  useEffect(() => {
    return () => {
      const data = JSON.parse(localStorage.getItem('cnow-cms'))
      delete data[mapId]
      localStorage.setItem('cnow-cms', JSON.stringify(data))
    }
  }, [])


  const getMapData = async () => {
    showSpinner(true);
    let myMap = await _api.map.getById(mapId);
    myMap.overlays = await _api.map.getOverlay(mapId) || []

    if (section === 'title') {
      myMap.categories = await _api.map.getCategory(mapId)
      myMap.themes = await _api.map.getTheme(mapId)
    }

    if (section === 'location') {
      myMap.locations = await _api.map.getLocation(mapId)
    }

    if (section === 'date') {
      myMap.dates = await _api.map.getDate(mapId)
    }

    if (section === 'about') {
      myMap.dates = await _api.map.getDate(mapId)
      myMap.stories = await _api.map.getStory(mapId)
      myMap.tags = await _api.map.getTag(mapId) || []
      myMap.related_sites = await _api.map.getRelatedSite(mapId)
    }

    if (section === 'images') {
      myMap.dates = await _api.map.getDate(mapId)
      myMap.images = await _api.map.getImage(mapId)
    }

    if (section === 'people') {
      myMap.dates = await _api.map.getDate(mapId)
      myMap.people = await _api.map.getPerson(mapId)
      myMap.related_people = await _api.map.getRelatedPerson(mapId)
    }

    if (section === 'attach_map') {
      myMap.maps = await _api.map.getRelatedMap(mapId)
    }

    if (section === 'events_tours') {
      myMap.related_tours = await _api.map.getTour(mapId) || []
      myMap.related_events = await _api.map.getEvent(mapId) || []
      myMap.related_event_series = await _api.map.getEventSeries(mapId) || []
    }

    if (section === 'podcasts') {
      myMap.dates = await _api.map.getDate(mapId)
      myMap.podcasts = await _api.map.getPodcast(mapId)
      myMap.playlists = await _api.map.getPlaylist(mapId)
    }

    if (section === 'on_this_day') {
      myMap.images = await _api.map.getImage(mapId)
      myMap.on_this_day = await _api.map.getOnThisDay(mapId)
    }

    if (section === 'curator') {
      myMap.images = await _api.map.getImage(mapId)
      myMap.podcasts = await _api.map.getPodcast(mapId)
      myMap.curator = await _api.map.getCurator(mapId)
    }

    if (section === 'legal') {
      myMap.images = await _api.map.getImage(mapId)
      myMap.podcasts = await _api.map.getPodcast(mapId)
    }

    dispatch({ type: 'entity', payload: myMap })
    showSpinner(false);
  }

  const updateAndRedirect = async (path) => {
    if (user.id) {
      map.created_via = "ADMIN"
    }

    if (!path) {
      path = section;
      if (sectionId) path = path.concat('/', sectionId);
    }

    showSpinner(true);
    if (map.id === 'NEW') {
      map.id = null
      map.modified_by = null
    } else {
      map.modified_by = user.id
    }

    let isValidVanityUrl = true;

    if (section === 'meta') {
      const { isValid, message } = await _api.vanity_url.validateVanityUrl({ vanity_url: map.meta.vanity_url, id: map.id, entity: "map" })
      isValidVanityUrl = isValid;
      if (!isValid) toast.error(message);
      showSpinner(false);
    }

    let myMap
    
    if (isValidVanityUrl) {
      myMap = await _api.map.update(map)
    }

    if (section === 'title' || !section) {
      myMap.overlays = await _api.map.updateOverlay(myMap.id, map.overlays)
      myMap.categories = await _api.map.updateCategory(myMap.id, map.categories)
      myMap.themes = await _api.map.updateTheme(myMap.id, map.themes)
    }

    if (section === 'date') {
      myMap.dates = await _api.map.updateDate(myMap.id, map.dates)
    }

    if (section === 'location') {
      myMap.locations = await _api.map.updateLocation(myMap.id, map.locations)
    }

    if (section === 'people') {
      myMap.people = await _api.map.updatePerson(myMap.id, map.people)
      if (map.related_people?.length > 0) {
        let related_people = []
        for (const item of map.related_people) {
          if (item.person_id) {
            let response = await _api.person.updateMap(item.person_id, [item])
            related_people.push(response[0])
          }
        }
        myMap.related_people = related_people
      }
    }

    if (section === 'about') {
      myMap.dates = await _api.map.updateDate(myMap.id, map.dates)
      myMap.stories = await _api.map.updateStory(myMap.id, map.stories)
      myMap.tags = await _api.map.updateTag(myMap.id, map.tags)
      if (map.related_sites?.length > 0) {
        let related_site = []
        for (const item of map.related_sites) {
          if (item.site_id) {
            let response = await _api.site.updateMap(item.site_id, [item])
            related_site.push(response[0])
          }
        }
        myMap.related_sites = related_site
      }
    }

    if (section === 'images') {
      myMap.images = await _api.map.updateImage(myMap.id, map.images)
    }

    if (section === 'podcasts') {
      myMap.podcasts = await _api.map.updatePodcast(myMap.id, map.podcasts)
      myMap.playlists = await _api.map.updatePlaylist(myMap.id, map.playlists)
    }

    if (section === 'on_this_day') {
      myMap.on_this_day = await _api.map.updateOnThisDay(map.on_this_day)
    }


    if (section === 'attach_map') {
      myMap.maps = await _api.map.updateRelatedMap(myMap.id, map.maps)
    }

    if (section === 'curator') {
      myMap.curator = await _api.map.updateCurator(myMap.id, map.curator)
    }

    if (section === 'events_tours') {
      myMap.related_tours = await _api.map.updateTour(myMap.id, map.related_tours)
      myMap.related_events = await _api.map.updateEvent(myMap.id, map.related_events)
      myMap.related_event_series = await _api.map.updateEventSeries(myMap.id, map.related_event_series)
    }

    dispatch({ type: 'entity', payload: myMap })
    history.push(`/map/${myMap.id}/editor/${path}`)
    showSpinner(false);
  }

  return (
    <div className='cnow-editor-container'>
      <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
        <MapMenu id={mapId} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
      </div>
      <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
        <Spinner display={spinner}>
          {section === 'title' && <MenuTitle id={mapId} />}
          {section === 'location' && <MapLocation id={mapId} locationId={sectionId} onUpdate={(path) => updateAndRedirect(path)} type={'map'} />}
          {section === 'date' && <MapDate id={mapId} />}
          {section === 'people' && <MapPeople id={mapId} />}
          {section === 'about' && <MapAbout id={mapId} />}
          {section === 'images' && <MapImages id={mapId} onUpdate={() => updateAndRedirect()} />}
          {section === 'meta' && <MapMetaDescription id={mapId} />}
          {section === 'attach_map' && <RelatedMapEditor id={mapId} type='map' mapLabelTextEditor={'Remarks'} title={'Related Maps'} />}
          {section === 'events_tours' && <EventsTours id={mapId} />}
          {section === 'podcasts' && <MapPodcast id={mapId} />}
          {section === 'on_this_day' && <OnThisDayInThisPhoto id={mapId} onUpdate={() => updateAndRedirect()} />}
          {section === 'legal' && <Legal id={mapId} />}
          {section === 'curator' && <CuratorManagement id={mapId} />}
        </Spinner>
      </div>
      <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
        <MapPreview id={mapId} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
  libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(MapEditor);
//export default MapEditor;