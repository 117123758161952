import React from 'react'
import ImageViewer from '../viewers/image'

const MapCard = ({ data }) => {
    return (
        <div className='w-25'>
            <ImageViewer entityId={data?.map_id} entityType={"map"} type='thumb' cssClass='thumb p-1' />
            <div className='small cnow-ellipsis-1'> {data?.map_name}</div>
        </div>
    )
}

export default MapCard