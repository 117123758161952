import React, { useState, useRef, useEffect } from 'react';
import ImageView from './image';
import Icon from './icon';

const TimeLine = ({ data, setOpacityStyle, section }) => {
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(true);

    const [slideId, setSlideId] = useState(null);
    useEffect(() => {
        if (scrl.current) scrollCheck()
        // eslint-disable-next-line
    }, [data?.length])

    //Slide click
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (scrl.current.scrollWidth === scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrl?.current?.scrollLeft);

        if (scrl.current.scrollWidth === scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    return (
        <>
            {data?.length > 0 &&
                <div className={`d-flex ${data.length > 0 && 'entity-timeline-container'}`}>
                    {scrollX !== 0 && (
                        <div className={section === 'homePage' ? 'timeline-scroll-left-home' : 'timeline-scroll-left'} onClick={() => slide(-250)}>
                            <Icon name="arrow-left" size={30} />
                        </div>
                    )}
                    <div className={`d-flex entity-timeline`} ref={scrl} onScroll={scrollCheck}
                    >
                        <div className={`mb-4 d-flex`} onMouseLeave={() => setSlideId(null)}>
                            {   // eslint-disable-next-line
                                data.map((item, index) => {
                                    if (item?.section[0]?.url) {
                                        item.section[0].url = item?.section[0].url.replace('.pdf', '.jpg')
                                        return (
                                            <EntityTimeLineSection
                                                key={index}
                                                data={item}
                                                setOpacityStyle={setOpacityStyle}
                                                setSlideId={setSlideId}
                                                slideId={slideId}
                                                scrollCheck={scrollCheck}
                                            />
                                        )
                                    }
                                })}
                        </div>

                    </div>
                    {!scrolEnd && (
                        <div className={section === 'homePage' ? 'timeline-scroll-right-home' : 'timeline-scroll-right'} onClick={() => slide(+250)}>
                            <Icon name="arrow-right" size={30} />
                        </div>
                    )}
                </div>
            }
        </>
    )
}

const EntityTimeLineSection = ({ data, setOpacityStyle, setSlideId, slideId, scrollCheck }) => {
    const [more, setMore] = useState(false);

    const moreCount = data.section?.length - 1 <= 0 ? `` : `- ${data.section?.length - 1}`;
    const lessCount = data.section?.length - 1 <= 0 ? `` : `+ ${data.section?.length - 1}`;

    let timer;

    const setStyle = (opacityValue, link, uniqueId) => {
        setSlideId(uniqueId);
        setOpacityStyle((previous) => { return { ...previous, id: uniqueId, sectionId: link, opacValue: opacityValue } })
    }

    const handleScroll = () => {
        setTimeout(() => {
            scrollCheck()
        }, 100)
    }

    return (
        <>
            {data.section?.length > 0 && data?.year > 0 &&
                <div className='entity-timeline-section'>
                    <div className='d-flex'>
                        <div className={'entity-timeline-item'}
                            onMouseEnter={() => setStyle(0.5, data.section[0]?.sectionId, data.section[0]?.id)}
                            onMouseLeave={() => setStyle(1, data.section[0]?.sectionId, null)} >
                            <div className='mb-3 entity-timeline-image-container'>
                                <div className="entity-timeline-image"
                                    style={slideId === data.section[0]?.id || slideId === null ? { opacity: 1 } : { opacity: .5 }}
                                >
                                    {data.section?.length > 1 ? (
                                        <a href={more ? "#" + data.section[0]?.id : null}
                                            onClick={() => {
                                                clearTimeout(timer)
                                                timer = setTimeout(() => {
                                                    setMore(true)
                                                }, 0)
                                                handleScroll()
                                            }}>
                                            <ImageView image_typedoc={data.section[0]?.image_type} url={data.section[0]?.url} type="thumb" cssClass={"thumb-small-timeline"} />
                                        </a>
                                    ) :
                                        data.section[0]?.sectionId ?
                                            <a href={"#" + data.section[0]?.id}>
                                                <ImageView image_typedoc={data.section[0]?.image_type} url={data.section[0]?.url} type="thumb" cssClass={"thumb-small-timeline"} />
                                            </a>
                                            :
                                            <ImageView image_typedoc={data.section[0]?.image_type} url={data.section[0]?.url} type="thumb" cssClass={"thumb-small-timeline"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ display: more ? 'flex' : 'none' }}>
                            {data.section?.slice(1)?.map((dat, idx) => {
                                return (
                                    <div className={''} key={idx}>
                                        <div className='me-2 mb-3 entity-timeline-image-container'>
                                            <div className="entity-timeline-image" onMouseEnter={() => setStyle(0.5, dat?.sectionId, dat?.id)}
                                                onMouseLeave={() => setStyle(1, dat?.sectionId, dat?.id)} style={slideId === dat?.id || slideId === null ? { opacity: 1 } : { opacity: .5 }}>
                                                {dat?.id ?
                                                    <a href={'#' + dat?.id}>
                                                        <ImageView url={dat?.url} type='thumb' cssClass={'thumb-small-timeline'} />
                                                    </a>
                                                    :
                                                    <ImageView url={dat?.url} type='thumb' cssClass={'thumb-small-timeline'} />}
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    </div>
                    <div className='d-flex justify-content-between entity-timeline-year-section'>
                        <span className='fz-12'>{data.year ? data.year : ''}</span>
                        <span className='fz-12 align-self-center cursor-pointer' onClick={() => {
                            setMore(!more)
                            handleScroll()
                        }}>{more ? moreCount : lessCount}</span>
                    </div>
                </div>}
        </>
    )
}
export default TimeLine;