import React from "react";
import { useSelector } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";

const CuratorManagement = ({ id }) => {
    const recipe = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={recipe.name} />
            <LegalHistory id={id} type={'recipe'} />
            <EntityOfDayCheckBox type={'recipe'} id={id} entity_day={'Recipe'} show_entity_of_day={'not_show_recipe_of_the_day'} key={recipe?.curator?.not_show_recipe_of_the_day} />
        </>
    )
}

export default CuratorManagement

