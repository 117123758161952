/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uniqid from 'uniqid'
import Dragula from "react-dragula";

import ImageViewer from "../viewers/image";
import TextEditor from './textEditor';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId } from '../../helpers';
import SelectTour from '../selects/tour';

const _api = require('../../api')

const RelatedTourEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-related-tours'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.related_tours, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, related_tours: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addRelatedTour = () => {
        if (!entity.related_tours) entity.related_tours = [];
        if (props.type === 'tour') {
            entity.related_tours.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,
                [`${props.type}_id`]: props?.id,
                'related_tour_id': "",
                'related_tour_name': "",
                'sort': entity.related_tours ? entity.related_tours.length + 1 : 1,
            });
        } else {
            entity.related_tours.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,
                [`${props.type}_id`]: props?.id,
                'tour_id': "",
                'tour_name': "",
                'sort': entity.related_tours ? entity.related_tours.length + 1 : 1,
            });
        }
        entity.related_tours.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, related_tours: entity.related_tours } });
    }

    const removeRelatedTour = async (index) => {
        if (entity.related_tours[index]) {
            if (!entity.related_tours[index].id.startsWith('temp.')) {
                await _api[props.type].deleteTour(entity.related_tours[index].id)
            }

            entity.related_tours.splice(index, 1)
            entity.related_tours.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, related_tours: entity.related_tours } });
        }
    }

    const handleTourChange = (index, value) => {
        if (props.type === 'tour') {
            { console.log(value, "value"); }
            entity.related_tours[index].related_tour_id = value.id
            entity.related_tours[index].related_tour_name = value.name
        } else {
            entity.related_tours[index].tour_id = value.id
            entity.related_tours[index].tour_name = value.name
        }
        dispatch({ type: 'entity', payload: { ...entity, related_tours: entity.related_tours } });
    }

    const handleEditorChange = (index, data) => {
        entity.related_tours[index].description = data;
        dispatch({ type: 'entity', payload: { ...entity, related_tours: entity.related_tours } });
    }

    return (
        <div>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Related Tours</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Tour' onClick={() => addRelatedTour()} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.related_tours?.length > 0 && entity.related_tours.map((item, index) => {
                        { console.log(item, "item"); }
                        return (
                            <div key={index} id={item.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`}>
                                <div className='row g-1'>
                                    {
                                        entity?.related_tours?.length > 1 &&
                                        <div className='col-auto'>
                                            <label className='cnow-form-label fw-bold'>Order</label>
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <ImageViewer key={item?.id} entityType='tour' entityId={item?.related_tour_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-label fw-bold'>Title</label>
                                        <div className='row g-1'>
                                            <div className='col'>
                                                {!item.id.startsWith('temp.') ?
                                                    <input value={props.type === 'tour' ? item?.related_tour_name : item?.tour_name} disabled className='h-100 w-100' />
                                                    :
                                                    <SelectTour key={item.id}
                                                        value={{
                                                            id: props.type === 'tour' ? item?.related_tour_id : item?.tour_id,
                                                            name: props.type === 'tour' ? item?.related_tour_name : item?.tour_name
                                                        }}
                                                        onChange={(value) => handleTourChange(index, value)} />
                                                }
                                            </div>
                                            <div className='col-auto'>
                                                <div className='btn-list'>
                                                    <PreviewButton link={`tour/${props.type === 'tour' ? item?.related_tour_id : item?.tour_id}`} />
                                                    <LinkButton icon='edit' to={`/tour/${props.type === 'tour' ? item?.related_tour_id : item?.tour_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeRelatedTour(index)} />
                                                </div>
                                            </div>
                                        </div>
                                        {!props?.disableTextEditor && <TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                        </TextEditor>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RelatedTourEditor;