/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import Icon from '../../components/viewers/icon';
import TableHeader from '../../components/tableHeader';
import LinkButton from '../../components/buttons/link';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import PreviewButton from '../../components/buttons/preview';
import swal from 'sweetalert';
import LocationList from '../../components/table/locationList';
import EventType from '../../components/table/eventType';
import RelatedEventList from '../../components/table/relatedEvent';
import TagList from '../../components/table/tagList';
import LongTextView from '../../components/viewers/longText';
import { TABLE_ROWS, checkUserTypeAndReturnIds } from '../../helpers';
import PodcastList from '../../components/table/podcastList';
import PlayList from '../../components/table/playlist';
import RelatedRecipeList from '../../components/table/relatedRecipeList';
import RelatedSiteList from '../../components/table/relatedSiteList';
import RelatedPeopleList from '../../components/table/relatedPeopleList';
import RelatedTourList from '../../components/table/relatedTourList';
import RelatedMapList from '../../components/table/relatedMapList';
import { useSelector } from 'react-redux';
import EventSeriesPeopleList from '../../components/table/eventSeriesPeopleList';

const _api = require('../../api');
const columns = require('../../data/columns_event_series.json');

const EventSeries = (props) => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        search()
    }, [page, sort, keyword, user.id]);

    const search = async () => {

        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                    { "match": { "created_via": "ADMIN" } },
                ]
            }
        };

        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^3",
                        "host.person_name^2",
                        "locations.name",
                        "locations.city",
                        "locations.state",
                        "locations.country",
                        "categories.category_name",
                        "categories.subcategory_name",
                        "tag.tag_name",
                        "related_sites.site_name",
                        "related_people.person_name",
                        "podcasts.podcast_name",
                        "podcasts.podcast_type",
                        "people.role_name",
                        "people.profession.profession_name",
                        "related_people.org_type.organization_name",
                        "locations.address",
                        "themes.theme_name",
                        "types.type_name",
                        "featured.event_name",
                        "related_event.event_name",
                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }


        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }


        showSpinner(true)

        await _api.event_series.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
            setSearchResult(results?.hits)
            showSpinner(false)
        })
    }

    const handleDelete = (event) => {
        swal({
            title: `Delete Event Series`,
            text: `Are you sure that you want to delete ${event?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.event_series.deleteById(event.id).then(() => { search() })
        });
    }
    const updateLive = (event_series, live) => {

        showSpinner(true)

        if (live === true) {
            _api.event_series.updateLive(event_series?.id, live).then((_) => search())
            swal({
                title: 'Event Series is Live!',
                icon: 'success',
                text: `${event_series?.name} is now Live!`,
                buttons: 'Ok',
            })
        } else {
            _api.event_series.updateLive(event_series?.id, live).then((_) => search())
            swal({
                title: 'Not Live!',
                icon: 'info',
                text: `${event_series?.name} is Not Live.`,
                buttons: 'Ok',
            })
        }
        showSpinner(false)
    }


    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add an Event Series' color='danger' icon='plus-white' onClick={() => history.push('/event_series/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0'>EventSeries</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                                    {
                                        keyword && <span className='badge badge-search'>
                                            {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                                        </span>
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchResult?.hits?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_event' value={item?._source.id} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/event_series/${item?._source.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item?._source) }} />
                                                                <PreviewButton size='sm' link={`event_series/${item?._source?.vanity_url || item?._source?.id}`} />
                                                                <Button size='live'
                                                                    icon={item?._source.live === true ? 'live' : 'not-live'}
                                                                    color={item?._source.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item?._source, item?._source.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item?._source.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item?._source?.name}</td>
                                                <LocationList data={item?._source?.locations} />
                                                <EventType data={item?._source?.types} />


                                                <EventSeriesPeopleList host={item?._source?.host} />
                                                <RelatedEventList data={item?._source?.related_event} />
                                                <td>{item?._source?.id}</td>
                                                <TagList data={item?._source?.tag} />
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item?._source.description || (item?._source?.dates && item?._source?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                                                </td>
                                                <PodcastList data={item?._source?.podcast} />
                                                <PlayList data={item?._source?.related_playlist} />
                                                <td></td>
                                                <RelatedRecipeList data={item?._source?.recipes} />
                                                <RelatedSiteList data={item?._source?.related_site} />
                                                <RelatedPeopleList data={item?._source?.related_person} />
                                                <RelatedTourList data={item?._source?.tour} />
                                                <RelatedMapList data={item?._source?.related_map} />
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default EventSeries;