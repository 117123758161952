import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PersonProfession from '../../components/peopleEditor/profession';
import uniqid from 'uniqid'
import PersonDesignation from '../../components/peopleEditor/designation';
import PersonOrganizationType from '../../components/peopleEditor/organizationType';
import PersonPublicationType from '../../components/peopleEditor/publication';

const PeopleName = (props) => {
	const dispatch = useDispatch();
	const person = useSelector(state => state[props.id]);
	const [key, setKey] = useState(uniqid())
	const { type } = useSelector(state => state.user)

	const [showAlias, toggleAlias] = useState();

	useEffect(() => {
		if (person) toggleAlias(person.alias?.length > 0)
	}, [person])

	const onTypeChange = ({ target: { value, checked } }) => {
		person.type = value;
		person.professions = []
		person.designations = []
		person.organization_types = []
		person.publication_types = []
		person.quotes = []

		if (value === 'person') {
			person.first_name = person.name || '';
			person.name = '';
		} else {
			person.name = person.name || (person.first_name || '').concat('', person.last_name || '');
			person.first_name = '';
			person.last_name = '';
		}

		dispatch({
			type: 'entity', payload: {
				...person,
				name: person.name,
				first_name: person.first_name,
				last_name: person.last_name,
				type: value,
				professions: person.professions,
				designations: person.designations,
				organization_types: person.organization_types,
				publication_types: person.publication_types
			}
		});
	};

	const handleInputChange = ({ target: { name, value } }) => {
		person[name] = value;
		dispatch({ type: 'entity', payload: { ...person, [name]: value } });
		//on change refresh dragula
		setKey(uniqid())
	};

	const handleCreditChange = ({ target: { name, checked } }) => {
		if (!person.credits) person.credits = {}
		person.credits[name] = checked
		dispatch({ type: 'entity', payload: { ...person, credits: person.credits } })
	}

	return (
		<div>
			{
				person && <>
					<div className='form-group mt-2'>
						<div className='cnow-form-title'>Type *</div>
						<div className='row'>
							<div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='person' onChange={onTypeChange} checked={person.type === 'person'} />
									<label className='form-check-label small'>Person</label>
								</div>
							</div>
							<div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='organization' onChange={onTypeChange} checked={person.type === 'organization'} />
									<label className='form-check-label small'>Organization</label>
								</div>
							</div>
							{type !== "EXPLORER" ? <div className='col-auto'>
								<div className='form-check form-check-inline'>
									<input type='radio' className='form-check-input' name='type' value='publication' onChange={onTypeChange} checked={person.type === 'publication'} />
									<label className='form-check-label small'>Publication</label>
								</div>
							</div> : null}
						</div>
					</div>

					{
						person.type &&
						<div>
							<hr className='cnow-hr' />
							<label className='cnow-form-title'>Name *</label>
							{
								person.type === 'person' && <>
									<div className='row g-2'>
										<div className='col-6'>
											<span className='cnow-form-title'>First Name *</span>
											<input name='first_name' type='text' className='form-control' value={person.first_name || ''} onChange={handleInputChange} autoComplete="off" />
										</div>
										<div className='col-6'>
											<span className='cnow-form-title'>Last Name *</span>
											<input name='last_name' type='text' className='form-control' value={person.last_name || ''} onChange={handleInputChange} autoComplete="off" />
										</div>
									</div>
								</>
							}

							{
								person.type !== 'person' && <input name='name' type='text' className='form-control' value={person.name || ''} onChange={handleInputChange} autoComplete="off" />
							}
						</div>
					}

					{
						person.type &&
						<div>
							<hr className='cnow-hr' />
							<div className='d-flex justify-content-between'>
								<span className='cnow-form-title'>Also Known As</span>
								<div className='form-check'>
									<input className='form-check-input' type='checkbox' checked={showAlias || false} onChange={(e) => {
										toggleAlias(e.target.checked);
										if (e.target.checked === false) handleInputChange({ target: { 'name': 'alias', 'value': '' } })
									}}
										autoComplete="off"
									/>
									<label className={`small form-check-label ${showAlias && 'fw-bold'}`}>If applicable</label>
								</div>
							</div>
							{showAlias && <input type='text' className='form-control' name='alias' placeholder='e.g. John Smith' value={person?.alias || ''} onChange={handleInputChange} autoComplete="off" />}
						</div>
					}

					{
						person.type === 'person' && <>
							<PersonDesignation type='person_designation' title='Credentials (Post-nominal letters, ex. - “MD”, “FAIA”)' onUpdate={props.onUpdate} id={props?.id} key={`person-designation-${key}`} refresh={() => setKey(uniqid())} />
							<PersonProfession onUpdate={props.onUpdate} id={props?.id} key={`person-profession-${key}`} refresh={() => setKey(uniqid())} />
						</>
					}

					{
						person.type === 'organization' && <>
							<PersonOrganizationType type='organization_type' title='Organization Type *' onUpdate={props.onUpdate} id={props?.id} key={`person-organization-${key}`} />
						</>
					}

					{
						person.type === 'publication' && <>
							<PersonPublicationType type='publication_type' title='Publication Type *' onUpdate={props.onUpdate} id={props?.id} key={`person-profession-${key}`} />
						</>
					}

					{
						person.id &&
						<div>
							<hr className='cnow-hr' />
							<div className='cnow-form-title'>Credited Projects, Sites and Albums *</div>

							{
								person.type === 'person' && <>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='sites' defaultChecked={person.credits?.sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Sites</label> <span className='small text-muted'>(Direct credit to a site - Design professionals, Actors in movies)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='related_sites' defaultChecked={person.credits?.related_sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Related Sites</label> <span className='small text-muted'>(Historic figures, Non-Designers)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='image_albums' defaultChecked={person.credits?.image_albums} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Image Albums</label> <span className='small text-muted'>(Photographers / Collections)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='publications' defaultChecked={person.credits?.publications} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Publications</label> <span className='small text-muted'>(Authors)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='writing_articles' defaultChecked={person.credits?.writing_articles} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Writing and Articles</label> <span className='small text-muted'>(Authors, Historians, Field experts, Newspapers, Magazines, text written for projects by designers)</span>
									</div>
								</>
							}

							{
								person.type === 'organization' && <>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='sites' defaultChecked={person.credits?.sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Sites</label> <span className='small text-muted'>(Design professionals)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='individual_credit' defaultChecked={person.credits?.individual_credit} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Individuals credited on the Sites </label> <span className='small text-muted'>(other than the main org-Collections like the MTA, will show artist name/credit under the thumbnail)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='related_sites' defaultChecked={person.credits?.related_sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Related Sites</label> <span className='small text-muted'>(Non Design Organizations)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='image_albums' defaultChecked={person.credits?.image_albums} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Image Albums</label> <span className='small text-muted'>(Photographers / Collections)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='publications' defaultChecked={person.credits?.publications} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Publications</label> <span className='small text-muted'>(Publication connected to the organization)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='writing_articles' defaultChecked={person.credits?.writing_articles} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Writing and Articles</label> <span className='small text-muted'>(Authors, Historians, Field experts, Newspapers, Magazines, text written for projects by design firms)</span>
									</div>
								</>
							}

							{
								person.type === 'publication' && <>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='sites' defaultChecked={person.credits?.sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Sites</label> <span className='small text-muted'>(Directly connected to publication)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='related_sites' defaultChecked={person.credits?.related_sites} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Related Sites</label> <span className='small text-muted'>(Featured in the publication)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='image_albums' defaultChecked={person.credits?.image_albums} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Image Albums</label> <span className='small text-muted'>(Photographers / Collections)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='writing_articles' defaultChecked={person.credits?.writing_articles} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Writing and Articles</label> <span className='small text-muted'>(Authors, Historians, Field experts, Newspapers, Magazines, text written for sites in publication)</span>
									</div>
									<div className='form-check mt-2'>
										<input className='form-check-input' type='checkbox' name='individual_credit' defaultChecked={person.credits?.individual_credit} onChange={handleCreditChange} />
										<label className='form-check-label fw-bold'>Individuals who contributed to the Writing/Articles</label> <span className='small text-muted'>(featured in the main item, Author of articles)</span>
									</div>
								</>
							}
						</div>
					}

				</>
			}
		</div>
	);
};

export default PeopleName;