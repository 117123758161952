import React from 'react';
import { TABLE_ROWS, personName } from '../../helpers';

const InThisPhotoList = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'><i className='fw-bold'>{item?.site_id ? 'Site - ' : 'Person - '}</i>{item?.site_id ? item?.site_name : personName(item)}</div>

                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )

        }
export default InThisPhotoList;