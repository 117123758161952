import React, { useState } from 'react';
import Button from './buttons/default';
import Select from 'react-select'
import { customSelectStyle } from '../helpers';
const SubSection = (props) => {
    const [more, showMore] = useState(true);

    const collapsable = props.collapsable || false;
    const bgColor = props.bgColor || 'bg-light';
    const textColor = props.textColor || 'text-red';
    const margin_top = props.marginTop || '';
    const button = props.button || false;
    const select = props.select || false;
    const options = [
        { value: 'tier1', label: 'tier1' },
        { value: 'tier2', label: 'tier2' },
        { value: 'tier3', label: 'tier3' },
        { value: 'tier4', label: 'tier4' }
    ]



    return (
        <div id={props.id} className={`my-3 ${margin_top}`}>
            <div className={`${bgColor}  py-1 d-flex justify-content-between align-items-center`}>
                <div className={`fw-bold text-uppercase ps-2 ${textColor}`}>{props.title}</div>
                <div className='small me-2 d-flex'>
                    {
                        button && <>
                            <Button size='sm' color='light' icon='' label={`Undo`} className={`mx-2 px-3`} />
                            <Button size='sm' color='light' icon='Symbols_Repeat' label={`Reset`} className={`mx-2 px-3`} />
                        </>
                    }

                    {
                        select &&
                        <div className='px-2'><Select options={options} styles={customSelectStyle} placeholder={"Move/Edit"} /></div>
                    }

                    {props.count && <span className='me-1 my-auto'>{props.count}</span>}
                    {
                        collapsable && <>
                            {more && <span style={{ cursor: 'pointer' }} onClick={() => showMore(false)} className='my-auto'>Show less <i className='fa fa-chevron-up' /></span>}
                            {!more && <span style={{ cursor: 'pointer' }} onClick={() => showMore(true)} className='my-auto'>Show more <i className='fa fa-chevron-down' /></span>}
                        </>
                    }

                </div>
            </div>
            {more && <>{props.children}</>}
        </div>
    )
}

export default SubSection;