import React from "react";
import { useSelector } from 'react-redux';

import InThisPhoto from "../../components/inThisPhoto";
import OnThisDay from "../../components/onThisDay";
import PageTitle from "../../components/pageTitle";

const OnThisDayInThisPhoto = (props) => {

    const tour = useSelector(state => state[props.id]);

    return (
        <>
            <PageTitle title={tour.name} />
            <OnThisDay id={tour.id} type={'tour'} onUpdate={(path) => props.onUpdate(path)} />
            <InThisPhoto id={tour.id} type={'tour'} onUpdate={(path) => props.onUpdate(path)} />
        </>
    )
}
export default OnThisDayInThisPhoto