import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PlaylistImageMenu from '../../components/playlistHeadshot/menu';
import PlaylistImageEditor from '../../components/playlistHeadshot/editor';
import PlaylistInlineImageList from '../../components/playlistHeadshot/inlineImageList';

const _api = require('../../api')

const PlaylistHeadshot = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.id;
    const playlist = useSelector(state => state[id]);

    const handleSelect = (img) => {
        let imgCopy = { ...img }
        const copy = [...playlist.selected];
        let index = copy.findIndex(x => x.id === imgCopy.id);

        if (copy.length < 4 && imgCopy.url && index < 0) {
            imgCopy.selectOrder = copy.length + 1

            let indexToAdd = copy.findIndex(x => !x.id)
            if (indexToAdd >= 0) {
                copy.splice(indexToAdd, 1, imgCopy)
            } else {
                copy.push(imgCopy)
            }
        } else if (imgCopy.url && index >= 0) {
            imgCopy.id = null
            imgCopy.name = null
            imgCopy.url = null

            copy.splice(index, 1, imgCopy)
        } else if (copy.length === 4 && imgCopy.url) {
            let indexToAdd = copy.findIndex(x => !x.id)
            if (indexToAdd >= 0) {
                copy.splice(indexToAdd, 1, imgCopy)
            }
        }

        copy.forEach((item, i) => { item.selectOrder = i + 1 })

        dispatch({ type: 'entity', payload: { ...playlist, selected: copy } })
    }

    const handleSave = async() => {
        // Remove isNew from each object of images
        playlist.images.forEach(item => {
            delete item.isNew
        })

        // Make thumbnail with 10 images
        playlist.thumbnail = getThumnailImages()

        // Update playlist
        dispatch({ type: 'entity', payload: { ...playlist, thumbnail: playlist.thumbnail, images: playlist.images } })
        await _api.playlist.update(playlist)

        // Process thumnail/headshot 
        const parts = playlist.thumbnail.map(x => x.url)
        const params = {
            id: playlist.id,
            type: 'playlist',
            parts: parts
        }
        await _api.image.process(params)

        toast.success('Playlist image saved successfully')
    }

    const handleCancel = () => {
        // Remove newly added images (flag: isNew = true)
        playlist.images = playlist.images.filter(x => !x.isNew)
        // Remove selected images
        playlist.selected = []

        dispatch({ type: 'entity', payload: { ...playlist, selected: playlist.selected, images: playlist.images } })

        // Redirect
        // window.history.go(-1);
        history.goBack();
    }

    const getThumnailImages = () => {
        let thumbImgs = []

        thumbImgs = [...thumbImgs, ...playlist.selected]

        let unSelected = playlist.allImages?.filter((el) => {
            return playlist.selected.every(f => (f.id !== el.id) && el.url);
        });

        thumbImgs = [...thumbImgs, ...unSelected]

        if (thumbImgs?.length < 10) {
            let times = Math.ceil(10 / thumbImgs.length)
            thumbImgs = new Array(times).fill(thumbImgs).flat()
            thumbImgs = thumbImgs?.slice(0, 10)
        }

        return thumbImgs
    }

    return (
        <div className='row px-0 mx-0 cnow-playlist-headshot-container'>
            <div className='col-2 bg-light container'>
                <PlaylistImageMenu
                    id={id}
                    items={playlist.selected}
                    onChange={() => { }} />
            </div>
            <div className={'col-10'}>
                <div className='h-100 d-flex flex-column justify-content-between'>
                    <div>
                        <PlaylistImageEditor
                            id={id}
                            selected={playlist.selected}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                        />
                    </div>
                    <div>
                        <PlaylistInlineImageList
                            id={id}
                            onSelect={handleSelect}
                            items={playlist.allImages}
                            selected={playlist.selected}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlaylistHeadshot