import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import { useDispatch } from 'react-redux';
import RecipeRelatedEditor from './recipeRelatedEditor';
import Button from '../../components/buttons/default';
import { locationName } from '../../helpers';

const Related = (props) => {
  const recipe = useSelector((state) => state[props.id]);
  const dispatch = useDispatch();
  const [index, setIndex] = useState();
  const [location, setLocation] = useState([]);

  useEffect(() => {
      siteLocations()
    if (index >= 0) {
      setIndex(index);
    }/* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const siteLocations = () => {
    let allLocations = []
    if (recipe?.related_sites?.length || recipe?.related_events?.length) {
      if(recipe?.related_sites?.length){
      for (let result of recipe?.related_sites) {
        for (let sites of result?.locations) {
          allLocations.push(sites)
        }
      }}
      if(recipe?.related_events?.length){

        for (let results of recipe?.related_events) {
          if(results?.locations){
            for (let site of results?.locations) {
              allLocations.push(site)
            }
          }
        }
      }
      setLocation(allLocations)
      if(!recipe?.location_override)
    {  let site_loc = locationName(allLocations[0])
      dispatch({ type: 'entity', payload: { ...recipe, location: site_loc } })}

    }
  }


  const handleCustomLocationChange = () => {
    dispatch({ type: 'entity', payload: { ...recipe, location_override: !recipe.location_override } });
  };

  const removeCustomLocation = () => { 
    dispatch({ type: 'entity', payload: { ...recipe, location_override: !recipe.location_override , location : '' } });

  };
  const handleInputChange = ({ target: { name, value, checked } }) => {
    dispatch({ type: 'entity', payload: { ...recipe, location: value } });
  };

  return (<>
    <PageTitle title={recipe?.name} />
    <RecipeRelatedEditor id={props.id} type={"site"} />
    <RecipeRelatedEditor id={props.id} type={"event"} />
    <RecipeRelatedEditor id={props.id} type={"event_series"} />
    <RecipeRelatedEditor id={props.id} type={"playlist"} />
    <hr className='cnow-hr' />
    <div className='cnow-form-title'>Location</div>
    {location?.length > 0 && location?.map((item, index) => {
      return (
        <div className='row g-1 mt-1' key={item.id}>
          <div className='col'>
            {index >= 0 && <label className='cnow-form-label'>{`Address ${index + 1}`}</label>}
            <input type='text' className='form-control' disabled value={item.address} autoComplete="off"></input>
          </div>
          <div className='col-auto'>
          </div>
        </div>
      )
    })}
    <hr className='cnow-hr' />
    <div className='row g-1'>
      <div className='col'>
        <label className='cnow-form-title fw-bold'>
          Address Override
        </label>
      </div>
      <div className='col-auto'>
        <div className='form-check mt-1 mx-2'>
          <input
            className='form-check-input'
            name='custom'
            type='checkbox'
            value={recipe?.location_override || ''}
            checked={recipe?.location_override}
            onChange={() => { handleCustomLocationChange() }}
          />
          <label className='small'>If required</label>
        </div>
      </div>
    </div>

    {recipe?.location_override && (
      <div className='row g-1'>
        <div className='col'>
          <input type='text' className='form-control' name='alias' placeholder='e.g. Flight Center' value={recipe.location} onChange={handleInputChange} autoComplete='off' />
        </div>
        <div className='col-auto'>
          <Button icon='delete' onClick={() => removeCustomLocation()} />
        </div>
      </div>
    )}
  </>);
};

export default Related;