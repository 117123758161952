import React, { useEffect, useState } from 'react';
import ToolTip from '../../../components/ToolTip';
import Spinner from '../../../components/spinner';
const _api = require('../../../api')

const Index = (props) => {
	let mainType = props.match.params.page ? props.match.params.page : 'data-health'

	const [data, setData] = useState(0)
	const [spinner, showSpinner] = useState(false);

	let headings = {
		"data-health": "data health type",
		"duplicate": "duplicate entries",
		"address": "address check",
		"deleted": "deleted items",
		"logs": "data entry log - changes / updates",
		"submitted": "submitted entries",
		"legal": "legal form submitted"
	}

	useEffect(() => {
		const getData = async () => {
			try {
				showSpinner(true)
				let result = null

				if (mainType === 'data-health') result = await _api.data_health.getTypicalDataHealth();
				else if (mainType === 'duplicate') result = await _api.data_health.getDuplicateDataHealth();
				else if (mainType === 'address') result = await _api.data_health.getAddressDataHealth();

				setData(result)
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				showSpinner(false)
			}
		}
		getData();
		// eslint-disable-next-line
	}, [props.match.params.page]);

	return (
		<div className="container my-5 p-0" >
			<div className="data-health-header  border border-1 ">
				<p> {headings[mainType.toLowerCase()].toUpperCase()} </p>
				<p href="/" className="text-end"> <i className="fe fe-x fw-bold"></i> </p>
			</div>
			<Spinner display={spinner}>
				<div className="data-health-body">
					{
						data
							? Object.entries(data).map(([key, value]) => (
								<div className='data-health-card-group' key={key}>
									<header>{key}</header>
									<div className="data-health-card row"> {Object.entries(value).map(([heading, values]) => <DataCard key={heading} heading={heading} values={values} />)} </div>
								</div>))
							:
							<div className='data-health-card-group'>
								<header className='text-red'>No Data Found!</header>
							</div>
					}
				</div>
			</Spinner>
		</div>
	)
}

function DataCard({ heading, values }) {
	const issues = values?.issues ? values?.issues : 0;
	const total = values?.total ? values?.total : 0;
	return (
		<div key={heading} className='col-12 col-md-3 mx-0 p-1 d-flex'>
			<div className="data-health-card-item">
				<p>{heading}</p>
				{issues ? ( <>
						<p>
							<ToolTip text={issues} tooltip={`${issues} issues found in ${heading}`} />
							<span className='text-lowercase'> of </span>
							<ToolTip text={total} tooltip={heading} />
						</p>
						<p className='data-health-card-alert p-1'>!</p>
					</> )
					: <p> <ToolTip text={total} tooltip={heading} /> </p>
				}
			</div>
		</div>
	);
}

export default Index
