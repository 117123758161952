import React from 'react'

const About = (props) => {
  return (
    <>
  <div>
        <div className='cnow-form-title '>About</div>
        <div><span dangerouslySetInnerHTML={{ __html: props?.data }} /></div>
        <br></br>
        <div className={`${props?.stories?.length > 0 ? "" : "d-none"} fw-bold fst-italic`}>Highlights</div>
        {props?.stories?.map(item => { return (<div><span dangerouslySetInnerHTML={{ __html: item?.description }} /></div>) })}
    </div>
    </>
  )
}

export default About