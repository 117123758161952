import React, { useState } from 'react';
import uniqid from 'uniqid'
import { useDispatch, useSelector } from 'react-redux';
import RelatedTourEditor from '../../components/editors/relatedTourEditor';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';
import PageTitle from '../../components/pageTitle';
import { personName } from '../../helpers';
import RelatedEventEditor from '../../components/editors/eventEditor';
import RelatedEventSeriesEditor from '../../components/editors/relatedEventSeriesEditor';
import DirectEventsInfo from './directEvent';
const _api = require('../../api')

const EventsTours = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    const deleteTourRole = async (item) => {
        if (item.role === 'Created By') await _api.tour.deleteCreatedBy(item.id);
        if (item.role === 'Partner Org') await _api.tour.deletePartnerOrg(item.id);
        if (item.role === 'Tour Leader') await _api.tour.deleteTourLeader(item.id);
        dispatch({ type: 'entity', payload: { ...person, tour_roles: person.tour_roles.filter(x => x.id !== item.id) } });
    }

    return (
        <>
            <PageTitle title={personName(person)} />
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Tours</label>
                </div>
            </div>
            {
                person.tour_roles?.length > 0 && person.tour_roles.map((item, index) => {
                    return (
                        <div key={item.id} className='mb-3'>
                            <div className='row g-1'>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                </div>
                                <div className='col'>
                                    <div className='row g-1'>
                                        <div className='col'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Title</label>}
                                            <input type='text' className='form-control' value={item.tour_name} autoComplete="off" disabled />
                                        </div>
                                        <div className='col-4'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Role</label>}
                                            <input type='text' className='form-control' value={item.role} autoComplete="off" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <PreviewButton link={`tour/${item.tour_id}`} />
                                        <LinkButton icon='edit' to={`/tour/${item.tour_id}/editor`} />
                                        <Button icon='delete' onClick={() => deleteTourRole(item)} />
                                    </div>
                                </div>
                            </div>
                        </div>)
                })
            }
            <DirectEventsInfo id={person.id} type={'direct_events'} title={'Event'} />
            <DirectEventsInfo id={person.id} type={'direct_event_series'} title={'Event Series'} />
            <RelatedTourEditor id={person.id} type={'person'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <RelatedEventEditor id={person.id} type={'person'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={true} />
            <RelatedEventSeriesEditor id={person.id} type={'person'} key={`related-event_series-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
        </>
    );
};

export default EventsTours;