import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import NoOptionsMessage from './NoOptionsMessage';

const dates = require('../../data/dates.json');
const months = require('../../data/months.json');
const dateTypes = require('../../data/date_types.json');

const monthStyle = {
    control: (base, state) => ({
        ...base,
        borderRadius: '0px',
        borderColor: '#707070',
        width: '93px'
    }),
    indicatorSeparator: () => { }
}

const dateStyle = {
    control: (base, state) => ({
        ...base,
        borderRadius: '0px',
        borderColor: '#707070',
        width: '80px'
    }),
    indicatorSeparator: () => { },
}

const dateTypeStyle = {
    control: (base, state) => ({
        ...base,
        borderRadius: '0px',
        borderColor: '#707070',
        width: '180px'
    }),
    indicatorSeparator: () => { },
}

const SelectDate = (props) => {
    let showType = props.type;
    let showLabel = props.label;
    let data = props.value;

    const [selectedMonth, setSelectedMonth] = useState(months.filter(x => x.value === data?.month)[0]);
    const [selectedDay, setSelectedDay] = useState(dates.filter(x => x.value === data?.day)[0]);

    let selectedDateTypes = [];
    if (data?.bce) selectedDateTypes.push(dateTypes.filter(item => item.value === 'bce')[0]);
    if (data?.ca) selectedDateTypes.push(dateTypes.filter(item => item.value === 'ca')[0]);

    const handleYearChange = (value) => {
        if (!data) data = {};
        data.year = value;
        props.onChange(data);
    }

    const handleMonthChange = (event) => {
        if (!data) data = {};
        data.month = event ? event.value === -1 ? null : event.value : -1;
        setSelectedMonth(months.filter(x => x.value === data?.month)[0])
        props.onChange(data);
        if (event.value < 0) {
            setSelectedMonth('')
        }
    }

    const handleDateChange = (event) => {
        if (!data) data = {};
        data.day = event ? event.value : 0;
        setSelectedDay(dates.filter(x => x.value === data?.day)[0])
        props.onChange(data);
        if (!event.value) {
            setSelectedDay(event.value)
        }
    }

    const handleDateTypeChange = (event) => {
        data.bce = false;
        data.ca = false;
        event.forEach(item => { data[item.value] = true });
        setSelectedMonth(-1)
        setSelectedDay(0)
        props.onChange(data);
    }

    return (
        <>
            <div className='row g-1'>
                <div className='col-auto'>
                    {showLabel && <label className='cnow-form-label fw-bold'>Year</label>}

                    <NumberFormat className='form-control form-control-year-people' placeholder='YYYY' format='####' value={data?.year || ''} onValueChange={({ value }) => handleYearChange(value)} />
                </div>
                <div className='col-auto'>
                    {showLabel && <label className='cnow-form-label fw-bold'>Month</label>}
                    <Select
                        placeholder='MM'
                        options={months}
                        styles={monthStyle}
                        value={selectedMonth}
                        isDisabled={data?.bce || data?.ca}
                        isClearable={false}
                        onChange={handleMonthChange}
                        components={{ NoOptionsMessage }}
                    />
                </div>
                <div className='col-auto'>
                    {showLabel && <label className='cnow-form-label fw-bold'>Date</label>}
                    <Select
                        placeholder='DD'
                        options={dates}
                        styles={dateStyle}
                        value={selectedDay}
                        isDisabled={data?.bce || data?.ca}
                        isClearable={false}
                        onChange={handleDateChange}
                        components={{ NoOptionsMessage }}
                    />
                </div>
                {
                    showType &&
                    <div className='col-auto'>
                        <label className='cnow-form-label'></label>
                        <Select
                            isMulti
                            options={dateTypes}
                            styles={dateTypeStyle}
                            value={selectedDateTypes}
                            isClearable={false}
                            onChange={handleDateTypeChange}
                            components={{ NoOptionsMessage }}
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default SelectDate