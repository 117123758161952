import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/buttons/default';
import Spinner from "../../../components/spinner";
import { useDispatch, useSelector } from 'react-redux';
import ExhibitionList from './exhibition';
import PreviewButton from '../../../components/buttons/preview';

const _api = require('../../../api');

const Exhibition = () => {
    const [spinner, setSpinner] = useState(false);
    const [menuSize, setMenuSize] = useState('lg');
    const [previewSize, setPreviewSize] = useState('lg');
    const dispatch = useDispatch();
    const toggleMenuSize = () => setMenuSize(menuSize === 'sm' ? 'lg' : 'sm')
    const togglePreviewSize = () => setPreviewSize(previewSize === 'sm' ? 'lg' : 'sm')
    const eventExhibition = useSelector(state => state['exhibition']);
    const exhibition = eventExhibition?.event || []
    // eslint-disable-next-line
    useEffect(() => { getEventExhibition() }, []);

    const getEventExhibition = async () => {
        setSpinner(true)
        const response = await _api.event.getExhibition()
        const dispatchData = {
            id: 'exhibition',
            event: response
        }
        dispatch({ type: 'entity', payload: dispatchData })
        setSpinner(false)
    }

    const updateItem = async () => {
        setSpinner(true)
        const response = await _api.event.updateExhibition(exhibition)
        const dispatchData = {
            id: 'exhibition',
            event: response
        }
        dispatch({ type: 'entity', payload: dispatchData })
        setSpinner(false)
        toast.success('Data Saved');
    }

    return (
        <div className='cnow-editor-container'>

            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                {
                    menuSize === 'sm' && <>
                        <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    </>
                }
                {
                    menuSize === 'lg' && <>
                        <div className='d-flex justify-content-between'>
                            <h5>Exhibition</h5>
                        </div>
                        <ul className='list-group mt-3'>
                            {
                                exhibition?.map((item, index) => {
                                    let itemClass = 'text-start list-group-item cnow-list-group-item';
                                    return (
                                        <li key={index} className={itemClass}>
                                            <button className={itemClass} onClick={() => { }}>
                                                {item.event_name}
                                            </button>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </>
                }
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    <div className='h-100'>
                        <div class="content-heading py-4 ps-2" style={{ background: '#ededed' }}><h6 class="text-red fw-bold">Exhibition</h6></div>
                        <div class="cnow-form-title mt-4 ps-2">List</div>
                        <input name="name" type="text" class="form-control disabled" disabled={true} autocomplete="off" value="Exhibitions"></input>
                        <ExhibitionList id={'exhibition'} />
                    </div>
                </Spinner >
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => updateItem()} />

                        <PreviewButton size='sm' link={`event/exhibitions`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }
                {
                    previewSize === 'lg' && <div className='btn-list'>
                        <Button size='sm' label='Instructions' />
                        <Button size='sm' color='danger' label='Save' onClick={() => updateItem()} />
                        <PreviewButton size='sm' link={`event/exhibitions`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                    </div>
                }
            </div>
        </div>
    )
}

export default Exhibition