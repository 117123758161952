import { ApiCall } from './utils'
import axios from 'axios'
import { toast } from 'react-toastify';

const getUploadUrl = (params) => ApiCall('post', 'file/upload-url', params)

const upload = (url, file, headers) => {
    return new Promise(async resolve => {
        axios.put(url, file, headers).then((res) => resolve(res.data)).catch(() => {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            resolve(null)
        })
    })
}

export default {
    getUploadUrl,
    upload
}