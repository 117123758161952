/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import RecipeTitle from './title';
import EventMenu from './menu';
import Spinner from '../../components/spinner';
import RecipePreview from './preview';
import RecipePeople from './people';
import RecipePodcast from './podcast';
import RecipeAbout from './about';
import RecipeImage from './image';
import RecipeCuratorManagement from './curator';
import Related from './related';
import Legal from '../../components/legal/editor';
import RecipeMetaDescription from './meta';
import { toast } from 'react-toastify';
const _api = require('../../api');

const RecipeEditor = (props) => {
    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'title';
    const sectionId = props.match.params.sectionId || '';
    const history = useHistory();
    const dispatch = useDispatch();
    const recipe = useSelector(state => state[id]);
    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);
    const { id: userId, parent_id: parentId } = useSelector((state) => state.user);
    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        if (id === 'NEW') {
            dispatch({
                type: 'entity', payload: {
                    'id': 'NEW',
                    'name': '',
                    'description': '',
                    'meta': {},
                    'image_id': null,
                    'deleted': false,
                    'live': false,
                    'types': [],
                    'categories': [],
                    'themes': [],
                    'user_id': parentId || userId,
                }
            })
        } else {
            getEventData()
        }
    }, [id, sectionId, section]);

    useEffect(() => {
        return () => {
            const data = JSON.parse(localStorage.getItem('cnow-cms'))
            delete data[id]
            localStorage.setItem('cnow-cms', JSON.stringify(data))
        }
    }, [])

    const getEventData = async () => {
        showSpinner(true);
        let myRecipe = await _api.recipe.getById(id)

        if (section === 'people') {
            myRecipe.people = await _api.recipe.getPerson(myRecipe.id)
        }

        if (section === 'about') {
            myRecipe.sections = await _api.recipe.getSection(myRecipe.id)

        }

        if (section === 'images') {
            myRecipe.images = await _api.recipe.getImage(id) || []

        }

        if (section === 'podcast') {
            myRecipe.podcasts = await _api.recipe.getPodcast(id)

        }
        if (section === 'related') {
            myRecipe.event = await _api.recipe.getEvent(id)
            myRecipe.event_series = await _api.recipe.getEventSeries(id)
            myRecipe.site = await _api.recipe.getSite(id)
            myRecipe.playlist = await _api.recipe.getPlaylist(id)

        }

        if (section === 'legal') {
            myRecipe.images = await _api.recipe.getImage(id) || []
            myRecipe.podcasts = await _api.recipe.getPodcast(id)
        }

        if (section === 'curator') {
            myRecipe.images = await _api.recipe.getImage(id) || []
            myRecipe.podcasts = await _api.recipe.getPodcast(id)
            myRecipe.curator = await _api.recipe.getCurator(id)
        }

        dispatch({ type: 'entity', payload: myRecipe })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (recipe.id) {
            recipe.created_via = "ADMIN"
        }
        if (!path) {
            path = section;
            if (sectionId) path = path.concat('/', sectionId);
        }

        showSpinner(true);
        if (recipe.id === 'NEW') recipe.id = null;

        let isValidVanityUrl = true;

        if (section === 'meta') {
            const { isValid, message } = await _api.vanity_url.validateVanityUrl({ vanity_url: recipe.meta.vanity_url, id: recipe.id, entity: "recipe" })
            isValidVanityUrl = isValid;
            if (!isValid) toast.error(message);
            showSpinner(false);
        }

        let myRecipe;

        if (isValidVanityUrl) {
            myRecipe = await _api.recipe.update(recipe)
        }


        if (section === 'people') {
            if (recipe.people?.length > 0) {
                let people = []
                for (const item of recipe.people) {
                    if (item.person_id) {
                        let response = await _api.person.updateRecipePeople(item.person_id, [item])
                        people.push(response[0])
                    }
                }
                myRecipe.people = people
            }
        }

        if (section === 'about') {
            myRecipe.sections = await _api.recipe.updateSection(myRecipe.id, recipe.sections)

        }

        if (section === 'images') {
            myRecipe.images = await _api.recipe.updateImage(myRecipe.id, recipe.images) || []
        }

        if (section === 'podcast') {
            myRecipe.podcasts = await _api.recipe.updatePodcast(myRecipe.id, recipe.podcasts)
        }

        if (section === 'curator') {
            myRecipe.curator = await _api.recipe.updateCurator(myRecipe.id, recipe.curator)
        }

        dispatch({ type: 'entity', payload: myRecipe })
        history.push(`/recipe/${myRecipe.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <div className='cnow-editor-container'>
            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                <EventMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    {section === 'title' && <RecipeTitle id={id} />}
                    {section === 'people' && <RecipePeople id={id} />}
                    {section === 'related' && <Related id={id} />}
                    {section === 'about' && <RecipeAbout id={id} />}
                    {section === 'images' && <RecipeImage id={id} onUpdate={() => updateAndRedirect()} />}
                    {section === 'podcast' && <RecipePodcast id={id} />}
                    {section === 'legal' && <Legal id={id} />}
                    {section === 'curator' && <RecipeCuratorManagement id={id} />}
                    {section === 'meta' && <RecipeMetaDescription id={id} />}
                </Spinner>
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                <RecipePreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(RecipeEditor);