import React from 'react';
import { cleanHtml } from '../../helpers';

const QuoteList = ({ data, quoteType }) => {

    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    {quoteType === "publication" ? <div className='fw-bold fst-italic'>Excerpts</div> : <div className='fw-bold fst-italic'>Quotes</div>}
                    {
                        data.map((item, index) => {
                            return <div key={index} dangerouslySetInnerHTML={{ __html: cleanHtml(item?.quote) }} />
                        })
                    }
                </>
            }
        </>
    )
}

export default QuoteList;