import React from "react";
import TextEditor from "./textEditor";
import { useDispatch, useSelector } from 'react-redux';

const DimensionsEditor = (props) => {

    const dispatch = useDispatch();
    const site = useSelector(state => state[props.id]);

    const handleMediumChange = (e) => {
        dispatch({ type: 'entity', payload: { ...site, material: e.target.value } })
    }

    const handleDimensionsChange = (property, data = 0, unit) => {
       
        if(property !== 'description'){
            let values = site.dimension[property]?.split(`'`) || [0, 0];
            let feet = values[0] || 0;
            let inches = values[1] || 0;
    
            if (unit === 'feet') {
                feet = data || 0;
            } else if (unit === 'inches') {
                inches = data || 0;
            }
    
            // Format dimensions based on provided feet and inches values
            if (feet && inches) {
                data = `${feet}'${inches}"`;
            } else if (feet && !inches) {
                data = `${feet}'0"`;
            } else if (!feet && inches) {
                data = `0'${inches}"`;
            } else {
                data = '0'; // If both feet and inches are empty
            }
    
        }

        site.dimension[property] = data;
        dispatch({ type: 'entity', payload: { ...site, dimension: site.dimension } });
    }
    const getUnit = (data, unit) => {
        if (unit === 'feet') return Number(data?.split(`'`)[0] || 0) || undefined
        else if (unit === 'inches') return Number(data?.split(`'`)[1]?.replace('"', '') || 0) || undefined
    }

    return (
        <>
            <hr className='cnow-hr' />
            <label className='cnow-form-title fw-bold'>Dimensions</label>
            <div>
                <div>
                    <div className='cnow-form-label mt-2 fw-bold'>Length</div>
                    <div className='row'>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" className='form-control' name='length' value={getUnit(site.dimension?.length, 'feet')} onChange={(e) => handleDimensionsChange('length', e.target.value, 'feet')} />
                            <input disabled className="border-0" value='Feet' />
                        </div>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" max="11" className=' form-control' name='length' value={getUnit(site.dimension?.length, 'inches')} onChange={(e) => handleDimensionsChange('length', e.target.value, 'inches')} />
                            <input disabled className="border-0" value='Inches' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='cnow-form-label mt-2 fw-bold'>Width</div>
                    <div className='row'>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" className='form-control' name='width' value={getUnit(site.dimension?.width, 'feet')} onChange={(e) => handleDimensionsChange('width', e.target.value, 'feet')} />
                            <input disabled className="border-0" value='Feet' />
                        </div>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" max="11" className=' form-control' name='width' value={getUnit(site.dimension?.width, 'inches')} onChange={(e) => handleDimensionsChange('width', e.target.value, 'inches')} />
                            <input disabled className="border-0" value='Inches' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='cnow-form-label mt-2 fw-bold'>Height</div>
                    <div className='row'>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" className=' form-control' name='height' value={getUnit(site.dimension?.height, 'feet')} onChange={(e) => handleDimensionsChange('height', e.target.value, 'feet')} />
                            <input disabled className="border-0" value='Feet' />
                        </div>
                        <div className='col-4 d-flex'>
                            <input type='number' min="0" max="11" className=' form-control' name='height' value={getUnit(site.dimension?.height, 'inches')} onChange={(e) => handleDimensionsChange('height', e.target.value, 'inches')} />
                            <input disabled className="border-0" value='Inches' />
                        </div>
                    </div>
                </div>
                <div>
                    <TextEditor name='description' data={site.dimension?.description} toolbar={true} onChange={(data) => handleDimensionsChange('description', data, null)}>
                        <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                    </TextEditor>
                </div>
            </div>
            <div className='mt-4'>
                <label className='cnow-form-title fw-bold'>Medium</label>
                <input name='material' type='text' className='form-control' placeholder='medium' value={site.material} onChange={handleMediumChange} autoComplete='off' />
            </div>
        </>)
}
export default DimensionsEditor;