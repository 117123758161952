import React from 'react';
import ImageViewer from './viewers/image';

const TourInlineImageList = (props) => {
    return (
        props.items?.length > 0 && <div className='d-flex inlineimagelist'>
            {

                props.items?.length > 0 && props?.items?.map((item, index) => {
                    const selected = props.image ? item.image_id ? props.image.id === item.image_id : props.image.id === item.site_id : item.image_id ? props.bannerImageId === item.image_id : props.bannerImageId === item.site_id;

                    return (
                        item?.label ?
                            <div className='pe-2'>
                                <div className='d-flex fw-bold align-items-center text-truncate ps-2'><div className={`tour-stop-site-number-red`}>{item.label}</div><div className='ps-2'>{item?.site_name}</div></div>
                                <div className='d-flex'>
                                    {item.image.map((elem) => {
                                        return <span onClick={() => props.onSelect(elem.image_id ? elem.image_id : elem?.site_id, elem.image_id ? 'image' : 'site')}>
                                            <ImageViewer key={elem?.image_id} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${props?.image?.id === elem?.image_id ? 'highlight-box' : ''}`} entityId={elem?.site_id ? elem?.site_id : elem?.image_id} entityType={elem?.site_id ? 'site' : 'image'} />
                                        </span>
                                    }
                                    )}
                                </div>
                            </div>
                            :

                            <div key={item.id} onClick={() => {
                                // console.log("item without label", item);
                                // console.log("Selecting", item.image_id ? item.image_id : item.site_id, item.image_id ? 'image' : 'site');
                                props.onSelect(item?.image_id ? item?.image_id : item?.site_id, item.image_id ? 'image' : 'site')
                            }} >
                                {item.index ? <div className={Number.isInteger(item.index) ? `tour-stop-number-red d-flex ms-1` : `tour-stop-site-number-red ms-1`}>{item.index}</div> : <div>&nbsp;</div>}
                                <ImageViewer key={item.image_id} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`} entityId={item.site_id ? item.site_id : item?.image_id} entityType={item.site_id ? 'site' : 'image'} />
                            </div>
                    )
                })
            }
        </div>
    )
}

export default TourInlineImageList