import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import MapCuratorTicket from "../../components/curator/curatorPurchase";
import { newTempId } from "../../helpers";
import Accession from "../../components/curator/accession";
import Permission from "../../components/curator/permission";

const CuratorManagement = ({ id }) => {
    const map = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={map.name} />
            <TextFied id={id} key={id} />
            <MapCuratorTicket id={id} />
            <LegalHistory id={id} type={'map'} />
            <EntityOfDayCheckBox type={'map'} id={id} entity_day={'Map'} key={map?.curator?.not_show_person_of_the_day} show_entity_of_day={'not_show_map_of_the_day'} />

        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const map = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const map_curator = map.curator
    const handleOnChange = (data, type) => {
        if (!map?.curator) map.curator = {}
        if (!map?.curator?.id) map.curator.id = newTempId()
        if (!map?.curator?.[type]) map.curator[type] = ''
        map.curator[type] = data
        dispatch({ type: 'entity', payload: { ...map, curator: { ...map.curator, [type]: map.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'DESCRIPTION'} disableMainTextBox subTitle={'Internal Notes (available only to users with admin access):'} handleSubInputChange={(e) => { handleOnChange(e, 'internal_notes') }} subInput={map_curator?.internal_notes || ''} hrTrue />
            <Accession label={'ACCESSION'} data={map} hrTrue entity={"Map"}/>
            <Permission label={'PERMISSION'} handleMainInputChange={(e) => { handleOnChange(e, 'permission') }} mainInput={map_curator?.permission || ''} hrTrue/>
        </>
    )
}