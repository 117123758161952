import React, { useState, useEffect, useRef } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import FeaturedCard from '../../components/featured/featuredCard';
import TextEditor from '../../components/editors/textEditor';
import ImagePreview from '../../components/featured/editInfo/imagePreview';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { uniqBy } from "lodash";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { customSelectStyle, handleDragDropReorder } from '../../helpers';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import FeaturedTitle from './title';
const WhatHappenedHere = (props) => {
   
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [whatHappenedHere, setWhatHappenedHere] = useState([])
    const [display, setDisplay] = useState(false)
    const searchWhatHappenedHere = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const what_happened_here = featured.what_happened_here || []
    let selectedDate = whatHappenedHere[editedIndex]?.dates?.map((elem) => { return { value: elem.year, label: elem.year } })
    selectedDate = uniqBy(selectedDate, 'label')

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (props?.page !== 'home') ? 'dragula-container-what-happened-here' : ''
    useEffect(() => {
        handleDragDrop(what_happened_here?.[0]?.data?.what_happened_here || [])
        // eslint-disable-next-line
    }, [dragula])
    const handleDragDrop = (whatHappenedHere) => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            setEditedIndex(-1)
            const items = handleDragDropReorder(whatHappenedHere, dragulaContainer)
            what_happened_here[0].edited = true
            what_happened_here[0].data.what_happened_here = items
            dispatch({ type: 'featured', payload: { ...featured, what_happened_here: what_happened_here } })
            setDragula(uniqid())
        })
    }
    // Dragula End

    // eslint-disable-next-line
    useEffect(() => { formatWhatHappenedHere() }, [what_happened_here?.length, dragula])

    const formatWhatHappenedHere = () => {
        let data = []
        if (what_happened_here)
            for (let i in what_happened_here) {
                if (what_happened_here[i]?.data?.what_happened_here) {
                    for (let j in what_happened_here[i].data.what_happened_here) {
                        what_happened_here[i].data.what_happened_here[j].section_index = i
                        what_happened_here[i].data.what_happened_here[j].entity_index = j
                        if (!what_happened_here[i].data.what_happened_here[j].description) addDateDescription(i, j)
                        data.push(what_happened_here[i].data.what_happened_here[j])
                    }
                }
            }
        setWhatHappenedHere(data)
    }

    const addDateDescription = (i, j) => {
        what_happened_here[i].data.what_happened_here[j].description = what_happened_here[i].data.what_happened_here[j]?.dates?.filter((e) => e.description !== '')[0]?.description
        dispatch({ type: 'featured', payload: { ...featured, what_happened_here: what_happened_here } })
    }


    const handleInputChange = (value, type, id) => {
        let id_index = whatHappenedHere[editedIndex].section_index
        let id_site_index = whatHappenedHere[editedIndex].entity_index
        what_happened_here[id_index].edited = true
        if (type === 'name') {
            what_happened_here[id_index].data.what_happened_here[id_site_index].name = value
        }
        if (type === 'date') {
            let dates = what_happened_here[id_index].data.what_happened_here[id_site_index].dates
            let index = dates.findIndex((elem) => elem.year === value.value)
            let selectedDate = dates.splice(index, 1)
            dates.unshift(selectedDate[0])
            what_happened_here[id_index].data.what_happened_here[id_site_index].dates = dates
        }
        if (type === 'description' && value) {
            if (!what_happened_here[id_index].data.what_happened_here[id_site_index].description_original) {
                what_happened_here[id_index].data.what_happened_here[id_site_index].description_original = what_happened_here[id_index].data.what_happened_here[id_site_index].description
            }
            what_happened_here[id_index].data.what_happened_here[id_site_index].description = value
        }
        dispatch({ type: 'featured', payload: { ...featured, what_happened_here: what_happened_here } })
    }

    const handleDeleteWhatHappenedHere = () => {
        setDisplay(true)
        let id_index = whatHappenedHere[editedIndex].section_index
        let id_site_index = whatHappenedHere[editedIndex].entity_index
        what_happened_here[id_index].edited = true
        what_happened_here[id_index].data.what_happened_here.splice(id_site_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, what_happened_here: what_happened_here } })
        setDisplay(false)
        //to refresh Call function Again
        formatWhatHappenedHere()
        setEditedIndex(editedIndex - 1)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchWhatHappenedHere, what_happened_here: whatHappenedHere, selectedResult: [], title: 'HOMEPAGE - What Happened here', filterCategory: ["Events and Stories"], filterLive: true } })
        history.push(`/featured/searchList/what_happened_here/site`)
    }

    const handleDescriptionReset = () => {
        let id_index = whatHappenedHere[editedIndex].section_index
        let id_site_index = whatHappenedHere[editedIndex].entity_index
        if (what_happened_here[id_index].data.what_happened_here[id_site_index].description_original) {
            what_happened_here[id_index].edited = true
            what_happened_here[id_index].data.what_happened_here[id_site_index].description = what_happened_here[id_index].data.what_happened_here[id_site_index].description_original
            dispatch({ type: 'featured', payload: { ...featured, what_happened_here: what_happened_here } })
        }
    }

    return (
        <div>

            <FeaturedTitle title='Featured Sites on the Landing page' page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="WHAT HAPPENED HERE"
                        count={whatHappenedHere.length > 1 ? `${whatHappenedHere?.length} items` : `${whatHappenedHere?.length} item`}
                        page={props?.page}
                        button={{
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            edit: { show: true, onHandle: () => { setEditedIndex(0) } },
                        }}>
                        <Spinner display={display}>
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {whatHappenedHere.map((item, index) => (
                                    <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') setEditedIndex(index); }}>
                                        <FeaturedCard data={item} image="true" title="true" description="true" />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                    </CollapsableSection>
                    {(editedIndex !== -1) && whatHappenedHere?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/site/${whatHappenedHere[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (whatHappenedHere.length > 1), onHandle: () => { handleDeleteWhatHappenedHere(); props.onUpdate(); } }
                        }}>
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='cnow-form-label fw-bold'>Date</label>
                                    <Select key={`Select-${editedIndex}`} options={selectedDate} styles={customSelectStyle} placeholder={"Select"} onChange={(e) => { handleInputChange(e, 'date') }} defaultValue={selectedDate[0]} />
                                </div>
                                <div className='col-10'>
                                    {<> <label className='cnow-form-label fw-bold text-capitalize'>{`${whatHappenedHere[editedIndex]?.entity_type} Title`}</label>
                                        <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={what_happened_here[whatHappenedHere[editedIndex].section_index].data.what_happened_here[whatHappenedHere[editedIndex].entity_index]?.name} onChange={(e) => handleInputChange(e.target.value, 'name')} autoComplete="off" />
                                    </>}
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-${editedIndex}`} data={what_happened_here[whatHappenedHere[editedIndex].section_index].data.what_happened_here[whatHappenedHere[editedIndex].entity_index]?.description} onChange={(data) => { handleInputChange(data, 'description', whatHappenedHere[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => {handleDescriptionReset() } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${whatHappenedHere[editedIndex]?.entity_type} of the Day Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <ImagePreview data={whatHappenedHere[editedIndex]} key={`ImagePreview-${editedIndex}`} section={'what_happened_here'} single={true} singleImageTitle={'Website and Phone App Image'} singleImageButton={true} imageEditorTitle={`What Happened here - ${whatHappenedHere[editedIndex].name}`} type={'featured'} />
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default WhatHappenedHere