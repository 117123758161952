/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import BulkImageEditor from '../site/bulkImageEditor';

import bulkOptions from '../../data/bulk_options.json'
import ArrangeImagesList from '../../components/arrangeImagesList';
import { customSelectStyle, personName } from '../../helpers';
import RelatedSiteList from '../../components/editors/relatedSiteList';
import ImagePodcast from '../../components/editors/imagePodcast';
import LinkButton from '../../components/buttons/link';
import PersonHeadshot from '../../components/peopleEditor/headshot';
import Select from 'react-select';

const _api = require('../../api');

const PeopleImages = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);
    const [bulkEditorType, setBulkEditorType] = useState()
    const [showBulkEditor, setShowBulkEditor] = useState(false)
    const [isSelectAll, setSelectAll] = useState(false);
    const [selectedImages, handleSelectItem] = useState([])

    const [offsetIndex, setOffsetIndex] = useState({
        "sites": 0,
        "related_sites": 0,
        "image_albums": 0,
        "publications": 0,
        "podcast": 0
    })

    const handleOnHide = async () => {
        setBulkEditorType();
        //to revert back user changes
        person.images = await _api.person.getImage(props.id)
        dispatch({ type: 'entity', payload: { ...person, image: person.images } });
        setShowBulkEditor(false);
    }

    useEffect(() => {
        // getBannerCaption()
    }, [person.image_id])

    useEffect(() => {
        getOffsetIndex()
    }, [])

    const getOffsetIndex = () => {
        let sites = person?.sites?.length && person.credits.sites ? person.sites.length : 0
        let related_sites = person.related_sites?.length ? person?.related_sites.length : 0
        let image_albums = person.image_albums?.length && person.credits.image_albums ? person.image_albums.filter(x => x.role_name !== 'Drawn By').length : 0
        let publications = person.credits?.publications && person?.publications?.length ? person?.publications.length : 0
        setOffsetIndex({
            "sites": 0,
            "related_sites": sites,
            "image_albums": sites + related_sites,
            "publications": sites + related_sites + image_albums,
            "podcast": sites + related_sites + image_albums + publications
        })
    }
    let imageRecognition = person ? person.images?.filter(x => x.type === 'image-recognition') : []

    return (

        <>
            {
                person &&
                <>
                    <PageTitle title={personName(person)} />
                    {/* headshot  */}
                    <PersonHeadshot id={person.id} />

                    {
                        person.sites && person.credits.sites && person.sites?.length > 0 &&
                        <section id='sites' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='cnow-form-title'>SITES <small>(Direct relationship to a site)</small></div>
                            <div><RelatedSiteList title={'Sites'} data={person.sites} type='site' offsetIndex={0} /></div>
                        </section>
                    }

                    {
                        person.related_sites && person.related_sites?.length > 0 && <section id='related_sites' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='cnow-form-title'>RELATED SITES <small>(Indirect relationship to a site)</small></div>
                            <div><RelatedSiteList title={'Related Sites'} data={person.related_sites} type='site' offsetIndex={offsetIndex.related_sites} /></div>
                        </section>
                    }

                    {
                        person.image_albums && person.credits.image_albums && <section id='image_Albums' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='cnow-form-title'>IMAGE ALBUMS <small>(Photographer/Collection)</small></div>
                            <table className='table mb-2'>
                                <thead className='bg-light'>
                                    <tr className='small'>
                                        <td style={{ width: '70px' }}>Order</td>
                                        <td style={{ width: '90px' }}>Media</td>
                                        <td><div>Site Title, Image caption, Image credits</div></td>
                                        <td style={{ width: '50px' }}></td>
                                    </tr>
                                </thead>
                            </table>
                            <div >
                                {
                                    person.image_albums && person.image_albums.filter(x => x.role_name !== 'Drawn By').map((album, index) => {
                                        let images = album.person_images || album.site_images
                                        return (
                                            <div className='py-2'>
                                                <div key={album.image_id} className='row g-2'>
                                                    <div className='col-auto'>
                                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={offsetIndex.image_albums + index + 1} autoComplete="off" />
                                                    </div>
                                                    <div className='col-auto' >
                                                        <ImageViewer key={album.image_id} entityType='image' entityId={album.image_id} type='thumb' cssClass='thumb' />
                                                    </div>
                                                    <div className='col'>
                                                        <div className='cnow-preview-title fw-bold p-2'>{album.site_name || album.person_name}</div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='btn-list'>
                                                            <LinkButton icon='edit' to={`/${album.entity_type}/${album.site_id || album.person_id}/editor`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    images.map((image, subIndex) => {
                                                        return (
                                                            <div key={subIndex} className='row g-2 mt-2 related_images_margin'>
                                                                <div className='col-auto'>
                                                                    <input type='text' className='cnow-preview-title fw-bold p-1 form-control form-control-sort' disabled name='sort' placeholder={subIndex + 1} autoComplete="off" />
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <ImageViewer key={image} entityType='image' entityId={image.image_id} type='thumb' cssClass='thumb' />
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='cnow-preview-title fw-bold p-1'>{image.image_caption}</div>
                                                                    <div className='bg-light ps-1 fw'><span>© </span>{image.image_credit}</div>
                                                                </div>
                                                                <div className='col-auto btn-list'>
                                                                    <LinkButton icon='edit' to={`/image/${image.image_id}/editor`} />
                                                                </div>
                                                            </div>)
                                                    })
                                                }
                                            </div>)
                                    })
                                }
                            </div>
                        </section>
                    }

                    {
                        person.publications && person.credits.publications && person.publications?.length > 0 && <section id='publications' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='cnow-form-title'>PUBLICATIONS <small> (Author/Editor)</small></div>
                            <div><RelatedSiteList title={'Publications'} data={person.publications} type='person' offsetIndex={offsetIndex.publications} /></div>
                        </section>
                    }

                    <div id='podcasts'>
                        <hr className='cnow-hr' />
                        <ImagePodcast id={person.id} type="person" offsetIndex={offsetIndex.podcast} />
                    </div>

                    {
                        <section id='drawings' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='cnow-form-title'>DRAWINGS</div>
                                <div className='col-auto'>
                                </div>
                            </div>
                            <table className='table mb-2'>
                                <thead className='bg-light'>
                                    <tr className='small'>
                                        <td style={{ width: '70px' }}>Order</td>
                                        <td style={{ width: '90px' }}>Media</td>
                                        <td><div>Site Title, Image caption, Image credits</div></td>
                                        <td style={{ width: '50px' }}></td>
                                    </tr>
                                </thead>
                            </table>
                            <div>
                                {/* <RelatedSiteList title={'Site'} data={relatedSite?.drawing} type={'image'} /> */}
                                {
                                    person.image_albums && person.image_albums.filter(x => x.role_name === 'Drawn By').map((album, index) => {
                                        let images = album.person_images || album.site_images
                                        return (
                                            <div className='py-2'>
                                                <div key={album.image_id} className='row g-2'>
                                                    <div className='col-auto'>
                                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                                    </div>
                                                    <div className='col-auto' >
                                                        <ImageViewer key={album.image_id} entityType='image' entityId={album.image_id} type='thumb' cssClass='thumb' />
                                                    </div>
                                                    <div className='col'>
                                                        <div className='cnow-preview-title fw-bold p-2'>{album.site_name || album.person_name}</div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='btn-list'>
                                                            <LinkButton icon='edit' to={`/${album.entity_type}/${album.site_id || album.person_id}/editor`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    images.map((image, subIndex) => {
                                                        return (
                                                            <div key={subIndex} className='row g-2 mt-2 related_images_margin ' >
                                                                <div className='col-auto'>
                                                                    <input type='text' className='cnow-preview-title fw-bold p-1 form-control form-control-sort' disabled name='sort' placeholder={subIndex + 1} autoComplete="off" />
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <ImageViewer key={image} entityType='image' entityId={image.image_id} type='thumb' cssClass='thumb' />
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='cnow-preview-title fw-bold p-1'>{image.image_caption}</div>
                                                                    <div className='bg-light ps-1 fw'><span>© </span>{image.image_credit}</div>
                                                                </div>
                                                                <div className='col-auto btn-list'>
                                                                    <LinkButton icon='edit' to={`/image/${image.image_id}/editor`} />
                                                                </div>
                                                            </div>)
                                                    })
                                                }
                                            </div>)
                                    })
                                }
                            </div>
                            <hr className='cnow-hr' />

                            <div id='bulk-edit' className='row g-1 align-items-start mb-2 justify-content-between'>
                                <div className='col'>
                                    <div className='cnow-form-title'>Images</div>
                                </div>
                                <div className='col d-flex '>


                                    <div className='col-4'>
                                        <div className=''>&nbsp;</div>
                                    </div>

                                    <div className='col-8'>
                                        <label className='small fw-bold fst-normal'>Bulk Editing</label>
                                        <Select styles={customSelectStyle} options={bulkOptions} onChange={(item) => { setBulkEditorType(item); setShowBulkEditor(true) }} />

                                        {
                                            (bulkEditorType?.value === 'image-type' || bulkEditorType?.value === 'image-credit' || bulkEditorType?.value === 'image-date' || bulkEditorType?.value === 'another-section' || bulkEditorType?.value === 'delete' || bulkEditorType?.value === 'image-recognition') &&
                                            <BulkImageEditor
                                                id={person?.id}
                                                show={showBulkEditor}
                                                type={bulkEditorType}
                                                items={selectedImages}
                                                entityType='person'
                                                onHide={() => handleOnHide()}
                                            />
                                        }

                                        {
                                            bulkEditorType?.value === 'rearrange' &&
                                            <ArrangeImagesList id={person?.id}
                                                arrangeType="person"
                                                entityType="person"
                                                show={showBulkEditor}
                                                type={bulkEditorType}
                                                items={selectedImages}
                                                section='person'
                                                onHide={() => handleOnHide()}
                                            />
                                        }
                                    </div>


                                </div>
                            </div>
                            <hr className='cnow-hr' />
                            <EntityImages title='Other Drawings'
                                entityType='person'
                                showCheckbox={true}
                                onSelect={(items) => handleSelectItem(items)}
                                onSelectAll={(value) => setSelectAll(value)}
                                isSelectAll={isSelectAll}
                                entityId={person.id} imageType='document'
                                buttonLabel={'Add a Drawing'}
                                sort="index" widthOfOrder='45px' widthOfMedia="80px" paddingLeftMedia="35px" paddingLeftPhoto="45px" />
                        </section>
                    }

                    {
                        <section id='additional photographs' className='mt-3'>
                            <hr className='cnow-hr' />
                            <div className='cnow-form-title'>ADDITIONAL PHOTOGRAPHS</div>
                            <div>
                                {/* <RelatedSiteList title={'Site'} data={person.additionalPhotos} type={'image'} /> */}
                                {
                                    person.additionalPhotos?.length > 0 && person.additionalPhotos.map((image, index) => {
                                        return (
                                            <div key={index} className='row g-2 mt-2 ps-1'>
                                                <div className='col-auto ps-0'>
                                                    <input type='text' className='cnow-preview-title fw-bold p-1 form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                                </div>
                                                <div className='col-auto ps-2 ' style={{ marginLeft: "-3px" }}>
                                                    <ImageViewer key={image} entityId={image.image_id} entityType='image' type='thumb' cssClass='thumb' />
                                                </div>
                                                <div className='col'>
                                                    <div className='cnow-preview-title fw-bold p-1'>{image.image_caption}</div>
                                                    <div className='bg-light ps-1 fw'><span>© </span>{image.image_credits}</div>
                                                </div>
                                                <div className='col-auto btn-list'>
                                                    <LinkButton icon='edit' to={`/image/${image.image_id}/editor`} />
                                                </div>
                                            </div>)
                                    })
                                }
                            </div>
                        </section>
                    }
                </>

            }

            <hr className='cnow-hr' />
            <EntityImages
                showCheckbox={true} title='Other Photos'
                onSelect={(items) => handleSelectItem(items)}
                onSelectAll={(value) => setSelectAll(value)}
                isSelectAll={isSelectAll}
                entityType='person'
                entityId={person.id}
                imageType='image'
                buttonLabel={'Add an Image'}
                offsetIndex={person.additionalPhotos?.length || 0}
                widthOfOrder='45px' widthOfMedia="80px" paddingLeft />
            {
                <EntityImages title={`Image Recognition *`}
                    items={imageRecognition}
                    onSelect={(items) => handleSelectItem(items)}
                    showCheckbox={true}
                    imageType='image-recognition'
                    entityType='person'
                    entityId={person.id}
                    buttonLabel={'Add a Document'}
                    sort='index'
                // offsetIndex={person.images.filter(image => (image.type === 'image' || image.type === 'document'))?.length}
                />
            }
        </>
    )
}

export default PeopleImages
