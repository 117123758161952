/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ParticipantMenu from './menu';
import ParticipantPreview from './preview';
import ParticipantInfo from './info';
import Spinner from '../../components/spinner';
import { newTempId } from '../../helpers';
import { chain } from 'lodash';
import Legal from '../../components/legal/editor';

const _api = require('../../api');

const ParticipantEditor = (props) => {
	const id = props.match.params.id || 'NEW';
	const section = props.match.params.section || 'info';
	const history = useHistory();
	const dispatch = useDispatch();

	const person = useSelector(state => state[id])
	const menuSize = useSelector((state) => state.menu);
	const previewSize = useSelector((state) => state.preview);
	const user = useSelector((state) => state.user);

	const [spinner, showSpinner] = useState(false);

	useEffect(() => {
		if (id === 'NEW') {
			dispatch({
				type: 'entity', payload: {
					'id': 'NEW',
					'id_ext': '',
					'type': '',
					'name': '',
					'first_name': '',
					'last_name': '',
					'alias': '',
					'description': '',
					'phone': '',
					'email': '',
					'url': '',
					'hide_location': false,
					'deleted': false,
					'live': false,
					'primary_contact': null,
					'alternate_contact': [{
						description: "",
						email: '',
						id: newTempId(),
						name: '',
						phone: '',
						role: '',
						sort: null
					}],
					'image_id': null,
					'meta': {},
					'is_participant': true
				}
			})
		} else {
			getPersonData();
		}
	}, [id, section]);

	const getPersonData = async () => {
		showSpinner(true);
		let participant = await _api.person.getById(id)

		if (section === 'info') {
			participant.professions = await _api.person.getProfession(participant.id)
			participant.designations = await _api.person.getDesignation(participant.id)
			participant.organization_types = await _api.person.getOrganizationType(participant.id)
			participant.publication_types = await _api.person.getPublicationType(participant.id)
			participant.related_items = await _api.person.getRelatedItems(participant.id)
			participant.affiliations = await _api.person.getAffiliation(participant.id)
			let contact = (participant.primary_contact?.email || participant.primary_contact?.phone) ? [{
				description: "",
				email: participant.primary_contact?.email || '',
				id: newTempId(),
				name: '',
				phone: participant.primary_contact?.phone || '',
				role: '',
				sort: null
			}] : []
			participant.alternate_contact = [...contact, ...(participant.alternate_contact || [])]
			if (participant.alternate_contact?.length > 0) {
				participant.alternate_contact.forEach((x, i) => x.sort = i + 1)
			} else {
				participant.alternate_contact = [{
					description: "",
					email: '',
					id: newTempId(),
					name: '',
					phone: '',
					role: '',
					sort: null
				}]
			}
			participant.primary_contact = null
		}

		if (section === 'legal') {
			if (!participant?.images) participant.images = []
			if (participant?.image_id) {
				participant.images.push(participant.image)
			}
			participant.images.forEach((elem) => {
				if (!elem.image_id && !elem.image_type && !elem.image_url && !elem.image_caption) {
					elem.image_id = elem.id;
					elem.image_type = elem.type;
					elem.image_url = elem.url;
					elem.image_caption = elem.caption;
				}
			})
		}
		dispatch({ type: 'entity', payload: participant })
		showSpinner(false);
	}

	const updateAndRedirect = async (path) => {
		if (!path) {
			path = section;
		}
		if (user.id) {
			person.created_via = "ADMIN"
		}

		showSpinner(true);
		if (person.id === 'NEW') person.id = null;

		person.is_participant = true;

		let participant = await _api.person.update(person)

		if (section === 'info') {
			participant.professions = await _api.person.updateProfession(participant.id, person.professions)
			participant.designations = await _api.person.updateDesignation(participant.id, person.designations)
			participant.organization_types = await _api.person.updateOrganizationType(participant.id, person.organization_types)
			participant.publication_types = await _api.person.updatePublicationType(participant.id, person.publication_types)
			participant.affiliations = await _api.person.updateAffiliation(participant.id, person.affiliations)

			if (participant.add_to_mail === true && participant.alternate_contact?.length > 0) {
				for (let item of participant.alternate_contact) {
					if (item.email) {
						//TO DO: api integration for subscription
					}
				}
			}
			//update all 2 way relationship apis (for remarks/role)
			updateRelatedItems(participant.related_items)
		}


		dispatch({ type: 'entity', payload: participant })
		history.push(`/participant/${participant.id}/editor/${path}`)
		showSpinner(false);
	}

	const updateRelatedItems = async (relatedItems) => {
		if (relatedItems?.length > 0) {
			const groups = chain([...relatedItems]).groupBy("source").map((value, key) => ({ source: key, data: value })).value();
			if (groups?.length > 0) {
				for (let item of groups) {
					if (item.source === 'related-site' && item?.data?.length > 0) {
						for (let d of item.data) {
							let params = {
								id: d.row_id,
								site_id: d.id,
								related_person_id: person.id,
								description: d.remarks,
								sort: null
							}
							await _api.site.updateRelatedPerson(d.id, [params])
						}
					}

					if (item.source === 'related-person' && item?.data?.length > 0) {
						let params = item.data.map(d => ({
							id: d.row_id,
							person_id: person.id,
							related_person_id: d.id,
							description: d.remarks,
							source: 'person',
							role_id: null,
							sort: null
						}))

						params.forEach((x, i) => x.sort = i + 1)

						await _api.person.updateRelatedPerson(person.id, params)
					}

					if (item.source === 'related-map' && item?.data?.length > 0) {
						let params = item.data.map(d => ({
							id: d.row_id,
							map_id: d.id,
							person_id: person.id,
							description: d.remarks,
							sort: null,
							source: 'person'
						}))

						params.forEach((x, i) => x.sort = i + 1)

						await _api.person.updateMap(person.id, params)
					}

					if (item.source === 'related-tour' && item?.data?.length > 0) {
						let params = item.data.map(d => ({
							id: d.row_id,
							tour_id: d.id,
							person_id: person.id,
							description: d.remarks,
							sort: null,
							source: 'person'
						}))

						params.forEach((x, i) => x.sort = i + 1)

						await _api.person.updateTour(person.id, params)
					}

					if (item.source === 'related-playlist' && item?.data?.length > 0) {
						let params = item.data.map(d => ({
							id: d.row_id,
							playlist_id: d.id,
							person_id: person.id,
							description: d.remarks,
							sort: null,
							source: 'person'
						}))

						params.forEach((x, i) => x.sort = i + 1)

						await _api.person.updatePlaylist(person.id, params)
					}
				}
			}
		}
	}

	return (
		<div className='cnow-editor-container'>
			<div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
				<ParticipantMenu id={id} section={section} onClick={(path) => updateAndRedirect(path)} display={spinner} />
			</div>
			<div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
				<Spinner display={spinner}>
					{section === 'info' && <ParticipantInfo id={id} onUpdate={() => updateAndRedirect()} />}
					{section === 'legal' && <Legal id={id} />}
				</Spinner>
			</div>
			<div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
				<ParticipantPreview id={id} onUpdate={() => updateAndRedirect()} section={section} display={spinner} />
			</div>
		</div>
	);
};

export default ParticipantEditor;