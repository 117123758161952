import React from "react";
import ImageViewer from "../viewers/image";

const CollectionImageCard = (props) => {
    return (
        <>
            <div className={`cursor-pointer card-deck my-2`} style={{ width: '300px', heigth: '100px' }}>
                <div className='card small bg-light border-0'>
                    <div className='cursor-pointer'>
                        <ImageViewer entityId={props.data?.image_id} entityType={'image'} type='thumb@2x' cssClass='featured-thumb' url={props.data?.url} />
                    </div>
                    <div className='card-body fz-12 p-2 pt-0 nearbyList-name  h-100' >
                        <div className={`fw-bold d-flex `} >
                            {props.name}{(props.designation || props.location) && `, `}{props.designation || props.location}
                        </div>
                        <div className={`fw-bold d-flex `} >
                            {props.author}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CollectionImageCard