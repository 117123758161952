import React from "react";
import ImageViewer from "../../components/viewers/image";
import LinkButton from "../../components/buttons/link";
const FeaturedProjectImage = (props) => {
    const relatedSites = props?.items?.map((item) => item?.related_sites).flat(Infinity)
    const getRandomSiteImage = () => {
        if (relatedSites && relatedSites?.length > 0) {
            const randomSite = relatedSites[Math.floor(Math.random() * relatedSites?.length)];
            return randomSite?.image_url;
        }
        return null;
    };
    return (
        <>
            <div className='py-2 bg-light'>
                <table className='table'>
                    <thead>
                        <tr className='small'>
                            <td style={{ width: '70px' }}>Order</td>
                            <td style={{ width: '90px' }}>Media</td>
                            <td><div>{props.title}, Image caption, Image credits</div></td>
                            <td style={{ width: '50px' }}></td>
                        </tr>
                    </thead>
                </table>
                <div>
                    {props?.items?.map((item, index) => {
                        return (
                            <div className='row g-2 pb-2' key={index}>
                                <div className='col-auto'>
                                    <input type='text' className='form-control form-control-sort fw-bold' disabled name='sort' value={index + 1} autoComplete='off' />
                                </div>
                                <div className='col-auto'>
                                    <ImageViewer key={item?.id} {...props?.showByEntityInfo && {
                                        entityId: props.type === 'site' ? item?.site_id : item?.person_id,
                                        entityType: props.type === 'site' ? 'site' : 'person',
                                    }}
                                    url={item?.image_url ? item?.image_url : getRandomSiteImage()}   type='thumb' cssClass='thumb' />
                                </div>
                                <div className='text-start small col'>
                                    <div className='cnow-preview-title fw-bold p-2'>{props?.type === 'site' ? item?.site_name : item?.person_name}</div>
                                    <div className='bg-light ps-2 fw-bold'>{item?.caption || ' '}</div>
                                    <div className='bg-light ps-2'>
                                        {item?.image_credits ? `©${item?.image_credits}` : ''}
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <div className='btn-list'>
                                        <LinkButton icon='edit' to={`/${props?.type === 'site' ? 'site' : 'people'}/${props?.type === 'site' ? item?.site_id : item?.person_id}/editor`} size={'sm'} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default FeaturedProjectImage