import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/buttons/default';
import Name from '../../components/preview/name';
import Location from '../../components/preview/location';
import Category from '../../components/preview/category';
import PeopleList from '../../components/preview/peopleList';
import Description from '../../components/preview/description';
import PodcastList from '../../components/preview/podcastList';
import Playlist from '../../components/preview/playlist';
import StoryList from '../../components/preview/storyList';
import EventList from '../../components/preview/eventList';
import RecurringEventList from '../../components/preview/recurringEventList';
import TourList from '../../components/preview/tourList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import LocationList from '../../components/preview/locationList';
import TagList from '../../components/preview/tagList';
import MetaDescription from '../../components/preview/metaDescription';
import RelatedMapList from '../../components/preview/relatedMapList';
import PreviewButton from '../../components/buttons/preview';
import MapsList from '../../components/preview/mapsList'
import ImagesList from '../../components/preview/imagesList'
import swal from 'sweetalert'
import { getCategoryName } from '../../helpers';

import DateDescriptionList from '../../components/preview/dateDescriptionList';

const _api = require('../../api')

const MapPreview = (props) => {
    const dispatch = useDispatch();
    const map = useSelector(state => state[props.id]);
    const previewSize = useSelector((state) => state.preview);
    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' });


    const handleLive = (mapId) => {
        let dateDescription = map.dates?.filter((e) => e.description !== '')
        let category = getCategoryName(map?.categories)
        if (
            map?.name &&
            category &&
            map?.themes?.length > 0 && map?.themes?.[0]?.theme_name &&
            map?.locations && map?.locations?.[0]?.address &&
            (map?.description || dateDescription?.length > 0) &&
            (map?.image)
        ) {
            _api.map.updateLive(mapId, true).then((_) => {
                swal({
                    title: 'Map is Live!',
                    icon: 'success',
                    text: `${map?.name} is now Live!`,
                    buttons: 'Ok',
                })
                dispatch({ type: 'entity', payload: { ...map, live: true } })
            })
        } else {
            swal({
                title: `${map?.name} cannot go Live!`,
                icon: 'error',
                text: `Please fill the mandatory fields:
              ${map?.name ? '' : 'Title is missing.'}
              ${category ? '' : 'Category is missing.'} 
              ${map?.themes?.length > 0 && map?.themes?.[0]?.theme_name ? '' : 'Theme is missing.'} 
              ${map?.locations && map?.locations?.[0]?.address ? '' : 'Location is missing.'} 
              ${(map?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
              ${(map?.image) ? '' : 'Image is missing.'} `,
                buttons: 'Go back',
                dangerMode: true,
            })

        }
    }

    const handleNotLive = (mapId) => {
        _api.map.updateLive(mapId, false).then(() =>
            dispatch({ type: 'entity', payload: { ...map, live: false } })
        )
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${map?.name} is now Not Live.`,
            buttons: 'Ok',
        })
    }

    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`map/${map?.vanity_url ||map?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {
                            map?.live ? <Button size='sm' color='light' label='Live' onClick={() => handleNotLive(map.id)} /> :
                                <Button size='sm' color='danger' label='Not Live' onClick={() => handleLive(map.id)} />
                        }

                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`map/${map?.vanity_url ||map?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>
            <div className='preview'>
                {
                    previewSize === 'lg' && <>
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>

                        {

                            map && props.section !== 'location' && props.section !== 'meta' && props.section !== 'images' && props.section !== 'attach_map' && <>
                                <Name data={map?.name} />
                                <Location data={map.locations?.filter(x => x.previous === false)} prevLocations={map.locations?.filter(x => x.previous === true)} />
                                <Category categories={map.categories} />
                                <PeopleList data={map.people?.length > 0 ? map.people : []} dates={map.date_people?.length > 0 ? map.date_people : []} />
                                <Description data={map.description} title="About" />
                                {(!map.description && map.dates?.length > 0) && <hr />}
                                <DateDescriptionList dates={map?.dates} />
                                <PodcastList data={map.podcasts} />
                                <StoryList data={map.stories} />
                                <EventList data={map.events} />
                                <RecurringEventList data={map.events} />
                                <TourList data={map.tours} />
                                <RelatedPeopleList data={map.related_people} />
                                <RelatedSiteList data={map.related_sites} />
                                <RelatedMapList data={map.maps} />
                                <Playlist data={map.playlists} />
                                <LocationList id={map.id} overlays={map.overlays} type={'map'} />
                                <MapsList id={map.id} type='map' />
                                <TagList tags={map.tags} themes={map.themes} categories={map.categories} date_categories={map.date_categories} entity={'map'} />
                            </>
                        }

                        {
                            map && props.section === 'meta' &&
                            <MetaDescription data={map.meta} id={map.id} type='map' />

                        }

                        {
                            map && props.section === 'location' &&
                            <LocationList id={map.id} height='70vh' locationId={props.locationId} column='flex-column-reverse' overlays={map.overlays} />
                        }

                        {
                            map && props.section === 'images' &&
                            <ImagesList id={map.id} type='map' />
                        }

                        {map && props.section === 'attach_map' &&
                            <>
                                <MapsList id={map.id} type='map' />
                                <LocationList id={map.id} height='70vh' type={'map'} overlays={map.overlays.concat(map.selectedRelatedMap?.overlays || [])} />
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}
export default MapPreview;