import React, { useState } from 'react';
import Icon from '../viewers/icon';
const MIN_LENGTH = 3
const Playlist = ({ data }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {data && data.length > 0 && <>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold fst-italic'>Related Playlist</div>

                    {
                        data.length > MIN_LENGTH &&
                        <div>
                            {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                            {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                        </div>
                    }
                </div>
                {data.slice(0, length).map((item, index) => {
                    return (
                        <div className="d-flex mb-2 align-items-center" key={index}>
                            <Icon name='play' bottom={0} size={14} />
                            <div className="ms-1 align-self-start">
                                <a href={`${process.env.REACT_APP_WEB_URL}/playlist/${item?.playlist_id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none text-dark'> {item.playlist_name}</a>
                            </div>
                        </div>
                    );
                })}
            </>
            }
        </>
    )
}

export default Playlist;