import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


const TextAreaEditor = (props) => {
    const wordLimit = props?.limit || 250;
    const [wordCount, setWordCount] = useState(props?.data?.split(' ').length || 0);

    const entity = useSelector(state => state[props?.id]);
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const data = e.target.value;
        setWordCount(data.split(' ').filter(word => word).length);
        dispatch({ type: 'entity', payload: { ...entity, [e.target.name]: e.target.value } });

    }

    return (
        <>
            <div className='d-flex justify-content-between mt-2'>
                <div>
                    <label className='cnow-form-title'>
                        {props?.title}
                        {props?.subTitle && <small className='ms-1'>{props?.subTitle}</small>}
                    </label>
                </div>
                <div>
                    <label className='cnow-form-word-count'>
                        {wordCount > 0 ? `${wordCount} of` : `Limit`} {wordLimit} words
                    </label>
                </div>
            </div>
            <textarea type='textarea' name='alt_text' rows='2' className='form-control' value={entity?.alt_text} onChange={handleChange} />
        </>
    )
}

export default TextAreaEditor 