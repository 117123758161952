import React from 'react';
import ImageViewer from '../viewers/image';
import ReactShowMoreText from 'react-show-more-text';

const FeaturedImage = ({ url, title, subTitle, description, type }) => {
  return (
    <div className='mt-1' style={{ minHeight: '250px' }}>
      <ImageViewer url={url} type={`${type ? type : 'thumb@2x'}`} cssClass={'w-100'} />
      <div className='mt-1 cnow-text-primary fw-bold'>{title}</div>
      <div>
        <div className='fw-bold cnow-ellipsis-1 small '>{subTitle}</div>
        <ReactShowMoreText lines={1} more="" less="" className={`content-css`}>
          {description && <div dangerouslySetInnerHTML={{ __html: "- " + description }} />}
        </ReactShowMoreText>
      </div>
    </div>
  )
}

export default FeaturedImage