import React from 'react';

const RecipeList = ({ data }) => {
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    <div className='fw-bold fst-italic'>Recipes</div>
                </>
            }
        </>
    )
}

export default RecipeList;