import React from 'react';

const Icon = (props) => {
    const size = props.size || 16;
    const marginLeft = props.left || 0;
    const marginRight = props.right || 0;
    const marginBottom = props.bottom || 2;
    const cursor = props.cursor || '';
    const minWidth =  props.minWidth  || '';

    return <i style={{
        backgroundImage: `url(/img/icons/${props.name}.svg)`,
        width: size,
        minWidth: minWidth,
        height: size,
        display: 'inline-block',
        verticalAlign: 'middle',
        marginBottom: marginBottom,
        marginRight: marginRight,
        marginLeft: marginLeft,
        cursor: cursor,
        backgroundRepeat: 'no-repeat'
    }}
        onClick={props.onClick}
    />
};

export default Icon;