import React, { useState } from 'react';
import Button from './buttons/default';
import Select from 'react-select'
import { customSelectStyle } from '../helpers';
import { useSelector } from 'react-redux';
const CollapsableSection = (props) => {
    const [more, showMore] = useState(true);
    const featured = useSelector((state) => state.featured);
    const collapsable = props.collapsable || false;
    const bgColor = props.bgColor || 'bg-light';
    const textColor = props.textColor || '';
    const cssClass = props.cssClass || '';
    const button = props?.page !== 'home' ? props.button || null : null
    const select = props.select || null;
    return (
        <>
            {!props.disable && <div id={props.id} className={`my-3 ${cssClass}`}>
                <div className={`${bgColor} py-1 d-flex justify-content-between align-items-center`}>
                    <div className={`d-flex gap-2 ms-2 align-items-center ${props.title2 ? 'flex-grow-1' : ''}`}>
                        {/* Select */}
                        {select?.entity?.show && <Select options={select?.entity?.options} styles={customSelectStyle} placeholder={"Select"} onChange={select?.entity?.onHandle} defaultValue={[select?.entity?.defaultValue]} />}
                        {select?.tags?.show && <Select options={select?.tags?.options} styles={customSelectStyle} placeholder={"Select"} onChange={select?.tags?.onHandle} />}

                        {/* Title */}
                        {props.title2 ?
                            <div className='d-inline-flex w-100'>
                                <div className={`fw-bold w-50 ps-2 ${textColor}`}>{props.title}</div>
                                <div className={`fw-bold w-50 pe-2 ${textColor}`} style={{ paddingLeft: '5rem' }}>{props.title2}</div>
                            </div>
                            :
                            <div className={`fw-bold ps-2 ${textColor}`}>{props.title}</div>
                        }

                        {/* Set Button */}
                        {button?.set?.show && <Button size='sm' color='light' icon='' className={'ms-2 px-3'} label={`Set`} onClick={button?.set?.onHandle} />}
                    </div>

                    {/* Show More/Less and Action Buttons */}
                    <div className='me-2'>
                        {props.count && <span className='small fst-italic me-1'>{props.count}</span>}
                        {
                            collapsable && <>
                                {more &&
                                    <span onClick={() => showMore(false)} className='small fst-italic cursor-pointer'>Show less <i className='fe fe-chevron-up' /></span>}
                                {!more &&
                                    <span onClick={() => showMore(true)} className='small fst-italic cursor-pointer'>Show more <i className='fe fe-chevron-down' /></span>}
                            </>
                        }
                        {button?.undo?.show && <Button size='sm' color='light' icon='' label={`Undo`} className={`ms-1`} onClick={button?.undo?.onHandle} />}

                        {button?.close?.show && <Button size='sm' color='light' icon='close' className={`ms-1`} onClick={button?.close?.onHandle} />}

                        {button?.search?.show && <Button size='sm' color='light' icon='search' disabled={featured?.end_date === 'null' ? true : false} className={`ms-1`} onClick={button?.search?.onHandle} />}
                        {button?.edit?.show && <Button size='sm' color='light' icon='edit' className={`ms-1`} onClick={button?.edit?.onHandle} />}
                        {button?.add?.show && <Button size='sm' color='light' icon='plus' className={`ms-1`} onClick={button?.add?.onHandle} />}

                        {button?.reset?.show && <Button size='sm' color='light' icon='Symbols_Repeat' className={`ms-1`} onClick={button?.reset?.onHandle} />}
                        {button?.delete?.show && <Button size='sm' color='light' icon='delete' className={`ms-1`} onClick={button?.delete?.onHandle} />}
                        {button?.save?.show && <Button size='sm' color='light' icon='save' className={`ms-1`} onClick={button?.save?.onHandle} />}
                    </div>
                </div>
                {more && <>{props.children}</>}
            </div>}
            {props.disable && <>{props.children}</>}
        </>
    )
}

export default CollapsableSection;