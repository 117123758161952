/*eslint-disable*/
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Icon from '../components/viewers/icon';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { debounce } from 'lodash';
import { personName } from '../helpers';

const topMenu = require('../data/menu_top.json');
const subMenu = require('../data/menu_sub.json');

export const selectStyle = {
	control: (base, state) => (
		{
			...base,
			borderRadius: '0px 0px 0px 0px',
			borderColor: '#FFFFFF !important',
			backgroundColor: 'transparent',
			boxShadow: 'none',
		}
	),
	dropdownIndicator: (base, state) => (
		{
			...base,
			display: 'none',
		}
	),
	container: (base, state) => (
		{
			...base,
			width: '100%',

		}
	),
	input: (base, state) => (
		{
			...base,
			'.cnow-select__input': {
				color: '#FFF !important',
			}
		}
	),
	placeholder: (base) => ({
		...base,
		fontWeight: 400,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),

	singleValue: (base, state) => (
		{
			...base,
			color: '#FFF',
		}),
	indicatorSeparator: () => { },
	indicatorsContainer: () => { },

}


const TopNav = (props) => {
	const { location, history } = props;
	const [search, setSearch] = useState('')
	const { type } = useSelector(state => state.user)
	const [show, setShow] = useState(false)
	const [redShow, setRedShow] = useState(false)

	const suggestions = async (keyword, callback) => {
		let items = [];
		let hitsData = []
		items.push({ 'value': keyword, 'label': keyword })
		let params = {
			q: keyword
		}
		let response = await axios.post(`/search/suggest`, params);
		response?.data?.forEach((result) => { if (result?.name || result?.first_name || result?.last_name) { hitsData.push(result) } });
		hitsData.forEach(data => items.push({ 'value': personName(data), 'label': personName(data) }));
		callback(items)
		return items;
	}

	const handleSearch = (keyword) => {
		setSearch(keyword.label)
		window.open(`${process.env.REACT_APP_WEB_URL}/search?keyword=${keyword.label}`, '_blank');
	}

	const Control = ({ children, ...props }) => {

		const style = { cursor: 'pointer' };

		return (
			<components.Control  {...props}>
				<span className='text-white d-flex ms-2' style={style}>
					<span className=' ml-1' ><Icon className='mb-1' name='search-white' minWidth={16} size={16} bottom={0} right={8} cursor='pointer' /></span> Search:
				</span>
				{children}
			</components.Control >
		);
	};

	const debouncedSuggestions = debounce((keyword, callBack) => {
		suggestions(keyword, callBack)
	}, 500)

	return (
		<>
			<nav className='navbar navbar-expand-lg cnow-bg-dark'>
				<div className='container-fluid'>
					<a className='navbar-brand' href={process.env.REACT_APP_WEB_URL} target='_blank'>
						<img src='/img/logo/logo-dark.svg' alt='CultureNow logo' className='cnow-logo' />
					</a>

					<div className='navbar-search mx-3'>
						<div className='input-group '>
							{/* <span className='input-group-text bg-transparent border-white text-white border-end-0 search-label rounded-0'>
                <Icon name='search-white'  minWidth={16} size={16} bottom={0} right={8} cursor='pointer' /> Search:
              </span> */}
							{/* <input type='search' className='form-control bg-transparent border-white text-white border-start-0' placeholder={`City, Place, Art, Architecture, Park, Collections, Don't see what you're looking for`} /> */}

							<AsyncSelect
								instanceId='unique'
								width='100%'
								classNamePrefix='cnow-select'
								styles={selectStyle}
								value={search}
								loadOptions={debouncedSuggestions}
								components={{ Control }}
								placeholder="City, Place, Art, Architecture, Park, Collection..."
								onChange={handleSearch}
							/>
						</div>
					</div>
					<div>
						<button className='navbar-toggler d-sm-none' type='button' bs-data-toggle='collapse' data-bs-target='#topnav' aria-controls='topnav' aria-expanded='false' aria-label='Toggle Top Nav'>
							<Icon className='mb-1' name='search-white' />
						</button>
						<button className='navbar-toggler cnow-topnav-toggler' type='button' bs-data-toggle='collapse' data-bs-target='#topnav' aria-controls='topnav' aria-expanded='false' aria-label='Toggle Top Nav' onClick={() => setShow(!show)}>
							<i className='fe fe-menu' />
						</button>
					</div>

					<div className={`${show ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}`} id='topnav'>
						<ul className='navbar-nav'>
							{
								topMenu.map((item, index) => {
									let linkClass = `nav-link cnow-nav-link`

									return (
										<li className='nav-item p-1' key={index}>
											<a
												href={item.path === 'logout' ? '#' : `${process.env.REACT_APP_WEB_URL}${item.path}`}
												target={item.path === 'logout' ? '_self' : '_blank'}
												className={linkClass}
												onClick={(e) => {
													if (item.path === 'logout') {
														e.preventDefault();
														localStorage.clear();
														window.location.href = `/`; 
													}
												}}
											>
												{item.name}
											</a>
										</li>
									)
								})
							}
						</ul>
					</div>
				</div>
			</nav>

			<nav className='navbar navbar-expand-lg cnow-bg-red'>
				<div className='container-fluid'>
					<div></div>
					<button className='navbar-toggler cnow-subnav-toggler' type='button' bs-data-toggle='collapse' data-bs-target='#subnav' aria-controls='subnav' aria-expanded='false' aria-label='Toggle Top Nav' onClick={() => setRedShow(!redShow)}>
						<i className='fe fe-menu' />
					</button>
					<div className={`${redShow ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}`} id='subnav'>
						<ul className='navbar-nav nav-fill w-100'>
							{
								subMenu[type]?.map((item, index) => {
									let navItemClass = 'nav-item';
									// if (item.items?.length > 0) navItemClass += ' dropdown';

									let navLinkClass = 'nav-link cnow-nav-link';
									if (location.pathname.startsWith(item.path)) navLinkClass += ' active';
									if (!item.enabled) navLinkClass += ' disabled';
									// if (item.items?.length > 0) navLinkClass += ' dropdown-toggle';

									if (item.items?.length > 0) {
										return (
											<li className="nav-item dropdown" key={index}>
												<a className="nav-link cnow-nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
													{item.name}
												</a>
												<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
													{item?.items?.map((data, i) => {
														let linkClass = 'dropdown-item'
														if (!data.enabled) linkClass += ' disabled';
														return (
															data?.path ?
																<li key={i}>
																	<Link to={data.path} className={linkClass}>{data.name}</Link>
																</li> :
																data?.items.length > 0 &&
																<li key={i}>
																	<Link className="dropdown-item" to={data.pathForThirdLevel ? data.pathForThirdLevel : "#"}> {data.name} </Link>
																	<ul className="dropdown-menu dropdown-submenu dropdown-submenu-left third-level-dropdown-container">
																		{
																			data.items.map((item, i) => {
																				return (
																					<li key={i}>
																						<Link to={data.pathForThirdLevel ? `${data.pathForThirdLevel}/${item.id}` : item.path} className={linkClass}>{item.name}</Link>
																					</li>
																				)
																			})
																		}
																	</ul>
																</li>
														)
													})}
												</ul>
											</li>)
									} else {
										return <li className={navItemClass} key={index}> <Link to={item.path} className={navLinkClass}>{item.name}</Link> </li>
									}
								})
							}
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default withRouter(TopNav);
