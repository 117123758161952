import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import MetaDescriptionEditor from '../../components/editors/metaDescriptionEditor';
import MetaKeywordsEditor from '../../components/editors/metaKeywordsEditor';
import MetaTitleEditor from '../../components/editors/metaTitleEditor';
import MetaVanityUrlEditor from '../../components/editors/metaVanityUrl';


const PlaylistMetaDescription = (props) => {
  const playlist = useSelector(state => state[props.id]);
  return (
    <>
      <PageTitle title={playlist.name} />
      <MetaDescriptionEditor id={props.id} type='playlist' />
      <MetaKeywordsEditor id={props.id} type='playlist' />
      <MetaTitleEditor id={props.id} type='playlist' />
      <MetaVanityUrlEditor id={props.id} type='playlist' />
    </>
  );
};

export default PlaylistMetaDescription;