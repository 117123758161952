import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Route from './layout/route';

import adminLayout from './layout/admin';
import publicLayout from './layout/public';

import login from './pages/auth/login';

import sites from './pages/site';
import siteEditor from './pages/site/editor';
import siteMapEditor from './pages/site/map';

import playlist from './pages/playlist';
import playlistEditor from './pages/playlist/editor';
import playlistHeadshot from './pages/playlist/headshot';

import podcast from './pages/podcast';
import podcastEditor from './pages/podcast/editor';

import people from './pages/people';
import peopleEditor from './pages/people/editor';
import participantEditor from './pages/participant/editor';

import featuredEditor from './pages/featured/editor';
import FeatureSearchList from './pages/featured/searchList/index';

import images from './pages/image';
import imageEditor from './pages/image/editor';
import imageTag from './pages/image/tag'
import imageResize from './pages/image/resize'
import imageViewer from './pages/image/viewer';
import imageViewerDzi from './pages/image/viewerDzi';
import imageViewerThenNow from './pages/image/viewerThenNow';
import bannerEditor from './pages/image/bannerEditor'
import maps from './pages/map';
import mapEditor from './pages/map/editor';

import globalList from './pages/admin/global-list';
import globalListItem from './pages/admin/global-list/items';
import globalListEditor from './pages/admin/global-list/editor';
import GlobalListSubItems from './pages/admin/global-list/subItems';
import GlobalListCategoryProgram from './pages/admin/global-list/program'

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import MapOverlays from './pages/map_overlay';
import MapOverlayEditor from './pages/map_overlay/editor';

import Tour from './pages/tour';
import TourEditor from './pages/tour/editor'
import TourMapEditor from './pages/tour/tourMapEditor';
import TourBanner from './pages/image/bannerTour';

import Event from './pages/event';
import EventEditor from './pages/event/editor'
import Exhibition from './pages/event/exhibition/editor';
import EventExhibitionList from './pages/event/exhibition';

import Recipe from './pages/recipe';
import RecipeEditor from './pages/recipe/editor';

import EventSeries from './pages/event_series';
import EventSeriesEditor from './pages/event_series/editor'

import OnThisDay from './pages/onThisDay';

import Data from './pages/admin/data'
import Users from './pages/admin/user';
import UserEditor from './pages/admin/user/editor';

import StaticPageEditor from './pages/admin/static-pages/editor';

const App = (props) => {
	const loggedin = useSelector(state => state.loggedin);
	return (
		<Router>
			<Switch>
				<Route exact={loggedin} path='/' layout={publicLayout} component={login} />

				{loggedin && <>
					<Route exact path='/site' layout={adminLayout} component={sites} />
					<Route exact path='/site/create' layout={adminLayout} component={siteEditor} />
					<Route exact path='/site/:id/editor' layout={adminLayout} component={siteEditor} />
					<Route exact path='/site/:id/editor/:section/:sectionId' layout={adminLayout} component={siteEditor} />
					<Route exact path='/site/:id/editor/:section' layout={adminLayout} component={siteEditor} />
					<Route exact path='/site/:id/editor/:section/:sectionId/:type' layout={publicLayout} component={siteMapEditor} />

					<Route exact path='/playlist' layout={adminLayout} component={playlist} />
					<Route exact path='/playlist/create' layout={adminLayout} component={playlistEditor} />
					<Route exact path='/playlist/:id/editor' layout={adminLayout} component={playlistEditor} />
					<Route exact path='/playlist/:id/headshot' layout={adminLayout} component={playlistHeadshot} />

					<Route exact path='/playlist/:id/editor/:section/:sectionId' layout={adminLayout} component={playlistEditor} />
					<Route exact path='/playlist/:id/editor/:section' layout={adminLayout} component={playlistEditor} />

					<Route exact path='/people' layout={adminLayout} component={people} />
					<Route exact path='/people/create' layout={adminLayout} component={peopleEditor} />
					<Route exact path='/people/:id/editor' layout={adminLayout} component={peopleEditor} />
					<Route exact path='/people/:id/editor/:section' layout={adminLayout} component={peopleEditor} />

					<Route exact path='/featured' layout={adminLayout} component={featuredEditor} />
					<Route exact path='/featured/editor/:section' layout={adminLayout} component={featuredEditor} />
					<Route exact path='/featured/searchList/:section/:entity' layout={adminLayout} component={FeatureSearchList} />

					<Route exact path='/participant/create' layout={adminLayout} component={participantEditor} />
					<Route exact path='/participant/:id/editor' layout={adminLayout} component={participantEditor} />
					<Route exact path='/participant/:id/editor/:section' layout={adminLayout} component={participantEditor} />

					<Route exact path='/podcast' layout={adminLayout} component={podcast} />
					<Route exact path='/podcast/:id/editor' layout={publicLayout} component={podcastEditor} />

					<Route exact path='/image' layout={adminLayout} component={images} />
					<Route exact path='/image/:id/editor' layout={publicLayout} component={imageEditor} />
					<Route exact path='/image/:id/tag' layout={publicLayout} component={imageTag} />
					<Route exact path='/image/:id/preview' layout={publicLayout} component={imageViewer} />
					<Route exact path='/image/:id/dzi' layout={publicLayout} component={imageViewerDzi} />
					<Route exact path='/image/:id/then_now' layout={publicLayout} component={imageViewerThenNow} />
					<Route exact path='/image/:id/resize/:type' layout={publicLayout} component={imageResize} />
					<Route exact path='/image/:id/banner' layout={publicLayout} component={bannerEditor} />
					<Route exact path='/image/:id/editor/:section' layout={adminLayout} component={imageEditor} />

					<Route exact path='/map' layout={adminLayout} component={maps} />
					<Route exact path='/map/create' layout={adminLayout} component={mapEditor} />
					<Route exact path='/map/:id/editor' layout={adminLayout} component={mapEditor} />
					<Route exact path='/map/:id/editor/:section/:sectionId' layout={adminLayout} component={mapEditor} />
					<Route exact path='/map/:id/editor/:section' layout={adminLayout} component={mapEditor} />
					<Route exact path='/map/:id/editor/:section/:sectionId/:type' layout={publicLayout} component={siteMapEditor} />

					<Route exact path='/map_overlay' layout={adminLayout} component={MapOverlays} />
					<Route exact path='/map_overlay/create' layout={adminLayout} component={MapOverlayEditor} />
					<Route exact path='/map_overlay/:id/editor' layout={adminLayout} component={MapOverlayEditor} />
					<Route exact path='/map_overlay/:id/editor/:section/:sectionId' layout={adminLayout} component={MapOverlayEditor} />
					<Route exact path='/map_overlay/:id/editor/:section' layout={adminLayout} component={MapOverlayEditor} />

					<Route exact path='/global-list' layout={adminLayout} component={globalList} />
					<Route exact path='/global-list/:type' layout={adminLayout} component={globalListItem} />
					<Route exact path='/global-list/:type/:id/editor' layout={adminLayout} component={globalListEditor} />
					<Route exact path='/global-list/:type/:id/:sub_category_id/editor' layout={adminLayout} component={globalListEditor} />
					<Route exact path='/global-list/:type/:id/:sub_category_id/:program_id/editor' layout={adminLayout} component={globalListEditor} />
					<Route exact path='/global-list/:type/:id' layout={adminLayout} component={GlobalListSubItems} />
					<Route exact path='/global-list/:type/:id/:sub_category_id/view' layout={adminLayout} component={GlobalListCategoryProgram} />

					<Route exact path='/tour' layout={adminLayout} component={Tour} />
					<Route exact path='/tour/create' layout={adminLayout} component={TourEditor} />
					<Route exact path='/tour/:id/editor' layout={adminLayout} component={TourEditor} />
					<Route exact path='/tour/:id/editor/:section' layout={adminLayout} component={TourEditor} />
					<Route exact path='/tour/:id/editor/tour_map_editor/:editMode/:stopIndex' layout={adminLayout} component={TourMapEditor} />
					<Route exact path='/tour/:id/editor/tour_map_editor/:editMode' layout={adminLayout} component={TourMapEditor} />
					<Route exact path='/tour/image/:id/:type' layout={publicLayout} component={TourBanner} />

					<Route exact path='/event' layout={adminLayout} component={Event} />
					<Route exact path='/event/create' layout={adminLayout} component={EventEditor} />
					<Route exact path='/event/:id/editor' layout={adminLayout} component={EventEditor} />
					<Route exact path='/event/:id/editor/:section' layout={adminLayout} component={EventEditor} />
					<Route exact path='/event/:id/editor/:section/:sectionId' layout={adminLayout} component={EventEditor} />

					<Route exact path='/event/exhibition' layout={adminLayout} component={Exhibition} />
					<Route exact path='/event/searchList/exhibition' layout={adminLayout} component={EventExhibitionList} />

					<Route exact path='/user' layout={adminLayout} component={Users} />
					<Route exact path='/user/create' layout={adminLayout} component={UserEditor} />
					<Route exact path='/user/:id/editor' layout={adminLayout} component={UserEditor} />
					<Route exact path='/user/:id/editor/:section' layout={adminLayout} component={UserEditor} />
					<Route exact path='/user/:id/editor/:section/:sectionId' layout={adminLayout} component={UserEditor} />

					<Route exact path='/recipe' layout={adminLayout} component={Recipe} />
					<Route exact path='/recipe/create' layout={adminLayout} component={RecipeEditor} />
					<Route exact path='/recipe/:id/editor' layout={adminLayout} component={RecipeEditor} />
					<Route exact path='/recipe/:id/editor/:section' layout={adminLayout} component={RecipeEditor} />
					<Route exact path='/recipe/:id/editor/:section/:sectionId' layout={adminLayout} component={RecipeEditor} />

					<Route exact path='/event_series' layout={adminLayout} component={EventSeries} />
					<Route exact path='/event_series/create' layout={adminLayout} component={EventSeriesEditor} />
					<Route exact path='/event_series/:id/editor' layout={adminLayout} component={EventSeriesEditor} />
					<Route exact path='/event_series/:id/editor/:section' layout={adminLayout} component={EventSeriesEditor} />
					<Route exact path='/event_series/:id/editor/:section/:sectionId' layout={adminLayout} component={EventSeriesEditor} />

					<Route exact path='/onthisday' layout={adminLayout} component={OnThisDay} />

					<Route exact path='/data' layout={adminLayout} component={Data} />
					<Route exact path='/data/:page' layout={adminLayout} component={Data} />

					<Route exact path='/static-pages/:page' layout={adminLayout} component={StaticPageEditor} />
					<Route exact path='/static-pages' layout={adminLayout} component={StaticPageEditor} />
				</>}
			</Switch>
		</Router>
	);
};

export default App;
