import React from 'react';
import { personName } from '../../helpers/index'
import { isObject, isString } from 'lodash';
const ImageCreditList = ({ data }) => {
    let credits = [];
    data && data.length && data.forEach(item => {
        if (item && isString(item)) credits.push(item)
        if (item && isObject(item)) credits.push(personName(item))
    })
    return (
        <>{credits && credits.length > 0 && <span>© {credits.join(', ')}</span>}</>
    )
}

export default ImageCreditList;