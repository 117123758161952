/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uniqid from 'uniqid'
import Dragula from "react-dragula";
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import SelectPeople from '../selects/person';
import { handleReorder, newTempId } from '../../helpers';

const _api = require('../../api');

const PersonAffiliation = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.affiliations, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, affiliations: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addAffiliation = () => {
        let affiliations = person.affiliations && person?.affiliations?.length > 0 ? person?.affiliations : []
        affiliations.push({
            'id': newTempId(),
            'former': false,
            'person_id': props?.id,
            'person_position': '',
            'is_participant': false,
            'related_person_id': null,
            'related_person_name': '',
            'related_person_last_name': '',
            'related_person_first_name': '',
            'sort': affiliations.length + 1
        });
        dispatch({ type: 'entity', payload: { ...person, affiliations: affiliations } });
    }

    const removeAffiliation = async (index) => {
        if (person.affiliations[index]) {
            if (!person.affiliations[index].id.startsWith('temp.')) await _api.person.deleteAffiliation(person.affiliations[index].id)

            person.affiliations.splice(index, 1)
            person.affiliations.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...person, dates: person.affiliations } });
        }
    }

    const handleAffiliationChange = ({ target: { name, value, checked, dataset } }) => {
        const index = dataset.id
        if (name === 'former') person.affiliations[index].former = checked;
        if (name === 'person_position') person.affiliations[index].person_position = value;
        dispatch({ type: 'entity', payload: { ...person, affiliations: person.affiliations } });
    }

    const handlePersonChange = (index, data) => {
        person.affiliations[index].related_person_id = data?.id
        person.affiliations[index].related_person_name = data?.name
        person.affiliations[index].designations_name = data?.designations_name
        person.affiliations[index].is_participant = data?.is_participant
        dispatch({ type: 'entity', payload: { ...person, affiliations: person.affiliations } });
    }

    return (
        <>
            <div id='affiliations'>
                <hr className='cnow-hr' />

                <div className='row'>
                    <div className='col'>
                        <div className='form-group'>
                            <div className='cnow-form-title'>{person.type === 'person' ? `Affiliations (Position)` : `Affiliations (Team)`}</div>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button size='sm' icon='plus' label={person.type === 'person' ? `Add an Affiliation` : `Add a Person`} onClick={() => addAffiliation()} disabled={props.id === 'NEW'}  />
                    </div>
                </div>
                <div id={dragulaContainer} key={dragula} >
                    {
                        person?.affiliations && person?.affiliations?.length > 0 && person.affiliations?.map((affiliation, index) => {
                            return (
                                <div id={affiliation.id} key={index} className='mb-3 dragula-item'>
                                    <div className='row g-1'>
                                        <div className='col-auto'>
                                            {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                        <div className='col'>
                                            <div className='row g-1'>
                                                <div className='col-auto'>
                                                    {index === 0 && <label className='cnow-form-label'></label>}
                                                    <div className='form-check mt-1 mx-2'>
                                                        <input className='form-check-input' name='former' type='checkbox' checked={affiliation.former} onChange={handleAffiliationChange} data-id={index} />
                                                        <label className='small'>Former</label>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    {index === 0 && <label className='cnow-form-label fw-bold'>Position</label>}
                                                    <input name='person_position' type='text' className='form-control' value={affiliation.person_position || ''} onChange={handleAffiliationChange} data-id={index} autoComplete="off" />
                                                </div>
                                                <div className='col'>
                                                    {index === 0 && <label className='cnow-form-label fw-bold'>Person/Organization</label>}
                                                    <SelectPeople key={affiliation.id} value={{ id: affiliation.related_person_id, name: affiliation.related_person_name, first_name: affiliation.related_person_first_name, last_name: affiliation.related_person_last_name, is_participant: affiliation.is_participant }} onChange={(data) => handlePersonChange(index, data)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            {index === 0 && <label className='cnow-form-label'></label>}
                                            <div className='btn-list'>
                                                <PreviewButton link={`${!affiliation.is_participant ? 'people' : 'participant'}/${affiliation.related_person_id}`} />
                                                <LinkButton icon='edit' to={`/${!affiliation.is_participant ? 'people' : 'participant'}/${affiliation.related_person_id}/editor`} />
                                                <Button icon='delete' onClick={() => removeAffiliation(index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default PersonAffiliation;