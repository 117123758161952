import React from "react";
import { useSelector, useDispatch } from 'react-redux';

const Permissions = ({ id }) => {
    return (
        <>
            <hr className="cnow-hr" />
            <VerificationCheckBox id={id} key={id} />
        </>
    )
}

export default Permissions

const VerificationCheckBox = ({ id }) => {
    const people = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const people_curator = people.curator

    const handleCreditChange = ({ target: { name, checked } }) => {
        if (!people.curator) people.credits = {}
        people.curator[name] = checked
        dispatch({ type: 'entity', payload: { ...people, curator: people.curator } })
    }
    return (
        <>
            <label className='cnow-form-title'>PERMISSIONS</label>
            <br />
            <label className='text-red' style={{fontSize:'12px'}}>NOTE: Checking this give user unlimited access to the entire database. This should be reserved only for cultureNOW staff</label>
            <div className='py-2'>
                <div className='form-check me-3'>
                    <input className='form-check-input' type='checkbox' name='super_user' defaultChecked={people_curator?.super_user || ''} key={people_curator?.super_user} onChange={handleCreditChange} />
                    <label className='form-check-label'>Super User</label>
                </div>
                <div className='form-check me-3'>
                    <input className='form-check-input' type='checkbox' name='send_newsletter' defaultChecked={people_curator?.send_newsletter || ''} key={people_curator?.send_newsletter} onChange={handleCreditChange} />
                    <label className='form-check-label'>Send Newsletter</label>
                </div>
            </div>
        </>
    )
}