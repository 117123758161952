import React, {useState} from "react";
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import PageTitle from "../../components/pageTitle";
import PodcastEditor from "../../components/editors/podcastEditor";
import RelatedPlaylist from "../../components/editors/relatedPlaylist";
import CuratorPlaylist from '../../components/editors/curatorPlaylist'
const PlaylistPodcast = (props) => {
  const playlist = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid());

  return (
    <>
      <PageTitle title={playlist?.name} />
      <hr className='cnow-hr' />
      <PodcastEditor id={props.id} type="playlist" key={`podcast-editor-${key}`} refresh={() => setKey(uniqid())} />
      <RelatedPlaylist id={props.id} type="playlist" key={`related-playlist-${key}`} refresh={() => setKey(uniqid())} />
      <CuratorPlaylist id={props.id} type="playlist" key={`curator-playlist-${key}`} refresh={() => setKey(uniqid())} />
    </>
  );
};

export default PlaylistPodcast;
