import { ApiCall } from './utils'

const getAll = (type, keyword = '', parent = '') => ApiCall('get', `/global_list/${type}?parent=${parent}&keyword=${keyword}&take=10000`)

const create = (type, params) => ApiCall('post', `/global_list/${type}`, params)

// const createTwo = (type, params) => ApiCall('post', `/global_list/${type}`, params)

const update = (id, params) => ApiCall('put', `/global_list/${id}`, params)

const getCount = (type, keyword = '') => ApiCall('get', `/global_list/${type}/count?keyword=${keyword}`)

const deleteById = (id) => ApiCall('delete', `/global_list/${id}`)

const getItems = (type, keyword = '', take, page, sort, order) => ApiCall('get', `/global_list/${type}?keyword=${keyword}&take=${take}&skip=${(page - 1) * take}&sort=${sort.value}&order=${order}`)

const getSubItems = (type) => ApiCall('get', `/global_list/${type}`)
export default {
    getAll,
    create,
    // createTwo,
    update,
    getCount,
    deleteById,
    getItems,
    getSubItems
}