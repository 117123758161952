import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/buttons/default'
import PreviewButton from '../../components/buttons/preview';
import ImageViewer from '../../components/viewers/image';
import StopEditor from './stopEditor';
import StopSiteEditor from './stopSiteEditor';
import ReplaceHighlight from './replaceHighlight';
import TourAlerts from './tourAlerts';
const _api = require('../../api')

export const Stop1 = ({ tourId, stop, showButtons, dragula, dragulaContainer, dragMode, setDragula }) => {


    const [tourAlert, showtourAlert] = useState({
        show: false,
        type: 'type',
        heading: "heading",
        entity: null
    });


    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);

    const [stopEditor, showStopEditor] = useState(false);
    const [siteEditor, showSiteEditor] = useState(false);
    const [highlightEditor, showHighLightEditor] = useState(false);

    const [selectedHighlight, setSelectedHighlight] = useState({});
    const [highlightedSites, setHighlightedSites] = useState({});

    const [siteId, setEditSiteId] = useState({})
    const [minimize, setMinimize] = useState(dragMode === 'site')

    const deleteStop = async () => {
        if (!stop.id.startsWith('temp.')) await _api.tour.deleteStop(stop.id)
        tour.stops = tour.stops.filter(item => item.id !== stop.id)
        tour.stops?.length > 0 && tour.stops.forEach((stop, index) => stop.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops, route: null } });
        showtourAlert(e => ({ show: false }));
    }

    const deleteSite = async (site) => {
        if (!site.id.startsWith('temp.')) await _api.tour.deleteSite(site.id)
        const index = tour.stops.findIndex(item => item.id === stop.id)
        tour.stops[index].sites = tour.stops[index].sites.filter(item => item.site_id !== site.site_id)
        tour.stops[index].sites.forEach((site, siteNo) => site.sort = siteNo + 1)
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
    }

    const highlight = (site) => {
        let highlightedSites = [];
        const stopIndex = tour.stops.findIndex(item => item.id === stop.id)
        const siteIndex = tour.stops[stopIndex].sites.findIndex(item => item.site_id === site.site_id)
        tour.stops?.length > 0 && tour.stops.forEach(stop => {
            if (stop.sites?.length > 0) highlightedSites = highlightedSites.concat(stop.sites.filter(site => site.highlight));
        });
        if ((highlightedSites.length + 1) <= 3 || tour.stops[stopIndex].sites[siteIndex].highlight) {
            tour.stops[stopIndex].sites[siteIndex].highlight = !tour.stops[stopIndex].sites[siteIndex].highlight
            dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
        } else {
            replaceHighlightedSite(site, highlightedSites);
        }
    }

    const replaceHighlightedSite = (site, highlightedSites) => {
        setHighlightedSites(highlightedSites)
        setSelectedHighlight(site)
        showHighLightEditor(true)
    }

    return (
        <div className='tour-stop-bg p-2 mb-4 prevent-select dragula-item' id={stop.id}>
            <div className='row g-2 mb-2'>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <div className='tour-stop-number d-flex'>{stop?.sort}</div>
                </div>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <ImageViewer entityType='site' entityId={stop?.site_id} type='thumb' cssClass='thumb-small' />
                </div>
                <div className='col'>
                    <label className='cnow-form-label fw-bold'>Stop Name*</label>
                    <div className={`${stop?.stop_name ? '' : 'cnow-form-title'} w-100 border border-2 border-dark p-1  fw-bold bg-gray`}>{stop?.stop_name ? stop?.stop_name : `Tour Stop ${stop?.sort}`}</div>
                </div>
                {
                    showButtons && <>
                        <div className='col-auto'>
                            <div className=''>&nbsp;</div>
                            <div className='btn-list'>
                                {(stop?.stop_name && stop?.location.address) ?
                                    <Button icon='edit' onClick={() => showStopEditor(true)} />
                                    : <Button icon='editWhite' color="danger" onClick={() => showStopEditor(true)} />}
                                <Button icon='delete' onClick={() => showtourAlert(e => ({ show: true, entity: stop, type: 'AddaDelte', heading: 'ARE YOU SURE YOU WANT TO REMOVE A STOP AND ALL ASSOCIATED SITES' }))} />
                                <Button icon='plus' onClick={() => { setEditSiteId('NEW'); showSiteEditor(true); }} />
                            </div>
                        </div>
                    </>
                }
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <div className='btn-list'><Button icon={`${minimize ? 'arrow-top' : 'arrow-bottom'}`} onClick={() => setMinimize(!minimize)} /></div>
                </div>
            </div>
            <div className={`${!minimize ? 'd-none' : ''} row `} style={{ minHeight: '20px' }} >
                <div className='col' id={dragMode === 'site' ? `${dragulaContainer}-${stop.id}` : 'stop-site-container'} key={dragula} >
                    {
                        stop?.sites?.length > 0 && stop.sites.map((site, index) =>
                            <div className='row mb-2 g-2 dragula-site-item' id={site?.id}>
                                <div className='col-auto'>
                                    <div className=''>&nbsp;</div>
                                    <div className='tour-stop-site-number d-flex'>{`${stop?.sort}.${site?.sort}`}</div>
                                </div>
                                <div className='col-auto'>
                                    <div className=''>&nbsp;</div>
                                    <ImageViewer entityType='site' entityId={site?.site_id} type='thumb' cssClass='thumb-small' />
                                </div>
                                <div className='col'>
                                    <label className='cnow-form-label fw-bold'>Associated Site name*</label>
                                    <input value={site?.site_name} disabled className='bg-white w-100 cnow-input-disabled-height border-0' />
                                </div>
                                {
                                    showButtons && <>
                                        <div className='col-auto'>
                                            <div className=''>&nbsp;</div>
                                            <div className='btn-list'>
                                                <PreviewButton link={`site/${site?.site_id}`} />
                                                {(site?.site_id && site?.site_name && site?.description) ?
                                                    <Button icon='edit' onClick={() => { setEditSiteId(site?.site_id); showSiteEditor(true) }} />
                                                    : <Button icon='editWhite' color="danger" onClick={() => { setEditSiteId(site?.site_id); showSiteEditor(true) }} />}
                                                <Button icon='delete' onClick={() => { showtourAlert(e => ({ show: true, entity: site, type: 'deleteSite', heading: 'ARE YOU SURE YOU WANT TO REMOVE A SITE' })) }} />
                                                <Button icon={`${site?.highlight ? 'star-red' : 'star-black'}`} onClick={() => highlight(site)} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>)
                    }
                </div>
            </div>
            {tourAlert.show && <TourAlerts site={tourAlert.entity} stop={stop} show={tourAlert.show} onHide={() => { showtourAlert(e => ({ show: false })) }} siteAlert={deleteSite} stopAlert={deleteStop} type={tourAlert.type} heading={tourAlert.heading} />}
            {stopEditor && <StopEditor tourId={tourId} stopId={stop.id} show={stopEditor} onHide={() => showStopEditor(false)} setDragula={setDragula} />}
            {siteEditor && <StopSiteEditor format={tour.format} tourId={tourId} stopId={stop.id} siteId={siteId} show={siteEditor} onHide={() => showSiteEditor(false)} />}
            {highlightEditor && <ReplaceHighlight tourId={tourId} selectedSiteStop={stop} highlightedSites={highlightedSites} selectedSite={selectedHighlight} show={highlightEditor} onHide={() => showHighLightEditor(false)} />}
        </div>)
}

export const Stop2 = ({ tourId, stop, showButtons, dragula }) => {
    const [tourAlert, showtourAlert] = useState({
        show: false,
        type: 'type',
        heading: "heading",
        entity: null
    });

    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);

    const [siteEditor, showSiteEditor] = useState(false);
    const [highlightEditor, showHighLightEditor] = useState(false);

    const [selectedHighlight, setSelectedHighlight] = useState({});
    const [highlightedSites, setHighlightedSites] = useState({});

    const deleteStop = async () => {
        if (!stop.id.startsWith('temp.')) await _api.tour.deleteStop(stop.id)
        tour.stops = tour.stops.filter(item => item.id !== stop.id)
        tour.stops?.length > 0 && tour.stops.forEach((stop, index) => stop.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops, route: null } });
        showtourAlert(e => ({ show: false }));
    }

    const highlight = (site) => {
        let highlightedSites = [];
        const stopIndex = tour.stops.findIndex(item => item.id === stop.id)
        const siteIndex = tour.stops[stopIndex].sites.findIndex(item => item.site_id === site.site_id)
        tour.stops?.length > 0 && tour.stops.forEach(stop => {
            if (stop.sites?.length > 0) highlightedSites = highlightedSites.concat(stop.sites.filter(site => site.highlight));
        });
        if ((highlightedSites.length + 1) <= 3 || tour.stops[stopIndex].sites[siteIndex].highlight) {
            tour.stops[stopIndex].sites[siteIndex].highlight = !tour.stops[stopIndex].sites[siteIndex].highlight
            dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
        } else {
            replaceHighlightedSite(site, highlightedSites);
        }
    }

    const replaceHighlightedSite = (site, highlightedSites) => {
        setHighlightedSites(highlightedSites)
        setSelectedHighlight(site)
        showHighLightEditor(true)
    }


    let site = stop.sites[0];
    { console.log(stop, "stop"); }
    return (
        <div className='dragula-item' id={stop.id} key={stop.id}>
            <div className='row mb-2 g-2 '>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <div className='tour-stop-site-number d-flex'>{stop?.sort}</div>
                </div>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <ImageViewer entityType='site' entityId={site?.site_id} type='thumb' cssClass='thumb-small' />
                </div>
                <div className='col'>
                    {stop?.sort === 1 ? <label className='cnow-form-label fw-bold'>Tour Stop Name</label> : <div className=''>&nbsp;</div>}
                    <input value={stop?.stop_name} disabled className='w-100 cnow-input-disabled-height border-0' />
                </div>
                <div className='col-2'>
                    <div className=''>&nbsp;</div>
                    <input value={site?.year} disabled className='w-100 cnow-input-disabled-height border-0' />
                </div>
                {
                    showButtons && <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='btn-list'>
                            <PreviewButton link={`site/${site?.site_id}`} />
                            {(site?.site_name && site?.description) ?
                                <Button icon='edit' onClick={() => showSiteEditor(true)} />
                                : <Button icon='editWhite' color="danger" onClick={() => showSiteEditor(true)} />}
                            <Button icon='delete' onClick={() => { showtourAlert(e => ({ show: true, entity: site, type: 'deleteSite', heading: 'ARE YOU SURE YOU WANT TO REMOVE A SITE' })) }} />
                            <Button icon={`${site?.highlight ? 'star-red' : 'star-black'}`} onClick={() => highlight(site)} />
                        </div>
                    </div>
                }
                {highlightEditor && <ReplaceHighlight tourId={tourId} selectedSiteStop={stop} highlightedSites={highlightedSites} selectedSite={selectedHighlight} show={highlightEditor} onHide={() => showHighLightEditor(false)} />}
                {tourAlert.show && <TourAlerts site={tourAlert.entity} format={tour.format} stop={stop} show={tourAlert.show} onHide={() => { showtourAlert(e => ({ show: false })) }} siteAlert={deleteStop} type={tourAlert.type} heading={tourAlert.heading} />}
                {siteEditor && <StopSiteEditor format={tour.format} tourId={tourId} stopId={stop.id} siteId={site.site_id} show={siteEditor} onHide={() => showSiteEditor(false)} />}
            </div>
        </div>)
}
