import React from 'react';
import { components } from "react-select"

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">Type to search</span>
        </components.NoOptionsMessage>
    );
};
export default NoOptionsMessage