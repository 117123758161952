import React from 'react'
import ImageViewer from '../viewers/image'

const SiteCard = ({ data }) => {
    return (
        <div className='d-flex'>
            {data?.map((item, index) => {
                return (
                    <>
                        <div className="w-25">
                            <ImageViewer key={index} url={item?.site_image_url} type='thumb@2x' cssClass='thumb p-1' />
                            <div className='small cnow-ellipsis-1'> {item?.site_name}</div>
                            {/* <div className='small'>{item?.person_name}</div> */}
                        </div>

                    </>
                )
            })}

        </div>
    )
}

export default SiteCard