import { ApiCall } from './utils'

const getByList = (params) => ApiCall('get', `/static_page/search/${params}`, {})

const saveItems = (params) => ApiCall('post', `/static_page`, params);

export default {
	getByList,
	saveItems
}
