import React, { useState, useEffect } from "react";

import axios from "axios";

const NearbyList = (props) => {
  const [nearbySites, setNearbySites] = useState([]);
  useEffect(() => {
    getNearBySites()
    // eslint-disable-next-line
  }, [props?.lat, props?.lng])

  const getNearBySites = async () => {
    let params = {
      "keyword": "",
      "page": 0,
      "filters": "live:true AND deleted:false",
      "aroundLatLng": `${props.lat}, ${props.lng}`,
      "aroundRadius": 80500 // 50 miles
    }
    if (props.lat && props.lng) {
      const response = await axios.post(`site/search`, params)
      if (response?.data?.hits?.length) {
        let sites = [];
        response.data.hits.forEach(element => {
          sites.push({
            id: element.id,
            name: element.name,
            distance: getDistance(props, { lat: element._geoloc[0]?.lat, lng: element._geoloc[0]?.lng })
          })
        });

        sites = sites.filter(x => x.id !== props.id) // remove current site
        sites = sites.sort((a, b) => (a.distance - b.distance)) // sort by distance

        //feet and miles calculation
        sites.forEach(x => {
          let distance = x.distance;
          if (distance < 1) {
            distance = Math.round(distance * 5280) + ' feet'
          } else {
            distance = distance + ' miles'
          }
          x.distance = distance
        })

        sites = sites.slice(0, 6) // only 6

        setNearbySites(sites)
      }
    }
  }

  const rad = function (x) {
    return x * Math.PI / 180;
  };
  const getDistance = function (p1, p2) { //p1 - current site lat and lng p2 - near by sites lat and lng
    let earthRadius = 6378137;
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);

    let dist = earthRadius * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));

    let distance = (dist / 1609).toFixed(1)
    return distance;
  };
  return (
    <>
      {nearbySites?.length > 0 && <>
        <hr />
        <div className='fw-bold fst-italic'>Nearby</div>
        <div>
          {
            nearbySites?.map((site) => {
              return (
                <div className='row px-0 py-1' key={site.id}>
                  <div className='col-8 nearbyList-name'>{site.name}</div>
                  <div className='col-4 text-end'>{site.distance}</div>
                </div>
              );
            })
          }
        </div>
      </>
      }
    </>
  );
};
export default NearbyList;
