import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageViewer from "../viewers/image";
import { toast } from 'react-toastify';
import { uniqBy } from "lodash";
import ImageCreditList from "../preview/imageCreditList";
const ImageRelease = (props) => {
  const [images, setImages] = useState([])
  const entity = useSelector((state) => state[props.id]);
  let currentImages = entity ? entity.images : [];
  const handleImageOnClick = (value, id) => {
    if (props.mode && props.mode !== value) { toast.error(`Please Select ${props.mode === "upload_document" ? 'Upload Document Option' : 'Digital Signature Option'}`); }
    const selectedImages = [...images]
    const index = selectedImages.findIndex((elem) => elem.entity_id === id)
    if (index === -1) selectedImages.push({ entity_id: id, entity_type: 'image', mode: value })
    else if (index !== -1 && selectedImages[index].mode === value) { selectedImages.splice(index, 1) }
    else selectedImages[index].mode = value
    setImages(selectedImages)
    props.handleImageClick(selectedImages)
    props.handleUploadMode(value)
  }

  return (
    <div>
      <hr className='cnow-hr' />
      <div className="col">
        <label className="cnow-form-title">IMAGE RELEASE *</label>
      </div>
      <div className="row text-center">
        <div className="col-1">
          <label className="form-check-label d-block small">
            Authorized CultureNow electronically
          </label>
        </div>
        <div className="col-1">
          <label className="form-check-label d-block small">
            Upload Signed Document
          </label>
        </div>
        <div className="col-10"></div>
      </div>
      {currentImages &&
        uniqBy(currentImages, 'image_id').filter((elem) => !elem.is_legal && elem.type !== 'then_now').map((image) => <LegalImageButton image={image} key={image.id} onClick={(value) => { handleImageOnClick(value, image.image_id) }} selectedImages={images} />)}
    </div>
  );
};

export default ImageRelease;

const LegalImageButton = (props) => {
  const image = props.image
  const selectedImages = props.selectedImages
  const handleCheckedBox = (type) => {
    const index = selectedImages.findIndex((elem) => elem.entity_id === image.image_id)
    if (index !== -1 && selectedImages[index].mode === type) return true
    else return false
  }
  return (
    <div className="row mb-4 gx-1" key={`${image.id}-image`}>
      <div className="col-1 text-center">
        <div className="form-check-inline">
          <input type="radio" className="form-check-input" name={`type-${image.id}`} value="digital_signature" checked={handleCheckedBox('digital_signature')} onClick={(e) => { props.onClick(e.target.value) }} />
        </div>
      </div>
      <div className="col-1 text-center">
        <div className="form-check-inline">
          <input type="radio" className="form-check-input" name={`type-${image.id}`} value="upload_document" checked={handleCheckedBox('upload_document')} onClick={(e) => { props.onClick(e.target.value) }} />
        </div>
      </div>
      <div className="col-10">
        <div className="d-flex ms-4">
          <div className="me-3">
            <ImageViewer url={image.image_url} type="thumb" cssClass="thumb" />
          </div>
          <div>
            <div>{image?.image_caption}</div>
            <div className="small"><ImageCreditList data={image?.credits} /></div>
            {image?.document_name}
          </div>
        </div>
      </div>
    </div>
  )
}



