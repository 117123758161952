import React, { useState, useEffect } from 'react'
import PageTitle from '../../../components/pageTitle'
import Spinner from '../../../components/spinner'
import { useSelector } from 'react-redux'
import moment from 'moment'
const _api = require('../../../api');
const Billing = ({ id }) => {

  const [billingData, setBillingData] = useState({});
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state[id])

  useEffect(() => {
    if (userData.id) {
      setLoading(true)
      _api.user.getPaymentDetailsById(userData.id).then((res) => {
        setLoading(false)
        setBillingData(res);
      }).catch(error => {
        setLoading(false)
      });
    }
  }, [userData.id]);



  return (
    <>
      {/* <PageTitle title={`Prasan`} /> */}
      <hr className="cnow-hr" />

      {/* <div className="row">
        <div className="col-lg-6">
          <div className="d-flex">
          <div className='cnow-form-title text-uppercase me-5'>Order History</div>
          <Link to="/" className="text-dark text-decoration-none"> <b>See Detail {'>'}</b> </Link>
          </div>

           <ol className='mt-3'>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
           </ol>

        </div>
        <div className="col-lg-6">
        <div className="d-flex">
          <div className='cnow-form-title text-uppercase me-5'>Bill (Next /Current /History)</div>
          <Link to="/" className="text-dark text-decoration-none"> <b>See Detail {'>'}</b> </Link>
          </div>

           <ol className='mt-3'>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
            <li>Manish</li>
            <li>Jagannath</li>
            <li>Aniket</li>
           </ol>
        </div>
    </div> */}

      <Spinner show={loading} className='h-302px'>
        <div className="row">
          <div className=" text-muted fw-bold">Current Bill</div>
          {billingData?.current?.length ? (
            billingData?.current?.map((item, index) => (
              <div className="row w-100" key={index}>
                <div>
                  <span className="text-muted me-3 me-1">{index + 1}.</span>
                  <span>
                    ${item.amount_due} - ${item.description} - {moment.unix(item.due_date).format('MMMM YYYY')} Dues
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="row w-100">
              <div className="text-muted ms-2">No Bills</div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="mt-1  text-muted fw-bold">Upcoming Bill</div>
          {billingData?.upcoming?.length ? billingData?.upcoming?.map((item, index) => (
            <div className="row w-100" key={index}>
              <div>
                <span className="text-muted me-3 me-1">{index + 1}.</span>
                <span>
                  ${item.amount_due} - ${item.description} - {moment.unix(item.due_date).format('MMMM YYYY')} Dues
                </span>
              </div>
            </div>
          )) : (
            <div className="row w-100">
              <div className="text-muted ms-2">No Bills</div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="mt-1  text-muted fw-bold">History</div>
          {billingData?.history?.length ? billingData?.history?.map((item, index) => (
            <div className="row w-100" key={index}>
              <div>
                <span className="text-muted me-3 me-1">{index + 1}.</span>
                <span>
                  ${item.amount_due} - ${item.description} - {moment.unix(item.due_date).format('MMMM YYYY')} Dues
                </span>
              </div>
            </div>
          )) : (
            <div className="row w-100">
              <div className="text-muted ms-2">No Bills</div>
            </div>
          )}
        </div>
      </Spinner>

    </>
  )
}

export default Billing