import React, { useState } from 'react';
import Select from 'react-select';
import { customSelectStyle, } from '../../helpers';


const IndiviualCreditsPerson = (props) => {
    let selected = props.individual_credits?.id ? {
        'value': props.individual_credits.id, 'label': props.individual_credits.person_name, 'role_name': props.individual_credits.role_name,
        'role_id': props.individual_credits.role_id,
    } : {};


    let options = props?.options?.map((item) => ({ value: item?.related_person_id, label: item?.related_person_name, type: item?.related_person_type, parentId: item?.person_id }))


    const handleChange = (e) => {
        if (e.value !== 'participant') {
            let value = { 'id': e.value, 'name': e.label, 'role_name': e.role_name, 'role_id': e.role_id, 'type': e.type, 'parentId': e.parentId };
            props.onChange(value);
        }
        else {
            if (e.value === 'participant') window.open('/participant/create')
        }
    }


    return (
        <div>
            <Select
                styles={customSelectStyle}
                onChange={handleChange}
                options={options}
                value={selected}
            />
        </div>
    )
}

export default IndiviualCreditsPerson