import React from 'react';
import { personName, TABLE_ROWS } from '../../helpers';

const CreditList = ({ data }) => {
    return (
        <td>
            {
                data && data.length > 0 && data.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'><i>{item.role_name}:</i> {personName(item)}</div>;
                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default CreditList;