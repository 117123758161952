import React , {useState} from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import RelatedTourEditor from '../../components/editors/relatedTourEditor';
import RelatedEventEditor from '../../components/editors/eventEditor';
import PageTitle from '../../components/pageTitle';

const EventsTours = (props) => {
    const tour = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={tour.name} />
            <RelatedTourEditor id={tour.id} type={'tour'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <RelatedEventEditor id={tour.id} type={'tour'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={false} />
        </>
    );
};

export default EventsTours;