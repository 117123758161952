/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import Icon from '../../components/viewers/icon';
import TableHeader from '../../components/tableHeader';
import LinkButton from '../../components/buttons/link';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import PreviewButton from '../../components/buttons/preview';
import swal from 'sweetalert';
import RelatedSiteList from '../../components/table/relatedSiteList';
import RecipePeople from '../../components/table/recipePeople';
import PlayList from '../../components/table/playlist';
import LongTextView from '../../components/viewers/longText';
import RelatedMapList from '../../components/table/relatedMapList';
import RelatedEventList from '../../components/table/relatedEvent';
import RelatedEventSeriesList from '../../components/table/relatedEventSeries';
import { TABLE_ROWS, checkUserTypeAndReturnIds } from '../../helpers';
import PodcastList from '../../components/table/podcastList';
import { useSelector } from 'react-redux';

const _api = require('../../api');
const columns = require('../../data/columns_recipe.json');

const Recipe = (props) => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        search()
    }, [page, sort, keyword, user.id]);


    const search = async () => {


        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                    { "match": { "created_via": "ADMIN" } },
                ]
            }
        };


        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^3",
                        "people.person_name^2",
                        "locations.name",
                        "location",
                        "related_events.categories.category_name",
                        "related_event_series.categories.category_name",
                        "related_sites.categories.category_name",
                        // "related_sites.date_count",
                        "related_sites.tags.tag_name",
                        "tags.tag_name",
                        "podcasts.podcast_name",
                        "podcasts.podcast_type",
                        "type_name",
                        "people.role_name",
                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }

        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }

        showSpinner(true)
        setSearchResult([])

        await _api.recipe.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
            setSearchResult(results?.hits)
            showSpinner(false)
        })
    }

    const handleDelete = (recipe) => {
        swal({
            title: `Delete Recipe`,
            text: `Are you sure that you want to delete ${recipe?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.recipe.deleteById(recipe.id).then(() => { search() })
        });
    }
    const updateLive = (recipe, live) => {

        showSpinner(true)

        if (live === true) {
            _api.recipe.updateLive(recipe?.id, live).then((_) => search())
            swal({
                title: 'Recipe is Live!',
                icon: 'success',
                text: `${recipe?.name} is now Live!`,
                buttons: 'Ok',
            })
        } else {
            _api.recipe.updateLive(recipe?.id, live).then((_) => search())
            swal({
                title: 'Not Live!',
                icon: 'info',
                text: `${recipe?.name} is Not Live.`,
                buttons: 'Ok',
            })
        }
        showSpinner(false)
    }

    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(recipe) => { setKeywordValue(recipe.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add an Recipe' color='danger' icon='plus-white' onClick={() => history.push('/recipe/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0'>Recipe</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                                    {
                                        keyword && <span className='badge badge-search'>
                                            {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                                        </span>
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchResult?.hits?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_recipe' value={item?._source.id} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/recipe/${item?._source.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item?._source) }} />
                                                                <PreviewButton size='sm' link={`recipe/${item?._source?.vanity_url || item?._source?.id}`} />
                                                                <Button size='live'
                                                                    icon={item?._source.live === true ? 'live' : 'not-live'}
                                                                    color={item?._source.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item?._source, item?._source.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item?._source.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item?._source?.name}</td>
                                                <RecipePeople data={item?._source?.people?.filter(x => x.role_name === "Creator")} />
                                                <RecipePeople data={item?._source?.people?.filter(x => x.role_name === "Muse")} />
                                                <td>{item?._source?.location}</td>
                                                <td></td>
                                                <td>{item?._source?.id}</td>
                                                <td></td>
                                                <td><LongTextView lines={TABLE_ROWS + 1}>{item?._source.description}</LongTextView></td>
                                                <PodcastList data={item?._source.podcasts} />
                                                <PlayList data={item?._source?.related_playlists} />
                                                <RelatedEventSeriesList data={item?._source?.related_event_series} />
                                                <td></td>
                                                <RelatedSiteList data={item?._source?.related_sites} />
                                                <td></td>
                                                <RelatedEventList data={item?._source?.related_events} />
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <RelatedMapList data={item?._source.maps} />
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                {/* 
                                                <RelatedPeopleList data={item?.related_people}/> */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>
            <ScrollToTop />
        </>
    );
};

export default Recipe;