import React from "react";

const FirstPage = () => {
    return (
        <>
            <div className="my-3">
                <div className='fs-large py-2 fw-bold'>PERMISSION AND RELEASE AGREEMENT</div>
                <div>
                    CultureNOW is a Not-For-Profit Corporation formed under the laws of the State of New York and exempt from federal income taxation under section 501(c)(3) of the Internal Revenue Code.
                </div>
            </div>
            <div className="my-3">
                <div className='fs-large py-2 fw-bold'>SPEAKER RELEASE</div>
                <div>
                    The speaker(s) hearby grant an unlimited, permanent, nonexclusive license, under which cultureNOW may film, tape, transcribe, otherwise record or quote from the video or audio presentation or materials, may create derivative works, may publish them in any format, including audio recordings, distance education courses, the Internet, Internet II, or any other digital, wired, or unwired network, including any successor technologies, or any other media as related to the MuseumWithoutWalls Website and app.</div>
            </div>
            <div className="my-3">
                <ol>
                    <li>For use in the Museum Without Walls Website and App</li>
                    <li>To use and reproduce this material as required</li>
                    <li>For educational purposes, including audiovisual presentations, exhibitions, tours articles, pdf’s and books related to its Museum Without Walls</li>
                    <li>For educational purposes on other podcast channels including but not limited to: BuzzSprout, Spotify, Apple Podcasts, Pandora, iTunes, Google Podcasts, etc. and video channels including but not limited to Vimeo and YouTube.</li>
                    <li>For publicity purposes in other media to announce the Museum Without walls and cultureNOW programs in addition to media (inclusive of the culturenow website or partner organization websites in coordination with cultureNOW programming) or to educate the public about architecture, history and public art</li>
                </ol>
            </div>
            <div className="my-3" style={{ paddingBottom: '110px' }}>
                The speaker is not restricted from making further use of his or her materials in any way that he / she wishes. The speaker agrees to allow cultureNOW to mine the previously recorded podcasts / videos / programs / conversations and tours according to the terms above.
            </div>
        </>
    )
}

export default FirstPage