import React from 'react'
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';

const ImagesList = (props) => {
    const entity = useSelector(state => state[props.id]);
    let currentMaps = entity ? entity.images?.filter(x => x.type === 'map' && x.date_id === null) : []
    let currentImages = entity ? entity.images?.filter(x => (x.type === 'image' || x.type === 'dzi') && x.date_id === null && x?.type !== 'then_now') : []
    let currentDocuments = entity ? entity.images?.filter(x => x.type === 'document' && x.date_id === null && x?.type !== 'then_now') : []
    return (
        <div>
            {
                props.type === 'people' ?
                    <ImageViewer url={entity.image?.url} type='web@2x' cssClass={entity.type === 'person' ? 'cnow-image-editor-headshot-preview' : 'cnow-image-editor-thumbnail-preview'} /> :
                    entity?.image?.url?.endsWith('.pdf') ?
                    <ImageViewer key={entity.image_id} url={entity.image.url.replace('.pdf', '.png')} entityType='image' type='banner' cssClass='cnow-image-editor-banner mt-2' />
                    :
                    <ImageViewer key={entity.image_id} entityId={entity.image_id} entityType='image' type='banner' cssClass='cnow-image-editor-banner mt-2' />
            }

            {props.type === 'map' && currentMaps?.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>Maps</div>
                    {currentMaps.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {currentImages && currentImages.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>{props.type === 'site' ? 'Current' : 'Additional'} Images</div>
                    {currentImages.map((item, index) => { return <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {
                entity.dates?.length > 0 && entity.dates.map((date, index) => {
                    let date_images = entity.images?.length > 0 && entity.images.filter(x => x.date_id === date.id && (x.type === 'image' || x.type === 'dzi') && x?.type !== 'then_now')
                    return (
                        date_images?.length > 0 && <>
                            <hr />
                            <div key={index} className='cnow-form-title cnow-preview-title'>{`${date.year} - ${date.event_type_name} ${props.type === 'map' ? 'Map' : 'Images'}`}</div>
                            {date_images.map((item, index) => <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' />)}
                        </>)
                })
            }
            {
                props.type === 'map' && entity.dates?.length > 0 && entity.dates.map((date, index) => {
                    let date_images = entity.images?.length > 0 && entity.images.filter(x => x.date_id === date.id && x.type === 'map' && x?.type !== 'then_now')
                    return (
                        date_images?.length > 0 && <>
                            <hr />
                            <div key={index} className='cnow-form-title cnow-preview-title'>{`${date.year} - ${date.event_type_name}  ${props.type === 'map' ? 'Map' : 'Images'}`}</div>
                            {date_images.map((item, index) => <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' />)}
                        </>)
                })
            }
            {
                currentDocuments && currentDocuments.length > 0 &&
                <>
                    <hr />
                    <div className='cnow-form-title cnow-preview-title'>{props.type === 'site' ? 'Current' : 'Additional'} Documents</div>
                    {currentDocuments.map((item, index) => { return <ImageViewer key={index} url={item.image_url} type='thumb' cssClass='thumb p-1' /> }
                    )}
                </>
            }
            {
                entity.dates?.length > 0 && entity.dates.map((date, index) => {
                    let date_images = entity.images?.length > 0 && entity.images.filter(x => x.date_id === date.id && x.type === 'document' && x?.type !== 'then_now')
                    return (date_images?.length > 0 && <>
                        <hr />
                        <div key={index} className='cnow-form-title cnow-preview-title'>{`${date.year} - ${date.event_type_name} Documents`}</div>
                        {date_images.map((item, index) => <ImageViewer key={index} url={item?.image_url} type='thumb' cssClass='thumb p-1' />)}
                    </>)
                })
            }
        </div>
    )
}

export default ImagesList