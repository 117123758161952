/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import ImageCreditList from "./imageCreditList";

const ImageView = ({ image, url, type, cssClass, hasCredits, entityType, entityId }) => {

  let imageType = type || "web";
  let [imageUrl, setImageUrl] = useState(`/img/${type}.png`);

  useEffect(() => {
    if (url) setImageUrl(url.toString()?.replace('original.', `${imageType}.`) + "?" + moment().unix());

    if (image?.url) setImageUrl(image.url?.replace('original.', `${imageType}.`) + "?" + moment().unix());

    if (image?.image_url) setImageUrl(image?.image_url?.replace('original.', `${imageType}.`) + "?" + moment().unix());

    if (entityType && entityId) setImageUrl(`${process.env.NEXT_PUBLIC_API_URL}/public/image/${entityType}/${entityId}/${imageType}`);

  }, [image, url, entityType, entityId]);

  const handleError = () => {
    setImageUrl(`/img/${type}.png`);
  };

  return (
    <>
      <>
        <img src={imageUrl} className={cssClass} alt={''}
          onError={handleError} draggable={false}
        />
      </>
      {hasCredits === true && (
        <div className="position-absolute bottom-0 end-0 fz-10 text-black fw-bold headshot-child-img ps-3">
          <ImageCreditList data={image?.credits} />
        </div>
      )}
    </>
  );
};

export default ImageView;
