import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Modal } from 'react-bootstrap';
import { customSelectStyle } from '../../helpers';
import Button from '../buttons/default';
import NoOptionsMessage from './NoOptionsMessage';
import { debounce } from 'lodash';

const globalList = require('../../data/global_list.json');
const _api = require('../../api')

const EntityCategoryList = (props) => {

    const [modal, showModal] = useState(false);
    const [item, setItem] = useState('');

    const list = globalList.filter(x => x.id === props.type)[0];
    let selected = props.value?.id ? ( props.value?.parent ? { 'value': props.value.id, 'label': `${props.value.parent_name} - ${props.value.name}` } : { 'value': props.value.id, 'label': props.value.name }) : {};
    const items = async (keyword, callback) => {
        let newItems = [];
        let response = await _api.global_list.getAll(props.type, keyword);
        for (let i in response) {
            if (response[i].parent) {
                if (props?.type.includes('award')) newItems.push({ 'value': response[i].id, 'label': `${response[i].parent_name ? `${response[i].parent_name} - ${response[i].name}` : response[i].name }`, 'award_parent_name': response[i].parent_name, 'award_child_name': response[i].name })
            }
            if (props?.type === 'image_category') newItems.push({ 'value': response[i].id, 'label': `${response[i].name}`, 'name': response[i].name, })

        }
        if (newItems.length === 0) newItems.push({ 'value': 'NEW', 'label': `Don't see what you're looking for?`, 'data': keyword })
        let items = newItems.sort((a, b) => a.label.localeCompare(b.label));
        callback(items)
        return items;
    }
    
    const handleChange = (e) => {
        if (e.value === 'NEW') {
            setItem(e.data);
            showModal(true);
        } else {
            if (props?.type.includes('award')) props.onChange({ 'id': e.value, 'name': e.label, 'award_parent_name': e.award_parent_name, 'award_child_name': e.award_child_name });
            else if (props?.type === 'image_category') props.onChange({ 'id': e.value, 'name': e.label, 'image_name': e.name });
        }
    }

    const addItem = () => {
        _api.global_list.create(props.type, {
            name: item
        }).then(response => {
            props.onChange({ 'id': response.id, 'name': response.name });
            showModal(false);
        })
    }
    
	const debouncedSuggestions = debounce((keyword, callBack) => {
		items(keyword, callBack)
	}, 500)

    return (
        <>
            <AsyncSelect cacheOptions defaultOptions
                styles={customSelectStyle}
                loadOptions={debouncedSuggestions}
                value={selected}
                onChange={handleChange}
                components={{ NoOptionsMessage }}
            />

            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <div className='cnow-form-title'>Add a {list?.name}</div>
                    <hr />
                    <p>Don't see what you're looking for? <br />Add one here.</p>
                    <input type='text' className='form-control' value={item} onChange={(e) => setItem(e.target.value)} autoComplete="off" />
                    <p>If our curators have any questions we will get back to you.</p>
                    <Button label='Save' onClick={() => addItem()} />
                </Modal.Body>
            </Modal>

        </>
    );
};

export default EntityCategoryList;