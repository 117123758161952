import React from 'react'
import LinkButton from './buttons/link'
import ImageViewer from './viewers/image'
import swal from 'sweetalert';
import Button from './buttons/default';
const _api = require('../api');


const DynamicTable = ({ searchResult, search, type }) => {

    const handleDelete = (user) => {
        swal({
            title: `Delete User`,
            text: `Are you sure that you want to delete ${user?.first_name + " " + user?.last_name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            _api.user.deleteById(user?.id).then(() => { search() })
        });
    }

    return (
        <>
            {
                searchResult?.filter(item => item.type === type).map((item, index) => (
                    <tr key={index}>
                        <td>
                            <div className='row g-1'>
                                <div className='col-auto'>
                                    <div className='form-check mt-1'>
                                        <input className='form-check-input align-middle' type='checkbox' name='select_event' value={item.id} />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <div className='btn-list'>
                                        <LinkButton size='sm' icon='edit' to={`/user/${item.id}/editor/profile`} />
                                        <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                        {/* <PreviewButton size='sm' link={`user/${item.id}`} /> */}
                                        {/* <Button size='live'
                                    icon={item.live === true ? 'live' : 'not-live'}
                                    color={item.live === true ? '' : 'danger'}
                                    onClick={() => { updateLive(item.id, item.live === true ? false : true) }} /> */}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ImageViewer url={item.image_url ? item?.image_url : '/img/thumb.png'} type='original' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'>{item?.first_name + " " + item?.last_name}</td>
                        <td></td>
                        <td></td>
                        <td className='fw-bold'>{item?.phone}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='fw-bold'>{item?.id}</td>
                        <td>{searchResult?.hits?.length}</td>
                        <td></td>
                        <td></td>
                        <td className='fw-bold'></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                ))}
        </>
    )
}

export default DynamicTable
