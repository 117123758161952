/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { newTempId } from '../../helpers';
const type = require('../../data/curator_playlist_type.json')

const PlaylistType = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const handleChange = (e) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        if (!entity?.curator?.type) entity.curator.type = ''
        entity.curator.type = e.value
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, type: entity.curator.type } } })
    }
    const styleSelect = {
        control: (base, state) => ({
            ...base, borderRadius: '0px',
            borderColor: '#707070',
            color: '#000000',
            textTransform: 'none',
            fontWeight: 'normal'
        }),
        option: (styles) => {
            return {
                ...styles,
                color: '#000000',
                textTransform: 'none',
                fontWeight: 'normal'
            }
        },
        valueContainer: (provided, state) => ({
            ...provided,
            width: '200px'
        }),

        indicatorSeparator: () => { },
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{'TYPE'}</label>
                </div>
                <div className='col-auto'>
                </div>
            </div>
            <div>
                <div className='row g-1 mt-1 dragula-item' >
                    <div className='col-auto d-flex'>
                        <div>
                            <img src="/img/icons/podcast_video.svg" style={{ width: '45px', height: '45px' }} alt="playlist" />

                        </div>
                        <div className='ps-3'>
                            <Select cacheOptions defaultOptions
                                styles={styleSelect}
                                options={type}
                                value={{
                                    value: entity?.curator?.type,
                                    label: entity?.curator?.type
                                }}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlaylistType;