import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import MetaDescriptionEditor from '../../components/editors/metaDescriptionEditor';
import MetaVanityUrlEditor from '../../components/editors/metaVanityUrl';

const EventMeta = (props) => {
    const event = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={event.name} />
            <MetaDescriptionEditor id={props.id} type='event-series' />
            <MetaVanityUrlEditor id={props.id} type='event-series' />
        </>
    );
};

export default EventMeta;