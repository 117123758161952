import React from "react";
import { useSelector } from 'react-redux';
import PodcastStopName from "./podcastStopName";
const PodcastStop = (props) => {
    const tour = useSelector(state => state[props.id]);
    return (
        <>
            <hr className='cnow-hr' />
            <div className='d-flex justify-content-between'>
                <div className='cnow-form-title'>Podcasts, for Tour Stop</div>
            </div>
            {tour?.stops?.length > 0 && tour.stops.map((stop, index) => <PodcastStopName stop={stop} id={props.id} index={index} />)}
        </>
    )

}

export default PodcastStop