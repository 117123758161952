import { ApiCall } from './utils'

//playlist
const getById = (id) => ApiCall('get', `/playlist/${id}`)

const search = (params) => ApiCall('post', `/playlist/search`, params)
const searchByElastic = (from, size, params) => ApiCall('post', `/search/playlist/${from}/${size}`, params)

const update = (params) => ApiCall('post', `/playlist`, params)

const process = (id, params) => ApiCall('post', `/playlist/${id}/process`, params)

const deleteById = (id) => ApiCall('delete', `/playlist/${id}`)

const updateLive = (id, live) => ApiCall('put', `/playlist/${id}/live/${live}`)

//tag
const getTag = (id) => ApiCall('get', `/playlist/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/playlist/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/playlist/tag/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/playlist/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/playlist/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/playlist/category/${id}`)

//Person
const getPerson = (id) => ApiCall('get', `/playlist/${id}/curator`)

const updatePerson = (id, params) => ApiCall('post', `/playlist/${id}/curator`, params)

const deletePerson = (id) => ApiCall('delete', `/playlist/curator/${id}`)

//tours
const getTour = (id) => ApiCall('get', `/playlist/${id}/tour`)

const updateTour = (id, params) => ApiCall('post', `/playlist/${id}/tour`, params)

const deleteTour = (id) => ApiCall('delete', `/playlist/tour/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/playlist/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/playlist/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/playlist/theme/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/playlist/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/playlist/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/playlist/podcast/${id}`)

//related playlist
const getRelatedPlaylist = (id) => ApiCall('get', `/playlist/${id}/related_playlist`)

const updateRelatedPlaylist = (id, params) => ApiCall('post', `/playlist/${id}/related_playlist`, params)

const deleteRelatedPlaylist = (id) => ApiCall('delete', `/playlist/related_playlist/${id}`)

//related entity
const getMap = (id) => ApiCall('get', `/playlist/${id}/map`)

const getRelatedPerson = (id) => ApiCall('get', `/playlist/${id}/related_person`)

const getRelatedSite = (id) => ApiCall('get', `/playlist/${id}/related_site`)

//playlist Event
const getEvent = (id) => ApiCall('get', `/playlist/${id}/event `)

const updateEvent = (id, params) => ApiCall('post', `/playlist/${id}/event`, params)

const deleteEvent = (id) => ApiCall('delete', `/playlist/event/${id}`)


//playlist Event_series
const getEventSeries = (id) => ApiCall('get', `/playlist/${id}/event_series`)

const updateEventSeries = (id, params) => ApiCall('post', `/playlist/${id}/event_series`, params)

const deleteEventSeries = (id) => ApiCall('delete', `/playlist/event_series/${id}`)

//recipe

const getPlaylistRecipe = (id) => ApiCall('get', `/playlist/${id}/recipe`)

const updatePlaylistRecipe = (id, params) => ApiCall('post', `/playlist/${id}/recipe`, params)

const deleteRecipe = (id) => ApiCall('delete', `/playlist/recipe/${id}`)

//curator

const getCurator = (id) => ApiCall('get', `/playlist/${id}/curator_mgmt`)

const updateCurator = (id, params) => ApiCall('post', `/playlist/${id}/curator_mgmt`, params)

export default {
    getById,
    search,
    searchByElastic,
    update,
    deleteById,
    process,
    updateLive,

    getTag,
    updateTag,
    deleteTag,

    getCategory,
    updateCategory,
    deleteCategory,

    getPerson,
    updatePerson,
    deletePerson,

    getTour,
    updateTour,
    deleteTour,

    getRelatedPlaylist,
    updateRelatedPlaylist,
    deleteRelatedPlaylist,

    getTheme,
    updateTheme,
    deleteTheme,

    getPodcast,
    updatePodcast,
    deletePodcast,

    getMap,
    getRelatedPerson,
    getRelatedSite,

    getEvent,
    updateEvent,
    deleteEvent,

    getEventSeries,
    updateEventSeries,
    deleteEventSeries,

    getPlaylistRecipe,
    updatePlaylistRecipe,
    deleteRecipe,

    getCurator,
    updateCurator
}