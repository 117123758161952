import React, { useState } from 'react';
import { useEffect } from 'react';
import ReactShowMoreText from 'react-show-more-text';

const Panel = (props) => {
    const cssId = props.title + props.lineNumber;
    const [more, showMore] = useState(false);
    const [showExpand, setShowExpand] = useState(false);
    const [lineNumber, setlineNumber] = useState(props.lineNumber || 0);
    const expandable = props.expandable || false;
    const bold = props.bold ? 'fw-bold' : '';
    const italic = props.italic ? 'fst-italic' : '';
    const uppercase = props.uppercase ? 'text-uppercase' : ''

    useEffect(() => {
        setTimeout(() => {
            let anchor = document.getElementsByClassName(`content-css-${cssId}`)

            const tag = anchor[0]?.firstChild
            let allElement = tag?.firstChild?.children
            allElement = allElement ? Array.from(allElement) : null; // all span tags

            if (allElement) {
                allElement.forEach(item => {
                    if (item?.children?.[0]?.children) {
                        let lastChild = Array.from(item?.children?.[0]?.children) //last child of all span tags
                        //if 2, show more option will be shown
                        if (expandable) {
                            if (lastChild?.length === 2) setShowExpand(true)
                            else setShowExpand(false)
                        } else {
                            setShowExpand(false)
                        }
                    }
                })
            }

        }, 0);
        // eslint-disable-next-line
    }, [props.description])

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <div className={`${bold} ${italic} ${uppercase}`}>{props.title}</div>
                {(showExpand) &&
                    <div>
                        {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }}
                            onClick={() => { setlineNumber(props.lineNumber || 0); showMore(false) }}>Show less <i className='fe fe-chevron-up ms-1' /></div>}
                        {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }}
                            onClick={() => { setlineNumber(0); showMore(true) }}>Show more <i className='fe fe-chevron-down ms-1' /></div>}
                    </div>
                }
            </div>
            <ReactShowMoreText lines={lineNumber} more="" less="" className={`content-css-${cssId}`}>
                {props.description && <div dangerouslySetInnerHTML={{ __html: props.description }} />}
            </ReactShowMoreText>
        </div>
    )
};

export default Panel;