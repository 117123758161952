/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uniqBy } from 'lodash'
import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import Spinner from '../spinner'
import EntityPodcast from './entityPodcast'
import { dateTitle, getNewItemIndex, handleReorder, newTempId, personName, getOffsetIndex } from '../../helpers'

const _api = require('../../api')

const PodcastEditor = (props) => {
  const dispatch = useDispatch()
  const entity = useSelector((state) => state[props.id])
  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const [personPodcast, setPersonPocast] = useState([])
  const [spinner, setSpinner] = useState(false)
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-podcast'

  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    if (entity?.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleReorder(entity.podcasts, entity.dates, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...entity, podcasts: items } })
      props.refresh && props.refresh()
    })
  }, [dragula])
  // Dragula End

  useEffect(() => {
    if (props?.type === "person") getPersonPodcast()
    if (entity?.podcasts && entity?.podcasts?.length > 1 && (props?.type !== 'playlist' ) && (props?.type !== 'recipe' )) _api[props?.type].automatedPlaylist(props?.id)
  }, [entity?.podcasts?.length])

  const getPersonPodcast = async () => {
    setSpinner(true)

    let pods = []
    if (entity.credited_podcasts) {
      for (const item of entity.credited_podcasts) {
        let res = await _api.podcast.getRelatedItem(item.podcast_id) || []
        res = res?.filter(x => (x.source === 'map' || x.source === 'playlist'))
        if (res?.length >= 0) pods.push(item)
      }

      const response = await _api.person.getPersonSitePodcasts(props.id)
      let relatedPodcasts = entity?.podcasts?.filter(x => x.source !== 'credited_podcast')
      relatedPodcasts = relatedPodcasts?.filter(f => response.every(item => item.podcast_id !== f.podcast_id)) || [];
      let personPodcasts = [...pods, ...relatedPodcasts]
      personPodcasts = uniqBy(personPodcasts, 'podcast_id')
      setPersonPocast(personPodcasts)

      setSpinner(false)
    }
  }

  const updatePodcasts = async (data, date_id) => {
    let podcasts = entity.podcasts || []
    let items = (data?.length && Array.isArray(data)) ? data : data?.entityPodcasts
    let index = getNewItemIndex(podcasts, entity.dates, date_id)
    if (items?.length > 0) {
      for (let i in items) {
        let podcast = {
          'id': newTempId(),
          'podcast_id': items[i].id,
          'podcast_name': items[i].name,
          'date_id': date_id,
          'podcast_image_url': items[i]?.image?.url ? items[i]?.image?.url : entity?.image?.url ? entity?.image?.url : null,
          'podcast_type': items[i].type,
          'podcast_credits': items[i].credits?.length ? personName(items[i].credits[0]) : '',
          'podcast_duration': items[i].duration,
          'sort': podcasts.length + 1
        }
        podcast[`${props.type}_id`] = entity.id
        if (props.type === 'tour') podcast[`stop_id`] = null
        podcasts.splice(index, 0, podcast)
      }
    }
    podcasts = uniqBy(podcasts, 'podcast_id')
    podcasts.forEach((item, index) => item.sort = index + 1)
    podcasts = await _api[props.type].updatePodcast(entity.id, podcasts)
    dispatch({ type: 'entity', payload: { ...entity, podcasts: podcasts } })
    //refresh dragula
    setDragula(uniqid())
  }

  const removePodcast = async (id) => {
    const index = entity.podcasts.findIndex(x => x.id === id)
    if (entity.podcasts[index]) {
      if (!entity.podcasts[index].id.startsWith('temp.'))
        await _api[props.type].deletePodcast(entity.podcasts[index].id)

      entity.podcasts.splice(index, 1)
      entity.podcasts.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...entity, podcasts: entity.podcasts } })
    }
  }

  return (
    <>
      <Spinner display={spinner}>
        <EntityPodcast id={props.id} type={props.type}
          title={props.type === 'tour' ? 'Podcast for the tour' : 'Podcasts'}
          items={props?.type === "person" ? (personPodcast?.length > 0 ? personPodcast : []) : 
          props?.type === "tour" ? (entity?.podcasts?.length > 0 ? entity?.podcasts.filter((elem) => elem.stop_id === null) : []) : 
          props?.type === "playlist" ? (entity?.podcasts?.length > 0 ? entity?.podcasts : []) : 
          (entity?.podcasts?.length > 0 ? entity?.podcasts?.filter(x => !x.date_id) : [])}
          onDelete={async (index) => await removePodcast(index)}
          onUpdate={async (items) => await updatePodcasts(items, null)}
          dragulaId={dragulaContainer} dragula={dragula}
          offsetIndex={0}
        />
      </Spinner>


      {(props.type === 'site' || props.type === 'map') && entity?.dates?.map((date, index) => {
        return (
          <div className='mt-4' key={index}>
            <EntityPodcast id={props.id} type={props.type}
              title={`${dateTitle(date)} Podcasts`}
              items={entity?.podcasts?.length > 0 ? entity?.podcasts?.filter(x => x.date_id === date.id) : []}
              onUpdate={async (items) => await updatePodcasts(items, date.id)}
              onDelete={async (id) => await removePodcast(id)}
              dragulaId={`${dragulaContainer}-${date.id}`} dragula={dragula}
              offsetIndex={getOffsetIndex(entity.podcasts, entity.dates, date.id)}
            />
          </div>
        )
      })}
    </>
  )
}

export default PodcastEditor
