/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageTitle from './pageTitle';
import axios from 'axios';
import SubSection from './subSection';
import ImageCard from './imageCard';
import Spinner from './spinner';
import { personName } from '../helpers';

const _API = require('../api')

const RelationshipMapping = (props) => {
    const entity = useSelector(state => state[props.id]);
    const associated_sites = useSelector((state) => state.associated_sites);
    const [tierData, setTierData] = useState([]);
    const [count, setCount] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const history = useHistory();
    useEffect(() => {
        dataFunction()
    }, [])
    const dataFunction = async () => {
        setSpinner(true)
        let relatedSitesIds = [];
        let relatedPeopleIds = [];
        let associatedSiteIds = [];
        let asscociatedPeopleIds = [];
        let factoidSiteIds = [];
        let factoidPeopleIds = [];
        let affiliations = [];
        let highlightPeopleIds = [];
        let highlightSiteIds = [];
        let creditedSiteIds = [];
        let creditedPeopleIds = [];
        let inThisPhotos = [];
        let inThisPhotoSiteId = [];
        let inThisPhotoPeopleId = [];

        entity.related_sites.forEach(item => {
            relatedSitesIds.push(item?.site?.id);
        });

        entity.associated_sites && entity.associated_sites.forEach(item => {
            if (item?.site) {
                associatedSiteIds.push(item?.site.id);
            } else if (item?.person) {
                asscociatedPeopleIds.push(item?.person.id);
            }
        });

        if (props?.type === 'site') {
            let associatedArray = [].concat(associated_sites?.list).concat(associated_sites?.updateList)
            associatedArray = associatedArray.filter((item) => item?.as_deleted === false)
            associatedArray.length > 0 && associatedArray.forEach((item) => {
                    associatedSiteIds.push(item?.site?.id)
                })
        }

        if (props?.type === 'people') {
            let associatedData = []
            const sitesByPersonId = await _API.site.getByPersonId(entity.id)
            const sitesByDateByPersonId = await _API.site.getByDatePersonId(entity.id)
            associatedData.concat(sitesByDateByPersonId).concat(sitesByPersonId).forEach((data) => {
                associatedSiteIds.push(data?.id)
            })
        }

        entity.related_people.forEach(item => {
            relatedPeopleIds.push(item.person.id);
        });

        entity.highlights && entity.highlights.forEach(item => {
            if (item.entity.type === 'site') {
                highlightSiteIds.push(item.entity.id);
            } else if (item.entity.type === 'person') {
                highlightPeopleIds.push(item.entity.id);
            }
        })

        entity.affiliations && entity.affiliations.forEach(item => {
            affiliations.push(item.person.id);
        });

        entity.stories.forEach(story => {
            if (story?.entity?.type.toLowerCase() === 'site') {
                factoidSiteIds.push(story.entity.id);
            } else if (story?.entity?.type.toLowerCase() === 'person') {
                factoidPeopleIds.push(story.entity.id);
            }
        });
        entity.people && entity.people.forEach(item => {
            creditedPeopleIds.push(item.person.id)
        })

        entity.date_people.length>0 && entity.date_people.forEach(item => {
            item.people.forEach((entity) => { creditedPeopleIds.push(entity.person.id) })
        })

        await axios.get(`${props?.type}/${props?.id}/images`).then((response) => {
            response.data?.length > 0 && response.data.forEach(element => {
                inThisPhotos.push(element);
            })
        })
        await axios.get(`${props?.type}/${props?.id}/documents`).then((response) => {
            response.data?.length > 0 && response.data.forEach(element => {
                inThisPhotos.push(element)
            })
        })
        if (props?.type === 'site') {
            await axios.get(`${props?.type}/${props?.id}/date_images`).then((response) => {
                response.data?.length > 0 && response.data.forEach(element => {
                    element.images.forEach((image) => { inThisPhotos.push(image) })
                })
            })
        }
        inThisPhotos.length > 0 && inThisPhotos.filter(image => (image.type !== 'then_now') && (image.image_tags?.length > 0)).forEach((image) => { image.image_tags.length > 0 && image.image_tags.forEach((tag) => {
             if (tag?.type.toLowerCase() === 'site') { inThisPhotoSiteId.push(tag?.entity?.id) }
             else if (tag?.type.toLowerCase() === 'people') { inThisPhotoPeopleId.push(tag?.entity?.id) } 
        }) })

        
        let data = [
            {
                tier: 1,
                data: [],
                title: 'TIER ONE'
            },
            {
                tier: 2,
                data: [],
                title: 'TIER TWO'
            },
            {
                tier: 3,
                data: [],
                title: 'TIER THREE'
            },
            {
                tier: 4,
                data: [],
                title: 'TIER FOUR'
            }
        ];

        _API.site.getByPersonId(entity.id).then(sites => {
            sites.forEach(item => {
                data.forEach(tier => {
                    if (tier.tier === 1) {
                        tier.data.push(item)
                    }
                })
            })
        });
        await axios.get(`/site?ids=${relatedSitesIds + ',' + associatedSiteIds + ',' + factoidSiteIds + ',' + highlightSiteIds + ',' + creditedSiteIds + ',' + inThisPhotoSiteId}&show_all=true`).then((response) => {
            response.data.forEach(item => {
                if (relatedSitesIds.includes(item.id) || creditedSiteIds.includes(item.id)) {
                    data.forEach(tier => {
                        if (tier.tier === 2) {
                            tier.data.push(item)
                        }
                    })
                }
                if (associatedSiteIds.includes(item.id)) {

                    data.forEach(tier => {
                        if (tier.tier === 1) {
                            tier.data.push(item)
                        }
                    })
                }
                if (factoidSiteIds.includes(item.id) || highlightSiteIds.includes(item.id)) {
                    data.forEach(tier => {
                        if (tier.tier === 3) {
                            tier.data.push(item)
                        }
                    })
                }
                if (inThisPhotoSiteId.includes(item.id)) {
                    data.forEach(tier => {
                        if (tier.tier === 4) {
                            tier.data.push(item)
                        }
                    })
                }
            })
        })

        await axios.get(`/people?ids=${relatedPeopleIds + ',' + asscociatedPeopleIds + ',' + factoidPeopleIds + ',' + affiliations + ',' + highlightPeopleIds + ',' + creditedPeopleIds + ',' + inThisPhotoPeopleId}&show_all=true`)
            .then((response) => {
                response.data.forEach(item => {
                    if (relatedPeopleIds.includes(item.id)) {
                        data.forEach(tier => {
                            if (tier.tier === 2) {
                                tier.data.push(item)
                            }
                        })
                    }
                    if (asscociatedPeopleIds.includes(item.id) || creditedPeopleIds.includes(item.id) || affiliations.includes(item.id) || highlightPeopleIds.includes(item.id)) {
                        data.forEach(tier => {
                            if (tier.tier === 1) {
                                tier.data.push(item)
                            }
                        })
                    }
                    if (factoidPeopleIds.includes(item.id)) {
                        data.forEach(tier => {
                            if (tier.tier === 3) {
                                tier.data.push(item)
                            }
                        })
                    }
                    if (inThisPhotoPeopleId.includes(item.id)) {
                        data.forEach(tier => {
                            if (tier.tier === 4) {
                                tier.data.push(item)
                            }
                        })
                    }
                })
            })

        setTierData(data);
        let countValue = 0;
        data.forEach(tier => { countValue = countValue + tier.data.length; })
        setCount(countValue)
        setSpinner(false);
    }

    const handleRedirection = (type, id) => {
        if (type) {
            history.push(`/people/${id}/editor`)
        }
        else {
            history.push(`/site/${id}/editor`)
        }
    }

    return (
        <Spinner display={spinner}>
            <div className='px-4'>
                <PageTitle title={entity.name} />
                <hr />
                {count >= 1 && <SubSection collapsable={true} title={'RELATIONSHIP HIERARCHY'} textColor={'cnow-text-primary'} count={`${count} ${count > 1 ? 'items' : 'item'}`} button={true}>
                    {
                        tierData.map((tier, index) => {
                            return (
                                <>
                                    {tier.data.length > 0 && <SubSection key={index} collapsable={true} title={`${tier.title}`} count={`${tier.data.length}  ${tier.data.length > 1 ? 'items' : 'item'}`} select={true}>
                                        <div className='row row-cols-6 row-cols-md-6  row-cols-lg-4 row-cols-xl-4 g-2 mt-1 gx-4'>
                                            {
                                                tier.data?.length > 0 && tier.data.map((item) => {
                                                    return <div className='col cursor-pointer' onClick={() => { handleRedirection(item.type, item.id) }}><ImageCard image={item?.image?.url || ''} typeInfo={item.type ? item.type : 'site'} title={item.type ? personName(item) : item?.name} /></div>
                                                })
                                            }
                                        </div>
                                    </SubSection>}

                                </>
                            )
                        })
                    }
                </SubSection>}
            </div>
        </Spinner>
    )
}

export default RelationshipMapping