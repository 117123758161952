import React from "react";
import MapCredits from "./mapCredits";
import RelatedMapEditor from "../../components/editors/relatedMapEditor";
const PeopleAttachMap = ({ id }) => {
    return (
        <>
            <RelatedMapEditor id={id} type='person' showDate={true} />
            <MapCredits id={id} />
        </>
    )
}

export default PeopleAttachMap