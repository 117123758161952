import React from "react";
import FirstPage from "./pdfFirstPageImage";
import ImageCreditList from "../preview/imageCreditList";
import PDFImage from "./pdfImage";

const ImagePdfLayout = ({ data, enity_title, id }) => {
  return (
    <div className="row" key={id}>
      <div className="col">
        <div>
          <div className="row d-flex align-items-start">
            <div className="col-4"><PDFImage src='/img/logo/cnow_light_logo.png' className='img-fluid' /></div>
            <div className="col-5 fw-bold" style={{ color: '#63509f' }}>
              <div>37-24 24th Street, Suite 102</div>
              <div>Long Island City, NY 11101</div>
              <div className="pt-2"> 718.706.0900</div>
              <div>info@culturenow.org </div>
            </div>
          </div>
          <FirstPage />
        </div>
        <div className="py-2 d-flex"><div className="fw-bold">Project Name</div> : {enity_title}</div>
        <div className="fw-bold pb-1">List of Images:</div>
        {
          data && data?.length > 0 && data.map((elem, index) => {
            return <div className="d-flex justify-content-start align-items-top py-3"><PDFImage src={elem?.image_url} className='thumb' imageType={'thumb'} key={elem?.id} /><div className="ms-4">{elem?.image_caption}</div></div>
          })
        }
        <div className="pb-3">
          <div className='fs-large fw-bold d-flex pb-2'><div><input className="form-check-input align-middle" type="checkbox" name="select_site" /></div> <div className="ps-2">COPYRIGHT OWNER CONFIRMATION (Architect, Artist, etc):</div></div>
          <p>I affirm that I am sole owner and/or copyright holder (as indicated above) of the drawings, photographs, or other images submitted herewith, that they are original works, and that neither these materials nor the permission granted hereby infringe upon the ownership, copyright, trademark or
            other rights of others. I affirm that I have the rights from the photographer(s) to include the following images:</p>
        </div>
        <div>
          <div className="py-2 d-flex"><div className="fw-bold">Image Credits :</div>  <div className="px-2"><ImageCreditList data={data[0]?.credits} /></div> </div>
          <p className="py-2 fw-bold">Name : </p>
          <p className="py-2 fw-bold">Signature : </p>
          <p className="py-2 fw-bold">Date : </p>
        </div>
      </div>
    </div>
  )
}

export default ImagePdfLayout