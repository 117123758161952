/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import TextEditor from './textEditor';
import Button from '../buttons/default';
import { handleReorder, newTempId } from '../../helpers';
const _api = require('../../api')

const RecipeDescription = (props) => {
	const dispatch = useDispatch();
	const entity = useSelector(state => state[props.id]);

	// Dragula Start
	const [dragula, setDragula] = useState(uniqid())
	const dragulaRef = useRef()
	const dragulaContainer = 'dragula-container-sections'

	useEffect(() => {
		let containers = []
		containers.push(document.getElementById(dragulaContainer))
		if (entity?.dates?.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
		dragulaRef.current = Dragula(containers, {})
		dragulaRef.current.on('drop', () => {
			const items = handleReorder(entity.sections, null, dragulaContainer)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, sections: items } })
			props.refresh && props.refresh()
		})
	}, [dragula])
	// Dragula End

	const addRelatedPerson = () => {
		entity.sections.push({
			'id': newTempId(),
			"title": '',
			'description': '',
		})
		entity.sections.forEach((item, index) => item.sort = index + 1)
		setDragula(uniqid())
		dispatch({ type: 'entity', payload: { ...entity, sections: entity.sections } });
	}

	const removeRelatedPerson = async (index) => {
		if (!entity.sections[index].id.startsWith('temp.'))	await _api.recipe.deleteSection(entity.sections[index].id)
		entity.sections.splice(index, 1)
		entity.sections.forEach((item, index) => item.sort = index + 1)
		dispatch({ type: 'entity', payload: { ...entity, sections: entity.sections } });

	}

	const handleEditorChange = (index, data) => {
		entity.sections[index].description = data;
		dispatch({ type: 'entity', payload: { ...entity, sections: entity.sections } });
	}

	const handletitleChange = (index, data) => {
		entity.sections[index].title = data.target.value;
		dispatch({ type: 'entity', payload: { ...entity, sections: entity.sections } });
	}

	return (
		<div>
			<hr className='cnow-hr' />

			<div className='row'>
				<div className='col'>
					<label className='cnow-form-title'>RECIPE *</label>
				</div>
				<div className='col-auto'>
					<Button size='sm' icon='plus' label='Add a section' onClick={() => addRelatedPerson()} />
				</div>
			</div>
			<div id={dragulaContainer} key={dragula} >
				{
					entity?.sections?.map((item, index) => {
						return (
							<div key={index} id={item?.id} className='mb-3 dragula-item'>
								<div className='row g-1'>
									<div className={entity?.sections?.length > 1 ? 'col-auto' : 'd-none'}>
										<label className='cnow-form-label fw-bold'>Order</label>
										<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
									</div>

									<div className='col'>
										<div className='row g-1'>
											<div className='col-auto d-flex flex-grow-1'>
												<div className='w-100'>
													<label className='cnow-form-label fw-bold'>title</label>
													<input name='Recipe' type='text' value={item?.title} onChange={(data) => handletitleChange(index, data)} className='form-control' placeholder='Ingredients or Recipe or Garnishes or Suggestions or Prep Time' autoComplete='off' />
												</div>

											</div>
											<div className='col-auto'>
												<label className='cnow-form-label'></label>
												<div className='btn-list'>

													<Button icon='delete' onClick={() => removeRelatedPerson(index)} />
												</div>
											</div>

										</div>

										<TextEditor key={item?.id} data={item?.description} onChange={(data) => handleEditorChange(index, data)}>
											<label className='cnow-form-label mt-2 fw-bold'>Text </label>
										</TextEditor>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default RecipeDescription;