/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import { useHistory } from "react-router-dom";
import { handleReorder } from '../../../helpers';
import Button from '../../../components/buttons/default';
import LinkButton from '../../../components/buttons/link';
import PreviewButton from '../../../components/buttons/preview';
import ImageViewer from '../../../components/viewers/image';
const _api = require('../../../api')

const ExhibitionList = (props) => {
    const dispatch = useDispatch();
    const exhibition = useSelector(state => state[props.id]);
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-events'
    const history = useHistory();


    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(exhibition.event, null, dragulaContainer)
            setDragula(uniqid())
            const dispatchData = {
                id: 'exhibition',
                event: items
            }
            dispatch({ type: 'entity', payload: dispatchData })
        })
    }, [dragula])
    // Dragula End

    const addEvent = () => { history.push(`/event/searchList/exhibition`) }

    const removeEvent = async (index) => {
        if (exhibition.event[index]) {
            if (!exhibition.event[index].id.startsWith('temp.')) {
                await _api.event.deleteExhibition(exhibition.event[index].id)
            }
            exhibition.event.splice(index, 1)
            exhibition.event.forEach((item, index) => item.sort = index + 1)
            const dispatchData = {
                id: 'exhibition',
                event: exhibition.event
            }
            dispatch({ type: 'entity', payload: dispatchData });
        }
    }

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>ITEMS *</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add one more' onClick={() => addEvent()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} >
                {
                    exhibition?.event?.map((item, index) => {
                        return (
                            <div key={index} id={item?.id} className='mb-3 dragula-item'>
                                <div className='row g-1'>
                                    <div className={exhibition?.event?.length > 1 ? 'col-auto' : 'd-none'}>
                                        <label className='cnow-form-label fw-bold'>Order</label>
                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <ImageViewer key={item?.event_id} entityType='event' entityId={item?.event_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <div className='row g-1'>
                                            <div className='col-auto d-flex flex-grow-1'>
                                                <div className='w-100'>
                                                    <label className='cnow-form-label fw-bold'>Name*</label>
                                                    <input value={item?.event_name} disabled className='w-100 cnow-input-disabled-height' />
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <label className='cnow-form-label'></label>
                                                <div className='btn-list'>
                                                    <PreviewButton link={`/event/${item?.event_id}`} />
                                                    <LinkButton icon='edit' to={`/event/${item?.event_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeEvent(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ExhibitionList;