/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import ImageViewer from "../viewers/image";
import TextEditor from './textEditor';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId } from '../../helpers';
import SelectEventSeries from '../selects/eventSeries';

const _api = require('../../api')

const RelatedEventSeriesEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-related-eventseries'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.related_event_series, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, related_event_series: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addRelatedevent_series = () => {
        if (!entity.related_event_series) entity.related_event_series = [];
        if (props.type === 'event_series') {
            entity.related_event_series.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,

                [`${props.type}_id`]: props?.id,
                'related_event_series_id': "",
                'related_event_series_name': "",
                'sort': entity.related_event_series ? entity.related_event_series.length + 1 : 1,
            });
        } else {
            entity.related_event_series.push({
                'description': "",
                'id': newTempId(),
                'source': props.type,
                [`${props.type}_id`]: props?.id,
                'event_series_id': "",
                'event_series_name': "",
                'sort': entity.related_event_series ? entity.related_event_series.length + 1 : 1,
            });
        }
        entity.related_event_series.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, related_event_series: entity.related_event_series } });
    }

    const removeRelatedevent_series = async (index) => {
        if (entity.related_event_series[index]) {
            if (!entity.related_event_series[index].id.startsWith('temp.')) {
                await _api[props.type].deleteEventSeries(entity.related_event_series[index].id)
            }

            entity.related_event_series.splice(index, 1)
            entity.related_event_series.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, related_event_series: entity.related_event_series } });
        }
    }

    const handleevent_seriesChange = (index, value) => {
        if (props.type === 'event_series') {
            entity.related_event_series[index].related_event_series_id = value.id
            entity.related_event_series[index].related_event_series_name = value.name
        } else {
            entity.related_event_series[index].event_series_id = value.id
            entity.related_event_series[index].event_series_name = value.name
        }
        dispatch({ type: 'entity', payload: { ...entity, related_event_series: entity.related_event_series } });
    }

    const handleEditorChange = (index, data) => {
        entity.related_event_series[index].description = data;
        dispatch({ type: 'entity', payload: { ...entity, related_event_series: entity.related_event_series } });
    }

    return (
        <div>
            {props.type !== 'event' && <hr className='cnow-hr' />}
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.type !== "event" ? 'Related Event Series' : ""}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Event Series' onClick={() => addRelatedevent_series()} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.related_event_series?.length > 0 && entity.related_event_series.map((item, index) => {
                        return (
                            <div key={index} id={item.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`}>
                                <div className='row g-1'>
                                    {
                                        entity?.related_event_series?.length > 1 &&
                                        <div className='col-auto'>
                                            <label className='cnow-form-label fw-bold'>Order</label>
                                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col-auto'>
                                        <div className=''>&nbsp;</div>
                                        <ImageViewer key={item?.id} entityType='event_series' entityId={item?.event_series_id} type='thumb' cssClass='thumb-small' />
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-label fw-bold'>Title</label>
                                        <div className='row g-1'>
                                            <div className='col'>
                                                {!item.id.startsWith('temp.') ?
                                                    <input value={item?.event_series_name} disabled className='h-100 w-100' />
                                                    :
                                                    <SelectEventSeries key={item.id}
                                                        value={{
                                                            id: props.type === 'event_series' ? item?.related_event_series_id : item?.event_series_id,
                                                            name: props.type === 'event_series' ? item?.related_event_series_name : item?.event_series_name
                                                        }}
                                                        onChange={(value) => handleevent_seriesChange(index, value)} />
                                                }
                                            </div>
                                            <div className='col-auto'>
                                                <div className='btn-list'>
                                                    <PreviewButton link={`event_series/${props.type === 'event_series' ? item?.related_event_series_id : item?.event_series_id}`} />
                                                    <LinkButton icon='edit' to={`/event_series/${props.type === 'event_series' ? item?.related_event_series_id : item?.event_series_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removeRelatedevent_series(index)} />
                                                </div>
                                            </div>
                                        </div>
                                        {!props?.disableTextEditor && <TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                        </TextEditor>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RelatedEventSeriesEditor;