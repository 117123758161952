import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import ImageUploader from '../image/upload';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';
import swal from 'sweetalert';
import {  newTempId } from '../../helpers';
const _api = require('../../api');

const Overlay = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const overlay = useSelector(state => state[props.id]);
    const [uploader, showUploader] = useState(false)

    const handleChange = (e) => {
        dispatch({ type: 'entity', payload: { ...overlay, [e.target.name]: e.target.value } })
    }

    const handleLinkChange = (e, index) => {
        overlay.overlay_links[index][e.target.name] = e.target.value;
        dispatch({ type: 'entity', payload: { ...overlay, overlay_links: overlay.overlay_links } })
    }

    const setThumbnailImage = (image) => {
        showUploader(false)
        dispatch({ type: 'entity', payload: { ...overlay, image_id: image.id } })
    }

    const addOverlayLink = () => {
        if (!overlay.overlay_links) overlay.overlay_links = [];
        overlay.overlay_links.push({
            'type': 'Map Tiler',
            'url': '',
            'map_overlay_id': overlay.id,
            'sort': overlay.overlay_links.length + 1,
            'id' : newTempId()
        });
        dispatch({ type: 'entity', payload: { ...overlay, overlay_links: overlay.overlay_links } })
    }

    const editItems = (id) => {
        if (overlay.id === 'NEW') overlay.id = null;
        _api.map_overlay.update(overlay).then((response) => {
            dispatch({ type: 'entity', payload: response })
            dispatch({ type: 'images', payload: { source: `/map_overlay/${response.id}/editor` } })
            history.push(`/image/${id}/editor`)
        })
    }

    const handleDelete = () => {
        swal({
            title: `Remove Image?`,
            text: `Are you sure that you want to delete this image?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                dispatch({ type: 'entity', payload: { ...overlay, image_id: null } })
            }
        })
    }

    const handleMapOverlayDelete = async (data) => {
        let index = overlay.overlay_links.findIndex((elem) => elem.id === data.id)

        if (index >= 0) {
            if (!overlay.overlay_links[index].id?.startsWith('temp.')) await _api.map_overlay.deleteMapOverlays(overlay.overlay_links[index].id);
            overlay.overlay_links.splice(index, 1)
            dispatch({ type: 'entity', payload: { ...overlay, overlay_links: overlay.overlay_links } })
        }
    }

    return (
        <div className='form-group'>
            <div className='cnow-form-title'>Map Overlay Title <span className='text-cn-warning'>*</span> </div>
            <div className='row'>
                <div className='col-2'>
                    <label className='cnow-form-label'>Year</label>
                    <input name='year' type='text' className='form-control' placeholder='Year' value={overlay?.year || ''} onChange={handleChange} autoComplete='off' />
                </div>
                <div className='col'>
                    <label className='cnow-form-label'>Title</label>
                    <input name='title' type='text' className='form-control' placeholder='Title' value={overlay?.title || ''} onChange={handleChange} autoComplete='off' />
                </div>
            </div>
            <hr className='cnow-hr' />
            <div className='row mb-2'>
                <div className='col'>
                    <label className='cnow-form-title'>Map Overlay Link </label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label={'Add a Map Overlay'} onClick={() => addOverlayLink()} />
                </div>
            </div>
            <div>
                <p> NOTE: <small> Enter the source name before - https://storage.googleapis.com/ - the map link cnow-historic-maps/Map/Maps/Viele - then a forward slash / at the end
                    to create a full source link - https://storage.googleapis.com/cnow-staging/map/neworleans2_1845/ </small></p>
            </div>
            {
                overlay?.overlay_links?.length > 0 && overlay.overlay_links.map((item, index) => {
                    return (
                        <div className='row py-1'>
                            <div className='col-auto'>
                                <label className='cnow-form-label'>Order</label>
                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                            </div>
                            <div className='col-2'>
                                <label className='cnow-form-label'>Source</label>
                                <input name='type' type='text' className='form-control' disabled placeholder='Source' value={item.type} onChange={(e) => handleLinkChange(e, index)} autoComplete='off' />
                            </div>
                            <div className='col'>
                                <label className='cnow-form-label'>Source Link</label>
                                <input name='url' type='text' className='form-control' placeholder='Source Link' value={item.url} onChange={(e) => handleLinkChange(e, index)} autoComplete='off' />
                            </div>
                            <div className='col-auto'>
                                <label className='cnow-form-label'></label>
                                <div className='text-end'>
                                    <Button size='sm' icon='delete' onClick={() => { handleMapOverlayDelete(item) }} />
                                </div>
                            </div>
                        </div>)
                })
            }
            <hr className='cnow-hr' />
            <div className='mt-3'>
                <div className='cnow-form-title'></div>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>Image Thumbnail *</label>
                    </div>
                    <div className='col-auto'>
                        <Button size='sm' icon='plus' label={'Add an Image'} onClick={() => showUploader(true)} />
                    </div>
                </div>

                {
                    overlay?.image_id &&
                    <table className='table'>
                        <thead className='bg-light'>
                            <tr className='small'>
                                <td style={{ width: '90px' }}>Media</td>
                                <td>Photo Title, Credits</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='text-left' >
                                <td>
                                    <ImageViewer entityType='image' entityId={overlay.image_id} type='thumb' cssClass='thumb' />
                                </td>
                                <td>
                                    <div className='bg-light ps-2 fw-bold'>{overlay?.caption}</div>
                                    <div className=' ps-2'> {overlay?.credits ? `©${overlay?.credits}` : ''}</div>
                                </td>
                                <td>
                                    <div className='d-flex gap-1 justify-content-end'>
                                        <Button size='sm' icon='edit' onClick={() => { editItems(overlay.image_id) }} />
                                        <Button size='sm' icon='delete' onClick={() => { handleDelete() }} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            <DescriptionEditor id={overlay?.id} type='site' limit= {100} label={'About'}/>
            <hr className='cnow-hr' />
            <div className='cnow-form-title'>Related Maps</div>
            {
                overlay?.related_maps?.length > 0 && overlay.related_maps.map((map, index) => {
                    return (
                    
                        <div className='row py-1'>
                            <div className='col-auto pe-1'>
                                <label className='cnow-form-label'>Order</label>
                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                            </div>  
                            <div className='col-auto ps-0 pe-1'>
                                <div className=''>&nbsp;</div>
                                <ImageViewer key={map.id} entityType='map' entityId={map?.map_id} type='thumb' cssClass='thumb-small' />
                            </div>
                            <div className='col-4 ps-0 pe-0' >
                                <label className='cnow-form-label'>Map Name</label>
                                <input name='name' type='text' className='form-control' placeholder='Map Name' value={map.map_name} disabled autoComplete='off' />
                            </div>
                            <div className='col ps-1 pe-0'>
                                <label className='cnow-form-label'>Person Name</label>
                                <input name='name' type='text' className='form-control' placeholder='Person Name' value={map.person_name} disabled autoComplete='off' />
                            </div>
                            <div className='col-auto ps-1'>
                                <div className=''>&nbsp;</div>
                                <div className='btn-list'>
                                    <PreviewButton link={`map/${map?.map_id}`} />
                                    <LinkButton icon='edit' to={`/map/${map?.map_id}/editor`} />

                                </div>
                            </div>
                        </div>)
                })
            }
            <ImageUploader
                globalSearch={true}
                show={uploader}
                onHide={() => showUploader(false)}
                onSave={(items) => setThumbnailImage(items[0])}
                imageType={'image'}
            />
        </div>)
}
export default Overlay