import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';
import Select from 'react-select';

import SelectSite from '../../components/selects/site';
import Button from '../../components/buttons/default'
import TextEditor from '../../components/editors/textEditor';
import ImageViewer from '../../components/viewers/image';
import LinkButton from '../../components/buttons/link';
import TourAlerts from './tourAlerts';

import { customSelectStyle, newTempId } from '../../helpers';

const _api = require('../../api');

const StopSiteEditor = ({ tourId, stopId, siteId, onHide, show }) => {
    const [tourAlert, showtourAlert] = useState(false);
    const [isEdited, setEdited] = useState(false)
    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);


    const getSite = () => {
        if (stopId !== 'NEW') {
            let site = {}
            let stop = tour.stops.filter(stop => stop.id === stopId)[0]
            if (siteId === 'NEW') {
                if (stop && !stop?.sites) stop.sites = []
                site = {
                    'id': newTempId(),
                    'tour_id': tourId,
                    'stop_id': stopId,
                    'site_id': null,
                    'site_name': null,
                    'highlight': false,
                    'description': '',
                    'location': {},
                    'date_id': null,
                    'sort': stop.sites.length + 1
                }
            } else {
                site = stop?.sites.filter(site => site.site_id === siteId)[0]
            }
            return site
        } else {
            return {
                'id': newTempId(),
                'tour_id': tourId,
                'stop_id': null,
                'site_id': null,
                'site_name': null,
                'highlight': false,
                'description': '',
                'location': {},
                'date_id': null,
                'sort': 1
            }
        }
    }

    const getStop = () => {
        if (stopId !== 'NEW') return tour.stops.filter(stop => stop.id === stopId)[0]
    }

    const [stop] = useState(getStop())
    const [site, setSite] = useState(getSite());
    const [dateOptions, setDateOptions] = useState([])


    useEffect(() => {
        if (site?.site_id) getDateOptions()
        // eslint-disable-next-line
    }, [site?.site_id])

    const getDateOptions = async () => {
        let dates = await _api.site.getDate(site.site_id)
        if (dates?.length > 0) {
            let options = [];
            dates.forEach(date => {
                if (date.year) options.push({ value: date.id, label: date.year })
            });
            setDateOptions(options)
        }
    }

    const saveSite = () => {

        if (stopId === 'NEW') {
            let newSite = { ...site }
            let new_stop_id = newTempId()
            newSite.stop_id = new_stop_id;
            tour.stops.push({
                'id': new_stop_id,
                'tour_id': tour.id,
                'stop_name': newSite.site_name,
                'description': '',
                'image_id': null,
                'site_id': newSite.site_id,
                'site_name': newSite.site_name,
                'location': newSite.location,
                'additional_info': '',
                'sort': tour.stops.length + 1,
                'sites': [newSite]
            })
        } else {
            if (siteId === 'NEW') {
                if (stop && !stop.sites) stop.sites = [];
                const index = tour.stops.findIndex(s => s.id === stop.id)
                if (index >= 0) {
                    stop.sites.push(site)
                    tour.stops[index] = stop
                }
            } else {
                const siteIndex = stop.sites.findIndex(s => s.site_id === site.site_id)
                stop.sites[siteIndex] = site
                const index = tour.stops.findIndex(s => s.id === stop.id)
                tour.stops[index] = stop
            }
        }
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
        setEdited(false)
        onHide();
    }

    const handleSiteChange = async (value) => {
        let site_id = value.id
        let site_name = value.name
        let location = {}
        let siteLocations = await _api.site.getLocation(site_id)
        let date_id = null
        let year = ''
        if (siteLocations?.length > 0) {
            location = siteLocations[0]
        }
        let dates = await _api.site.getDate(site_id)
        if (dates?.length > 0) {
            date_id = dates[0].id
            year = dates[0].year
        }
        setSite({ ...site, site_id: site_id, site_name: site_name, location: location, date_id: date_id, year: year, image_id: value.image_id })
        setEdited(true)

    }

    const handleDescChange = (data) => {
        setSite({ ...site, description: data })
        setEdited(true)
    }

    const handleYearChange = (value) => {
        setSite({ ...site, date_id: value.value, year: value.label })
        setEdited(true)
    }

    return (<>
        <Modal show={show} onHide={() => onHide()} size='xl' centered backdrop="static" style={{ opacity: tourAlert ? "0.8" : '1' }}>
            <Modal.Body>
                <div>
                    <div className='row'>
                        <div className='col'>
                            <div className='row'>
                                {
                                    tour.format === 2 ? <>
                                        <div className='col-auto'><div className='tour-stop-site-number d-flex'>{`${stop?.sort}.${site?.sort}`}</div></div>
                                        <div className='col-auto p-1'><label className='cnow-form-title mb-0'>Site In</label></div>
                                        <div className='col-auto'><div className='tour-stop-number d-flex'>{stop?.sort}</div></div>
                                        <div className='col p-1'><label className='cnow-form-title mb-0'>{stop?.stop_name}</label></div>
                                    </> : <><div className='col-auto'><div className='tour-stop-site-number d-flex'>{siteId === 'NEW' ? tour.stops?.length + 1 : stop.sort}</div></div>
                                        <div className='col p-1'><label className='cnow-form-title mb-0'>{stop?.stop_name}</label></div></>
                                }

                            </div>
                        </div>
                        <div className='col-auto btn-list'>
                            {isEdited ? <Button size='sm' label='Save' color="danger" onClick={() => saveSite()} /> : <Button size='sm' label='Save' onClick={() => saveSite()} />}
                            {isEdited ? <Button size='sm' icon='close' onClick={() => showtourAlert(true)} /> : <Button size='sm' icon='close' onClick={() => onHide()} />}
                        </div>
                    </div>
                    <hr className='cnow-hr' />
                    <div name='addSite'>
                        <div className='row g-2 py-1 mb-2'>
                            <div className='col-auto'>
                                <div className=''>&nbsp;</div>
                                <ImageViewer entityType='site' entityId={site?.site_id} type='thumb' cssClass='thumb-small' />
                            </div>
                            <div className='col'>
                                <label className='cnow-form-label fw-bold'>Associated Site Name*</label>
                                <SelectSite value={{ id: site?.site_id, name: site?.site_name }} onChange={(value) => handleSiteChange(value)} />
                            </div>
                            <div className='col-2'>
                                <label className='cnow-form-label fw-bold'>Year</label>
                                <Select key={site?.date_id} styles={customSelectStyle}
                                    options={dateOptions}
                                    defaultValue={{ value: site?.date_id, label: site?.year }}
                                    onChange={(data) => handleYearChange(data)} />
                            </div>
                            <div className='col-auto'>
                                <div className=''>&nbsp;</div>
                                <LinkButton icon='edit' to={`/site/${site?.site_id}/editor`} target={"_blank"}/>
                            </div>
                        </div>
                        <div className='row  py-1'>
                            <div className='col'>
                                <TextEditor limit={250} toolbar={true} data={site?.description} onChange={(data) => handleDescChange(data)}>
                                    <label className='cnow-form-label fw-bold'>About the site</label>
                                </TextEditor>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        {
            tourAlert &&
            <TourAlerts
                show={tourAlert}
                onHide={() => showtourAlert(false)}
                tourSiteHide={onHide}
                alertSite={saveSite}
                type={'AddaSite'}
                heading={'SAVE THE SITE INFORMATION'}
                site={site}
                stop={stop}
                tourId={tour.id} />
        }
    </>)
}
export default StopSiteEditor