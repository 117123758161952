import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { dateString, cleanHtml } from "../helpers";

import PreviewButton from './buttons/preview';
import ImageViewer from "./viewers/image";
import Button from "./buttons/default";
import Spinner from "./spinner";


const _api = require('../api');

const ThenNowListView = ({ image, index, id }) => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const entity = useSelector(state => state[id]);
    const [spinner, setSpinner] = useState(false)

    // const [images, setImage] = useState([]);

    useEffect(() => {
        getChildImages()
        // eslint-disable-next-line
    }, [image?.image_id])

    const getChildImages = async () => {
        setSpinner(true)
        image.entityImages = await _api.image.getRelatedImage(image.image_id)
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
        setSpinner(false)
    }

    const handleEdit = (url) => {
        let images = [].concat(image).concat(image.entityImages)
        dispatch({ type: 'images', payload: { entityImages: images, source: location.pathname } });
        history.push({ pathname: url })
    }

    const getThenNowCaption = (img) => {
        let date = [];
        let caption = [];
        let isEmptyCaption = true;
        let description = []
        let isEmptyDesc = true;
        image.entityImages?.length > 0 && image.entityImages.forEach((item) => {

            if ((item?.taken_date?.date || item?.taken_date?.year || item?.taken_date?.month)) {
                date.push(dateString(item?.taken_date))
            }
            if (item.caption) {
                caption.push(item?.related_image_caption)
                isEmptyCaption = false
            }
            if (item.description) {
                description.push(item?.description)
                isEmptyDesc = false
            }
        })

        let newCaption = ''
        const getDescription = () => {
            if (isEmptyDesc) {
                if (entity.description) {
                    let newDescription = entity.description
                    return newDescription
                }
            } else if (!isEmptyDesc) {
                return description.join(' & ')
            }
        }

        let updatedThenNowCaption = isEmptyCaption === true ? entity.name : caption.join(' & ');
        if (date.length > 1) date = date.join(' & ')
        if (image.image_caption || image.description) {
            image.image_caption = image.image_caption ? image.image_caption.trim() : updatedThenNowCaption.trim();
            image.description = image.description ? image.description : getDescription();
            //In Then and Now page below post api are creating empty images as variable img is undefined. Below line of code is commented
            // _api.image.update(img)
            let datedes = date?.length > 0 ? `<b>${date} -</b>` : ''
            let imageCap = image.image_caption.length > 0 ? `<b>${image.image_caption}</b> -` : ''
            newCaption = datedes + imageCap + (image.description ? image.description : '')
            return newCaption
        } else {
            image.image_caption = image.image_caption ? image.image_caption : updatedThenNowCaption;
            image.description = image.description ? image.description : getDescription();
            let datedes = date.length > 0 ? `<b>${date} - </b>` : ''
            let imageCap = image.image_caption.length > 0 ? `<b>${image.image_caption}</b> -` : ''
            newCaption = datedes + imageCap + (image.description ? image.description : '')
            // _api.image.update(img)
            return newCaption
        }

    }

    const HandleLive = (image, status) => {
        const Index = entity?.images?.findIndex((el) => {
            return image.id === el.id
        })
        entity.images[Index].then_and_now_live = status;
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
    }

    return (
        <>
            <Spinner display={spinner}>
                <div key={image.id} className='row g-2'>
                    <div className='col-auto'>
                        <label className='cnow-form-label fw-bold'>Order</label>
                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                    </div>
                    <div className='col-auto' >
                        <div className=''>&nbsp;</div>
                        <span className="cursor-pointer" onClick={() => { handleEdit(`/image/${image.image_id}/editor`) }} >
                            <ImageViewer url={image.image_url} type='thumb' cssClass='thumb-1x' />
                        </span>
                    </div>
                    <div className='col'>
                        <label className='cnow-form-label fw-bold'>Caption - Description</label>
                        <div className='cnow_onThisDay' onClick={() => { handleEdit(`/image/${image.image_id}/editor`) }}>
                            <span className="cnow-text-primary" dangerouslySetInnerHTML={{ __html: cleanHtml(getThenNowCaption()) }} />
                        </div>
                    </div>
                    <div className='col-auto btn-list align-self-end'>

                        <div className='then_now_buttons'>
                            {image?.then_and_now_live === true ? <Button size='live' icon='live' onClick={() => { HandleLive(image, false) }} /> : <Button size='live' icon='not-live' color='danger' onClick={() => { HandleLive(image, true) }} />}
                            <Button size='sm' target='_self' icon='edit' onClick={() => { handleEdit(`/image/${image.image_id}/editor`) }} />
                            <PreviewButton size='sm' link={`image/${image.image_id}`} />
                        </div>
                    </div>
                </div>
                {
                    image.entityImages?.length > 0 && image.entityImages.map((item, subIndex) => {
                        return (
                            <div key={item.id} className='row g-2 mt-2 then_now_margin'>
                                <div className='col-auto'>
                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={subIndex + 1} autoComplete="off" />
                                </div>
                                <div className='col-auto'>
                                    <ImageViewer key={item.id} entityType='image' entityId={item.related_image_id} type='thumb' cssClass='thumb-1x' />
                                </div>
                                <div className='col'>
                                    <input value={item.related_image_caption} disabled className='w-100' />

                                </div>
                                <div className='col-auto btn-list'>
                                    <PreviewButton size='sm' link={`image/${item.related_image_id}`} />
                                    <Button size='sm' target='_self' icon='edit' onClick={() => handleEdit(`/image/${item.related_image_id}/editor`)} />
                                </div>
                            </div>
                        )
                    })
                }
            </Spinner>
        </>
    )
}
export default ThenNowListView;