import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, } from 'react-redux'
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import PageTitle from '../../components/pageTitle';
import ImageViewer from "../../components/viewers/image";
import Button from "../../components/buttons/default";
import PreviewButton from '../../components/buttons/preview';
import TourSearch from "./tourSearch";
import { handleReorder, newTempId } from '../../helpers';
const _api = require('../../api')
const EventSeriesTour = (props) => {
  const tour = useSelector(state => state[props.id])
  const dispatch = useDispatch()
  const [showUpload, setShowUpload] = useState(false);
  const entity = useSelector((state) => state[props.id]);

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-related-tours'
  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleReorder(entity.tour, entity.dates, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...entity, tour: items } })
      props.refresh && props.refresh()
    })// eslint-disable-next-line
  }, [dragula])
  // Dragula End

  const onUpdate = async (data) => {
    let items = data
    if (entity.tour?.length === 0) entity.tour = []
    if (items?.length > 0) {

      for (let i in items) {
        entity.tour.push({
          'id': newTempId(),
          'tour_id': data[i].id,
          'description': '',
          "tour_name": data[i].name,
          'source': props.type,

          'sort': entity.tour ? entity.tour.length + 1 : 1,
        })
      }
    }
    entity.tour.forEach((item, index) => item.sort = index + 1)
    setDragula(uniqid())
    dispatch({ type: 'entity', payload: { ...entity, tour: entity.tour } });
  }

  const removetour = async (id) => {
    const index = entity.tour.findIndex(x => x.id === id)
    if (entity.tour[index]) {
      if (!entity.tour[index].id.startsWith('temp.'))
        await _api.event_series.delete_tour(entity.tour[index].id)

      entity.tour.splice(index, 1)
      entity.tour.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...entity, tour: entity.tour } })
    }
  }

  // const handleEdit = (route) => {
  //   window.open({ pathname: route })
  //   // dispatch({ type: 'podcasts', payload: { entityPodcasts: entity.podcasts, source: location.pathname } })
  // }

  return (
    <div>
      <PageTitle title={tour?.name} />
      <div className='d-flex justify-content-end align-items-end mb-2'>
        <div>
          <Button size='md' icon='plus' label='Add a Tour   ' onClick={() => setShowUpload(true)} />
        </div>
      </div>
      <div>
        <hr className='cnow-hr' />

        <div id={dragulaContainer} key={dragula} >
          {
            entity?.tour?.length > 0 && entity.tour.map((item, index) => {
              return (
                <div key={index} id={item.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`}>
                  <div className='row g-1'>
                    {
                  
                      <div className='col-auto'>
                        <label className='cnow-form-label fw-bold'>Order</label>
                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                      </div>
                    }
                    <div className='col-auto'>
                      <div className=''>&nbsp;</div>
                      <ImageViewer key={item?.id} entityType='tour' entityId={item?.tour_id} type='thumb' cssClass='thumb-small' />
                    </div>
                    <div className='col'>
                      <label className='cnow-form-label fw-bold'>Title</label>
                      <div className='row g-1'>
                        <div className='col'>
                          <input value={item.tour_name} className={'cnow-form-title text-black form-control cnow-preview-title fw-bold'} disabled />
                        </div>
                        <div className='col-auto'>
                          <div className='btn-list'>
                            <PreviewButton size='md' icon='preview' link={`event/${item?.tour_id}`} />
                            <Button size='md' icon='edit' onClick={() => {
                              window.open(`/tour/${item?.tour_id}/editor`)
                            }} />
                            <Button size='md' icon='delete' onClick={() => removetour(item?.id)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      {
        showUpload && (
          <TourSearch show={true} entityId={props?.id} onSave={async (items) => await onUpdate(items)}
            onHide={() => { setShowUpload(false) }}

          />
        )
      }

    </div>
  )
}

export default EventSeriesTour