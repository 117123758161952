import React, { useState } from 'react';
import { personName, relatedPersonName } from '../../helpers';
const MIN_LENGTH = 3

const Publication = ({ data }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold fst-italic'>Publications</div>

                        {
                            data.length > MIN_LENGTH &&
                            <div>
                                {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                            </div>
                        }
                    </div>
                    {
                        data.slice(0, length).map((item, index) => {
                            return <div key={index}>
                                <a href={`${process.env.REACT_APP_WEB_URL}${!item.is_participant ? 'people' : 'participant'}/${item?.person_id || item?.related_person_id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none text-dark'>{personName(item) || relatedPersonName(item)}</a>
                            </div>
                        })
                    }
                </>
            }
        </>
    )
}

export default Publication;