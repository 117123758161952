import React from 'react';

const RecurringEventList = ({ data }) => {
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    <div className='fw-bold fst-italic'>Recurring Events</div>
                </>
            }
        </>
    )
}

export default RecurringEventList;