import React from 'react';
import ImageViewer from '../viewers/image';
import LongTextViewer from '../viewers/longText';
import moment from 'moment';
import { locationName, personName } from '../../helpers';
const FeaturedCardEntityOfDay = (props) => {
    const data = props.data
    const entity_type = props.entity_type
    const convertDateTimeZone = (date) => {
        return moment.unix(date).format('LT dddd, MMMM Do YYYY');
    }

    const giveHTMLContent = () => {
        const title = data?.name || personName(data) || data?.caption
        const year = data?.dates?.[0]?.year || data?.taken_date?.year || data?.year
        const location = locationName(data?.locations?.[0] || data?.stops?.[0]?.location)
        const recipePersonCreator = data?.people?.filter((elem) => elem.role_name === 'Creator')
        const podcastAuthorName = entity_type === 'podcast' ? data?.credits?.filter((elem) => elem.role_name === 'Author') : []
        const playlistCuratorName = entity_type === 'playlist' ? data?.curators?.[0] : []
        const personprofessions = data?.professions?.[0]
        const titleYear = <div className={`fw-bold d-flex `} >
            {title} {((year) && (title)) ? ' - ' : ''} {year}
        </div>
        const titleLocation = <> <div className={`fw-bold d-flex `} >
            {title}
        </div>
            {((location) && (title)) ? ' - ' : ''} {location}
        </>

        if (entity_type === 'site') {
            return <>
                {titleYear}
            </>
        }
        if (entity_type === 'event') {
            return <>
                <div className={`fw-bold d-flex `} > {title}</div> {((location) && (title)) ? ' - ' : ''} {location}  {((location) && (title) && (data?.dates?.[0]?.start_date)) ? ' - ' : ''} {data?.dates?.[0]?.start_date && convertDateTimeZone(data?.dates?.[0]?.start_date)}
            </>
        }
        if (entity_type === 'recipe') {
            return <>
                <> <div className={`fw-bold d-flex `}> {title}</div> {(recipePersonCreator && (title)) ? ' - ' : ''} {recipePersonCreator?.[0]?.person_name}  {((recipePersonCreator?.length > 0) && (title)) ? ', ' : ''} {recipePersonCreator?.[0]?.profession_name}</>
            </>
        }
        if (entity_type === 'tour') {
            return <>
                <div className={`fw-bold d-flex `} > {title} </div>
                {((location) && (title)) ? ', ' : ''} {location}
            </>
        }
        if (entity_type === 'person') {
            return <>
                <div className={`fw-bold `} >{title}</div> {((personprofessions) && (title)) ? ', ' : ''} {personprofessions?.profession_name}
            </>
        }
        if (entity_type === 'map') {
            return <>
                {titleYear}  {((location) && (titleYear)) ? ', ' : ''} {location}
            </>
        }
        if (entity_type === 'podcast') {
            return <>
                <div className={`fw-bold d-flex `} > {title} {((podcastAuthorName?.length > 0) && (title)) ? ', ' : ''} {podcastAuthorName[0]?.person_name}</div>
            </>
        }
        if (entity_type === 'event_series') {
            return <>
                {titleLocation}
            </>
        }
        if (entity_type === 'playlist') {
            return <>
                <div className={`fw-bold d-flex `} > {title}</div>  {((title) && (playlistCuratorName)) ? ' - ' : ''} {playlistCuratorName?.person_name}
            </>
        }
    }


    return (
        <div className={`${props.cssClass} cursor-pointer card-deck`} style={{ width: '200px', heigth: '100px' }}>
            <div className='card small bg-light border-0'>
                <div onClick={() => { }} className='cursor-pointer'>
                    <ImageViewer url={data?.image?.url || data?.image_url || data?.url} type='thumb' cssClass='thumb-2x' key={data?.image_id} />
                </div>

                <div className='card-body fz-12 p-2 pt-0 nearbyList-name  h-100 height-115px' >
                    {giveHTMLContent()}
                    <LongTextViewer lines={4}>{data?.description}</LongTextViewer>
                </div>
            </div>
        </div>
    )
}

export default FeaturedCardEntityOfDay