/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dragula from 'react-dragula';
import uniqid from 'uniqid';
import TextEditor from './textEditor';
import SelectMap from '../selects/map';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../../components/buttons/link';
import PageTitle from '../../components/pageTitle';
import { dateString, handleReorder, personName, getNewItemIndex, newTempId, getOffsetIndex } from '../../helpers';

const _api = require('../../api')

const RelatedMapEditor = (props) => {
	const dispatch = useDispatch();

	const entity = useSelector(state => state[props.id]);
	const mapTitle = props?.title || 'Attach Maps';
	const mapAddLabel = props?.buttonLabel || 'Add a Map';
	const mapLabelTextEditor = props?.mapLabelTextEditor || 'About'

	// Dragula Start
	const [dragula, setDragula] = useState(uniqid())
	const dragulaRef = useRef()
	const dragulaContainer = 'dragula-container-highlight'

	useEffect(() => {
		let containers = []
		containers.push(document.getElementById(dragulaContainer))
		if (entity?.dates && entity?.dates?.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
		dragulaRef.current = Dragula(containers, {})
		dragulaRef.current.on('drop', () => {
			const items = handleReorder(entity.maps, entity.dates, dragulaContainer)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, maps: items } })
		})
	}, [dragula])
	// Dragula End

	const addMap = (date_id) => {
		let maps = entity.maps ? entity.maps : []
		let index = getNewItemIndex(maps, entity.dates, date_id)
		if (props.showDate) {
			// Insert new map at the appropriate index
			if (props.type === 'map') {
				maps.splice(index, 0, {
					'id': newTempId(),
					'map_id': props.id,
					'related_map_id': null,
					'related_map_name': '',
					'date_id': date_id,
					'description': '',
					'source': props.type,
					'sort': index
				})
			}
			else {
				maps.splice(index, 0, {
					'id': newTempId(),
					'map_id': null,
					'map_name': '',
					'date_id': date_id,
					'description': '',
					[`${props.type}_id`]: props.id,
					'source': props.type,
					'sort': index
				})
			}
		} else {
			// Insert new map for entities without show date
			if (props.type === 'map') {
				maps.push({
					'id': newTempId(),
					'map_id': props.id,
					'related_map_id': null,
					'related_map_name': '',
					'date_id': date_id,
					'description': '',
					'source': props.type,
					'sort': index
				})
			}
			else {
				maps.push({
					'id': newTempId(),
					'map_id': null,
					'map_name': '',
					'date_id': date_id,
					'description': '',
					[`${props.type}_id`]: props.id,
					'source': props.type,
					'sort': index
				})
			}
		}

		// Update sort order
		maps.forEach((item, index) => item.sort = index + 1)

		// Update store
		setDragula(uniqid())
		dispatch({ type: 'entity', payload: { ...entity, maps: maps } });
	}

	const removeMap = async (index) => {
		if (entity.maps[index]) {
			if (!entity.maps[index].id.startsWith('temp.')) {
				if (props.type === 'map') {
					await _api.map.deleteRelatedMap(entity.maps[index].id)
				} else if (props.type === 'site' || props.type === 'person') {
					await _api[props.type].deleteMap(entity.maps[index].id)
				} else if (props.type === 'playlist') {
					await _api.map.deletePlaylist(entity.maps[index].id)
				} else if (props.type === 'podcast') {
					await _api.map.deletePodcast(entity.maps[index].id)
				}
				else if (props.type === 'tour') {
					await _api.map.deleteTour(entity.maps[index].id)
				}
				else if (props.type === 'event') {
					await _api.map.deleteEvent(entity.maps[index].id)
				}

				else if (props.type === 'event_series') {
					await _api.map.deleteEventSeries(entity.maps[index].id)
				}
			}

			entity.maps.splice(index, 1)
			entity.maps.forEach((item, index) => item.sort = index + 1)
			dispatch({ type: 'entity', payload: { ...entity, maps: entity.maps } })
		}
	}

	const handleItemChange = (index, value) => {
		if (props.type === 'map') {
			entity.maps[index].related_map_id = value.id
			entity.maps[index].related_map_name = value.name
		} else {
			entity.maps[index].map_id = value.id
			entity.maps[index].map_name = value.name
		}
		dispatch({ type: 'entity', payload: { ...entity, maps: entity.maps } });
	}

	const handleEditorChange = (index, data) => {
		entity.maps[index].description = data;
		dispatch({ type: 'entity', payload: { ...entity, maps: entity.maps } });
	}

	return (
		<>
			<PageTitle title={personName(entity)} />
			<hr className='cnow-hr' />

			<div className='row'>
				<div className='col'>
					<label className='cnow-form-title'>{mapTitle}</label>
				</div>
				<div className='col-auto'>
					<Button size='sm' icon='plus' label={mapAddLabel} onClick={() => addMap(null)} />
				</div>
			</div>
			<div id={dragulaContainer} key={dragula} className='dragula-container'>
				{
					entity?.maps?.filter(x => !x.date_id).map((item, index) => {
						return (
							<div key={index} className={`${index > 0 ? 'mt-4' : ''} dragula-item`} id={item?.id}>
								<div className='row g-1'>
									{
										entity?.maps?.length > 1 &&
										<div className='col-auto'>
											<label className='cnow-form-label fw-bold'>Order</label>
											<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete='off' />
										</div>
									}
									<div className='col'>
										<div className='row g-1'>
											<div className='col-auto d-flex flex-grow-1'>
												<div className='w-100'>
													<label className='cnow-form-label fw-bold'>Map*</label>
													{!item.id.startsWith('temp.') ? <input value={props.type === 'map' ? item?.related_map_name : item?.map_name} disabled className='w-100 cnow-input-disabled-height' /> : <SelectMap key={item.id}
														value={{
															id: props.type === 'map' ? item.related_map_id : item.map_id,
															name: props.type === 'map' ? item.related_map_name : item.map_name
														}}
														onChange={(value) => handleItemChange(index, value)} />}
												</div>
											</div>
											<div className='col-auto'>
												<label className='cnow-form-label'></label>
												<div className='btn-list'>
													<PreviewButton link={`map/${props.type === 'map' ? item.related_map_id : item.map_id}`} />
													<LinkButton icon='edit' to={`/map/${props.type === 'map' ? item.related_map_id : item.map_id}/editor`} />
													<Button icon='delete' onClick={() => removeMap(index)} />
												</div>
											</div>
											<TextEditor key={item.id} data={item?.description} onChange={(data) => handleEditorChange(index, data)}>
												<label className='cnow-form-label mt-2 fw-bold'>{mapLabelTextEditor}</label>
											</TextEditor>
										</div>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
			{
				props.showDate && entity?.dates?.length > 0 &&
				<>
					<hr className='cnow-hr' />
					<div className='cnow-form-title'>Attach a Map, By Date</div>
					{
						entity?.dates?.map((date, dateIndex) => {
							let offset = getOffsetIndex(entity?.maps, entity?.dates, date.id)
							return (
								<div key={dateIndex} className='mb-2'>
									<div className='row'>
										<div className='col'>
											<label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
										</div>
										<div className='col text-end'>
											<Button size='sm' icon='plus' label='Add a Map' onClick={() => { addMap(date.id) }} />
										</div>
									</div>
									<div id={`${dragulaContainer}-${date.id}`} key={`${dragula}${date.id}`} className='dragula-container'>
										{
											entity?.maps?.filter(x => x.date_id === date.id).map((item, index) => {
												const mapIndex = entity.maps.findIndex(x => x.id === item.id)
												return (
													<div key={index} className={`${index > 0 ? 'mt-4' : ''} dragula-item`} id={item.id}>
														<div className='row g-1'>
															{
																entity?.maps?.length > 1 &&
																<div className='col-auto'>
																	<label className='cnow-form-label fw-bold'>Order</label>
																	<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={offset + index + 1} autoComplete='off' />
																</div>
															}
															<div className='col'>
																<div className='row g-1'>
																	<div className='col-auto d-flex flex-grow-1'>
																		<div className='w-100'>
																			<label className='cnow-form-label fw-bold'>Map*</label>
																			{!item.id.startsWith('temp.') ? <input value={props.type === 'map' ? item?.related_map_name : item?.map_name} disabled className='w-100 cnow-input-disabled-height' /> :
																				<SelectMap key={item.id} value={{
																					id: props.type === 'map' ? item.related_map_id : item.map_id,
																					name: props.type === 'map' ? item.related_map_name : item.map_name
																				}} onChange={(value) => handleItemChange(mapIndex, value)} />}
																		</div>
																	</div>
																	<div className='col-auto'>
																		<label className='cnow-form-label'></label>
																		<div className='btn-list'>
																			<PreviewButton link={`map/${props.type === 'map' ? item?.related_map_id : item?.map_id}`} />
																			<LinkButton icon='edit' to={`/map/${props.type === 'map' ? item?.related_map_id : item?.map_id}/editor`} />
																			<Button icon='delete' onClick={() => removeMap(mapIndex)} />
																		</div>
																	</div>
																	<TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(mapIndex, data)}>
																		<label className='cnow-form-label mt-2 fw-bold'>{mapLabelTextEditor}</label>
																	</TextEditor>
																</div>
																<div>
																</div>
															</div>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							)
						})
					}
				</>
			}
		</>
	)
}

export default RelatedMapEditor;