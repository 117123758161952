import React from 'react';
import Icon from '../viewers/icon';

const LinkButton = (props) => {
    const icon = props.icon || '';
    const color = props.color || 'light';
    const size = props.size || 'md';
    const target = props.target || '_self';
    const tooltipText = props.tooltipText || '';
    const tooltipRequired = props.tooltipRequired || false

    return (
        <>
            {
                props.to && <a href={props.to} target={target} rel='noopener noreferrer' className={`btn btn-${size} btn-${color}`}>
                    <Icon name={icon} size={14} /> {props.label}
                  {tooltipRequired &&   <span className={`${tooltipText ? 'tooltiptext': ''}`}>{tooltipText}</span>}
                </a>
            }
        </>
    )
}

export default LinkButton;