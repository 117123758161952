import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import { Stop1, Stop2 } from './stop';
import LinkButton from '../../components/buttons/link';

const PathDuration = (props) => {

    const dispatch = useDispatch();
    const tour = useSelector(state => state[props.id]);

    const handleChange = (e) => {
        if (e.target.name === 'duration') {
            dispatch({ type: 'entity', payload: { ...tour, [e.target.name]: e.target.value, duration_override: true } });
        } else {
            dispatch({ type: 'entity', payload: { ...tour, [e.target.name]: e.target.value, route: null } });
        }
    }

    return (
        <>
            <PageTitle title={tour?.name} />
            <hr className='cnow-hr' />
            <div name='tour_type'>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>Type of Tour</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-around' onChange={handleChange}>
                            <div><input type='radio' value='WALKING' name='type' checked={tour?.type === 'WALKING'} className='form-check-input' /> Walking</div>
                            <div><input type='radio' value='BICYCLING' name='type' checked={tour?.type === 'BICYCLING'} className='form-check-input' /> Bicycle</div>
                            <div><input type='radio' value='DRIVING' name='type' checked={tour?.type === 'DRIVING'} className='form-check-input' /> Car</div>
                            <div><input type='radio' value='VIRTUAL' name='type' checked={tour?.type === 'VIRTUAL'} className='form-check-input' /> Virtual</div>
                        </div>
                    </div>
                </div>
            </div>
         {  tour?.type  !== 'VIRTUAL' &&
         <> <hr className='cnow-hr' />
            <div name='Duration'>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>Duration</label>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col'>
                        <input value={tour?.duration} name='duration' className= 'form-control' onChange={handleChange} />
                    </div>
                </div>
            </div>
            </>}
            <hr className='cnow-hr' />
            <div name='tour_stops'>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>Tour Stops</label>
                    </div>
                    <div className='col-auto'>
                        <LinkButton icon='edit' label='Edit Path' to={`/tour/${tour?.id}/editor/tour_map_editor/path_edit`} />
                    </div>
                </div>
                <div className='row px-2'>
                    {
                        tour?.format === 2 ? (
                            <>
                                {
                                    tour?.stops?.length > 0 && tour.stops.map((stop, index) => {
                                        return (<Stop1 tourId={tour.id} stop={stop} showButtons={false} />)
                                    })
                                }
                            </>
                        ) : (
                            <>
                                {
                                    tour?.stops?.length > 0 && tour.stops.map((stop, index) => {
                                        if (stop.sites?.length > 0)
                                            return (<Stop2 tourId={tour.id} stop={stop} showButtons={false}/>)
                                        else return null
                                    })
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}
export default PathDuration