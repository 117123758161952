import { ApiCall } from './utils'

const getRemarksAttributionById = (id) => ApiCall('get', `/remarks_attribution/${id}`);

const updateRemarksAttributionById = (params) => ApiCall('post', `/remarks_attribution`, params)

const deleteRemarksAttributionById = (id) => ApiCall('delete', `/remarks_attribution/${id}`)

export default {
    getRemarksAttributionById,
    updateRemarksAttributionById,
    deleteRemarksAttributionById
}
