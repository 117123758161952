import React, { useState } from 'react';

import moment from "moment";
import Icon from '../viewers/icon';
const MIN_LENGTH = 3

const EventDate = ({ data, hr = true, title, location, description }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);

    const changeUTCYear = (values) => {
        const year = moment(new Date(values * 1000)).format('MMMM Do YYYY')
        return year;
    }
    const changeUTCTime = (values) => {
        const year = moment(new Date(values * 1000)).format('h:mm a');
        return year;
    }
    const convertDateTimeZone = (date) => {
        let data = moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
        return new Date(data)
    }
    const handleDateButtonClick = (date, title, location, description) => {
        const encodedLocation = encodeURIComponent(location);
        const encodedEventName = encodeURIComponent(title);
        const encodedDescription = encodeURIComponent(description)
        let eventDate = convertDateTimeZone(date)
        const year = eventDate.getFullYear();
        const month = (eventDate.getMonth() + 1).toString().padStart(2, '0');
        const day = (eventDate.getDate()).toString().padStart(2, '0');
        const startTime = `${eventDate.getHours().toString().padStart(2, '0')}${eventDate.getMinutes().toString().padStart(2, '0')}${eventDate.getSeconds().toString().padStart(2, '0')}`;
        const endTime = `${eventDate.getHours().toString().padStart(2, '0')}${eventDate.getMinutes().toString().padStart(2, '0')}${eventDate.getSeconds().toString().padStart(2, '0')}`;
        const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodedEventName}&location=${encodedLocation}&details=${encodedDescription}&dates=${year}${month}${day}T${startTime}/${year}${month}${day}T${endTime}`;
        window.open(calendarUrl, "_blank");
    };
    return (
        <>
            {
                data && data.length > 0 && <>
                    {<hr />}
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold fst-italic'></div>

                        {data.length > MIN_LENGTH &&
                            <div className='fst-italic'>
                                {more && <div className='small d-flex align-items-center' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(3); showMore(false) }}>
                                    {`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1 fa-2x text-dark' style={{ fontWeight: '900' }} /></div>}
                                {!more && <div className='small d-flex align-items-center' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(data.length); showMore(true) }}>
                                    {`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1 fa-2x text-dark' style={{ fontWeight: '900' }} /></div>}
                            </div>
                        }
                    </div>
                    {data?.slice(0, length)?.map((items, index) => {
                        return (
                            <div key={index} className="">
                                <div className='d-flex'>
                                    <div className="col fw-bold">{items.start_date && changeUTCYear(items.start_date)} {items?.start_date && changeUTCYear(items.start_date) !== changeUTCYear(items.end_date)&& ' - '} {items.start_date && items.end_date && changeUTCYear(items.start_date) === changeUTCYear(items.end_date) ? "" : ` ${items.end_date && changeUTCYear(items.end_date)}`}</div>
                                    <div>
                                        {items.start_date && items.end_date && <button type="button" className="play-button" onClick={() => handleDateButtonClick(items.start_date, title, location, description)} >
                                            <Icon name={"plus"} size={10} /> Add to Calendar

                                        </button>}
                                    </div>
                                </div>
                                <div className="col">
                                    {items.start_date && changeUTCTime(items?.start_date)} {items?.start_date &&  '-'} {items.end_date && changeUTCTime(items.end_date)}
                                </div>
                                <div className="col">
                                    <div><span dangerouslySetInnerHTML={{ __html: items.remarks }} /></div>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </>
    )
}

export default EventDate;