import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import uniqid from 'uniqid'
import GoogleMapReact from 'google-map-react';
import { GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import ImageViewer from '../../components/viewers/image';
import { personName, time_convert } from '../../helpers';
import Icon from '../../components/viewers/icon';
import StopPick from './stopPick';
import Button from '../../components/buttons/default';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { selectStyle } from '../../layout/nav';
import Name from '../../components/preview/name';

const _api = require('../../api');

const TourMapEditor = (props) => {

    const tourId = props.match.params.id;
    const editMode = props.match.params.editMode;
    const stopIndex = props.match.params.stopIndex;
    var stopId = null;

    const history = useHistory();

    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);
    if (stopIndex >= 0) {
        stopId = tour.stops[stopIndex].id
    }

    const [center] = useState({ lat: 40.730610, lng: -98.6273925 }); //United States
    const [mapKey, setMapKey] = useState(uniqid);
    const mapRef = useRef();

    const [siteList, setSiteList] = useState([]);
    const [boundsPolygon, setBoundsPolygon] = useState()
    const [site, setSite] = useState({});
    const [stopPick, showStopPick] = useState(false)
    const [locationPick, setLocationPick] = useState('new')
    const [pickedLocation, setPickedLocation] = useState({})
    const [minimize, setMinimize] = useState(false)
    const [addedSites, setAddedSites] = useState([]);
    const [explore, setExplore] = useState(true);
    const [hoveredId, setHoveredId] = useState(null);

    useEffect(() => {
        let sites = []
        tour.stops?.length > 0 && tour.stops.forEach(stop => {
            if (stop.sites?.length > 0) sites = sites.concat(stop.sites);
        })
        setAddedSites(sites)
    }, [tour.stops])


    const handleMapLoad = (google) => {
        mapRef.current = google.map;
        mapRef.current.setOptions({
            'zoomControlOptions': { 'position': google.maps.ControlPosition.RIGHT_BOTTOM },
            'controlSize': 24,
        })
        if (tour.stops?.length > 0 && editMode !== 'location_pick') setBounds(google);
        if (editMode !== 'path_edit') {
            google.maps.event.addListener(mapRef.current, 'idle', function () {
                setBoundsPolygon(getBoundsPolygon())
            })
            exploreMap(getBoundsPolygon())
        }
        if (editMode === 'location_pick' && stopId) {
            google.maps.event.addListener(mapRef.current, 'click', function (event) {
                if (locationPick === 'pick') {
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode({
                        'latLng': event.latLng
                    }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0] && tour.stops?.length > 0) {
                                setLocationPick('picked')
                                setPickedLocation({ address: results[0].formatted_address, location: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() } })
                            }
                        }
                    })
                }
            })
        }
        if (editMode === 'path_edit') {
            let directionsRenderer = new google.maps.DirectionsRenderer({
                map: mapRef.current,
                draggable: true,
                polylineOptions: { strokeColor: '#A82829' },
                suppressInfoWindows: false,
                suppressBicyclingLayer: true,
                suppressMarkers: true
            });
            showRoutes(new google.maps.DirectionsService(), directionsRenderer, google)
            google.maps.event.addListener(directionsRenderer, 'directions_changed', function () {
                let route = directionsRenderer.directions.routes[0]
                let waypoints = [];
                let totalTime = 0;
                route.legs?.length > 0 && route.legs.forEach((leg) => {
                    totalTime = totalTime + leg.duration.value;
                    leg.steps?.length > 0 && leg.steps.forEach(step => {
                        waypoints.push(step.polyline.points);
                    })
                })
                if (!tour.duration_override) dispatch({ type: 'entity', payload: { ...tour, route: JSON.stringify(waypoints), route_overview: route.overview_polyline, duration: time_convert(totalTime) } })
                else dispatch({ type: 'entity', payload: { ...tour, route: waypoints } })
            });
        }
    }

    const showRoutes = (directionsService, directionsRenderer, google) => {
        let stops = [];

        tour.stops?.length > 0 && tour.stops.forEach((stop, index) => {
            if (index > 0 && index < tour.stops.length - 1 && stop.location.location.lat && stop.location.location.lng)
                stops.push({ location: stop.location.location, stopover: true })
        })

        let request = {
            origin: tour.stops?.[0]?.location.location,
            destination: tour.stops[tour.stops.length - 1]?.location.location,
            waypoints: stops,
            optimizeWaypoints: true,
            travelMode: tour.type
        };

        directionsService.route(request, function (response, status) {
            if (status === 'OK') {
                directionsRenderer.setDirections(response);
            }
        })
    }

    const savePickedLocation = () => {
        let index = tour.stops.findIndex(s => s.id === stopId)
        if (index >= 0) {
            tour.stops[index].location = pickedLocation;
        }
        handleTourSave();
        setLocationPick('new')
        setPickedLocation(null)
        setMapKey(uniqid())
    }

    const setBounds = (google) => {
        var bounds = new google.maps.LatLngBounds();
        let markerCount = 0;
        tour?.stops?.length > 0 && tour.stops.forEach((stop) => {
            if (stop.location?.location?.lat && stop.location?.location?.lng)
                bounds.extend(stop.location.location)
            markerCount = markerCount + 1;
            stop.sites?.length > 0 && stop.sites.forEach((site) => {
                markerCount = markerCount + 1;
                if (site.location?.location?.lat && site.location?.location?.lng)
                    bounds.extend(site.location.location)
            })
        })
        if (tour?.stops?.length > 0) mapRef.current.fitBounds(bounds);
        if (markerCount === 1) mapRef.current.setZoom(16);
    }

    const getBoundsPolygon = () => {
        return {
            ne: {
                lat: mapRef.current.getBounds().getNorthEast().lat(),
                lng: mapRef.current.getBounds().getNorthEast().lng()
            },
            sw: {
                lat: mapRef.current.getBounds().getSouthWest().lat(),
                lng: mapRef.current.getBounds().getSouthWest().lng()
            },
            nw: {
                lat: mapRef.current.getBounds().getNorthEast().lat(),
                lng: mapRef.current.getBounds().getSouthWest().lng()
            },
            se: {
                lat: mapRef.current.getBounds().getSouthWest().lat(),
                lng: mapRef.current.getBounds().getNorthEast().lng()
            }
        }
    }

    const exploreMap = (boundsPolygon) => {
        let params = {
            "keyword": "",
            // "sort": "modified_desc",
            "page": 0,
            "filters": "live:true AND deleted:false",
            "hitsPerPage": 500,
            "insidePolygon": `${boundsPolygon?.nw?.lat}, ${boundsPolygon?.nw?.lng}, ${boundsPolygon?.ne?.lat}, ${boundsPolygon?.ne?.lng}, ${boundsPolygon?.se?.lat}, ${boundsPolygon?.se?.lng}, ${boundsPolygon?.sw?.lat}, ${boundsPolygon?.sw?.lng}`
        }
        axios.post(`site/search`, params).then((response) => {
            setSiteList(response.data?.hits)
        })
    }

    const suggestions = async (keyword) => {
        let items = [];
        let hitsData = []
        items.push({ 'value': keyword, 'label': keyword })
        let params = {
            q: keyword
        }
        let response = await axios.post(`/search/suggest`, params);
        response.data?.length > 0 && response.data.forEach((result) => { if (result?.name || result?.first_name || result?.last_name) { hitsData.push(result) } });
        hitsData.forEach(data => items.push({ 'value': personName(data), 'label': personName(data) }));
        return items;
    }

    const handleSearch = async (keyword) => {
        setExplore(false);
        setMinimize(true);
        const params = {
            keyword: keyword.label,
            hitsPerPage: 20,
            page: 0,
        }
        await axios.post(`site/search`, params).then((response) => {
            setSiteList(response.data?.hits)
        })
    }

    const Control = ({ children, ...props }) => {

        const style = { cursor: 'pointer' };

        return (
            <components.Control  {...props}>
                <span className='text-white d-flex ms-2' style={style}>
                    <span className=' ml-1' ><Icon className='mb-1' name='search-white' minWidth={16} size={16} bottom={0} right={8} cursor='pointer' /></span> Search:
                </span>
                {children}
            </components.Control >
        );
    };

    const handleMarkerClick = (site, type) => {
        if (type === 'exploredSite') {
            addSiteToStop(site)
        }
    }

    const MarkerIcon = ({ number, colour, fill }) => ((number?.toString().length) > 1 ? (
        <svg id="Component_131_14" data-name="Component 131 – 14" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
            <g id="Path_583" data-name="Path 583" fill="#fff">
                <path d="M 14 26 C 10.79467964172363 26 7.781219959259033 24.75177955627441 5.51471996307373 22.48527908325195 C 3.248219966888428 20.21878051757812 2 17.20532035827637 2 14 C 2 10.79467964172363 3.248219966888428 7.781219959259033 5.51471996307373 5.51471996307373 C 7.781219959259033 3.248219966888428 10.79467964172363 2 14 2 C 17.20532035827637 2 20.21878051757812 3.248219966888428 22.48527908325195 5.51471996307373 C 24.75177955627441 7.781219959259033 26 10.79467964172363 26 14 C 26 17.20532035827637 24.75177955627441 20.21878051757812 22.48527908325195 22.48527908325195 C 20.21878051757812 24.75177955627441 17.20532035827637 26 14 26 Z" stroke="none" fill={fill ? colour : null} />
                <path d="M 14 4 C 8.485979080200195 4 4 8.485979080200195 4 14 C 4 19.51401901245117 8.485979080200195 24 14 24 C 19.51401901245117 24 24 19.51401901245117 24 14 C 24 8.485979080200195 19.51401901245117 4 14 4 M 14 0 C 21.73197937011719 0 28 6.268009185791016 28 14 C 28 21.73197937011719 21.73197937011719 28 14 28 C 6.268009185791016 28 0 21.73197937011719 0 14 C 0 6.268009185791016 6.268009185791016 0 14 0 Z" stroke="none" fill={colour} />
            </g>
            <text y="18" x="7" fill="#000">{number}</text><path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
        </svg>) : (
        <svg id="Component_131_14" data-name="Component 131 – 14" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
            <g id="Path_583" data-name="Path 583" fill="#fff">
                <path d="M 14 26 C 10.79467964172363 26 7.781219959259033 24.75177955627441 5.51471996307373 22.48527908325195 C 3.248219966888428 20.21878051757812 2 17.20532035827637 2 14 C 2 10.79467964172363 3.248219966888428 7.781219959259033 5.51471996307373 5.51471996307373 C 7.781219959259033 3.248219966888428 10.79467964172363 2 14 2 C 17.20532035827637 2 20.21878051757812 3.248219966888428 22.48527908325195 5.51471996307373 C 24.75177955627441 7.781219959259033 26 10.79467964172363 26 14 C 26 17.20532035827637 24.75177955627441 20.21878051757812 22.48527908325195 22.48527908325195 C 20.21878051757812 24.75177955627441 17.20532035827637 26 14 26 Z" stroke="none" fill={fill ? colour : null} />
                <path d="M 14 4 C 8.485979080200195 4 4 8.485979080200195 4 14 C 4 19.51401901245117 8.485979080200195 24 14 24 C 19.51401901245117 24 24 19.51401901245117 24 14 C 24 8.485979080200195 19.51401901245117 4 14 4 M 14 0 C 21.73197937011719 0 28 6.268009185791016 28 14 C 28 21.73197937011719 21.73197937011719 28 14 28 C 6.268009185791016 28 0 21.73197937011719 0 14 C 0 6.268009185791016 6.268009185791016 0 14 0 Z" stroke="none" fill={colour} />
            </g>
            <text y="18" x="10" fill="#000">{number}</text>
            <path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
        </svg>))

    const Marker = ({ stop, site, type, number, name, id }) => {
        let colour = hoveredId === id ? '#a82829' : '#86E8FF'
        if (type === 'stop' || type === 'site') {
            colour = '#a82829';
        }

        return (
            <>
                <div className='cnow-marker' onClick={() => handleMarkerClick(site, type)} onMouseEnter={() => setHoveredId(site?.id)} onMouseLeave={() => setHoveredId(null)}>
                    <MarkerIcon colour={colour} number={number} fill={type === 'stop'} />
                </div>
                {hoveredId === id && <div className='listMarkerInfo'>{name}</div>}
            </>
        )
    }

    const addSiteToStop = (site) => {
        setSite(site);
        showStopPick(true);
    }


    const handleStopPickSave = (stop_id) => {
        const index = tour.stops.findIndex(s => s.id === stop_id)
        if (!tour.stops[index].sites) tour.stops[index].sites = [];
        let siteItem = {
            'id': `temp.${uniqid()}`,
            'tour_id': tour.id,
            'stop_id': stop_id,
            'site_id': site.id,
            'image_id': site.image_id,
            'site_name': site.name,
            'highlight': false,
            'description': '',
            'location': site.locations?.length > 0 ? site.locations[0] : {},
            'date_id': site.dates?.length > 0 ? site.dates[0].id : null,
            'year': site.dates?.length > 0 ? site.dates[0].year : null,
            'sort': tour.stops[index].sites.length + 1
        }
        tour.stops[index].sites.push(siteItem)
        if (tour.format === 1) {
            tour.stops[index].site_id = site.id
            tour.stops[index].stop_name = site.name
            tour.stops[index].image_id = site.image_id
        }
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
        showStopPick(false);
        handleTourSave();
    }

    const handleTourSave = async () => {
        let response = {};
        if (editMode !== 'path_edit') {
            response = await _api.tour.updateTourStops(tour.id, tour.stops);
            toast.success('Tour Saved Successfully')
            dispatch({ type: 'entity', payload: { ...tour, stops: response } })
        } else {
            response = await _api.tour.update(tour);
            response.stops = await _api.tour.updateTourStops(tour.id, tour.stops);
            toast.success('Tour Saved Successfully')
            dispatch({ type: 'entity', payload: response })
        }
    }

    const handleRedirect = () => {
        if (editMode === 'path_edit')
            history.push(`/tour/${tour.id}/editor/path_duration`)
        else history.push(`/tour/${tour.id}/editor/tour_stops`)
    }

    useMemo(() => {
        if (explore && editMode !== 'path_edit') exploreMap(boundsPolygon)
    }, [boundsPolygon, explore, editMode])


    return (
        <>
            <div className='tourmap-header'>
                <div className='row'>
                    <div className='col-3'>
                        <Button size='md' label='Back' icon='arrow-left' color='danger' onClick={() => handleRedirect()} className={'tour-map-editor-border'} />
                    </div>
                    <div className='col-6'>
                        {
                            editMode !== 'path_edit' && <AsyncSelect
                                instanceId='tourSiteSearch'
                                width='100%'
                                value={''}
                                classNamePrefix='cnow-select'
                                styles={selectStyle}
                                loadOptions={suggestions}
                                components={{ Control }}
                                placeholder="City, Place, Art, Architecture, Park, Collection..."
                                onChange={handleSearch}
                            />
                        }
                    </div>
                    <div className='col-3 d-flex justify-content-center'>
                        {editMode === 'path_edit' ? <Button size='md' label='Save Tour' color='danger' onClick={() => handleTourSave()} className={'tour-map-editor-border'} /> :
                            <Button size='md' label='Explore This Area' color={`${explore ? '' : 'danger'}`} onClick={() => { setSiteList([]); setExplore(!explore) }} className={'tour-map-editor-border'} />}
                    </div>

                </div>
            </div>
            <div className='d-flex prevent-select'>
                <div className='tourmap-listView'>
                    <div className='row'>
                        <div className='col ms-1'><Name data={tour.name} /></div>
                    </div>
                    {
                        tour.stops?.length > 0 && <div className='p-1'>
                            <div className='row'>
                                <div className='col'><label className='cnow-form-label fw-bold'>Tour stops and sites</label></div>
                                <div className='col-auto'><small className='cursor-pointer g-1' onClick={() => setMinimize(!minimize)}><Icon name={`${!minimize ? 'arrow-top' : 'arrow-bottom'}`} />Minimize</small></div>
                            </div>
                            <hr className='cnow-hr' />
                            {
                                !minimize && tour.stops?.length > 0 && tour.stops.map((stop, index) => {
                                    return (
                                        <>
                                            {
                                                tour.format === 2 && <div className='row p-2'>
                                                    <div className='col-auto'>
                                                        <div className='tour-stop-number-red d-flex'>{stop?.sort}</div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <ImageViewer entityType={'image'} entityId={stop.image_id} type='thumb' cssClass='thumb' />
                                                    </div>
                                                    <div className='col d-flex justify-content-between '>
                                                        <div id='adress'>
                                                            <strong id='personname'>{stop.stop_name}</strong>
                                                            <p id='details'><small ><b>{stop.location.address}</b></small></p>
                                                        </div>
                                                    </div>
                                                    {
                                                        (stop.id === stopId) && <div className='col-auto'>
                                                            <div className='row'>
                                                                {locationPick === 'picked' && <Button size='sm' label='save' color='danger' onClick={() => savePickedLocation()} />}
                                                                {locationPick === 'new' && <Button size='sm' label='Pick Location' onClick={() => { setLocationPick('pick'); setMapKey(uniqid()) }} />}
                                                                {locationPick === 'pick' && <Button size='sm' label='select from Map' disabled />}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div>
                                                {
                                                    stop.sites?.length > 0 && stop.sites.map(site => {
                                                        let siteNo = index + 1;
                                                        if (tour.format === 2) {
                                                            siteNo = `${stop?.sort}.${site.sort}`;
                                                        }
                                                        return (
                                                            <>
                                                                <div className='row p-2'>
                                                                    <div className='col-auto'>
                                                                        <div className='tour-stop-highlight-number d-flex'>{siteNo}</div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <ImageViewer entityType={'site'} entityId={site.site_id} type='thumb' cssClass='thumb' />
                                                                    </div>
                                                                    <div className='col d-flex justify-content-between '>
                                                                        <div id='adress'>
                                                                            <strong id='personname'>{site.site_name}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)
                                                    })
                                                }
                                            </div>
                                        </>)
                                })
                            }
                        </div>
                    }
                    {
                        editMode !== 'path_edit' && siteList?.length > 0 && <div className='p-1' onMouseLeave={() => setHoveredId(null)}>
                            <label className='cnow-form-label fw-bold'>{explore ? 'Explore' : 'Search Results'}</label>
                            <hr className='cnow-hr' />
                            {
                                siteList?.length > 0 && siteList.map(listitem => {
                                    let index = addedSites.map(s => s.site_id).indexOf(listitem.id)
                                    return (
                                        <>
                                            <div className='row' onMouseEnter={() => setHoveredId(listitem.id)}>
                                                <div className='col-auto'>
                                                    <ImageViewer url={listitem?.image?.url} type='thumb' cssClass='thumb' />
                                                </div>
                                                <div className='col d-flex justify-content-between '>
                                                    <div id='adress'>
                                                        <strong id='personname'>{personName(listitem)}</strong>
                                                        {listitem?.locations?.[0]?.address?.length > 0 ? <p id='details'><small ><b>{listitem.locations?.[0] && listitem.locations[0]?.address}</b></small><br></br><i id='category'>{listitem.categories?.[0] && listitem.categories[0]?.category_name}</i></p> : <p><i id='category'>{listitem.categories?.[0] && listitem.categories[0]?.category_name}</i></p>}
                                                    </div>
                                                </div>
                                                {
                                                    index < 0 ? (
                                                        <div className='col-auto'>
                                                            <span className='cursor-pointer' onClick={() => addSiteToStop(listitem)}><Icon name='plus' size={16} /></span>
                                                        </div>) : (
                                                        <div className='col-auto'>
                                                            <span><Icon name='added-tick-red' size={16} /></span>
                                                        </div>)
                                                }
                                            </div>
                                        </>)
                                })
                            }
                        </div>
                    }
                </div>
                <div className='tourmap-mapview'>
                    <GoogleMapReact
                        key={mapKey}
                        center={center}
                        defaultZoom={3}
                        options={{ mapId: "1f81af8398de79dd" }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={handleMapLoad}
                    >
                        {
                            tour?.stops?.length > 0 && tour.stops.map((stop) => {
                                if (stop.location?.location?.lat && stop.location?.location?.lng)
                                    return (
                                        <Marker
                                            key={stop.id}
                                            lat={stop.location.location.lat}
                                            lng={stop.location.location.lng}
                                            type={'stop'}
                                            number={stop.sort}
                                            name={stop.stop_name}
                                        />)
                                else return null;
                            }
                            )
                        }
                        {
                            tour?.stops?.length > 0 && tour.stops.map(stop => {
                                return (
                                    stop.sites?.length > 0 && stop.sites.map((site) => {
                                        if (site.location?.location?.lat && site.location?.location?.lng)
                                            return (
                                                <Marker
                                                    key={site.id}
                                                    lat={site.location.location.lat}
                                                    lng={site.location.location.lng}
                                                    type={'site'}
                                                    number={`${stop.sort}.${site.sort}`}
                                                    name={site.site_name}
                                                    site={site}
                                                    id={site.id}
                                                />)
                                        else return null;
                                    }
                                    )
                                )
                            })
                        }
                        {
                            siteList?.length > 0 && siteList.map(listItem => {
                                let index = addedSites.map(s => s.site_id).indexOf(listItem.id)
                                if (listItem?._geoloc && listItem._geoloc?.[0]?.lat && index < 0)
                                    return (
                                        <Marker
                                            key={listItem.id}
                                            id={listItem.id}
                                            lat={listItem?._geoloc[0] && listItem?._geoloc[0].lat}
                                            lng={listItem?._geoloc[0] && listItem?._geoloc[0].lng}
                                            type={'exploredSite'}
                                            name={listItem.name}
                                            site={listItem}
                                        />)
                                else return null;
                            })
                        }
                        {pickedLocation?.location?.lat && <Marker lat={pickedLocation.location.lat} lng={pickedLocation.location.lng} type={'stop'} />}
                    </GoogleMapReact>
                </div>
            </div>
            <StopPick show={stopPick} onHide={() => showStopPick(false)} tourId={tour.id} title={site.name} onSave={(stopId) => handleStopPickSave(stopId)} />
        </>
    )
}
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(TourMapEditor)