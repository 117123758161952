import React from "react";

const LabelInput = ({ label, type = 'text', name, handleChange, value, children, readonly, handleOnClick, disabled, className, placeholder }) => {
    return (

        <div className="row py-1">
            <div className="col-sm-3 small text-muted">{label}</div>
            <div className="col-sm-9" onClick={handleOnClick}>
                <input type={type} name={name} value={value} onChange={handleChange} className={`w-100 bg-light w-100 border-0 py-1 ${className}`} readonly={readonly} disabled={disabled} placeholder={placeholder || ''}/>
                <>{children}</>
            </div>
        </div>
    )
}

export default LabelInput