import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { handleReorder } from '../../helpers';
import Button from "../../components/buttons/default";
import PreviewButton from "../../components/buttons/preview";
import LinkButton from "../../components/buttons/link";
import ImageViewer from "../../components/viewers/image";
import Dragula from "react-dragula";
import uniqid from 'uniqid';

const Tour = (props) => {
    const entity = useSelector(state => state[props.id]);
    const history = useHistory()
    const dispatch = useDispatch()
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-tour'


    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.related_tours, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, related_tours: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End
    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Tour</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a tour' onClick={() => { history.push('/tour') }} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    entity?.related_tours?.length > 0 && entity.related_tours.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                <div className='col-auto' >
                                    {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                    <input type='text' id={`${item.id}, ${item.tour_id}`} className='nwprime form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                                </div>

                                <div className='col-auto'>
                                    {index === 0 && <div className=''>&nbsp;</div>}
                                    <ImageViewer key={item?.id} entityType='tour' entityId={item.tour_id} type='thumb' cssClass='thumb-small' />
                                </div>

                                <div className='col-auto d-flex flex-grow-1'>
                                    <div className='w-100 d-flex flex-column align-items-between'>
                                        {index === 0 && <label className='cnow-form-label fw-bold mb-1'>Tour Title</label>}
                                        <input name='name' type='text' id={`${item.id}, ${item.tour_id}`} disabled className='ps-2 fw-bold h-100 border border-dark' value={item?.tour_name} autoComplete="off" />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <PreviewButton link={`/tour/${item.tour_id}`} />
                                        <LinkButton icon='edit' to={`/tour/${item.tour_id}/editor`} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Tour