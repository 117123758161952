import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

import Spinner from '../spinner';
import ImageUpload from '../../pages/image/upload';
import Button from '../buttons/default';
import ImageViewer from '../viewers/image';

const _api = require('../../api')

const PersonHeadshot = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const person = useSelector(state => state[props.id]);
    const [imageUploader, showImageUploader] = useState(false)
    const [bannerCreditsArray, setBannerCreditsArray] = useState([])
    const [spinner, showSpinner] = useState(false)

    const setBanner = async (items) => {
        if (items.length > 0) {
            items.forEach((item) => {
                person.image_id = item.id
                person.url = item.url
            })
        }
        await _api.person.update(person)
        //get back updated info
        let personData = await _api.person.getById(person.id)
        dispatch({ type: 'entity', payload: { ...person, ...personData } })
        showImageUploader(false)
    }

    const removeBanner = async () => {
        swal({
            title: `Delete Image`,
            text: `Are you sure that you want to delete this image?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async () => {
            showSpinner(true)
            person.image = {}
            person.image_id = null
            person.url = null

            let personResponse = await _api.person.update(person)
            dispatch({ type: 'entity', payload: personResponse });
            showSpinner(false)
        })
    }

    const editImage = (id) => {
        if (id) {
            history.push(`/image/${id}/editor`)
            dispatch({ type: 'images', payload: { entityImages: {}, source: location.pathname, type: person?.type } })
        }
    }

    useEffect(() => {
        getBannerCaption()
        // eslint-disable-next-line 
    }, [person?.image_id])

    const getBannerCaption = async () => {
        if (person?.image && person?.image?.id) {
            let bannerCredits = await _api.image.getCredit(person?.image?.id)
            for (let i in bannerCredits) {
                if (bannerCredits[i].person_name) bannerCreditsArray.push(bannerCredits[i].person_name)
                if (bannerCredits[i].person_first_name || bannerCredits[i].person_last_name) bannerCreditsArray.push(bannerCredits?.[i]?.person_first_name + ' ' + bannerCredits?.[i]?.person_last_name)
            }
            setBannerCreditsArray(bannerCreditsArray.join(', '))
        }
    }

    return (
        <Spinner display={spinner}>
            <div id='header'>
                <hr className='cnow-hr' />
                <div className='row'>
                    <div className='col'>
                        <div className='form-group'>
                            {
                                person?.type === 'person' &&
                                <div className='cnow-form-title'>Headshot *</div>
                            }
                            {
                                person?.type === 'organization' &&
                                <div className='cnow-form-title'>Logo *</div>
                            }
                            {
                                person?.type === 'publication' &&
                                <div className='cnow-form-title'>Publication Logo/Cover *</div>
                            }
                            <div className='d-flex gap-2'>
                                <div onClick={() => editImage(person?.image_id)} className='cursor-pointer'>
                                    <ImageViewer entityId={person?.image_id} entityType='image' type={props.imageType || 'headshot'}
                                        cssClass={props.imageType ? 'thumb-1x' : 'cnow-image-editor-headshot'} />
                                </div>
                                {person?.image?.caption && bannerCreditsArray?.length > 0 &&
                                    <div className='w-25'>
                                        <div className='bg-light fw-bold py-0'>{person?.image?.caption}</div>
                                        <div className='ps-1 fw small'> {bannerCreditsArray.length > 0 ? `© ${bannerCreditsArray}` : ''}</div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className='col-auto'>
                        {
                            person?.image?.url &&
                            <div className='d-flex gap-1 justify-content-end'>
                                <Button size='sm' icon='edit' onClick={() => { editImage(person?.image?.id) }} />
                                <Button size='sm' icon='delete' onClick={() => removeBanner()} />
                            </div>
                        }
                        {
                            !person?.image?.url && <>
                                <Button size='sm' icon='plus' label='Add an Image' onClick={() => showImageUploader(true)} disabled={props.id === 'NEW'} />
                                {
                                    imageUploader && <ImageUpload globalSearch={true} imageType='image' entityType='people'
                                        show={imageUploader}
                                        onHide={() => showImageUploader(false)}
                                        onSave={(items) => setBanner(items)}
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </Spinner>
    )
}

export default PersonHeadshot