import React from "react";
import { useSelector } from 'react-redux';
import PageTitle from "../../components/pageTitle";
import SpecialFeature from "../../components/specialFeature";
const EventSeriesSpecialFeature = (props) => {
    const event_series = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={event_series.name} />
            <SpecialFeature id={props.id} type={'event_series'} />
        </>
    )
}

export default EventSeriesSpecialFeature