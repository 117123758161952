import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import TextEditor from '../editors/textEditor';
import Button from '../buttons/default';
import { handleReorder, newTempId } from '../../helpers';

const AlternateContact = (props) => {

    const dispatch = useDispatch();
    let person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-contact'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.alternate_contact, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, alternate_contact: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End

    //#region Alternate Contact Section
    const addAlternateContact = () => {
        let contacts = person.alternate_contact || []
        contacts.push({
            'id': newTempId(),
            'name': '',
            'role': '',
            'phone': '',
            'email': '',
            'description': '',
            'sort': contacts.length + 1
        });
        dispatch({ type: 'entity', payload: { ...person, alternate_contact: contacts } });
    }

    const removeAlternateContact = (id) => {
        person.alternate_contact = person.alternate_contact.filter(function (item) { return item.id !== id; });
        dispatch({ type: 'entity', payload: { ...person, alternate_contact: person.alternate_contact } });
    }

    const handleAlternateContactChange = (e, index) => {
        let alternate_contact = person.alternate_contact
        alternate_contact[index][e.target.name] = e.target.value
        dispatch({ type: 'entity', payload: { ...person, alternate_contact: alternate_contact } });
    }

    //#endregion


    const handleEditorChange = (index, data) => {
        let alternate_contact = person.alternate_contact
        alternate_contact[index].description = data;
        dispatch({ type: 'entity', payload: { ...person, alternate_contact: alternate_contact } });
    }
    return (
        <div id='alternate_contact'>
            <hr className='cnow-hr' />

            <div className='row g-1'>
                <div className='col'>
                    <div className='form-group'>
                        <div className='cnow-form-title'>{!props.isParticipant && 'Alternate'} Contact</div>
                    </div>
                </div>
                {!props.isParticipant && <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Person' onClick={() => addAlternateContact()} />
                </div>}
            </div>
            <div id={dragulaContainer} key={dragula} >
                {
                    person?.alternate_contact?.length > 0 && person?.alternate_contact?.map((contact, index) => {
                        return (
                            <div key={index} id={contact.id} className='mb-3 dragula-item'>
                                <div className='row g-1'>
                                    {
                                        person?.alternate_contact?.length > 1 &&
                                        <div className='col-auto'>
                                            <label className='cnow-form-label fw-bold'>{index === 0 ? 'Order' : ''}</label>
                                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={contact.sort} onChange={(e) => handleAlternateContactChange(e, index)} data-id={contact.id} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col'>
                                        <div className='row g-1'>
                                            <div className='col'>
                                                <label className='cnow-form-label fw-bold'>Name</label>
                                                <input name='name' type='text' className='form-control' value={contact.name || ''} onChange={(e) => handleAlternateContactChange(e, index)} data-id={contact.id} autoComplete="off" />
                                            </div>
                                            <div className='col'>
                                                <label className='cnow-form-label fw-bold'>Role</label>
                                                <input name='role' type='text' className='form-control' value={contact.role || ''} onChange={(e) => handleAlternateContactChange(e, index)} data-id={contact.id} autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className='row g-1'>
                                            <div className='col'>
                                                <label className='cnow-form-label fw-bold'>Phone</label>
                                                <input name='phone' type='text' className='form-control' value={contact.phone || ''} onChange={(e) => handleAlternateContactChange(e, index)} data-id={contact.id} autoComplete="off" />
                                            </div>
                                            <div className='col'>
                                                <label className='cnow-form-label fw-bold'>Email</label>
                                                <input name='email' type='text' className='form-control' value={contact.email || ''} onChange={(e) => handleAlternateContactChange(e, index)} data-id={contact.id} autoComplete="off" />
                                            </div>
                                        </div>

                                        <TextEditor limit={50} key={contact.id} data={contact.description} toolbar={true} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                        </TextEditor>
                                    </div>
                                    <div className='col-auto'>
                                        <label className='cnow-form-label'></label>
                                        <div className='text-end'>
                                         {!props.isParticipant && <Button icon='delete' onClick={() => removeAlternateContact(contact.id)} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AlternateContact