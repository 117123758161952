import React, { useState, useEffect } from "react";
import { personName } from "../../helpers";
const MIN_LENGTH = 3;

const PeopleList = ({ data, title }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    let items = [];

    useEffect(() => {
        for (let i in data) {
            const item = data[i];
            let role = item?.role_name ? item.role_name : '';
            let index = items.findIndex(x => x.role === role);
            if (index >= 0) {
                items[index].people.push({ 'id': item.person_id, 'name': [personName(item)], is_participant: item.is_participant })
            } else {
                items.push({
                    'role': role,
                    'people': [{ 'id': item.person_id, 'name': [personName(item)], is_participant: item.is_participant }]
                })
            }
        }
        let count = 0
        // eslint-disable-next-line
        items = items.map((element) => {
            return ({
                role: element.role, people: element?.people?.map((people) => {
                    count = ++count
                    if (count <= length) return people
                    else return null
                }).filter(item => item)
            })
        }).filter(item => item.people.length)

        setPeopleList(items)
    }, [length, data, data?.[data?.length - 1]?.person_name, data?.length, data?.[data?.length - 1]?.role_name])


    return (
        <>
            {peopleList.length > 0 && <>
                <hr />
                <div>
                    {title === 'playlist' && <div className='fw-bold fst-italic'>Curator</div>}
                    <div className='col'>
                        {peopleList.slice(0, length).map((item, index) => {
                            return (
                                <div key={index} className='mb-1'>
                                    <div className="d-flex justify-content-between">
                                        <div className='fw-bold fst-italic'>{item.role}</div>
                                        {data?.length > MIN_LENGTH && index === 0 &&
                                            <div className='d-flex justify-content-end'>
                                                {more && <div className='small d-flex align-items-center fst-italic' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(3); showMore(false) }}>{`${data?.length} total, Show less`} <i className='fe fe-chevron-up ms-1  fa-2x' style={{ fontWeight: '900' }} /></div>}

                                                {!more && <div className='small d-flex align-items-center fst-italic' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(data?.length); showMore(true) }}>{`${data?.length} total, Show more`}<i className='fe fe-chevron-down ms-1  fa-2x' style={{ fontWeight: '900' }} /></div>}
                                            </div>
                                        }
                                    </div>
                                    {item.people?.map((person, index) =>
                                        <div key={index}>
                                            <a href={`/${!person.is_participant ? 'people' : 'participant'}/${person?.id}`} className='text-decoration-none text-dark'>{person.name}</a>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
            }
        </>
    );
};

export default PeopleList;
