import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import uniqid from 'uniqid'

import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import { toast } from 'react-toastify';

const StopPick = ({ show, onHide, title, tourId, onSave }) => {

    const dispatch = useDispatch();
    const tour = useSelector(state => state[tourId]);

    const [stopId, setStopId] = useState(null);

    const selectStop = () => {
        if (stopId) {
            onSave(stopId);
        } else toast.error('Please select a stop to add the site')
    }

    const addNewStop = () => {
        let stop = {
            'id': `temp.${uniqid()}`,
            'tour_id': tour.id,
            'stop_name': `New Stop ${tour.stops.length + 1}`,
            'description': '',
            'image_id': null,
            'site_id': null,
            'site_name': '',
            'location': {},
            'additional_info': '',
            'sort': tour.stops.length + 1,
            'sites': []
        }
        if (!tour.stops) tour.stops = [];
        tour.stops.push(stop);
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops, duration_override: false } });
        onSave(stop.id);
    }

    return (
        <>
            <Modal show={show} onHide={() => onHide()} size='xl' centered>
                <Modal.Body>
                    {
                        tour.format === 2 && <>
                            <div>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col p-1'><label className='cnow-form-title mb-0'>Select Stop to Add - {title}</label></div>
                                        </div>
                                    </div>
                                    <div className='col-auto btn-list'>
                                        <Button size='sm' icon='close' onClick={() => onHide()} />
                                    </div>
                                </div>
                                <hr className='cnow-hr' />
                                <div className='row stop-pick-list p-2'>
                                    {
                                        tour.stops?.length > 0 && tour.stops.map(stop => {
                                            return (
                                                <>
                                                    <div className='row p-2'>
                                                        <div className='col-auto'>
                                                            <input type='radio' value={stop.id} name='type' className='form-check-input' onChange={() => setStopId(stop.id)} />
                                                        </div>
                                                        <div className='tour-stop-number d-flex col-auto'>{`${stop?.sort}`}</div>
                                                        <div className='col-auto'>
                                                            <ImageViewer entityType='image' entityId={stop.image_id} type='thumb' cssClass='thumb-small' />
                                                        </div>
                                                        <div className='col'><input value={stop.stop_name} disabled className='w-100 cnow-input-disabled-height' /></div>
                                                    </div>
                                                </>)
                                        })
                                    }
                                </div>
                                <div className='row p-2'>
                                    <div className='col-auto btn-list'>
                                        <Button size='md' label='Save' onClick={() => selectStop()} />
                                        <Button size='md' label='New Stop' onClick={() => addNewStop()} />
                                        <Button size='md' label='Cancel' onClick={() => onHide()} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        tour.format === 1 && <>
                            <div>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col p-2'><label className='cnow-form-title mb-0'>Add Site as Stop - {title}</label></div>
                                        </div>
                                    </div>
                                    <div className='col-auto btn-list'>
                                        <Button size='sm' icon='close' onClick={() => onHide()} />
                                    </div>
                                </div>
                                <hr className='cnow-hr' />
                                <div className='row p-2'>
                                    <div className='col-auto btn-list'>
                                        <Button size='md' label='Save' onClick={() => addNewStop()} />
                                        <Button size='md' label='Cancel' onClick={() => onHide()} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </Modal.Body>
            </Modal>
        </>)
}
export default StopPick