import React from 'react';
import { personName } from '../../helpers';

const ImageCreditList = ({ data, prefix }) => {
    let credits = [];
    for (let i in data) credits.push(personName(data[i]));
    return (
        <div>
            <span className='me-1'>{prefix || '©'}</span>{credits.join(', ')}
        </div>
    )
}

export default ImageCreditList;