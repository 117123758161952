/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import uniqid from 'uniqid';
import Dragula from 'react-dragula';
import Button from '../../components/buttons/default';
import { useDispatch, useSelector } from 'react-redux';
import SelectGlobalList from '../../components/selects/globalList';
import { toast } from 'react-toastify';
import { handleReorder } from '../../helpers';
const _api = require('../../api')

const PersonPublicationType = (props) => {
    const dispatch = useDispatch();
    const person = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (person.dates && person.dates.length > 0) person.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(person.publication_types, person.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...person, publication_types : items } })
        })
    }, [dragula])
    // Dragula End

    const addPublicationType = () => {
        if (!person?.first_name && !person?.name) toast.warning('please enter First Name')
        else {
            let publication_types = person.publication_types ? person.publication_types : []
            publication_types.push({
                'id': `temp.${uniqid()}`,
                'person_id': person.id,
                'publication_type_id': null,
                'publication_type_name': '',
                'sort': publication_types ? publication_types.length + 1 : 1
            });
            dispatch({ type: 'entity', payload: { ...person, publication_types: publication_types } });
        }
    };

    const removePublicationType = async (index) => {
        if (!person.publication_types[index].id.startsWith('temp.')) await _api.person.deletePublicationType(person.publication_types[index].id)
        person.publication_types.splice(index, 1)
        dispatch({ type: 'entity', payload: { ...person, publication_types: person.publication_types } });
    };

    const handlePublicationTypeChange = (index, data) => {
        let publication_types = person.publication_types
        publication_types[index].publication_type_id = data.id
        publication_types[index].publication_type_name = data.name
        dispatch({ type: 'entity', payload: { ...person, publication_types: publication_types } });
    }

    return (
        <>
            <div className='form-group' >
                <hr className='cnow-hr' />

                <div className='row'>
                    <div className='col'>
                        <span className='cnow-form-title'>{props.title}</span>
                    </div>
                    <div className='col-auto'>
                        <Button size='sm' icon='plus' label='Add a Type' onClick={() => addPublicationType()} />
                    </div>
                </div>


                <div id={dragulaContainer} key={dragula}>
                    {
                        person?.publication_types?.map((item, index) => {
                            const selectedPublicationType = { id: item?.publication_type_id, name: item?.publication_type_name }
                            return (
                                <div key={index} id={item.id} className='mt-2 dragula-item'>
                                    <div className='row g-1'>
                                        <div className={person?.publication_types.length > 1 ? `col-auto` : `d-none`}>
                                            {index === 0 && <div className='cnow-form-label fw-bold'>Order</div>}
                                            <input key={item.id} type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                        </div>
                                        <div className='col-auto d-flex flex-grow-1'>
                                            <div className='w-100'>
                                                {index === 0 && <div className='cnow-form-label'>&nbsp;</div>}
                                                <SelectGlobalList key={item.id} type={props.type} value={selectedPublicationType} onChange={(data) => handlePublicationTypeChange(index, data)} />
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            {index === 0 && <div key={item.id} className='cnow-form-label'>&nbsp;</div>}
                                            <Button icon='delete' onClick={() => removePublicationType(index)} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default PersonPublicationType