import React from 'react'
import PageTitle from '../../../components/pageTitle'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/buttons/default'
// import uniqid from 'uniqid'
import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Icon from '../../../components/viewers/icon'
import { Modal } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import { toast } from 'react-toastify'
// import { useEffect } from 'react'
const _api = require('../../../api');

const UserProfile = (props) => {

  const initialState = {
    name: "",
    email: ""
  }

  const user = useSelector(state => state[props?.id]);
  const [values, setValues] = useState(initialState)

  // const [key, setKey] = useState(uniqid())
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const history = useHistory();
  // const onTypeChange = ({ target: { value, checked } }) => {

  // };

  const addImageType = () => {

  }

  const handleInputChange = ({ target: { name, value } }) => {
    user[name] = value;
    dispatch({ type: 'entity', payload: { ...user, [name]: value } });
    // setKey(uniqid())
  }

  const forgetPassword = async (email) => {
    try {
      const response = await _api.user.resetPassword({ email: email });
      if (response.status === 200) return response.data;
    } catch (error) {
      if (error.response?.status === 500) throw new Error('User does not exist');
      else throw error
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email } = values;
    const data = {
      name: name,
      email: email,
      id: `temp.${uniqueId()}`,
      parent_id: user?.id
    }

    try {
      await _api.user.emailInvite(data).then(async () => {
        let myUser = await _api.user.getUserById(user.id)
        dispatch({ type: 'entity', payload: myUser })
      })
      handleClose()
      setValues(initialState)
      toast.success("User Invited")
    } catch (error) {
      console.log(error);
    }
  }

  const deleteMember = async (memberId) => {
    try {
      await _api.user.emailInviteDelete(memberId).then(async () => {
        let myUser = await _api.user.getUserById(user.id)
        dispatch({ type: 'entity', payload: myUser })
      })
      toast.error('user Deleted')
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <PageTitle title={`Profile`} />
      <hr className="cnow-hr" />

      <div className='cnow-form-title text-uppercase'>Membership</div>

      {/* <UserTitle id={props.id} /> */}

      <div className="row mt-4">
        <div className="col-lg-8">
          <div className='form-group'>
            <div className='cnow-form-title text-dark'>Title</div>
            <input name='title' type='text' className='form-control' autoComplete="off" value={user?.title} onChange={handleInputChange} />
          </div>
        </div>
        <div className="col-lg-4">
          <div className='form-group'>
            <div className='cnow-form-title text-dark'>Member Id <span className='text-cn-dark'>*</span> </div>
            <input name='membership_id' type='text' className='form-control' autoComplete="off" value={user?.membership_id} disabled={true} />
          </div>
        </div>
      </div>

      <div className="row mt-4 mb-4">
        <div className="col-lg-8">
          <div className='form-group'>
            <div className='cnow-form-title text-dark'>Email (User ID)*</div>
            <input name='name' type='text' className='form-control' autoComplete="off" value={user?.email} disabled={true} />
          </div>
        </div>
        <div className="col-lg-4 mt-4">
          <button className={`btn btn-sm btn-dark`} onClick={() => forgetPassword(user?.email)}>
            {<Icon name="plus" size="sm" right={4} />}  Reset Password
          </button>

          {/* <Button size='sm' icon='plus' label='Reset Password'  /> */}
        </div>
      </div>

      <div className='form-group mt-2 d-flex'>
        <div className='cnow-form-title text-dark me-5'>Type *</div>
        <div className='row'>
          <div className='col-auto'>
            <div className='form-check form-check-inline'>
              <input type='radio' className='form-check-input' name='type' value='' checked="true" />
              <label className='form-check-label small'>{user?.type}</label>
            </div>
          </div>
        </div>
      </div>

      <hr className="cnow-hr" />
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className='form-group'>
            <div className='cnow-form-title text-dark'>First Name*</div>
            <input name='first_name' type='text' className='form-control' autoComplete="off" value={user?.first_name} onChange={handleInputChange} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className='form-group'>
            <div className='cnow-form-title text-dark'>Last Name*</div>
            <input name='last_name' type='text' className='form-control' autoComplete="off" value={user?.last_name} onChange={handleInputChange} />
          </div>
        </div>
      </div>

      <hr className="cnow-hr" />

      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-8">
              <div className='cnow-form-title text-uppercase mb-3'>Contact</div>
              <div className='form-group'>
                <div className='cnow-form-title text-dark'>Phone</div>
                <input name='phone' type='text' className='form-control' autoComplete="off" value={user?.phone} onChange={handleInputChange} />
              </div>
              <div className='form-group mt-2'>
                <div className='cnow-form-title text-dark'>Address</div>
                <input name='address' type='text' className='form-control' autoComplete="off" value={user?.address} onChange={handleInputChange} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className='cnow-form-title text-uppercase mb-3'>Headshot</div>
              <img src={user?.image_url} className='img-fluid user-headshot-img' alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end flex-column align-items-end">
            <Button size='sm' icon='plus' label='Add an Image' onClick={() => addImageType(null)} />
            <div className='mt-2 d-flex justify-content-end'>
              <div className="me-2"><Button size='sm' icon='edit' onClick={() => history.push(`/image/51c0b8ae-b56a-4825-aa12-cc39740f5b28/editor`)}
              /></div>
              <div className=""><Button size='sm' icon='delete' /></div>
            </div>
          </div>
        </div>
      </div>

      <hr className="cnow-hr" />
      <div className='form-group'>
        <div className='cnow-form-title text-uppercase'>People Page Link</div>
        <input name='website' type='text' className='form-control' autoComplete="off" value={user?.website} onChange={handleInputChange} />
      </div>


      {/* <hr className="cnow-hr" />
      <div className='cnow-form-title text-uppercase'>Affiliations</div>
      <div className="row mt-2">
        <div className="col-lg-6">
        <div className='form-group'>
        <div className='cnow-form-title text-dark'>Profession/Job Title</div>
                <input name='name' type='text' className='form-control'  autoComplete="off" />
            </div>
        </div>
        <div className="col-lg-6">
        <div className='form-group'>
        <div className='cnow-form-title text-dark'>Organization</div>
                <input name='organization' type='text' className='form-control' value={user?.organization} onChange={handleInputChange}  autoComplete="off" />
            </div>
        </div>
      </div> */}


      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="card w-100">
              <div className="card-header fw-bold d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src="https://staging.culturenow.org/icon/Symbols_Login.svg"
                    alt="series"
                    height="24px"
                    className="text-start me-2"
                  />
                  Send Account Member Invite
                </div>
                <div className="text-end border-0 m-0 text-decoration-none text-end cursor-pointer">
                  X
                </div>
              </div>
              <div className="card-body">
                <form>
                  <div className="fw-bold small py-2">NAME*</div>
                  <div>
                    <input
                      required=""
                      type="text"
                      name="name"
                      className="w-100 bg-light w-100 border-0 py-1"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <span className="text-danger text-end flex-grow-1 small" />
                  </div>
                  <div className="fw-bold small py-2">EMAIL*</div>
                  <div>
                    <input
                      required=""
                      type="email"
                      name="email"
                      className="w-100 bg-light w-100 border-0 py-1"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <span className="text-danger text-end flex-grow-1 small" />
                  </div>
                  <div className="small text-muted my-5 ">
                    Your membership provided 4 accounts. This will sent an invite to 2 user
                    by email. Once they accept, they can begin exploring on cultureNOW
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-sm btn-light fw-bold mx-1 h-100 small text-black" type='submit'>
                        Re-Send Invite
                      </button>
                      <button className="btn btn-sm btn-red fw-bold mx-1 h-100 small text-white">
                        Delete
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <hr className="cnow-hr" />
      <div className="row">
        <div className="col-lg-10">
          <div className='cnow-form-title text-uppercase'>Sub Users/Secondary Members</div>
        </div>
        <div className="col-lg-2">
          <div className="d-flex justify-content-end mb-3">
            <button className="btn btn-sm btn-light" disabled={user?.invited_users?.length === 5} onClick={handleShow}>
              <i
                style={{
                  backgroundImage: 'url("/img/icons/plus.svg")',
                  width: 12,
                  height: 12,
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginBottom: 2,
                  marginRight: 4,
                  marginLeft: 0,
                  backgroundRepeat: "no-repeat",

                }}
              />
              Invite member
            </button>
          </div>
        </div>

        {
          user?.invited_users?.map((item) => {
            return <div className="row">
              <div className="col-lg-10">
                <input name={item?.name} value={item?.name} type='text' className='form-control mb-3' autoComplete="off" disabled={true} />
              </div>
              <div className="col-lg-2">
                <div className="d-flex justify-content-end" onClick={() => deleteMember(item?.id)}>
                  <Button size='sm' icon='delete' />
                </div>
              </div>
            </div>
          })
        }
      </div>

      <hr className="cnow-hr" />
      <div className='form-group'>
        <div className='cnow-form-title text-uppercase'>Culturenow Email</div>
        <input name='name' type='text' className='form-control' autoComplete="off" value={user?.email} disabled={true} />
      </div>

    </>
  )
}

export default UserProfile
