import React from 'react';

const PublicationTypeList = ({ data }) => {
    return (
        <>
            {
                data && data.length > 0 && <ul className='list-inline small text-muted mb-0'>
                    {
                        data.map((item, index) => {
                            return <li key={index} className='list-inline-item'>{item?.publication_type_name}</li>
                        })
                    }
                </ul>
            }
        </>
    )
}

export default PublicationTypeList;