import React from 'react'
import { Modal } from 'react-bootstrap';
import Button from '../../components/buttons/default'
import { useSelector } from 'react-redux';
const TourAlerts = (props) => {
    let site = props.site;
    let stop = props.stop;
    const PathType = props.pathType || '';
    const tour = useSelector(state => state[props.tourId]);
    return (
        <Modal show={props.show} onHide={() => props.onHide()} size='lg' centered backdrop="static" >
            <Modal.Body >
                <div>
                    <div className='row d-flex align-items-center'>
                        <div className='col'>
                            <div className='row d-flex align-items-center'>
                                {props.type === 'changeFormat' && <div className='col '><label className='cnow-form-title mb-0'>{`YOU ARE CHANGING THE FORMAT OF THE ${PathType} TOUR`}</label></div>}
                                <div className={props.type === 'changeFormat' ? 'd-none' : 'd-block'}><label className='cnow-form-title mb-0'>{props.heading}</label></div>

                            </div>
                        </div>
                        <div className='col-auto btn-list '>
                            <Button size='sm' icon='close' onClick={() => props.onHide()} />
                        </div>
                    </div>
                    <hr className='cnow-hr' />
                    {props.type === 'AddaStop' &&
                        <div name='addStop' className='row gy-3'>
                            <div className='row mt-2'>
                                <div className='col-auto d-flex'>
                                    <label className='cnow-form-label fw-bold mt-2 me-1'>Do you want to save changes made to the</label>
                                    <div className='tour-stop-number-red prevent-select d-flex'>{props.stopSort}</div>
                                    <div className='col p-1'><label className='cnow-form-title mb-0'>{`Tour Stop ${props.stopSort}`}</label></div>
                                </div>
                                <div className='col'>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='row'>
                                        <label className='cnow-form-label fw-bold'>Your changes will be lost if you don't save them.</label>
                                    </div>
                                    <div className='col-auto mt-5'>
                                        <div className='btn-list'>
                                            <Button className="me-3" color='danger' label='Save and close' onClick={() => props.alertStop()} />
                                            <Button className="me-3 ms-3" label="Don't save" onClick={() => props.tourStopHide()} />
                                            <Button className="me-3 ms-3" label='Go back' onClick={() => props.onHide()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {props.type === 'AddaDelte' &&
                        <div>
                            <div className='row'>
                                <label className='cnow-form-label fw-bold'>Are you sure want to remove ther following from your walking tour.</label>
                            </div>
                            <div className='col d-flex mt-2'>
                                <div className='tour-stop-number-red prevent-select d-flex  me-2'>{props.stop.sort}</div>
                                <label className='cnow-form-title fw-bold'>{props.stop.stop_name}</label>
                            </div>
                            <div className=''>&nbsp;</div>
                            {props.stop?.sites?.length > 0 && props.stop.sites.map((site, index) =>
                                <div className='row mb-2 g-2'>
                                    <div className='col-auto'>
                                        <div className='tour-stop-site-number-red d-flex'>{`${props.stop?.sort}.${site.sort}`}</div>
                                    </div>
                                    <div className='col'>
                                        <label className='cnow-form-title fw-bold'>{site.site_name} </label>
                                    </div>
                                </div>)
                            }
                            <label className='cnow-form-title fw-bold'>{props.stop.stop_name}</label>
                            <div className='col'>
                                <div className='btn-list d-flex justify-content-center'>
                                    <Button className="me-3 ms-3" label="Confirm" onClick={() => props.stopAlert()} />
                                    <Button className="me-3" color='danger' label='Cancel' onClick={() => props.onHide()} />
                                </div>
                            </div>
                        </div>
                    }
                    {props.type === 'changeFormat' && <div name='addStop' className='row gy-3'>
                        <div className='row mt-2'>
                            <div className='col-auto d-flex'>
                                <label className='cnow-form-label fw-bold mt-2 me-1'>The Self guided tour format will change into TOUR STOPS, with one or more SITE within each TOUR STOP </label>

                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col'>
                                <div className='row'>
                                    <label className='cnow-form-label fw-bold'>Every other site will be under its own TOUR STOP. You can drag and drop to consolidate the others</label>
                                </div>
                                <div className='col-auto mt-5'>
                                    <div className='btn-list'>
                                        <Button className="me-3" color='danger' label='Confirm' onClick={() => { props.changeTourFormat(); props.onHide() }} />
                                        <Button className="me-3 ms-3" label="Go back" onClick={() => props.onHide()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {
                        props.type === 'deleteSite' && <div name='addStop' className='row gy-3'>
                            <div className='row'>
                                <div className='row'>
                                    <label className='cnow-form-label fw-bold'>Are you sure you want to remove the following from your walking tour.</label>
                                </div>
                                <div className='col-auto'>
                                    <div className='tour-stop-site-number-red d-flex'>{`${props.stop?.sort}.${site.sort}`}</div>
                                </div>
                                <div className='col'>

                                    <label className='cnow-form-title mb-0'>{site.site_name}</label>
                                </div>
                                <div className='col mt-5'>
                                    <div className='btn-list d-flex justify-content-center'>
                                        <Button className="me-3 ms-3" label='Confirm' onClick={() => { props.siteAlert(site); props.onHide() }} />
                                        <Button className="me-3" color='danger' label='Cancel' onClick={() => props.onHide()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {props.type === 'AddaSite' &&
                        <div name='addStop' className='row gy-3'>
                            <div className='row mt-2'>
                                <div className='col-auto d-flex'>
                                    {tour.format === 2 ? <><label className='cnow-form-label fw-bold mt-2 me-1'>Do you want to save changes made to the</label>
                                        <div className='tour-stop-number-red prevent-select d-flex'>{`${stop?.sort}.${site.sort}`}</div>
                                        <div className='col p-1'><label className='cnow-form-title mb-0'>Site In</label></div>
                                        <div className='tour-stop-number-red prevent-select d-flex'>{`${stop?.sort}`}</div>
                                        <label className='cnow-form-title mb-0 p-1'>{stop?.stop_name}</label></> : <><div className='tour-stop-number-red prevent-select d-flex'>{`${site.site_id === null ? tour.stops.length + 1 : stop?.sort}`}</div>
                                        <label className='cnow-form-title mb-0 p-1'>{stop?.stop_name}</label></>}
                                </div>
                                <div className='col'>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='row'>
                                        <label className='cnow-form-label fw-bold'>Your changes will be lost if you don't save them.</label>
                                    </div>
                                    <div className='col-auto mt-5'>
                                        <div className='btn-list'>
                                            <Button className="me-3" color='danger' label='Save and close' onClick={() => props.alertSite()} />
                                            <Button className="me-3 ms-3" label="Don't save" onClick={() => props.tourSiteHide()} />
                                            <Button className="me-3 ms-3" label='Go back' onClick={() => props.onHide()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TourAlerts