import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import RelatedEventSeriesEditor from "../../components/editors/relatedEventSeriesEditor";
// const _api = require('../../api')

const EventSeries = (props) => {
    const dispatch = useDispatch();
    const [key, setKey] = useState(uniqid())

    // const dispatch = useDispatch();
    const event = useSelector((state) => state[props.id]);


    const setShowEventSeries = () => {
        if (event.id==="NEW") toast.warning('Please Save the Event')
        else
       { dispatch({ type: 'entity', payload: { ...event, show_event_series: !event.show_event_series } });}
    }
    return (
        <>
            <hr className='cnow-hr' />
            <div className='form-group'>
                <div className='d-flex justify-content-between'>
                    <span className='cnow-form-title'>EVENT SERIES</span>
                    <div className='form-check'>
                        <input className='form-check-input' type='checkbox' value={event.show_event_series || ''} checked={event.show_event_series} onChange={() => setShowEventSeries()} />
                        <label className={`small form-check-label ${event.show_event_seriess && 'fw-bold'}`}>If applicable</label>
                    </div>
                </div>
                {event.show_event_series && <RelatedEventSeriesEditor id={event.id} type={'event'} disableTextEditor = {true} key={`related-event-event_series-editor-${key}`} refresh={() => { setKey(uniqid()) }} />}

            </div>
        </>
    )
}
export default EventSeries