import React, { useState } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import Spinner from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import FeaturedTitle from './title';
import SelectPerson from '../../components/selects/person';
import SelectEvent from '../../components/selects/event';
import SelectTour from '../../components/selects/tour';
import SelectHashTags from './hashTags';
import { newTempId } from '../../helpers';
import { useHistory } from 'react-router-dom';
const options = [
    {
        "label": "HASH TAG",
        "value": "hash_tag"
    },
    {
        "label": "PERSON / ORG ",
        "value": "per_org"
    },
    {
        "label": "EVENT",
        "value": "event"
    },
    {
        "label": "TOUR",
        "value": "tour"
    }
]
const FeaturedForCollection = (props) => {
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [display, setDisplay] = useState(false)
    const featured = useSelector((state) => state.featured);
    const featured_for_collection = featured.featured_for_collection || []
    const featured_entity = featured_for_collection?.[0]?.data?.entity_type || ''
    const [featuredOption, setFeaturedOption] = useState(featured_entity ? options.filter(elem => elem.value === featured_entity)[0] : options[0])
    const dataFeatured = featured_for_collection?.[0]?.data?.featured_for_collection
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    const history = useHistory();
    const handleDispatchInitialState = (flag) => {
        let data = {
            "start_date": start_date.slice(0, 10),
            "end_date": end_date.slice(0, 10),
            "section": `featured_for_collection`,
            "id": newTempId(),
            "data": {
                'featured_for_collection': [
                    { name: '', id: '', entity_type: '' }
                ]
            }
        }
        if (flag) data.data.featured_for_collection = [{
            'value': '', 'label': '', 'id': ''
        }]
        featured_for_collection.push(data)
        dispatch({ type: 'featured', payload: { ...featured, featured_for_collection: featured_for_collection } })
    }

    const handleDeleteSite = () => {
        setDisplay(true)
        featured_for_collection[0].edited = true
        featured_for_collection[0].data.featured_for_collection = []
        dispatch({ type: 'featured', payload: { ...featured, featured_for_collection: featured_for_collection } })
        setDisplay(false)
        setEditedIndex(-1)
    }

    const handleEntityChange = (entity, value) => {
        setDisplay(true)
        if (featured_for_collection?.length === 0) handleDispatchInitialState()
        featured_for_collection[0].edited = true
        featured_for_collection[0].data.featured_for_collection = []
        featured_for_collection[0].data.featured_for_collection[0] = {
            'name': '', 'id': ''
        }
        featured_for_collection[0].data.entity_type = entity
        featured_for_collection[0].data.featured_for_collection[0].name = value.name
        featured_for_collection[0].data.featured_for_collection[0].id = value.id
        dispatch({ type: 'featured', payload: { ...featured, featured_for_collection: featured_for_collection } })
        setDisplay(false)
    };

    const handleHashTags = (data) => {
        if (featured_for_collection?.length === 0) handleDispatchInitialState(true)
        featured_for_collection[0].edited = true
        featured_for_collection[0].data.featured_for_collection = []
        featured_for_collection[0].data.entity_type = "hash_tag"
        for (let i in data) {
            featured_for_collection[0].data.featured_for_collection[i] = {
                'value': '', 'label': '', 'id': ''
            }
            featured_for_collection[0].data.featured_for_collection[i].value = data[i].value
            featured_for_collection[0].data.featured_for_collection[i].label = data[i].label
            featured_for_collection[0].data.featured_for_collection[i].id = data[i].id
        }

        dispatch({ type: 'featured', payload: { ...featured, featured_for_collection: featured_for_collection } })
    }

    return (
        <div>

            <FeaturedTitle title='Featured' page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />
            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}
                    disable={props?.page === 'home' ? true : false}
                >
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="FEATURED FOR COLLECTION PAGES"
                        count={`1 item`}
                        button={{
                            edit: { show: true, onHandle: () => { setEditedIndex(1) } },
                        }}

                        page={props?.page}
                        select={{
                            entity: { show: true, options: options, onHandle: (data) => { setFeaturedOption(data) }, defaultValue: featured_entity ? options.filter(elem => elem.value === featured_entity)[0] : options[0] },
                        }}>
                        <Spinner display={display} >
                            <div className='row py-3'>
                                <div className='col-12'> <input value={featuredOption.value === 'hash_tag' ? featured_for_collection?.[0]?.data?.featured_for_collection?.map((elem) => { return elem.label }).join(', ') : dataFeatured?.[0]?.name || 'esto'} className='w-100 cursor-pointer' readOnly onClick={() => { if (props?.page !== 'home') setEditedIndex(1); }} /></div>
                            </div>
                        </Spinner>
                    </CollapsableSection>
                    {(editedIndex !== -1) && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (true), onHandle: () => { handleDeleteSite(); props.onUpdate(); } }
                        }}
                        key={editedIndex}
                    >
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-12'>
                                    {featuredOption.value === 'hash_tag' && <>
                                        <SelectHashTags data={featured_for_collection?.[0]?.data?.featured_for_collection?.length > 0 ? featured_for_collection[0].data.featured_for_collection : []} updateData={(data) => { handleHashTags(data) }} />
                                    </>}

                                    {featuredOption.value === 'per_org' && <>
                                        <label className='cnow-form-label fw-bold'>Person / Org</label>
                                        <SelectPerson value={{ 'id': dataFeatured?.[0]?.id, 'name': dataFeatured?.[0]?.name }} onChange={(value) => { handleEntityChange('per_org', value) }} facetFiltersMoreThanOne entityType='person' subEntityType='organization' key='per_org' />

                                    </>}

                                    {featuredOption.value === 'event' && <>
                                        <label className='cnow-form-label fw-bold'>Event</label>
                                        <SelectEvent value={{ 'id': dataFeatured?.[0]?.id, 'name': dataFeatured?.[0]?.name }} onChange={(value) => { handleEntityChange('event', value) }} key='event' />
                                    </>}

                                    {featuredOption.value === 'tour' && <>
                                        <label className='cnow-form-label fw-bold'>Tour</label>
                                        <SelectTour value={{ 'id': dataFeatured?.[0]?.id, 'name': dataFeatured?.[0]?.name }} onChange={(value) => { handleEntityChange('tour', value) }} key='tour' />
                                    </>}


                                </div>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default FeaturedForCollection