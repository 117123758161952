import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import swal from 'sweetalert'

import Name from '../../components/preview/name';
import LocationList from '../../components/preview/locationList';
import LinkButton from '../../components/buttons/link';
import MetaDescription from '../../components/preview/metaDescription';

const _api = require('../../api');

const OverlayPreview = (props) => {
    const dispatch = useDispatch();
    const overlay = useSelector(state => state[props.id]);
    const previewSize = useSelector((state) => state.preview);
    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

    const handleLiveNotLive = async (id) => {
        overlay.live = !overlay.live;
        await _api.map_overlay.update(overlay).then((myOverlay) => {
            let messageStatus;
            let icon;
            if (myOverlay.live) {
                messageStatus = `Not Live!`
                icon = 'success'
            } else {
                messageStatus = `Live!`
                icon = 'info'
            }
            swal({
                title: `Map is ${messageStatus}!`,
                icon: icon,
                text: `${myOverlay?.title} is now ${messageStatus}.`,
                buttons: 'Ok',
            })
            dispatch({ type: 'entity', payload: myOverlay })
        })
    }

    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true}/>
                        <PreviewButton size='sm' link={`/historic-map/map_overlay/${overlay?.id}`} />
                        <LinkButton size='sm' icon='pan' to={`/map_overlay/${overlay?.id}/editor`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar btn-preview-pan'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {overlay?.live ? (<Button size='sm' color='light' label='Live' onClick={() => handleLiveNotLive(overlay.id)} />) :
                            (<Button size='sm' color='danger' label='Not Live' onClick={() => handleLiveNotLive(overlay.id)} />)}
                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true}/>
                        <LinkButton size='sm' icon='pan' to={`/map_overlay/${overlay?.id}/editor`} />
                        <PreviewButton size='sm' link={`/historic-map/map_overlay/${overlay?.id}`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>

            {
                overlay &&
                <div className='preview'>
                    {
                        previewSize === 'lg' &&
                        <>
                            <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                            <Name data={overlay.name} />
                            <LocationList id={overlay.id} height='70vh' type='map_overlay' showMap={true} />
                            <MetaDescription data={overlay.meta} id={overlay.id} type='overlay' locationId={props.locationId} />
                        </>
                    }
                </div>
            }
        </>
    );
}
export default OverlayPreview