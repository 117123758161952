/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import { personName } from '../../helpers';
import AlternateContact from '../../components/peopleEditor/alternateContact';
import Location from './location';
import uniqid from 'uniqid'
const PeopleContact = (props) => {
	const [key, setKey] = useState(uniqid())
	const dispatch = useDispatch();
	let person = useSelector(state => state[props.id]);
	const handleContactChange = ({ target: { name, value } }) => {
		person.primary_contact[name] = value
		dispatch({ type: 'entity', payload: { ...person, primary_contact: person.primary_contact } });
	}
	return (
		<>
			<PageTitle title={personName(person)} />
			<div id='header'>
				<hr className='cnow-hr' />
				<div className='cnow-form-title'>Contact</div>
				{
					person?.type !== 'person' &&
					<div className='row g-1'>
						<div className='col'>
							<label className='cnow-form-label fw-bold'>Name</label>
							<input name='name' type='text' className='form-control' value={person?.primary_contact?.name || ''} onChange={handleContactChange} autoComplete="off" />
						</div>
						<div className='col'>
							<label className='cnow-form-label fw-bold'>Role</label>
							<input name='role' type='text' className='form-control' value={person?.primary_contact?.role || ''} onChange={handleContactChange} autoComplete="off" />
						</div>
					</div>
				}
				<div className='row g-1'>
					<div className='col'>
						<label className='cnow-form-label fw-bold'>Phone</label>
						<input name='phone' type='text' className='form-control' value={person?.primary_contact?.phone || ''} onChange={handleContactChange} autoComplete="off" />
					</div>
					<div className='col'>
						<label className='cnow-form-label fw-bold'>Email</label>
						<input name='email' type='text' className='form-control' value={person?.primary_contact?.email || ''} onChange={handleContactChange} autoComplete="off" />
					</div>
				</div>
			</div>
			<AlternateContact id={props.id} key={`alternate-contact-${key}`} refresh={() => setKey(uniqid())} />
			<Location id={props.id} key={`person-location-${key}`} refresh={() => setKey(uniqid())} />
		</>
	);
};

export default PeopleContact