import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import PreviewButton from "../../components/buttons/preview";
import Button from "../../components/buttons/default";
import ImageViewer from "../../components/viewers/image";
import Spinner from "../../components/spinner";
const _api = require('../../api')
const PodcastSiteName = ({ site, stop_index, site_index, format }) => {
    const [spinner, setSpinner] = useState(false)
    const [minimize, setMinimize] = useState(false)
    const [sitePodcast, setSitePodcast] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleEdit = (route) => {
        history.push({ pathname: route })
        dispatch({ type: 'podcasts', payload: { entityPodcasts: sitePodcast, source: location.pathname } })
    }

    const handleSitePodcastApi = async (id) => {
        setSpinner(true)
        if (!minimize) await _api.site.getPodcast(id).then((data) => {
            setSitePodcast(data)
            setSpinner(false)
        })
        setSpinner(false)
    }

    return (
        <>
            <div className='row mb-2 g-2'>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <div className='tour-stop-site-number d-flex'>{format === 2 ? `${stop_index + 1}.${site_index + 1}` : `${stop_index + 1}`}</div>
                </div>
                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <ImageViewer entityType='image' entityId={site.image_id} type='thumb' cssClass='thumb-small' />
                </div>
                <div className='col'>
                    <label className='cnow-form-label fw-bold'>Associated Site name*</label>
                    <input value={site.site_name} disabled className='bg-white w-100 cnow-input-disabled-height border-0' />
                </div>

                <div className='col-auto'>
                    <div className=''>&nbsp;</div>
                    <div className='btn-list'><Button icon={`${minimize ? 'arrow-top' : 'arrow-bottom'}`} onClick={() => { setMinimize(!minimize); handleSitePodcastApi(site.site_id) }} /></div>
                </div>
            </div>

            <div className={`${!minimize ? 'd-none' : ''}`}>
                <Spinner display={spinner}>
                    {sitePodcast?.length > 0 &&
                        sitePodcast?.map((item, index) => {
                            return (
                                <div key={index} id={item?.id} className={`${item?.id} text-left dragula-item d-flex py-2`}>
                                    <div style={{ width: '70px' }}>
                                        <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete='off' />
                                    </div>
                                    <div style={{ width: '90px' }}>
                                        <ImageViewer entityType={'podcast'} entityId={item?.podcast_id} type='thumb@2x' cssClass='thumb' />
                                    </div>
                                    <div className='text-start small flex-grow-1'>
                                        <div className='bg-light fw-bold'>{item?.podcast_name} </div>
                                        <div>by {item?.podcast_credits}</div>
                                    </div>
                                    <div className='text-end' style={{ width: '150px' }}>
                                        <div className='d-flex gap-1 justify-content-end'>
                                            <PreviewButton size='md' icon='preview' link={`podcast/${item?.podcast_id}`} />
                                            <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${item?.podcast_id}/editor`) }} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </Spinner>
            </div>
        </>
    )
}

export default PodcastSiteName