import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';

import Name from '../../components/preview/name';
import Alias from '../../components/preview/alias';
import Location from '../../components/preview/location';
import Category from '../../components/preview/category';
import Publication from '../../components/preview/publication';
import PeopleList from '../../components/preview/peopleList';
import Description from '../../components/preview/description';
import PodcastList from '../../components/preview/podcastList';
import Playlist from '../../components/preview/playlist';
import SiteDesignationList from '../../components/preview/siteDesignationList';
import AwardList from '../../components/preview/awardList';
import RecipieList from '../../components/preview/recipieList';
import StoryList from '../../components/preview/storyList';
import EventList from '../../components/preview/eventList';
import RecurringEventList from '../../components/preview/recurringEventList';
import TourList from '../../components/preview/tourList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import LocationList from '../../components/preview/locationList';
import TagList from '../../components/preview/tagList';
import NearbyList from '../../components/preview/nearbyList';
import MetaDescription from '../../components/preview/metaDescription';
import ImagesList from '../../components/preview/imagesList'
import DateDescriptionList from '../../components/preview/dateDescriptionList';
import MapsList from '../../components/preview/mapsList'
import OnThisDay from '../../components/preview/onThisDay';
import { getCategoryName } from '../../helpers';
import swal from 'sweetalert'
const _api = require('../../api');

const SitePreview = (props) => {
	const dispatch = useDispatch();
	const site = useSelector(state => state[props.id]);
	const previewSize = useSelector((state) => state.preview);

	const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

	const handleLive = (siteId) => {
		let dateDescription = site.dates?.filter((e) => e.description !== '')
		let category = getCategoryName(site?.categories)
		if (site.id === 'NEW') {
			swal({
				title: 'Site cannot go Live.',
				icon: 'error',
				text: `${site?.id === 'NEW' && 'Fill the mandatory fields * to make the site go live.'}`,
				buttons: 'Go back',
				dangerMode: true,
			})
		}
		else if (
			site?.name &&
			category &&
			site?.locations?.[0] &&
			site?.locations?.[0]?.address &&
			(site?.description || dateDescription?.length > 0) &&
			site?.image?.url
		) {
			swal({
				title: 'Site is Live!',
				icon: 'success',
				text: `${site.name} is now Live.`,
				buttons: 'Ok',
			})
			_api.site.updateLive(siteId, true).then(() =>
				dispatch({ type: 'entity', payload: { ...site, live: true } })
			)
		} else {
			swal({
				title: 'Site cannot go Live.',
				icon: 'error',
				text: `${'Please fill the mandatory fields:'}
					${site?.name ? '' : 'Title is missing.'}
					${category ? '' : 'Category is missing.'}
					${site?.locations?.[0] && site?.locations?.[0]?.address ? '' : 'Location is missing.'} 
					${(site?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
					${site?.image?.url ? '' : 'Image is missing.'}`,
				buttons: 'Go back',
				dangerMode: true,
			})
			_api.site.updateLive(siteId, false).then(() =>
				dispatch({ type: 'entity', payload: { ...site, live: false } })
			)
		}
	}

	const handleNotLive = (siteId) => {
		_api.site.updateLive(siteId, false).then(() =>
			dispatch({ type: 'entity', payload: { ...site, live: false } })
		)
		swal({
			title: 'Not Live!',
			icon: 'info',
			text: `${site.name} is now Not Live.`,
			buttons: 'Ok',
		})
	}

	const isPublicArt = () => {
		return site.categories?.filter(cat =>
			cat?.category_name?.toLowerCase() === 'public art' || cat?.program_name?.toLowerCase() === 'public art' || cat?.subcategory_name?.toLowerCase() === 'public art')?.length > 0;
	}

	return (
		<>
			<>
				{
					previewSize === 'sm' && <div className='btn-grid'>
						<Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' color='light' link={`site/${site?.vanity_url || site?.id}`} />

						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

						<Button size='sm' color='light' icon='help' />
					</div>
				}

				{
					previewSize === 'lg' && <div className='btn-list action-bar'>
						<Button size='sm' color='light' label='Instructions' icon='help' />
						{site?.live ? (<Button size='sm' color='light' label='Live' onClick={() => handleNotLive(site.id)} />)
							: (<Button size='sm' color='danger' label='Not Live' onClick={() => handleLive(site.id)} />)}
						<Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' color='light' link={`site/${site?.vanity_url || site?.id}`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
					</div>
				}
			</>

			<div className='preview'>
				{
					previewSize === 'lg' && <>
						<div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
						{
							site && props.section !== 'meta' && props.section !== 'location' && props.section !== 'images' && props.section !== 'on_this_day' && props.section !== 'attach_map' &&
							<>
								<Name data={site.name} />
								<Alias data={site.alias} />
								<Location data={site.locations?.filter(x => x.previous === false)} prevLocations={site?.locations?.filter(x => x.previous === true)} />
								<Category categories={site.categories} />
								<PeopleList data={site.people?.length > 0 ? site?.people?.filter((item) => item.person_type !== 'publication') : []} />
								<Publication data={site.people?.length > 0 ? site?.people?.filter((item) => item.person_type === 'publication') : []} />
								<Description data={site.description} />
								{
									isPublicArt() &&
									<div>
										<div className='d-flex'>
											<div className='fw-bold'>Material: </div>
											<div className='px-1'>{site.material}</div>
										</div>
										{
											(site.dimension.length?.length > 1 || site.dimension.width?.length > 1 || site.dimension.height?.length > 1) && <div className='d-flex'>
												<div className='fw-bold'>Dimensions:</div>
												<div>{`${site.dimension.length?.length > 1 ? `L ${site.dimension.length}` : ''}${site.dimension.width?.length > 1 ? ` x W ${site.dimension.width}` : ''}${site.dimension.height?.length > 1 ? ` x H ${site.dimension.height}` : ''} ;`}</div>
											</div>
										}
										<div dangerouslySetInnerHTML={{ __html: site.dimension.description }} />
									</div>
								}
								{(!site.description && site.dates?.length > 0) && <hr />}
								<DateDescriptionList dates={site.dates} />
								<PodcastList data={site.podcasts} />
								<SiteDesignationList designations={site.designations} />
								<AwardList awards={site.awards} />
								<RecipieList data={site.recipies} />
								<StoryList data={site.stories} />
								<EventList data={site.events} />
								<RecurringEventList data={site.events} />
								<TourList data={site.tours} />
								<RelatedPeopleList data={site.related_people?.length > 0 ? site.related_people.filter((item) => item.related_person_type !== 'publication') : []} />
								<RelatedSiteList data={site.related_sites} type='site' />
								<Playlist data={site.playlists} />
								<RelatedPeopleList data={site.related_people?.length > 0 ? site.related_people.filter((item) => item.related_person_type === 'publication') : []} title={'Related Publication'} />
								<LocationList id={site.id} type={'site'} />
								<MapsList id={site.id} />
								<NearbyList id={site.id} lat={site.locations?.length > 0 && site.locations[0]?.location?.lat} lng={site.locations?.length && site.locations[0]?.location?.lng} />
								<TagList tags={site.tags} themes={site.themes} categories={site.categories} entity={'site'} />
							</>
						}

						{site && props.section === 'meta' && <MetaDescription data={site.meta} id={site.id} type='site' />}
						{site && props.section === 'location' && <LocationList type={'site'} id={site.id} height='70vh' locationId={props.locationId} column='flex-column-reverse' />}
						{site && props.section === 'images' && <ImagesList id={site.id} type='site' />}
						{site && props.section === 'on_this_day' && <OnThisDay id={site.id} />}
						{site && props.section === 'attach_map' &&
							<>
								<MapsList id={site.id} type='site' />
								<LocationList id={site.id} height='70vh' locationId={props.locationId} type={'site'} overlays={site.selectedRelatedMap?.overlays || []} />
							</>
						}
					</>
				}
			</div>
		</>
	);
};

export default SitePreview;