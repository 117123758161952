
import { ApiCall } from './utils'

//podcast
const getById = (id) => ApiCall('get', `/podcast/${id}`)

const search = (params) => ApiCall('post', `/podcast/search`, params)

const searchByElastic = (from, size, params) => ApiCall('post', `/search/podcast/${from}/${size}`, params)

const update = (params) => ApiCall('post', `/podcast`, params)

const process = (id, params) => ApiCall('post', `/podcast/${id}/process`, params)

const deleteById = (id) => ApiCall('delete', `/podcast/${id}`)

const updateLive = (id, live) => ApiCall('put', `/podcast/${id}/live/${live}`)

//Credit
const getCredit = (id) => ApiCall('get', `/podcast/${id}/credit`)

const updateCredit = (id, params) => ApiCall('post', `/podcast/${id}/credit`, params)

const deleteCredit = (id) => ApiCall('delete', `/podcast/credit/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/podcast/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/podcast/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/podcast/tag/${id}`)

//related item
const getRelatedItem = (id) => ApiCall('get', `/podcast/${id}/related_item`)

export default {
    getById,
    search,
    searchByElastic,
    update,
    deleteById,
    process,
    updateLive,

    getCredit,
    updateCredit,
    deleteCredit,

    getTag,
    updateTag,
    deleteTag,

    getRelatedItem,
}