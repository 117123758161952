import React, { useState } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../../components/pageTitle";
import PodcastEditor from "../../components/editors/podcastEditor";
import RelatedPodcast from "../../components/editors/relatedPodcast";
import RelatedPlaylist from "../../components/editors/relatedPlaylist";
import { personName } from "../../helpers";
import uniqid from 'uniqid'
const PeoplePodcast = (props) => {
  const people = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={personName(people)} />
      <hr className='cnow-hr' />
      <PodcastEditor id={props.id} type="person" key={`podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
      <RelatedPodcast id={props.id} type="person" />
      <RelatedPlaylist id={props.id} type="person" key={`related-playlist-${key}`} refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default PeoplePodcast;
