/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const MetaTitleEditor = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    useEffect(() => {
        if (!entity.meta.metaOgUpdated) {
            if (entity.type === 'person') {
                entity.meta.og_title = entity?.first_name + ' ' + entity?.last_name + ' | CultureNow - Museum Without Walls';
            }
            else {
                entity.meta.og_title = entity?.name + ' | CultureNow - Museum Without Walls';
            }

            dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
        }
    }, [])

    const handleInputChange = async ({ target: { name, value } }) => {
        entity.meta[name] = value;
        entity.meta.metaOgUpdated = true;
        dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
    }
    return (
        <div>
            <div className='row mt-2'>
                <div className='col'>
                    <label className='cnow-form-title'>OG Title</label>
                </div>

            </div>
            <textarea type='textarea' rows='2' className='form-control' name='og_title' value={entity?.meta?.og_title} onChange={handleInputChange} />
        </div>
    )

}

export default MetaTitleEditor