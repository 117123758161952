/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../components/buttons/default';
import Icon from '../../components/viewers/icon';

const menu = require('../../data/menu_people.json');

const PeopleMenu = (props) => {
    const dispatch = useDispatch();

    const person = useSelector(state => state[props.id]);
    const menuSize = useSelector((state) => state.menu);

    const toggleMenuSize = () => dispatch({ type: 'menu', payload: menuSize === 'sm' ? 'lg' : 'sm' })

    const hasData = (path) => {
        let hasData = false;

        if (path === 'name') hasData = person?.name?.length || person?.first_name?.length > 0 || person?.last_name?.length > 0;
        else if (path === 'contact') hasData = person?.alternate_contact?.length > 0 || person?.locations?.length > 0 || person?.primary_contact?.name?.length > 0 || person?.primary_contact?.email?.length > 0 || person?.primary_contact?.phone?.length > 0 || person?.primary_contact?.role?.length > 0;
        else if (path === 'affiliation') hasData = person?.affiliations?.length > 0 || person?.related_people?.length > 0;
        else if (path === 'date') hasData = person?.dates?.length > 0;
        else if (path === 'people') hasData = person?.people?.length > 0;
        else if (path === 'podcasts') hasData = person?.podcasts?.length > 0 || person?.playlists?.length > 0;
        else if (path === 'about') hasData = person?.description?.length > 0 || person?.stories?.length > 0 || (person?.dates?.length > 0 && person?.dates?.filter((e) => e.description !== '')?.length > 0)
        else if (path === 'associated_sites') hasData = (person?.credits?.sites && person?.sites?.length > 0) || (person?.related_sites && person?.related_sites?.length > 0) || (person?.credits?.image_albums && person?.image_albums?.length > 0) || person?.additionalPhotos?.length > 0
        else if (path === 'images') hasData = (person?.credits?.sites && person?.sites?.length > 0) || (person?.related_sites && person?.related_sites?.length > 0) || (person?.credits?.image_albums && person?.image_albums?.length > 0) || person?.images?.length > 0 || person?.publications?.length || person?.image?.url?.length || person?.url?.length > 0;
        else if (path === 'awards_highlights') hasData = person?.awards?.length > 0 || person?.highlights?.length > 0;
        else if (path === 'meta') hasData = person?.meta?.description?.length > 0 || person?.meta?.keywords?.length > 0 || person?.meta?.og_title?.length > 0
        else if (path === 'attach_map') hasData = person?.maps?.length > 0;
        else if (path === 'on_this_day') hasData = (person?.images?.length > 0 && person?.images?.filter((item) => item?.type === 'then_now')?.length > 0) || person?.on_this_day?.length > 0
        else if (path === 'legal') hasData = person?.images?.filter((elem) => !elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || person?.podcasts?.filter((elem) => !elem?.is_legal)?.length > 0
        else if (path === 'curator') hasData = person?.curator !== null || person?.images?.filter((elem) => elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || person?.podcasts?.filter((elem) => elem?.is_legal)?.length > 0
        return hasData;
    }

    return (
        <>
            {
                menuSize === 'sm' && <>
                    <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    <div className='btn-list mt-4 text-center'>
                        {
                            menu.map((item, index) => {
                                let icon = item.icon;
                                if (hasData(item.path)) icon = `${item.icon}-done`;
                                if (props.section === item.path) icon = `${item.icon}-active`

                                return (
                                    <div key={index} className='mt-2'>
                                        <OverlayTrigger placement='right' overlay={<Tooltip>{item.name}</Tooltip>}>
                                            <button className='btn' onClick={() => { if (!props.display) props.onClick(item.path) }} data-bs-toggle='tooltip' data-bs-placement='right' title='Tooltip on right'>
                                                <Icon name={icon} size={24} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            {
                menuSize === 'lg' && <>
                    <div className='d-flex justify-content-between'>
                        <h5 className='text-capitalize text-nowrap'>{person?.type === "organization" ? `Add An ${person?.type}` : `Add A ${person?.type}`}</h5>
                        <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                    </div>

                    <ul className='list-group'>
                        {
                            menu.map((item, index) => {
                                let itemClass = 'list-group-item cnow-list-group-item d-flex align-items-start';
                                if (props.section === item.path) itemClass = `${itemClass} active`;
                                if (!item.enabled) itemClass = `${itemClass} disabled`
                                if (!person?.id) itemClass = `${itemClass} disabled`;

                                return (
                                    <li key={index} className={itemClass}>
                                        <button className={itemClass} onClick={() => { if (!props.display) props.onClick(item.path) }}>
                                            <i className={`fe fe-check mt-1 ${hasData(item.path) ? '' : 'cnow-text-light'}`} />
                                            <div className='ms-2 text-start'>
                                                {item.name}
                                                {item.required && <span className='ms-1'>*</span>}
                                            </div>
                                        </button>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </>
            }
        </>
    )
}

export default PeopleMenu;