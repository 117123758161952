import React from "react";
import PDFGenerator from "./pdfGenerator";

const PdfCurrentData = ({ currentIndex, pdf, entity_title, findTiming }) => {
    return (
        <>
            <div key={currentIndex}>
                {pdf?.length > 0 && pdf.filter((elem, index) => currentIndex === index).map((elem) => {
                    return < PDFGenerator title={elem.id} data={elem[elem.id]} type={elem.entity_type} enity_title={entity_title} milliSec={findTiming(elem)} key={elem.id} />
                })
                }
            </div>
        </>
    )
}

export default PdfCurrentData