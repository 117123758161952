import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import RelatedTourEditor from '../../components/editors/relatedTourEditor';
import RelatedEventEditor from '../../components/editors/eventEditor';
import PageTitle from '../../components/pageTitle';

const EventTour = (props) => {
    const event = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={event.name} />
            <RelatedEventEditor id={event.id} type={'event'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={false} />
            <RelatedTourEditor id={event.id} type={'event'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} disableTextEditor = {true}/>

        </>
    );
};

export default EventTour;