/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uniqid from 'uniqid'
import Dragula from "react-dragula";

import ImageViewer from "../viewers/image";
import TextEditor from './textEditor';
import SelectSite from '../selects/site';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId } from '../../helpers';

const _api = require('../../api')

const RelatedSiteEditor = (props) => {
	const dispatch = useDispatch();
	const entity = useSelector(state => state[props.id]);

	// Dragula Start
	const [dragula, setDragula] = useState(uniqid())
	const dragulaRef = useRef()
	const dragulaContainer = 'dragula-container'

	useEffect(() => {
		let containers = []
		containers.push(document.getElementById(dragulaContainer))
		if (entity?.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
		dragulaRef.current = Dragula(containers, {})
		dragulaRef.current.on('drop', () => {
			const items = handleReorder(entity.related_sites, entity.dates, dragulaContainer)
			setDragula(uniqid())
			dispatch({ type: 'entity', payload: { ...entity, related_sites: items } })
			props.refresh && props.refresh()
		})
	}, [dragula])
	// Dragula End

	const addRelatedSite = () => {
		if (!entity.related_sites) entity.related_sites = [];
		if (props.type === 'site') {
			entity.related_sites.push({
				'id': newTempId(),
				'description': '',
				'related_site_id': null,
				'related_site_name': '',
				[`${props.type}_id`]: props?.id,
				'source': props.type,
				'sort': entity.related_sites ? entity.related_sites.length + 1 : 1,
			});
		} else {
			entity.related_sites.push({
				'id': newTempId(),
				'description': '',
				'site_id': null,
				'site_name': '',
				'source': props.type,
				[`${props.type}_id`]: props?.id,
				'sort': entity.related_sites ? entity.related_sites.length + 1 : 1,
			});
		}
		entity.related_sites.forEach((item, index) => item.sort = index + 1)
		setDragula(uniqid())
		dispatch({ type: 'entity', payload: { ...entity, related_sites: entity.related_sites } });
	}

	const removeRelatedSite = async (index) => {
		if (entity.related_sites[index]) {
			if (!entity.related_sites[index].id?.startsWith('temp.')) {
				if (props.type === 'site') {
					await _api[props.type].deleteRelatedSite(entity.related_sites[index].id)
				} else if (props.type === 'person') {
					await _api.site.deleteRelatedPerson(entity.related_sites[index].id)
				} else if (props.type === 'map') {
					await _api.site.deleteMap(entity.related_sites[index].id)
				} else if (props.type === 'playlist') {
					await _api.site.deletePlaylist(entity.related_sites[index].id)
				} else if (props.type === 'podcast') {
					await _api.site.deletePodcast(entity.related_sites[index].id)
				}
				else if (props.type === 'tour') {
					await _api.site.deleteTour(entity.related_sites[index].id)
				}
				else if (props.type === 'event') {
					await _api.site.deleteEvent(entity.related_sites[index].id)
				}
				else if (props.type === 'event_series') {
					await _api.site.deleteEventSeries(entity.related_sites[index].id)
				}
			}

			entity.related_sites.splice(index, 1)
			entity.related_sites.forEach((item, index) => item.sort = index + 1)
			dispatch({ type: 'entity', payload: { ...entity, related_sites: entity.related_sites } });
		}

	}

	const handleSiteChange = (index, value) => {
		if (props.type === 'site') {
			entity.related_sites[index].related_site_id = value.id
			entity.related_sites[index].related_site_name = value.name
		} else {
			entity.related_sites[index].site_id = value.id
			entity.related_sites[index].site_name = value.name
		}

		dispatch({ type: 'entity', payload: { ...entity, related_sites: entity.related_sites } });
	}

	const handleEditorChange = (index, data) => {
		entity.related_sites[index].description = data;
		dispatch({ type: 'entity', payload: { ...entity, related_sites: entity.related_sites } });
	}

	return (
		<div>
			<hr className='cnow-hr' />
			<div className='row'>
				<div className='col'>
					<label className='cnow-form-title'>Related Sites  <span className='small fw-normal'>(Indirect relationship to a site)</span></label>
				</div>
				<div className='col-auto'>
					<Button size='sm' icon='plus' label='Add a Site' onClick={() => addRelatedSite()} />
				</div>
			</div>

			<div id={dragulaContainer} key={dragula} >
				{
					entity?.related_sites?.map((item, index) => {
						let offset = 0;
						offset = props.type === 'person' ? props?.siteLength : offset
						return (
							<div key={index} id={item.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`}>
								<div className='row g-1'>
									{
										entity?.related_sites?.length + offset > 1 &&
										<div className='col-auto'>
											<label className='cnow-form-label fw-bold'>Order</label>
											<input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1 + offset} autoComplete="off" />
										</div>
									}
									<div className='col-auto'>
										<div className=''>&nbsp;</div>
										<ImageViewer key={item.id} entityType='site' entityId={props.type === 'site' ? item?.related_site_id : item?.site_id} type='thumb' cssClass='thumb-small' />
									</div>
									<div className='col'>
										<label className='cnow-form-label fw-bold'>Related Site *</label>
										<div className='row g-1'>
											<div className='col'>
												{!item.id?.startsWith('temp.') ?
													<input value={props.type === 'site' ? item?.related_site_name : item?.site_name} disabled className='h-100 w-100' />
													:
													<SelectSite key={item.id}
														value={{
															id: props.type === 'site' ? item?.related_site_id : item?.site_id,
															name: props.type === 'site' ? item?.related_site_name : item?.site_name
														}}
														onChange={(value) => handleSiteChange(index, value)} />
												}
											</div>
											<div className='col-auto'>
												<div className='btn-list'>
													<PreviewButton link={`site/${props.type === 'site' ? item?.related_site_id : item?.site_id}`} />
													<LinkButton icon='edit' to={`/site/${props.type === 'site' ? item?.related_site_id : item?.site_id}/editor`} />
													<Button icon='delete' onClick={() => removeRelatedSite(index)} />
												</div>
											</div>
										</div>
										<TextEditor key={item.id} data={item.description} onChange={(data) => handleEditorChange(index, data)}>
											<label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
										</TextEditor>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default RelatedSiteEditor;