import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import PodcastEditor from '../../components/editors/podcastEditor';

const RecipePodcast = (props) => {
  const recipe = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (<>
    <PageTitle title={recipe?.name} />
    <hr className='cnow-hr' />
    <PodcastEditor id={props.id} type='recipe' key={`podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
 



  </>);
};

export default RecipePodcast;