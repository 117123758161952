/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useId } from '@reach/auto-id';

import OpenSeadragon from 'openseadragon';

import LinkButton from '../../components/buttons/link';

const ViewerDzi = (props) => {
    const image = useSelector(state => state[props.match.params.id]);

    const id = useId();
    const viewerRef = useRef(null);

    useEffect(() => {
        if (id && image?.url) {
            let parts = image.url.split('.');
            let ext = parts[parts.length - 1];
            let url = image.url.replace(`.${ext}`, '.dzi');

            const viewer = OpenSeadragon({
                id,
                tileSources: url,
                showFullPageControl: false,
                showHomeControl: false,
                showZoomControl: false,
                showNavigationControl: false,
                showNavigator: true,
                navigatorPosition: 'TOP_LEFT',
                navigatorHeight: '100px',
                navigatorWidth: '125px',
            });

            viewerRef.current = viewer;

            return () => viewer.destroy();
        }
    }, [id]);

    return (
        <div>
            <div className='dzi-controls'>
                <LinkButton size='sm' icon='close' target='_self' to={`/image/${image.id}/editor`} />
            </div>

            <div id={id} className='dzi-container' />
        </div>
    )
}

export default ViewerDzi;