import React, { useState } from 'react';
import ImageViewer from '../viewers/image';
const MIN_LENGTH = 3
const RelatedSiteList = ({ data, type }) => {
    const [length, setLength] = useState(MIN_LENGTH);
    const [more, showMore] = useState(false);
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <div className='fw-bold fst-italic'>Related Sites</div>

                        {
                            data.length > MIN_LENGTH &&
                            <div>
                                {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                                {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
                            </div>
                        }
                    </div>
                    {
                        data.slice(0, length).map((item, index) => {
                            return <div key={index} className='mb-2'>
                                {/* <a href={`${process.env.REACT_APP_WEB_URL}/site/${type === 'site' ? item?.related_site_id : item?.site_id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none text-dark'>{item?.related_site_name || item?.site_name}</a> */}
                                {
                                    item.image_url && <>
                                    <a href={`${process.env.REACT_APP_WEB_URL}/site/${type === 'site' ? item?.related_site_id : item?.site_id}`} target='_blank' rel="noopener noreferrer" className='text-decoration-none text-dark'> <ImageViewer url={item?.image_url} type='thumb' cssClass='thumb' /> </a> 
                                    </>
                                }
                                {/* {
                                    item.description && <>
                                        <span className='mx-1'>-</span><span dangerouslySetInnerHTML={{ __html: cleanHtml(item.description) }} />
                                    </>
                                } */}

                            </div>
                        })
                    }
                </>
            }
        </>
    )
}

export default RelatedSiteList;