import React, { useState } from 'react';
import swal from 'sweetalert';
import Button from '../buttons/default';
import ImageViewer from '../viewers/image';
import PreviewButton from '../buttons/preview';
import { useHistory, useLocation } from 'react-router-dom';
import PodcastUpload from '../../pages/podcast/upload';
import { useSelector, useDispatch } from 'react-redux';

const EntityPodcast = (props) => {
  const entity = useSelector((state) => state[props.id]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showUpload, setShowUpload] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const removeItem = (id) => {
    swal({
      title: `Remove Podcast?`,
      text: `Are you sure that you want to remove this podcast?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then((status) => {
      if (status) props.onDelete(id)
    })
  }

  const handleEdit = (route) => {
    history.push({ pathname: route })
    dispatch({ type: 'podcasts', payload: { entityPodcasts: entity.podcasts, source: location.pathname } })
  }

  const handleOpenUploader = () => {
    dispatch({ type: 'podcasts', payload: [] })
    setShowUpload(true)
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-end mb-2'>
        <div>
          {(props?.type === 'playlist' || props?.type === 'tour') ?
            <div className='cnow-form-title'>{`${props.title} * (Total ${props?.items?.length} podcasts)`}</div> :
            <div className='cnow-form-title'>{props.title}</div>}
        </div>
        <div>
          {props?.type === 'playlist' ? <Button size='md' icon='plus' label='Add one more' onClick={() => handleOpenUploader()} /> : <Button size='md' icon='plus' label='Add a Podcast' onClick={() => handleOpenUploader()} />}
        </div>
      </div>
      <div className='table'>
        <div className='d-flex bg-light small py-2 ps-2'>
          <div style={{ width: '70px' }} className='fw-bold'>Order</div>
          <div style={{ width: '90px' }} className='fw-bold'>Media</div>
          <div className='flex-grow-1 fw-bold'>Podcast, Title, Credits</div>
          <div className='text-end' style={{ width: '150px' }}>
            {props.items?.length} Podcasts
            {showTable === true && <i className='fe fe-chevron-down ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setShowTable(false)} />}
            {showTable === false && <i className='fe fe-chevron-up ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setShowTable(true)} />}
          </div>
        </div>
        {
          showTable &&
          <div id={props.dragulaId} key={`${props.dragula}${props.cssId}`} className='dragula-container'>
            {
              props.items?.map((item, index) => {
                return (
                  <div key={item.id + index} id={item?.id} className={`${item?.id} text-left dragula-item d-flex`}>
                    <div style={{ width: '70px' }}>
                      <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={props?.offsetIndex !== undefined ? props.offsetIndex + index+ 1 : index + 1} autoComplete='off' />
                    </div>
                    <div style={{ width: '90px' }}>
                      <div className='position-relative'>
                        {item?.podcast_image_url &&  <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                          {item.podcast_type === 'audio' ? <img src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%'}} alt="playlist" />
                          : <img src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%'}} alt="playlist" />}
                        </div>}
                        <ImageViewer url={item?.podcast_image_url} type='thumb' cssClass='thumb' />
                      </div>
                    </div>
                    <div className='text-start small flex-grow-1'>
                      <div className='bg-light fw-bold'>{item?.podcast_name} </div>
                      <div>by {item?.podcast_credits}</div>
                    </div>
                    <div className='text-end' style={{ width: '150px' }}>
                      <div className='d-flex gap-1 justify-content-end'>
                        <PreviewButton size='md' icon='preview' link={`podcast/${item?.podcast_id}`} />
                        <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${item?.podcast_id}/editor`) }} />
                        <Button size='md' icon='delete' onClick={() => removeItem(item?.id)} />
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        }
      </div>

      {
        showUpload && (
          <PodcastUpload show={true} entityId={props?.id} onlyFromDB={(props.onlyFromDB === true || props.type === 'playlist') ? true : false}
            onHide={() => { setShowUpload(false) }} onSave={(items) => props.onUpdate(items)}
          />
        )
      }
    </>
  );
};

export default EntityPodcast;
