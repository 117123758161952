import React, { useEffect, useRef, useState } from "react";

const ThenNow = (props) => {
  const [images] = useState(props.image ? [ ...props.image.images].reverse() : [])
  const [imageWidth, setImageWidth] = useState(0);
  const [sliderX, setSliderX] = useState([]);
  const containerDiv = useRef();

  useEffect(() => {
    let temp = [];
    if (images?.length > 1) {
      let fullWidth = containerDiv.current.clientWidth
      let singleWidth = containerDiv.current.clientWidth / images?.length;
      let sliderWidth = fullWidth - singleWidth;
      temp.push({ width: sliderWidth });

      for (let i = 1; i < images?.length; i++) {
        if (i > 1) {
          sliderWidth = sliderWidth - singleWidth; 
          temp.push({ width: sliderWidth });
        }
      }
    }
    setSliderX(temp);
    setImageWidth(containerDiv.current.clientWidth);
  }, [images]);

  const handleDrag = (e, index) => {
    if (e.clientX > 0) {
        let copySlider = [...sliderX]
        copySlider[index].width = e.clientX 
        setSliderX(copySlider)
    };
  };

  function handleDragStart(e) {
    e.dataTransfer.setDragImage(new Image(), window.outerWidth, window.outerHeight);
  }
  
  return (
    <div className='then-now-container' ref={containerDiv}>
      {images?.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              position: "fixed",
              width:
                index === 0
                  ? imageWidth
                  : sliderX.length && sliderX[index - 1]?.width,
              overflow: index === 0 ? "visible" : "hidden",
              height: 'inherit'
            }}
          >
            <div style={{ width: imageWidth, height: '100%'  }}>
              <img src={`${process.env.REACT_APP_API_URL}public/image/image/${item.related_image_id}/original`} style={{ maxWidth: "100%", maxHeight: "100%"}} alt={item?.alt_text || ''} />
            </div>
          </div>
        );
      })}
      <div className="slider-handle-section">
      {sliderX && sliderX.length && sliderX.map((item, index) => {
          return(<div
            key={index}
            id='slider'
            className='slider-handle'
            style={{ left: item.width }}
            draggable='true'
            onDrag={(e) => handleDrag(e, index)}
            onDragStart={handleDragStart}
          >
            <div className='slider-handle-line'></div>
            <div className='slider-handle-button'>
              <div className='arrow-left'></div>
              <div className='arrow-right'></div>
            </div>
            <div className='slider-handle-line'></div>
          </div>)
      })}
      </div>
    </div>
  );
};

export default ThenNow;
