/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import TableHeader from '../../../components/tableHeader';
// import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
// import ImageViewer from '../../../components/viewers/image';
// import PreviewButton from '../../components/buttons/preview';
// import swal from 'sweetalert';
import { checkUserTypeAndReturnIds } from '../../../helpers';
import { useSelector } from 'react-redux';
import DynamicTable from '../../../components/DynamicTable';
const _api = require('../../../api');
const columns = require('../../../data/columns_users.json');

const Users = (props) => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);
    const [count, setCount] = useState('')

    useEffect(() => {
        search()
    }, [page, sort, keyword, user.id]);

    const search = async () => {
        showSpinner(true)
        setSearchResult([])
        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                ]
            }
        };
        if (keyword) {
            sortBoolean = false
            params.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": [
                        "name^3",

                    ],
                    "fuzziness": 2,
                    "prefix_length": 2
                }
            });
        }


        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }
        await _api.user.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
            const mappedHits = results?.hits?.hits.map(hit => hit._source);
            setSearchResult(mappedHits)
            setCount(results?.hits?.total?.value)
            showSpinner(false)
        })
    }

    // const handleDelete = (user) => {
    //     swal({
    //         title: `Delete Event`,
    //         text: `Are you sure that you want to delete ${user?.name}?`,
    //         buttons: ['Cancel', 'Yes'],
    //         icon: 'warning',
    //         dangerMode: true,
    //     }).then((status) => {
    //         _api.user.deleteById(user.id).then(() => { search() })
    //     });
    // }

    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add a User' color='danger' icon='plus-white' onClick={() => history.push('/user/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0'>Users</h4>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={count} />
                                    {
                                        keyword && <span className='badge badge-search'>
                                            {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                                        </span>
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={count} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td colSpan={columns.filter((column) => column.display === true).length} className='p-0'>
                                            <h5 className="cnow-bg-red text-white py-0 ps-1" style={{ width: '100%' }}>
                                                Curator
                                            </h5>
                                        </td>
                                    </tr>

                                    <DynamicTable searchResult={searchResult} search={search} type="CURATOR" />

                                    <tr>
                                        <td colSpan={columns.filter((column) => column.display === true).length} className='p-0'>
                                            <h5 className="cnow-bg-red text-white py-0 ps-1" style={{ width: '100%' }}>
                                                Explorer
                                            </h5>
                                        </td>
                                    </tr>

                                    <DynamicTable searchResult={searchResult} search={search} type="EXPLORER" />


                                    <tr>
                                        <td colSpan={columns.filter((column) => column.display === true).length} className='p-0'>
                                            <h5 className="cnow-bg-red text-white py-0 ps-1" style={{ width: '100%' }}>
                                                Publisher
                                            </h5>
                                        </td>
                                    </tr>

                                    <DynamicTable searchResult={searchResult} search={search} type="PUBLISHER" />

                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={searchResult.nbHits} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default Users;
