/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uniqBy } from 'lodash';
import PlaylistMenu from './menu';
import PlaylistPreview from './preview';
import PlaylistTitle from './title';
import PlaylistPeople from './people';
import PlaylistDescription from './description';
import PlaylistImages from './image';
import PlaylistMeta from './meta';
import PlaylistPodcast from './podcast';
import Spinner from '../../components/spinner';
import CuratorManagement from './curator';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import EventsTours from './eventsTours';
import PlaylistSpecialFeature from './specialFeature';
import { toast } from 'react-toastify';
const _api = require('../../api');

const PlaylistEditor = (props) => {
  const id = props.match.params.id || 'NEW';
  const section = props.match.params.section || 'title';
  const sectionId = props.match.params.sectionId || '';
  const { id: userId, parent_id: parentId } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const playlist = useSelector(state => state[id]);
  const menuSize = useSelector((state) => state.menu);
  const previewSize = useSelector((state) => state.preview);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    if (id === 'NEW') {
      dispatch({
        type: 'entity', payload: {
          'id': 'NEW',
          'id_ext': '',
          'url': '',
          'name': '',
          'type': '',
          'description': '',
          'duration': '',
          'meta': {},
          'deleted': false,
          'live': false,
          'categories': [],
          'themes': [],
          'images': [],
          'thumbnail': [],
          'user_id': parentId || userId
        }
      })
    } else {
      getPlaylistData()
    }
  }, [sectionId, section]);

  useEffect(() => {
    return () => {
      const data = JSON.parse(localStorage.getItem('cnow-cms'))
      delete data[id]
      localStorage.setItem('cnow-cms', JSON.stringify(data))
    }
  }, [])


  const getPlaylistData = async () => {
    showSpinner(true);
    let myPlaylist = await _api.playlist.getById(id)

    if (section === 'title' || !section) {
      myPlaylist.themes = await _api.playlist.getTheme(myPlaylist.id)
      myPlaylist.categories = await _api.playlist.getCategory(myPlaylist.id)
    }

    if (section === 'description') {
      myPlaylist.tags = await _api.playlist.getTag(myPlaylist.id) || []
      myPlaylist.related_sites = await _api.playlist.getRelatedSite(myPlaylist.id)
    }

    if (section === 'attach_map') {
      myPlaylist.maps = await _api.playlist.getMap(myPlaylist.id)
    }

    if (section === 'people') {
      myPlaylist.people = await _api.playlist.getPerson(myPlaylist.id)
      myPlaylist.related_people = await _api.playlist.getRelatedPerson(myPlaylist.id)
    }

    if (section === 'events_tours') {
      myPlaylist.related_tours = await _api.playlist.getTour(myPlaylist.id)
      myPlaylist.related_events = await _api.playlist.getEvent(myPlaylist.id) || []
      myPlaylist.related_event_series = await _api.playlist.getEventSeries(myPlaylist.id) || []
    }

    if (section === 'images') {
      // myPlaylist.images = await _api.playlist.getImage(myPlaylist.id)
      myPlaylist.podcasts = await _api.playlist.getPodcast(myPlaylist.id)
      myPlaylist.playlists = await _api.playlist.getRelatedPlaylist(myPlaylist.id)
      myPlaylist.curator_playlist = []
      for (let i in myPlaylist.curator) {
        let res = await _api.person.getMorePlaylist(myPlaylist.curator[i].person_id)
        myPlaylist.curator_playlist = myPlaylist.curator_playlist.concat(res)
      }
      myPlaylist.curator_playlist = uniqBy(myPlaylist.curator_playlist, 'id')
      myPlaylist.curator_playlist = myPlaylist.curator_playlist?.filter(x => x.playlist_id !== myPlaylist.id)

      myPlaylist.thumbnail = myPlaylist.thumbnail || []
      myPlaylist.images = myPlaylist.images || []

      //get all images from podcast and uploaded images
      let pods = myPlaylist.podcasts?.map((item, index) => ({
        id: item.podcast_id,
        name: item.podcast_name,
        type: 'podcast',
        url: item.podcast_image_url,
        selectOrder: 0,
        podcastOrder: index + 1
      })) || [];

      let imgs = myPlaylist.images?.map(item => ({
        id: item.id,
        name: item.caption,
        type: 'image',
        url: item.url,
        selectOrder: 0,
        podcastOrder: 0
      })) || [];

      //all images
      myPlaylist.allImages = [...pods, ...imgs]

      //set initial playlist image 
      if (myPlaylist.thumbnail?.length < 10) {
        myPlaylist.thumbnail = await getThumbnailImages(myPlaylist) || []
      }

      //set thumbnail / headshot if its not present or podcasts data modified or deleted
      let podIdsInThumb = myPlaylist.thumbnail?.filter(x => x.type === 'podcast' && x.url)?.map(x => x.url)
      podIdsInThumb = uniqBy(podIdsInThumb)
      let podIds = pods?.filter(x => x.url)?.map(x => x.url)

      const samePods = podIdsInThumb.filter(t => { return podIds.includes(t) })

      if ((samePods.length === podIdsInThumb.length) !== true || myPlaylist.podcasts?.length === 0) {
        myPlaylist.thumbnail = await getThumbnailImages(myPlaylist) || []
      }

      //set default selected
      let data = [...myPlaylist.thumbnail]
      myPlaylist.selected = data?.slice(0, 4) || []
      myPlaylist.selected = uniqBy(myPlaylist.selected, 'id')
      myPlaylist.selected.forEach((item, i) => {
        item.selectOrder = i + 1
      })

      // Process thumnail/headshot 
      if (myPlaylist.thumbnail?.length === 10) {
        const parts = myPlaylist.thumbnail.map(x => x.url)
        const params = {
          id: myPlaylist.id,
          type: 'playlist',
          parts: parts
        }
        await _api.image.process(params)
      }
    }

    if (section === 'podcasts') {
      myPlaylist.podcasts = await _api.playlist.getPodcast(myPlaylist.id)
      myPlaylist.playlists = await _api.playlist.getRelatedPlaylist(myPlaylist.id)
      myPlaylist.curator_playlist = []
      for (let i in myPlaylist.curator) {
        let res = await _api.person.getMorePlaylist(myPlaylist.curator[i].person_id)
        myPlaylist.curator_playlist = myPlaylist.curator_playlist.concat(res)
      }
      myPlaylist.curator_playlist = uniqBy(myPlaylist.curator_playlist, 'playlist_id')
      myPlaylist.curator_playlist = myPlaylist.curator_playlist?.filter(x => x.playlist_id !== myPlaylist.id)
    }

    if (section === 'special_features') {
      myPlaylist.special_features = await _api.playlist.getPlaylistRecipe(id)
    }

    if (section === 'curator') {
      myPlaylist.podcasts = await _api.playlist.getPodcast(id)
      myPlaylist.curator = await _api.playlist.getCurator(id)
    }

    dispatch({ type: 'entity', payload: myPlaylist })
    showSpinner(false);
  }

  const updateAndRedirect = async (path) => {
    if (user.id) {
      playlist.created_via = "ADMIN"
    }

    if (!path) {
      path = section;
      if (sectionId) path = path.concat('/', sectionId);
    }

    showSpinner(true);
    if (playlist.id === 'NEW') {
      playlist.id = null
      playlist.modified_by = null
    } else {
      playlist.modified_by = user.id
    }

    let isValidVanityUrl = true;

    if (section === 'meta') {
      const { isValid, message } = await _api.vanity_url.validateVanityUrl({ vanity_url: playlist.meta.vanity_url, id: playlist.id, entity: "playlist" })
      isValidVanityUrl = isValid;
      if (!isValid) toast.error(message);
      showSpinner(false);
    }

    let myPlaylist;

    if (isValidVanityUrl) {
      myPlaylist = await _api.playlist.update(playlist)
    }

    if (section === 'title' || !section) {
      myPlaylist.themes = await _api.playlist.updateTheme(myPlaylist.id, playlist.themes)
      myPlaylist.categories = await _api.playlist.updateCategory(myPlaylist.id, playlist.categories)
    }

    if (section === 'description') {
      myPlaylist.tags = await _api.playlist.updateTag(myPlaylist.id, playlist.tags)
      if (playlist.related_sites?.length > 0) {
        let relatedSites = []
        for (const item of playlist.related_sites) {
          let response = await _api.site.updatePlaylist(item.site_id, [item])
          relatedSites.push(response[0])
        }
        myPlaylist.related_sites = relatedSites
      }
    }

    if (section === 'podcasts') {
      myPlaylist.podcasts = await _api.playlist.updatePodcast(myPlaylist.id, playlist.podcasts)
      myPlaylist.playlists = await _api.playlist.updateRelatedPlaylist(myPlaylist.id, playlist.playlists)
    }

    if (section === 'people') {
      myPlaylist.people = await _api.playlist.updatePerson(myPlaylist.id, playlist.people)
      if (playlist.related_people?.length > 0) {
        let relatedPeople = []
        for (const item of playlist.related_people) {
          let response = await _api.person.updatePlaylist(item.person_id, [item]) || []
          relatedPeople.push(response[0])
        }
        myPlaylist.related_people = relatedPeople
      }
    }

    if (section === 'events_tours') {
      myPlaylist.related_tours = await _api.playlist.updateTour(myPlaylist.id, playlist.related_tours)
      myPlaylist.related_events = await _api.playlist.updateEvent(myPlaylist.id, playlist.related_events)
      myPlaylist.related_event_series = await _api.playlist.updateEventSeries(myPlaylist.id, playlist.related_event_series)
    }

    if (section === 'images') {
      // await _api.playlist.updateImage(id, playlist.images)
      myPlaylist.podcasts = await _api.playlist.updatePodcast(myPlaylist.id, playlist.podcasts)
    }

    if (section === 'attach_map') {
      if (playlist.maps?.length > 0) {
        let playlistMaps = []
        for (const item of playlist.maps) {
          let response = await _api.map.updatePlaylist(item.map_id, [item])
          playlistMaps.push(response[0])
        }
        myPlaylist.maps = playlistMaps
      }
    }
    if (section === 'special_features') {
      myPlaylist.special_features = await _api.playlist.updatePlaylistRecipe(myPlaylist.id, playlist.special_features)
    }

    if (section === 'curator') {
      myPlaylist.curator = await _api.playlist.updateCurator(myPlaylist.id, playlist.curator)
    }

    dispatch({ type: 'entity', payload: myPlaylist })
    history.push(`/playlist/${myPlaylist.id}/editor/${path}`)
    showSpinner(false);
  }

  const getThumbnailImages = async (myPlaylist) => {
    //set thumbnail(10) 
    let data = [...myPlaylist.allImages]
    data = data.filter(x => x.url) || []
    if (data?.length) {
      let times = Math.ceil(10 / data.length)
      myPlaylist.thumbnail = new Array(times).fill(data).flat()
      myPlaylist.thumbnail = myPlaylist.thumbnail?.slice(0, 10)
    } else {
      myPlaylist.thumbnail = []
      myPlaylist.url = null
    }
    const res = await _api.playlist.update(myPlaylist);
    return res.thumbnail;
  }

  return (
    <div className='cnow-editor-container'>
      {playlist && <>
        <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
          <PlaylistMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
        </div>
        <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
          <Spinner display={spinner}>
            {section === 'title' && <PlaylistTitle id={id} />}
            {section === 'people' && <PlaylistPeople id={id} />}
            {section === 'description' && <PlaylistDescription id={id} />}
            {section === 'images' && <PlaylistImages id={id} />}
            {section === 'meta' && <PlaylistMeta id={id} />}
            {section === 'podcasts' && <PlaylistPodcast id={id} />}
            {section === 'events_tours' && <EventsTours id={id} />}
            {section === 'special_features' && <PlaylistSpecialFeature id={id} />}
            {section === 'attach_map' && <RelatedMapEditor id={id} type='playlist' title='Related Map' buttonLabel='Add One More' />}
            {section === 'curator' && <CuratorManagement id={id} />}
          </Spinner>
        </div>
        <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
          <PlaylistPreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} display={spinner} />
        </div>
      </>}
    </div>
  );
};

export default PlaylistEditor;