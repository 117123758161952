
import React, { useEffect, useState } from 'react'

import ImageViewer from '../../components/viewers/image';
// import { useHistory, useLocation } from 'react-router-dom';
import LinkButton from '../buttons/link';
const _api = require('../../api')
const RelatedSiteList = (props) => {
    const [images, setImages] = useState(props.data)

    useEffect(() => {
        getImageDetails()
        // eslint-disable-next-line
    }, [])

    const getImageDetails = async () => {
        let copy = [...images]
        for (let i in copy) {
            copy[i].image = copy[i].image_id && await _api.image.getById(copy[i].image_id)
        }
        setImages(copy)
    }

    return (
        <div className='py-2 '>
            <table className='table'>
                <thead className='bg-light'>
                    <tr className='small'>
                        <td style={{ width: '70px' }}>Order</td>
                        <td style={{ width: '90px' }}>Media</td>
                        <td><div>{props.title}, Image caption, Image credits</div></td>
                        <td style={{ width: '50px' }}></td>
                    </tr>
                </thead>
            </table>
            <div>
                {images?.map((item, index) => {
                    return (
                        <div className='row g-2 mt-2 ' key={index}>
                            <div className='col-auto'>
                                <input type='text' className='form-control form-control-sort fw-bold' disabled name='sort' value={props.offsetIndex === undefined ? index + 1 : props.offsetIndex + index + 1} autoComplete='off' />
                            </div>
                            <div className='col-auto'>
                                <ImageViewer key={item?.id} url={item?.image?.url || item?.image_url} type='thumb' cssClass='thumb' />
                            </div>
                            <div className='text-start small col'>
                                <div className='cnow-preview-title fw-bold p-2'>{item?.site_name ? item?.site_name : item?.related_person_name}</div>
                                <div className='bg-light ps-2 fw-bold'>{item?.caption || ' '}</div>
                                <div className='bg-light ps-2'>
                                    {item?.image_credits ? `©${item?.image_credits}` : ''}
                                </div>
                            </div>
                            <div className='col-auto'>
                                <div className='btn-list'>
                                    <LinkButton icon='edit' to={`/${props.type === 'site' ? 'site' : 'people'}/${item?.site_id ? item?.site_id : item?.related_person_id}/editor`} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>)
}

export default RelatedSiteList 