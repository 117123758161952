import React from 'react';

const PageTitle = (props) => {
    return (
        <div className='cnow-page-title'>
            {props.title} {props.subtitle &&
                <span className='mx-1'>-
                    <span className='small cnow-text-primary mx-1'>{props.subtitle}</span>
                   
                </span>
            }
        </div>
    )
}

export default PageTitle;