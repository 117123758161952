/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanHtmlMeta } from '../../helpers/index'


const MetaDescriptionEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const dateDescription = entity.dates ? entity.dates.filter((e) => e.description !== '') : []

    useEffect(() => {
        if (!entity.meta.metaUpdated) {
            if (entity.description) {
                entity.meta.description = cleanHtmlMeta(entity.description)
            }
            else if (dateDescription.length > 0) {
                if (dateDescription[0]?.description !== undefined) {
                entity.meta.description = cleanHtmlMeta(dateDescription[0]?.description)
            }
            }
            dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } })
        }
    }, [])

    const handleInputChange = ({ target: { name, value } }) => {
        entity.meta[name] = value;
        entity.meta.metaUpdated = true;
        dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
    }

    return (
        <>
            <div className='row mt-2'>
                <div className='col'>
                    <label className='cnow-form-title'>Meta Description</label>
                </div>
                <div className='col-auto'>
                    <label className='cnow-form-word-count'>
                        {entity.meta?.description ? `${entity.meta?.description?.length} of 160 characters` : `Limit 160 characters`}
                    </label>
                </div>

            </div>
            <textarea name='description' type='textarea' rows='2' className='form-control' value={entity.meta?.description ? entity.meta?.description : cleanHtmlMeta(entity.description)} onChange={handleInputChange} />
        </>
    )
}

export default MetaDescriptionEditor