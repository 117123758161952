import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import Name from '../../components/preview/name';
import PeopleList from '../../components/preview/peopleList';
import Description from '../../components/preview/description';
import PodcastList from '../../components/preview/podcastList';
import Playlist from '../../components/preview/playlist';
import EventList from '../../components/preview/eventList';
import RecurringEventList from '../../components/preview/recurringEventList';
import TourList from '../../components/preview/tourList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import MetaDescription from '../../components/preview/metaDescription';
import TagList from '../../components/preview/tagList';
import PodcastPlaylist from '../../components/preview/podcastplaylist';
import { getPodcastsDuration } from '../../helpers';
import RelatedMapList from '../../components/preview/relatedMapList';
import MapsList from '../../components/preview/mapsList';
import swal from 'sweetalert'
const _api = require('../../api')

const PlaylistPreview = (props) => {
	const dispatch = useDispatch();

	const playlist = useSelector(state => state[props.id]);
	const previewSize = useSelector((state) => state.preview);
	const [podcastDuration, setDuration] = useState([]);

	useEffect(() => {
		setDuration(getPodcastsDuration(playlist.podcasts))
		// eslint-disable-next-line
	}, [playlist?.podcasts?.length])

	const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

	const handleLive = (playlistId) => {
		if (
			playlist?.name &&
			playlist?.categories &&
			playlist?.categories?.[0] &&
			playlist?.description &&
			playlist?.url &&
			playlist?.podcasts?.length
		) {
			_api.playlist.updateLive(playlistId, true).then((_) => {
				swal({
					title: 'Playlist is Live!',
					icon: 'success',
					text: `${playlist?.name} is now Live!`,
					buttons: 'Ok',
				})
				dispatch({ type: 'entity', payload: { ...playlist, live: true } })
			})
		} else {
			swal({
				title: `${playlist.name} cannot go Live!`,
				icon: 'error',
				text: `Please fill the mandatory fields:
          ${playlist?.name ? '' : 'Title is missing.'}
          ${playlist?.categories && playlist?.categories?.[0] ? '' : 'Category is missing.'} 
          ${playlist?.description ? '' : 'Description is missing.'} 
          ${playlist?.url ? '' : 'Image is missing.'} 
		  ${playlist?.podcasts?.length > 0 ? '' : 'Podcast is missing.'} 
		  `,
				buttons: 'Go back',
				dangerMode: true,
			})
		}
	}

	const handleNotLive = (playlistId) => {
		_api.playlist.updateLive(playlistId, false).then((_) => {
			swal({
				title: 'Not Live!',
				icon: 'info',
				text: `${playlist?.name} is Not Live.`,
				buttons: 'Ok',
			})
			dispatch({ type: 'entity', payload: { ...playlist, live: false } })
		})
	}

	return (
		<>
			<>
				{
					previewSize === 'sm' && <div className='btn-grid'>
						<Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' color='light' link={`playlist/${playlist.vanity_url || playlist.id}`} />

						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

						<Button size='sm' color='light' icon='help' />
					</div>
				}

				{
					previewSize === 'lg' && <div className='btn-list action-bar'>
						<Button size='sm' color='light' label='Instructions' />
						{playlist?.live ? (<Button size='sm' color='light' label='Live' onClick={() => handleNotLive(playlist.id)} />) :
							(<Button size='sm' color='danger' label='Not Live' onClick={() => handleLive(playlist.id)} />)}
						<Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' color='light' link={`playlist/${playlist.vanity_url || playlist.id}`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
					</div>
				}
			</>

			<div className='preview'>
				{
					previewSize === 'lg' && <>
						{props.section !== 'images' && <div className='cnow-bg-red text-white mt-2 fs-5'>PREVIEW</div>}

						{
							playlist && (props.section !== 'meta' && props.section !== 'podcasts' && props.section !== 'images') && <>
								<Name data={playlist.name} />
								<div className='text-muted fw-bold'>Playlist</div>
								<PeopleList data={playlist.people?.length > 0 ? playlist.people : []} title="playlist" />
								<Description data={playlist?.description} />
								<PodcastList data={playlist.podcasts} />
								<EventList data={playlist.events} />
								<RecurringEventList data={playlist.events} />
								<TourList data={playlist.tours} />
								<RelatedPeopleList data={playlist.related_people} />
								<RelatedSiteList data={playlist.related_sites} />
								<RelatedMapList data={playlist?.maps} />
								<Playlist data={playlist.playlists} />
								{/* <LocationList data={playlist.locations} /> */}
								<MapsList data={playlist?.maps} />
								<TagList tags={playlist.tags} themes={playlist.themes} categories={playlist.categories}
									entity={'playlist'} />
							</>
						}

						{
							playlist && props.section === 'meta' &&
							<MetaDescription data={playlist.meta} id={playlist.id} type='playlist' />
						}

						{
							playlist && props.section === 'podcasts' &&
							<>
								<Name data={playlist.name} />
								<div className='text-muted fz-12'>Playlist</div>
								<div className='text-muted fz-12'>{playlist.podcasts?.length ? playlist.podcasts?.length + ' Podcasts,' : '0 Podcasts'} {playlist.podcasts?.length ? podcastDuration + ' Minutes' : " "}</div>
								<PodcastPlaylist data={playlist} type='podcast' />
							</>
						}

						{
							playlist && props.section === 'images' &&
							<>
								<PodcastPlaylist data={playlist} type='image' />
							</>
						}
					</>
				}
			</div>
		</>
	);
};

export default PlaylistPreview;