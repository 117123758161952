import React, { useState, useEffect } from 'react'
import FeaturedTitle from './title';
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import StaticImageCard from '../../components/featured/staticImagesCard';
import StaticContentCard from '../../components/featured/staticContentCard';
import CollectionImageCard from '../../components/featured/collectionsImageCard';
import { personName } from '../../helpers';

const People = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [peopleData, setPeopleData] = useState([])
    const [display, setDisplay] = useState(false)
    const [isContentEdit, setIsContentEdit] = useState(false);

    const searchPeople = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const people = featured.people || []

    // eslint-disable-next-line
    useEffect(() => { formatPeople(); if (!people[0]?.data?.description && !people[0]?.data?.title) setTitleDescription() }, [people?.length])

    const formatPeople = () => {
        let data = []
        if (people)
            for (let i in people) {
                if (people[i]?.data?.people) {
                    for (let j in people[i].data.people) {
                        people[i].data.people[j].section_index = i
                        people[i].data.people[j].entity_index = j
                        data.push(people[i].data.people[j])
                    }
                }
            }
        setPeopleData(data)
    }

    const setTitleDescription = () => {
        if (people[0]?.data?.people.length > 0) {
            people[0].data.title = 'People/Organizations/Publications'
            people[0].data.description = '90 Collections of public art in the countru, 16412 works of public art. To explain them we have 1412 podcasts and 12 walking tours. Our largest collections are City of Albuquerque Public Art Program has 780 Boston. An Commission wit 416 and 4 major collections in New York City which have together over 277 artworks.'
            dispatch({ type: 'featured', payload: { ...featured, people: people } })
        }
    }

    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchPeople, people: peopleData, selectedResult: [], title: `HOMEPAGE - What's in the Collection - People`, filterLive: true, selectedLimit: 2 } })
        history.push(`/featured/searchList/people/person`)
    }

    const handleInputChange = (value, type, id) => {
        if (type === 'name') {
            people[0].data.title = value
        }
        if (type === 'description' && value) {
            people[0].data.description = value
        }
        people[0].edited = true
        dispatch({ type: 'featured', payload: { ...featured, people: people } })
    }

    const handleDeletePeople = () => {
        setDisplay(true)
        let id_index = peopleData[editedIndex].section_index
        let id_person_index = peopleData[editedIndex].entity_index
        people[id_index].edited = true
        people[id_index].data.people.splice(id_person_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, people: people } })
        setDisplay(false)
        //to refresh Call function Again
        formatPeople()
        setEditedIndex(editedIndex - 1)
    }

    return (
        <>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="PEOPLE"
                        count={peopleData.length > 1 ? `${peopleData?.length} items` : `${peopleData?.length} item`}
                        page={props?.page}
                        button={{
                            // set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            // setIsEdited(true);
                            edit: { show: true, onHandle: () => { handleClick(0) } },
                            // add: { show: true, onHandle: () => { } },
                        }}
                    >
                        <Spinner display={display}>
                            <div className='row'>
                                {peopleData.map((item, index) => (
                                    <div className={`mt-2 col-auto`} key={`people-${index}`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') handleClick(index) }}>
                                        <StaticImageCard data={item} />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                        {peopleData.length > 0 && <div className='mt-2' onClick={(e) => { if (props?.page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                            <StaticContentCard data={people[0]?.data} />
                        </div>}
                    </CollapsableSection>
                    {(editedIndex !== -1) && peopleData.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/people/${peopleData[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (true), onHandle: () => { handleDeletePeople(); props.onUpdate(); } }
                        }}>
                        <CollectionImageCard key={`image-${editedIndex}`} data={peopleData[editedIndex]} name={personName(peopleData[editedIndex])} designation={peopleData[editedIndex]?.affiliations?.[0]?.person_position} />
                    </CollapsableSection>}

                    {isContentEdit && people[0]?.data.description && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: (peopleData.length > 0), onHandle: () => { setIsContentEdit(false); props.onUpdate(); } },
                        }}>

                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={people[0]?.data.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={people[0]?.data.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"
                                    key={`TextEditor${editedIndex}`}>
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </>
    )
}

export default People