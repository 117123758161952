import React from 'react'
import { useSelector } from 'react-redux'
import ImageViewer from '../viewers/image';
import { dateString, personName } from '../../helpers';
const OnThisDay = (props) => {
    const entity = useSelector(state => state[props.id]);
    const getType = (value) => {
        let type = ''
        if (value.award_id) {
            type = 'Award'
        } else if (value.designation_id) {
            type = 'Designation'
        } else if (value.story_id) {
            type = 'Story'
        } else {
            type = 'Date'
        }
        return type;
    }
    const getEntityName = () => {
        if (props.type === 'person') return personName(entity)
        return entity.name;
    }
    const getDescription = (item) => {
        return item[`${getType(item).toLowerCase()}_description`];
    }
    const getName = (item) => {
        let type = getType(item);
        if (type === 'Date') {
            return item.event_type_name;
        }
        return item[`${type.toLowerCase()}_name`];
    }
    return (
        <>
            {
                entity.on_this_day?.length > 0 && entity.on_this_day.map((item, index) => {
                    return (
                        <div className='py-2'>
                            <ImageViewer key={item.id} entityType='image' entityId={item.image_id || entity.image_id} type='thumb' cssClass='cnow-onthisday-img' />
                            <div>
                                <span className='fw-bold' >
                                    {dateString(item) && <><span>{dateString(item)}</span> - </>}<span>{getEntityName()}</span>{getName(item) && <> - <span>{getName(item)}</span></>}
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: getDescription(item) }} />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default OnThisDay