import React, { useState, useEffect, useRef } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import FeaturedCard from '../../components/featured/featuredCard';
import TextEditor from '../../components/editors/textEditor';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dateString, handleDragDropReorder } from '../../helpers';
import FeaturedTitle from './title';
import ImagePreview from '../../components/featured/editInfo/imagePreview';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'

const InThisPhoto = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [inThisPhoto, setInThisPhoto] = useState([])
    const [display, setDisplay] = useState(false)
    const searchInThisPhoto = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const in_this_photo = featured.in_this_photo || []
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (props?.page !== 'home') ? 'dragula-container-in-this-photo' : ''
    useEffect(() => {
        handleDragDrop(in_this_photo?.[0]?.data?.in_this_photo || [])
        // eslint-disable-next-line
    }, [dragula])
    const handleDragDrop = (inThisPhoto) => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            setEditedIndex(-1)
            const items = handleDragDropReorder(inThisPhoto, dragulaContainer)
            in_this_photo[0].edited = true
            in_this_photo[0].data.in_this_photo = items
            dispatch({ type: 'featured', payload: { ...featured, in_this_photo: in_this_photo } })
            setDragula(uniqid())
        })
    }
    // Dragula End

    // eslint-disable-next-line
    useEffect(() => { formatInThisPhoto() }, [in_this_photo?.length, dragula])

    const formatInThisPhoto = () => {
        let data = []
        if (in_this_photo)
            for (let i in in_this_photo) {
                if (in_this_photo[i]?.data?.in_this_photo) {
                    for (let j in in_this_photo[i].data.in_this_photo) {
                        in_this_photo[i].data.in_this_photo[j].section_index = i
                        in_this_photo[i].data.in_this_photo[j].entity_index = j
                        data.push(in_this_photo[i].data.in_this_photo[j])
                    }
                }
            }
        setInThisPhoto(data)
    }

    const handleInputChange = (value, type, id) => {
        let id_index = inThisPhoto[editedIndex].section_index
        let id_inThisPhoto_index = inThisPhoto[editedIndex].entity_index
        in_this_photo[id_index].edited = true
        if (type === 'description') {
            if (!in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description_original) {
                in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description_original = in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description
            }
            in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description = value
        }
        dispatch({ type: 'featured', payload: { ...featured, in_this_photo: in_this_photo } })
    }

    const handleDeleteInThisPhoto = () => {
        setDisplay(true)
        let id_index = inThisPhoto[editedIndex].section_index
        let id_site_index = inThisPhoto[editedIndex].entity_index
        in_this_photo[id_index].edited = true
        in_this_photo[id_index].data.in_this_photo.splice(id_site_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, in_this_photo: in_this_photo } })
        setDisplay(false)
        //to refresh Call function Again
        formatInThisPhoto()
        setEditedIndex(editedIndex - 1)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchInThisPhoto, in_this_photo: inThisPhoto, selectedResult: [], title: `HOMEPAGE - Featured - In this Photo`, inThisPhoto: true } })
        history.push(`/featured/searchList/in_this_photo/image`)
    }

    const handleDescriptionReset = () => {
        let id_index = inThisPhoto[editedIndex].section_index
        let id_inThisPhoto_index = inThisPhoto[editedIndex].entity_index
        if (in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description_original) {
            in_this_photo[id_index].edited = true
            in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description = in_this_photo[id_index].data.in_this_photo[id_inThisPhoto_index].description_original
            dispatch({ type: 'featured', payload: { ...featured, in_this_photo: in_this_photo } })
        }
    }


    return (
        <div>

            <FeaturedTitle title='Featured Sites on the Landing page' page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { history.push(`/featured`) } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="IN THIS PHOTO"
                        count={inThisPhoto.length > 1 ? `${inThisPhoto?.length} items` : `${inThisPhoto?.length} item`}
                        page={props?.page}
                        button={{
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            edit: { show: true, onHandle: () => { setEditedIndex(0) } },
                        }}
                    >
                        <Spinner display={display}>
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {inThisPhoto.map((item, index) => (
                                    <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => {
                                   console.log("what is the clicking");
                                        if (props?.page !== 'home') setEditedIndex(index);
                                         }}>
                                        <FeaturedCard year={dateString(item?.taken_date)} data={item} image="true" title="true" description="true" showOnlyCaption />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                    </CollapsableSection>
                    {(editedIndex !== -1) && inThisPhoto?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { window.open(`/${inThisPhoto[editedIndex].entity_type !== 'person' ? inThisPhoto[editedIndex].entity_type : 'people'}/${inThisPhoto[editedIndex].id}/editor`) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1); props.onUpdate(); } },
                            delete: { show: (inThisPhoto.length > 1), onHandle: () => { handleDeleteInThisPhoto(); props.onUpdate(); } }
                        }}>
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='cnow-form-label fw-bold'>Date</label>
                                    <input key={`input-${editedIndex}`} name='date' type='text' className='form-control' value={dateString(inThisPhoto[editedIndex]?.taken_date)} disabled autoComplete="off" />
                                </div>
                                <div className='col-10'>
                                    <> <label className='cnow-form-label fw-bold text-capitalize'>{`${inThisPhoto[editedIndex]?.entity_type} Caption`}</label>
                                        <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={inThisPhoto[editedIndex]?.caption} autoComplete="off" disabled />
                                    </>
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-${editedIndex}`} data={in_this_photo[inThisPhoto[editedIndex].section_index].data.in_this_photo[inThisPhoto[editedIndex].entity_index].description} onChange={(data) => { handleInputChange(data, 'description', inThisPhoto[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => { handleDescriptionReset() } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${inThisPhoto[editedIndex]?.entity_type} Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <ImagePreview data={inThisPhoto[editedIndex]} key={`ImagePreview-${editedIndex}`} singleImageButton={true} section={'in_this_photo'} single={true} singleImageTitle={'Website and Phone App Image'} imageEditorTitle={`In This Photo - ${inThisPhoto[editedIndex].caption}`} type={'featured'} />
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default InThisPhoto