import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import PreviewButton from './buttons/preview';
import ImageViewer from "./viewers/image";
import Button from "./buttons/default";

const _api = require('../api');

const PodcastRelatedItemListView = ({ podcasts, state }) => {

    const history = useHistory();
    const [related_podcasts, setRelatedPodcasts] = useState(podcasts);
    useEffect(() => {
        getPodcastRelatedItems();
        // eslint-disable-next-line
    }, [state?.podcast])

    const getPodcastRelatedItems = async () => {
        let pods = [...related_podcasts]
        if (pods?.length > 0) {
            for (const index in pods) {
                let response = await _api.podcast.getRelatedItem(pods[index].podcast_id) || []
                response = uniqBy(response, 'id').filter(x => (x.source === "site" || x.source === "person") && x.id !== pods[index].person_id)
                pods[index].related_items = response || []
                if (index > 0) {
                    pods[index].start = Number(pods[index - 1].start) + Number(pods[index - 1].related_items?.length)
                } else {
                    pods[index].start = Number(state[`podcast`]) || 0
                }
            }
            setRelatedPodcasts(pods)
        }
    }
    return (
        <>
            {related_podcasts?.length > 0 && related_podcasts.map((podcast, index) => {
                return (
                    <div className='row py-1' key={podcast.id}>
                        <div key={podcast.id} className='row pe-0'>
                            <div className='col-auto pe-1'>
                                <label className='cnow-form-label fw-bold'>Order</label>
                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
                            </div>
                            <div className='col ps-0 ms-0 pe-1'>
                                <div>
                                    <label className='cnow-form-label fw-bold'>Podcast - Title</label>
                                    <input value={podcast.podcast_name} disabled className='form-control' />
                                </div>

                            </div>
                            <div className='col-auto btn-list pe-0 ps-0'>
                                <div className=''>&nbsp;</div>
                                <Button size='md' target='_blank' icon='edit' onClick={() => history.push({ pathname: `/podcast/${podcast.podcast_id}/editor` })} />
                                <PreviewButton size='md' link={`podcast/${podcast.podcast_id}`} />
                            </div>
                        </div>

                        <div className='row pe-0 '>
                            <div className='col ms-5 pe-0'>
                                {
                                    podcast?.related_items?.length > 0 && podcast?.related_items.map((item, subIndex) => {
                                        return (
                                            <div key={item.id} className='row g-1 ms-2'>
                                                <div className='col-auto'>
                                                    <div className=''>&nbsp;</div>
                                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={podcast?.start + subIndex + 1} autoComplete="off" />
                                                </div>
                                                <div className='col-auto' >
                                                    <div className=''>&nbsp;</div>
                                                    <ImageViewer entityId={item.id} entityType={item.source} type='thumb' cssClass='thumb-small' />
                                                </div>
                                                <div className='col'>
                                                    <label className='cnow-form-label fw-bold'>{item.source}</label>
                                                    <input value={item.name} disabled className='form-control' />
                                                </div>
                                                <div className='col-auto btn-list me-1 pe-0'>
                                                    <div className=''>&nbsp;</div>
                                                    <Button size='md' target='_blank' icon='edit' onClick={() => history.push({ pathname: `/${item.source}/${item.id}/editor` })} />
                                                    <PreviewButton size='md' link={`/${item.source}/${item.id}`} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                )
            })}
        </>)
}
export default PodcastRelatedItemListView