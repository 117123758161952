/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import TextEditor from './textEditor'
import SelectDate from '../selects/date'
import AwardsGlobalList from '../selects/entityCategoryList'
import Button from '../buttons/default'
import { dateString, handleReorder, newTempId, getNewItemIndex, getAwardsName } from '../../helpers'
const _api = require('../../api')

const AwardEditor = (props) => {
    const dispatch = useDispatch()
    const entity = useSelector(state => state[props.id])

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.awards, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, awards: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addAward = (date_id) => {
        let awards = entity.awards ? entity.awards : []
        let index = getNewItemIndex(awards, entity.dates, date_id)
        // Insert new award at the appropriate index
        awards.splice(index, 0, {
            'id': newTempId(),
            'date_id': date_id,
            'award_id': null,
            'award_parent_name': '',
            'award_child_name': '',
            'day': null,
            'month': null,
            'year': null,
            'ca': false,
            'bce': false,
            'description': '',
            'attribution': '',
            'sort': index
        })

        // Update sort order
        awards.forEach((item, index) => item.sort = index + 1)

        // Update store
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, awards: awards } })
    }

    const removeAward = async (index) => {
        if (entity.awards[index]) {
            if (!entity.awards[index].id.startsWith('temp.')) {
                await _api[props.entity].deleteAward(entity.awards[index].id).then(_ => {
                    entity.awards.splice(index, 1)
                    entity.awards.forEach((item, index) => item.sort = index + 1)
                    dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } });
                })
            } else {
                entity.awards.splice(index, 1)
                entity.awards.forEach((item, index) => item.sort = index + 1)
                dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } })
            }

        }
    }

    const handleInputChange = ({ target: { name, value, checked, dataset } }) => {
        if (name === 'attribution') entity.awards[dataset.id].attribution = value
        dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } })
    }

    const handleEditorChange = (index, data) => {
        entity.awards[index].description = data
        dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } })
    }

    const handleItemChange = (index, value) => {
        entity.awards[index].award_child_name = value.award_child_name
        entity.awards[index].award_parent_name = value.award_parent_name
        entity.awards[index].award_id = value.id
        dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } })
    }

    const handleDateChange = (index, data) => {
        entity.awards[index].day = data.day
        entity.awards[index].month = data.month
        entity.awards[index].year = data.year
        dispatch({ type: 'entity', payload: { ...entity, awards: entity.awards } })
    }

    return (
        <div>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Awards</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add an Award' onClick={() => addAward(null)} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    entity.awards?.length > 0 && entity.awards.filter(x => x.date_id === null).map((award, index) => {
                        const selectedAward = { id: award.award_id, name: getAwardsName(award) }
                        const selectedDate = { day: award.day, month: award.month, year: award.year }
                        return (
                            <div key={index} className='my-3 dragula-item' id={award.id}>
                                <div className='card-bodys'>
                                    <div className='row g-1'>
                                        {
                                            entity?.awards?.length > 1 &&
                                            <div className='col-auto'>
                                                <label className='cnow-form-label fw-bold'>Order</label>
                                                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete='off' />
                                            </div>
                                        }
                                        <div className={entity?.awards?.length > 1 ? 'col' : 'col'}>
                                            <div className='row g-1'>
                                                <div className='col-12 col-md-auto d-flex flex-grow-1'>
                                                    <div className='w-100'>
                                                        <label className='cnow-form-label fw-bold'>Award *</label>
                                                        <AwardsGlobalList key={award.award_id} type={props.type}
                                                            value={selectedAward} onChange={(data) => handleItemChange(index, data)} />
                                                    </div>
                                                </div>
                                                <div className='col-auto'>
                                                    <SelectDate key={award.date_id} label={true}
                                                        value={selectedDate} onChange={(data) => handleDateChange(index, data)} />
                                                </div>
                                                <div className='col-auto'>
                                                    <label className='cnow-form-label'></label>
                                                    <div className='text-end'>
                                                        <Button icon='delete' onClick={() => removeAward(index)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <TextEditor key={award.id} data={award.description} onChange={(data) => handleEditorChange(index, data)}>
                                                <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                            </TextEditor>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks Attribution</label>
                                            <input type='text' className='form-control' name='attribution' defaultValue={award.attribution} onChange={handleInputChange} data-id={index} autoComplete='off' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                props.showDate && entity?.dates?.length > 0 && <>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>Awards, By Dates</div>
                    {
                        entity?.dates?.map((date, index) => {
                            return (
                                <div key={index} className='mb-2'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
                                        </div>
                                        <div className='col-9 text-end'>
                                            <Button size='sm' icon='plus' label='Add an Award' onClick={() => addAward(date.id)} />
                                        </div>
                                    </div>
                                    <div id={`${dragulaContainer}-${date.id}`} key={`${dragula}${date.id}`} className='dragula-container'>
                                        {
                                            entity?.awards?.filter(x => x.date_id === date.id).map((award, index) => {
                                                const awardIndex = entity.awards.findIndex(x => x.id === award.id)
                                                const selectedAward = { id: award.award_id, name: getAwardsName(award) }
                                                const selectedDate = { day: award.day, month: award.month, year: award.year }
                                                return (
                                                    <div key={index} id={award.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`}>
                                                        <div className='row g-1'>
                                                            {
                                                                entity?.awards?.length > 1 &&
                                                                <div className='col-auto'>
                                                                    <label className='cnow-form-label fw-bold'>Order</label>
                                                                    <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={award.sort} autoComplete='off' />
                                                                </div>
                                                            }
                                                            <div className='col'>
                                                                <div className='row g-1'>
                                                                    <div className='col-12 col-md-auto d-flex flex-grow-1'>
                                                                        <div className='w-100'>
                                                                            <label className='cnow-form-label fw-bold'>Award *</label>
                                                                            <AwardsGlobalList key={award.award_id} type='award_site'
                                                                                value={selectedAward} onChange={(data) => handleItemChange(awardIndex, data)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <SelectDate key={award.date_id} label={true}
                                                                            value={selectedDate} onChange={(data) => handleDateChange(awardIndex, data)} />
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <label className='cnow-form-label'></label>
                                                                        <div className='text-end'>
                                                                            <Button icon='delete' onClick={() => removeAward(awardIndex)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <TextEditor key={award.id} data={award.description} onChange={(data) => handleEditorChange(awardIndex, data)}>
                                                                    <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                                                </TextEditor>
                                                                <label className='cnow-form-label mt-2 fw-bold'>Remarks Attribution</label>
                                                                <input type='text' className='form-control' name='attribution' value={award.attribution} onChange={handleInputChange} data-id={awardIndex} autoComplete='off' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </div>
    )
}

export default AwardEditor