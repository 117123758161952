import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";

const PersonVerification = ({ id }) => {
    const people = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const people_curator = people.curator
    const handleOnChange = (data, type) => {
        if (!people?.curator) people.curator = {}
        if (!people?.curator.id) people.curator.id = newTempId()
        if (!people.curator?.[type]) people.curator[type] = ''
        people.curator[type] = data
        dispatch({ type: 'entity', payload: { ...people, curator: { ...people.curator, [type]: people.curator[type] } } })
    }

    return (
        <>
            <hr className="cnow-hr" />
            <VerificationCheckBox id={id} key={id} />
            <CuratorTextField subTitle={'Internal Notes'} disableMainTextBox={true} handleSubInputChange={(e) => { handleOnChange(e, 'internal_notes') }} subInput={people_curator?.internal_notes || ''} />
        </>
    )
}

export default PersonVerification

const VerificationCheckBox = ({ id }) => {
    const people = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const people_curator = people.curator

    const handleCreditChange = ({ target: { name, checked } }) => {
        if (!people.curator) people.credits = {}
        people.curator[name] = checked
        dispatch({ type: 'entity', payload: { ...people, curator: people.curator } })
    }
    return (
        <>
            <label className='cnow-form-title'>VERIFICATIONS</label>
            < div className='d-flex py-2'>
                <div className='form-check me-3'>
                    <input className='form-check-input' type='checkbox' name='is_trusted' defaultChecked={people_curator?.is_trusted || ''} key={people_curator?.is_trusted} onChange={handleCreditChange} />
                    <label className='form-check-label'>Trusted?</label>
                </div>
                <div className='form-check me-3'>
                    <input className='form-check-input' type='checkbox' name='is_verified' defaultChecked={people_curator?.is_verified || ''} key={people_curator?.is_verified} onChange={handleCreditChange} />
                    <label className='form-check-label'>Verified?</label>
                </div>
                <div className='form-check me-3'>
                    <input className='form-check-input' type='checkbox' name='is_legally_signed' defaultChecked={people_curator?.is_legally_signed || ''} key={people_curator?.is_legally_signed} onChange={handleCreditChange} />
                    <label className='form-check-label'>Legal Agreement Signed?</label>
                </div>
            </div>
        </>
    )
}