import React from 'react';
import LongTextViewer from '../viewers/longText';

const StaticContentCard = (props) => {
  const data = props.data
  return (
    <>
    {data && <div className={`${props.cssClass}`}>
        <div className={` py-1 fz-12 d-flex justify-content-between align-items-center small  border-0  pt-0 row`} style={{ margin: '0 0.5px 0 0.4px', gap:'10px'}}>
          <div className="col bg-light cursor-pointer">
            <div className={`fw-bold`}>
              {data?.description ? <LongTextViewer lines={2}>{data?.title}</LongTextViewer> : <LongTextViewer lines={4}>{data?.title}</LongTextViewer>}
            </div>
              {data?.title?.length > 50 ? <LongTextViewer lines={3}>{data?.description}</LongTextViewer> : <LongTextViewer lines={4}>{data?.description}</LongTextViewer>}  
          </div>
          {data.title2 && <div className="col bg-light cursor-pointer">
            <div className={`fw-bold`}>
              {data?.description ? <LongTextViewer lines={2}>{data?.title2}</LongTextViewer> : <LongTextViewer lines={4}>{data?.title}</LongTextViewer>}
            </div>
              {data?.title?.length > 50 ? <LongTextViewer lines={3}>{data?.description2}</LongTextViewer> : <LongTextViewer lines={4}>{data?.description}</LongTextViewer>}  
          </div>}
        </div>
      
    </div>}
    </>
  )
}

export default StaticContentCard