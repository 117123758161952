
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { customSelectStyle, newTempId } from '../../helpers';
import SelectPerson from '../../components/selects/person';
import SelectSite from '../../components/selects/site';

import { toast } from 'react-toastify';
import { isEmpty } from 'lodash'
import swal from 'sweetalert';

const types = [
  { 'value': 'site', 'label': 'Site' },
  { 'value': 'person', 'label': 'Person' }
]

const ImageTagEditor = (props) => {
  const dispatch = useDispatch();
  const image = useSelector(state => state[props.id]);
  const [type, setType] = useState(types[0]);
  const [entity, setEntity] = useState({});
  const handleSubmit = () => {
    if (isEmpty(entity)) return toast.error('Select an option from the dropdown.', { hideProgressBar: true })

    image.entity_tags = image.entity_tags || [];
    let itemId = `${type.value}_id`
    if (image.entity_tags.findIndex((item, index) => item[itemId] === entity.id) >= 0) {
      swal({
        title: `Tag already exists.`,
        text: `Are you sure that you want to add it again?`,
        buttons: ['Cancel', 'Yes'],
        icon: 'warning',
      }).then(async (status) => {
        status && image.entity_tags.push({
          'id': newTempId(),
          'image_id': image.id,
          'site_id': entity.type === 'site' ? entity.id : null,
          'person_id': entity.type === 'person' ? entity.id : null,
          'url': entity.image,
          'site_name': entity.type === 'site' ? entity.name : '',
          'person_name': entity.type === 'person' ? entity.name : '',
          'geometry': props.annotation.geometry,
          'sort': image.entity_tags ? image.entity_tags.length + 1 : 1,
          'data': { 'id': newTempId() }
        });
        dispatch({ type: 'entity', payload: { ...image, entity_tags: image.entity_tags } });
      })
    }
    else {
      image.entity_tags.push({
        'id': newTempId(),
        'image_id': image.id,
        'site_id': entity.type === 'site' ? entity.id : null,
        'person_id': entity.type === 'person' ? entity.id : null,
        'url': entity.image,
        'site_name': entity.type === 'site' ? entity.name : '',
        'person_name': entity.type === 'person' ? entity.name : '',
        'geometry': props.annotation.geometry,
        'sort': image.entity_tags ? image.entity_tags.length + 1 : 1,
        'data': { 'id': newTempId() }
      });
      dispatch({ type: 'entity', payload: { ...image, entity_tags: image.entity_tags } });
    }
    props.onSubmit();
  }

  return (
    <div>
      <Select name='type' options={types} styles={customSelectStyle} value={type} onChange={(item) => setType(item)} />

      <div className='mt-2'>
        {type.value === 'site' && <SelectSite value={entity} onChange={(value) => setEntity(value)} />}
        {type.value === 'person' && <SelectPerson value={entity} onChange={(value) => setEntity(value)} />}
      </div>

      <button className='btn btn-sm btn-light mt-2' onClick={handleSubmit}>Submit</button>
    </div>);
}
export default ImageTagEditor;