import React from "react";

const Icon = (props) => {
  const size = props.size || 16;
  const marginRight = props.right || 0;
  const marginBottom = props.bottom || 2;
  const marginLeft = props.left || 0;
  const marginTop = props.top || 0;
  return (
    <i
      onClick={props.mapClick}
      style={{
        cursor: "pointer",
        backgroundImage: `url(/img/icons/${props.name}.svg)`,
        backgroundRepeat: "no-repeat",
        width: size,
        height: size,
        display: "inline-block",
        verticalAlign: "middle",
        marginBottom: marginBottom,
        marginRight: marginRight,
        marginLeft: marginLeft,
        marginTop: marginTop,
      }}
    />
  );
};

export default Icon;
