import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import uniqid from 'uniqid'
import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import StoryEditor from '../../components/editors/storyEditor';
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';

const MapAbout = (props) => {
  const map = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())

  return (
    <>
      <PageTitle title={map?.name} />
      <DescriptionEditor id={props.id} type='map' refresh={() => setKey(uniqid())} label={'Description'}/>
      <TagEditor id={props.id} type='map' />
      <StoryEditor id={props.id} type='map' key={`story-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
      <RelatedSiteEditor id={props.id} type='map' key={`related-site-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default MapAbout;