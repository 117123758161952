/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import ImageView from '../../components/viewers/image';
import ImageForm from './form';
import ImagePreview from './preview';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import Spinner from '../../components/spinner';
import { getImageUrl } from '../../helpers';
import ImageUploader from './upload';
import { uniqBy } from 'lodash';
import axios from 'axios';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';
import PdfView from '../../components/viewers/pdfView';

const _api = require('../../api');

const ImageEditor = (props) => {
    const id = props.match.params.id
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [key, setKey] = useState(uniqid())
    const image = useSelector(state => state[id]);
    const images = useSelector((state) => state.images);
    const [spinner, showSpinner] = useState(false)
    const uploadRef = useRef(null)

    const [uploader, showUploader] = useState(false)

    useEffect(() => {
        getImageData()
    }, [id])

    const getImageData = async () => {
        showSpinner(true)

        let myImage = await _api.image.getById(id)
        myImage.credits = await _api.image.getCredit(id)
        myImage.tags = await _api.image.getTag(id)
        myImage.entity_tags = await _api.image.getEntityTag(id)
        myImage.related_images = await _api.image.getRelatedImage(id)
        myImage.related_items = await _api.image.getRelatedItem(id)
        myImage.related_items = uniqBy(myImage.related_items, 'id')

        if (myImage.entity_tags) myImage.entity_tags.forEach(x => x.data = { 'id': x.id })

        dispatch({ type: 'entity', payload: myImage })
        showSpinner(false);
    }

    const save = async () => {
        showSpinner(true);

        let myImage = await _api.image.update(image)
        myImage.credits = await _api.image.updateCredit(image.id, image.credits)
        myImage.tags = await _api.image.updateTag(image.id, image.tags)
        myImage.entity_tags = await _api.image.updateEntityTag(image.id, image.entity_tags)
        myImage.related_images = await _api.image.updateRelatedImage(image.id, image.related_images)

        if (myImage.entity_tags) myImage.entity_tags.forEach(x => x.data = { 'id': x.id })

        dispatch({ type: 'entity', payload: myImage })

        showSpinner(false);
        toast.success('Image has been saved successfully')
    }

    const highlightImage = (image) => {
        if (image?.related_image_id) {
            if (image?.related_image_id === id) return 'thumb small cnow-image-editor-thumbnail highlight-box'
            else return 'thumb small cnow-image-editor-thumbnail'
        }
        else if (image?.image_id) {
            if (image?.image_id === id) return 'thumb small cnow-image-editor-thumbnail highlight-box'
            else return 'thumb small cnow-image-editor-thumbnail'
        }
        else if (image?.id === id) {
            return 'thumb small cnow-image-editor-thumbnail highlight-box'
        }
        else {
            return 'thumb small cnow-image-editor-thumbnail'
        }
    }
    let history_image = location?.state?.history_image;
    if (image?.type === 'then_now') {
        if (!history_image) {
            history_image = {}
        }
        history_image["type"] = 'then_now';
        history_image["id"] = image.id;
        history_image["image"] = image;
    }
    const showImage = async (image) => {
        showSpinner(true)
        save().then(() => {
            if (image.related_image_id) {
                showSpinner(false)
                history.push({
                    state: {
                        history_image
                    },
                    pathname: `/image/${image?.related_image_id || image?.id}/editor`
                })
            } else {
                showSpinner(false)
                history.push({
                    pathname: `/image/${image?.image_id || image?.id}/editor`,
                })
            }
        })
    }

    const downloadImage = () => {
        saveAs(image.url, image.caption)
    }

    const handleReplace = async (item) => {
        if (uploadRef && uploadRef.current) {
            uploadRef.current.click()
        }
        showUploader(true)
        if (item) {
            image.url = item[0].url
            await axios.put(`/image/${image?.id}`, image)
            if (images.length > 0) {
                let index = images?.findIndex(x => x?.id === image.id)
                images[index].url = item[0].url && item[0].url
                dispatch({ type: 'images', payload: images })
            }
            if (location?.state?.history_image && location?.state?.history_image?.type === 'then_now' && location?.state?.history_image?.id) {
                let related_images = await _api.image.getRelatedImage(location.state.history_image.id)
                processThenNow(related_images, location.state.history_image.id)
            }
        }
    }
    const processThenNow = async (related_images, id) => {
        // props.showSpinner(true)
        if (related_images?.length > 0) {
            related_images = await _api.image.updateRelatedImage(id, related_images)
            dispatch({ type: 'entity', payload: { ...image, related_images: related_images } });
            if (history_image?.image?.related_images) {
                history_image.image.related_images = related_images;
            }
        }
        // 2. Process image
        await _api.image.process(history_image?.image)
        window.location.reload();
    }

    return (
        <>
            {
                image && <>
                    <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                        <div className='row'>
                            <div className='col'>
                                <strong>Image Editor</strong>
                            </div>
                            <div className='col-auto'>
                                <div className='btn-list'>
                                    {
                                        image.type !== 'then_now' &&
                                        <>
                                            <Button icon='crop' size='sm' onClick={() => { save().then(history.push(`/image/${id}/resize/image`)); }} />
                                            <Button icon='replace' size='sm' onClick={() => handleReplace()} />
                                        </>
                                    }

                                    <Button size='sm' icon='download' onClick={() => downloadImage()} />
                                    <PreviewButton size='sm' link={(image?.type === 'then_now' || image?.type === 'image' || image?.type === 'document') ? `image/${image.id}/preview/${image.id}` : `image/${image.id}`} />
                                    <Button size='sm' label='Save' color='danger' onClick={() => save()} />
                                    <Button icon='close' size='sm' target='_self' onClick={() => { images?.source ? history.push(images?.source) : history.goBack() }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='cnow-image-edit-container cnow-bg-light p-4'>
                        <Spinner display={spinner}>
                            <div className='row g-0'>
                                <div className='col-5 cnow-border-right'>
                                    <ImageForm id={id} showSpinner={showSpinner} onSave={() => save()} key={`dragula-${key}`} refresh={() => setKey(uniqid())} />
                                </div>

                                <div className='col-7 h-100vh'>
                                    <ImagePreview id={id} onSave={() => save()} />
                                </div>
                            </div>
                        </Spinner>
                    </div>

                    {
                        images && images?.entityImages?.length > 0 &&
                        <div className='cnow-podcast-editor-list-container'>
                            <Spinner display={spinner} >
                                <div className='d-flex flex-row ms-2'>
                                    {
                                        images.entityImages?.map((image, index) => {
                                            return (
                                                <div key={index} className='mt-3 me-2 d-flex flex-column align-items-center' onClick={() => { showImage(image) }}>
                                                    {image.image_type !== "document" && <ImageView
                                                        url={getImageUrl(image.url || image.image_url, 'thumb')}
                                                        entityId={image.related_image_id ? image.related_image_id : image.image_id}
                                                        entityType='image' type='thumb'
                                                        cssClass={highlightImage(image)} />}
                                                    {image.image_type === "document" && <PdfView
                                                        url={image.image_url}
                                                        type="thumb"
                                                        cssClass={highlightImage(image)} />
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Spinner>
                        </div>
                    }
                    {
                        uploader &&
                        <ImageUploader
                            globalSearch={false}
                            show={uploader}
                            onHide={() => showUploader(false)}
                            replace={true}
                            onSave={async (item) => { await handleReplace(item); showUploader(false) }}
                        />
                    }
                </>
            }
        </>
    )
}

export default ImageEditor;