import React from 'react'

const LegalScrollableBox = ({ isImagesToBeLegal, isPodcastToBeLegal }) => {
  return (
    <div>
      <hr className='cnow-hr' />
      <div className="col">
        <label className="cnow-form-title">AUTHORIZE CULTURENOW ELECTRONICALLY *</label>
      </div>
      <div className="col mt-2">
        <label className="cnow-form-title">LEGAL *</label>
      </div>
      <div className='legal-scrollBar' style={{ width: 'fi-content', height: '300px', overflow: 'auto', border: '1px solid black', }}>
        <div className='p-4'>
          <div className='fw-bold'>PERMISSION AND RELEASE AGREEMENT</div>
          <div>
            These photographs, documents, and podcasts have been uploaded to cultureNOW’s website. By checking the
            boxes next to the images, you have acknowledged that you are the rightful copyright owner and that cultureNOW
            may use them on the Museum Without Walls website and for educational or promotional purposes outlined below.
            None of the media will appear on the website or app until you have signed this agreement. If you can only
            confirm rights for some of the images or podcasts, if you check them, that is what will appear. You can add the
            others later.
            CultureNOW is a Not-For-Profit Corporation formed under the laws of the State of New York and exempt from
            federal income taxation under section 501(c)(3) of the Internal Revenue Code.
          </div>
          {isImagesToBeLegal && <div>
            <label className="cnow-form-title">PHOTOGRAPH, DRAWING AND DOCUMENT RELEASE AND DIGITAL IMAGE RELEASE</label>
            <div>
              The owners and copyright holders of drawings, photographs, and other images submitted to cultureNOW, Inc in
              connection with the Museum Without Walls website and App hereby grant permission to cultureNOW for the
              following purposes:
              <ol>
                <li>For use in the Museum Without Walls Website and App</li>
                <li>To use and reproduce this material as required</li>
                <li>For advertising and all other legal purposes, including audiovisual presentations, exhibitions, tours,
                  articles, pdf’s and books related to its Museum Without Walls</li>
                <li>For publicity purposes in other media to announce the Museum Without Walls and cultureNOW programs in
                  addition to media (inclusive of the culturenow website or partner organization websites in coordination with
                  cultureNOW programming) or to educate the public about architecture, history, and public art.</li>
                <li>For image recognition on Amazon or Google platforms</li>
                <li>It is understood that the images may be cropped or otherwise digitally edited to fit the design constraints
                  of the platforms.</li>
              </ol>
            </div>
            <p className='fw-bold'>
              Non-Exclusive Permission License and Release.
            </p>
            <div>
              Nothing in this grant of permission, license to use, or release of copyright of the Released Photo/Digital Images
              shall be deemed to grant an exclusive right to use the Released Photo/Digital image unless negotiated by CULTURE NOW
              with the Owner of the Image/Copyright for a set period of time,
              or if no set period of time is stated, then for an unlimited period of time, and signed and dated by the parties to this
              Agreement attached hereto as an Exclusivity Clause;
            </div>
            <p className='fw-bold'>
              Credit
            </p>
            <div>
              cultureNOW will include credit and any copyright on drawings, photographs, or digital images that it publishes and
              will include this information with all material distributed to other media or retained in its library archives.
              cultureNOW will not be responsible for the failure of any other person or organization to include this information in
              their publicity.
            </div>
            <p className='fw-bold'>
              Copyright
            </p>
            <div>
              If materials submitted are copyrighted, the copyright notice must be made available to cultureNOW and can be
              uploaded here.
            </div>
            <p className='fw-bold'>
              Compensation
            </p>
            <div>
              cultureNOW will not be required to pay royalties, make any other payment, or provide other consideration of any
              type. No Financial Remuneration will be received for the license, use, reproduction, and release of the Photo/
              digital images.
            </div>
            <p className='fw-bold'>
              OWNER CONFIRMATION:
            </p>
            <div>
              I affirm that I am the sole owner and/or copyright holder (as indicated above) of the drawings, photographs, or
              other images submitted herewith, that they are original works, and that neither these materials nor the permission
              granted hereby infringe upon the ownership, copyright, trademark, or other rights of others.
            </div>
            <p className='fw-bold'>
              PHOTOGRAPHER CONFIRMATION:
            </p>
            <div>
              I affirm that I am the sole owner and/or copyright holder (as indicated above) of the drawings, photographs, or
              other images submitted herewith, that they are original works, and that neither these materials nor the permission
              granted hereby infringe upon the ownership, copyright, trademark, or other rights of others.
            </div>
          </div>}

          {isPodcastToBeLegal && <div>
            <label className="cnow-form-title">
              SPEAKER RELEASE
            </label>
            <div>
              cultureNOW extends the educational experience to the general public beyond the physical experience of attending
              its walking tours and programs by offering a podcast platform on its Museum Without Walls website and App. This
              includes: podcasts, videos, recorded walking tours and Cocktails & Conversations and other programs it has held
              at the Center for Architecture in New York and any other venue, public or private, where Culture Now may sponsor,
              organize, be invited to, or participate in a speaking engagement, conference, seminar or audio-visual or social media
              presentation within the USA or other foreign country.
            </div>
            <div>
              The speaker(s) hereby grant an unlimited, permanent, nonexclusive license, under which cultureNOW may film, tape,
              transcribe, otherwise record or quote from the video or audio presentation or materials, may create derivative works,
              may publish them in any format, including audio recordings, distance education courses, the Internet, Internet II, or
              any other digital, wired, or unwired network, including any successor technologies, or any other media as related to
              the Museum Without Walls Website and app.
              <ol>
                <li>For use in the Museum Without Walls Website and App</li>
                <li>To use and reproduce this material as required</li>
                <li>For educational purposes, including audiovisual presentations, exhibitions, tours, articles, and books related to
                  its Museum Without Walls</li>
                <li>For educational purposes on other podcast channels including but not limited to: BuzzSprout, Spotify, Apple
                  Podcasts, Pandora, iTunes, Google Podcasts, etc., and video channels including but not limited to: Vimeo and
                  YouTube.</li>
                <li>For publicity purposes in other media to announce the Museum Without Walls and cultureNOW programs in addition
                  to media (inclusive of the culturenow website or partner organization websites in coordination with cultureNOW
                  programming) or to educate the public about architecture, history, and public art.</li>
              </ol>
            </div>
            <p className='fw-bold'>
              Non-Exclusive Permission License and Release.
            </p>
            <div>
              Nothing in this grant of permission, license to use, or release of copyright of the Released Podcasts / Videos shall
              be deemed to grant an exclusive right to use the Released media unless negotiated by CULTURE NOW with the Owner of
              the Podcast / Video /Copyright for a set period of time, or if no set period of time is stated, then for an unlimited
              period of time, and signed and dated by the parties to this Agreement attached hereto as an Exclusivity Clause;
            </div>
            <p className='fw-bold'>
              Credit
            </p>
            <div>
              cultureNOW will include credit and any copyright on media that it publishes and will include this information with
              all material distributed to other media or retained in its library archives. cultureNOW will not be responsible for the
              failure of any other person or organization to include this information in their publicity.
            </div>
            <p className='fw-bold'>
              Copyright
            </p>
            <div>
              If materials submitted are copyrighted, the copyright notice must be made available to cultureNOW and can be
              uploaded here.
            </div>
            <p className='fw-bold'>
              Compensation
            </p>
            <div>
              cultureNOW will not be required to pay royalties, make any other payment, or provide other consideration of any
              type. No Financial Remuneration will be received for the license, use, reproduction, and release of the media.
              The speaker agrees to allow cultureNOW to mine the previously recorded podcasts / videos / programs / conversations
              and tours according to the terms above.
            </div>
          </div>}


        </div>
      </div>

    </div>
  )
}

export default LegalScrollableBox