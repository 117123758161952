/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import Spinner from '../../../components/spinner';
import UserProfile from './profile';
import UserMenu from './menu';
import UserMembers from './userMembers';
import UserMembership from './userMembership';
import UserCollection from './collection';
import SavedContent from './savedContent';
import Billing from './billing';
import UserAnalytics from './UserAnalytics';
import UserPreview from './preview';

const _api = require('../../../api');

const UserEditor = (props) => {
    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'profile';
    const sectionId = props.match.params.sectionId || '';
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state[id]);
    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);
    // const { id: userId, parent_id: parentId } = useSelector((state) => state.user);
    const [spinner, showSpinner] = useState(false);


    useEffect(() => {
        if (id === 'NEW') {
            //need to ask client about add new user
            // dispatch({
            //     type: 'entity', payload: {
            //         'id': 'NEW',
            //         'name': '',
            //         'description': '',
            //         'meta': {},
            //         'image_id': null,
            //         'deleted': false,
            //         'live': false,
            //         'types': [],
            //         'categories': [],
            //         'themes': [],
            // 		'user_id' :  parentId || userId
            //     }
            // })
        } else {
            getUserById()
        }
    }, [id, sectionId, section]);

    useEffect(() => {
        return () => {
            const data = JSON.parse(localStorage.getItem('cnow-cms'))
            delete data[id]
            localStorage.setItem('cnow-cms', JSON.stringify(data))
        }
    }, [])


    const getUserById = async () => {
        showSpinner(true);
        let myUser = await _api.user.getUserById(id)
        if (section === 'title' || !section) {

        }
        dispatch({ type: 'entity', payload: myUser })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (!path) {
            path = section;
            if (sectionId) path = path.concat('/', sectionId);
        }

        showSpinner(true);
        if (user.id === 'NEW') user.id = null;

        if (section === 'profile') {
            let myUsers = await _api.user.updateUserById(user.id, user)
            dispatch({ type: 'entity', payload: myUsers })
        }

        history.push(`/user/${user.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <div className='cnow-editor-container'>
            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                <UserMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    {section === 'profile' && <UserProfile id={id} />}
                    {section === 'members' && <UserMembers id={id} />}
                    {section === 'membership' && <UserMembership id={id} />}
                    {section === 'collection' && <UserCollection id={id} />}
                    {section === 'saved-content' && <SavedContent id={id} />}
                    {section === 'billing' && <Billing id={id} />}
                    {section === 'analytics' && <UserAnalytics id={id} />}
                </Spinner>
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                <UserPreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(UserEditor);