import { createStore } from 'redux';

const defaultState = {
  loggedin: false,
  user: {},
  images: [],
  podcasts: [],
  related_sites: [],
  menu: 'lg',
  preview: 'lg',
  search: {},
  associated_sites: {
    siteId: '',
    list: [],
    updateList: []
  },
  onThisDayList: [],
  featured: {}
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'loggedin':
      return { ...state, loggedin: action.payload };
    case 'user':
      return { ...state, user: action.payload };
    case 'entity':
      return { ...state, [action.payload?.id]: action.payload };
    case 'images':
      return { ...state, images: action.payload };
    case 'podcasts':
      return { ...state, podcasts: action.payload };
    case 'related_sites':
      return { ...state, [`${action.id}_related_sites`]: action.payload };
    case 'associated_sites':
      return { ...state, associated_sites: action.payload };
    case 'menu':
      return { ...state, menu: action.payload };
    case 'preview':
      return { ...state, preview: action.payload };
    case 'search':
      return { ...state, search: action.payload };
    case 'onThisDayList':
      return { ...state, onThisDayList: action.payload };
    case 'featured':
      return { ...state, featured: action.payload };
    case 'clearStorage': return defaultState;
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cnow-cms');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cnow-cms', serializedState);
  } catch (err) {

  }
};

const store = createStore(appReducer, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(() => {
  saveState(store.getState());
});

export default store;