import React, { useState, useEffect } from 'react';

// import ThenNowControl from '../../components/thenNowControl'
import ThenNow from '../../components/thenNow/thenNow'
import LinkButton from '../../components/buttons/link';

const _api = require('../../api');

const ImageViewerThenNow = (props) => {

    const [image, setImage] = useState({});

    // useEffect(() => {
    //     new ThenNowControl({
    //         container: document.querySelector('.b-dics')
    //     });
    // }, [])

    useEffect(() => {
      getImage();
      // eslint-disable-next-line
    },[])

    const getImage = async() => {
        let image = await _api.image.getById(props.match.params.id)
        image.images = await _api.image.getRelatedImage(image.id)
        setImage(image);
    }

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>{image?.caption}</strong>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <LinkButton size='sm' icon='close' target='_self' to={`/image/${image?.id}/editor`} />
                        </div>
                    </div>
                </div>
            </div>

            <div id='image_tagger' className='cnow-image-resize-container text-center'>
                {image.id && <ThenNow image={image} />}
                {/* <div className='b-dics'>
                    {
                        image.images.map((image, index) => {
                            return (
                                <img key={index} src={image.url} alt={index} />
                            )
                        })
                    }
                </div> */}
            </div>
        </div>
    )
}
export default ImageViewerThenNow;   
