/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { cleanHtml, dateString, relatedPersonName } from '../../helpers';
const MIN_LENGTH = 3
const HighlightList = ({ data ,type}) => {
  const [length, setLength] = useState(MIN_LENGTH);
  const [more, showMore] = useState(false);
  return (
    <>
      {
        data && data?.length > 0 && <>
          <hr />
          <div className='d-flex justify-content-between'>
            <label className='fst-italic fw-bold'>{(type === 'person' || data?.related_person_id) ? 'Career Highlights' : 'Highlights'}</label>
            {
              data.length > MIN_LENGTH &&
              <div>
                {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1' /></div>}
              </div>
            }
          </div>
          {
            data.slice(0, length).map((item, index) => {
              let path;
              let url;
              if (item.entity_type === 'person' || item?.related_person_id) {
                path = 'people';
                url = item.related_person_id;
              } else if (item.entity_type === 'site' || item?.related_site_id) {
                path = 'site';
                url = item.related_site_id;
              }
              return ((
                <div key={index}>
                  {(item.entity_type === 'site' || item.entity_type === 'person' || relatedPersonName(item) || item?.related_site_name) ? (
                    <a
                      href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-dark text-decoration-none'
                    >
                      <span
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: cleanHtml(item.highlight),
                        }}
                      />{' '}
                    </a>
                  ) : (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: cleanHtml(item.highlight),
                      }}
                    />
                  )}
                  {((item.entity_type === 'site' || item.entity_type === 'person' || relatedPersonName(item) || item?.related_site_name) && (dateString(item)) && item?.highlight) ? <a href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-dark text-decoration-none'><span className='mx-1'>in</span></a> : (dateString(item)) && item?.highlight && <span className='mx-1'>in</span>}

                  {item.entity_type === 'site' || item.entity_type === 'person' || relatedPersonName(item) || item.related_site_name
                    ? (
                      <a
                        href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-dark text-decoration-none'
                      >
                        <span>{dateString(item) && dateString(item)}</span>
                      </a>
                    ) : (
                      <span>{dateString(item) && dateString(item)}</span>
                    )}
                </div>
              ))
            })
          }
        </>
      }
    </>
  )
}

export default HighlightList;