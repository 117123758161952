/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import { useHistory } from 'react-router-dom';

const RecipeImage = (props) => {

    const dispatch = useDispatch()
    const recipe = useSelector(state => state[props.id])

    const location = useLocation()
    const history = useHistory()

    let currentImages = recipe ? recipe.images?.filter(x => x.type === 'image' && x.date_id === null) : []

    const editItems = (id) => {
        let imagesList = recipe.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: recipe.id, entityType: 'recipe' } })
        history.push(`/image/${id}/banner`)
    }

    return (
        <>
            {
                // !recipe &&
                 <>
                    <PageTitle title={recipe?.name} />

                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(recipe.image_id)} className='cursor-pointer'>
                                        {recipe?.images?.find(item => item.image_id === recipe?.image_id)?.image_url?.endsWith('.pdf') ?
                                            <ImageViewer
                                                url={recipe?.images?.find(item => item.image_id === recipe?.image_id)?.image_url}
                                                entityType={'image'}
                                                type='banner'
                                                cssClass='cnow-image-editor-banner'
                                            />
                                            :
                                            <ImageViewer
                                                entityId={recipe?.image_id}
                                                entityType={'image'}
                                                type='banner'
                                                cssClass='cnow-image-editor-banner'
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(recipe.image_id)} disabled={recipe?.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='entity-image-sort'>

                        {
                            <EntityImages title={`Images *`} items={currentImages}
                                imageType='image'
                                entityType='recipe'
                                entityId={recipe?.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={0}
                            />
                        }
                    </div>

                </>
            }
        </>
    )
}

export default RecipeImage 
