import React from 'react';

const RelatedLocation = ({ data, type, id }) => {
    const handleMapRedirect = () =>{
        window.open(`${process.env.REACT_APP_WEB_URL}/historic-map/${type}/${id}`, "_blank");
    }
    return (
        <>
            {
                data && data.length > 0 && <>
                    <hr className={type === 'person' ? 'd-none' : ''} />
                    <div className='fw-bold fst-italic'></div>
                    {
                        data.map((item, index) => {
                            return <div key={index} className='cursor-pointer' onClick={() =>{handleMapRedirect()}}>
                                <div className='fw-bold'>{item?.name}</div>
                                <div className='text-muted'>{item?.address}</div>
                                <div className='text-muted'>{item?.description}</div>
                            </div>
                        })
                    }
                </>
            }
        </>
    )
}

export default RelatedLocation;