import React from 'react';
import { personName, relatedPersonName, TABLE_ROWS } from '../../helpers';

const RelatedPeopleList = ({ data }) => {
    return (
        <td>
            {
                data?.slice(0, TABLE_ROWS).map((item, index) => {
                    return <div key={index} className='text-truncate'>{relatedPersonName(item) || personName(item)}</div>

                })
            }

            {data?.length > TABLE_ROWS && <div className='small text-muted'>+ {data?.length - TABLE_ROWS} more</div>}
        </td>
    )
}

export default RelatedPeopleList;