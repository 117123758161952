import React from 'react';
import ReactShowMoreText from 'react-show-more-text';

const LongTextViewer = ({ lines, children }) => {

    return (
        <ReactShowMoreText lines={lines || 4} more='' less=''>
            <div dangerouslySetInnerHTML={{ __html: children }} />
        </ReactShowMoreText>
    )
}

export default LongTextViewer;