import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const _api = require('../../api')

const RecipeTitle = (props) => {
  const [data, setData] = useState([]);
  const getRecipeType = async () => {
    let res = await _api.global_list.getAll('recipe_type')
    setData(res)
  }

  useEffect(() => {
    getRecipeType()
  }, [])


  const dispatch = useDispatch();
  const recipe = useSelector((state) => state[props.id]);
  const handleInputChange = ({ target: { name, value } }) => {
    recipe[name] = value;
    dispatch({ type: 'entity', payload: { ...recipe, [name]: value } });

  }
  const handleTypeChange = (recipie_type_id) => {
    dispatch({ type: 'entity', payload: { ...recipe, type_id: recipie_type_id } });
  }
  return (
    <>
      <div className='form-group'>
        <div className='form-group mt-2'>
          <div className='cnow-form-title'>SPECIAL FEATURE</div>
          <div className='row '  >
            {
              data?.map((item, index) => {
                return (
                  <div className='col-auto' key={index}>
                    <div className='form-check form-check-inline'>
                      <input type='radio' className='form-check-input' name='type'
                        value='site'
                        onChange={() => handleTypeChange(item.id)}
                        checked={item?.id === recipe?.type_id}
                      />
                      <label className='form-check-label small'>{item.name}</label>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <hr className='cnow-hr' />
        <div className='cnow-form-title'>Title <span className='text-cn-warning'>*</span> </div>
        <input name='name' type='text' className='form-control' value={recipe?.name} onChange={handleInputChange} autoComplete="off" />
      </div>

    </>
  );
}

export default RecipeTitle