import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";
import InternalTags from "../../components/curator/internalTags";
import Accession from "../../components/curator/accession";
import Permission from "../../components/curator/permission";

const CuratorManagement = ({ id }) => {
    const site = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={site.name} />
            <TextFied id={id} key={id} />
            <LegalHistory id={id} type={'site'} />
            <EntityOfDayCheckBox type={'site'} id={id} entity_day={'Site'} show_entity_of_day={'not_show_site_of_the_day'} key={site?.curator?.not_show_person_of_the_day} />
        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const site = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const site_curator = site.curator
    const handleOnChange = (data, type) => {
        if (!site?.curator) site.curator = {}
        if (!site?.curator.id) site.curator.id = newTempId()
        if (!site.curator?.[type]) site.curator[type] = ''
        site.curator[type] = data
        dispatch({ type: 'entity', payload: { ...site, curator: { ...site.curator, [type]: site.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'LINK'} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'link') }} mainInput={site_curator?.link || ''} hrTrue />
            <CuratorTextField label={'DESCRIPTION'} title={'Remarks Attribution:'} subTitle={'Internal Notes (available only to users with admin access):'} handleMainInputChange={(e) => { handleOnChange(e, 'description_remark') }} handleSubInputChange={(e) => { handleOnChange(e, 'description_notes') }} mainInput={site_curator?.description_remark || ''} subInput={site_curator?.description_notes || ''} hrTrue />
            <CuratorTextField label={'CONDITION REPORT'} title={'Brief Condition Report'} subTitle={'Condition Notes'} handleMainInputChange={(e) => { handleOnChange(e, 'condition_report') }} handleSubInputChange={(e) => { handleOnChange(e, 'condition_notes') }} mainInput={site_curator?.condition_report || ''} subInput={site_curator?.condition_notes || ''} hrTrue />
            <InternalTags label={'INTERNAL TAGS'} handleMainInputChange={(e) => { handleOnChange(e, 'internal_tags') }} mainInput={site_curator?.internal_tags || ''} hrTrue/>
            <Accession createdText="Creation at" label={'ACCESSION'} data={site} hrTrue entity={"Site"}/>
            <Permission label={'PERMISSION'} handleMainInputChange={(e) => { handleOnChange(e, 'permission') }} mainInput={site_curator?.permission || ''} hrTrue/>
        </>
    )
}