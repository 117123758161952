import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import PodcastEditor from '../../components/editors/podcastEditor';
import RelatedPlaylist from '../../components/editors/relatedPlaylist';
import RelatedEventPodcast from './RelatedEventPodcast';

const EventSeriesPodcast = (props) => {
  const event_series = useSelector((state) => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (<>
    <PageTitle title={event_series?.name} />
    <hr className='cnow-hr' />
    <PodcastEditor id={props.id} type='event_series' key={`podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    <RelatedPlaylist id={props.id} type='event_series' key={`related-podcast-editor-${key}`} refresh={() => { setKey(uniqid()) }} />

    {
      event_series?.related_event?.length > 0 &&
      <>
        <hr />
        <RelatedEventPodcast title={'Featured Events Podcasts'} data={event_series?.related_event} type={'event'} titleLabel={'Event Title'} />
      </>
    }



  </>);
};

export default EventSeriesPodcast;