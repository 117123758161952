import React, { useState, useEffect } from "react";
import ImageViewer from "../../viewers/image";
import { Carousel } from "react-bootstrap";
import { personName } from "../../../helpers";

const EntityOfTheDay = ({ featured }) => {
    const site_of_the_day = featured.site_of_the_day
    const [siteOfDay, setSiteOfDay] = useState([])

    // eslint-disable-next-line 
    useEffect(() => { formatSiteOfDay() }, [site_of_the_day?.length, site_of_the_day])
    const formatSiteOfDay = () => {
        let data = []
        if (site_of_the_day)
            for (let i in site_of_the_day) {
                if (site_of_the_day[i]?.data?.site_of_the_day) {
                    for (let j in site_of_the_day[i].data.site_of_the_day) {
                        site_of_the_day[i].data.site_of_the_day[j].section_index = i
                        site_of_the_day[i].data.site_of_the_day[j].entity_index = j
                        data.push(site_of_the_day[i].data.site_of_the_day[j])
                    }
                }
            }
        setSiteOfDay(data)
    }
    return (
        <>
            {site_of_the_day && <Carousel>
                {
                    siteOfDay?.length > 0 && siteOfDay.map((elem, index) => {
                        return (

                            <Carousel.Item interval={2000} key={`site-of-day-preview-${index}`}>
                                <div>
                                    <ImageViewer entityId={elem?.image_id} entityType={'image'} type='featured@3x' cssClass='featured_preview_entityOfDay' url={elem?.image?.url} key={elem?.image_id} />
                                </div>
                                <div className='cnow-bg-grey small fw-bold mt-1 cnow-ellipsis-1 text-uppercase'>{elem.entity_type === 'recipe' ? `Special Features - Recipe of the Day` : `${elem.entity_type !== 'event_series' ? elem.entity_type === 'person' ? elem?.type : elem.entity_type : 'EVENT SERIES'}`} OF THE DAY - {personName(elem)}</div>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>}
        </>
    )
}

export default EntityOfTheDay