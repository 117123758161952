import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import MetaDescriptionEditor from '../../components/editors/metaDescriptionEditor';
import MetaKeywordsEditor from '../../components/editors/metaKeywordsEditor';
import MetaTitleEditor from '../../components/editors/metaTitleEditor';
import MetaVanityUrlEditor from '../../components/editors/metaVanityUrl';


const MapMetaDescription = (props) => {
  const map = useSelector((state) => state[props.id]);
  return (
    <>
      <PageTitle title={map?.name} id={props.id}/>
      <MetaDescriptionEditor id={props.id} type='map' />
      <MetaKeywordsEditor id={props.id} type='map' />
      <MetaTitleEditor id={props.id} type='map' />
      <MetaVanityUrlEditor id={props.id} type='map' />
    </>
  );
};

export default MapMetaDescription;