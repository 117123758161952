import React from "react";
import { useSelector } from 'react-redux';
import InThisPhoto from "../../components/inThisPhoto";
import OnThisDay from "../../components/onThisDay";
import PageTitle from "../../components/pageTitle";
import { personName } from '../../helpers';

const OnThisDayInThisPhoto = (props) => {
    const person = useSelector(state => state[props.id]);

    return (
        <>
            <PageTitle title={personName(person)} />
            <OnThisDay id={person.id} type={'person'} onUpdate={(path) => props.onUpdate(path)}/>
            <InThisPhoto id={person.id} type={'person'} onUpdate={(path) => props.onUpdate(path)} />
        </>
    )
}
export default OnThisDayInThisPhoto