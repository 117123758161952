/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default';
import ImageView from '../../components/viewers/image';
import { useHistory } from 'react-router-dom';

const _api = require('../../api')

const ImageViewer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const image = useSelector(state => state[props.match.params.id]);

    useEffect(() => {
        if (!image?.id) {
            _api.image.getById(props.match.params.id).then(image => dispatch({ type: 'entity', payload: image }))
        }
    }, [])

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>{image?.caption}</strong>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button icon='close' size='sm' target='_self' onClick={() => { history.goBack() }} />
                        </div>
                    </div>
                </div>
            </div>

            <div id='image_tagger' className='cnow-image-resize-container text-center prevent-select'>
                <ImageView url={image.url} type='original' cssClass='img-preview' />
            </div>
        </div>
    )
}
export default ImageViewer;   
