import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import RelatedSiteEditor from '../../components/editors/relatedSiteEditor';
import uniqid from 'uniqid'

const PlaylistDescription = (props) => {
  const playlist = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={playlist.name} />
      <DescriptionEditor id={props.id} type='playlist' refresh={() => setKey(uniqid())} label={'Description'} />
      <TagEditor id={props.id} type='playlist' />
      <RelatedSiteEditor id={props.id} type='playlist' key={`relatedsite-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
    </>
  );
};

export default PlaylistDescription;