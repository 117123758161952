import React from 'react';
import Panel from '../panel';

const MetaDescription = (props) => {

    const title = props.data?.og_title;
    const description = props.data?.description?.length > 160 ? props.data?.description?.substring(0, 160) + '...' : props.data?.description;

    return (
        <>
            {
                (title || description) &&
                <>
                    <hr />
                    <div className='fw-bold fst-italic'>Meta Description</div>
                    <a href={`${process.env.REACT_APP_WEB_URL}/${props.type}/${props.id}`} target='_blank' rel="noopener noreferrer" className='text-dark'>{`${process.env.REACT_APP_WEB_URL}${props.type}/${props.id}`}</a>
                    <Panel title={title} description={description} bold={true} />
                </>
            }
        </>
    )
}

export default MetaDescription