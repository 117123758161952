import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/pageTitle';
import DescriptionEditor from '../../components/editors/descriptionEditor';
import uniqid from 'uniqid'
import RecipeDescription from '../../components/editors/recipeDescription';

const RecipeAbout = (props) => {

  const recipe = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={recipe?.name} />
      <DescriptionEditor id={props.id} type='recipe' label={'About'} refresh={() => setKey(uniqid())} />
      <RecipeDescription id={props.id} type='recipe' key={`related-person-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
      

    </>
  );
};

export default RecipeAbout;