import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import PageTitle from '../../components/pageTitle';
import EventPeopleEditor from '../../components/eventPeopleEditor';
import EventParticipants from './participants';
import RelatedPeople from '../../components/editors/relatedPeopleEditor';

const EventPeople = (props) => {
    const event = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={event?.name} />
            <EventParticipants id={event?.id} key={`event-participants-editor-${key}`} refresh={() => { setKey(uniqid()) }} type={'event'} />
            <EventPeopleEditor id={event?.id} type='event' section='hosts' key={`host-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Host *' deleteLabel={'deleteHost'} />
            <EventPeopleEditor id={event?.id} type='event' section='sponsors' key={`sponsor-editor-${key}`} refresh={() => { setKey(uniqid()) }} title='Sponsor *' deleteLabel={'deleteSponsor'} />
            <RelatedPeople id={props.id} type='event' key={`related-person-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
        </>
    );
};

export default EventPeople;