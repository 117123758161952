import React, { useState } from 'react'
import { cleanHtml, dateString, relatedPersonName } from '../../helpers'
const MIN_LENGTH = 3
const StoryList = ({ data, type }) => {
  const [length, setLength] = useState(MIN_LENGTH);
  const [more, showMore] = useState(false);

  return (
    <>
      {data && data.length > 0 && (
        <>
          <hr />
          <div className='d-flex justify-content-between'>
            <div className='fw-bold fst-italic'>{type === "event-series" ? 'Higlights' : 'Factoids'}</div>

            {
              data?.length > MIN_LENGTH &&
              <div>
                {more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(3); showMore(false) }}>{`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1' /></div>}
                {!more && <div className='small text-muted fst-italic' style={{ cursor: 'pointer' }} onClick={() => { setLength(data.length); showMore(true) }}>{`${data.length} total, Show more`} <i className='fe fe-chevron-down ms-1' /></div>}
              </div>
            }
          </div>
          {
            data.slice(0, length).map((item, index) => {
              let path;
              let url;
              if (item.entity_type === 'person' || (!item.source?.includes("related_") && item.related_person_id) || (item.source === 'related_person' && item.related_person_id)) {
                path = `${!item.is_participant ? 'people' : 'participant'}`;
                url = item.related_person_id;
              }
              if (item.entity_type === 'site' || (!item.source?.includes("related_") && item.related_site_id) || (item.source === 'related_site' && item.related_site_id)) {
                path = 'site';
                url = item.related_site_id;
              }
              if (item.entity_type === 'tour' || (!item.source?.includes("related_") && item.related_tour_id) || (item.source === 'related_tour' && item.related_tour_id)) {
                path = 'tour';
                url = item.related_tour_id;
              }
              if (item.entity_type === 'map' || (!item.source?.includes("related_") && item.related_map_id) || (item.source === 'related_map' && item.related_map_id)) {
                path = 'map';
                url = item.related_map_id;
              }
              if (item.entity_type === 'event' || (!item.source?.includes("related_") && item.related_event_id) || (item.source === 'related_event' && item.related_event_id)) {
                path = 'event';
                url = item.related_event_id;
              }
              return ((
                <div key={index}>
                  {((item.entity_type === 'site' || item.entity_type === 'person' || item.entity_type === 'tour' || item.entity_type === 'map' || item.entity_type === 'event' || relatedPersonName(item) || item.related_site_name || item.related_tour_name || item.related_map_name || item.related_event_name)) ? (
                    <a
                      href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-dark text-decoration-none'
                    >
                      <span>{dateString(item) && dateString(item)}</span>
                    </a>
                  ) : (
                    <span>{dateString(item) && dateString(item)}</span>
                  )}
                  {((item.entity_type === 'site' || item.entity_type === 'person' || item.entity_type === 'tour' || item.entity_type === 'map' || item.entity_type === 'event' || relatedPersonName(item) || item.related_site_name || item.related_tour_name || item.related_map_name || item.related_event_name) && item?.year && item?.description) ? <a href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-dark text-decoration-none'><span className='mx-1'>-</span></a> : dateString(item) && item?.description && <span className='mx-1'>-</span>}
                  {(item.entity_type === 'site' || item.entity_type === 'person' || item.entity_type === 'tour' || item.entity_type === 'map' || item.entity_type === 'event' || relatedPersonName(item) || item.related_site_name || item.related_tour_name || item.related_map_name || item.related_event_name) ? (
                    <a
                      href={`${process.env.REACT_APP_WEB_URL}/${path}/${url}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-dark text-decoration-none'
                    >
                      <span
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: cleanHtml(item?.description),
                        }}
                      />{' '}
                    </a>
                  ) : (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: cleanHtml(item?.description),
                      }}
                    />
                  )}
                </div>
              ))
            })}
        </>
      )}
    </>
  )
}

export default StoryList
