import React, { useState, useEffect } from "react";

const PDFImage = (props) => {
  const [baseUrl, setBaseUrl] = useState('');

  const convertImageToBase64 = async (url) => {
    if (url && props.imageType) {
      url = url?.toLowerCase().endsWith('.pdf') ? url.replace('.pdf', '.png') : url;
      setBaseUrl(url?.replace('original.', `${props.imageType}.`).replace('original.', `${props.imageType}.`));
    }
    else {
      setBaseUrl(url);
    }
  };

  useEffect(() => {
    convertImageToBase64(props.src);
    // eslint-disable-next-line
  }, [props.src]);

  return (
    <div>
      <img src={baseUrl} alt="PDF" className={props.className} />
    </div>
  );
};

export default PDFImage;
