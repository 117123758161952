import React from 'react'
import PageTitle from '../../../components/pageTitle'

const data = [
  {
    quantity: 67,
    label: 'sites',
  },
  {
    quantity: 32,
    label: 'People/org',
  },
  {
    quantity: 26,
    label: 'Media',
  },
  {
    quantity: 63,
    label: 'Tours',
  },
  {
    quantity: 5,
    label: 'Events',
  },
  {
    quantity: 63,
    label: 'Event Series',
  },
  {
    quantity: 82,
    label: 'Recipe',
  },
  {
    quantity: 64,
    label: 'Maps',
  },
  {
    quantity: 9,
    label: 'What Happened Here',
  },
  {
    quantity: 12,
    label: 'On This Day',
  },
  {
    quantity: 3,
    label: 'In This Photo',
  },
  {
    quantity: 42,
    label: 'Location',
  },
  {
    quantity: 24,
    label: 'Interesting Sites/Facts',
  },
  {
    quantity: 6,
    label: 'Collections',
  },
  {
    quantity: 6,
    label: 'Images',
  },
];

const renderedData = data.map((item, index) => (
  <div key={index}>
    <div className="d-flex">
      <p className='saved-content-quantity'>{item.quantity} &times;</p>
      <p className='text-uppercase border border-dark ms-2 ps-3 saved-content-label'><b>{item.label}</b></p>
    </div>
  </div>
));


const UserCollection = () => {
  return (
    <>
     <PageTitle title={`Prasan`} />
     <hr className="cnow-hr" />
     <div className='cnow-form-title text-uppercase'>Curated Content</div>

     <div className="mt-3">
     {renderedData}
     </div>

    </>
  )
}

export default UserCollection