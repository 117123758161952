/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Featured from './index'
import FeaturedMenu from './menu';
import FeaturedPreview from './preview';
import Spinner from '../../components/spinner';
import SiteOfTheDay from './siteOfTheDay';
import FeaturedForCollection from './featuredForCollection';
import AboutTheMuseum from './aboutTheMuseum';
import OnThisDayMonthYear from './onThisDayMonthYear';
import InThisPhoto from './inThisPhoto';
import WhatHappenedHere from './whatHappenedHere';
import People from './people';
import Podcast from './podcast';
import CNowMaps from './cNowMaps';
import Tours from './tours';
import UpComingEvents from './upComingEvents';
import StaticImages from './staticImages';
import CulturenowCollectionSite from './culturenowCollectionSite';
import { useEffect } from 'react';
import ThenAndNow from './thenAndNow';
import moment from 'moment'
import { newTempId } from '../../helpers';
const _api = require('../../api');

const FeaturedEditor = (props) => {
	const id = props.match.params.id || 'NEW';
	const section = props.match.params.section || '';
	const dispatch = useDispatch()
	const history = useHistory();
	const menuSize = useSelector((state) => state.menu);
	const previewSize = useSelector((state) => state.preview);
	const featured = useSelector((state) => state.featured);
	const [spinner, showSpinner] = useState(false);
	const [getDataStatus, setGetDataStatus] = useState(false)
	const start_date = featured && featured.start_date && JSON.parse(featured.start_date)
	const end_date = featured && featured.end_date && JSON.parse(featured.end_date)

	useEffect(() => {
		showSpinner(getDataStatus)
	}, [getDataStatus])

	const handleMultipleIds = (response) => {
		let data = []
		response.forEach((elem) => {
			data.push(elem.data[section])
			elem.data[section] = []
		})
		response[0].edited = true;
		response[0].data[section] = data.flat()
		return response
	}

	const getData = async () => {
		showSpinner(true);
		let params = {
			"section": section,
			"start_date": start_date.slice(0, 10),
			"end_date": end_date.slice(0, 10),
		}
		if (section === 'site_of_the_day') {
			let response = await _api.featured.getFeatured(params)
			if (response.length > 1) featured.site_of_the_day = handleMultipleIds(response)
			else featured.site_of_the_day = response;
		}
		if (section && section !== 'site_of_the_day') {
			let response = await _api.featured.getFeatured(params)
			featured[section] = response
		}
		dispatch({ type: 'featured', payload: { ...featured } })
		showSpinner(false);
	}

	const updateAndRedirect = async (path) => {
		showSpinner(true);
		if (!path) {
			path = section;
		}
		if (section === 'site_of_the_day') {
			if (featured.site_of_the_day) {
				featured.site_of_the_day.forEach((elem) => {
					if (elem.id.startsWith('temp.') || elem.edited) {
						_api.featured.updateFeatured([elem])
					}
					if (!elem.id.startsWith('temp.') && elem.data.site_of_the_day.length === 0) {
						_api.featured.deleteFeatured(elem.id)
					}
				})
			}
		}
		if (section === 'about_the_museum_without_walls') {
			if (featured[section] && featured[section]?.length > 0) {
				featured[section].forEach((elem) => {
					elem.data.heading = elem.data.heading ? elem.data.heading : `<h2><span style="color:#a82829;"><strong>${moment.unix(moment.utc(start_date).unix()).format("MMMM Do,YYYY")} </strong></span></h2>`
					elem.data.description = elem.data.description ? elem.data.description : `<p>Consider the world outside a museum. Imagine that the world that we live in is really another kind of museum where the works of art exist in the landscape itself. What if you could have a gallery guide which would tell you about the buildings and artworks you find around you. It would show you what the place used to look like and introduce you to some of the people who shaped it.</p>
					<p>Our growing virtual collection of photographs and drawings, maps and documents, podcasts and videos tell the stories of how some of the more iconic places in our cities got to be the way they are and what they might become.</p>
					<p>Explore buildings of the past, present and future. Look at the vast selection of artwork that graces the public realm. And discover how places have evolved over time. Deconstruct the layers of history that form the fabric of our urban landscape. Meet people who have made their mark on our cities and country who have lived in the past or are living now. Listen to their voices. Take (or make) a tour. And join us at an event either virtual or real.</p>
					<p>Our curators are the artists, architects, photographers and historians who created the images, podcasts and videos to share their knowledge and insights.
					Our collaborators are museums, universities, cities, and civic organizations who are the stewards of our shared cultural history.</p>
				   <p>Use the guide online or take it with you on your phone…..</p>
				   <p>Like the cities we live in, this is a work in progress….. Enjoy!</p>`
					if ((elem.id.startsWith('temp.') || elem.edited) && (elem.data[section]?.length > 0 || (!elem.data[section] && elem.data?.description))) {
						_api.featured.updateFeatured([elem])
					}
					if (!elem.id.startsWith('temp.') && elem.data[section]?.length === 0) {
						_api.featured.deleteFeatured(elem.id)
					}
				})
			}

			else {
				let start_date = featured.start_date && JSON.parse(featured.start_date);
				let end_date = featured.end_date && JSON.parse(featured.end_date);
				let data = {
					"start_date": start_date?.slice(0, 10),
					id: newTempId(),
					"end_date": end_date?.slice(0, 10),
					"section": "about_the_museum_without_walls",
				};
				data.data = {
					heading: `<h2><span style="color:#a82829;"><strong>${moment.unix(moment.utc(start_date).unix()).format("MMMM Do,YYYY")} </strong></span></h2>`,
					description: `<p>Consider the world outside a museum. Imagine that the world that we live in is really another kind of museum where the works of art exist in the landscape itself. What if you could have a gallery guide which would tell you about the buildings and artworks you find around you. It would show you what the place used to look like and introduce you to some of the people who shaped it.</p>
			<p>Our growing virtual collection of photographs and drawings, maps and documents, podcasts and videos tell the stories of how some of the more iconic places in our cities got to be the way they are and what they might become.</p>
			<p>Explore buildings of the past, present and future. Look at the vast selection of artwork that graces the public realm. And discover how places have evolved over time. Deconstruct the layers of history that form the fabric of our urban landscape. Meet people who have made their mark on our cities and country who have lived in the past or are living now. Listen to their voices. Take (or make) a tour. And join us at an event either virtual or real.</p>
			<p>Our curators are the artists, architects, photographers and historians who created the images, podcasts and videos to share their knowledge and insights.
			Our collaborators are museums, universities, cities, and civic organizations who are the stewards of our shared cultural history.</p>
		   <p>Use the guide online or take it with you on your phone…..</p>
		   <p>Like the cities we live in, this is a work in progress….. Enjoy!</p>`}

				featured.about_the_museum_without_walls = []
				featured.about_the_museum_without_walls.push(data)
				dispatch({ type: 'featured', payload: { ...featured } })
				_api.featured.updateFeatured([data])

			}
		}




		if (section && section !== 'site_of_the_day' && section !== 'about_the_museum_without_walls') {
			if (featured[section] && featured[section]?.length > 0) {
				featured[section].forEach((elem) => {
					if ((elem.id.startsWith('temp.') || elem.edited) && (elem.data[section]?.length > 0 || (!elem.data[section] && elem.data?.description))) {
						_api.featured.updateFeatured([elem])
					}
					if (!elem.id.startsWith('temp.') && elem.data[section]?.length === 0) {
						_api.featured.deleteFeatured(elem.id)
					}
				})
			}
		}
		history.push(`/featured/editor/${path}`)
		dispatch({ type: 'featured', payload: { ...featured, source: '' } })
		dispatch({ type: 'search', payload: {} })
		showSpinner(false);
		// toast.success('Data Saved');
	}

	//intentionally put 4ms timer and after that get API. Because to make sure that Data is 100% saved and returned back
	//by calling again get API it is confirmed Data Returned there is one more way of setting in store in dispatch after responce in preview. I am not doing that way because of complex
	const onSave = () => {
		showSpinner(true);
		setTimeout(() => {
			if ((start_date === end_date)) getData()
			if ((start_date !== end_date)) dispatch({ type: 'featured', payload: { ...featured, [section]: [] } })
			showSpinner(false);
		}, 4000)
	}
	return (
		<div className='cnow-editor-container cnow-feature-menu'>
			<div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
				<FeaturedMenu id={id} section={section} onClick={(path) => updateAndRedirect(path)} display={spinner} onSave={(path) => { if (path === section) onSave() }} />
			</div>
			<div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
				<Spinner display={spinner}>
					{!section && <Featured id={id} onUpdate={() => { }} />}
					{section === 'site_of_the_day' && <SiteOfTheDay onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'featured_for_collection' && <FeaturedForCollection onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'about_the_museum_without_walls' && <AboutTheMuseum onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'on_this_day' && <OnThisDayMonthYear onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'in_this_photo' && <InThisPhoto onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'then_now' && <ThenAndNow onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'what_happened_here' && <WhatHappenedHere onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'public_art' && <CulturenowCollectionSite section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'architecture' && <CulturenowCollectionSite section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'history' && <CulturenowCollectionSite section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'people' && <People onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'podcast' && <Podcast onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'cnow_maps' && <CNowMaps onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'tours' && <Tours onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'upcoming_events' && <UpComingEvents onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'what_is_around' && <CulturenowCollectionSite section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'places_over_time' && <CulturenowCollectionSite section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'image_search_for_app' && <StaticImages section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'curate_your_own_collection' && <StaticImages section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'contribute_content' && <StaticImages section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'join_the_community' && <StaticImages section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
					{section === 'donate' && <StaticImages section={section} onUpdate={() => { updateAndRedirect(); onSave() }} />}
				</Spinner>
			</div>
			<div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
				<FeaturedPreview id={id} onUpdate={() => updateAndRedirect()} section={section} display={spinner} onSave={() => onSave()} setGetDataStatus={setGetDataStatus} />
			</div>
		</div>
	);
};

export default FeaturedEditor;