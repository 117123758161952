import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import PreviewButton from '../../components/buttons/preview';
import ImageViewer from '../../components/viewers/image';
import PageTitle from '../../components/pageTitle';
import Button from '../../components/buttons/default'
import TextEditor from '../../components/editors/textEditor';
import { Stop1, Stop2 } from './stop';
import StopEditor from './stopEditor';
import StopSiteEditor from './stopSiteEditor';
import LinkButton from '../../components/buttons/link';
import { newTempId } from '../../helpers';
import TourAlerts from './tourAlerts';
import Spinner from '../../components/spinner';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import { handleDragDropReorder, ReorderSites } from '../../helpers';
const _api = require('../../api')

const TourStops = (props) => {


    const history = useHistory();

    const dispatch = useDispatch();
    const tour = useSelector(state => state[props.id]);
    const [tourAlert, showtourAlert] = useState(false);
    const [stopEditor, showStopEditor] = useState(false);
    const [siteEditor, showSiteEditor] = useState(false);
    const [spinner, showSpinner] = useState(false);
    const [dragMode, setDragMode] = useState('stop');

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    let dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        if (dragMode === 'stop') containers.push(document.getElementById(dragulaContainer))
        if (dragMode === 'site') {
            if (tour.stops && tour.stops.length > 0) tour.stops.forEach((stop) => containers.push(document.getElementById(`${dragulaContainer}-${stop.id}`)))
        }
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            let items = []
            if (tour.format === 2 && dragMode === 'stop') {
                items = handleDragDropReorder(tour.stops, dragulaContainer)
            }
            if (tour.format === 2 && dragMode === 'site') {
                items = ReorderSites(tour.stops, dragulaContainer)
            }

            if (tour.format === 1) {
                items = handleDragDropReorder(tour.stops, dragulaContainer)
            }
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...tour, stops: items } })
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End


    const handleEditorChange = (data) => {
        dispatch({ type: 'entity', payload: { ...tour, highlight_description: data } });
        setDragula(uniqid())
    }

    const changeTourFormat = async () => {
        showSpinner(true);
        let newFormat;
        let newStops = [];

        let podcasts = await _api.tour.getPodcast(tour.id)
        if (podcasts?.length > 0) {
            podcasts.forEach(podcast => {
                if (podcast.stop_id) podcast.stop_id = null
            })
            await _api.tour.updatePodcast(tour.id, podcasts)
        }

        await _api.tour.deleteAllStops(tour.id)

        if (tour.format === 2) {
            newFormat = 1;
            tour.stops?.length > 0 && tour.stops.forEach((stop, stopIndex) => {
                stop.sites?.length > 0 && stop.sites.forEach((site, siteIndex) => {
                    let newStopId = newTempId();
                    site.stop_id = newStopId;
                    site.id = newTempId();
                    newStops.push({
                        'id': newStopId,
                        'tour_id': tour.id,
                        'stop_name': site.site_name,
                        'description': siteIndex === 0 ? site.description : '',
                        'image_id': site.image_id,
                        'site_id': site.site_id,
                        'site_name': site.site_name,
                        'location': site.location,
                        'additional_info': '',
                        'sort': newStops.length + 1,
                        'sites': [site]
                    });
                })
            })
        }

        if (tour.format === 1) {
            newStops = [];
            newFormat = 2
            tour.stops?.length > 0 && tour.stops.forEach(stop => {
                let newStopId = newTempId();
                if (stop.sites?.length > 0) {
                    stop.sites[0].stop_id = newStopId;
                    stop.sites[0].id = newTempId();
                    stop.sites[0].sort = 1;
                }
                newStops.push({
                    'id': newStopId,
                    'tour_id': tour.id,
                    'stop_name': stop.location?.address,
                    'description': stop.description,
                    'image_id': stop.image_id,
                    'site_id': stop.site_id,
                    'site_name': stop.site_name,
                    'location': stop.location,
                    'additional_info': '',
                    'sort': newStops.length + 1,
                    'sites': stop.sites
                })
            })
        }

        tour.format = newFormat
        tour.route = null
        tour.route_overview = null
        tour.duration = null
        tour.duration_override = false
        tour.type = null;
        let updatedTour = await _api.tour.update(tour)
        let stops = await _api.tour.updateTourStops(tour.id, newStops)

        dispatch({ type: 'entity', payload: { ...updatedTour, stops: stops } });
        showSpinner(false);
        setDragula(uniqid())
    }

    const addStop = () => {
        if (tour.format === 1) showSiteEditor(true)
        if (tour.format === 2) showStopEditor(true)
    }

    const deleteHighlight = (stop, site) => {
        let stopIndex = tour.stops.findIndex(s => s.id === stop.id);
        let siteIndex = tour.stops[stopIndex].sites.findIndex(s => s.site_id === site.site_id)
        tour.stops[stopIndex].sites[siteIndex].highlight = false;
        dispatch({ type: 'entity', payload: { ...tour, stops: tour.stops } });
    }

    const openMapExplore = async () => {
        let stops = await _api.tour.updateTourStops(tour.id, tour.stops)
        dispatch({ type: 'entity', payload: { ...tour, stops: stops } });
        history.push({ pathname: `/tour/${tour.id}/editor/tour_map_editor/sites_add` })
    }

    const handleDragMode = () => {
        if (dragMode === 'stop') {
            setDragMode('site')
        } else {
            setDragMode('stop')
        }
        setDragula(uniqid())
    }

    return (
        <>
            <PageTitle title={tour?.name} />
            <hr className='cnow-hr' />
            <div name='stop-list'>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>{`Tour Stops - Format ${tour?.format}`}</label>
                    </div>
                    <div className='col-auto btn-list d-flex align-items-center'>
                        {tour?.format === 2 && <div className='form-check px-1'>
                            <input className='form-check-input' type='checkbox' checked={dragMode === 'site'} onChange={() => handleDragMode()} />
                            <label className={`small form-check-label fw-bold `}>Reorder Sites</label>
                        </div>}
                        <Button size='sm' label={tour?.format === 1 ? 'Group Sites within Stops' : 'Detach Sites from Stops'} onClick={() => { showtourAlert(true); setDragula(uniqid()); }} />
                        <Button size='sm' icon='nav-location-done' label={'Explore the Map'} onClick={() => openMapExplore()} />
                        <Button size='sm' icon='plus' label={'New Stop'} onClick={() => addStop()} />
                    </div>
                </div>
                <div className='prevent-select' key={`${tour?.format}-fomat-key`}>
                    <Spinner display={spinner}>
                        {
                            tour?.format === 2 ? (
                                <div id={dragMode === 'stop' ? dragulaContainer : 'stop-container'} key={dragula} >
                                    {
                                        tour?.stops?.length > 0 && tour?.stops.map((stop, index) => {
                                            return (
                                                <Stop1 tourId={tour?.id} stop={stop} showButtons={true} dragMode={dragMode} dragulaContainer={dragulaContainer} dragula={dragula} key={`${stop.id}-stop`} setDragula={setDragula} />
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div id={dragulaContainer} key={dragula}>
                                    {
                                        tour?.stops?.length > 0 && tour?.stops.map((stop, index) => {
                                            if (stop.sites?.length > 0)
                                                return (<Stop2 tourId={tour?.id} stop={stop} showButtons={true} dragula={dragula} />)
                                            else return null
                                        })
                                    }
                                </div>
                            )
                        }
                    </Spinner>
                </div>
            </div>
            <hr className='cnow-hr' />
            <div className='row mb-2'>
                <div className='col'>
                    <div className='row'>
                        <label className='cnow-form-title'>Selected Highlights (select upto 3)</label>
                    </div>
                    {
                        tour?.stops?.length > 0 && tour?.stops?.map(stop => {
                            return (
                                stop.sites?.length > 0 && stop.sites.filter(s => s?.highlight).map(site => {
                                    return (
                                        <>
                                            <div className='row g-2 p-2'>
                                                <div className='col-auto'>
                                                    <div className=''>&nbsp;</div>
                                                    <div className='tour-stop-site-number d-flex'>{tour?.format === 1 ? stop?.sort : `${stop?.sort}.${site.sort}`}</div>
                                                </div>
                                                <div className='col-auto'>
                                                    <div className=''>&nbsp;</div>
                                                    <ImageViewer key={site.image_id} entityType='image' entityId={site.image_id} type='thumb' cssClass='thumb-small' />
                                                </div>
                                                <div className='col'>
                                                    <label className='cnow-form-label fw-bold'>Associated Site name</label>
                                                    <input value={site.site_name} disabled className='w-100 cnow-input-disabled-height border-0' />
                                                </div>
                                                <div className='col-auto'>
                                                    <div className=''>&nbsp;</div>
                                                    <div className='btn-list'>
                                                        <PreviewButton link={`site/${site.site_id}`} />
                                                        <LinkButton icon='edit' to={`/site/${site.site_id}/editor`} />
                                                        <Button icon='delete' onClick={() => deleteHighlight(stop, site)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <div className='row mb-2'>
                    <div className='col'>
                        <TextEditor limit={250} toolbar={true} data={tour?.highlight_description} onChange={(data) => handleEditorChange(data)} >
                            <label className='cnow-form-title'>{'About the Highlights'}</label>
                        </TextEditor>
                    </div>
                </div>
            </div>
            {tourAlert && <TourAlerts show={tourAlert} changeTourFormat={changeTourFormat} pathType={tour?.type} onHide={() => showtourAlert(false)} tourStopHide={props.onHide} type={'changeFormat'} />}
            {stopEditor && <StopEditor key={`stopEditor${tour?.stops?.length}`} tourId={tour?.id} stopId={'NEW'} show={stopEditor} onHide={() => showStopEditor(false)} />}
            {siteEditor && <StopSiteEditor tourId={tour?.id} stopId={'NEW'} siteId={'NEW'} show={siteEditor} onHide={() => showSiteEditor(false)} />}
        </>
    )
}
export default TourStops

