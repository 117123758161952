import React from 'react'
// import TextEditor from '../editors/textEditor'

const InternalTags = ({ label,  handleMainInputChange,  mainInput, hrTrue, placeholder }) => {
  return (
    <>
        {hrTrue && <hr className="cnow-hr" />}
        {label && <div className='row'>
            <div className='col'>
                <label className='cnow-form-title'>{label}</label>
            </div>
        </div>}
        <div className='row g-1'>
            <div className='col'>
                { <div className='row'>
                    <div className='col'>
                        <input name='link' type='text' className='form-control' placeholder={placeholder || 'Esto'} value={mainInput || ''} onChange={({ target: { value } }) => handleMainInputChange(value)} autoComplete='off' />
                    </div>
                </div>}
            </div>
        </div>
    </>
  )
}

export default InternalTags
