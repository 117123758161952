/* eslint-disable react-hooks/exhaustive-deps */
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

import Button from '../../components/buttons/default';
import { useHistory, useLocation } from 'react-router-dom';
import PreviewButton from '../../components/buttons/preview';
import Pagination from "../../components/pagination";
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import CreditList from '../../components/table/creditList';
import LocationList from '../../components/table/locationList';
import ImageRelatedItemList from '../../components/table/imageRelatedItems';
import RelatedEntityList from '../../components/table/relatedEntityList';
import TagList from '../../components/table/tagList';
import TableHeader from '../../components/tableHeader';
import Icon from '../../components/viewers/icon';
import ImageView from '../../components/viewers/image';
import LongTextView from '../../components/viewers/longText';
import ImageUpload from './upload';
import InThisPhotoList from '../../components/table/inThisPhotoList'
import { useDispatch } from 'react-redux';
import { dateString, fileFormat, TABLE_ROWS, cleanHtmlMeta, checkUserTypeAndReturnIds } from '../../helpers';
import { useSelector } from 'react-redux';

const columns = require('../../data/columns_images.json')
const _api = require('../../api')

const Images = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  const history = useHistory();
  const location = useLocation();
  const [searchResult, setSearchResult] = useState([]);

  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 48;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);

  const [spinner, showSpinner] = useState(false);
  const [uploader, showUploader] = useState(false);
  const [view, setView] = useState('list');

  const [licensed, setLicensed] = useState(false)
  const [style, setStyle] = useState('')

  useEffect(() => {
    search()
  }, [page, sort, keyword, user.id]);

  const search = async () => {

    let sortBoolean = true
    const params = {
      "bool": {
        "must": [
          { "match": { "deleted": "false" } },
          { "match": { "created_via": "ADMIN" } },
        ]
      }
    };

    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "caption^3",
            "credits.person_name^2",
            "related_item.categories.category_name",
            "related_item.categories.subcategory_name",
            "tags.tag_name",
            "related_sites.related_site_name",
            "related_people.related_person_name",
            "podcasts.podcast_name",
            "credits.role_name",
            "people.profession.profession_name",
            "image_category",
            "locations.address",
            "theme.theme_name",
            "related_item.name",
            "related_item.themes.theme_name",
            "type",

          ],
          "fuzziness": 2,
          "prefix_length": 2
        }
      });
    }

    if (sort.type) {

      if (sort.type === 'inThisPhoto') {
        params.bool.must[2] = {
          "bool": {
            "should": [
              {
                "match": {
                  "has_entity": true
                }
              }
            ]
          }
        }

      }
      else {

        params.bool.must[2] = {
          "bool": {
            "should": [
              {
                "match": {
                  "type": sort.type
                }
              }
            ]
          }
        }
      }
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }
    showSpinner(true);




    _api.image.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
      setSearchResult(response?.hits)
      showSpinner(false)
    })

    dispatch({ type: 'images', payload: {} })
  }

  const deleteImage = (image) => {
    swal({
      title: `Delete Image`,
      text: `Are you sure that you want to delete ${image?.caption}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => { _api.image.deleteById(image?.id).then(() => { search() }) });
  };

  const editImage = async (id, type, url) => {
    history.push(`/image/${id}/editor`)
    let imgs = []
    if (type === 'then_now') {
      let imgObj = {
        'id': id,
        'url': url
      }

      imgs = await _api.image.getRelatedImage(id)
      imgs.unshift(imgObj)
    }
    dispatch({ type: 'images', payload: { entityImages: imgs, source: location.pathname } })
  }

  const downloadImage = (image) => {
    saveAs(image.url, image.caption)
  }

  const checkLicensed = (image) => {
    setLicensed(!licensed)
  }

  const onSave = (images) => {
    dispatch({ type: 'images', payload: { entityImages: images, source: location.pathname } })
    history.push({ pathname: `/image/${images[0].id}/editor` })
  }

  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a image name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list'>
              <Button label='Add an Image' color='danger' icon='plus-white' onClick={() => showUploader(true)} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between align-items-end'>
              <div>
                <h4 className='mb-0'>Images</h4>
                <div className='small'>
                  <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                  {keyword && <span className='badge badge-search'>
                    {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                  </span>}
                </div>
              </div>
              <div>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
              <div>
                <div className='d-flex gap-2 align-self-end'>
                  <div>
                    {view === 'list' && <Button icon='grid' onClick={() => setView('grid')} />}
                    {view === 'grid' && <Button icon='list' onClick={() => setView('list')} />}
                  </div>
                  <div>
                    <div className='dropdown'>
                      <button className='btn btn-light dropdown-toggle' type='button' id="sort" data-bs-toggle='dropdown' aria-expanded='false'>
                        Sort by: {sort.name}
                      </button>
                      <ul className='dropdown-menu'>
                        {columns.filter(x => x.sort === true).map((column, index) =>
                          <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            {view === 'list' &&
              <div className='table-responsive cnow-table'>
                <table className='table table-striped table-hover table-bordered'>
                  <thead>
                    <tr className='bg-light'>
                      {columns.filter(x => x.display === true).map((column, index) =>
                        <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />)}
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                      const inThisPhoto = item?._source?.image_tags?.map((cat) => cat) || []


                      return (
                        <tr key={index}>
                          <td>
                            <div className='row g-1'>
                              <div className='col-auto'>
                                <div className='form-check mt-1'>
                                  <input className='form-check-input align-middle' type='checkbox' name='select_image' value={item?._source.id} />
                                </div>
                              </div>
                              <div className='col-auto'>
                                <div className='btn-list'>
                                  <Button size='sm' icon='edit' onClick={() => editImage(item?._source?.id, item?._source?.type, item?._source?.url)} />
                                  <Button size='sm' icon='delete' onClick={() => deleteImage(item?._source)} />
                                  <PreviewButton size='sm' link={(item?._source?.type === 'then_now' || item?._source?.type === 'image' || item?._source?.type === 'document') ? `image/${item?._source.id}/preview/${item?._source.id}` : `image/${item?._source.id}`} />
                                  <Button size='sm' icon='download' onClick={() => downloadImage(item?._source)} />
                                  {licensed ? <Button size='sm' icon='yes' onClick={() => checkLicensed(item?._source)} /> :
                                    <Button size='sm' icon='no' color='danger' onClick={() => checkLicensed(item?._source)} />
                                  }
                                </div>
                              </div>
                            </div>
                          </td>
                          <td><ImageView url={item?._source.url} type='thumb' cssClass='thumb' /></td>
                          <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item?._source?.caption}</LongTextView></td>
                          <td>{item?._source?.image_category}</td>
                          <CreditList data={item?._source.credits} />
                          <LocationList data={item?._source.related_item} />
                          <ImageRelatedItemList data={item?._source.related_item} />
                          <InThisPhotoList data={item?._source.entity_tag} />
                          <td><ImageView url={item?._source.url} type='banner' cssClass='banner' /></td>
                          <td>{item?._source.id}</td>
                          <TagList data={item?._source.tags} />
                          <td>{fileFormat(item?._source?.url)}</td>
                          <td>{item?._source.show_taken_date && dateString(item?._source.taken_date)}</td>
                          <td><LongTextView lines={TABLE_ROWS + 1}>{item?._source.description && cleanHtmlMeta(item?._source.description)}</LongTextView></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <RelatedEntityList data={item?._source?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                          <td></td>
                          <td></td>
                          <RelatedEntityList data={item?._source?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                          <RelatedEntityList data={item?._source?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                          <td></td>
                          <td></td>
                          <RelatedEntityList data={item?._source?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                          <td>{item?._source?.edited_by}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            }
            {view === 'grid' &&
              <div>
                <div className='row bg-white p-2 g-2'>
                  {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                    return (
                      <div className='col-6 col-md-4 col-lg-3 col-xl-2 cnow-grid-images px-3' key={index} style={{ opacity: style }}>
                        <div className='card border-0 h-100 px-0' onMouseEnter={() => { setStyle(0.5) }} onMouseLeave={() => { setStyle(1) }} onClick={() => props.history.push({ pathname: `/image/${item?._source.id}/editor`, require: false, url: location.pathname })} style={{ cursor: 'pointer', padding: '10px' }}>
                          <ImageView url={item?._source.url} type='thumb' className='thumb-2x img-fluid' />
                          <div className='card-body p-0'>
                            <div className='cnow-img-caption text-dark'>
                              <LongTextView lines={2}>{item?._source.caption}</LongTextView>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            }
            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
              </div>
            </div>

          </Spinner>
        </div>
      </div>

      {
        uploader && <ImageUpload
          show={uploader}
          onHide={() => showUploader(false)}
          onSave={items => onSave(items)}
          thenAndNow={true}
        />
      }

      <ScrollToTop />
    </>
  );
};

export default Images;