/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from "../../components/buttons/default";
import ImageViewer from "../../components/viewers/image";
import PreviewButton from '../../components/buttons/preview';

const _api = require('../../api')

const RelatedEventPodcast = ({ title, subtitle, data, type, titleLabel }) => {
  const history = useHistory();
  const location = useLocation();
  const [minimize, setMinimize] = useState([])

  const handleEdit = (route) => {
    history.push({ pathname: route, url: location.pathname })
  }

  const handleMinimize = async (item) => {
    let newMin = [...minimize]

    if (newMin.includes(item.id)) newMin = newMin.filter(x => x !== item.id)
    else {
      newMin.push(item.id)

      if (type === 'event' && item?.event_id) {
        item.podcasts = await _api.event.getPodcast(item?.event_id) || []
      }

      setMinimize(newMin)
    }
  }
  return (
    <div className='table table-borderless' key={title}>
      <div className='d-flex'>
        <div className='cnow-form-title'>{title}</div> <div className='sub-title ps-1'>{subtitle}</div>
      </div>
      <div className='podcast-card pb-2'>
        {data?.length > 0 && data?.map((item, index) => {
          return (
            <div className='d-flex' key={item.id}>
              <div>
                <label className='cnow-form-label fw-bold'>Order</label>
                <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={index + 1} autoComplete="off" />
              </div>
              <div className='ms-2'>
                <div>&nbsp;</div>
                <ImageViewer entityId={item?.image_id} entityType={'image'} type='thumb' cssClass='thumb' />
              </div>

              <div className='flex-grow-1 pe-0'>

                <span className='fw-bold'>{titleLabel ? titleLabel : (<div>&nbsp;</div>)}</span>
                <div className='w-100 border border-2 border-dark p-1  fw-bold bg-gray'>{item.event_name}</div>
                {minimize.includes(item.id) &&
                  <div className='pt-2'>
                    {item?.podcasts?.length > 0 && item?.podcasts?.map((subItem, idx) => {
                      return (
                        <div key={subItem.id} className='d-flex pb-3'>
                          <div>
                            <input type='text' className='form-control form-control-sort cnow-order-input-class' name='sort' placeholder={idx + 1} autoComplete="off" />
                          </div>
                          <div className='ms-2'>
                            <div className='position-relative'>
                              {subItem?.podcast_image_url && <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                {subItem.podcast_type === 'audio' ? <img src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%' }} alt="playlist" />
                                  : <img src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%' }} alt="playlist" />}
                              </div>}
                              <ImageViewer url={subItem?.podcast_image_url} type='thumb' cssClass='thumb' />
                            </div>
                          </div>
                          <div className='text-start small ms-2 flex-grow-1'>
                            <div className='bg-light fw-bold'>{subItem?.podcast_name} </div>
                            <div> by {subItem?.podcast_credits} </div>
                          </div>
                          <div className='btn-list d-flex align-items-baseline ms-1 pe-1 me-1'>
                            <PreviewButton size='md' link={`podcast/${subItem?.podcast_id}`} />
                            <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${subItem?.podcast_id}/editor`) }} />

                          </div>
                        </div>
                      )
                    })}
                  </div>}
              </div>
              <div className='ms-1 ps-0'>
                <div>&nbsp;</div>
                <Button size='sm' color='light'
                  icon={`${minimize.includes(item.id) ? 'arrow-top' : 'arrow-bottom'}`}
                  onClick={() => handleMinimize(item)} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}


export default RelatedEventPodcast