import React from 'react'
import PageTitle from '../../../components/pageTitle'

const UserAnalytics = () => {
  return (
    <>
      {/* <PageTitle title={`Prasan Analytics`} /> */}
      <hr className="cnow-hr" />
    </>
  )
}

export default UserAnalytics